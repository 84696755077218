import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import RenderField from "../../../../HelperComponents/RenderField/RenderField";

import { postNewDataSource } from "../../../../../actions/dataSourcesActions";

class SetNameAndUrl extends Component {
  createDataSource = data => {
    const { postNewDataSource, history } = this.props;
    return postNewDataSource({
      ...data,
      type: "magento"
    }).then(res => {
      if (res.payload) {
        history.push("/main/add-magento-data-source/configure-api-endpoints");
      } else {
        const errors = {};
        if (res.error.response.data && res.error.response.data.api_key) {
          errors.api_key = res.error.response.data.api_key[0].message;
        }
        if (res.error.response.data && res.error.response.data.name) {
          errors.name = res.error.response.data.name[0].message;
        }
        throw new SubmissionError(errors);
      }
    });
  };

  render() {
    const { handleSubmit, submitting, pristine, valid, loading, t } = this.props;
    return (
      <div className="add-magento__form-wrapper">
        <div className="title">{t("t:data-sources.set-data-source-name-and-url")}</div>
        <div className="add-magento__form-wrapper--description">
          {t("t:data-sources.set-data-source-name-and-url--description")}
        </div>
        <form onSubmit={handleSubmit(this.createDataSource)}>
          <div className="add-magento__form-wrapper--fields">
            <div>
              <div className="add-magento__form-wrapper--label">
                {t("t:data-sources.data-source-name")}
              </div>
              <Field name="name" type="text" component={RenderField} label="" />
            </div>
            <div>
              <div className="add-magento__form-wrapper--label">
                {t("t:data-sources.access-token")}
              </div>
              <Field name="api_key" type="text" component={RenderField} label="" />
            </div>
          </div>
          <DefaultButton
            variant="contained"
            classes="auth"
            disabled={submitting || pristine || !valid}
            loading={loading}
            formAction
          >
            {t("t:common.continue")}
          </DefaultButton>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.name) {
    errors.name = t("t:common.field-is-required");
  }
  if (!values.api_key) {
    errors.api_key = t("t:common.field-is-required");
  }
  return errors;
};

SetNameAndUrl = reduxForm({
  form: "SetNameAndUrlForm",
  validate
})(SetNameAndUrl);

const mapStateToProps = ({ dashboard }) => {
  return {
    loading: dashboard.loadingComponent
  };
};

const mapDispatchToProps = {
  postNewDataSource
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(SetNameAndUrl)));
