import { takeEvery } from "redux-saga/effects";
import { disableLoader, enableLoader } from "./index";
import { openSuccessSnack } from "./snacks";

export function* handleBalanceLoader() {
  yield takeEvery("POST_GET_INVOICE", enableLoader);
  yield takeEvery("POST_GET_INVOICE_SUCCESS", disableLoader);
  yield takeEvery("POST_GET_INVOICE_FAIL", disableLoader);

  yield takeEvery("POST_DEPOSIT", enableLoader);
  yield takeEvery("POST_DEPOSIT_SUCCESS", disableLoader);
  yield takeEvery("POST_DEPOSIT_FAIL", disableLoader);

  yield takeEvery("POST_REQUEST_MONEY", enableLoader);
  yield takeEvery("POST_REQUEST_MONEY_SUCCESS", disableLoader);
  yield takeEvery("POST_REQUEST_MONEY_FAIL", disableLoader);
}

export function* handleBalanceSnacks() {
  yield takeEvery("POST_GET_INVOICE_SUCCESS", () => openSuccessSnack("invoice-sent-success"));
  yield takeEvery("POST_DEPOSIT_SUCCESS", () => openSuccessSnack("money-deposited-success"));
}
