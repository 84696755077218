import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Clipboard from "react-clipboard.js";
import { Trans, withTranslation } from "react-i18next";

import Snack from "../../HelperComponents/Snack/Snack";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { getOption } from "../../../helpers/functions";

import { getCurrentCompanyCampaigns } from "../../../actions/companiesActions";
import { inviteUser } from "../../../actions/usersActions";

import BackIcon from "../../../assets/image/small-left.svg";
import CopyIcon from "../../../assets/image/document-copy.svg";

import "./InviteUser.scss";

class InviteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignsGot: false,
      loading: false,
      inviteSent: false,
      linkGenerated: false,
      email: "",
      openSnack: false,
      campaign: { label: getOption(props.t("t:common.all")), value: "" },
      campaignList: [],
      role: { label: getOption(props.t("t:users.user")), value: "user" },
      roles: [
        { label: getOption(props.t("t:users.user")), value: "user" },
        { label: getOption(props.t("t:users.owner")), value: "owner" }
      ]
    };
  }

  componentDidMount() {
    if (!this.props.user_info?.current_company?.is_owner)
      this.props.history.push("/main/campaigns");
    this.doRequest(1).then(() => {
      let campaignList = [{ label: getOption(this.props.t("t:common.all")), value: "" }],
        tempCampaigns = [...this.props.campaignsOfCurrentCompany];
      tempCampaigns.forEach(el => campaignList.push({ label: getOption(el.name), value: el.id }));
      this.setState({ campaignsGot: true, campaignList });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.campaign.value !== this.state.campaign.value) {
      this.setState({
        role: { label: getOption(this.props.t("t:users.user")), value: "user" }
      });
    }
  }

  doRequest = async () => {
    const { getCurrentCompanyCampaigns } = this.props;
    await getCurrentCompanyCampaigns();
  };

  handleChange = name => event => {
    this.setState({
      [name]: event,
      linkGenerated: false
    });
  };

  sendInvite = async () => {
    const { inviteUser } = this.props;
    const { email, campaign, role } = this.state;
    this.setState({ loading: true });
    let data = { email };
    if (campaign.value) {
      data.campaign = campaign.value;
    } else {
      data.role = role.value;
    }
    const res = await inviteUser(data);
    if (res.payload) {
      this.setState({ inviteSent: true, loading: false });
    } else {
      this.setState({ openSnack: true, loading: false });
    }
  };

  generateLink = async () => {
    const { inviteUser } = this.props;
    const { campaign, role } = this.state;
    let data = {};
    if (campaign.value) {
      data.campaign = campaign.value;
    } else {
      data.role = role.value;
    }
    await inviteUser(data);
    this.setState({ linkGenerated: true });
  };

  getContent = () => {
    const {
      user_invitation_info,
      campaignsOfCurrentCompany,
      user_info: { current_company },
      t
    } = this.props;
    const {
      inviteSent,
      campaign,
      campaignList,
      linkGenerated,
      email,
      openSnack,
      loading,
      role,
      roles
    } = this.state;
    if (!inviteSent) {
      return (
        <div>
          <Link to="/main/users/?tab=approved" className="text_hover back">
            <img src={BackIcon} alt="" />
            {t("t:users.back-to-users")}
          </Link>
          <div className="title">{t("t:users.invite-users-to-your-account")}</div>
          <div className="email-and-role">
            <div>
              <span className="info">{`${t("t:users.user-email-address")} *`}</span>
              <div className="input_wrapper">
                <input
                  type="text"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </div>
            </div>
            <div>
              <span className="info">{t("t:users.role")}</span>
              <FormControl className="select_wrapper">
                <SelectComponent
                  value={role}
                  options={roles}
                  change={this.handleChange("role")}
                  isClearable="false"
                  isSearchable={false}
                  disabled={campaign.value}
                />
              </FormControl>
            </div>
          </div>
          <div className="info">{t("t:common.campaign")}</div>
          <FormControl className="select_wrapper">
            <SelectComponent
              value={campaign}
              options={campaignList}
              change={this.handleChange("campaign")}
              isClearable="false"
              isSearchable={false}
              placeholder={t("t:common.campaign")}
            />
          </FormControl>
          <div className="btn_wrapper">
            <DefaultButton
              variant="contained"
              classes="auth"
              loading={loading}
              onClick={this.sendInvite}
              disabled={!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email)}
            >
              {t("t:users.send-invite")}
            </DefaultButton>
            {!linkGenerated && (
              <DefaultButton
                variant="contained"
                classes="generate_link"
                onClick={this.generateLink}
              >
                {t("t:users.generate-link")}
              </DefaultButton>
            )}
          </div>
          {linkGenerated && (
            <div className="invitation_link_wrapper">
              <div className="inv_title">{t("t:users.link-you-can-share")}</div>
              <div className="inv_link_block">
                <div>
                  <span>{t("t:users.link")}</span>
                  <p>{user_invitation_info.url}</p>
                </div>
                <Clipboard component="div" data-clipboard-text={user_invitation_info.url}>
                  <TooltipMessage text="Copy link" delay={200} position="top" classes="">
                    <img className="text_hover" src={CopyIcon} alt="copy" />
                  </TooltipMessage>
                </Clipboard>
              </div>
            </div>
          )}
          <Snack
            open={openSnack}
            handleSnack={() => this.setState({ openSnack: false })}
            message={t("t:users.enter-valid-email")}
            type="error"
          />
        </div>
      );
    } else {
      const singleCampaign = !!user_invitation_info.campaign;
      return (
        <div>
          <div className="title">{t("t:users.invite-users-to-your-account")}</div>
          <div className="yellow_box max_width_520">
            {singleCampaign ? (
              <section>
                <Trans
                  i18nKey="t:users.user-invited-to-company-in-campaign"
                  components={{ bold: <strong /> }}
                  values={{
                    user_email: user_invitation_info.email,
                    company: current_company.name,
                    campaign: campaignsOfCurrentCompany.find(
                      el => el.id === user_invitation_info.campaign
                    ).name
                  }}
                />
              </section>
            ) : (
              <section>
                <Trans
                  i18nKey="t:users.user-invited-to-company"
                  components={{ bold: <strong /> }}
                  values={{
                    user_email: user_invitation_info.email,
                    company: current_company.name
                  }}
                />
              </section>
            )}
          </div>
          <div className="btn_wrapper go_to_users">
            <DefaultButton
              variant="contained"
              classes="auth"
              type="link"
              to="/main/users/?tab=approved"
            >
              {t("t:users.go-to-users")}
            </DefaultButton>
          </div>
        </div>
      );
    }
  };

  render() {
    return <div className="invite_user_wrapper">{this.getContent()}</div>;
  }
}

const mapStateToProps = ({ auth, users, companies }) => {
  return {
    user_info: auth.user_info,
    user_invitation_info: users.user_invitation_info,
    campaignsOfCurrentCompany: companies.campaignsOfCurrentCompany
  };
};

const mapDispatchToProps = {
  inviteUser,
  getCurrentCompanyCampaigns
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteUser));
