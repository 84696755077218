import React from "react";

import { renderMobileImage } from "../../helpers";

import RatingIcon from "../../../../../../assets/image/mobile-rating.svg";

const Rating = ({ title, description, image }) => (
  <>
    <div style={{ textAlign: "left", width: "100%" }}>
      <h2>{title}</h2>
      <p>{description}</p>
      {image && <div className="image-wrapper">{renderMobileImage(image)}</div>}
    </div>
    <img src={RatingIcon} alt="rating" className="rating-icon" />
  </>
);

export default Rating;
