import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";

import Snack from "../Snack/Snack";

import CancelIcon from "../../../assets/image/btn-trash.svg";
import SaveIcon from "../../../assets/image/btn-save.svg";
import EditIcon from "../../../assets/image/btn-edit.svg";

import "./styles.scss";

const EditableText = ({
  text,
  inputPlaceholder,
  linkTo,
  onSave,
  canEdit = true,
  containerStyle
}) => {
  const [inputValue, setInputValue] = useState("");
  const [editing, setEditing] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (text !== inputValue) setInputValue(text);
    // eslint-disable-next-line
  }, [text]);

  useEffect(() => {
    if (editing) document.getElementById("nameInput").focus();
  }, [editing]);

  const onEditClick = () => {
    setEditing(true);
  };

  const onCancel = () => {
    setInputValue(text);
    setEditing(false);
  };

  const onSaveClick = () => {
    onSave(inputValue).then(status => {
      if (status === "success") {
        setEditing(false);
      } else {
        setErrorCode(status);
      }
    });
  };

  return (
    <>
      <div className={clsx(["editable-text", containerStyle, editing && "is-editing"])}>
        {editing ? (
          <>
            <input
              className="editable-text__input"
              id="nameInput"
              type="text"
              placeholder={inputPlaceholder}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
            <img src={CancelIcon} alt="X" className="text_hover" onClick={onCancel} />
            <img src={SaveIcon} alt="V" className="text_hover" onClick={onSaveClick} />
          </>
        ) : (
          <>
            {linkTo ? (
              <Link to={linkTo} className="editable-text__text" title={text}>
                {text}
              </Link>
            ) : (
              <div className="editable-text__text" title={text}>
                {text}
              </div>
            )}
            {canEdit && (
              <img src={EditIcon} alt="edit" className="text_hover" onClick={onEditClick} />
            )}
          </>
        )}
      </div>
      <Snack
        open={!!errorCode}
        handleSnack={() => this.setState({ openSnack: false })}
        message={t(`t:snack-messages.${errorCode || "fallback-error"}`)}
        type="error"
      />
    </>
  );
};

export default EditableText;
