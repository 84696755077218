import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Image as CloudinaryImage } from "cloudinary-react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Snack from "../../HelperComponents/Snack/Snack";
import { validateImage } from "../../../helpers/functions";
import Dropzone from "../../HelperComponents/DropzoneComponent/DropzoneComponent";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import ColorPicker from "../../HelperComponents/ColorPicker/ColorPicker";
import CropperComponent from "../../HelperComponents/CropperComponent";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import {
  postProduct,
  resetProductErrors,
  setProductError,
  postProductImage
} from "../../../actions/productsActions";
import CharactersLeft from "../../HelperComponents/CharactersLeft";

import eye from "../../../assets/image/My_Campaign/eye.svg";
import del from "../../../assets/image/My_Campaign/del.svg";
import Shape from "../../../assets/image/My_Campaign/Shape.svg";

import "./CreateProduct.scss";

class CreateProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      description: "",
      api_product_id: "",
      url: "",
      price: "",
      vat_percent: "20",
      color: null,
      images: [],
      openErrorSnack: false,
      openFileTypeErrorSnack: false,
      previewDialogOpen: false,
      imagesToCrop: [],
      isBackDropOpen: false,
      selectedOrientation: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.images.length !== this.state.images.length && !this.state.images.length) {
      this.setState({ selectedOrientation: null });
    }
  }

  createProduct = async () => {
    const {
      postProduct,
      history,
      setProductError,
      resetProductErrors,
      onSuccess = newProduct => {
        resetProductErrors();
        history.push(`/main/products`);
      },
      t
    } = this.props;
    const {
      name,
      description,
      api_product_id,
      url,
      price,
      vat_percent,
      color,
      images
    } = this.state;
    if (images.length > 5) {
      setProductError({
        image: [
          { code: "max_limit", message: t("t:snack-messages.maximum-n-images", { number: 5 }) }
        ]
      });
      this.setState({ openErrorSnack: true });
    } else {
      this.setState({ loading: true });
      const res = await postProduct({
        name,
        description,
        api_product_id,
        url,
        price,
        vat_percent,
        color,
        images_ids: images.map(i => i.id)
      });
      if (res.error) {
        this.setState({ openErrorSnack: true, loading: false });
      } else {
        onSuccess(res.payload.data);
      }
    }
  };

  handleFiles = files => {
    this.validateFiles(files).then(res => {
      if (!res) {
        this.setState({ openErrorSnack: true });
      } else {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onloadend = () =>
            this.setState(({ imagesToCrop }) => ({
              imagesToCrop: [...imagesToCrop, { dataUrl: [reader.result] }]
            }));
        }
      }
    });
  };

  validateFiles = async files => {
    let validationPassed = true;
    for await (const el of files) {
      if (!validateImage(el)) {
        validationPassed = false;
      }
    }
    return validationPassed;
  };

  uploadImage = (canvas, mimeType, orientation) => {
    const { postProductImage } = this.props;
    const { imagesToCrop, images } = this.state;
    const tempImagesToCrop = imagesToCrop.slice(1);
    this.setState({ imagesToCrop: [], isBackDropOpen: true, selectedOrientation: orientation });
    canvas.toBlob(blob => {
      const formData = new FormData();
      formData.append("image", blob, "product.png");
      postProductImage(formData).then(res => {
        this.setState({
          imagesToCrop: tempImagesToCrop,
          images: [...images, res.payload.data],
          isBackDropOpen: false
        });
      });
    }, mimeType);
  };

  onCancelCropping = () => this.setState({ imagesToCrop: [] });

  handleColorChange = color => this.setState({ color });

  onDescriptionChange = textInput => {
    const { description } = this.state;
    if (textInput.length < 351 || textInput.length < description) {
      this.setState({ description: textInput });
    } else {
      this.setState({ description: textInput.slice(0, 350) });
    }
  };

  render() {
    const {
      productError,
      t,
      pageTitle = t("t:products.add-new-product"),
      productNameText = `${t("t:products.product-name")} *`,
      productDescriptionText = `${t("t:products.product-description")} *`,
      productIDText = t("t:products.product-id"),
      productUrlText = t("t:products.product-url"),
      productImageText = `${t("t:products.product-images")} *`,
      productFullPriceText = `${t("t:products.product-full-price")} *`,
      productVatText = `${t("t:products.vat")} *`,
      productColorText = t("t:products.color"),
      submitButtonText = t("t:products.add-product"),
      onCancel
    } = this.props;
    const {
      name,
      description,
      api_product_id,
      url,
      price,
      vat_percent,
      color,
      openErrorSnack,
      loading,
      images,
      previewDialogOpen,
      fileTypeErrorSnack,
      imagesToCrop,
      isBackDropOpen,
      selectedOrientation
    } = this.state;
    const regexp = new RegExp("^[0-9.,]+$");
    const fieldsAreMissing = !name || !description || !price || !vat_percent || !images.length;
    return (
      <div className="add_product_wrapper">
        <div>
          <div className="title">{pageTitle}</div>
          <span className="info">{productNameText}</span>
          <div className="input_wrapper">
            <input
              type="text"
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
            />
          </div>
          <span className="info">{productDescriptionText}</span>
          <div className="input_wrapper">
            <textarea
              value={description}
              onChange={e => this.onDescriptionChange(e.target.value)}
              placeholder={t("t:products.product-description-placeholder")}
            />
            <CharactersLeft title={description} maxCharacters={350} />
          </div>
          <span className="info">
            {productIDText}
            <TooltipMessage
              text={<p>{t("t:helper-texts.product-sku")}</p>}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img className="tooltip-question-icon" src={Shape} alt="Shape" />
            </TooltipMessage>
          </span>
          <div className="input_wrapper product-id">
            <input
              type="text"
              value={api_product_id}
              onChange={e => this.setState({ api_product_id: e.target.value })}
            />
          </div>
          <span className="info">{productUrlText}</span>
          <div className="input_wrapper full-width">
            <input
              type="text"
              value={url}
              onChange={e => this.setState({ url: e.target.value })}
              placeholder={t("t:products.product-url-placeholder")}
            />
          </div>
          <span className="info">
            {productImageText}
            <TooltipMessage
              text={
                <p>
                  {t("t:helper-texts.image")} <br />
                  {t("t:helper-texts.aspect-ratio-and-min-res", {
                    aspect_ratio: "16:10",
                    min_resolution: "1280x800"
                  })}
                </p>
              }
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img className="tooltip-question-icon" src={Shape} alt="Shape" />
            </TooltipMessage>
          </span>
          {images.map(({ id, image }) => (
            <div className="drag_img" key={id}>
              <div>
                <CloudinaryImage
                  cloudName="campy"
                  width="39"
                  heigth="39"
                  gravity="face"
                  crop="thumb"
                  publicId={image.split("/").pop()}
                />
                <div>{image.name || image.split("/").pop()}</div>
              </div>
              <div>
                <button onClick={() => this.setState({ previewDialogOpen: id })}>
                  <img src={eye} alt="eye" />
                </button>
                <button onClick={() => this.setState({ images: images.filter(i => i.id !== id) })}>
                  <img src={del} alt="del" />
                </button>
              </div>
            </div>
          ))}
          <Dropzone
            placeholder={t("t:common.drop-your-image-here")}
            multiple
            handleFiles={this.handleFiles}
          />
          <div className="fields-wrapper">
            <div className="field-wrapper">
              <span className="info">{productFullPriceText}</span>
              <div className="price_block_input">
                <input
                  type="text"
                  value={price}
                  onChange={e => {
                    if (
                      e.target.value === "" ||
                      regexp.test(e.target.value) ||
                      e.target.value.length < (price || "").length
                    ) {
                      this.setState({
                        price: e.target.value.replace(",", ".")
                      });
                    }
                  }}
                />
                <span>€</span>
              </div>
            </div>
            <div className="field-wrapper">
              <span className="info">
                {productVatText}
                <TooltipMessage
                  text={<p>{t("t:helper-texts.product-vat")}</p>}
                  delay={200}
                  position="top"
                  classes="step_tooltip"
                >
                  <img className="tooltip-question-icon" src={Shape} alt="Shape" />
                </TooltipMessage>
              </span>
              <div className="price_block_input">
                <input
                  type="text"
                  value={vat_percent}
                  onChange={e => {
                    if (
                      e.target.value === "" ||
                      regexp.test(e.target.value) ||
                      e.target.value.length < (vat_percent || "").length
                    ) {
                      this.setState({ vat_percent: e.target.value });
                    }
                  }}
                />
                <span>%</span>
              </div>
            </div>
            <div className="field-wrapper">
              <span className="info">{productColorText}</span>
              <ColorPicker
                colorID={color}
                handleColorChange={this.handleColorChange}
                fieldHeight={32}
              />
            </div>
          </div>
          <div className="fields-wrapper btn-wrapper">
            <TooltipMessage
              text={fieldsAreMissing ? t("t:common.enter-mandatory-fields") : ""}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <div>
                <DefaultButton
                  variant="contained"
                  classes="add_product"
                  type="button"
                  onClick={this.createProduct}
                  loading={loading}
                  disabled={fieldsAreMissing || loading}
                >
                  {submitButtonText}
                </DefaultButton>
              </div>
            </TooltipMessage>
            {onCancel ? (
              <p onClick={onCancel} className="text_hover back">
                {t("t:common.cancel")}
              </p>
            ) : (
              <Link to="/main/products" className="text_hover back">
                {t("t:common.cancel")}
              </Link>
            )}
          </div>
          <Snack
            open={openErrorSnack}
            handleSnack={() => this.setState({ openErrorSnack: false })}
            message={
              productError &&
              Object.values(productError)[0] &&
              Object.values(productError)[0][0].message
            }
            type="error"
          />
          <Snack
            open={fileTypeErrorSnack}
            handleSnack={() => this.setState({ fileTypeErrorSnack: false })}
            message={t("t:snack-messages.file-type-fail")}
            type="error"
          />
          <DialogComponent
            open={!!previewDialogOpen}
            onClose={() => this.setState({ previewDialogOpen: false })}
            closeIcon
            classes="image_dialog"
            rootClass="image_root_dialog"
          >
            <div>
              <img src={images.find(i => i.id === previewDialogOpen)?.image} alt="preview" />
            </div>
          </DialogComponent>
          {!!imagesToCrop.length && (
            <CropperComponent
              aspectRatio="product"
              imageToCrop={imagesToCrop[0].dataUrl}
              onFinishCropping={this.uploadImage}
              onCancelCropping={this.onCancelCropping}
              withOrientation={!selectedOrientation}
              lockedOrientation={selectedOrientation}
            />
          )}
          <Backdrop open={isBackDropOpen} style={{ color: "white", zIndex: 9999 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ products }) => {
  return {
    productError: products.productError
  };
};

const mapDispatchToProps = {
  resetProductErrors,
  postProduct,
  setProductError,
  postProductImage
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateProduct));
