import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Clipboard from "react-clipboard.js";

import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { generateCompanyAccessToken, getCompanyAccessToken } from "../../actions/companiesActions";
import { API_BASE_URL } from "../../config";

import BackIcon from "../../assets/image/small-left.svg";
import ShowIcon from "../../assets/image/show_icon.svg";
import HideIcon from "../../assets/image/hide_icon.svg";

import "./styles.scss";

const CompanyApiAccess = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { accessToken } = useSelector(({ companyApiAccess }) => companyApiAccess);
  const [isShownToken, setIsShownToken] = useState(false);
  useEffect(() => {
    dispatch(getCompanyAccessToken(id));
  }, [dispatch, id]);

  const onGenerateClick = () => {
    dispatch(generateCompanyAccessToken(id));
  };

  const renderDocumentationButton = () => (
    <a
      href={`${API_BASE_URL.replace("/web/v1", "/public")}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <DefaultButton
        variant="contained"
        classes="test_btn public-api-access__documentation-btn"
        type="button"
      >
        {t("t:companies.view-documentation")}
      </DefaultButton>
    </a>
  );

  return (
    <div className="public-api-access__page">
      <div className="public-api-access__wrapper">
        <Link to="/main/profile/?tab=companies" className="public-api-access__back text_hover">
          <img src={BackIcon} alt="" />
          {t("t:companies.back-to-companies")}
        </Link>
        <div className="public-api-access__title">{t("t:companies.public-api-access")}</div>
        <div className="public-api-access__description">
          <Trans
            i18nKey="t:companies.public-api-access-description"
            components={{ break: <br /> }}
          />
        </div>
        {accessToken ? (
          <>
            <div className="public-api-access__access-token">
              <div>
                {t("t:companies.access-token")}:&nbsp;
                <span>{isShownToken ? accessToken : "••••••••••••••••••••••••••••••••"}</span>
              </div>
              <img
                src={isShownToken ? HideIcon : ShowIcon}
                alt="show/hide"
                onClick={() => setIsShownToken(!isShownToken)}
              />
            </div>
            <div className="public-api-access__btn-wrapper">
              <Clipboard component="div" data-clipboard-text={accessToken}>
                <DefaultButton
                  variant="contained"
                  classes="auth public-api-access__copy-btn"
                  type="button"
                >
                  {t("t:companies.copy")}
                </DefaultButton>
              </Clipboard>
              <DefaultButton
                variant="contained"
                classes="test_btn public-api-access__regenerate-btn"
                type="button"
                onClick={onGenerateClick}
              >
                {t("t:companies.regenerate")}
              </DefaultButton>
            </div>
            <hr className="public-api-access__separator" />
            {renderDocumentationButton()}
          </>
        ) : (
          <div className="public-api-access__btn-wrapper">
            <DefaultButton
              variant="contained"
              classes="auth public-api-access__generate-btn"
              type="button"
              onClick={onGenerateClick}
            >
              {t("t:companies.generate")}
            </DefaultButton>
            {renderDocumentationButton()}
          </div>
        )}
      </div>
      <Backdrop open={accessToken === null} style={{ zIndex: 1000, color: "white" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default CompanyApiAccess;
