import React, { Component } from "react";
import Switch from "react-switch";

import "./SwitchComponent.scss";

class SwitchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: props.defaultChecked };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.defaultChecked !== this.props.defaultChecked) {
      this.setState({ checked: this.props.defaultChecked });
    }
  }

  handleChange(checked) {
    if (this.props.couldGoWrong) {
      this.props.onChange(checked).then(completed => {
        if (completed) {
          this.setState({ checked });
        }
      });
    } else {
      this.props.onChange(checked);
      this.setState({ checked });
    }
  }

  render() {
    const { height, width, handleDiameter, disabled, labelLeftSide } = this.props;
    return (
      <label className="switch_wrapper">
        {labelLeftSide && (
          <span style={{ opacity: disabled ? "0.5" : "1" }}>{this.props.label}</span>
        )}
        <Switch
          onChange={this.handleChange}
          checked={this.state.checked}
          disabled={disabled}
          uncheckedIcon={false}
          checkedIcon={false}
          height={height}
          width={width}
          handleDiameter={handleDiameter}
          onColor="#F23EA6"
          offColor="#CECECE"
          activeBoxShadow="0 0 2px 2px #FFFFFF"
        />
        {!labelLeftSide && (
          <span style={{ opacity: disabled ? "0.5" : "1", marginLeft: "12px" }}>
            {this.props.label}
          </span>
        )}
      </label>
    );
  }
}

export default SwitchComponent;
