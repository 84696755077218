import React from "react";

import { renderMobileImage } from "../../helpers";

import PhotoIcon from "../../../../../../assets/image/mobile-photo.svg";

const Photo = ({ title, description, image }) => (
  <>
    <h2>{title}</h2>
    <p>{description}</p>
    <div className="image-wrapper">
      {renderMobileImage(image)}
      <img src={PhotoIcon} alt="camera" className="image-wrapper__icon" />
    </div>
  </>
);

export default Photo;
