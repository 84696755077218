import React, { Fragment } from "react";

import Pagination from "../../HelperComponents/Pagination/Pagination";

import bg_icon from "../../../assets/image/user_dummy.svg";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";

const InvitedUsers = ({ usersList, toggleDialog, activePage, doRequest, setPage, t }) => (
  <Fragment>
    <div className="users_content">
      {usersList.results.map(({ id, email, url, is_used, campaign_name }) => (
        <div key={`user_item_${id}`}>
          <div className="content invited">
            <div className="img_block">
              <img src={bg_icon} alt="bg_icon" />
            </div>
            <div className="user_info">
              <div className="name">{email || url}</div>
              {campaign_name ? (
                <div className="last_updated_info">{`${t(
                  "t:users.invited-to-the-campaign"
                )} «${campaign_name}»`}</div>
              ) : (
                <div className="to_company">{t("t:users.invited-to-the-campaign")}</div>
              )}
            </div>
          </div>
          <div className="more_info_btn invited">
            <div />
            {!is_used && (
              <div className="btn_wrapper">
                <DefaultButton
                  variant="outlined"
                  classes="leave_btn"
                  onClick={() => toggleDialog("revoke_invitation", id)}
                >
                  {t("t:users.revoke")}
                </DefaultButton>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
    <div className="users_pagination_wrapper">
      {usersList.count > 3 && (
        <Pagination
          active={activePage}
          pageCount={Math.ceil(usersList.count / 3)}
          onChange={e =>
            doRequest(e.selected + 1).then(() => setPage("activeInvitedPage", e.selected + 1))
          }
          t={t}
        />
      )}
    </div>
  </Fragment>
);

export default InvitedUsers;
