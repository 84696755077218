import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import { withTranslation } from "react-i18next";

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import Tabs from "../../HelperComponents/Tabs";
import BreadcrumbsTabs from "../../HelperComponents/Tabs/BreadcrumbsTabs";

import { Preview } from "../Preview";
import {
  deleteEditor,
  postViewer,
  setEditorRulesAreChanging
} from "../../../actions/campaignActions";

import PreviewIcon from "../../../assets/image/preview.svg";
import EditIcon from "../../../assets/image/edit-icon-white.svg";
import DoneIcon from "../../../assets/image/check-white.svg";
import AnalyticsActiveIcon from "../../../assets/image/CampaignTabs/analytics_active.svg";
import AnalyticsInactiveIcon from "../../../assets/image/CampaignTabs/analytics_inactive.svg";
import AudienceActiveIcon from "../../../assets/image/CampaignTabs/audience_active.svg";
import AudienceInactiveIcon from "../../../assets/image/CampaignTabs/audience_inactive.svg";
import BuildCampaignActiveIcon from "../../../assets/image/CampaignTabs/build_active.svg";
import BuildCampaignInactiveIcon from "../../../assets/image/CampaignTabs/build_inactive.svg";
import CostActiveIcon from "../../../assets/image/CampaignTabs/cost_active.svg";
import CostInactiveIcon from "../../../assets/image/CampaignTabs/cost_inactive.svg";
import DeliveriesActiveIcon from "../../../assets/image/CampaignTabs/deliveries_active.svg";
import DeliveriesInactiveIcon from "../../../assets/image/CampaignTabs/deliveries_inactive.svg";
import DistributionActiveIcon from "../../../assets/image/CampaignTabs/distribution_active.svg";
import DistributionInactiveIcon from "../../../assets/image/CampaignTabs/distribution_inactive.svg";

import "./styles.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.stepTabs = [
      {
        label: props.t("t:campaigns.steps"),
        value: "build_campaign",
        error: false,
        activeIcon: BuildCampaignActiveIcon,
        inactiveIcon: BuildCampaignInactiveIcon
      },
      {
        label: props.t("t:campaign-audience.audience"),
        value: "audience",
        error: false,
        activeIcon: AudienceActiveIcon,
        inactiveIcon: AudienceInactiveIcon
      },
      {
        label: props.t("t:distribution.distribution"),
        value: "distribution",
        error: false,
        activeIcon: DistributionActiveIcon,
        inactiveIcon: DistributionInactiveIcon
      },
      {
        label: props.t("t:support.creating-a-campaign--accordion-4--header"),
        value: "cost",
        error: false,
        activeIcon: CostActiveIcon,
        inactiveIcon: CostInactiveIcon
      }
    ];
    this.tabs = [
      {
        label: props.t("t:campaign-analytics.analytics"),
        value: "analytics",
        activeIcon: AnalyticsActiveIcon,
        inactiveIcon: AnalyticsInactiveIcon
      },
      {
        label: props.t("t:deliveries.deliveries"),
        value: "deliveries",
        activeIcon: DeliveriesActiveIcon,
        inactiveIcon: DeliveriesInactiveIcon
      }
    ];
    this.state = {
      campaignId: props.match.params.id,
      previewDialogOpen: false,
      openUsersDialog: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.publishValidationError !== prevProps.publishValidationError) {
      const {
        distributionAddresses,
        distributionTitle,
        distributionImage,
        distributionDescription,
        buildCampaignNumberOfTasks,
        buildCampaignRewardType,
        buildCampaignRewardTypeCount,
        buildCampaignRewardTypeLastStepInconsistency,
        buildCampaignLocationTitle,
        buildCampaignLocationAddress,
        buildCampaignLocationRadius,
        buildCampaignLocationImage,
        buildCampaignVideoTitle,
        buildCampaignVideoUrl,
        buildCampaignImageTitle,
        buildCampaignImageImage,
        buildCampaignImageDuration,
        buildCampaignUrlTitle,
        buildCampaignUrlUrl,
        buildCampaignUrlImage,
        buildCampaignUrlDuration,
        buildCampaignPhotoTitle,
        buildCampaignPhotoImage,
        buildCampaignRatingImage,
        buildCampaignMultipleTitle,
        buildCampaignMultipleAnswersCount,
        buildCampaignMultipleAnswersEmpty,
        buildCampaignQuizTitle,
        buildCampaignQuizAnswersCount,
        buildCampaignQuizAnswersEmpty,
        buildCampaignQuizCorrectAnswer,
        buildCampaignPicturesTitle,
        buildCampaignPicturesImages,
        buildCampaignScanQRTitle,
        buildCampaignNewQRTitle,
        buildCampaignNewQRNumber,
        buildCampaignNewQRExpiration,
        buildCampaignNewQRImage,
        buildCampaignNewQRBackgroundImage,
        buildCampaignNewBarcodeTitle,
        buildCampaignNewBarcodeNumber,
        buildCampaignNewBarcodeExpiration,
        buildCampaignMonetaryTitle,
        buildCampaignMonetaryAmount,
        buildCampaignMonetaryCurrency,
        buildCampaignDiscountTitle,
        buildCampaignDiscountProduct,
        buildCampaignDiscountValue,
        buildCampaignBonusTitle,
        buildCampaignBonusProduct,
        buildCampaignBonusGift,
        buildCampaignNoveltyTitle,
        buildCampaignNoveltyProduct,
        distributionMaxParticipants,
        distributionTimeToFinish,
        costBalance,
        audienceInterests,
        audienceGender
      } = this.props.publishValidationError;
      if (audienceInterests || audienceGender) {
        this.stepTabs[1].error = true;
      }
      if (
        distributionTitle ||
        distributionImage ||
        distributionDescription ||
        distributionMaxParticipants ||
        distributionTimeToFinish ||
        distributionAddresses
      ) {
        this.stepTabs[2].error = true;
      }
      if (
        buildCampaignNumberOfTasks ||
        buildCampaignRewardType ||
        buildCampaignRewardTypeCount ||
        buildCampaignRewardTypeLastStepInconsistency ||
        buildCampaignLocationTitle ||
        buildCampaignLocationAddress ||
        buildCampaignLocationRadius ||
        buildCampaignLocationImage ||
        buildCampaignVideoTitle ||
        buildCampaignVideoUrl ||
        buildCampaignImageTitle ||
        buildCampaignImageImage ||
        buildCampaignImageDuration ||
        buildCampaignUrlTitle ||
        buildCampaignUrlUrl ||
        buildCampaignUrlImage ||
        buildCampaignUrlDuration ||
        buildCampaignPhotoTitle ||
        buildCampaignPhotoImage ||
        buildCampaignRatingImage ||
        buildCampaignMultipleTitle ||
        buildCampaignMultipleAnswersCount ||
        buildCampaignMultipleAnswersEmpty ||
        buildCampaignQuizTitle ||
        buildCampaignQuizAnswersCount ||
        buildCampaignQuizAnswersEmpty ||
        buildCampaignQuizCorrectAnswer ||
        buildCampaignPicturesTitle ||
        buildCampaignPicturesImages ||
        buildCampaignScanQRTitle ||
        buildCampaignNewQRTitle ||
        buildCampaignNewQRNumber ||
        buildCampaignNewQRImage ||
        buildCampaignNewQRBackgroundImage ||
        buildCampaignNewQRExpiration ||
        buildCampaignNewBarcodeTitle ||
        buildCampaignNewBarcodeNumber ||
        buildCampaignNewBarcodeExpiration ||
        buildCampaignMonetaryTitle ||
        buildCampaignMonetaryAmount ||
        buildCampaignMonetaryCurrency ||
        buildCampaignDiscountTitle ||
        buildCampaignDiscountProduct ||
        buildCampaignDiscountValue ||
        buildCampaignBonusTitle ||
        buildCampaignBonusProduct ||
        buildCampaignBonusGift ||
        buildCampaignNoveltyTitle ||
        buildCampaignNoveltyProduct
      ) {
        this.stepTabs[0].error = true;
      }
      if (costBalance) {
        this.stepTabs[3].error = true;
      }
    }
    if (this.props.tab !== prevProps.tab) {
      const tabID = this.stepTabs.findIndex(tab => tab.value === this.props.tab);
      if (tabID !== -1) this.stepTabs[tabID].error = false;
    }
    if (this.props.campaignViewers.is_editor !== prevProps.campaignViewers.is_editor) {
      [0, 1, 2, 3].map(id => (this.stepTabs[id].error = false));
    }
  }

  previewCampaign = () => {
    this.setState({ previewDialogOpen: true });
  };

  changeEditorRules = () => {
    const { setEditorRulesAreChanging } = this.props;
    setEditorRulesAreChanging();
  };

  finishEditing = () => {
    const { deleteEditor, clearEditingTimer, handleViewingInterval, postViewer } = this.props;
    const { campaignId } = this.state;
    this.changeEditorRules();
    deleteEditor(campaignId).then(() => {
      clearEditingTimer();
      handleViewingInterval();
      postViewer(campaignId);
    });
  };

  renderTooltip = (first_name, last_name, editor) => {
    const { t } = this.props;
    if (!editor) {
      return (
        <div className="tooltip_name">
          {first_name} {last_name}
        </div>
      );
    } else {
      return (
        <div className="tooltip_name">
          <p>
            {first_name} {last_name}
          </p>
          <span>{t("t:common.editor")}</span>
        </div>
      );
    }
  };

  render() {
    const {
      tab,
      campaignInfo: { status },
      campaignViewers: { viewers, editor, is_editor },
      setUrl,

      //editing/viewing actions
      handleEditingTimer,
      clearViewingInterval,
      t,
      editorRulesAreChanging
    } = this.props;
    const { previewDialogOpen, openUsersDialog } = this.state;
    let usersList = [...viewers];
    editor && usersList.unshift({ ...editor, editor: true });
    return (
      <>
        <div className="campaign_header">
          <div className="campaign_tabs">
            <BreadcrumbsTabs currentTab={tab} changeTab={setUrl} tabs={this.stepTabs} />
            <div className={clsx(["campaign-status", status])}>{t(`t:campaigns.${status}`)}</div>
            <Tabs currentTab={tab} changeTab={setUrl} tabs={this.tabs} />
          </div>

          <div className="campaign_controls_wrapper">
            <DefaultButton
              variant="contained"
              classes="edit_btn"
              onClick={this.previewCampaign}
              disabled={editorRulesAreChanging}
            >
              <img src={PreviewIcon} alt="preview" />
              {t("t:common.preview")}
            </DefaultButton>

            <div className="users_list">
              {usersList.slice(0, 3).map(({ id, first_name, last_name, editor }) => (
                <TooltipMessage
                  text={this.renderTooltip(first_name, last_name, editor)}
                  delay={100}
                  position="bottom"
                  classes="step_tooltip"
                  key={`user_icon_${id}`}
                >
                  <div className={clsx("user", editor && "editor")}>
                    {first_name.charAt(0)}
                    {last_name.charAt(0)}
                  </div>
                </TooltipMessage>
              ))}
              {usersList.length > 3 && (
                <div
                  className="user more_users"
                  onClick={() => this.setState({ openUsersDialog: true })}
                >
                  +{usersList.length - 3}
                </div>
              )}
            </div>

            {status === "inactive" && (
              <div>
                {is_editor ? (
                  <DefaultButton
                    variant="contained"
                    classes="done_btn"
                    onClick={this.finishEditing}
                    disabled={editorRulesAreChanging}
                  >
                    <img src={DoneIcon} alt="done" />
                    {t("t:deliveries.done")}
                  </DefaultButton>
                ) : (
                  <DefaultButton
                    variant="contained"
                    classes="save_btn"
                    onClick={() => {
                      this.changeEditorRules();
                      clearViewingInterval();
                      handleEditingTimer();
                    }}
                    disabled={editorRulesAreChanging}
                  >
                    <img src={EditIcon} alt="edit" />
                    {t("t:common.edit")}
                  </DefaultButton>
                )}
              </div>
            )}
          </div>
        </div>

        <DialogComponent
          open={previewDialogOpen}
          onClose={() => this.setState({ previewDialogOpen: false })}
          closeIcon
        >
          <div className="campaign_preview_dialog">
            <div className="dialog_title">{t("t:campaigns.campaign-preview")}</div>
            <Preview />
          </div>
        </DialogComponent>

        <DialogComponent
          open={openUsersDialog}
          onClose={() => this.setState({ openUsersDialog: false })}
          closeIcon
        >
          <div className="campaign_users_dialog">
            <div className="dialog_title">{t("t:common.users")}</div>
            <div className="users_list">
              {usersList.map(({ id, first_name, last_name, editor }) => (
                <div className="user_row" key={`user_${id}`}>
                  <div className="user">
                    <div className={clsx("user_icon", editor && "editor")}>
                      {first_name.charAt(0)}
                      {last_name.charAt(0)}
                    </div>
                    {first_name}&nbsp;{last_name}
                  </div>
                  <div className="user_role">
                    {editor ? t("t:common.editor") : t("t:common.viewer")}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DialogComponent>
      </>
    );
  }
}

function mapStateToProps({ campaign }) {
  return {
    campaignInfo: campaign.campaignInfo,
    campaignViewers: campaign.campaignViewers,
    publishValidationError: campaign.publishValidationError,
    editorRulesAreChanging: campaign.editorRulesAreChanging
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteEditor,
      postViewer,
      setEditorRulesAreChanging
    },
    dispatch
  );
}

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Header)))
);
