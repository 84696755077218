import { put, takeEvery, all } from "redux-saga/effects";
import { handleClientsActions } from "./profile";
import { handleBalanceLoader, handleBalanceSnacks } from "./balance";
import { handleSnack } from "./snacks";
import { handleAudienceLoader, handleAudienceSnacks } from "./audience";

export default function* rootSaga() {
  yield all([
    handleLoader(),
    handleSnack(),
    handleClientsActions(),
    handleBalanceLoader(),
    handleBalanceSnacks(),
    handleAudienceLoader(),
    handleAudienceSnacks()
  ]);
}

function* handleLoader() {
  yield takeEvery("GET_ANALYTICS", enableLoader);
  yield takeEvery("GET_ANALYTICS_SUCCESS", disableLoader);
  yield takeEvery("GET_ANALYTICS_FAIL", disableLoader);

  yield takeEvery("GET_PROFILE_INFO", enableLoader);
  yield takeEvery("GET_PROFILE_INFO_SUCCESS", disableLoader);
  yield takeEvery("GET_PROFILE_INFO_FAIL", disableLoader);

  yield takeEvery("POST_CONNECT_GOOGLE", enableLoader);
  yield takeEvery("POST_CONNECT_GOOGLE_SUCCESS", disableLoader);
  yield takeEvery("POST_CONNECT_GOOGLE_FAIL", disableLoader);

  yield takeEvery("POST_CONNECT_FACEBOOK", enableLoader);
  yield takeEvery("POST_CONNECT_FACEBOOK_SUCCESS", disableLoader);
  yield takeEvery("POST_CONNECT_FACEBOOK_FAIL", disableLoader);

  yield takeEvery("PATCH_PROFILE", enableLoader);
  yield takeEvery("PATCH_PROFILE_SUCCESS", disableLoader);
  yield takeEvery("PATCH_PROFILE_FAIL", disableLoader);

  yield takeEvery("POST_AVATAR", enableLoader);
  yield takeEvery("POST_AVATAR_SUCCESS", disableLoader);
  yield takeEvery("POST_AVATAR_FAIL", disableLoader);

  yield takeEvery("POST_NEW_EMAIL", enableLoader);
  yield takeEvery("POST_NEW_EMAIL_SUCCESS", disableLoader);
  yield takeEvery("POST_NEW_EMAIL_FAIL", disableLoader);

  yield takeEvery("POST_CODE", enableLoader);
  yield takeEvery("POST_CODE_SUCCESS", disableLoader);
  yield takeEvery("POST_CODE_FAIL", disableLoader);

  yield takeEvery("POST_CHANGE_PASSWORD", enableLoader);
  yield takeEvery("POST_CHANGE_PASSWORD_SUCCESS", disableLoader);
  yield takeEvery("POST_CHANGE_PASSWORD_FAIL", disableLoader);

  yield takeEvery("POST_SET_PASSWORD", enableLoader);
  yield takeEvery("POST_SET_PASSWORD_SUCCESS", disableLoader);
  yield takeEvery("POST_SET_PASSWORD_FAIL", disableLoader);

  yield takeEvery("POST_NEW_COMPANY", enableLoader);
  yield takeEvery("POST_NEW_COMPANY_SUCCESS", disableLoader);
  yield takeEvery("POST_NEW_COMPANY_FAIL", disableLoader);

  yield takeEvery("GET_COMPANY_DETAILS", enableLoader);
  yield takeEvery("GET_COMPANY_DETAILS_SUCCESS", disableLoader);
  yield takeEvery("GET_COMPANY_DETAILS_FAIL", disableLoader);

  yield takeEvery("PATCH_COMPANY_DETAILS", enableLoader);
  yield takeEvery("PATCH_COMPANY_DETAILS_SUCCESS", disableLoader);
  yield takeEvery("PATCH_COMPANY_DETAILS_FAIL", disableLoader);

  yield takeEvery("POST_ARCHIVE_COMPANY", enableLoader);
  yield takeEvery("POST_ARCHIVE_COMPANY_SUCCESS", disableLoader);
  yield takeEvery("POST_ARCHIVE_COMPANY_FAIL", disableLoader);

  yield takeEvery("POST_RESTORE_COMPANY", enableLoader);
  yield takeEvery("POST_RESTORE_COMPANY_SUCCESS", disableLoader);
  yield takeEvery("POST_RESTORE_COMPANY_FAIL", disableLoader);

  yield takeEvery("POST_LEAVE_COMPANY", enableLoader);
  yield takeEvery("POST_LEAVE_COMPANY_SUCCESS", disableLoader);
  yield takeEvery("POST_LEAVE_COMPANY_FAIL", disableLoader);

  yield takeEvery("SET_CURRENT_COMPANY", enableLoader);
  yield takeEvery("SET_CURRENT_COMPANY_SUCCESS", disableLoader);
  yield takeEvery("SET_CURRENT_COMPANY_FAIL", disableLoader);

  yield takeEvery("DELETE_CAMPAIGN", enableLoader);
  yield takeEvery("DELETE_CAMPAIGN_SUCCESS", disableLoader);
  yield takeEvery("DELETE_CAMPAIGN_FAIL", disableLoader);

  yield takeEvery("POST_ARCHIVE_CAMPAIGN", enableLoader);
  yield takeEvery("POST_ARCHIVE_CAMPAIGN_SUCCESS", disableLoader);
  yield takeEvery("POST_ARCHIVE_CAMPAIGN_FAIL", disableLoader);

  yield takeEvery("POST_RESTORE_CAMPAIGN", enableLoader);
  yield takeEvery("POST_RESTORE_CAMPAIGN_SUCCESS", disableLoader);
  yield takeEvery("POST_RESTORE_CAMPAIGN_FAIL", disableLoader);

  yield takeEvery("POST_LEAVE_CAMPAIGN", enableLoader);
  yield takeEvery("POST_LEAVE_CAMPAIGN_SUCCESS", disableLoader);
  yield takeEvery("POST_LEAVE_CAMPAIGN_FAIL", disableLoader);

  yield takeEvery("POST_STOP_CAMPAIGN", enableLoader);
  yield takeEvery("POST_STOP_CAMPAIGN_SUCCESS", disableLoader);
  yield takeEvery("POST_STOP_CAMPAIGN_FAIL", disableLoader);

  yield takeEvery("POST_BLOCK_USER_IN_COMPANY", enableLoader);
  yield takeEvery("POST_BLOCK_USER_IN_COMPANY_SUCCESS", disableLoader);
  yield takeEvery("POST_BLOCK_USER_IN_COMPANY_FAIL", disableLoader);

  yield takeEvery("POST_UNBLOCK_USER_IN_COMPANY", enableLoader);
  yield takeEvery("POST_UNBLOCK_USER_IN_COMPANY_SUCCESS", disableLoader);
  yield takeEvery("POST_UNBLOCK_USER_IN_COMPANY_FAIL", disableLoader);

  yield takeEvery("POST_REMOVE_USER_FROM_COMPANY", enableLoader);
  yield takeEvery("POST_REMOVE_USER_FROM_COMPANY_SUCCESS", disableLoader);
  yield takeEvery("POST_REMOVE_USER_FROM_COMPANY_FAIL", disableLoader);

  yield takeEvery("POST_BLOCK_USER_IN_ALL_CAMPAIGNS", enableLoader);
  yield takeEvery("POST_BLOCK_USER_IN_ALL_CAMPAIGNS_SUCCESS", disableLoader);
  yield takeEvery("POST_BLOCK_USER_IN_ALL_CAMPAIGNS_FAIL", disableLoader);

  yield takeEvery("POST_MAKE_A_REGULAR_USER", enableLoader);
  yield takeEvery("POST_MAKE_A_REGULAR_USER_SUCCESS", disableLoader);
  yield takeEvery("POST_MAKE_A_REGULAR_USER_FAIL", disableLoader);

  yield takeEvery("POST_MAKE_AN_OWNER", enableLoader);
  yield takeEvery("POST_MAKE_AN_OWNER_SUCCESS", disableLoader);
  yield takeEvery("POST_MAKE_AN_OWNER_FAIL", disableLoader);

  yield takeEvery("DELETE_INVITATION", enableLoader);
  yield takeEvery("DELETE_INVITATION_SUCCESS", disableLoader);
  yield takeEvery("DELETE_INVITATION_FAIL", disableLoader);

  yield takeEvery("GET_DATA_SOURCES_LIST", enableLoader);
  yield takeEvery("GET_DATA_SOURCES_LIST_SUCCESS", disableLoader);
  yield takeEvery("GET_DATA_SOURCES_LIST_FAIL", disableLoader);

  yield takeEvery("POST_NEW_DATA_SOURCE", enableLoader);
  yield takeEvery("POST_NEW_DATA_SOURCE_SUCCESS", disableLoader);
  yield takeEvery("POST_NEW_DATA_SOURCE_FAIL", disableLoader);

  yield takeEvery("POST_REGENERATE_API_KEY", enableLoader);
  yield takeEvery("POST_REGENERATE_API_KEY_SUCCESS", disableLoader);
  yield takeEvery("POST_REGENERATE_API_KEY_FAIL", disableLoader);

  yield takeEvery("GET_PRODUCTS", enableLoader);
  yield takeEvery("GET_PRODUCTS_SUCCESS", disableLoader);
  yield takeEvery("GET_PRODUCTS_FAIL", disableLoader);

  yield takeEvery("POST_ADD_PRODUCTS_FROM_API", enableLoader);
  yield takeEvery("POST_ADD_PRODUCTS_FROM_API_SUCCESS", disableLoader);
  yield takeEvery("POST_ADD_PRODUCTS_FROM_API_FAIL", disableLoader);

  yield takeEvery("POST_PUBLISH", enableLoader);
  yield takeEvery("POST_PUBLISH_SUCCESS", disableLoader);
  yield takeEvery("POST_PUBLISH_FAIL", disableLoader);

  yield takeEvery("GET_DELIVERIES", enableLoader);
  yield takeEvery("GET_DELIVERIES_SUCCESS", disableLoader);
  yield takeEvery("GET_DELIVERIES_FAIL", disableLoader);
}

export function* enableLoader() {
  yield put({ type: "SET_LOADING", payload: true });
}

export function* disableLoader() {
  yield put({ type: "SET_LOADING", payload: false });
}
