import { COMPANIES, CAMPAIGN } from "../constants";
import { getOption } from "../helpers/functions";

const INITIAL_STATE = {
  campaignCreationSuccess: false,
  campaignsOfCurrentCompany: [],
  campaigns: { load: true },
  managers: [],
  companyAddresses: [],
  euCountries: [],

  settingCompanyError: "",
  creatingCompanyError: "",

  companyOptions: {
    audience: {}
  },

  //profile tab
  companiesList: {},
  companyDetails: {},
  companyError: ""
};

const reduceCompanies = (state = INITIAL_STATE, action) => {
  let tempCampaignsResults = [];
  switch (action.type) {
    case COMPANIES.CREATE_CAMPAIGN_SUCCESS:
      return { ...state, campaignCreationSuccess: true };
    case COMPANIES.GET_CURRENT_COMPANY_CAMPAIGNS_SUCCESS:
      return { ...state, campaignsOfCurrentCompany: action.payload.data };
    case COMPANIES.GET_CAMPAIGNS_SUCCESS:
      return { ...state, campaigns: action.payload.data };
    case COMPANIES.GET_MANAGERS_SUCCESS:
      return { ...state, managers: action.payload.data };
    case COMPANIES.GET_EU_COUNTRIES_SUCCESS:
      return { ...state, euCountries: action.payload.data.country_phone_codes };
    case COMPANIES.GET_COMPANY_ADDRESSES_SUCCESS:
      return { ...state, companyAddresses: action.payload.data };

    case CAMPAIGN.PATCH_NAME_SUCCESS:
      if (state.campaigns.results) {
        tempCampaignsResults = [...state.campaigns.results];
        tempCampaignsResults[
          tempCampaignsResults.findIndex(el => el.id === action.payload.data.id)
        ] = action.payload.data;
      }
      return { ...state, campaigns: { ...state.campaigns, results: tempCampaignsResults } };

    // change state to published of chosen campaign without new request to API
    case CAMPAIGN.POST_PUBLISH_SUCCESS:
      if (state.campaigns.results) {
        tempCampaignsResults = [...state.campaigns.results];
        tempCampaignsResults[
          tempCampaignsResults.findIndex(el => el.id === action.payload.data.id)
        ] = action.payload.data;
      }
      return { ...state, campaigns: { ...state.campaigns, results: tempCampaignsResults } };

    case COMPANIES.SET_CURRENT_COMPANY_FAIL:
      return {
        ...state,
        settingCompanyError: action.error.response.data.non_field_errors[0].message
      };
    case COMPANIES.CREATE_CAMPAIGN_FAIL:
      let tempError = "";
      if (action.error.response.data.name) {
        tempError = action.error.response.data.name[0].message;
      } else if (action.error.response.data.company_name) {
        tempError = action.error.response.data.company_name[0].message;
      } else {
        tempError = action.error.response.data.non_field_errors[0].message;
      }
      return { ...state, creatingCompanyError: tempError };

    case COMPANIES.GET_COMPANIES_SUCCESS:
      return { ...state, companiesList: action.payload.data };
    case COMPANIES.GET_COMPANY_DETAILS_SUCCESS:
      return { ...state, companyDetails: action.payload.data };
    case COMPANIES.SET_COMPANY_ERROR:
      return {
        ...state,
        companyError: action.error
      };
    case COMPANIES.POST_NEW_COMPANY_FAIL:
    case COMPANIES.PATCH_COMPANY_DETAILS_FAIL:
      return { ...state, companyError: action.error.response.data };

    case COMPANIES.GET_COMPANY_OPTIONS_SUCCESS:
      return {
        ...state,
        companyOptions: {
          ...action.payload.data,
          audience: {
            csvList: action.payload.data.audience.csv.map(item => ({
              ...item,
              label: getOption(item.name, "no-classes"),
              value: item.id
            })),
            segmentList: action.payload.data.audience.segment.map(item => ({
              ...item,
              label: getOption(item.name, "no-classes"),
              value: item.id
            })),
            analyticsList: action.payload.data.audience.analytics.map(item => ({
              ...item,
              label: getOption(item.name, "no-classes"),
              value: item.id
            }))
          }
        }
      };
    case COMPANIES.RESET_COMPANY_OPTIONS_SUCCESS:
      return { ...state, companyOptions: INITIAL_STATE.companyOptions };

    case COMPANIES.RESET_ERRORS:
      return {
        ...state,
        settingCompanyError: "",
        creatingCompanyError: "",
        campaignCreationSuccess: false,
        companyError: ""
      };
    default:
      return state;
  }
};

export default reduceCompanies;
