import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Checkbox from "@material-ui/core/Checkbox";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

import { isDecimalNumberRegexp } from "../../../../../helpers/functions";
import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";

import ExpandMoreIcon from "../../../../../assets/image/down-arrow-purple.svg";
import CheckOn from "../../../../../assets/image/checkbox_checked.svg";
import CheckOff from "../../../../../assets/image/checkbox_unchecked.svg";
import Shape from "../../../../../assets/image/My_Campaign/Shape.svg";

const DeliveryCompanies = ({ delivery_companies, setDeliveryCompanies }) => {
  const smartPostSelected = delivery_companies.find(el => el.delivery_company === "smartpost");
  const omnivaSelected = delivery_companies.find(el => el.delivery_company === "omniva");
  const defaultValue = {
    price: "",
    checked: false
  };
  const [isOpen, setOpen] = useState(false);
  const [smartPost, setSmartPost] = useState(
    smartPostSelected ? { price: smartPostSelected.delivery_price, checked: true } : defaultValue
  );
  const [omniva, setOmniva] = useState(
    omnivaSelected ? { price: omnivaSelected.delivery_price, checked: true } : defaultValue
  );

  const { t } = useTranslation();

  const handleSelector = () => {
    if (isOpen) {
      let tempCompanies = [];
      if (smartPost.checked)
        tempCompanies.push({
          delivery_company: "smartpost",
          delivery_price: smartPost.price
        });
      if (omniva.checked)
        tempCompanies.push({
          delivery_company: "omniva",
          delivery_price: omniva.price
        });
      setDeliveryCompanies(tempCompanies);
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const renderSelected = () => {
    if (!delivery_companies.length) return "None";
    if (delivery_companies.length === 2) return "Itella SmartPost, Omniva";
    if (smartPostSelected) return "Itella SmartPost";
    if (omnivaSelected) return "Omniva";
    return "";
  };

  const onSmartpostCheck = () => setSmartPost({ ...smartPost, checked: !smartPost.checked });
  const onOmnivaCheck = () => setOmniva({ ...omniva, checked: !omniva.checked });

  const onSmartpostPriceInput = e => {
    if (e.target.value === "" || isDecimalNumberRegexp.test(+e.target.value)) {
      setSmartPost({ ...smartPost, price: e.target.value });
    }
  };
  const onOmnivaPriceInput = e => {
    if (e.target.value === "" || isDecimalNumberRegexp.test(+e.target.value)) {
      setOmniva({ ...omniva, price: e.target.value });
    }
  };

  return (
    <div className="delivery-companies">
      <span className="info">
        {t("t:companies.delivery-companies")}
        <TooltipMessage
          text={t("t:helper-texts.company-delivery-companies")}
          delay={200}
          position="top"
          classes="step_tooltip"
        >
          <img className="tooltip-question-icon" src={Shape} alt="Shape" />
        </TooltipMessage>
      </span>
      <div className="delivery-companies__selector" onClick={handleSelector}>
        {renderSelected()}
        <img
          src={ExpandMoreIcon}
          alt=">"
          className={clsx("delivery-companies__expand-icon", isOpen && "is-open")}
        />
      </div>
      {isOpen && (
        <ClickAwayListener onClickAway={handleSelector}>
          <div className="delivery-companies__options">
            <div className="delivery-companies__options--company">
              <Checkbox
                id="itella-checkbox"
                checked={smartPost.checked}
                onChange={onSmartpostCheck}
                checkedIcon={<img src={CheckOn} alt="check on" />}
                icon={<img src={CheckOff} alt="check off" />}
              />
              <div className="delivery-companies__options--info">
                <label htmlFor="itella-checkbox">Itella SmartPost</label>
                <span className="delivery-companies__options--input-label">
                  {t("t:distribution.delivery-price")}
                </span>
                <div className="input_wrapper delivery-companies__options--price-input">
                  <input type="text" value={smartPost.price} onChange={onSmartpostPriceInput} />
                </div>
              </div>
            </div>
            <hr className="delivery-companies__options--separator" />
            <div className="delivery-companies__options--company">
              <Checkbox
                id="omniva-checkbox"
                checked={omniva.checked}
                onChange={onOmnivaCheck}
                checkedIcon={<img src={CheckOn} alt="check on" />}
                icon={<img src={CheckOff} alt="check off" />}
              />
              <div className="delivery-companies__options--info">
                <label htmlFor="omniva-checkbox">Omniva</label>
                <span className="delivery-companies__options--input-label">
                  {t("t:distribution.delivery-price")}
                </span>
                <div className="input_wrapper delivery-companies__options--price-input">
                  <input type="text" value={omniva.price} onChange={onOmnivaPriceInput} />
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DeliveryCompanies;
