import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import { userAgreementError } from "../../helpers/errorMessages";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";
import Shape from "../../../../../assets/image/My_Campaign/Shape.svg";

const UserAgreement = ({ distributionError, isEditor, agreement, onSetFieldsInState }) => {
  const { t } = useTranslation();
  return (
    <div className="distribution_row_wrapper">
      <div className="row_name">
        {t("t:distribution.user-agreement")}
        {distributionError.agreement ? (
          <TooltipMessage
            text={userAgreementError(distributionError)}
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
      </div>
      <div className="row_content">
        <div className="input_title_block_my_campaign">
          <div>
            <span>{t("t:distribution.user-needs-to-agree-with")}</span>
            <TooltipMessage
              text={t("t:helper-texts.distribution-user-agreement")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <textarea
            placeholder={t("t:distribution.user-agreement-example")}
            disabled={!isEditor}
            className={clsx(!isEditor && "cursor_not_allowed")}
            value={agreement || ""}
            onChange={e => onSetFieldsInState({ agreement: e.target.value })}
          />
        </div>
      </div>
    </div>
  );
};

export default UserAgreement;
