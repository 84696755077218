import React from "react";
import { useTranslation } from "react-i18next";

import SkeletonComponent from "../../../../HelperComponents/SkeletonComponent/SkeletonComponent";
import HelpTooltip from "../../../../HelperComponents/HelpTooltip";
import { calculateFeeWithVAT, splitByCommas } from "../../../../../helpers/functions";
import Sharing from "../../Sharing";

const Published = ({
  loading,
  campaignId,
  already_paid,
  balance_left,
  initial_balance,
  max_participants,
  participants_finalized,
  participants_joined,
  total_estimated_cost,
  total_estimated_cost_based_on_people_joined,
  estimated_campaign_turnover_based_on_people_joined,
  total_estimated_cost_bonuses,
  total_estimated_fee,
  total_estimated_fb_sharing_cost,
  total_estimated_link_sharing_cost
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="cost_block">
        <div className="cost_marked_block">
          <div>
            <p>
              {t("t:cost.initial-balance")}
              <HelpTooltip helperTextsTransKey="cost-published-initial-balance" />
            </p>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <div>{splitByCommas(initial_balance || "0.00")}&nbsp;€</div>
            </SkeletonComponent>
          </div>
          <div>
            <p>
              {t("t:cost.balance-left")}
              <HelpTooltip helperTextsTransKey="cost-published-balance-left" />
            </p>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <div>{splitByCommas(balance_left || "0.00")}&nbsp;€</div>
            </SkeletonComponent>
          </div>
          <div>
            <p>
              {t("t:cost.already-paid")}
              <HelpTooltip helperTextsTransKey="cost-published-already-paid" />
            </p>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <div>{splitByCommas(already_paid || "0.00")}&nbsp;€</div>
            </SkeletonComponent>
          </div>
        </div>
        <div className="cost_block_info">
          <div className="totals">
            <div className="total_estimated_cost">
              <p>
                {t("t:cost.campaign-turnover-based-on-people")}
                <HelpTooltip helperTextsTransKey="cost-published-campaign-turnover-based-on-people-joined" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={250}>
                <div>
                  {splitByCommas(estimated_campaign_turnover_based_on_people_joined || "0.00")}
                  &nbsp;€
                </div>
              </SkeletonComponent>
            </div>
            <div className="total_estimated_cost">
              <p>
                {t("t:cost.campaign-cost-based-on-people")}
                <HelpTooltip helperTextsTransKey="cost-published-campaign-cost-based-on-people-joined" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={250}>
                <div>
                  {splitByCommas(total_estimated_cost_based_on_people_joined || "0.00")}
                  &nbsp;€
                </div>
              </SkeletonComponent>
            </div>
          </div>
          <div className="split_info">
            <div>
              <p>
                {t("t:cost.participants-joined")}
                <HelpTooltip helperTextsTransKey="cost-published-participants-joined" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>
                  <span>{participants_joined}</span> {t("t:common.one-OF-two")} {max_participants}
                </div>
              </SkeletonComponent>
            </div>
            <div>
              <p>
                {t("t:cost.participants-finalized-the-campaign")}
                <HelpTooltip helperTextsTransKey="cost-published-participants-finalized-the-campaign" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>
                  <span>{participants_finalized}</span> of {participants_joined}
                </div>
              </SkeletonComponent>
            </div>
          </div>
        </div>
      </div>
      <div className="cost_block">
        <div className="cost_block_title">{t("t:cost.estimated")}</div>
        <div className="cost_block_info">
          <div className="total_estimated_cost">
            <p>{t("t:cost.estimated-campaign-cost")}</p>
            <SkeletonComponent variant="text" loading={loading} width={250}>
              <div className="inactive">{splitByCommas(total_estimated_cost || "0.00")}&nbsp;€</div>
            </SkeletonComponent>
          </div>
          <div className="split_info mb-32">
            <div>
              <p>{t("t:cost.cost-of-bonuses")}</p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>{splitByCommas(total_estimated_cost_bonuses || "0.00")}&nbsp;€</div>
              </SkeletonComponent>
            </div>
            <div>
              <p>{t("t:cost.maximum-participants")}</p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>{max_participants}</div>
              </SkeletonComponent>
            </div>
            <div>
              <p>{t("t:cost.fee")}</p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>{splitByCommas(calculateFeeWithVAT(total_estimated_fee))}&nbsp;€</div>
              </SkeletonComponent>
            </div>
          </div>
          <div className="split_info">
            {/*<div>*/}
            {/*    <p>{t("t:cost.fb-sharing-cost")}</p>*/}
            {/*    <SkeletonComponent variant="text" loading={loading} width={100}>*/}
            {/*        <div>{splitByCommas(total_estimated_fb_sharing_cost || "0.00")}&nbsp;€</div>*/}
            {/*    </SkeletonComponent>*/}
            {/*</div>*/}
            <div>
              <p>{t("t:cost.link-sharing-cost")}</p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>
                  {splitByCommas(total_estimated_link_sharing_cost || "0.00")}
                  &nbsp;€
                </div>
              </SkeletonComponent>
            </div>
          </div>
        </div>
      </div>
      {(total_estimated_fb_sharing_cost !== "0.00" ||
        total_estimated_link_sharing_cost !== "0.00") && <Sharing campaignId={campaignId} />}
    </>
  );
};

export default Published;
