import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import AuthReducer from "./reduceAuth";
import DashboardReducer from "./reduceDashboard";
import UsersReducer from "./reduceUsers";
import CompaniesReducer from "./reduceCompanies";
import CampaignReducer from "./reduceCampaign";
import ProductsReducer from "./reduceProducts";
import ProfileReducer from "./reduceProfile";
import DataSourcesReducer from "./reduceDataSources";
import BalanceReducer from "./reduceBalance";
import AudienceReducer from "./reduceAudience";
import DeliveriesReducer from "./reduceDeliveries";
import BillingReportsReducer from "./reduceBillingReports";
import CompanyApiAccessReducer from "./reduceCompanyApiAccess";
import { reducer as formReducer } from "redux-form";

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth: AuthReducer,
    dashboard: DashboardReducer,
    users: UsersReducer,
    companies: CompaniesReducer,
    campaign: CampaignReducer,
    products: ProductsReducer,
    profile: ProfileReducer,
    dataSources: DataSourcesReducer,
    balance: BalanceReducer,
    audience: AudienceReducer,
    deliveries: DeliveriesReducer,
    billingReports: BillingReportsReducer,
    companyApiAccess: CompanyApiAccessReducer
  });

export default rootReducer;
