import { takeEvery, put } from "redux-saga/effects";
import { findAnError } from "../helpers/functions";

export function* handleSnack() {
  yield takeEvery("PATCH_DELIVERY_INFO_FAIL", () =>
    openErrorSnack("t:snack-messages.edit-delivery-fail")
  );
  yield takeEvery("POST_DELIVERY_DELIVER_TO_PICKUP_STATUS_FAIL", () =>
    openErrorSnack("t:snack-messages.edit-delivery-fail")
  );
  yield takeEvery("POST_DELIVERY_READY_FOR_PICKUP_STATUS_FAIL", () =>
    openErrorSnack("t:snack-messages.edit-delivery-fail")
  );
  yield takeEvery("POST_DELIVERY_NEEDS_DELIVERY_STATUS_FAIL", () =>
    openErrorSnack("t:snack-messages.edit-delivery-fail")
  );
  yield takeEvery("POST_DELIVERY_ON_DELIVERY_STATUS_FAIL", () =>
    openErrorSnack("t:snack-messages.edit-delivery-fail")
  );
  yield takeEvery("POST_DELIVERY_REDEEMED_STATUS_FAIL", () =>
    openErrorSnack("t:snack-messages.edit-delivery-fail")
  );
  yield takeEvery("POST_DELIVERY_DONE_STATUS_FAIL", () =>
    openErrorSnack("t:snack-messages.edit-delivery-fail")
  );
  yield takeEvery("POST_RESTORE_CAMPAIGN_SUCCESS", () =>
    openSuccessSnack("campaign-restored-success")
  );
  yield takeEvery("POST_ARCHIVE_CAMPAIGN_SUCCESS", () =>
    openSuccessSnack("campaign-archived-success")
  );
  yield takeEvery("POST_NEW_COMPANY_SUCCESS", () => openSuccessSnack("company-created-success"));
  yield takeEvery("POST_RESTORE_COMPANY_SUCCESS", () =>
    openSuccessSnack("company-restored-success")
  );
  yield takeEvery("POST_ARCHIVE_COMPANY_SUCCESS", () =>
    openSuccessSnack("company-archived-success")
  );
  yield takeEvery("PATCH_COMPANY_DETAILS_SUCCESS", () =>
    openSuccessSnack("company-edited-success")
  );
  yield takeEvery("POST_PRODUCT_SUCCESS", () => openSuccessSnack("product-created-success"));
  yield takeEvery("PATCH_PRODUCT_SUCCESS", () => openSuccessSnack("product-updated-success"));
  yield takeEvery("POST_CODE_SUCCESS", () => openSuccessSnack("email-changed-success"));
  yield takeEvery("POST_CHANGE_PASSWORD_SUCCESS", () =>
    openSuccessSnack("password-changed-success")
  );
  yield takeEvery("POST_SET_PASSWORD_SUCCESS", () => openSuccessSnack("password-set-success"));
  yield takeEvery("POST_CONNECT_GOOGLE_SUCCESS", () =>
    openSuccessSnack("google-connected-success")
  );
  yield takeEvery("POST_DISCONNECT_GOOGLE_SUCCESS", () =>
    openSuccessSnack("google-disconnected-success")
  );
  yield takeEvery("POST_CONNECT_FACEBOOK_SUCCESS", () =>
    openSuccessSnack("facebook-connected-success")
  );
  yield takeEvery("POST_DISCONNECT_FACEBOOK_SUCCESS", () =>
    openSuccessSnack("facebook-disconnected-success")
  );
  yield takeEvery("PATCH_PROFILE_SUCCESS", () => openSuccessSnack("profile-edited-success"));
  yield takeEvery("POST_DUPLICATE_CAMPAIGN_SUCCESS", () =>
    openSuccessSnack("campaign-duplicated-success")
  );
  yield takeEvery("PATCH_DATA_SOURCE_SUCCESS", () => openSuccessSnack("successfully-changed"));
  yield takeEvery("FILE_TYPE_ERROR", () => openErrorSnack("t:snack-messages.file-type-fail"));
  yield takeEvery("FILE_SIZE_ERROR", () => openErrorSnack("t:snack-messages.file-size-fail"));
  yield takeEvery("POST_AVATAR_FAIL", () => openErrorSnack("t:snack-messages.file-type-fail"));
  yield takeEvery("POST_DISCONNECT_GOOGLE_FAIL", () =>
    openErrorSnack("t:snack-messages.account-exists-fail")
  );
  yield takeEvery("POST_DISCONNECT_FACEBOOK_FAIL", () =>
    openErrorSnack("t:snack-messages.account-exists-fail")
  );
  yield takeEvery("POST_DISCONNECT_APPLE_FAIL", () =>
    openErrorSnack("t:snack-messages.account-exists-fail")
  );
  yield takeEvery("POST_CONNECT_APPLE_FAIL", openErrorSnack);
  yield takeEvery("POST_CONNECT_GOOGLE_FAIL", () =>
    openErrorSnack("t:snack-messages.google-account-exists-fail")
  );
  yield takeEvery("POST_CONNECT_FACEBOOK_FAIL", () =>
    openErrorSnack("t:snack-messages.facebook-account-exists-fail")
  );
  yield takeEvery("POST_DUPLICATE_CAMPAIGN_FAIL", () =>
    openErrorSnack("t:snack-messages.campaign-exists-error")
  );
  yield takeEvery("POST_HASHTAG_FAIL", openErrorSnack);
}

export function* openErrorSnack(e) {
  yield put({
    type: "ERROR_SNACK_OPEN",
    payload:
      e && e.error && e.error.response.data
        ? findAnError(e.error.response.data)
        : e && e.payload
        ? e.payload
        : e
        ? e
        : "t:snack-messages.fallback-error"
  });
}

export function* openSuccessSnack(e) {
  yield put({ type: "SUCCESS_SNACK_OPEN", payload: e });
}
