import React from "react";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import clsx from "clsx";
import { Image as CloudinaryImage } from "cloudinary-react";

import { renderStepPlaceholder } from "../../../../../../helpers/functions";
import TooltipMessage from "../../../../../HelperComponents/TooltipMessage/TooltipMessage";
import CharactersLeft from "../../../../../HelperComponents/CharactersLeft";
import DefaultButton from "../../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Dropzone from "../../../../../HelperComponents/DropzoneComponent/DropzoneComponent";
import Snack from "../../../../../HelperComponents/Snack/Snack";
import DialogComponent from "../../../../../HelperComponents/DialogComponent/DialogComponent";
import CropperComponent from "../../../../../HelperComponents/CropperComponent";

import quiz from "../../../../../../assets/image/My_Campaign/quiz.svg";
import cancel from "../../../../../../assets/image/My_Campaign/btn-cancel.svg";
import trash from "../../../../../../assets/image/My_Campaign/btn-trash.svg";
import save from "../../../../../../assets/image/My_Campaign/btn-save.svg";
import Shape from "../../../../../../assets/image/My_Campaign/Shape.svg";
import ErrorIcon from "../../../../../../assets/image/c-warning-e.svg";
import eye from "../../../../../../assets/image/My_Campaign/eye.svg";
import del from "../../../../../../assets/image/My_Campaign/del.svg";
import drag from "../../../../../../assets/image/My_Campaign/drag.svg";

const QuizExpandedView = ({
  type,
  detail,
  title,
  description,
  available_answers,
  openMaxAnswersSnack,
  openErrorSnack,
  openMinimumAnswersSnack,
  openErrorImageSnack,
  image,
  imagePreview,
  previewDialogOpen,
  imageToCrop,
  campaignError,
  isEditor,
  onDragEnd,
  deleteStep,
  changeStep,
  onTitleChange,
  changeState,
  deleteAnswer,
  changeAnswer,
  changeCorrectAnswer,
  addAnswer,
  onFinishCroppingImage,
  onCancelCropping,
  handleFiles,
  toClosePopper
}) => {
  const { t } = useTranslation();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="block_wrapper">
        <div className="head_block">
          <div className="title_head">
            <div>
              <img src={quiz} alt="quiz" />
            </div>
            <div>
              <p>{t("t:build-campaign.quiz")}</p>
              <span>{renderStepPlaceholder(detail.title, type, t)}</span>
            </div>
          </div>
          {isEditor ? (
            <div className="btn_head">
              <button onClick={deleteStep}>
                <img src={cancel} alt="cancel" />
              </button>
              <button onClick={toClosePopper}>
                <img src={trash} alt="trash" />
              </button>
              <button onClick={changeStep}>
                <img src={save} alt="save" />
              </button>
            </div>
          ) : (
            <div className="btn_head">
              <button onClick={toClosePopper}>
                <img src={trash} alt="trash" />
              </button>
            </div>
          )}
        </div>
        <div className="input_title_block_my_campaign no-padding-bottom">
          <div>
            <span>{`${t("t:build-campaign.question-title")} *`}</span>
            <TooltipMessage
              text={t("t:helper-texts.build-quiz-title")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <div className="step_input_wrapper">
            <input
              disabled={!isEditor}
              type="text"
              placeholder={renderStepPlaceholder(detail.title, type, t)}
              value={title}
              onChange={e => onTitleChange(e.target.value)}
            />
            {campaignError.title ? (
              <TooltipMessage
                text={campaignError.title[0].message}
                delay={200}
                error
                position="top"
                classes=""
              >
                <img src={ErrorIcon} alt="error" />
              </TooltipMessage>
            ) : (
              ""
            )}
          </div>
          <CharactersLeft title={title} maxCharacters={50} />
        </div>
        <div className="textarea_description_block_my_campaign">
          <div>
            <span>{t("t:common.description")}</span>
            <TooltipMessage
              text={t("t:helper-texts.build-quiz-description")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <div className="step_input_wrapper">
            <textarea
              disabled={!isEditor}
              value={description}
              onChange={e => changeState({ description: e.target.value })}
            />
            {campaignError.description ? (
              <TooltipMessage
                text={campaignError.description[0].message}
                delay={200}
                error
                position="top"
                classes=""
              >
                <img src={ErrorIcon} alt="error" />
              </TooltipMessage>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="answers_block">
          <div className="title">
            <span>{`${t("t:build-campaign.answers")} *`}</span>
          </div>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
                {getItems(
                  available_answers,
                  deleteAnswer,
                  changeAnswer,
                  changeCorrectAnswer,
                  t("t:build-campaign.correct-answer"),
                  isEditor
                ).map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={!isEditor}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        className={clsx(snapshot.isDragging && "is_dragging")}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {isEditor && (
            <div className="btn_wrapper">
              <DefaultButton variant="contained" classes="add_answer" onClick={addAnswer}>
                {t("t:build-campaign.add-answer")}
              </DefaultButton>
            </div>
          )}
        </div>
        <div className="images_add">
          <div className="title">
            <span>{t("t:common.image")}</span>
            <TooltipMessage
              text={
                <p>
                  {t("t:helper-texts.build-quiz-image")}
                  <br />
                  {t("t:helper-texts.aspect-ratio-and-min-res", {
                    aspect_ratio: "16:10",
                    min_resolution: "1280x800"
                  })}
                </p>
              }
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          {image && (
            <div className="drag_img">
              <p>
                {imagePreview ? (
                  <img src={imagePreview} alt="upload" />
                ) : (
                  <CloudinaryImage
                    cloudName="campy"
                    width="25"
                    height="25"
                    gravity="face"
                    crop="thumb"
                    publicId={image.split("/").pop()}
                  />
                )}
              </p>
              <div>
                {image.name ||
                  image
                    .split("/")
                    .pop()
                    .slice(0, 49)}
              </div>
              <button onClick={() => changeState({ previewDialogOpen: true })}>
                <img src={eye} alt="eye" />
              </button>
              {isEditor && (
                <button
                  onClick={() =>
                    changeState({
                      image: null,
                      imagePreview: null,
                      blobToDownload: null
                    })
                  }
                >
                  <img src={del} alt="del" />
                </button>
              )}
            </div>
          )}
          {isEditor && (
            <Dropzone
              placeholder={t("t:common.drop-your-image-here")}
              multiple={false}
              handleFiles={handleFiles}
            />
          )}
        </div>
        <Snack
          open={openMaxAnswersSnack}
          handleSnack={() => changeState({ openMaxAnswersSnack: false })}
          message={t("t:snack-messages.answers-number-error")}
          type="error"
        />
        <Snack
          open={openErrorSnack}
          handleSnack={() => changeState({ openErrorSnack: false })}
          message={t("t:snack-messages.incorrect-answers-error")}
          type="error"
        />
        <Snack
          open={openMinimumAnswersSnack}
          handleSnack={() => changeState({ openMinimumAnswersSnack: false })}
          message={t("t:snack-messages.min-two-answers-error")}
          type="error"
        />
        <Snack
          open={openErrorImageSnack}
          handleSnack={() => changeState({ openErrorImageSnack: false })}
          message={t("t:snack-messages.file-type-fail")}
          type="error"
        />
        <DialogComponent
          open={previewDialogOpen}
          onClose={() => changeState({ previewDialogOpen: false })}
          closeIcon
          classes="image_dialog"
          rootClass="image_root_dialog"
        >
          <div>
            <img src={imagePreview ? imagePreview : image} alt="preview" />
          </div>
        </DialogComponent>
        {imageToCrop && (
          <CropperComponent
            aspectRatio={1.6}
            imageToCrop={imageToCrop}
            onFinishCropping={onFinishCroppingImage}
            onCancelCropping={onCancelCropping}
          />
        )}
      </div>
    </DragDropContext>
  );
};

const getItems = (
  array,
  deleteAnswer,
  changeAnswer,
  changeCorrectAnswer,
  correctAnswerText,
  isEditor
) =>
  array.map((el, key) => ({
    id: `item_${key}`,
    content: renderStep(
      el,
      key,
      array.length,
      deleteAnswer,
      changeAnswer,
      changeCorrectAnswer,
      correctAnswerText,
      isEditor
    )
  }));

const renderStep = (
  el,
  key,
  length,
  deleteAnswer,
  changeAnswer,
  changeCorrectAnswer,
  correctAnswerText,
  isEditor
) => {
  return (
    <div className="drag_info">
      <span>
        <img src={drag} alt="drag" />
      </span>
      <div className="radio-button" onClick={() => isEditor && changeCorrectAnswer(key)}>
        <input
          type="radio"
          name="single"
          checked={el.is_correct}
          onChange={() => console.log("changed")}
        />
        <label htmlFor="radio1"></label>
      </div>
      <div className="input-with-adornment">
        <input
          disabled={!isEditor}
          type="text"
          value={el.answer}
          onChange={e => changeAnswer(e, key)}
          className={el.is_correct ? "correct-one" : ""}
        />
        {!!el.is_correct && <div className="correct-answer">{correctAnswerText}</div>}
      </div>
      {length > 2 && isEditor && (
        <button onClick={() => deleteAnswer(key)}>
          <img src={del} alt="del" />
        </button>
      )}
    </div>
  );
};

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    userSelect: "none",
    padding: 0,
    margin: 0,
    background: "transperent",
    border: "none",
    // styles we need to apply on draggables
    ...draggableStyle
  };
};

const getListStyle = () => ({
  background: "transparent",
  padding: 0,
  width: "auto",
  margin: 0
});

export default QuizExpandedView;
