import { DATA_SOURCES } from "../constants";

const INITIAL_STATE = {
  dataSourcesList: [],
  dataSource: {},
  testResponse: {},
  apiKey: "",
  products: {}
};

const reduceDataSources = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DATA_SOURCES.GET_DATA_SOURCES_LIST_SUCCESS:
      return { ...state, dataSourcesList: action.payload.data };
    case DATA_SOURCES.POST_NEW_DATA_SOURCE_SUCCESS:
      return { ...state, dataSource: action.payload.data };
    case DATA_SOURCES.PATCH_DATA_SOURCE_SUCCESS:
      return { ...state, dataSource: action.payload.data };
    case DATA_SOURCES.GET_DATA_SOURCE_SUCCESS:
      return { ...state, dataSource: action.payload.data };
    case DATA_SOURCES.POST_REGENERATE_API_KEY_SUCCESS:
      return { ...state, apiKey: action.payload.data.api_key };
    case DATA_SOURCES.GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload.data };
    case DATA_SOURCES.GET_PRODUCTS_FAIL:
      return { ...state, products: action.error.response.data };
    case DATA_SOURCES.RESET_PRODUCTS:
      return { ...state, products: {} };
    case DATA_SOURCES.RESET_DATA_SOURCES:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reduceDataSources;
