import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { calculatePercentOf } from "../../helpers";

import YesNoIcon from "../../../../../assets/image/CampaignPanel/path_green.svg";

const YesNo = () => {
  const { t } = useTranslation();

  const { yes, no } = useSelector(({ campaign }) => campaign.stepResults.yes_no);

  return (
    <div className="step-results__block">
      <img src={YesNoIcon} alt="yes/no" className="step-results__icon" />
      <div className="step-results__info">
        <div className="step-results__info--header">
          <div className="step-results__info--title">{t("t:build-campaign.yes-no")}</div>
        </div>
        <div className="step-results__info--bars">
          <div className="step-results__info--bars-row">
            <div className="step-results__info--bars-label">{t("t:common.yes")}</div>
            <div className="step-results__info--bars-bar">
              <div
                className="step-results__info--bars-filled"
                style={{ width: `${calculatePercentOf(yes, yes + no)}%` }}
              />
            </div>
            <div className="step-results__info--bars-value">{yes}</div>
          </div>
          <div className="step-results__info--bars-row">
            <div className="step-results__info--bars-label">{t("t:common.no")}</div>
            <div className="step-results__info--bars-bar">
              <div
                className="step-results__info--bars-filled"
                style={{ width: `${calculatePercentOf(no, yes + no)}%` }}
              />
            </div>
            <div className="step-results__info--bars-value">{no}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(YesNo);
