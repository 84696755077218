import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Companies from "./Companies/Companies";
import General from "./General/General";
import Tabs from "../HelperComponents/Tabs";

import { setConnectAppleError } from "../../actions/profileActions";

import "./Profile.scss";

class Profile extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search.substring(1));
    this.tabs = [
      { label: props.t("t:profile.general"), value: "general" },
      { label: props.t("t:common.companies"), value: "companies" }
    ];
    this.state = {
      tab: params.get("tab") || "general"
    };
  }

  componentDidMount() {
    const { location, setConnectAppleError } = this.props;
    const params = new URLSearchParams(location.search.substring(1));
    const error = params.get("error");
    if (error) {
      if (error === "profile_already_exists") {
        setConnectAppleError("account-has-its-profile-error");
      } else {
        setConnectAppleError("fallback-error");
      }
      this.changeTab("general");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      const params = new URLSearchParams(this.props.location.search.substring(1));
      this.changeTab(params.get("tab"));
    }
  }

  changeTab = tab => {
    const { history } = this.props;
    this.setState({ tab });
    history.push(`/main/profile/?tab=${tab}`);
  };

  render() {
    const { history, t } = this.props;
    const { tab } = this.state;
    return (
      <div className="page_wrapper">
        <div className="profile_wrapper">
          <div className="page_title">{t("t:profile.my-profile")}</div>
          <div className="profile_tabs_wrapper">
            <Tabs currentTab={tab} changeTab={this.changeTab} tabs={this.tabs} />
          </div>
          {tab === "general" ? <General /> : <Companies history={history} />}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setConnectAppleError
};

export default connect(null, mapDispatchToProps)(withTranslation()(Profile));
