import React, { Component } from "react";
import { Map, Marker } from "google-maps-react";
import GoogleMapLoader from "react-google-maps-loader";
import { withTranslation } from "react-i18next";

import { GOOGLE_API_KEY } from "../../../config";
import GoogleSuggest from "../GoogleSuggest/GoogleSuggest";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";

class GooglePickupAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.defaultAddress ? props.defaultAddress.address : "",
      coordinates: props.defaultAddress
        ? { lat: props.defaultAddress.lat, lng: props.defaultAddress.lng }
        : {}
    };
  }

  onMapClicked = (mapProps, map, clickEvent) => {
    const { t } = this.props;
    const latlng = {
      lat: clickEvent.latLng.lat(),
      lng: clickEvent.latLng.lng()
    };
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.setState({
            coordinates: { ...latlng },
            address: results[0].formatted_address
          });
        } else {
          window.alert(t("t:common.no-results-found"));
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  };

  handleLocation = (geocodedLocation, originalLocation) => {
    const originalAddressQuery = originalLocation?.structured_formatting?.main_text || null;
    this.setState({
      coordinates: {
        lat: geocodedLocation.geometry.location.lat(),
        lng: geocodedLocation.geometry.location.lng()
      },
      address: `${originalAddressQuery ? `${originalAddressQuery}, ` : ""}${
        geocodedLocation.formatted_address
      }`
    });
  };

  render() {
    const { defaultAddress, changeAddress, closeDialog, loading, t } = this.props;
    const { coordinates, address } = this.state;
    return (
      <GoogleMapLoader
        params={{
          key: GOOGLE_API_KEY,
          libraries: "places, geocode, maps",
          language: "en"
        }}
        render={googleMaps =>
          googleMaps && (
            <div className="google_pickup_wrapper">
              <span>{`${t("t:common.address")} *`}</span>
              <GoogleSuggest
                address={address}
                handleLocation={this.handleLocation}
                googleMaps={googleMaps}
                campaignError={{}}
                placeholder=""
                shouldAddressUpdate={true}
              />
              <span>{t("t:common.or-select-area-on-map")}</span>
              <div className="pickup_map_wrapper">
                <Map
                  google={window.google}
                  zoom={14}
                  streetViewControl={false}
                  initialCenter={coordinates.lat ? coordinates : { lat: 59.43, lng: 24.75 }}
                  center={coordinates}
                  onClick={this.onMapClicked}
                >
                  <Marker name={"Current location"} position={coordinates} />
                </Map>
              </div>
              <div className="buttons_wrapper">
                <DefaultButton
                  variant="contained"
                  classes="auth"
                  onClick={() => changeAddress(address, coordinates)}
                  disabled={!coordinates.lat || loading}
                  loading={loading}
                >
                  {defaultAddress ? t("t:common.save") : t("t:common.add")}
                </DefaultButton>
                <DefaultButton variant="contained" classes="cancel_btn" onClick={closeDialog}>
                  {t("t:common.cancel")}
                </DefaultButton>
              </div>
            </div>
          )
        }
      />
    );
  }
}

export default withTranslation()(GooglePickupAddress);
