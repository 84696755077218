import React, { Component, Fragment } from "react";
import { GoogleLogin } from "react-google-login";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { postGoogleAuth } from "../../../../actions/authActions";
import { postConnectGoogle } from "../../../../actions/profileActions";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Snack from "../../../HelperComponents/Snack/Snack";

import { GoogleClientId } from "../../../../config";

class GoogleAuth extends Component {
  state = {
    openSnack: false,
    openCookiesSnack: false
  };

  responseGoogle = response => {
    const { postGoogleAuth, postConnectGoogle, history } = this.props;
    const data = {
      access_token: response.accessToken
    };
    if (!this.props.history.location.pathname.includes("profile")) {
      postGoogleAuth(data).then(res => {
        if (
          res.payload &&
          res.payload.status &&
          (res.payload.status === 200 || res.payload.status === 201)
        ) {
          localStorage.token = res.payload.data.key;
          history.push("/main");
        } else {
          this.setState({ openSnack: true });
        }
      });
    } else {
      postConnectGoogle(data).then(res => {
        if (
          res.payload &&
          res.payload.status &&
          (res.payload.status === 200 || res.payload.status === 201)
        ) {
          localStorage.token = res.payload.data.key;
        }
      });
    }
  };

  render() {
    const { label, variant, classes, t } = this.props;
    const { openSnack, openCookiesSnack } = this.state;
    return (
      <Fragment>
        <GoogleLogin
          clientId={GoogleClientId}
          buttonText=""
          autoLoad={false}
          onSuccess={this.responseGoogle}
          onFailure={response => {
            console.log(response);
            if (response.error === "popup_closed_by_user") {
              this.setState({ openCookiesSnack: true });
            }
          }}
          onAutoLoadFinished={this.doNothing}
          cookiePolicy={"single_host_origin"}
          render={renderProps => (
            <DefaultButton
              variant={variant || "contained"}
              classes={classes || "google"}
              onClick={() => renderProps.onClick()}
            >
              {label || "Google"}
            </DefaultButton>
          )}
        />
        <Snack
          open={openSnack}
          handleSnack={() => this.setState({ openSnack: false })}
          message={t("t:auth.google-user-is-already-registered")}
          type="error"
        />
        <Snack
          open={openCookiesSnack}
          handleSnack={() => this.setState({ openCookiesSnack: false })}
          message={t("t:auth.google-please-enable-cookies")}
          type="error"
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  postGoogleAuth,
  postConnectGoogle
};

export default connect(null, mapDispatchToProps)(withRouter(withTranslation()(GoogleAuth)));
