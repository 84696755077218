import React, { memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import SelectComponent from "../../../../HelperComponents/SelectComponent/SelectComponent";
import { getOption } from "../../../../../helpers/functions";
import { getCompanyOptions, resetCompanyOptions } from "../../../../../actions/companiesActions";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";
import Shape from "../../../../../assets/image/My_Campaign/Shape.svg";

const CustomAudiences = ({
  audienceError,
  isEditor,
  handleAudienceChange,
  handleChange,
  customerRanking,
  audience
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { audience: audienceLists } = useSelector(({ companies }) => companies.companyOptions);

  useEffect(() => {
    dispatch(getCompanyOptions());
    return () => {
      dispatch(resetCompanyOptions());
    };
    // eslint-disable-next-line
  }, []);

  const { limitAudienceList } = useMemo(
    () => ({
      limitAudienceList: [
        {
          label: getOption(t("t:campaign-audience.no-limit"), "no-classes"),
          value: "",
          type: null
        },
        {
          label: getOption(t("t:campaign-audience.client-lists-from-csv"), "no-classes"),
          value: "csv",
          type: "csv"
        },
        {
          label: getOption(t("t:campaign-audience.custom-segments"), "no-classes"),
          value: "segment",
          type: "segment"
        },
        {
          label: getOption(t("t:campaign-audience.clients-based-on-analytics"), "no-classes"),
          value: "analytics",
          type: "analytics"
        }
      ]
    }),
    // eslint-disable-next-line
    []
  );

  const { limitAudience, audienceList, audienceListOptions } = useMemo(() => {
    let tempAudienceListOptions = [];
    if (audience && !_isEmpty(audienceLists)) {
      switch (audience.type) {
        case "csv":
          tempAudienceListOptions = audienceLists.csvList;
          break;
        case "segment":
          tempAudienceListOptions = audienceLists.segmentList;
          break;
        case "analytics":
          tempAudienceListOptions = audienceLists.analyticsList;
          break;
        default:
          break;
      }
    }
    return {
      limitAudience: audience
        ? limitAudienceList.find(el => el.value === audience.type)
        : limitAudienceList[0],
      audienceList:
        audience && audience.id ? tempAudienceListOptions.find(el => el.value === audience.id) : "",
      audienceListOptions: tempAudienceListOptions
    };
    // eslint-disable-next-line
  }, [JSON.stringify(audience), JSON.stringify(audienceLists)]);

  const onAudienceChange = name => event => {
    if (name === "type" && !event.value) {
      handleAudienceChange(null);
    } else if (name === "type") {
      handleAudienceChange({
        type: event.type
      });
    } else {
      handleAudienceChange({
        ...audience,
        ..._omit(event, ["label", "value"])
      });
    }
  };

  return (
    <div className="audience_row_wrapper">
      <div className="row_name">
        {t("t:campaign-audience.custom-audiences")}
        {audienceError.customer_ranking_from ? (
          <TooltipMessage
            text={audienceError.customer_ranking_from[0].message}
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
      </div>
      <div className="row_content">
        <div className="audience_limit">
          <div className="input_title_campaign">
            <p>{t("t:campaign-audience.use-audience-list")}</p>
            <TooltipMessage
              text={t("t:helper-texts.audience-audience-use-audience-list")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <FormControl className={clsx("select_wrapper", !isEditor && "cursor_not_allowed")}>
            <SelectComponent
              disabled={!isEditor}
              value={limitAudience}
              options={limitAudienceList}
              change={onAudienceChange("type")}
              isClearable="false"
              isSearchable={false}
              placeholder={t("t:common.choose")}
            />
          </FormControl>
          {limitAudience.value && (
            <FormControl
              className={clsx("select_wrapper", !isEditor && "cursor_not_allowed")}
              style={{ marginTop: "10px" }}
            >
              <SelectComponent
                disabled={!isEditor}
                value={audienceList}
                options={audienceListOptions}
                change={onAudienceChange("list")}
                isClearable="false"
                isSearchable={false}
                placeholder={t("t:common.choose-list")}
                loading={_isEmpty(audienceLists)}
              />
            </FormControl>
          )}
        </div>
        {/*<div className="minimum_customer_ranking">*/}
        {/*    <div className="input_title_campaign">*/}
        {/*        <span>{t("t:campaign-audience.minimum-customer-ranking")}</span>*/}
        {/*        <TooltipMessage*/}
        {/*            text={t("t:helper-texts.audience-audience-limit-maximum-customer-ranking")}*/}
        {/*            delay={200}*/}
        {/*            position="top"*/}
        {/*            classes="step_tooltip"*/}
        {/*        >*/}
        {/*            <img src={Shape} alt="Shape" />*/}
        {/*        </TooltipMessage>*/}
        {/*    </div>*/}
        {/*    <FormControl className={clsx("select_wrapper", !isEditor && "cursor_not_allowed")}>*/}
        {/*        <SelectComponent*/}
        {/*            disabled={!isEditor}*/}
        {/*            value={customerRanking}*/}
        {/*            options={customerRankingList}*/}
        {/*            change={handleChange("customerRanking")}*/}
        {/*            isClearable="false"*/}
        {/*            isSearchable={false}*/}
        {/*            placeholder={t("t:common.choose")}*/}
        {/*        />*/}
        {/*    </FormControl>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default memo(CustomAudiences);
