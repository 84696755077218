import { AUDIENCE } from "../constants";
import { customSegmentGroup } from "../components/Audience/constants";

const INITIAL_STATE = {
  buttonLoading: false,
  confirmationDialog: {
    open: false,
    onClose: () => {},
    confirmAction: () => {},
    declineAction: () => {},
    title: "",
    description: "",
    confirmText: "",
    declineText: ""
  },

  metaCsvBasedAudience: {
    key: "metaCsvBasedAudience",
    page: 1,
    search: "",
    ordering: "created_at",
    orderingDirection: "-",
    reloadBool: false
  },
  csvBasedAudience: {
    results: Array.from({ length: 5 }, (v, k) => ({ id: k, loading: true }))
  },
  csvBasedAudienceInfo: {
    name: "",
    description: ""
  },

  metaAnalyticsBasedAudience: {
    key: "metaAnalyticsBasedAudience",
    page: 1,
    search: "",
    status: "",
    ordering: "updated_at",
    orderingDirection: "-",
    reloadBool: false
  },
  analyticsBasedAudience: {
    results: Array.from({ length: 5 }, (v, k) => ({ id: k, loading: true }))
  },
  analyticsBasedAudienceInfo: {
    name: "",
    description: ""
  },

  metaCustomAudience: {
    key: "metaCustomAudience",
    page: 1,
    search: "",
    status: "",
    ordering: "updated_at",
    orderingDirection: "-",
    reloadBool: false
  },
  customAudience: {
    results: Array.from({ length: 5 }, (v, k) => ({ id: k, loading: true }))
  },
  customAudienceInfo: {
    name: "",
    total_contacts: 0,
    match_count: 0,
    match_percent: 0,
    groups: [customSegmentGroup]
  }
};

const reduceAudience = (
  state = INITIAL_STATE,
  {
    type,
    payload,
    metaCsvBasedAudience,
    metaAnalyticsBasedAudience,
    metaCustomAudience,
    confirmationDialog,
    csvBasedAudienceInfo,
    analyticsBasedAudienceInfo,
    customAudienceField,
    segmentIndex,
    groupIndex,
    customAudienceGroupField
  }
) => {
  switch (type) {
    case AUDIENCE.SET_AUDIENCE_META:
      if (metaCsvBasedAudience) {
        return {
          ...state,
          metaCsvBasedAudience: { ...state.metaCsvBasedAudience, ...metaCsvBasedAudience }
        };
      }
      if (metaAnalyticsBasedAudience) {
        return {
          ...state,
          metaAnalyticsBasedAudience: {
            ...state.metaAnalyticsBasedAudience,
            ...metaAnalyticsBasedAudience
          }
        };
      }
      if (metaCustomAudience) {
        return {
          ...state,
          metaCustomAudience: { ...state.metaCustomAudience, ...metaCustomAudience }
        };
      }
      return state;
    case AUDIENCE.RESET_AUDIENCE_STORE:
      return INITIAL_STATE;
    case AUDIENCE.SET_CONFIRMATION_DIALOG:
      if (confirmationDialog.reset)
        return {
          ...state,
          confirmationDialog: {
            ...state.confirmationDialog,
            open: false
          }
        };
      return { ...state, confirmationDialog };

    case AUDIENCE.POST_CSV_BASED_AUDIENCE:
    case AUDIENCE.POST_CUSTOM_AUDIENCE:
    case AUDIENCE.PATCH_CSV_BASED_AUDIENCE_BY_ID:
    case AUDIENCE.PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID:
    case AUDIENCE.PATCH_CUSTOM_AUDIENCE_BY_ID:
    case AUDIENCE.DELETE_CSV_BASED_AUDIENCE_BY_ID:
    case AUDIENCE.DUPLICATE_CUSTOM_AUDIENCE_BY_ID:
    case AUDIENCE.ARCHIVE_CUSTOM_AUDIENCE_BY_ID:
    case AUDIENCE.RESTORE_CUSTOM_AUDIENCE_BY_ID:
    case AUDIENCE.POST_ANALYTICS_BASED_AUDIENCE:
    case AUDIENCE.UPDATE_ANALYTICS_BASED_AUDIENCE:
      return {
        ...state,
        buttonLoading: true
      };
    case AUDIENCE.POST_CSV_BASED_AUDIENCE_SUCCESS:
    case AUDIENCE.POST_CSV_BASED_AUDIENCE_FAIL:
    case AUDIENCE.POST_CUSTOM_AUDIENCE_SUCCESS:
    case AUDIENCE.POST_CUSTOM_AUDIENCE_FAIL:
    case AUDIENCE.PATCH_CSV_BASED_AUDIENCE_BY_ID_SUCCESS:
    case AUDIENCE.PATCH_CSV_BASED_AUDIENCE_BY_ID_FAIL:
    case AUDIENCE.PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS:
    case AUDIENCE.PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL:
    case AUDIENCE.PATCH_CUSTOM_AUDIENCE_BY_ID_SUCCESS:
    case AUDIENCE.PATCH_CUSTOM_AUDIENCE_BY_ID_FAIL:
    case AUDIENCE.DELETE_CSV_BASED_AUDIENCE_BY_ID_FAIL:
    case AUDIENCE.DUPLICATE_CUSTOM_AUDIENCE_BY_ID_FAIL:
    case AUDIENCE.ARCHIVE_CUSTOM_AUDIENCE_BY_ID_FAIL:
    case AUDIENCE.RESTORE_CUSTOM_AUDIENCE_BY_ID_FAIL:
    case AUDIENCE.UPDATE_ANALYTICS_BASED_AUDIENCE_SUCCESS:
    case AUDIENCE.UPDATE_ANALYTICS_BASED_AUDIENCE_FAIL:
    case AUDIENCE.POST_ANALYTICS_BASED_AUDIENCE_SUCCESS:
    case AUDIENCE.POST_ANALYTICS_BASED_AUDIENCE_FAIL:
      return {
        ...state,
        buttonLoading: false
      };

    case AUDIENCE.GET_CSV_BASED_AUDIENCE_SUCCESS:
      return {
        ...state,
        csvBasedAudience: payload.data
      };
    case AUDIENCE.RECALCULATE_CSV_BASED_AUDIENCE_BY_ID_SUCCESS:
      return {
        ...state,
        csvBasedAudience: {
          ...state.csvBasedAudience,
          results: state.csvBasedAudience.results.map(el => {
            if (el.id === payload.data.id) return payload.data;
            return el;
          })
        }
      };
    case AUDIENCE.DELETE_CSV_BASED_AUDIENCE_BY_ID_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        metaCsvBasedAudience: {
          ...state.metaCsvBasedAudience,
          page: 1,
          reloadBool: !state.metaCsvBasedAudience.reloadBool
        },
        confirmationDialog: {
          ...state.confirmationDialog,
          open: false
        }
      };
    case AUDIENCE.GET_CSV_BASED_AUDIENCE_BY_ID_SUCCESS:
      return {
        ...state,
        csvBasedAudienceInfo: payload.data
      };
    case AUDIENCE.SET_CSV_BASED_AUDIENCE_INFO:
      return {
        ...state,
        csvBasedAudienceInfo
      };

    case AUDIENCE.GET_ANALYTICS_BASED_AUDIENCE_SUCCESS:
      return {
        ...state,
        analyticsBasedAudience: payload.data
      };
    case AUDIENCE.ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS:
    case AUDIENCE.RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS:
      return {
        ...state,
        metaAnalyticsBasedAudience: {
          ...state.metaAnalyticsBasedAudience,
          page: 1,
          reloadBool: !state.metaAnalyticsBasedAudience.reloadBool
        },
        confirmationDialog: {
          ...state.confirmationDialog,
          open: false
        }
      };
    case AUDIENCE.GET_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS:
      return {
        ...state,
        analyticsBasedAudienceInfo: payload.data
      };
    case AUDIENCE.SET_ANALYTICS_BASED_AUDIENCE_INFO:
      return {
        ...state,
        analyticsBasedAudienceInfo
      };

    case AUDIENCE.GET_CUSTOM_AUDIENCE_SUCCESS:
      return {
        ...state,
        customAudience: payload.data
      };
    case AUDIENCE.RECALCULATE_CUSTOM_AUDIENCE_BY_ID_SUCCESS:
      return {
        ...state,
        customAudience: {
          ...state.customAudience,
          results: state.customAudience.results.map(el => {
            if (el.id === payload.data.id) return payload.data;
            return el;
          })
        }
      };
    case AUDIENCE.ARCHIVE_CUSTOM_AUDIENCE_BY_ID_SUCCESS:
    case AUDIENCE.RESTORE_CUSTOM_AUDIENCE_BY_ID_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        metaCustomAudience: {
          ...state.metaCustomAudience,
          page: 1,
          reloadBool: !state.metaCustomAudience.reloadBool
        },
        confirmationDialog: {
          ...state.confirmationDialog,
          open: false
        }
      };
    case AUDIENCE.DUPLICATE_CUSTOM_AUDIENCE_BY_ID_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        confirmationDialog: {
          ...state.confirmationDialog,
          open: false
        }
      };
    case AUDIENCE.GET_CUSTOM_AUDIENCE_BY_ID_SUCCESS:
      return {
        ...state,
        customAudienceInfo: payload.data
      };
    case AUDIENCE.CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS_SUCCESS:
      return {
        ...state,
        customAudienceInfo: {
          ...state.customAudienceInfo,
          ...payload.data
        }
      };
    case AUDIENCE.SET_CUSTOM_AUDIENCE_FIELD:
      return {
        ...state,
        customAudienceInfo: {
          ...state.customAudienceInfo,
          ...customAudienceField
        }
      };
    case AUDIENCE.SET_CUSTOM_AUDIENCE_GROUP_FIELD:
      let tempGroupsToChangeField = [...state.customAudienceInfo.groups];
      tempGroupsToChangeField[groupIndex] = {
        ...state.customAudienceInfo.groups[groupIndex],
        ...customAudienceGroupField
      };
      return {
        ...state,
        customAudienceInfo: {
          ...state.customAudienceInfo,
          groups: tempGroupsToChangeField
        }
      };
    case AUDIENCE.ADD_CUSTOM_SEGMENT_GROUP:
      return {
        ...state,
        customAudienceInfo: {
          ...state.customAudienceInfo,
          groups: [...state.customAudienceInfo.groups, customSegmentGroup]
        }
      };
    case AUDIENCE.REMOVE_CUSTOM_SEGMENT_GROUP:
      let tempGroupsToRemoveGroup = [...state.customAudienceInfo.groups];
      tempGroupsToRemoveGroup.splice(segmentIndex, 1);
      return {
        ...state,
        customAudienceInfo: {
          ...state.customAudienceInfo,
          groups: tempGroupsToRemoveGroup
        }
      };
    case AUDIENCE.RESET_CUSTOM_AUDIENCE_STORE:
    case AUDIENCE.DELETE_EDITOR_FROM_CUSTOM_AUDIENCE:
      return {
        ...state,
        customAudienceInfo: INITIAL_STATE.customAudienceInfo
      };

    default:
      return state;
  }
};

export default reduceAudience;
