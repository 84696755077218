import { DASHBOARD } from "../constants";

export function setLoading(data) {
  return {
    type: DASHBOARD.SET_LOADING,
    payload: data
  };
}

export function closeErrorSnack() {
  return {
    type: DASHBOARD.ERROR_SNACK_CLOSE
  };
}

export function closeSuccessSnack() {
  return {
    type: DASHBOARD.SUCCESS_SNACK_CLOSE
  };
}
