import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Dropzone from "../../../HelperComponents/DropzoneComponent/DropzoneComponent";
import TooltipMessage from "../../../HelperComponents/TooltipMessage/TooltipMessage";
import { audienceFileTypeIsSupported } from "../../helpers";
import { postCSVBasedAudience } from "../../../../actions/audienceActions";

import FileIcon from "../../../../assets/image/document.svg";

import "../../styles.scss";

const CreateAudience = () => {
  const [fields, setFields] = useState({
    name: "",
    description: "",
    file: null
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const buttonLoading = useSelector(({ audience }) => audience.buttonLoading);

  const fieldsAreMissing = !fields.name || !fields.description || !fields.file;

  const onFieldChange = (name, value) => {
    setFields({ ...fields, [name]: value });
  };

  const handleFile = files => {
    if (audienceFileTypeIsSupported(files[0])) {
      onFieldChange("file", files[0]);
    } else {
      dispatch({ type: "FILE_TYPE_ERROR" });
    }
  };

  const onCreateAudienceClick = async () => {
    const formData = new FormData();
    Object.entries(fields).map(entry => formData.append(entry[0], entry[1]));
    dispatch(postCSVBasedAudience(formData)).then(res => {
      if (res.payload) history.push("/main/audience?tab=csv_based");
    });
  };

  return (
    <div className="audience-page__page">
      <div className="audience-page__wrapper">
        <div className="audience-page__title">{t("t:audience.create-audience")}</div>
        <div className="audience-page__label">{t("t:audience.enter-audience-name")}*</div>
        <div className="audience-page__input-wrapper">
          <input
            type="text"
            value={fields.name}
            onChange={e => onFieldChange("name", e.target.value)}
          />
        </div>
        <div className="audience-page__label">{t("t:audience.enter-audience-description")}*</div>
        <div className="audience-page__input-wrapper">
          <textarea
            value={fields.description}
            onChange={e => onFieldChange("description", e.target.value)}
          />
        </div>
        <div className="audience-page__label">{t("t:audience.add-csv-file")}*</div>
        {fields.file && (
          <div className="audience-page__file items-center">
            <img className="audience-page__file-icon" src={FileIcon} alt="file" />
            <div>{fields.file.name}</div>
          </div>
        )}
        <Dropzone
          placeholder={t("t:audience.drop-csv-here")}
          multiple={false}
          handleFiles={handleFile}
        />
        <TooltipMessage
          text={fieldsAreMissing ? t("t:common.enter-mandatory-fields") : ""}
          delay={200}
          position="top"
          classes="step_tooltip"
        >
          <div>
            <DefaultButton
              variant="contained"
              classes="auth audience-page__submit-btn"
              type="button"
              onClick={onCreateAudienceClick}
              disabled={fieldsAreMissing}
              loading={buttonLoading}
            >
              {t("t:audience.create-audience")}
            </DefaultButton>
          </div>
        </TooltipMessage>
        <Link to="/main/audience?tab=csv_based" className="text_hover audience-page__cancel">
          {t("t:common.cancel")}
        </Link>
      </div>
    </div>
  );
};

export default CreateAudience;
