import { DATA_SOURCES } from "../constants";

export function getDataSourcesList() {
  return {
    type: DATA_SOURCES.GET_DATA_SOURCES_LIST,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/datasources/`,
        method: "get"
      }
    }
  };
}

export function postNewDataSource(data) {
  return {
    type: DATA_SOURCES.POST_NEW_DATA_SOURCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/datasources/`,
        method: "post",
        data // name
      }
    }
  };
}

export function getDataSource(id) {
  return {
    type: DATA_SOURCES.GET_DATA_SOURCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/datasources/${id}/`,
        method: "get"
      }
    }
  };
}

export function patchDataSource(id, data) {
  return {
    type: DATA_SOURCES.PATCH_DATA_SOURCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/datasources/${id}/`,
        method: "patch",
        data // api_base_url
      }
    }
  };
}

export function postRegenerateAPIkey(id) {
  return {
    type: DATA_SOURCES.POST_REGENERATE_API_KEY,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/datasources/${id}/regenerate-api-key/`,
        method: "post"
      }
    }
  };
}

export function getProducts(id, params) {
  return {
    type: DATA_SOURCES.GET_PRODUCTS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/datasources/${id}/products/?${params.join("&")}`,
        method: "get"
      }
    }
  };
}

export function postAddProducts(id, data) {
  return {
    type: DATA_SOURCES.POST_ADD_PRODUCTS_FROM_API,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/datasources/${id}/create-products-from-api/`,
        method: "post",
        data
      }
    }
  };
}

export function resetProducts() {
  return {
    type: DATA_SOURCES.RESET_PRODUCTS
  };
}

export function resetDataSources() {
  return {
    type: DATA_SOURCES.RESET_DATA_SOURCES
  };
}
