import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import InitialInfo from "./Pages/InitialInfo";
import GenerateApiKey from "./Pages/GenerateApiKey";
import ConfigureApiEndpoints from "./Pages/ConfigureApiEndpoints";

import BackIcon from "../../../assets/image/small-left.svg";

import "./styles.scss";

const AddNewDataSource = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const dataSource = useSelector(({ dataSources }) => dataSources.dataSource);

  useEffect(() => {
    if (!history.location.pathname.includes("info")) {
      if (!dataSource.id) {
        history.push("/main/products?data-sources=true");
      }
    }
  }, [history, dataSource.id]);

  return (
    <div className="data_source_container">
      <div>
        <Link to="/main/products" className="text_hover back">
          <img src={BackIcon} alt="" />
          {t("t:data-sources.back-to-products")}
        </Link>
        <Switch>
          <Route
            path={match.url}
            exact
            render={() => <Redirect to="/main/add-new-data-source/info" />}
          />
          <Route path={`${match.url}/info`} exact component={InitialInfo} />
          <Route path={`${match.url}/generate-api-key`} exact component={GenerateApiKey} />
          <Route
            path={`${match.url}/configure-api-endpoints`}
            exact
            component={ConfigureApiEndpoints}
          />
        </Switch>
      </div>
    </div>
  );
};

export default AddNewDataSource;
