import { CAMPAIGN } from "../constants";

const INITIAL_STATE = {
  meta: {
    page: 1,
    search: "",
    type: "",
    status: ""
  },
  list: { results: Array.from({ length: 10 }, (v, k) => ({ id: k })) }
};

const reduceDeliveries = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CAMPAIGN.GET_COMPANY_DELIVERIES_SUCCESS:
      return { ...state, list: action.payload.data };
    case CAMPAIGN.SET_COMPANY_DELIVERIES_META:
      return { ...state, meta: { ...state.meta, ...action.meta } };
    case CAMPAIGN.PATCH_DELIVERY_INFO_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_DELIVER_TO_PICKUP_STATUS_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_READY_FOR_PICKUP_STATUS_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_NEEDS_DELIVERY_STATUS_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_ON_DELIVERY_STATUS_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_DONE_STATUS_SUCCESS:
      let tempResults = [...state.list.results];
      tempResults = tempResults.map(el =>
        el.id === action.payload.data.id ? { ...el, ...action.payload.data } : el
      );
      return { ...state, list: { ...state.list, results: tempResults } };
    default:
      return state;
  }
};

export default reduceDeliveries;
