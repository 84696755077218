let BASE_URL,
  SECOND_URL,
  APPLE_URL,
  APPLE_CONNECT_URL,
  APPLE_ID,
  GOOGLE_ID,
  GOOGLE_KEY,
  FACEBOOK_APP_ID,
  DOCUMENTATION_URL,
  SENTRY_DSN;

const host = window.location.host;

if (host === "campy.local") {
  BASE_URL = "http://api.campy.local:33000/web/v1";
  APPLE_URL = "https://campy.4-com.pro/auth/apple";
  APPLE_CONNECT_URL = "https://campy.4-com.pro/auth/apple-connect";
  APPLE_ID = "ee.campy.iosapplication.sandbox.signinwithapple";
  FACEBOOK_APP_ID = "344470190736783";
  GOOGLE_ID = "562166505995-7krcnfri7o55to86avu8rc33hh12coc9.apps.googleusercontent.com";
  GOOGLE_KEY = "AIzaSyBBfbf4apEdP9u5JkeqAiGuFO1oVczRM_4";
  DOCUMENTATION_URL = "https://api.campy.4-com.pro/products-example/";
  SENTRY_DSN = "https://53ce17890a654ef891ac034776436f06@sentry.4-com.pro/27";
} else if (host.includes("localhost")) {
  BASE_URL = "https://api.campy.4-com.pro/web/v1";
  APPLE_URL = "https://campy.4-com.pro/auth/apple";
  APPLE_CONNECT_URL = "https://campy.4-com.pro/auth/apple-connect";
  APPLE_ID = "ee.campy.iosapplication.sandbox.signinwithapple";
  FACEBOOK_APP_ID = "344470190736783";
  GOOGLE_ID = "562166505995-7krcnfri7o55to86avu8rc33hh12coc9.apps.googleusercontent.com";
  GOOGLE_KEY = "AIzaSyBBfbf4apEdP9u5JkeqAiGuFO1oVczRM_4";
  DOCUMENTATION_URL = "https://api.campy.4-com.pro/products-example/";
  SENTRY_DSN = "https://53ce17890a654ef891ac034776436f06@sentry.4-com.pro/27";
} else if (host === "app.campy.eu") {
  BASE_URL = `https://api.campy.eu/web/v1`;
  APPLE_URL = `https://app.campy.eu/auth/apple`;
  APPLE_CONNECT_URL = `https://app.campy.eu/auth/apple-connect`;
  APPLE_ID = "ee.campy.iosapplication.sandbox.signinwithapple";
  FACEBOOK_APP_ID = "344470190736783";
  GOOGLE_ID = "562166505995-7krcnfri7o55to86avu8rc33hh12coc9.apps.googleusercontent.com";
  GOOGLE_KEY = "AIzaSyBBfbf4apEdP9u5JkeqAiGuFO1oVczRM_4";
  DOCUMENTATION_URL = `https://api.campy.eu/products-example/`;
  SENTRY_DSN = "https://53ce17890a654ef891ac034776436f06@sentry.4-com.pro/27";
} else {
  BASE_URL = `https://api.${host}/web/v1`;
  APPLE_URL = `https://${host}/auth/apple`;
  APPLE_CONNECT_URL = `https://${host}/auth/apple-connect`;
  APPLE_ID = "ee.campy.iosapplication.sandbox.signinwithapple";
  FACEBOOK_APP_ID = "344470190736783";
  GOOGLE_ID = "562166505995-7krcnfri7o55to86avu8rc33hh12coc9.apps.googleusercontent.com";
  GOOGLE_KEY = "AIzaSyBBfbf4apEdP9u5JkeqAiGuFO1oVczRM_4";
  DOCUMENTATION_URL = `https://api.${host}/products-example/`;
  SENTRY_DSN = "https://53ce17890a654ef891ac034776436f06@sentry.4-com.pro/27";
}

SECOND_URL = "";

export const AppleRedirectUrl = APPLE_URL;
export const AppleConnectRedirectUrl = APPLE_CONNECT_URL;
export const AppleClientId = APPLE_ID;
export const GoogleClientId = GOOGLE_ID;
export const GOOGLE_API_KEY = GOOGLE_KEY;
export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
export const DATA_SOURCES_DOCUMENTATION_URL = DOCUMENTATION_URL;
export const SENTRY_DSN_URL = SENTRY_DSN;
export const TERMS_AND_CONDITIONS_ESTONIAN_URL = "https://campy.eu/arikliendi-kasutustingimused/";
export const TERMS_AND_CONDITIONS_URL = "https://campy.eu/en/b2b-terms-and-conditions/";
export const PRIVACY_POLICY_URL = "https://campy.eu/en/privacy-policy/";
export { FACEBOOK_APP_ID };
