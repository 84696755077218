import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Prompt } from "react-router";
import { withTranslation } from "react-i18next";

import Loader from "../../HelperComponents/Loader";
import DistributionPanel from "./DistributionPanel";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import Snack from "../../HelperComponents/Snack/Snack";
import { showToastError, validateImage } from "../../../helpers/functions";
import CropperComponent from "../../HelperComponents/CropperComponent";

import {
  setDistributionError,
  resetCampaignErrors,
  getDistribution,
  patchDistribution
} from "../../../actions/campaignActions";
import { getCompanyAddresses, postAddAddress } from "../../../actions/companiesActions";
import {
  checkDistributionChanges,
  parseDistributionData,
  prepareDistributionDataToSubmit
} from "./helpers/functions";
import TitleAndDescription from "./Blocks/TitleAndDescription";
import TimePeriod from "./Blocks/TimePeriod";
import Limits from "./Blocks/Limits";
import UserAgreement from "./Blocks/UserAgreement";
import Delivery from "./Blocks/Delivery";
// import ShareViaFacebook from "./Blocks/ShareViaFacebook";
import ShareViaLink from "./Blocks/ShareViaLink";

import "./styles.scss";

class Distribution extends Component {
  constructor(props) {
    super(props);
    this.editingCoolDown = 0;
    this.state = {
      loading: true,
      campaignId: props.campaignId,
      openSuccessSnack: false,
      startDateIsInvalid: false,
      endDateIsInvalid: false,
      max_participants_error: false,

      title: undefined,
      image: undefined,
      imagePreview: undefined,
      imageBlobToDownload: undefined,
      imageToCrop: undefined,
      description: undefined,
      available_immediately: true,
      no_deadline: true,
      start_date: undefined,
      start_time: undefined,
      end_date: undefined,
      end_time: undefined,
      max_participants: undefined,
      participants_limit: undefined,
      time_to_finish: undefined,
      timeToFinishParsed: {
        hours: undefined,
        minutes: undefined
      },
      deliveries: [],
      addresses: [],
      agreement: "",
      // facebook_share_is_enabled: undefined,
      // facebook_share_title: undefined,
      // facebook_share_image: undefined,
      // facebook_share_imagePreview: undefined,
      // facebook_share_imageToCrop: undefined,
      // facebook_share_imageBlobToDownload: undefined,
      // facebook_share_description: undefined,
      // facebook_share_reward: undefined,
      // facebook_share_days: undefined,
      link_share_is_enabled: undefined,
      link_share_title: undefined,
      link_share_image: undefined,
      link_share_imagePreview: undefined,
      link_share_imageToCrop: undefined,
      link_share_imageBlobToDownload: undefined,
      link_share_description: undefined,
      link_share_reward: undefined,
      rewardType: null
    };
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return this.checkChanges();
    };
    this.props.getCompanyAddresses();
    this.fetchDistribution();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.loading && this.state !== prevState) {
      if (this.editingCoolDown !== 10000) {
        this.props.isEditor && this.props.handleEditingTimer();
        this.editingCoolDown = 10000;
        const timeout = setTimeout(() => {
          this.editingCoolDown = 0;
          clearTimeout(timeout);
        }, 10000);
      }
    }
    if (this.state.max_participants !== prevState.max_participants) {
      this.setState({
        max_participants_error:
          !!this.state.max_participants &&
          (this.state.max_participants < 1 || +this.state.max_participants === "NaN")
      });
    }
  }

  fetchDistribution = () => {
    const { getDistribution, t } = this.props;
    const { campaignId, timeToFinishParsed } = this.state;
    getDistribution(campaignId).then(res => {
      this.setState(parseDistributionData({ ...res.payload.data, timeToFinishParsed, t }));
    });
  };

  handleFiles = (files, fieldName) => {
    const { setDistributionError, resetCampaignErrors, t } = this.props;
    resetCampaignErrors();
    if (validateImage(files[0])) {
      this.handleImage(files[0], fieldName);
    } else {
      setDistributionError({
        [fieldName]: [{ code: "file_type", message: t("t:snack-messages.file-type-fail") }]
      });
    }
  };

  handleImage = (image, fieldName) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => this.setState({ [`${fieldName}ToCrop`]: [reader.result] });
  };

  resetImagesToCrop = () =>
    this.setState({
      imageToCrop: null,
      facebook_share_imageToCrop: null,
      link_share_imageToCrop: null
    });

  onFinishCroppingImage = (canvas, imageMimeType) => {
    const { imageToCrop } = this.state;
    this.resetImagesToCrop();
    const fieldName = imageToCrop
      ? "image"
      : // : facebook_share_imageToCrop
        // ? "facebook_share_image"
        "link_share_image";
    canvas.toBlob(blob => {
      this.setState({
        [`${fieldName}Preview`]: canvas.toDataURL(imageMimeType),
        [fieldName]: canvas.toDataURL(imageMimeType),
        [`${fieldName}BlobToDownload`]: blob
      });
    }, imageMimeType);
  };

  onCancelCropping = () => this.resetImagesToCrop();

  handleSelectChange = name => event => {
    this.setState({ [name]: event });
  };

  saveCampaign = async () => {
    const { patchDistribution, resetCampaignErrors } = this.props;
    const { campaignId } = this.state;
    this.setState({ buttonLoading: true });
    resetCampaignErrors();
    const res = await patchDistribution(
      campaignId,
      prepareDistributionDataToSubmit({ ...this.state })
    );
    this.setState({ buttonLoading: false });
    if (res.payload) {
      await this.fetchDistribution();
      this.setState({ openSuccessSnack: true });
    } else {
      try {
        Object.values(res.error.response.data).forEach(el => {
          showToastError(Object.values(el)[0].message);
        });
      } catch (e) {
        console.log(`Unhandled error - ${e}`);
      }
    }
  };

  onSetFieldsInState = fields => this.setState(fields);

  render() {
    const { distributionError, isEditor, companyAddresses, t } = this.props;
    const {
      loading,
      buttonLoading,
      title,
      openSuccessSnack,
      image,
      imagePreview,
      imageToCrop,
      description,
      available_immediately,
      no_deadline,
      start_date,
      start_time,
      end_date,
      end_time,
      max_participants,
      participants_limit,
      timeToFinishParsed,
      startDateIsInvalid,
      endDateIsInvalid,
      max_participants_error,
      addresses,
      deliveries,
      agreement,
      // facebook_share_is_enabled,
      // facebook_share_title,
      // facebook_share_image,
      // facebook_share_imagePreview,
      // facebook_share_imageToCrop,
      // facebook_share_description,
      // facebook_share_reward,
      // facebook_share_days,
      link_share_is_enabled,
      link_share_title,
      link_share_image,
      link_share_imagePreview,
      link_share_imageToCrop,
      link_share_description,
      link_share_reward,
      rewardType
    } = this.state;
    return (
      <div className="distribution_wrapper">
        <DistributionPanel />
        <div className="distribution_container">
          {loading ? (
            <Loader />
          ) : (
            <div className="distribution">
              <div className="title">{t("t:distribution.distribution")}</div>
              <div className="description">{t("t:distribution.distribution-description")}</div>
              <TitleAndDescription
                distributionError={distributionError}
                isEditor={isEditor}
                title={title}
                image={image}
                imagePreview={imagePreview}
                description={description}
                handleFiles={this.handleFiles}
                onSetFieldsInState={this.onSetFieldsInState}
              />
              <TimePeriod
                distributionError={distributionError}
                startDateIsInvalid={startDateIsInvalid}
                endDateIsInvalid={endDateIsInvalid}
                isEditor={isEditor}
                available_immediately={available_immediately}
                no_deadline={no_deadline}
                end_date={end_date}
                start_date={start_date}
                start_time={start_time}
                end_time={end_time}
                onSetFieldsInState={this.onSetFieldsInState}
                handleSelectChange={this.handleSelectChange}
              />
              <Limits
                distributionError={distributionError}
                isEditor={isEditor}
                max_participants_error={max_participants_error}
                participants_limit={participants_limit}
                max_participants={max_participants}
                onSetFieldsInState={this.onSetFieldsInState}
                handleSelectChange={this.handleSelectChange}
                timeToFinishParsed={timeToFinishParsed}
              />
              {rewardType !== "monetary" && (
                <Delivery
                  distributionError={distributionError}
                  isEditor={isEditor}
                  addresses={addresses}
                  postAddAddress={this.props.postAddAddress}
                  getCompanyAddresses={this.props.getCompanyAddresses}
                  onSetFieldsInState={this.onSetFieldsInState}
                  companyAddresses={companyAddresses}
                  deliveries={deliveries}
                />
              )}
              <UserAgreement
                distributionError={distributionError}
                isEditor={isEditor}
                agreement={agreement}
                onSetFieldsInState={this.onSetFieldsInState}
              />
              {/*<ShareViaFacebook*/}
              {/*    distributionError={distributionError}*/}
              {/*    isEditor={isEditor}*/}
              {/*    facebook_share_is_enabled={facebook_share_is_enabled}*/}
              {/*    facebook_share_title={facebook_share_title}*/}
              {/*    facebook_share_image={facebook_share_image}*/}
              {/*    facebook_share_days={facebook_share_days}*/}
              {/*    facebook_share_imagePreview={facebook_share_imagePreview}*/}
              {/*    facebook_share_reward={facebook_share_reward}*/}
              {/*    facebook_share_description={facebook_share_description}*/}
              {/*    onSetFieldsInState={this.onSetFieldsInState}*/}
              {/*    handleFiles={this.handleFiles}*/}
              {/*/>*/}
              <ShareViaLink
                distributionError={distributionError}
                isEditor={isEditor}
                link_share_is_enabled={link_share_is_enabled}
                link_share_title={link_share_title}
                link_share_image={link_share_image}
                link_share_imagePreview={link_share_imagePreview}
                link_share_reward={link_share_reward}
                link_share_description={link_share_description}
                onSetFieldsInState={this.onSetFieldsInState}
                handleFiles={this.handleFiles}
              />
              <TooltipMessage
                text={
                  !description ||
                  !image ||
                  !title ||
                  !max_participants ||
                  (!Number(timeToFinishParsed.hours) && !Number(timeToFinishParsed.minutes))
                    ? t("t:distribution.mandatory-fields-validation")
                    : ""
                }
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <div className="save_button">
                  <DefaultButton
                    loading={buttonLoading}
                    variant="contained"
                    classes="campaign_save_btn"
                    onClick={this.saveCampaign}
                    disabled={
                      startDateIsInvalid ||
                      endDateIsInvalid ||
                      !!max_participants_error ||
                      !description ||
                      !image ||
                      !title ||
                      !max_participants ||
                      (!Number(timeToFinishParsed.hours) && !Number(timeToFinishParsed.minutes)) ||
                      !isEditor ||
                      !checkDistributionChanges({
                        isEditor,
                        prev: this.props.distribution,
                        ...this.state
                      })
                    }
                  >
                    {t("t:common.save")}
                  </DefaultButton>
                </div>
              </TooltipMessage>
            </div>
          )}
          <Prompt
            when={checkDistributionChanges({
              isEditor,
              prev: this.props.distribution,
              ...this.state
            })}
            message={t("t:common.you-have-unsaved-changes")}
          />
          <Snack
            open={openSuccessSnack}
            handleSnack={() => this.setState({ openSuccessSnack: false })}
            message={t("t:snack-messages.successfully-changed")}
            type="success"
          />
          {/*{(imageToCrop || facebook_share_imageToCrop || link_share_imageToCrop) && (*/}
          {(imageToCrop || link_share_imageToCrop) && (
            <CropperComponent
              aspectRatio={imageToCrop ? 1.25 : 1.6}
              // imageToCrop={imageToCrop || facebook_share_imageToCrop || link_share_imageToCrop}
              imageToCrop={imageToCrop || link_share_imageToCrop}
              onFinishCropping={this.onFinishCroppingImage}
              onCancelCropping={this.onCancelCropping}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ campaign, companies }) {
  return {
    isEditor: campaign.campaignViewers.is_editor,
    distributionError: campaign.distributionError,
    distribution: campaign.distribution,
    companyAddresses: companies.companyAddresses
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setDistributionError,
      resetCampaignErrors,
      getDistribution,
      patchDistribution,
      getCompanyAddresses,
      postAddAddress
    },
    dispatch
  );
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Distribution)));
