import React, { Component } from "react";
import { connect } from "react-redux";

import { postAppleAuth } from "../../../actions/authActions";

import Loader from "../../HelperComponents/Loader";

class AppleRedirectUrl extends Component {
  componentDidMount() {
    this.responseApple();
  }

  responseApple = response => {
    const {
      postAppleAuth,
      history,
      location: { search }
    } = this.props;
    const params = new URLSearchParams(search.substring(1));
    const data = {
      access_token: params.get("id_token")
    };
    postAppleAuth(data).then(res => {
      if (
        res.payload &&
        res.payload.status &&
        (res.payload.status === 200 || res.payload.status === 201)
      ) {
        localStorage.token = res.payload.data.key;
        history.push("/main");
      }
    });
  };

  render() {
    return <Loader size={40} />;
  }
}

const mapDispatchToProps = {
  postAppleAuth
};

export default connect(null, mapDispatchToProps)(AppleRedirectUrl);
