import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image as CloudinaryImage } from "cloudinary-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../../HelperComponents/DialogComponent/DialogComponent";

import { getCompanyDetails } from "../../../../actions/companiesActions";

import eye from "../../../../assets/image/My_Campaign/eye.svg";
import BackIcon from "../../../../assets/image/small-left.svg";
import SelectedIcon from "../../../../assets/image/selected.svg";

import "./styles.scss";

const ViewCompany = () => {
  const { t } = useTranslation();
  const { id: companyID } = useParams();
  const dispatch = useDispatch();
  const {
    name,
    legal_name,
    description,
    image,
    address,
    registry_code,
    country_of_registration_display,
    contact_person_full_name,
    contact_person_phone_number,
    contact_email,
    bank_account_number,
    billing_contact_email,
    addresses,
    delivery_companies,
    is_owner
  } = useSelector(({ companies }) => companies.companyDetails);

  const [loaded, setLoaded] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);

  const handlePreviewDialog = () => setPreviewDialogOpen(val => !val);

  const fetchCompany = async () => {
    await dispatch(getCompanyDetails(companyID));
  };

  useEffect(() => {
    fetchCompany().then(() => {
      setLoaded(true);
    });
    // eslint-disable-next-line
  }, []);

  const getDeliveryCompanyName = key => {
    switch (key) {
      case "smartpost":
        return "Itella SmartPost";
      case "omniva":
        return "Omniva";
      default:
        return key;
    }
  };

  if (!loaded) return null;

  return (
    <div className="view-company">
      <div className="view-company__wrapper">
        <Link to="/main/profile/?tab=companies" className="text_hover view-company__back">
          <img src={BackIcon} alt="back" className="view-company__back--icon" />
          {t("t:companies.back-to-companies")}
        </Link>
        <div className="view-company__title">{name}</div>
        <div className="view-company__names-wrapper">
          <div>
            <div className="view-company__label">{t("t:companies.company-name")}</div>
            <div className="view-company__value">{name}</div>
          </div>
          <div>
            <div className="view-company__label">{t("t:companies.company-legal-name")}</div>
            <div className="view-company__value">{legal_name}</div>
          </div>
        </div>
        <div className="view-company__label">{t("t:companies.company-description")}</div>
        <div className="view-company__value">{description}</div>
        <div className="view-company__label">{t("t:companies.company-image")}</div>
        {image ? (
          <div className="view-company__value is-image">
            <CloudinaryImage
              cloudName="campy"
              width="39"
              height="39"
              gravity="face"
              crop="thumb"
              publicId={image.split("/").pop()}
            />
            <p>{image.split("/").pop()}</p>
            <button onClick={handlePreviewDialog}>
              <img src={eye} alt="eye" />
            </button>
          </div>
        ) : (
          <div className="view-company__value">—</div>
        )}
        <div className="view-company__fields-wrapper">
          <div>
            <div className="view-company__label">{t("t:companies.company-address")}</div>
            <div className="view-company__value">{address}</div>
          </div>
          <div>
            <div className="view-company__label">{t("t:companies.registry-code")}</div>
            <div className="view-company__value">{registry_code}</div>
          </div>
          <div>
            <div className="view-company__label">{t("t:companies.country-of-registration")}</div>
            <div className="view-company__value">{country_of_registration_display}</div>
          </div>
          <div>
            <div className="view-company__label">
              {t("t:companies.full-name-of-contact-person")}
            </div>
            <div className="view-company__value">{contact_person_full_name}</div>
          </div>
          <div>
            <div className="view-company__label">{t("t:companies.contact-email-address")}</div>
            <div className="view-company__value">{contact_email}</div>
          </div>
          <div>
            <div className="view-company__label">
              {t("t:companies.contact-person-phone-number")}
            </div>
            <div className="view-company__value">{contact_person_phone_number}</div>
          </div>
        </div>
        <div className="view-company__fields-wrapper">
          <div>
            <div className="view-company__label">
              {t("t:companies.company-bank-account-number")}
            </div>
            <div className="view-company__value">{bank_account_number || "—"}</div>
          </div>
          <div>
            <div className="view-company__label">
              {t("t:companies.billing-contact-email-address")}
            </div>
            <div className="view-company__value">{billing_contact_email || "—"}</div>
          </div>
        </div>
        <div className="view-company__label">{t("t:companies.company-pickup-addresses")}</div>
        <div className="view-company__addresses">
          {addresses && addresses.length === 0 && <div className="view-company__value">—</div>}
          {addresses.map((el, key) => (
            <div className="view-company__addresses--address" key={`address_${key}`}>
              {el.address}
            </div>
          ))}
        </div>
        <div className="view-company__label">{t("t:companies.delivery-companies")}</div>
        <div className="view-company__delivery-companies">
          {delivery_companies && delivery_companies.length === 0 && (
            <div className="view-company__value mb-0">—</div>
          )}
          {delivery_companies &&
            delivery_companies.map(({ delivery_company, delivery_price }) => (
              <div className="view-company__delivery-companies--block" key={delivery_company}>
                <img
                  src={SelectedIcon}
                  alt="V"
                  className="view-company__delivery-companies--selected"
                />
                <div className="view-company__delivery-companies--info">
                  <div className="view-company__delivery-companies--name">
                    {getDeliveryCompanyName(delivery_company)}
                  </div>
                  <div className="view-company__delivery-companies--price">
                    {t("t:distribution.delivery-price")}: <span>{delivery_price}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {is_owner && (
          <DefaultButton
            variant="contained"
            classes="add_product"
            type="link"
            to={`/main/company/${companyID}`}
          >
            {t("t:common.edit")}
          </DefaultButton>
        )}
      </div>
      <DialogComponent
        open={previewDialogOpen}
        onClose={handlePreviewDialog}
        closeIcon
        classes="image_dialog"
        rootClass="image_root_dialog"
      >
        <div>
          <img src={image} alt="preview" />
        </div>
      </DialogComponent>
    </div>
  );
};

export default ViewCompany;
