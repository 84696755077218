/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { Image as CloudinaryImage } from "cloudinary-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ShowMoreText from "react-show-more-text";
import { getCampaignMobilePreview } from "../../../actions/campaignActions";

import ButtonIcon from "../../../assets/image/My_Campaign/Preview/action.png";
import AlertIcon from "../../../assets/image/My_Campaign/Preview/participants.svg";
import TimerIcon from "../../../assets/image/My_Campaign/Preview/tofinish.svg";
import ViewsIcon from "../../../assets/image/My_Campaign/Preview/views.svg";
import ForwardIcon from "../../../assets/image/My_Campaign/Preview/steps.svg";
import DownloadsIcon from "../../../assets/image/My_Campaign/Preview/downloads.svg";
import LikesIcon from "../../../assets/image/My_Campaign/Preview/likes.svg";
import BackIcon from "../../../assets/image/My_Campaign/Preview/back-arrow.svg";
import MonetaryIcon from "../../../assets/image/My_Campaign/Preview/monetary.png";
import BonusIcon from "../../../assets/image/My_Campaign/Preview/bonus.png";
import NoveltyIcon from "../../../assets/image/My_Campaign/Preview/novelty.png";
import DiscountIcon from "../../../assets/image/My_Campaign/Preview/discount.png";

import "./styles.scss";

const renderImage = (type, order) => {
  if (type === "discount") {
    return <img src={DiscountIcon} alt="reward" className="step_number" />;
  } else if (type === "bonus") {
    return <img src={BonusIcon} alt="reward" className="step_number" />;
  } else if (type === "monetary") {
    return <img src={MonetaryIcon} alt="reward" className="step_number" />;
  } else if (type === "novelty") {
    return <img src={NoveltyIcon} alt="reward" className="step_number" />;
  } else {
    return <div className="step_number">{order}</div>;
  }
};

export const Preview = () => {
  const dispatch = useDispatch();
  const {
    campaignMobilePreview: {
      company_name,
      company_image,
      image,
      name,
      company_description,
      participants_count,
      max_participants_count,
      estimate_time,
      product,
      product_description,
      product_image,
      product_color,
      gift,
      campaign_description,
      number_of_campaigns,
      view_count,
      steps
    },
    campaignInfo: { id }
  } = useSelector(({ campaign }) => campaign);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCampaignMobilePreview(id));
  }, []);

  const renderHeader = (
    <>
      <div className="mobile_header">
        <div />
        <span />
      </div>
      <div className="icons-on-top">
        <img src={BackIcon} alt="<-" />
        <div>
          <img src={DownloadsIcon} alt="[->" />
          <img src={LikesIcon} alt="<3" />
        </div>
      </div>
    </>
  );

  const renderStatistics = useMemo(() => {
    const toFinish = estimate_time >= 60 ? Math.floor(estimate_time / 60) : estimate_time;
    return (
      <div className="campaign-preview__statistics">
        <div>
          <span>{t("t:campaigns.steps")}</span>
          <p>
            <img src={ForwardIcon} alt="tasks" />
            {steps.length} {steps.length === 1 ? "task" : "tasks"}
          </p>
        </div>
        <div>
          <span>{t("t:campaigns.to-finish")}</span>
          <p>
            <img src={TimerIcon} alt="tasks" />
            {toFinish}{" "}
            {estimate_time >= 60 ? t("t:common.hours-short") : t("t:common.minutes-short")}
          </p>
        </div>
        <div>
          <span>{t("t:campaigns.participants")}</span>
          <p>
            <img src={AlertIcon} alt="tasks" />
            {participants_count || 0}/{max_participants_count || 0}
          </p>
        </div>
        <div>
          <span>{t("t:campaigns.views")}</span>
          <p>
            <img src={ViewsIcon} alt="views" />
            {view_count || 0}
          </p>
        </div>
      </div>
    );
  }, [steps.length, estimate_time, participants_count, max_participants_count, view_count]);

  const renderProduct = useMemo(
    () => (
      <>
        {product_image && (
          <CloudinaryImage
            cloudName="campy"
            gravity="face"
            crop="thumb"
            publicId={product_image.split("/").pop()}
            className="campaign-preview__product--icon"
          />
        )}
        <div className="campaign-preview__product--info">
          <div>
            <div className="campaign-preview__product--label">{t("t:campaigns.price")}</div>
            <div className="campaign-preview__product--price">
              <p>€{product?.new_price.toFixed(2)}</p>&nbsp;
              {!!product?.price && product.price !== product.new_price && (
                <span>€{product?.price.toFixed(2)}</span>
              )}
            </div>
          </div>
          {product_color && (
            <div style={{ textAlign: "right" }}>
              <div className="campaign-preview__product--label">{t("t:products.color")}</div>
              <div className="campaign-preview__product--color">
                <div style={{ backgroundColor: `#${product_color.hex}` }} />
                {product_color.name}
              </div>
            </div>
          )}
        </div>
        <div className="campaign-preview__product--description">
          <ShowMoreText
            lines={4}
            more={t("t:campaigns.read-more")}
            less={t("t:campaigns.read-less")}
            width={303}
            truncatedEndingComponent={" "}
          >
            {product_description}
          </ShowMoreText>
        </div>
      </>
    ),
    [product_image, product_description, JSON.stringify(product)]
  );

  const renderGift = useMemo(
    () => (
      <div className="campaign-preview__gift">
        <h3>{t("t:campaigns.your-free-gift")}</h3>
        <div className="campaign-preview__gift--wrapper">
          {product_image && (
            <CloudinaryImage
              cloudName="campy"
              gravity="face"
              crop="thumb"
              publicId={gift?.image.split("/").pop()}
              className="campaign-preview__gift--icon"
            />
          )}
          <div>
            <div className="campaign-preview__gift--name">{gift?.name}</div>
            <div className="campaign-preview__gift--price">
              <p>{t("t:campaigns.free")}</p>&nbsp;
              <span>€{gift?.price.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    ),
    [JSON.stringify(gift)]
  );

  const renderSteps = useMemo(() => {
    let stepsToRender = [];
    let stepOrder = 1;
    steps.forEach(s => {
      stepsToRender.push({
        ...s,
        stepOrder
      });
      if (s.category !== "rewards") stepOrder = ++stepOrder;
    });
    return (
      <>
        <h3 style={{ marginTop: "40px" }}>{t("t:campaigns.steps")}</h3>
        <div className="steps_wrapper">
          {stepsToRender.map(({ id, type, detail, category, stepOrder }) => (
            <div
              className={clsx(["step_block", category === "rewards" && "rewards"])}
              key={`step_${id}`}
            >
              {renderImage(type, stepOrder)}
              <p>
                {t(`t:campaigns.mobile-${type}`, {
                  value:
                    type === "discount"
                      ? `${detail.type === "price" ? "€" : ""}${(detail.value || 0).toFixed(
                          detail.type === "percent" ? 0 : 2
                        )}${detail.type === "percent" ? "%" : ""}`
                      : type === "monetary"
                      ? (detail.amount || 0).toFixed(2)
                      : type === "image"
                      ? detail.duration || 0
                      : undefined
                })}
              </p>
            </div>
          ))}
        </div>
      </>
    );
  }, [JSON.stringify(steps)]);

  const renderCompany = useMemo(
    () => (
      <>
        <h3>{company_name}</h3>
        {company_description && (
          <div className="campaign-preview__company-description">
            <ShowMoreText
              lines={1}
              more={t("t:campaigns.read-more")}
              less={t("t:campaigns.read-less")}
              width={303}
              truncatedEndingComponent={" "}
            >
              {company_description}
            </ShowMoreText>
          </div>
        )}
        {company_image && (
          <CloudinaryImage
            cloudName="campy"
            gravity="face"
            crop="thumb"
            publicId={company_image.split("/").pop()}
            className="company_icon"
          />
        )}
        <div className="company_block">
          <div>{company_name}</div>
          <p>{`${number_of_campaigns} ${t("t:build-campaign.challenges")}`}</p>
        </div>
      </>
    ),
    [company_name, company_description, company_image, number_of_campaigns]
  );

  return (
    <div className="campaign_preview_wrapper">
      <div className="mobile_view">
        {renderHeader}

        {image && (
          <CloudinaryImage
            cloudName="campy"
            gravity="face"
            crop="thumb"
            publicId={image.split("/").pop()}
            className="campaign_icon"
          />
        )}
        <div className="preview_title">
          <p>{company_name}</p>
          <div>{name}</div>
        </div>

        {renderStatistics}

        <h3>{t("t:common.description")}</h3>
        <div className="campaign_description">{campaign_description}</div>

        {!!product && renderProduct}

        {!!gift && renderGift}

        {renderSteps}

        {renderCompany}

        <div className="campaign-preview__swipe-to-start">
          <div className="campaign-preview__swipe-to-start--text">
            {t("t:campaigns.swipe-to-start")}
          </div>
          <img src={ButtonIcon} alt="button" />
        </div>
      </div>
    </div>
  );
};
