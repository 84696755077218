import React from "react";
import { useDrop } from "react-dnd";

function selectBackgorund(isActive, canDrop) {
  if (isActive) {
    return "rgba(0, 0, 0, 0.05)";
  } else if (canDrop) {
    return "rgba(0, 0, 0, 0.1)";
  } else {
    return "";
  }
}

export const DroppableArea = ({ backgroundFaded, children }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "box",
    drop: () => ({
      name: "DroppableArea",
      allowedDropEffect: "any"
    }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;
  const background = selectBackgorund(isActive, canDrop);
  return (
    <div
      ref={drop}
      className="campaign_canvas"
      style={{
        backgroundColor: backgroundFaded ? "rgba(248,248,248,0.01)" : background,
        paddingBottom: backgroundFaded ? "715px" : "150px"
      }}
    >
      {children}
    </div>
  );
};
