export const AUTH = {
  LOGIN: "LOGIN",
  LOGIN_FAIL: "LOGIN_FAIL",

  ACTIVATE_ACCOUNT: "ACTIVATE_ACCOUNT",

  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL",

  GET_GLOBAL_PARAMETERS: "GET_GLOBAL_PARAMETERS",
  GET_GLOBAL_PARAMETERS_SUCCESS: "GET_GLOBAL_PARAMETERS_SUCCESS",

  CHANGE_NAME: "CHANGE_NAME",

  RESET_ERROR: "RESET_ERROR"
};

export const DASHBOARD = {
  SET_LOADING: "SET_LOADING",

  SNACK_TEXTS_CLEARING: "SNACK_TEXTS_CLEARING",

  ERROR_SNACK_OPEN: "ERROR_SNACK_OPEN",
  ERROR_SNACK_CLOSE: "ERROR_SNACK_CLOSE",

  SUCCESS_SNACK_OPEN: "SUCCESS_SNACK_OPEN",
  SUCCESS_SNACK_CLOSE: "SUCCESS_SNACK_CLOSE"
};

export const USERS = {
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",

  DELETE_USER: "DELETE_USER",

  INVITE_USER: "INVITE_USER",
  INVITE_USER_SUCCESS: "INVITE_USER_SUCCESS",

  GET_INVITATION_INFO: "GET_INVITATION_INFO",
  GET_INVITATION_INFO_SUCCESS: "GET_INVITATION_INFO_SUCCESS",

  ACCEPT_INVITATION: "ACCEPT_INVITATION",

  GET_USERS_LIST: "GET_USERS_LIST",
  GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
  GET_USERS_LIST_FAIL: "GET_USERS_LIST_FAIL",

  POST_BLOCK_USER_IN_COMPANY: "POST_BLOCK_USER_IN_COMPANY",
  POST_BLOCK_USER_IN_COMPANY_SUCCESS: "POST_BLOCK_USER_IN_COMPANY_SUCCESS",
  POST_BLOCK_USER_IN_COMPANY_FAIL: "POST_BLOCK_USER_IN_COMPANY_FAIL",

  POST_UNBLOCK_USER_IN_COMPANY: "POST_UNBLOCK_USER_IN_COMPANY",
  POST_UNBLOCK_USER_IN_COMPANY_SUCCESS: "POST_UNBLOCK_USER_IN_COMPANY_SUCCESS",
  POST_UNBLOCK_USER_IN_COMPANY_FAIL: "POST_UNBLOCK_USER_IN_COMPANY_FAIL",

  POST_REMOVE_USER_FROM_COMPANY: "POST_REMOVE_USER_FROM_COMPANY",
  POST_REMOVE_USER_FROM_COMPANY_SUCCESS: "POST_REMOVE_USER_FROM_COMPANY_SUCCESS",
  POST_REMOVE_USER_FROM_COMPANY_FAIL: "POST_REMOVE_USER_FROM_COMPANY_FAIL",

  POST_BLOCK_USER_IN_ALL_CAMPAIGNS: "POST_BLOCK_USER_IN_ALL_CAMPAIGNS",
  POST_BLOCK_USER_IN_ALL_CAMPAIGNS_SUCCESS: "POST_BLOCK_USER_IN_ALL_CAMPAIGNS_SUCCESS",
  POST_BLOCK_USER_IN_ALL_CAMPAIGNS_FAIL: "POST_BLOCK_USER_IN_ALL_CAMPAIGNS_FAIL",

  POST_MAKE_A_REGULAR_USER: "POST_MAKE_A_REGULAR_USER",
  POST_MAKE_A_REGULAR_USER_SUCCESS: "POST_MAKE_A_REGULAR_USER_SUCCESS",
  POST_MAKE_A_REGULAR_USER_FAIL: "POST_MAKE_A_REGULAR_USER_FAIL",

  POST_MAKE_AN_OWNER: "POST_MAKE_AN_OWNER",
  POST_MAKE_AN_OWNER_SUCCESS: "POST_MAKE_AN_OWNER_SUCCESS",
  POST_MAKE_AN_OWNER_FAIL: "POST_MAKE_AN_OWNER_FAIL",

  GET_INVITED_USERS: "GET_INVITED_USERS",
  GET_INVITED_USERS_SUCCESS: "GET_INVITED_USERS_SUCCESS",
  GET_INVITED_USERS_FAIL: "GET_INVITED_USERS_FAIL",

  DELETE_INVITATION: "DELETE_INVITATION",
  DELETE_INVITATION_SUCCESS: "DELETE_INVITATION_SUCCESS",
  DELETE_INVITATION_FAIL: "DELETE_INVITATION_FAIL"
};

export const COMPANIES = {
  SET_CURRENT_COMPANY: "SET_CURRENT_COMPANY",
  SET_CURRENT_COMPANY_SUCCESS: "SET_CURRENT_COMPANY_SUCCESS",
  SET_CURRENT_COMPANY_FAIL: "SET_CURRENT_COMPANY_FAIL",

  CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
  CREATE_CAMPAIGN_SUCCESS: "CREATE_CAMPAIGN_SUCCESS",
  CREATE_CAMPAIGN_FAIL: "CREATE_CAMPAIGN_FAIL",

  RESET_ERRORS: "RESET_ERRORS",

  GET_CURRENT_COMPANY_CAMPAIGNS: "GET_CURRENT_COMPANY_CAMPAIGNS",
  GET_CURRENT_COMPANY_CAMPAIGNS_SUCCESS: "GET_CURRENT_COMPANY_CAMPAIGNS_SUCCESS",

  GET_CAMPAIGNS: "GET_CAMPAIGNS",
  GET_CAMPAIGNS_SUCCESS: "GET_CAMPAIGNS_SUCCESS",

  GET_MANAGERS: "GET_MANAGERS",
  GET_MANAGERS_SUCCESS: "GET_MANAGERS_SUCCESS",

  GET_EU_COUNTRIES: "GET_EU_COUNTRIES",
  GET_EU_COUNTRIES_SUCCESS: "GET_EU_COUNTRIES_SUCCESS",

  GET_COMPANIES: "GET_COMPANIES",
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAIL: "GET_COMPANIES_FAIL",

  POST_NEW_COMPANY: "POST_NEW_COMPANY",
  POST_NEW_COMPANY_SUCCESS: "POST_NEW_COMPANY_SUCCESS",
  POST_NEW_COMPANY_FAIL: "POST_NEW_COMPANY_FAIL",

  GET_COMPANY_DETAILS: "GET_COMPANY_DETAILS",
  GET_COMPANY_DETAILS_SUCCESS: "GET_COMPANY_DETAILS_SUCCESS",
  GET_COMPANY_DETAILS_FAIL: "GET_COMPANY_DETAILS_FAIL",

  PATCH_COMPANY_DETAILS: "PATCH_COMPANY_DETAILS",
  PATCH_COMPANY_DETAILS_SUCCESS: "PATCH_COMPANY_DETAILS_SUCCESS",
  PATCH_COMPANY_DETAILS_FAIL: "PATCH_COMPANY_DETAILS_FAIL",

  POST_ARCHIVE_COMPANY: "POST_ARCHIVE_COMPANY",
  POST_ARCHIVE_COMPANY_SUCCESS: "POST_ARCHIVE_COMPANY_SUCCESS",
  POST_ARCHIVE_COMPANY_FAIL: "POST_ARCHIVE_COMPANY_FAIL",

  POST_RESTORE_COMPANY: "POST_RESTORE_COMPANY",
  POST_RESTORE_COMPANY_SUCCESS: "POST_RESTORE_COMPANY_SUCCESS",
  POST_RESTORE_COMPANY_FAIL: "POST_RESTORE_COMPANY_FAIL",

  POST_LEAVE_COMPANY: "POST_LEAVE_COMPANY",
  POST_LEAVE_COMPANY_SUCCESS: "POST_LEAVE_COMPANY_SUCCESS",
  POST_LEAVE_COMPANY_FAIL: "POST_LEAVE_COMPANY_FAIL",

  SET_COMPANY_ERROR: "SET_COMPANY_ERROR",

  POST_RESTORE_CAMPAIGN: "POST_RESTORE_CAMPAIGN",
  POST_RESTORE_CAMPAIGN_SUCCESS: "POST_RESTORE_CAMPAIGN_SUCCESS",
  POST_RESTORE_CAMPAIGN_FAIL: "POST_RESTORE_CAMPAIGN_FAIL",

  POST_ARCHIVE_CAMPAIGN: "POST_ARCHIVE_CAMPAIGN",
  POST_ARCHIVE_CAMPAIGN_SUCCESS: "POST_ARCHIVE_CAMPAIGN_SUCCESS",
  POST_ARCHIVE_CAMPAIGN_FAIL: "POST_ARCHIVE_CAMPAIGN_FAIL",

  POST_DUPLICATE_CAMPAIGN: "POST_DUPLICATE_CAMPAIGN",
  POST_DUPLICATE_CAMPAIGN_SUCCESS: "POST_DUPLICATE_CAMPAIGN_SUCCESS",
  POST_DUPLICATE_CAMPAIGN_FAIL: "POST_DUPLICATE_CAMPAIGN_FAIL",

  POST_LEAVE_CAMPAIGN: "POST_LEAVE_CAMPAIGN",
  POST_LEAVE_CAMPAIGN_SUCCESS: "POST_LEAVE_CAMPAIGN_SUCCESS",
  POST_LEAVE_CAMPAIGN_FAIL: "POST_LEAVE_CAMPAIGN_FAIL",

  GET_COMPANY_ADDRESSES: "GET_COMPANY_ADDRESSES",
  GET_COMPANY_ADDRESSES_SUCCESS: "GET_COMPANY_ADDRESSES_SUCCESS",
  GET_COMPANY_ADDRESSES_FAIL: "GET_COMPANY_ADDRESSES_FAIL",

  POST_ADD_ADDRESS: "POST_ADD_ADDRESS",
  POST_ADD_ADDRESS_SUCCESS: "POST_ADD_ADDRESS_SUCCESS",
  POST_ADD_ADDRESS_FAIL: "POST_ADD_ADDRESS_FAIL",

  GET_COMPANY_OPTIONS: "GET_COMPANY_OPTIONS",
  GET_COMPANY_OPTIONS_SUCCESS: "GET_COMPANY_OPTIONS_SUCCESS",
  GET_COMPANY_OPTIONS_FAIL: "GET_COMPANY_OPTIONS_FAIL",

  GET_COMPANY_ACCESS_TOKEN: "GET_COMPANY_ACCESS_TOKEN",
  GET_COMPANY_ACCESS_TOKEN_SUCCESS: "GET_COMPANY_ACCESS_TOKEN_SUCCESS",
  GET_COMPANY_ACCESS_TOKEN_FAIL: "GET_COMPANY_ACCESS_TOKEN_FAIL",

  POST_GENERATE_ACCESS_TOKEN: "POST_GENERATE_ACCESS_TOKEN",
  POST_GENERATE_ACCESS_TOKEN_SUCCESS: "POST_GENERATE_ACCESS_TOKEN_SUCCESS",
  POST_GENERATE_ACCESS_TOKEN_FAIL: "POST_GENERATE_ACCESS_TOKEN_FAIL",

  CHECK_REGISTRY_CODE: "CHECK_REGISTRY_CODE",

  RESET_COMPANY_OPTIONS_SUCCESS: "RESET_COMPANY_OPTIONS_SUCCESS"
};

export const CAMPAIGN = {
  RESET_ERRORS: "RESET_ERRORS",

  SET_ERROR: "SET_ERROR",
  SET_ACCESS_ERROR: "SET_ACCESS_ERROR",
  SET_EDITOR_RULES_ARE_CHANGING: "SET_EDITOR_RULES_ARE_CHANGING",

  BLOCK_OTHER_ACTIONS: "BLOCK_OTHER_ACTIONS",

  GET_INFO: "GET_INFO",
  GET_INFO_SUCCESS: "GET_INFO_SUCCESS",
  GET_INFO_FAIL: "GET_INFO_FAIL",

  GET_MOBILE_PREVIEW: "GET_MOBILE_PREVIEW",
  GET_MOBILE_PREVIEW_SUCCESS: "GET_MOBILE_PREVIEW_SUCCESS",

  GET_CAMPAIGN_STEP_RESULTS: "GET_CAMPAIGN_STEP_RESULTS",
  GET_CAMPAIGN_STEP_RESULTS_SUCCESS: "GET_CAMPAIGN_STEP_RESULTS_SUCCESS",

  PATCH_NAME: "PATCH_NAME",
  PATCH_NAME_SUCCESS: "PATCH_NAME_SUCCESS",

  DELETE_CAMPAIGN: "DELETE_CAMPAIGN",
  DELETE_CAMPAIGN_SUCCESS: "DELETE_CAMPAIGN_SUCCESS",
  DELETE_CAMPAIGN_FAIL: "DELETE_CAMPAIGN_FAIL",

  POST_PUBLISH: "POST_PUBLISH",
  POST_PUBLISH_SUCCESS: "POST_PUBLISH_SUCCESS",
  POST_PUBLISH_FAIL: "POST_PUBLISH_FAIL",

  POST_UNPUBLISH: "POST_UNPUBLISH",

  POST_STOP_CAMPAIGN: "POST_STOP_CAMPAIGN",
  POST_STOP_CAMPAIGN_SUCCESS: "POST_STOP_CAMPAIGN_SUCCESS",

  POST_EDITOR: "POST_EDITOR",
  POST_EDITOR_SUCCESS: "POST_EDITOR_SUCCESS",

  DELETE_EDITOR: "DELETE_EDITOR",
  DELETE_EDITOR_SUCCESS: "DELETE_EDITOR_SUCCESS",
  DELETE_EDITOR_FAIL: "DELETE_EDITOR_FAIL",

  POST_VIEWER: "POST_VIEWER",
  POST_VIEWER_SUCCESS: "POST_VIEWER_SUCCESS",

  DELETE_VIEWER: "DELETE_VIEWER",
  DELETE_VIEWER_SUCCESS: "DELETE_VIEWER_SUCCESS",

  GET_INTERESTS: "GET_INTERESTS",
  GET_INTERESTS_SUCCESS: "GET_INTERESTS_SUCCESS",

  GET_AUDIENCE: "GET_AUDIENCE",
  GET_AUDIENCE_SUCCESS: "GET_AUDIENCE_SUCCESS",

  GET_POTENTIAL_REACH: "GET_POTENTIAL_REACH",
  GET_POTENTIAL_REACH_SUCCESS: "GET_POTENTIAL_REACH_SUCCESS",

  PATCH_AUDIENCE: "PATCH_AUDIENCE",
  PATCH_AUDIENCE_FAIL: "PATCH_AUDIENCE_FAIL",
  PATCH_AUDIENCE_SUCCESS: "PATCH_AUDIENCE_SUCCESS",

  GET_DISTRIBUTION: "GET_DISTRIBUTION",
  GET_DISTRIBUTION_SUCCESS: "GET_DISTRIBUTION_SUCCESS",

  PATCH_DISTRIBUTION: "PATCH_DISTRIBUTION",
  PATCH_DISTRIBUTION_FAIL: "PATCH_DISTRIBUTION_FAIL",
  PATCH_DISTRIBUTION_SUCCESS: "PATCH_DISTRIBUTION_SUCCESS",

  GET_COST: "GET_COST",
  GET_COST_SUCCESS: "GET_COST_SUCCESS",

  GET_ANALYTICS: "GET_ANALYTICS",
  GET_ANALYTICS_SUCCESS: "GET_ANALYTICS_SUCCESS",
  GET_ANALYTICS_FAIL: "GET_ANALYTICS_FAIL",

  GET_DELIVERIES: "GET_DELIVERIES",
  GET_DELIVERIES_SUCCESS: "GET_DELIVERIES_SUCCESS",
  GET_DELIVERIES_FAIL: "GET_DELIVERIES_FAIL",

  GET_COMPANY_DELIVERIES: "GET_COMPANY_DELIVERIES",
  GET_COMPANY_DELIVERIES_SUCCESS: "GET_COMPANY_DELIVERIES_SUCCESS",
  GET_COMPANY_DELIVERIES_FAIL: "GET_COMPANY_DELIVERIES_FAIL",
  SET_COMPANY_DELIVERIES_META: "SET_COMPANY_DELIVERIES_META",

  POST_DELIVERY_DELIVER_TO_PICKUP_STATUS: "POST_DELIVERY_DELIVER_TO_PICKUP_STATUS",
  POST_DELIVERY_DELIVER_TO_PICKUP_STATUS_SUCCESS: "POST_DELIVERY_DELIVER_TO_PICKUP_STATUS_SUCCESS",
  POST_DELIVERY_DELIVER_TO_PICKUP_STATUS_FAIL: "POST_DELIVERY_DELIVER_TO_PICKUP_STATUS_FAIL",

  POST_DELIVERY_READY_FOR_PICKUP_STATUS: "POST_DELIVERY_READY_FOR_PICKUP_STATUS",
  POST_DELIVERY_READY_FOR_PICKUP_STATUS_SUCCESS: "POST_DELIVERY_READY_FOR_PICKUP_STATUS_SUCCESS",
  POST_DELIVERY_READY_FOR_PICKUP_STATUS_FAIL: "POST_DELIVERY_READY_FOR_PICKUP_STATUS_FAIL",

  POST_DELIVERY_NEEDS_DELIVERY_STATUS: "POST_DELIVERY_NEEDS_DELIVERY_STATUS",
  POST_DELIVERY_NEEDS_DELIVERY_STATUS_SUCCESS: "POST_DELIVERY_NEEDS_DELIVERY_STATUS_SUCCESS",
  POST_DELIVERY_NEEDS_DELIVERY_STATUS_FAIL: "POST_DELIVERY_NEEDS_DELIVERY_STATUS_FAIL",

  POST_DELIVERY_DONE_STATUS: "POST_DELIVERY_DONE_STATUS",
  POST_DELIVERY_DONE_STATUS_SUCCESS: "POST_DELIVERY_DONE_STATUS_SUCCESS",
  POST_DELIVERY_DONE_STATUS_FAIL: "POST_DELIVERY_DONE_STATUS_FAIL",

  POST_DELIVERY_ON_DELIVERY_STATUS: "POST_DELIVERY_ON_DELIVERY_STATUS",
  POST_DELIVERY_ON_DELIVERY_STATUS_SUCCESS: "POST_DELIVERY_ON_DELIVERY_STATUS_SUCCESS",
  POST_DELIVERY_ON_DELIVERY_STATUS_FAIL: "POST_DELIVERY_ON_DELIVERY_STATUS_FAIL",

  POST_DELIVERY_REDEEMED_STATUS: "POST_DELIVERY_REDEEMED_STATUS",
  POST_DELIVERY_REDEEMED_STATUS_SUCCESS: "POST_DELIVERY_REDEEMED_STATUS_SUCCESS",
  POST_DELIVERY_REDEEMED_STATUS_FAIL: "POST_DELIVERY_REDEEMED_STATUS_FAIL",

  PATCH_DELIVERY_INFO: "PATCH_DELIVERY_INFO",
  PATCH_DELIVERY_INFO_SUCCESS: "PATCH_DELIVERY_INFO_SUCCESS",
  PATCH_DELIVERY_INFO_FAIL: "PATCH_DELIVERY_INFO_FAIL",

  GET_HASHTAGS_SUGGESTIONS: "GET_HASHTAGS_SUGGESTIONS",
  GET_HASHTAGS_SUGGESTIONS_SUCCESS: "GET_HASHTAGS_SUGGESTIONS_SUCCESS",
  GET_HASHTAGS_SUGGESTIONS_FAIL: "GET_HASHTAGS_SUGGESTIONS_FAIL",

  POST_HASHTAG: "POST_HASHTAG",
  POST_HASHTAG_SUCCESS: "POST_HASHTAG_SUCCESS",
  POST_HASHTAG_FAIL: "POST_HASHTAG_FAIL",

  SET_DISTRIBUTION_ERROR: "SET_DISTRIBUTION_ERROR",
  SET_AUDIENCE_ERROR: "SET_AUDIENCE_ERROR",

  FILTER_PUBLISH_VALIDATION_ERROR: "FILTER_PUBLISH_VALIDATION_ERROR",

  RESET_CAMPAIGN: "RESET_CAMPAIGN",

  SET_CURRENT_STEP_INFO: "SET_CURRENT_STEP_INFO"
};

export const STEPS = {
  GET_CAMPAIGN_STEPS: "GET_CAMPAIGN_STEPS",
  GET_CAMPAIGN_STEPS_SUCCESS: "GET_CAMPAIGN_STEPS_SUCCESS",

  POST_ONE: "POST_ONE",
  POST_ONE_SUCCESS: "POST_ONE_SUCCESS",

  DELETE_ONE: "DELETE_ONE",
  DELETE_ONE_SUCCESS: "DELETE_ONE_SUCCESS",

  POST_CAMPAIGN_STEPS: "POST_CAMPAIGN_STEPS",
  POST_CAMPAIGN_STEPS_SUCCESS: "POST_CAMPAIGN_STEPS_SUCCESS",

  POST_ORDER: "POST_ORDER",

  PATCH_LOCATION: "PATCH_LOCATION",
  PATCH_LOCATION_FAIL: "PATCH_LOCATION_FAIL",

  PATCH_URL: "PATCH_URL",
  PATCH_URL_FAIL: "PATCH_URL_FAIL",

  PATCH_VIDEO: "PATCH_VIDEO",
  PATCH_VIDEO_FAIL: "PATCH_VIDEO_FAIL",

  PATCH_CHECK_TEXT: "PATCH_CHECK_TEXT",
  PATCH_CHECK_TEXT_FAIL: "PATCH_CHECK_TEXT_FAIL",

  PATCH_SHORT_TEXT: "PATCH_SHORT_TEXT",
  PATCH_SHORT_TEXT_FAIL: "PATCH_SHORT_TEXT_FAIL",

  PATCH_PHOTO: "PATCH_PHOTO",
  PATCH_PHOTO_FAIL: "PATCH_PHOTO_FAIL",

  PATCH_RATING: "PATCH_RATING",
  PATCH_RATING_FAIL: "PATCH_RATING_FAIL",

  PATCH_YES_NO: "PATCH_YES_NO",
  PATCH_YES_NO_FAIL: "PATCH_YES_NO_FAIL",

  PATCH_IMAGE: "PATCH_IMAGE",
  PATCH_IMAGE_FAIL: "PATCH_IMAGE_FAIL",

  PATCH_MULTIPLE: "PATCH_MULTIPLE",
  PATCH_MULTIPLE_FAIL: "PATCH_MULTIPLE_FAIL",

  PATCH_QUIZ: "PATCH_QUIZ",
  PATCH_QUIZ_FAIL: "PATCH_QUIZ_FAIL",

  PATCH_PICTURES: "PATCH_PICTURES",
  PATCH_PICTURES_FAIL: "PATCH_PICTURES_FAIL",

  POST_PICTURE: "POST_PICTURE",
  POST_PICTURE_SUCCESS: "POST_PICTURE_SUCCESS",

  PATCH_NEW_BARCODE: "PATCH_NEW_BARCODE",
  PATCH_NEW_BARCODE_FAIL: "PATCH_NEW_BARCODE_FAIL",

  PATCH_NEW_QR: "PATCH_NEW_QR",
  PATCH_NEW_QR_FAIL: "PATCH_NEW_QR_FAIL",

  PATCH_SCAN_QR: "PATCH_SCAN_QR",
  PATCH_SCAN_QR_FAIL: "PATCH_SCAN_QR_FAIL",

  PATCH_MONETARY: "PATCH_MONETARY",
  PATCH_MONETARY_FAIL: "PATCH_MONETARY_FAIL",

  PATCH_BONUS: "PATCH_BONUS",
  PATCH_BONUS_FAIL: "PATCH_BONUS_FAIL",

  PATCH_DISCOUNT: "PATCH_DISCOUNT",
  PATCH_DISCOUNT_FAIL: "PATCH_DISCOUNT_FAIL",

  PATCH_NOVELTY: "PATCH_NOVELTY",
  PATCH_NOVELTY_FAIL: "PATCH_NOVELTY_FAIL",

  DELETE_PICTURE: "DELETE_PICTURE",

  REWRITE_PICTURES: "REWRITE_PICTURES"
};

export const PRODUCTS = {
  GET_LIST: "GET_LIST",
  GET_LIST_SUCCESS: "GET_LIST_SUCCESS",

  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",

  POST_PRODUCT: "POST_PRODUCT",
  POST_PRODUCT_FAIL: "POST_PRODUCT_FAIL",

  GET_PRODUCT: "GET_PRODUCT",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",

  GET_COLORS: "GET_COLORS",
  GET_COLORS_SUCCESS: "GET_COLORS_SUCCESS",

  POST_PRODUCT_IMAGE: "POST_PRODUCT_IMAGE",
  POST_PRODUCT_IMAGE_SUCCESS: "POST_PRODUCT_IMAGE_SUCCESS",

  PATCH_PRODUCT: "PATCH_PRODUCT",
  PATCH_PRODUCT_FAIL: "PATCH_PRODUCT_FAIL",

  DELETE_PRODUCT: "DELETE_PRODUCT",
  DELETE_PRODUCT_FAIL: "DELETE_PRODUCT_FAIL",

  SET_ERROR: "SET_ERROR",
  RESET_ERRORS: "RESET_ERRORS",
  RESET_PRODUCTS: "RESET_PRODUCTS"
};

export const PROFILE = {
  POST_CONNECT_GOOGLE: "POST_CONNECT_GOOGLE",
  POST_CONNECT_GOOGLE_SUCCESS: "POST_CONNECT_GOOGLE_SUCCESS",
  POST_CONNECT_GOOGLE_FAIL: "POST_CONNECT_GOOGLE_FAIL",

  POST_DISCONNECT_GOOGLE: "POST_DISCONNECT_GOOGLE",
  POST_DISCONNECT_GOOGLE_SUCCESS: "POST_DISCONNECT_GOOGLE_SUCCESS",
  POST_DISCONNECT_GOOGLE_FAIL: "POST_DISCONNECT_GOOGLE_FAIL",

  POST_CONNECT_FACEBOOK: "POST_CONNECT_FACEBOOK",
  POST_CONNECT_FACEBOOK_SUCCESS: "POST_CONNECT_FACEBOOK_SUCCESS",
  POST_CONNECT_FACEBOOK_FAIL: "POST_CONNECT_FACEBOOK_FAIL",

  POST_DISCONNECT_FACEBOOK: "POST_DISCONNECT_FACEBOOK",
  POST_DISCONNECT_FACEBOOK_SUCCESS: "POST_DISCONNECT_FACEBOOK_SUCCESS",
  POST_DISCONNECT_FACEBOOK_FAIL: "POST_DISCONNECT_FACEBOOK_FAIL",

  POST_DISCONNECT_APPLE: "POST_DISCONNECT_APPLE",
  POST_DISCONNECT_APPLE_SUCCESS: "POST_DISCONNECT_APPLE_SUCCESS",
  POST_DISCONNECT_APPLE_FAIL: "POST_DISCONNECT_APPLE_FAIL",
  POST_CONNECT_APPLE_FAIL: "POST_CONNECT_APPLE_FAIL",

  PATCH_PROFILE: "PATCH_PROFILE",
  PATCH_PROFILE_SUCCESS: "PATCH_PROFILE_SUCCESS",
  PATCH_PROFILE_FAIL: "PATCH_PROFILE_FAIL",

  PATCH_LANGUAGE: "PATCH_LANGUAGE",

  POST_AVATAR: "POST_AVATAR",
  POST_AVATAR_SUCCESS: "POST_AVATAR_SUCCESS",
  POST_AVATAR_FAIL: "POST_AVATAR_FAIL",

  POST_NEW_EMAIL: "POST_NEW_EMAIL",
  POST_NEW_EMAIL_SUCCESS: "POST_NEW_EMAIL_SUCCESS",
  POST_NEW_EMAIL_FAIL: "POST_NEW_EMAIL_FAIL",

  POST_CODE: "POST_CODE",
  POST_CODE_SUCCESS: "POST_CODE_SUCCESS",
  POST_CODE_FAIL: "POST_CODE_FAIL",

  POST_CHANGE_PASSWORD: "POST_CHANGE_PASSWORD",
  POST_CHANGE_PASSWORD_SUCCESS: "POST_CHANGE_PASSWORD_SUCCESS",
  POST_CHANGE_PASSWORD_FAIL: "POST_CHANGE_PASSWORD_FAIL",

  POST_SET_PASSWORD: "POST_SET_PASSWORD",
  POST_SET_PASSWORD_SUCCESS: "POST_SET_PASSWORD_SUCCESS",
  POST_SET_PASSWORD_FAIL: "POST_SET_PASSWORD_FAIL"
};

export const DATA_SOURCES = {
  GET_DATA_SOURCES_LIST: "GET_DATA_SOURCES_LIST",
  GET_DATA_SOURCES_LIST_SUCCESS: "GET_DATA_SOURCES_LIST_SUCCESS",
  GET_DATA_SOURCES_LIST_FAIL: "GET_DATA_SOURCES_LIST_FAIL",

  POST_NEW_DATA_SOURCE: "POST_NEW_DATA_SOURCE",
  POST_NEW_DATA_SOURCE_SUCCESS: "POST_NEW_DATA_SOURCE_SUCCESS",
  POST_NEW_DATA_SOURCE_FAIL: "POST_NEW_DATA_SOURCE_FAIL",

  PATCH_DATA_SOURCE: "PATCH_DATA_SOURCE",
  PATCH_DATA_SOURCE_SUCCESS: "PATCH_DATA_SOURCE_SUCCESS",
  PATCH_DATA_SOURCE_FAIL: "PATCH_DATA_SOURCE_FAIL",

  GET_DATA_SOURCE: "GET_DATA_SOURCE",
  GET_DATA_SOURCE_SUCCESS: "GET_DATA_SOURCE_SUCCESS",
  GET_DATA_SOURCE_FAIL: "GET_DATA_SOURCE_FAIL",

  POST_REGENERATE_API_KEY: "POST_REGENERATE_API_KEY",
  POST_REGENERATE_API_KEY_SUCCESS: "POST_REGENERATE_API_KEY_SUCCESS",
  POST_REGENERATE_API_KEY_FAIL: "POST_REGENERATE_API_KEY_FAIL",

  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAIL: "GET_PRODUCTS_FAIL",

  POST_ADD_PRODUCTS_FROM_API: "POST_ADD_PRODUCTS_FROM_API",
  POST_ADD_PRODUCTS_FROM_API_SUCCESS: "POST_ADD_PRODUCTS_FROM_API_SUCCESS",
  POST_ADD_PRODUCTS_FROM_API_FAIL: "POST_ADD_PRODUCTS_FROM_API_FAIL",

  RESET_PRODUCTS: "RESET_PRODUCTS",
  RESET_DATA_SOURCES: "RESET_DATA_SOURCES"
};

export const BALANCE = {
  HANDLE_BALANCE_DIALOG: "HANDLE_BALANCE_DIALOG",

  POST_GET_INVOICE: "POST_GET_INVOICE",
  POST_GET_INVOICE_SUCCESS: "POST_GET_INVOICE_SUCCESS",
  POST_GET_INVOICE_FAIL: "POST_GET_INVOICE_FAIL",

  POST_DEPOSIT: "POST_DEPOSIT",
  POST_DEPOSIT_SUCCESS: "POST_DEPOSIT_SUCCESS",
  POST_DEPOSIT_FAIL: "POST_DEPOSIT_FAIL",

  POST_REQUEST_MONEY: "POST_REQUEST_MONEY",
  POST_REQUEST_MONEY_SUCCESS: "POST_REQUEST_MONEY_SUCCESS",
  POST_REQUEST_MONEY_FAIL: "POST_REQUEST_MONEY_FAIL",

  GET_CAMPAIGN_COST: "GET_CAMPAIGN_COST",
  GET_CAMPAIGN_COST_SUCCESS: "GET_CAMPAIGN_COST_SUCCESS",
  GET_CAMPAIGN_COST_FAIL: "GET_CAMPAIGN_COST_FAIL",

  GET_CAMPAIGN_SHARING_FB_COST: "GET_CAMPAIGN_SHARING_FB_COST",
  GET_CAMPAIGN_SHARING_FB_COST_SUCCESS: "GET_CAMPAIGN_SHARING_FB_COST_SUCCESS",
  GET_CAMPAIGN_SHARING_FB_COST_FAIL: "GET_CAMPAIGN_SHARING_FB_COST_FAIL",

  GET_CAMPAIGN_SHARING_LINK_COST: "GET_CAMPAIGN_SHARING_LINK_COST",
  GET_CAMPAIGN_SHARING_LINK_COST_SUCCESS: "GET_CAMPAIGN_SHARING_LINK_COST_SUCCESS",
  GET_CAMPAIGN_SHARING_LINK_COST_FAIL: "GET_CAMPAIGN_SHARING_LINK_COST_FAIL"
};

export const AUDIENCE = {
  GET_CSV_BASED_AUDIENCE: "GET_CSV_BASED_AUDIENCE",
  GET_CSV_BASED_AUDIENCE_SUCCESS: "GET_CSV_BASED_AUDIENCE_SUCCESS",
  GET_CSV_BASED_AUDIENCE_FAIL: "GET_CSV_BASED_AUDIENCE_FAIL",

  POST_CSV_BASED_AUDIENCE: "POST_CSV_BASED_AUDIENCE",
  POST_CSV_BASED_AUDIENCE_SUCCESS: "POST_CSV_BASED_AUDIENCE_SUCCESS",
  POST_CSV_BASED_AUDIENCE_FAIL: "POST_CSV_BASED_AUDIENCE_FAIL",

  GET_CSV_BASED_AUDIENCE_BY_ID: "GET_CSV_BASED_AUDIENCE_BY_ID",
  GET_CSV_BASED_AUDIENCE_BY_ID_SUCCESS: "GET_CSV_BASED_AUDIENCE_BY_ID_SUCCESS",
  GET_CSV_BASED_AUDIENCE_BY_ID_FAIL: "GET_CSV_BASED_AUDIENCE_BY_ID_FAIL",

  PATCH_CSV_BASED_AUDIENCE_BY_ID: "PATCH_CSV_BASED_AUDIENCE_BY_ID",
  PATCH_CSV_BASED_AUDIENCE_BY_ID_SUCCESS: "PATCH_CSV_BASED_AUDIENCE_BY_ID_SUCCESS",
  PATCH_CSV_BASED_AUDIENCE_BY_ID_FAIL: "PATCH_CSV_BASED_AUDIENCE_BY_ID_FAIL",

  RECALCULATE_CSV_BASED_AUDIENCE_BY_ID: "RECALCULATE_CSV_BASED_AUDIENCE_BY_ID",
  RECALCULATE_CSV_BASED_AUDIENCE_BY_ID_SUCCESS: "RECALCULATE_CSV_BASED_AUDIENCE_BY_ID_SUCCESS",
  RECALCULATE_CSV_BASED_AUDIENCE_BY_ID_FAIL: "RECALCULATE_CSV_BASED_AUDIENCE_BY_ID_FAIL",

  DELETE_CSV_BASED_AUDIENCE_BY_ID: "DELETE_CSV_BASED_AUDIENCE_BY_ID",
  DELETE_CSV_BASED_AUDIENCE_BY_ID_SUCCESS: "DELETE_CSV_BASED_AUDIENCE_BY_ID_SUCCESS",
  DELETE_CSV_BASED_AUDIENCE_BY_ID_FAIL: "DELETE_CSV_BASED_AUDIENCE_BY_ID_FAIL",

  GET_ANALYTICS_BASED_AUDIENCE: "GET_ANALYTICS_BASED_AUDIENCE",
  GET_ANALYTICS_BASED_AUDIENCE_SUCCESS: "GET_ANALYTICS_BASED_AUDIENCE_SUCCESS",
  GET_ANALYTICS_BASED_AUDIENCE_FAIL: "GET_ANALYTICS_BASED_AUDIENCE_FAIL",

  GET_ANALYTICS_BASED_AUDIENCE_BY_ID: "GET_ANALYTICS_BASED_AUDIENCE_BY_ID",
  GET_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS: "GET_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS",
  GET_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL: "GET_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL",

  POST_ANALYTICS_BASED_AUDIENCE: "POST_ANALYTICS_BASED_AUDIENCE",
  POST_ANALYTICS_BASED_AUDIENCE_SUCCESS: "POST_ANALYTICS_BASED_AUDIENCE_SUCCESS",
  POST_ANALYTICS_BASED_AUDIENCE_FAIL: "POST_ANALYTICS_BASED_AUDIENCE_FAIL",

  UPDATE_ANALYTICS_BASED_AUDIENCE: "UPDATE_ANALYTICS_BASED_AUDIENCE",
  UPDATE_ANALYTICS_BASED_AUDIENCE_SUCCESS: "UPDATE_ANALYTICS_BASED_AUDIENCE_SUCCESS",
  UPDATE_ANALYTICS_BASED_AUDIENCE_FAIL: "UPDATE_ANALYTICS_BASED_AUDIENCE_FAIL",

  PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID: "PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID",
  PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS: "PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS",
  PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL: "PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL",

  ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID: "ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID",
  ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS: "ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS",
  ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL: "ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL",

  RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID: "RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID",
  RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS: "RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS",
  RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL: "RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL",

  GET_CUSTOM_AUDIENCE: "GET_CUSTOM_AUDIENCE",
  GET_CUSTOM_AUDIENCE_SUCCESS: "GET_CUSTOM_AUDIENCE_SUCCESS",
  GET_CUSTOM_AUDIENCE_FAIL: "GET_CUSTOM_AUDIENCE_FAIL",

  POST_CUSTOM_AUDIENCE: "POST_CUSTOM_AUDIENCE",
  POST_CUSTOM_AUDIENCE_SUCCESS: "POST_CUSTOM_AUDIENCE_SUCCESS",
  POST_CUSTOM_AUDIENCE_FAIL: "POST_CUSTOM_AUDIENCE_FAIL",

  GET_CUSTOM_AUDIENCE_BY_ID: "GET_CUSTOM_AUDIENCE_BY_ID",
  GET_CUSTOM_AUDIENCE_BY_ID_SUCCESS: "GET_CUSTOM_AUDIENCE_BY_ID_SUCCESS",
  GET_CUSTOM_AUDIENCE_BY_ID_FAIL: "GET_CUSTOM_AUDIENCE_BY_ID_FAIL",

  PATCH_CUSTOM_AUDIENCE_BY_ID: "PATCH_CUSTOM_AUDIENCE_BY_ID",
  PATCH_CUSTOM_AUDIENCE_BY_ID_SUCCESS: "PATCH_CUSTOM_AUDIENCE_BY_ID_SUCCESS",
  PATCH_CUSTOM_AUDIENCE_BY_ID_FAIL: "PATCH_CUSTOM_AUDIENCE_BY_ID_FAIL",

  RECALCULATE_CUSTOM_AUDIENCE_BY_ID: "RECALCULATE_CUSTOM_AUDIENCE_BY_ID",
  RECALCULATE_CUSTOM_AUDIENCE_BY_ID_SUCCESS: "RECALCULATE_CUSTOM_AUDIENCE_BY_ID_SUCCESS",
  RECALCULATE_CUSTOM_AUDIENCE_BY_ID_FAIL: "RECALCULATE_CUSTOM_AUDIENCE_BY_ID_FAIL",

  CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS: "CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS",
  CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS_SUCCESS: "CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS_SUCCESS",
  CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS_FAIL: "CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS_FAIL",

  DUPLICATE_CUSTOM_AUDIENCE_BY_ID: "DUPLICATE_CUSTOM_AUDIENCE_BY_ID",
  DUPLICATE_CUSTOM_AUDIENCE_BY_ID_SUCCESS: "DUPLICATE_CUSTOM_AUDIENCE_BY_ID_SUCCESS",
  DUPLICATE_CUSTOM_AUDIENCE_BY_ID_FAIL: "DUPLICATE_CUSTOM_AUDIENCE_BY_ID_FAIL",

  ARCHIVE_CUSTOM_AUDIENCE_BY_ID: "ARCHIVE_CUSTOM_AUDIENCE_BY_ID",
  ARCHIVE_CUSTOM_AUDIENCE_BY_ID_SUCCESS: "ARCHIVE_CUSTOM_AUDIENCE_BY_ID_SUCCESS",
  ARCHIVE_CUSTOM_AUDIENCE_BY_ID_FAIL: "ARCHIVE_CUSTOM_AUDIENCE_BY_ID_FAIL",

  RESTORE_CUSTOM_AUDIENCE_BY_ID: "RESTORE_CUSTOM_AUDIENCE_BY_ID",
  RESTORE_CUSTOM_AUDIENCE_BY_ID_SUCCESS: "RESTORE_CUSTOM_AUDIENCE_BY_ID_SUCCESS",
  RESTORE_CUSTOM_AUDIENCE_BY_ID_FAIL: "RESTORE_CUSTOM_AUDIENCE_BY_ID_FAIL",

  POST_EDITOR_FOR_CUSTOM_AUDIENCE: "POST_EDITOR_FOR_CUSTOM_AUDIENCE",
  POST_EDITOR_FOR_CUSTOM_AUDIENCE_SUCCESS: "POST_EDITOR_FOR_CUSTOM_AUDIENCE_SUCCESS",
  POST_EDITOR_FOR_CUSTOM_AUDIENCE_FAIL: "POST_EDITOR_FOR_CUSTOM_AUDIENCE_FAIL",

  DELETE_EDITOR_FROM_CUSTOM_AUDIENCE: "DELETE_EDITOR_FROM_CUSTOM_AUDIENCE",
  DELETE_EDITOR_FROM_CUSTOM_AUDIENCE_SUCCESS: "DELETE_EDITOR_FROM_CUSTOM_AUDIENCE_SUCCESS",
  DELETE_EDITOR_FROM_CUSTOM_AUDIENCE_FAIL: "DELETE_EDITOR_FROM_CUSTOM_AUDIENCE_FAIL",

  SET_AUDIENCE_META: "SET_AUDIENCE_META",
  SET_CSV_BASED_AUDIENCE_INFO: "SET_CSV_BASED_AUDIENCE_INFO",
  SET_ANALYTICS_BASED_AUDIENCE_INFO: "SET_ANALYTICS_BASED_AUDIENCE_INFO",
  SET_CUSTOM_AUDIENCE_FIELD: "SET_CUSTOM_AUDIENCE_FIELD",
  SET_CUSTOM_AUDIENCE_GROUP_FIELD: "SET_CUSTOM_AUDIENCE_GROUP_FIELD",
  ADD_CUSTOM_SEGMENT_GROUP: "ADD_CUSTOM_SEGMENT_GROUP",
  REMOVE_CUSTOM_SEGMENT_GROUP: "REMOVE_CUSTOM_SEGMENT_GROUP",
  RESET_AUDIENCE_STORE: "RESET_AUDIENCE_STORE",
  RESET_CUSTOM_AUDIENCE_STORE: "RESET_CUSTOM_AUDIENCE_STORE",
  SET_CONFIRMATION_DIALOG: "SET_CONFIRMATION_DIALOG"
};

export const BILLING_REPORTS = {
  GET_CAMPAIGN_REPORT: "GET_CAMPAIGN_REPORT",
  GET_CAMPAIGN_REPORT_SUCCESS: "GET_CAMPAIGN_REPORT_SUCCESS",
  GET_CAMPAIGN_REPORT_FAIL: "GET_CAMPAIGN_REPORT_FAIL",

  GET_PURCHASE_REPORT: "GET_PURCHASE_REPORT",
  GET_PURCHASE_REPORT_SUCCESS: "GET_PURCHASE_REPORT_SUCCESS",
  GET_PURCHASE_REPORT_FAIL: "GET_PURCHASE_REPORT_FAIL",

  SET_CAMPAIGN_REPORT_META: "SET_CAMPAIGN_REPORT_META",
  SET_PURCHASE_REPORT_META: "SET_PURCHASE_REPORT_META",
  RESET_BILLING_REPORTS: "RESET_BILLING_REPORTS"
};
