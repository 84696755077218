import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { withTranslation } from "react-i18next";

import RenderField from "../../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";

import { postSetPassword } from "../../../../actions/profileActions";

class CreatePasswordForm extends Component {
  changeEmail = async data => {
    const { postSetPassword, toggleDialog } = this.props;
    let passwords = {
      new_password: data.new_password,
      new_password_repeat: data.new_password_repeat
    };
    const res = await postSetPassword(passwords);
    if (res.error) {
      throw new SubmissionError({
        new_password: res.error.response.data.new_password[0].message
      });
    } else {
      toggleDialog("create_password");
    }
  };

  render() {
    const { loading, handleSubmit, submitting, pristine, valid, toggleDialog, t } = this.props;
    return (
      <form onSubmit={handleSubmit(this.changeEmail)} className="dialog_form">
        <Field
          name="new_password"
          type="password"
          component={RenderField}
          label={t("t:profile.new-password")}
        />
        <Field
          name="new_password_repeat"
          type="password"
          component={RenderField}
          label={t("t:profile.re-enter-new-password")}
        />
        <div className="buttons_wrapper">
          <DefaultButton
            variant="contained"
            classes="purple_btn"
            formAction
            disabled={submitting || pristine || !valid}
            loading={loading}
          >
            {t("t:common.continue")}
          </DefaultButton>
          <DefaultButton
            variant="contained"
            classes="cancel_btn"
            onClick={() => toggleDialog("create_password")}
          >
            {t("t:common.cancel")}
          </DefaultButton>
        </div>
      </form>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.new_password) {
    errors.new_password = t("t:common.field-is-required");
  } else if (values.new_password.length < 8) {
    errors.new_password = t("t:common.min-8-characters");
  }
  if (!values.new_password_repeat) {
    errors.new_password_repeat = t("t:common.field-is-required");
  }
  if (
    values.new_password &&
    values.new_password_repeat &&
    values.new_password !== values.new_password_repeat
  ) {
    errors.new_password_repeat = t("t:auth.passwords-not-match");
  }
  return errors;
};

CreatePasswordForm = reduxForm({
  form: "CreatePasswordForm",
  validate
})(CreatePasswordForm);

const mapStateToProps = ({ dashboard }) => {
  return {
    loading: dashboard.loadingComponent
  };
};

const mapDispatchToProps = {
  postSetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreatePasswordForm));
