import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { resentCode } from "../../../actions/authActions";
import Snack from "../../HelperComponents/Snack/Snack";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import {
  TERMS_AND_CONDITIONS_URL,
  TERMS_AND_CONDITIONS_ESTONIAN_URL,
  PRIVACY_POLICY_URL
} from "../../../config";

class ConfirmYourEmail extends Component {
  constructor(props) {
    super(props);
    const email = localStorage.getItem("email");
    this.state = {
      loading: false,
      email,
      errorSnack: false,
      successSnack: false
    };
  }

  componentDidMount() {
    const { email } = this.state;
    const { history } = this.props;
    if (!email) {
      history.push("/auth/login");
    }
  }

  resentCode = async data => {
    const { resentCode } = this.props;
    const { email } = this.state;
    this.setState({ loading: true });
    await resentCode({ email }).then(res => {
      if (res.payload) {
        this.setState({ loading: false, successSnack: true });
      } else {
        this.setState({ loading: false, errorSnack: true });
      }
    });
  };

  render() {
    const { history, t, i18n } = this.props;
    const { loading, errorSnack, successSnack } = this.state;
    return (
      <div className="sign_in_wrapper">
        <div>
          <div className="auth_redirect" />
          <div className="auth_redirect" />
          <div className="yellow_box max_width_460">{t("t:auth.account-successfully-created")}</div>
          <div className="auth_btn_wrapper sign_in_link_wrapper">
            <DefaultButton
              variant="contained"
              classes="auth_sign_in_link"
              onClick={() => history.push("/auth/login")}
              loading={loading}
            >
              {t("t:auth.sign-in")}
            </DefaultButton>
          </div>
          <div className="resend_link text_hover" onClick={this.resentCode}>
            {t("t:auth.resend-activation-link")}
          </div>
        </div>
        <div className="terms_policy_wrapper">
          <a
            href={
              i18n.language === "et" ? TERMS_AND_CONDITIONS_ESTONIAN_URL : TERMS_AND_CONDITIONS_URL
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("t:auth.terms-and-conditions")}
          </a>
          <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
            {t("t:auth.privacy-policy")}
          </a>
        </div>
        <Snack
          open={errorSnack}
          handleSnack={() => this.setState({ errorSnack: false })}
          message={t("t:auth.link-request-delay")}
          type="error"
        />
        <Snack
          open={successSnack}
          handleSnack={() => this.setState({ successSnack: false })}
          message={t("t:auth.new-activation-link-sent")}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  resentCode
};

export default connect(null, mapDispatchToProps)(withTranslation()(ConfirmYourEmail));
