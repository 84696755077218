import React, { Fragment } from "react";
import { useDrag } from "react-dnd";

export const DraggableComponent = ({ name, children, onDrop, canDrag = true }) => {
  const item = { name, type: "box" };
  const [{ opacity }, drag] = useDrag({
    item,
    canDrag,
    end(item, monitor) {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const isDropAllowed =
          dropResult.allowedDropEffect === "any" ||
          dropResult.allowedDropEffect === dropResult.dropEffect;
        if (isDropAllowed) {
          onDrop(name);
        }
      }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <Fragment>
      <div ref={drag} style={{ margin: "0 15px 15px 0", opacity }}>
        {children}
      </div>
    </Fragment>
  );
};
