import { AUTH } from "../constants";

export function postLogin(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/login/`,
        method: "post",
        data
      }
    }
  };
}

export function postLogout(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/logout/`,
        method: "post"
      }
    }
  };
}

export function postSignUp(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/register/`,
        method: "post",
        data
      }
    }
  };
}

export function resentCode(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/resend-activation-code/`,
        method: "post",
        data
      }
    }
  };
}

export function activateAccount(data) {
  return {
    type: AUTH.ACTIVATE_ACCOUNT,
    payload: {
      client: "default",
      request: {
        url: `/auth/activate/`,
        method: "post",
        data
      }
    }
  };
}

export function postForgotPassword(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/reset-password-send-link/`,
        method: "post",
        data
      }
    }
  };
}

export function postChangePassword(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/reset-password-by-code/`,
        method: "post",
        data
      }
    }
  };
}

export function postGoogleAuth(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/login-google/`,
        method: "post",
        data
      }
    }
  };
}

export function postAppleAuth(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/login-apple/`,
        method: "post",
        data
      }
    }
  };
}

export function postFacebookAuth(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/login-facebook/`,
        method: "post",
        data
      }
    }
  };
}

export function getProfile() {
  return {
    type: AUTH.GET_PROFILE,
    payload: {
      client: "default",
      request: {
        url: `/user/profile/`,
        method: "get"
      }
    }
  };
}

export function getGlobalParameters() {
  return {
    type: AUTH.GET_GLOBAL_PARAMETERS,
    payload: {
      client: "default",
      request: {
        url: `/user/parameters`,
        method: "get"
      }
    }
  };
}

export function changeName(data) {
  return {
    type: AUTH.CHANGE_NAME,
    payload: {
      client: "default",
      request: {
        url: `/user/profile/`,
        method: "patch",
        data
      }
    }
  };
}

export function resetAuthError(data) {
  return {
    type: AUTH.RESET_ERROR
  };
}
