import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ErrorIcon from "../../../assets/image/c-warning-e.svg";
import { withTranslation } from "react-i18next";

import { postLogin, resetAuthError, activateAccount } from "../../../actions/authActions";

import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import AuthButtons from "../AuthButtons/AuthButtons";
import LanguageSwitcher from "../../HelperComponents/LanguageSwitcher";
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  TERMS_AND_CONDITIONS_ESTONIAN_URL
} from "../../../config";

class Login extends Component {
  state = {
    loading: false,
    mounted: false
  };

  componentDidMount() {
    const { location, activateAccount, history } = this.props;
    const params = new URLSearchParams(location.search.substring(1));
    const activation_code = params.get("activation_code");
    if (activation_code) {
      activateAccount({ code: activation_code }).then(res => {
        if (res.payload) {
          localStorage.token = res.payload.data.token;
          history.push("/main");
        } else {
          history.push("/auth/login");
          this.setState({ mounted: true });
        }
      });
    } else {
      this.setState({ mounted: true });
    }
    this.props.resetAuthError();
    localStorage.removeItem("email");
  }

  submitForm = data => {
    const { postLogin, history, resetAuthError } = this.props;
    this.setState({ loading: true });
    resetAuthError();
    return postLogin(data).then(res => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        localStorage.token = res.payload.data.token;
        history.push("/main");
      } else {
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { handleSubmit, submitting, pristine, valid, authError, t, i18n } = this.props;
    const { loading, mounted } = this.state;
    if (!mounted) return null;
    return (
      <div className="sign_in_wrapper">
        <div>
          <div className="auth_redirect with-lng-switcher">
            <LanguageSwitcher />
            <div>
              {t("t:auth.dont-have-account")}
              <Link to="/auth/sign-up" className="text_hover">
                {t("t:auth.sign-up-now")}
              </Link>
            </div>
          </div>
          <div className="auth_block_head">{t("t:auth.company-sign-in")}</div>
          <AuthButtons />
          <div className="or_separator">
            <hr />
            <span>{t("t:common.or")}</span>
          </div>
          <form onSubmit={handleSubmit(this.submitForm)} className="login_form">
            <Field
              name="email"
              type="text"
              component={RenderField}
              label={t("t:common.email")}
              placeholder={t("t:auth.enter-your-email")}
            />
            <Field
              name="password"
              type="password"
              component={RenderField}
              label={t("t:common.password")}
              placeholder={t("t:auth.enter-your-password")}
            />
            <div className="auth_btn_wrapper login_auth_buttons">
              <DefaultButton
                variant="contained"
                classes="auth"
                disabled={submitting || pristine || !valid}
                loading={loading}
                formAction
              >
                {t("t:auth.sign-in")}
              </DefaultButton>
              {authError ? (
                <TooltipMessage text={authError} delay={200} error position="right" classes="">
                  <img src={ErrorIcon} alt="error" />
                </TooltipMessage>
              ) : (
                ""
              )}
            </div>
            <Link to="/auth/forgot-password" className="forgot_password">
              {t("t:auth.forgot-password")}
            </Link>
          </form>
        </div>
        <div className="terms_policy_wrapper">
          <a
            href={
              i18n.language === "et" ? TERMS_AND_CONDITIONS_ESTONIAN_URL : TERMS_AND_CONDITIONS_URL
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("t:auth.terms-and-conditions")}
          </a>
          <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
            {t("t:auth.privacy-policy")}
          </a>
        </div>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.email) {
    errors.email = t("t:common.field-is-required");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = t("t:auth.please-enter-correct-email");
  }
  if (!values.password) {
    errors.password = t("t:common.field-is-required");
  } else if (values.password.length < 8) {
    errors.password = t("t:common.min-8-characters");
  }
  return errors;
};

Login = reduxForm({
  form: "LoginForm",
  validate
})(Login);

const mapStateToProps = state => {
  return {
    authError: state.auth.error_auth
  };
};
const mapDispatchToProps = {
  postLogin,
  resetAuthError,
  activateAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
