import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import TagsComponent from "../../../../HelperComponents/TagsComponent";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";

const Hashtags = ({
  audienceError,
  hashtags,
  changeTags,
  getHashtagSuggestions,
  postHashtag,
  isEditor
}) => {
  const { t } = useTranslation();
  return (
    <div className="audience_row_wrapper">
      <div className="row_name">
        {t("t:campaign-audience.hashtags")}
        {audienceError.hashtags ? (
          <TooltipMessage
            text={audienceError.hashtags[0].message}
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
      </div>
      <div className="row_content">
        <TagsComponent
          tags={hashtags}
          changeTags={changeTags}
          getSuggestions={getHashtagSuggestions}
          addNewHashtag={postHashtag}
          disabled={!isEditor}
        />
      </div>
    </div>
  );
};

export default memo(Hashtags);
