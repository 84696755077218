import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  deleteOneStep,
  patchPicturesStep,
  postPicture,
  resetCampaignErrors,
  getCampaignSteps,
  blockOtherActions,
  deletePicture,
  rewritePictures,
  setCurrentStepInfo
} from "../../../../../actions/campaignActions";
import { setLoading } from "../../../../../actions/dashboardActions";
import { renderStepPlaceholder, validateImage } from "../../../../../helpers/functions";
import Snack from "../../../../HelperComponents/Snack/Snack";
import PicturesExpandedView from "./PicturesExpandedView";

import layers from "../../../../../assets/image/My_Campaign/layers.svg";
import eye from "../../../../../assets/image/My_Campaign/eye.svg";
import edit from "../../../../../assets/image/btn-edit.svg";
import deleteIcon from "../../../../../assets/image/My_Campaign/btn-cancel.svg";
import dragHandle from "../../../../../assets/image/drag-handle.svg";

import "./styles.scss";

class Pictures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: props.match.params.id,
      popperOpened: false,
      title: props.detail.title,
      description: props.detail.description,
      typeRadio: props.detail.type || "single",
      images: props.detail.images,
      openSnack: false,
      previewDialogOpen: false,
      openErrorSnack: false,
      openMinimumImagesSnack: false,
      currentImageIndex: "",
      imagesToCrop: []
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.popperOpened !== this.state.popperOpened) {
      this.props.handleEdit(this.props.id);
      this.props.blockOtherActions(this.state.popperOpened);
      this.props.isEditor && this.props.handleEditingTimer();
      if (this.state.popperOpened) {
        this.setInfoForMobilePreview();
      } else {
        this.props.setCurrentStepInfo({
          step: "not-selected"
        });
      }
    }

    if (this.infoForPreviewWasChanged(this.state, prevState)) {
      this.setInfoForMobilePreview();
    }

    if (this.props.campaignError) {
      if (prevState !== this.state) {
        this.props.resetCampaignErrors();
      }
    }

    if (prevProps.pictures !== this.props.pictures) {
      this.setState({ images: this.props.pictures });
      this.props.setLoading(false);
    }

    if (prevState.currentImageIndex !== this.state.currentImageIndex) {
      if (this.state.currentImageIndex !== "") {
        setTimeout(() => {
          this.setState({ previewDialogOpen: true });
        }, 50);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCampaignErrors();
    this.props.rewritePictures([]);
  }

  infoForPreviewWasChanged = (currState, prevState) => {
    return (
      currState.title !== prevState.title ||
      currState.description !== prevState.description ||
      JSON.stringify(currState.images) !== JSON.stringify(prevState.images)
    );
  };

  setInfoForMobilePreview = () => {
    const { title, description, images } = this.state;

    this.props.setCurrentStepInfo({
      step: "pictures",
      pictures: {
        title,
        description,
        images
      }
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = result => {
    const { images, title, description } = this.state;
    if (!result.destination) {
      return;
    }
    const items = this.reorder(images, result.source.index, result.destination.index);
    this.setState({ images: items });

    this.props.setCurrentStepInfo({
      step: "pictures",
      pictures: {
        title,
        description,
        images: items
      }
    });
  };

  openPopper = () => this.setState({ popperOpened: true });

  closePopper = () => this.setState({ popperOpened: false });

  deleteStep = () => {
    const { deleteOneStep, id, campaign, handleEditingTimer } = this.props;
    deleteOneStep(campaign, id);
    handleEditingTimer();
    this.closePopper();
  };

  changeStep = async () => {
    const { patchPicturesStep, id, getCampaignSteps, setLoading, handleEditingTimer } = this.props;
    const { title, description, images, typeRadio, campaignId } = this.state;
    if (images.length < 2) {
      this.setState({ openMinimumImagesSnack: true });
    } else {
      setLoading(true);
      const data = {
        title,
        description,
        type: typeRadio,
        images
      };
      const res = await patchPicturesStep(campaignId, id, data);
      handleEditingTimer();
      if (res.payload) {
        getCampaignSteps(campaignId).then(() => setLoading(false));
        this.setState({ openSnack: true });
        this.closePopper();
      } else {
        setLoading(false);
      }
    }
  };

  handleFiles = files => {
    const { setLoading } = this.props;
    setLoading(true);
    this.validateFiles(files).then(res => {
      if (!res) {
        this.setState({ openErrorSnack: true });
      } else {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onloadend = () =>
            this.setState(({ imagesToCrop }) => ({
              imagesToCrop: [...imagesToCrop, { dataUrl: [reader.result] }]
            }));
        }
      }
    });
  };

  validateFiles = async files => {
    let validationPassed = true;
    for await (const el of files) {
      if (!validateImage(el)) {
        validationPassed = false;
      }
    }
    return validationPassed;
  };

  uploadImage = canvas => {
    const { postPicture, id } = this.props;
    const { campaignId, imagesToCrop } = this.state;
    const tempImagesToCrop = imagesToCrop.slice(1);
    this.setState({ imagesToCrop: [] });
    canvas.toBlob(blob => {
      const formData = new FormData();
      formData.append("image", blob, "picture.png");
      postPicture(campaignId, id, formData);
      this.setState({ imagesToCrop: tempImagesToCrop });
    });
  };

  onCancelCropping = () => this.setState({ imagesToCrop: [] });

  deletePicture = async key => {
    const { deletePicture, rewritePictures } = this.props;
    const { images } = this.state;
    await rewritePictures(images);
    deletePicture(key);
  };

  openDialog = key => this.setState({ currentImageIndex: key });

  onTitleChange = textInput => {
    const { title } = this.state;
    if (textInput.length < 51 || textInput.length < title) {
      this.setState({ title: textInput });
    } else {
      this.setState({ title: textInput.slice(0, 50) });
    }
  };

  render() {
    const { type, detail, blockActions, isEditor, t, campaignError } = this.props;
    const {
      popperOpened,
      openSnack,
      images,
      title,
      description,
      typeRadio,
      previewDialogOpen,
      openErrorSnack,
      openMinimumImagesSnack,
      currentImageIndex,
      imagesToCrop
    } = this.state;
    return (
      <div className="wrapper_block_my_campaign step_wrapper image_wrapper">
        <div className="head_block head_block_step">
          <div className="title_head">
            <div className="not_expanded">
              <img src={dragHandle} alt="draggable" />
              <img src={layers} alt="layers" />
            </div>
            <div>
              <p>{t("t:build-campaign.pictures")}</p>
              <span>{renderStepPlaceholder(detail.title, type, t, images.length)}</span>
            </div>
          </div>
          {isEditor ? (
            <div className="btn_head">
              <button onClick={this.deleteStep} disabled={blockActions}>
                <img src={deleteIcon} alt="trash" />
              </button>
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={edit} alt="save" />
              </button>
            </div>
          ) : (
            <div className="btn_head">
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={eye} alt="open" />
              </button>
            </div>
          )}
        </div>
        {popperOpened && (
          <div className="responses_wrapper wrapper_block_my_campaign pictures_wrapper">
            <PicturesExpandedView
              type={type}
              detail={detail}
              toClosePopper={this.closePopper}
              title={title}
              description={description}
              images={images}
              typeRadio={typeRadio}
              previewDialogOpen={previewDialogOpen}
              openErrorSnack={openErrorSnack}
              openMinimumImagesSnack={openMinimumImagesSnack}
              currentImageIndex={currentImageIndex}
              imagesToCrop={imagesToCrop}
              isEditor={isEditor}
              campaignError={campaignError}
              onDragEnd={this.onDragEnd}
              onTitleChange={this.onTitleChange}
              deleteStep={this.deleteStep}
              changeStep={this.changeStep}
              openDialog={this.openDialog}
              deletePicture={this.deletePicture}
              handleFiles={this.handleFiles}
              uploadImage={this.uploadImage}
              onCancelCropping={this.onCancelCropping}
              changeState={fields => this.setState(fields)}
            />
          </div>
        )}
        <Snack
          open={openSnack}
          handleSnack={() => this.setState({ openSnack: false })}
          message={t("t:snack-messages.successfully-changed")}
          type="success"
        />
      </div>
    );
  }
}

function mapStateToProps({ campaign }) {
  return {
    campaignError: campaign.campaignError,
    blockActions: campaign.blockActions,
    pictures: campaign.pictures,
    isEditor: campaign.campaignViewers.is_editor
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteOneStep,
      patchPicturesStep,
      postPicture,
      getCampaignSteps,
      resetCampaignErrors,
      blockOtherActions,
      setLoading,
      deletePicture,
      rewritePictures,
      setCurrentStepInfo
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Pictures)));
