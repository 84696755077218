import React from "react";

import SuccessIcon from "../../../../../../assets/image/mobile-check.svg";
import DiscountIcon from "../../../../../../assets/image/campycoin.svg";

const Monetary = ({ title, description, price }) => (
  <>
    <img src={SuccessIcon} alt="success" className="success-icon" />
    <div className="monetary-reward">
      <img src={DiscountIcon} alt="%" className="monetary-reward__icon" />
      {(+price || 0).toFixed(2)}
    </div>
    <div className="success-text">
      <strong>{title}</strong>
      <p>{description}</p>
    </div>
  </>
);

export default Monetary;
