import { PROFILE } from "../constants";

export function postConnectGoogle(data) {
  return {
    type: PROFILE.POST_CONNECT_GOOGLE,
    payload: {
      client: "default",
      request: {
        url: `/auth/connect-google/`,
        method: "post",
        data
      }
    }
  };
}

export function postDisconnectGoogle() {
  return {
    type: PROFILE.POST_DISCONNECT_GOOGLE,
    payload: {
      client: "default",
      request: {
        url: `/auth/disconnect-google/`,
        method: "post"
      }
    }
  };
}

export function postConnectFacebook(data) {
  return {
    type: PROFILE.POST_CONNECT_FACEBOOK,
    payload: {
      client: "default",
      request: {
        url: `/auth/connect-facebook/`,
        method: "post",
        data
      }
    }
  };
}

export function postDisconnectFacebook() {
  return {
    type: PROFILE.POST_DISCONNECT_FACEBOOK,
    payload: {
      client: "default",
      request: {
        url: `/auth/disconnect-facebook/`,
        method: "post"
      }
    }
  };
}

export function postDisconnectApple() {
  return {
    type: PROFILE.POST_DISCONNECT_APPLE,
    payload: {
      client: "default",
      request: {
        url: `/auth/disconnect-apple/`,
        method: "post"
      }
    }
  };
}

export function setConnectAppleError(e) {
  return {
    type: PROFILE.POST_CONNECT_APPLE_FAIL,
    payload: e
  };
}

export function patchProfile(data) {
  return {
    type: PROFILE.PATCH_PROFILE,
    payload: {
      client: "default",
      request: {
        url: `/user/profile/`,
        method: "patch",
        data
      }
    }
  };
}

export function patchLanguage(lng) {
  return {
    type: PROFILE.PATCH_LANGUAGE,
    payload: {
      client: "default",
      request: {
        url: `/user/profile/`,
        method: "patch",
        data: { lang_code: lng }
      }
    }
  };
}

export function postAvatar(data) {
  return {
    type: PROFILE.POST_AVATAR,
    payload: {
      client: "default",
      request: {
        url: `/user/set-avatar/`,
        method: "post",
        data
      }
    }
  };
}

export function postNewEmail(data) {
  return {
    type: PROFILE.POST_NEW_EMAIL,
    payload: {
      client: "default",
      request: {
        url: `/user/change-email-step-1-send-code/`,
        method: "post",
        data
      }
    }
  };
}

export function postCode(data) {
  return {
    type: PROFILE.POST_CODE,
    payload: {
      client: "default",
      request: {
        url: `/user/change-email-step-2-verify-code/`,
        method: "post",
        data
      }
    }
  };
}

export function postChangePassword(data) {
  return {
    type: PROFILE.POST_CHANGE_PASSWORD,
    payload: {
      client: "default",
      request: {
        url: `/user/change-password/`,
        method: "post",
        data
      }
    }
  };
}

export function postSetPassword(data) {
  return {
    type: PROFILE.POST_SET_PASSWORD,
    payload: {
      client: "default",
      request: {
        url: `/user/set-password-for-account-without-password/`,
        method: "post",
        data
      }
    }
  };
}
