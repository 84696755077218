import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import clsx from "clsx";

import BuildCampaign from "./BuildCampaign";
import Audience from "./Audience";
import Distribution from "./Distribution";
import Analytics from "./Analytics";
import OldCost from "./OldCost";
import Cost from "./Cost";
import Deliveries from "./Deliveries";
import Header from "./Header";
import Loader from "../HelperComponents/Loader";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import StepResults from "./BuildCampaign/StepResults";

import {
  getCampaignInfo,
  getCampaignMobilePreview,
  getCampaignSteps,
  postViewer,
  postEditor,
  deleteEditor,
  deleteViewer,
  setAccessError,
  postStopCampaign,
  resetCampaign
} from "../../actions/campaignActions";
import { useQueryParams, usePrevious } from "../../helpers/hooks";
import { showToastError } from "../../helpers/functions";
import { getCampaignCost } from "../../actions/balanceActions";
import { TERMS_AND_CONDITIONS_URL, TERMS_AND_CONDITIONS_ESTONIAN_URL } from "../../config";

import NotFoundImage from "../../assets/image/404.png";
import ShowIcon from "../../assets/image/show-hide.svg";

import "./styles.scss";

const Campaign = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { getParam, removeParam } = useQueryParams();
  const history = useHistory();
  const { id: campaignId } = useParams();
  const location = useLocation();

  const editingTimerRef = useRef(null);
  const inactiveEditorTimerRef = useRef(null);
  const viewingIntervalRef = useRef(null);

  const {
    campaignViewers: { is_editor: isEditor },
    publishValidationError,
    campaignInfo: { status: campaignStatus }
  } = useSelector(({ campaign }) => campaign);
  const [state, setState] = useState({
    loading: true,
    tab: getParam("tab") || "build_campaign",
    openInactivityDialog: false,
    openUnableToBeEditorDialog: false,
    openSorryDialog: false,
    openCampaignIsRunning: false,
    openCampaignIsStopped: false,
    openCampaignIsFinalized: false,
    notFound: false,
    stopCampaignDialog: false,
    loadingButton: false,
    stepResults: false
  });
  const stringifyState = JSON.stringify(state);
  const previousPublishValidationError = usePrevious(publishValidationError);

  const setUrl = useCallback(
    tab => history.push(`/main/campaign/${campaignId}?tab=${tab}`),
    // eslint-disable-next-line
    [campaignId]
  );

  const clearViewingInterval = () => {
    clearInterval(viewingIntervalRef.current);
    viewingIntervalRef.current = null;
  };
  const clearEditingTimer = () => {
    clearTimeout(editingTimerRef.current);
    editingTimerRef.current = null;
  };
  const clearInactiveTimer = () => {
    clearTimeout(inactiveEditorTimerRef.current);
    inactiveEditorTimerRef.current = null;
  };

  const toggleSorryDialog = () => setState(prevState => ({ ...prevState, openSorryDialog: true }));
  const toggleCampaignIsRunningDialog = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        openCampaignIsRunning: !prevState.openCampaignIsRunning
      })),
    // eslint-disable-next-line
    [stringifyState]
  );
  const toggleCampaignIsStoppedDialog = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        openCampaignIsStopped: !prevState.openCampaignIsStopped
      })),
    // eslint-disable-next-line
    [stringifyState]
  );
  const toggleCampaignIsFinalizedDialog = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        openCampaignIsFinalized: !prevState.openCampaignIsFinalized
      })),
    // eslint-disable-next-line
    [stringifyState]
  );
  const toggleUnableToBeEditorDialog = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        openUnableToBeEditorDialog: !prevState.openUnableToBeEditorDialog
      })),
    // eslint-disable-next-line
    [stringifyState]
  );
  const toggleInactivityDialog = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        openInactivityDialog: !prevState.openInactivityDialog
      })),
    // eslint-disable-next-line
    [stringifyState]
  );
  const toggleStopCampaignDialog = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        stopCampaignDialog: !prevState.stopCampaignDialog
      })),
    // eslint-disable-next-line
    [stringifyState]
  );
  const handleStepResults = useCallback(
    () => setState(prevState => ({ ...prevState, stepResults: !prevState.stepResults })),
    // eslint-disable-next-line
    [stringifyState]
  );

  const handleViewingInterval = useCallback(() => {
    clearViewingInterval();
    viewingIntervalRef.current = setInterval(() => {
      // for a viewer we do the request each 20 seconds to update viewers list
      dispatch(postViewer(campaignId));
    }, 20000);
    // eslint-disable-next-line
  }, [campaignId]);

  const handleEditingTimer = useCallback(() => {
    dispatch(postEditor(campaignId)).then(res => {
      if (res.payload) {
        clearEditingTimer();
        editingTimerRef.current = setTimeout(() => {
          // for editor we wait for 15 minutes (-10 seconds) and show "Are you still here?" dialog
          handleInactivityDialog();
        }, 890000);
      } else {
        if (res.error.response.data.non_field_errors[0].code === "is_not_current_editor") {
          toggleUnableToBeEditorDialog();
        } else if (res.error.response.data.non_field_errors[0].code === "campaign_is_active") {
          toggleCampaignIsRunningDialog();
        } else if (
          res.error.response.data.non_field_errors[0].code === "campaign_is_stopped_immediately"
        ) {
          toggleCampaignIsStoppedDialog();
        } else if (
          res.error.response.data.non_field_errors[0].code === "campaign_is_finalized" ||
          res.error.response.data.non_field_errors[0].code === "campaign_is_finalizing"
        ) {
          toggleCampaignIsFinalizedDialog();
        } else {
          toggleSorryDialog();
        }
      }
    });
    // eslint-disable-next-line
  }, [campaignId]);

  const handleInactiveEditorTimer = useCallback(() => {
    inactiveEditorTimerRef.current = setTimeout(() => {
      // 5 (-10 seconds) more minutes for inactive editor and then bye bye
      dispatch(deleteEditor(campaignId)).then(() => history.push("/main/campaigns"));
    }, 290000);
    // eslint-disable-next-line
  }, [campaignId]);

  const handleInactivityDialog = useCallback(() => {
    if (state.openInactivityDialog) {
      toggleInactivityDialog();
      clearInactiveTimer();
      handleEditingTimer(); // +15 minutes
    } else {
      toggleInactivityDialog();
      handleInactiveEditorTimer(); // start 5 minutes countdown
    }
    // eslint-disable-next-line
  }, [campaignId, state.openInactivityDialog]);

  useEffect(() => {
    setUrl(state.tab);
    dispatch(getCampaignInfo(campaignId)).then(res => {
      if (res.error) {
        if (res.error.response.status === 403) {
          history.push("/main/campaigns");
          dispatch(setAccessError(true));
        } else {
          setState({ ...state, notFound: true });
        }
      } else {
        dispatch(getCampaignMobilePreview(campaignId)).then(() => {
          dispatch(getCampaignSteps(campaignId)).then(() => {
            dispatch(postViewer(campaignId)).then(res => {
              if (getParam("editor-mode")) {
                // if you have just created the campaign
                removeParam("editor-mode");
                handleEditingTimer();
              } else if (res.payload && res.payload.data.is_editor) {
                // if you are the current editor timer will start over
                handleEditingTimer();
              } else {
                handleViewingInterval();
              }
              setState({ ...state, loading: false });
            });
          });
        });
      }
    });
    return () => {
      clearEditingTimer();
      clearInactiveTimer();
      clearViewingInterval();
      dispatch(resetCampaign());
      if (!isEditor) dispatch(deleteViewer(campaignId));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setState({
      ...state,
      tab: getParam("tab")
    });
    // eslint-disable-next-line
  }, [JSON.stringify(location.search)]);

  useEffect(() => {
    if (
      publishValidationError &&
      previousPublishValidationError &&
      !(Object.entries(publishValidationError) < Object.entries(previousPublishValidationError))
    ) {
      if (publishValidationError.unhandledError) {
        showToastError(publishValidationError.unhandledError);
      } else {
        Object.values(publishValidationError).forEach(error => {
          showToastError(t(`t:publish-validations.${error}`));
        });
      }
    }
    // eslint-disable-next-line
  }, [publishValidationError]);

  const handleStopCampaign = async () => {
    setState({ ...state, loadingButton: true });
    const res = await dispatch(postStopCampaign(campaignId));
    if (res.payload) {
      await dispatch(getCampaignCost(campaignId));
      setState({ ...state, loadingButton: false });
      toggleStopCampaignDialog();
    }
  };

  const renderDialogs = useMemo(
    () => (
      <>
        <DialogComponent open={state.openInactivityDialog} onClose={handleInactivityDialog}>
          <div className="delete_dialog">
            <div className="dialog_title">{t("t:common.are-you-still-here")}</div>
            <DefaultButton variant="contained" classes="ok_btn" onClick={handleInactivityDialog}>
              {t("t:common.yes")}
            </DefaultButton>
          </div>
        </DialogComponent>
        <DialogComponent
          open={state.openUnableToBeEditorDialog}
          onClose={toggleUnableToBeEditorDialog}
        >
          <div className="delete_dialog">
            <div className="dialog_title text_centered">{t("t:campaigns.unable-to-be-editor")}</div>
            <DefaultButton
              variant="contained"
              classes="ok_btn"
              onClick={toggleUnableToBeEditorDialog}
            >
              {t("t:common.ok")}
            </DefaultButton>
          </div>
        </DialogComponent>
        <DialogComponent open={state.openCampaignIsRunning} onClose={toggleCampaignIsRunningDialog}>
          <div className="delete_dialog">
            <div className="dialog_title text_centered">
              {t("t:campaigns.can-not-edit-active-campaign")}
            </div>
            <DefaultButton
              variant="contained"
              classes="ok_btn"
              onClick={toggleCampaignIsRunningDialog}
            >
              {t("t:common.ok")}
            </DefaultButton>
          </div>
        </DialogComponent>
        <DialogComponent open={state.openCampaignIsStopped} onClose={toggleCampaignIsStoppedDialog}>
          <div className="delete_dialog">
            <div className="dialog_title text_centered">
              {t("t:campaigns.can-not-edit-stopped-campaign")}
            </div>
            <DefaultButton
              variant="contained"
              classes="ok_btn"
              onClick={toggleCampaignIsStoppedDialog}
            >
              {t("t:common.ok")}
            </DefaultButton>
          </div>
        </DialogComponent>
        <DialogComponent
          open={state.openCampaignIsFinalized}
          onClose={toggleCampaignIsFinalizedDialog}
        >
          <div className="delete_dialog">
            <div className="dialog_title text_centered">
              {t("t:campaigns.can-not-edit-finalized-campaign")}
            </div>
            <DefaultButton
              variant="contained"
              classes="ok_btn"
              onClick={toggleCampaignIsFinalizedDialog}
            >
              {t("t:common.ok")}
            </DefaultButton>
          </div>
        </DialogComponent>
        <DialogComponent
          open={state.openSorryDialog}
          onClose={() => history.push("/main/campaigns")}
        >
          <div className="delete_dialog">
            <div className="sorry_word">{t("t:common.sorry")}</div>
            <div className="dialog_title text_centered">
              {t("t:common.content-is-not-available")}
            </div>
            <DefaultButton
              variant="contained"
              classes="ok_btn"
              onClick={() => history.push("/main/campaigns")}
            >
              {t("t:common.ok")}
            </DefaultButton>
          </div>
        </DialogComponent>
        <DialogComponent open={state.stopCampaignDialog} onClose={toggleStopCampaignDialog}>
          <div className="delete_dialog">
            <div className="dialog_title text_centered">
              {t("t:campaigns.stop-campaign-confirmation")}&nbsp;
              <a
                href={
                  i18n.language === "et"
                    ? TERMS_AND_CONDITIONS_ESTONIAN_URL
                    : TERMS_AND_CONDITIONS_URL
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("t:auth.terms-and-conditions")}
              </a>
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="auth"
                onClick={handleStopCampaign}
                loading={state.loadingButton}
              >
                {t("t:campaigns.stop-campaign")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={toggleStopCampaignDialog}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
      </>
    ),
    // eslint-disable-next-line
    [
      state.openInactivityDialog,
      state.openUnableToBeEditorDialog,
      state.openCampaignIsRunning,
      state.openCampaignIsStopped,
      state.openCampaignIsFinalized,
      state.openSorryDialog,
      state.stopCampaignDialog,
      state.loadingButton
    ]
  );

  if (state.notFound)
    return (
      <div className="not_found">
        <img src={NotFoundImage} alt="not found" />
      </div>
    );

  if (state.loading)
    return (
      <div className="loader_wrapper centered_loader">
        <Loader />
      </div>
    );

  return (
    <div className="campaign_wizard_container">
      <Header
        tab={state.tab}
        handleEditingTimer={handleEditingTimer}
        clearEditingTimer={clearEditingTimer}
        handleViewingInterval={handleViewingInterval}
        clearViewingInterval={clearViewingInterval}
        setUrl={setUrl}
        toggleStopCampaignDialog={toggleStopCampaignDialog}
      />
      <div className={clsx("campaign_wrapper", isEditor && "height-100")}>
        {state.tab === "build_campaign" ? (
          <BuildCampaign handleEditingTimer={handleEditingTimer} />
        ) : state.tab === "audience" ? (
          <Audience handleEditingTimer={handleEditingTimer} campaignId={campaignId} />
        ) : state.tab === "distribution" ? (
          <Distribution handleEditingTimer={handleEditingTimer} campaignId={campaignId} />
        ) : state.tab === "analytics" ? (
          <Analytics campaignId={campaignId} />
        ) : state.tab === "cost" ? (
          <Cost
            clearEditingTimer={clearEditingTimer}
            handleViewingInterval={handleViewingInterval}
            campaignId={campaignId}
            toggleStopCampaignDialog={toggleStopCampaignDialog}
          />
        ) : state.tab === "old_cost" ? (
          <OldCost
            clearEditingTimer={clearEditingTimer}
            handleViewingInterval={handleViewingInterval}
            campaignId={campaignId}
            toggleStopCampaignDialog={toggleStopCampaignDialog}
          />
        ) : state.tab === "deliveries" ? (
          <Deliveries campaignId={campaignId} />
        ) : (
          ""
        )}
        {state.tab === "build_campaign" && campaignStatus !== "inactive" && (
          <>
            <DefaultButton
              variant="outlined"
              classes="step-results__show-btn"
              onClick={handleStepResults}
            >
              <img src={ShowIcon} alt="show" className="show-icon" />
              {t("t:build-campaign.show-step-results")}
            </DefaultButton>
            <StepResults isHidden={!state.stepResults} hideStepResults={handleStepResults} />
          </>
        )}
      </div>
      {renderDialogs}
    </div>
  );
};

export default Campaign;
