import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import DialogComponent from "../DialogComponent/DialogComponent";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import { getImageMimeTypeForBlob } from "../../../helpers/functions";

// to onFinishCropping will be passed canvas object that has methods such as
// toBlob and toDataURL (for more information check out cropperjs docs)

const CropperComponent = ({
  imageToCrop,
  onFinishCropping,
  onCancelCropping,
  aspectRatio = "default",
  withOrientation = false,
  lockedOrientation = null
}) => {
  const [orientation, setOrientation] = useState("landscape");
  const cropperRef = useRef(null);
  const { t } = useTranslation();

  const getAspectRatio = useMemo(() => {
    switch (aspectRatio) {
      case 1:
        return 1;
      case 1.25:
        return orientation === "landscape" ? 12.5 / 10 : 10 / 12.5;
      case 1.6:
        return orientation === "landscape" ? 16 / 10 : 10 / 16;
      case "product":
        return orientation === "landscape" ? 63 / 52 : 31 / 39;
      case "default":
      default:
        return orientation === "landscape" ? 16 / 9 : 9 / 16;
    }
  }, [aspectRatio, orientation]);

  useEffect(() => {
    if (cropperRef.current?.cropper) cropperRef.current.cropper.setAspectRatio(getAspectRatio);
  }, [getAspectRatio]);

  useEffect(() => {
    if (lockedOrientation) setOrientation(lockedOrientation);
  }, [lockedOrientation]);

  return (
    <DialogComponent open classes="cropper-dialog">
      <Cropper
        src={imageToCrop}
        style={{ height: 400, width: "100%" }}
        aspectRatio={getAspectRatio}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        autoCropArea={1}
        guides={true}
        viewMode={1}
        ref={cropperRef}
      />
      {withOrientation && (
        <div className="item_radio no_select">
          <div className="option_wrapper">
            <div onClick={() => setOrientation("landscape")}>
              <input
                type="radio"
                checked={orientation === "landscape"}
                onChange={() => console.log("changed")}
              />
              <label htmlFor="radio1">{t("t:common.landscape")}</label>
            </div>
          </div>
          <div className="option_wrapper">
            <div onClick={() => setOrientation("portrait")}>
              <input
                type="radio"
                checked={orientation === "portrait"}
                onChange={() => console.log("changed")}
              />
              <label htmlFor="radio1">{t("t:common.portrait")}</label>
            </div>
          </div>
        </div>
      )}
      <div className="btn_wrapper">
        <DefaultButton
          variant="contained"
          classes="ok_btn"
          disabled={!cropperRef}
          onClick={() =>
            onFinishCropping(
              cropperRef.current.cropper.getCroppedCanvas(),
              getImageMimeTypeForBlob(imageToCrop),
              orientation
            )
          }
        >
          {t("t:common.save")}
        </DefaultButton>
        <DefaultButton variant="contained" classes="cancel_btn" onClick={onCancelCropping}>
          {t("t:common.cancel")}
        </DefaultButton>
      </div>
    </DialogComponent>
  );
};

export default CropperComponent;
