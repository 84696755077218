import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import RenderField from "../../../HelperComponents/RenderField/RenderField";

class AccessTokenForm extends Component {
  render() {
    const { handleSubmit, submitting, pristine, valid, t, onFormSubmit } = this.props;
    return (
      <div className="add-magento__form-wrapper">
        <div className="title" style={{ marginBottom: "30px" }}>
          {t("t:data-sources.change-api-access-key")}
        </div>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="add-magento__form-wrapper--fields">
            <div>
              <div className="add-magento__form-wrapper--label">
                {t("t:data-sources.access-token")}
              </div>
              <Field name="api_key" type="text" component={RenderField} label="" />
            </div>
          </div>
          <DefaultButton
            variant="contained"
            classes="auth"
            disabled={submitting || pristine || !valid}
            formAction
          >
            {t("t:common.save")}
          </DefaultButton>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.api_key) {
    errors.api_key = t("t:common.field-is-required");
  }
  return errors;
};

AccessTokenForm = reduxForm({
  form: "AccessTokenFormForm",
  validate
})(AccessTokenForm);

const mapStateToProps = ({ dataSources }) => {
  return {
    initialValues: {
      api_key: dataSources.dataSource.api_key
    }
  };
};

export default connect(mapStateToProps)(withTranslation()(AccessTokenForm));
