import React from "react";
import { useTranslation } from "react-i18next";

const ShortText = ({ title, description }) => {
  const { t } = useTranslation();
  return (
    <>
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="input-text">
        {t("t:build-campaign.mobile-preview--short_text--placeholder")}
      </div>
    </>
  );
};

export default ShortText;
