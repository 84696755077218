import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import RenderField from "../../HelperComponents/RenderField/RenderField";
import { renderPageLoader, urlPattern } from "../../../helpers/functions";
import TestApiComponent from "../TestApiComponent";
import AccessTokenForm from "./AccessTokenForm";
import {
  getDataSource,
  patchDataSource,
  postRegenerateAPIkey,
  getProducts,
  resetProducts,
  resetDataSources
} from "../../../actions/dataSourcesActions";

import BackIcon from "../../../assets/image/small-left.svg";

class EditDataSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      dataSourceId: props.match.params.id,
      searchKeyword: "",
      productID: ""
    };
  }

  componentDidMount() {
    const { getDataSource } = this.props;
    const { dataSourceId } = this.state;
    getDataSource(dataSourceId).then(() => this.setState({ mounted: true }));
  }

  componentWillUnmount() {
    this.props.resetDataSources();
  }

  regenerateAPIkey = async () => {
    const { postRegenerateAPIkey, resetProducts } = this.props;
    const { dataSourceId } = this.state;
    await postRegenerateAPIkey(dataSourceId);
    resetProducts();
  };

  changeDataSource = async data => {
    const {
      patchDataSource,
      dataSource: { id, type },
      resetProducts
    } = this.props;
    await patchDataSource(id, { ...data, type });
    resetProducts();
  };

  testURL = () => {
    const { getProducts } = this.props;
    const { searchKeyword, productID, dataSourceId } = this.state;
    let data = ["page=1"];
    if (searchKeyword) {
      data.push(`search=${searchKeyword}`);
    }
    if (productID) {
      data.push(`product_id=${productID}`);
    }
    getProducts(dataSourceId, data);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      valid,
      apiKey,
      dataSource: { api_base_url: apiURL, type },
      t
    } = this.props;
    const { mounted } = this.state;
    const isMagento = type === "magento";
    if (!mounted) return renderPageLoader();
    return (
      <div className="data_source_container">
        <div>
          <Link to="/main/products" className="text_hover back">
            <img src={BackIcon} alt="" />
            {t("t:data-sources.back-to-products")}
          </Link>
          {isMagento ? (
            <AccessTokenForm onFormSubmit={this.changeDataSource} />
          ) : (
            <>
              <div className="title">{t("t:data-sources.generate-new-api-key")}</div>
              <div className="warning_api_key_generation">
                <p>
                  <strong>{t("t:data-sources.generate-new-api-key-warning")}</strong>
                </p>
              </div>
              {apiKey && (
                <div className="api_key_wrapper regenerate_one">
                  <p>
                    {t("t:data-sources.new-api-key")}: <strong>{apiKey}</strong>
                  </p>
                  <br />
                  <p>{t("t:data-sources.store-api-key")}</p>
                </div>
              )}
              <DefaultButton variant="contained" classes="auth" onClick={this.regenerateAPIkey}>
                {t("t:data-sources.generate")}
              </DefaultButton>
            </>
          )}
          <hr className="api_key_separator" />
          <div className="title">{t("t:data-sources.configure-endpoints")}</div>
          <div className="add_api_source_description">
            <p>{t("t:data-sources.enter-the-path")}</p>
          </div>
          <form onSubmit={handleSubmit(this.changeDataSource)} className="generate_api_key_form">
            <div className="label">{t("t:data-sources.api-url")}</div>
            <Field name="api_base_url" type="text" component={RenderField} label="" />
            <DefaultButton
              variant="contained"
              classes="auth"
              disabled={submitting || pristine || !valid}
              formAction
            >
              {t("t:common.save")}
            </DefaultButton>
          </form>
          {apiURL && <TestApiComponent type={type} />}
        </div>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.api_base_url) {
    errors.api_base_url = t("t:common.field-is-required");
  } else if (!urlPattern.test(values.api_base_url)) {
    errors.api_base_url = t("t:data-sources.enter-correct-api-url");
  }
  return errors;
};

EditDataSource = reduxForm({
  form: "EditDataSourceForm",
  validate,
  enableReinitialize: true
})(EditDataSource);

const mapStateToProps = ({ dataSources }) => {
  return {
    dataSource: dataSources.dataSource,
    apiKey: dataSources.apiKey,
    products: dataSources.products,
    initialValues: {
      api_base_url: dataSources.dataSource.api_base_url
    }
  };
};

const mapDispatchToProps = {
  postRegenerateAPIkey,
  getProducts,
  getDataSource,
  patchDataSource,
  resetProducts,
  resetDataSources
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditDataSource));
