import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import clsx from "clsx";

import {
  getCustomAudienceByID,
  patchCustomAudienceByID,
  setCustomAudienceInfo,
  deleteEditorForCustomAudience,
  postEditorForCustomAudience,
  setAudienceConfirmationDialog
} from "../../../../actions/audienceActions";
import SegmentName from "../Blocks/SegmentName";
import SegmentGroups from "../Blocks/SegmentGroups";
import ConfirmationDialog from "../../../HelperComponents/DialogComponent/ConfirmationDialog/ConfirmationDialog";
import { showToastError } from "../../../../helpers/functions";
import { getInterests } from "../../../../actions/campaignActions";
import { renderCampaignsListErrorDialog } from "../../helpers";

import "../styles.scss";

const EditCustomAudience = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { customAudienceInfo, confirmationDialog, buttonLoading } = useSelector(
    ({ audience }) => audience
  );
  const [step, setStep] = useState("segment_name");
  const [inactiveTime, setInactiveTime] = useState(0);
  const editingInterval = useRef(null);
  const previousDataSnapshot = useRef(null);

  const closeConfirmationDialogAndRedirect = () => {
    dispatch(setAudienceConfirmationDialog({ reset: true }));
    history.push("/main/audience?tab=custom");
  };

  const closeConfirmationDialogAndResetInactiveTime = () => {
    dispatch(setAudienceConfirmationDialog({ reset: true }));
    setInactiveTime(0);
  };

  const setEditingInterval = () => {
    if (editingInterval.current) clearInterval(editingInterval.current);
    editingInterval.current = setInterval(() => {
      if (previousDataSnapshot.current !== JSON.stringify(customAudienceInfo)) {
        setInactiveTime(0);
      } else {
        setInactiveTime(time => time + 10000);
      }
      dispatch(postEditorForCustomAudience(id)).then(res => {
        previousDataSnapshot.current = JSON.stringify(customAudienceInfo);
        if (res.error) {
          dispatch(
            setAudienceConfirmationDialog({
              open: true,
              title: t("t:common.sorry"),
              description: t("t:audience.you-can-not-edit-this-segment"),
              confirmText: t("t:common.ok"),
              onClose: closeConfirmationDialogAndRedirect,
              confirmAction: closeConfirmationDialogAndRedirect
            })
          );
        }
      });
    }, 10000);
  };

  useEffect(() => {
    dispatch(getCustomAudienceByID(id));
    dispatch(getInterests());
    setEditingInterval();
    return () => {
      dispatch(deleteEditorForCustomAudience(id));
      clearInterval(editingInterval.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (inactiveTime === 890000) {
      // 15 minutes - "Are you still here?" dialog
      dispatch(
        setAudienceConfirmationDialog({
          open: true,
          title: t("t:common.are-you-still-here"),
          description: "",
          confirmText: t("t:common.yes"),
          onClose: closeConfirmationDialogAndResetInactiveTime,
          confirmAction: closeConfirmationDialogAndResetInactiveTime
        })
      );
    }
    if (inactiveTime === 1190000) {
      // 20 minutes - redirect to the segments list
      closeConfirmationDialogAndRedirect();
    }
    // eslint-disable-next-line
  }, [inactiveTime]);

  const onStepChange = newStep => setStep(newStep);

  const onFieldChange = (name, value) => {
    dispatch(setCustomAudienceInfo({ [name]: value }));
  };

  const onEditSegmentClick = (forceEdit = false) => {
    if (!forceEdit && customAudienceInfo.campaigns.length) {
      dispatch(
        setAudienceConfirmationDialog({
          open: true,
          title: t("t:audience.warning"),
          description: t("t:audience.this-will-affect-following-campaigns"),
          confirmText: t("t:audience.accept"),
          declineText: t("t:common.cancel"),
          additionalContent: () => renderCampaignsListErrorDialog(customAudienceInfo.campaigns),
          onClose: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
          declineAction: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
          confirmAction: () => {
            dispatch(setAudienceConfirmationDialog({ reset: true }));
            onEditSegmentClick(true);
          }
        })
      );
    } else {
      dispatch(
        patchCustomAudienceByID(id, {
          ...customAudienceInfo,
          groups: customAudienceInfo.groups.map(group => ({
            ...group,
            hashtags: group.hashtags.map(el => el.id),
            interests: group.interests.map(el => {
              if (el.id) return el.id;
              return el;
            })
          }))
        })
      ).then(res => {
        if (res.payload) {
          history.push("/main/audience?tab=custom");
        } else {
          if (res.error) {
            if (res.error.response.data.name) showToastError(res.error.response.data.name.message);
            if (res.error.response.data.groups) {
              Object.values(res.error.response.data.groups).forEach(el => {
                showToastError(Object.values(el)[0][0].message);
              });
            }
          }
        }
      });
    }
  };

  return (
    <div className="custom-audience__page">
      <div className={clsx("custom-audience__wrapper", step)}>
        <div className="custom-audience__title">{t("t:audience.edit-segment")}</div>
        {step === "segment_name" && (
          <SegmentName onFieldChange={onFieldChange} isEdit onStepChange={onStepChange} />
        )}
        {step === "segment_groups" && <SegmentGroups onSubmit={onEditSegmentClick} isEdit />}
        <Link to="/main/audience?tab=custom" className="text_hover custom-audience__cancel">
          {t("t:common.cancel")}
        </Link>
      </div>
      <ConfirmationDialog {...confirmationDialog} buttonLoading={buttonLoading} />
    </div>
  );
};

export default EditCustomAudience;
