import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import clsx from "clsx";
import { bindActionCreators } from "redux";
import { Image as CloudinaryImage } from "cloudinary-react";
import { withTranslation } from "react-i18next";

import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import AppleAuth from "../../Auth/AuthButtons/AppleAuth/AppleAuth";
import GoogleAuth from "../../Auth/AuthButtons/GoogleAuth/GoogleAuth";
import FacebookAuth from "../../Auth/AuthButtons/FacebookAuth/FacebookAuth";
import {
  asyncCheckImagePixels,
  isFilesSizeTooBig,
  validateImage
} from "../../../helpers/functions";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import ChangeEmailForm from "./Forms/ChangeEmailForm";
import CreatePasswordForm from "./Forms/CreatePasswordForm";
import ChangePasswordForm from "./Forms/ChangePasswordForm";
import EnterCodeForm from "./Forms/EnterCodeForm";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import CropperComponent from "../../HelperComponents/CropperComponent";

import {
  postDisconnectGoogle,
  postDisconnectApple,
  postDisconnectFacebook,
  patchProfile,
  postAvatar
} from "../../../actions/profileActions";

import PhotoIcon from "../../../assets/image/photo.svg";
import GoogleIcon from "../../../assets/image/google_icon.svg";
import FacebookIcon from "../../../assets/image/facebook_icon.svg";
import AppleIcon from "../../../assets/image/apple_icon.svg";
import Shape from "../../../assets/image/My_Campaign/Shape.svg";

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enterNewEmailDialog: false,
      enterCodeDialog: false,
      changePasswordDialog: false,
      createPasswordDialog: false,
      disconnectAccountDialog: false,
      toDisconnect: undefined,
      imageToCrop: null
    };
  }

  toggleDialog = (type, additionalValue) => {
    switch (type) {
      case "enter_new_email":
        this.setState(({ enterNewEmailDialog }) => ({
          enterNewEmailDialog: !enterNewEmailDialog
        }));
        break;
      case "enter_code":
        this.setState(({ enterCodeDialog }) => ({ enterCodeDialog: !enterCodeDialog }));
        break;
      case "change_password":
        this.setState(({ changePasswordDialog }) => ({
          changePasswordDialog: !changePasswordDialog
        }));
        break;
      case "create_password":
        this.setState(({ createPasswordDialog }) => ({
          createPasswordDialog: !createPasswordDialog
        }));
        break;
      case "disconnect_account":
        this.setState(({ disconnectAccountDialog }) => ({
          disconnectAccountDialog: !disconnectAccountDialog,
          toDisconnect: additionalValue
        }));
        break;
      default:
        break;
    }
  };

  changeFullName = async data => {
    const { patchProfile } = this.props;
    const res = await patchProfile(data);
    if (res.error) {
      throw new SubmissionError({ full_name: res.error.response.data.full_name[0].message });
    }
  };

  handleFile = async e => {
    const { dispatch, globalParameters, t } = this.props;
    e.persist();
    let file = e.target.files[0];
    if (file) {
      if (isFilesSizeTooBig(e.target.files)) {
        dispatch({ type: "FILE_SIZE_ERROR" });
      } else if (validateImage(file)) {
        const pixels = await asyncCheckImagePixels(file);
        if (
          globalParameters.max_image_resolution &&
          globalParameters.max_image_resolution < pixels
        ) {
          dispatch({
            type: "ERROR_SNACK_OPEN",
            payload: t("t:snack-messages.image-too-many-pixels-fail", {
              number: globalParameters.max_image_resolution
            })
          });
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => this.setState({ imageToCrop: [reader.result] });
        }
      } else {
        dispatch({ type: "FILE_TYPE_ERROR" });
      }
    }
  };

  uploadPhoto = canvas => {
    const { postAvatar } = this.props;
    this.setState({ imageToCrop: null });
    canvas.toBlob(blob => {
      const formData = new FormData();
      formData.append("avatar", blob, "avatar.png");
      postAvatar(formData);
      if (document.getElementById("file-input")) {
        document.getElementById("file-input").value = "";
      }
    });
  };

  onCancelCropping = () => {
    this.setState({ imageToCrop: null });
    document.getElementById("file-input").value = "";
  };

  removePhoto = () => {
    const { postAvatar } = this.props;
    const formData = new FormData();
    formData.append("avatar", "");
    postAvatar(formData);
  };

  disconnectAccount = async () => {
    const { postDisconnectGoogle, postDisconnectApple, postDisconnectFacebook } = this.props;
    const { toDisconnect } = this.state;
    if (toDisconnect === "google") {
      await postDisconnectGoogle();
    } else if (toDisconnect === "apple") {
      await postDisconnectApple();
    } else if (toDisconnect === "facebook") {
      await postDisconnectFacebook();
    }
    this.toggleDialog("disconnect_account");
  };

  render() {
    const { profileInfo, loading, handleSubmit, submitting, valid, t } = this.props;
    const {
      enterNewEmailDialog,
      enterCodeDialog,
      changePasswordDialog,
      createPasswordDialog,
      disconnectAccountDialog,
      imageToCrop
    } = this.state;
    return (
      <div className="general_wrapper">
        <div className="general_info_line">
          <div className="line_name">
            <p>{t("t:profile.personal-information")}</p>
          </div>
          <div className="profile_form_wrapper">
            <div>
              <div className="profile_photo_wrapper">
                <input
                  className="file-input"
                  id="file-input"
                  type="file"
                  multiple={false}
                  onChange={e => this.handleFile(e)}
                  accept="image/*"
                />
                <div className="avatar_wrapper">
                  {profileInfo.avatar ? (
                    <CloudinaryImage
                      cloudName="campy"
                      width="96"
                      height="96"
                      gravity="face"
                      crop="thumb"
                      publicId={profileInfo.avatar.split("/").pop()}
                    />
                  ) : (
                    <img src={PhotoIcon} alt="placeholder" />
                  )}
                </div>
                <div>
                  <p>
                    {t("t:profile.profile-photo")}
                    <TooltipMessage
                      text={t("t:helper-texts.image")}
                      delay={200}
                      position="top"
                      classes="step_tooltip"
                    >
                      <img className="tooltip-question-icon" src={Shape} alt="Shape" />
                    </TooltipMessage>
                  </p>
                  <div className="buttons_wrapper">
                    <DefaultButton
                      variant="outlined"
                      classes="upload_btn"
                      onClick={() => document.getElementById("file-input").click()}
                    >
                      {t("t:common.upload")}
                    </DefaultButton>
                    {profileInfo.avatar && (
                      <DefaultButton
                        variant="outlined"
                        classes="delete_avatar_btn"
                        onClick={this.removePhoto}
                      >
                        {t("t:common.delete")}
                      </DefaultButton>
                    )}
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit(this.changeFullName)}>
                <div className="label">{t("t:common.full-name")}</div>
                <Field name="full_name" type="text" component={RenderField} label="" />
                <button type="submit" disabled={submitting || !valid} className="text_hover">
                  {t("t:common.save")}
                </button>
              </form>
            </div>
            <div>
              <div className="profile_field">
                <div className="label">{t("t:common.email")}</div>
                <div className="field">
                  <p>{profileInfo.email}</p>
                  <button
                    onClick={() => this.toggleDialog("enter_new_email")}
                    className="text_hover"
                  >
                    {t("t:common.change")}
                  </button>
                </div>
              </div>
              <div className="profile_field">
                <div className="label">{t("t:common.password")}</div>
                {profileInfo.has_usable_password ? (
                  <div className="field">
                    <p className="with_opacity">**************</p>
                    <button
                      onClick={() => this.toggleDialog("change_password")}
                      className="text_hover"
                    >
                      {t("t:common.change")}
                    </button>
                  </div>
                ) : (
                  <div className="field">
                    <p className="with_opacity">{t("t:profile.not-specified")}</p>
                    <button
                      onClick={() => this.toggleDialog("create_password")}
                      className="text_hover"
                    >
                      {t("t:common.create")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="general_info_line">
          <div className="line_name">
            <p>{t("t:profile.connected-accounts")}</p>
          </div>
          <div className="social_buttons_wrapper">
            {[
              {
                key: "google",
                icon: GoogleIcon,
                name: "Google",
                fieldKey: "google_email"
              },
              {
                key: "facebook",
                icon: FacebookIcon,
                name: "Facebook",
                fieldKey: "facebook_email"
              },
              {
                key: "apple",
                icon: AppleIcon,
                name: "Apple",
                fieldKey: "apple_email"
              }
            ].map(({ key, icon, name, fieldKey }) => (
              <Fragment key={key}>
                <div>
                  <div className="account_wrapper">
                    <img src={icon} alt="google" />
                    <div>
                      <div>{name}</div>
                      <span className={clsx(!profileInfo[fieldKey] && "with_opacity")}>
                        {profileInfo[fieldKey] || "not connected"}
                      </span>
                    </div>
                  </div>
                  {profileInfo[fieldKey] ? (
                    <DefaultButton
                      variant="outlined"
                      classes="disconnect_btn"
                      onClick={() => this.toggleDialog("disconnect_account", key)}
                    >
                      {t("t:profile.disconnect")}
                    </DefaultButton>
                  ) : (
                    <>
                      {key === "google" && (
                        <GoogleAuth
                          variant="outlined"
                          label={t("t:profile.connect")}
                          classes="social_outlined"
                        />
                      )}
                      {key === "facebook" && (
                        <FacebookAuth
                          variant="outlined"
                          label={t("t:profile.connect")}
                          classes="social_outlined"
                        />
                      )}
                      {key === "apple" && (
                        <AppleAuth
                          variant="outlined"
                          label={t("t:profile.connect")}
                          classes="social_outlined"
                        />
                      )}
                    </>
                  )}
                </div>
                {key !== "apple" && <hr />}
              </Fragment>
            ))}
          </div>
        </div>
        <DialogComponent
          open={disconnectAccountDialog}
          onClose={() => this.toggleDialog("disconnect_account")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:profile.disconnect-account")}</div>
            <div className="dialog_description">
              {t("t:profile.disconnect-account-confirmation")}
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.disconnectAccount}
                loading={loading}
              >
                {t("t:profile.disconnect")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("disconnect_account")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={enterNewEmailDialog}
          onClose={() => this.toggleDialog("enter_new_email")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:profile.change-email")}</div>
            <div className="dialog_description">{t("t:profile.enter-new-email")}</div>
            <ChangeEmailForm
              initialValues={{ email: profileInfo.email }}
              toggleDialog={this.toggleDialog}
            />
          </div>
        </DialogComponent>
        <DialogComponent
          open={enterCodeDialog}
          onClose={() => this.toggleDialog("enter_code")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:profile.change-email")}</div>
            <div className="dialog_description">{t("t:profile.enter-code-we-sent")}</div>
            <EnterCodeForm toggleDialog={this.toggleDialog} />
          </div>
        </DialogComponent>
        <DialogComponent
          open={changePasswordDialog}
          onClose={() => this.toggleDialog("change_password")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:profile.change-password")}</div>
            <div className="dialog_description">
              {t("t:profile.enter-current-and-new-passwords")}
            </div>
            <ChangePasswordForm toggleDialog={this.toggleDialog} />
          </div>
        </DialogComponent>
        <DialogComponent
          open={createPasswordDialog}
          onClose={() => this.toggleDialog("create_password")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:profile.create-password")}</div>
            <div className="dialog_description">{t("t:profile.set-new-password")}</div>
            <CreatePasswordForm toggleDialog={this.toggleDialog} />
          </div>
        </DialogComponent>
        {imageToCrop && (
          <CropperComponent
            imageToCrop={imageToCrop}
            onFinishCropping={this.uploadPhoto}
            onCancelCropping={this.onCancelCropping}
            aspectRatio={1}
          />
        )}
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.full_name) {
    errors.full_name = t("t:common.field-is-required");
  }
  return errors;
};

General = reduxForm({
  form: "FullNameForm",
  validate
})(General);

const mapStateToProps = ({ auth, dashboard }) => {
  return {
    profileInfo: auth.user_info,
    loading: dashboard.loadingComponent,
    globalParameters: auth.globalParameters,
    initialValues: {
      full_name: auth.user_info.full_name
    }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postDisconnectGoogle,
      postDisconnectApple,
      postDisconnectFacebook,
      patchProfile,
      postAvatar
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(General));
