import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { withTranslation } from "react-i18next";

import RenderField from "../../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";

import { postCode } from "../../../../actions/profileActions";

class EnterCodeForm extends Component {
  enterCode = async data => {
    const { postCode, toggleDialog } = this.props;
    let email = { code: data.code };
    const res = await postCode(email);
    if (res.error) {
      throw new SubmissionError({ code: res.error.response.data.code[0].message });
    } else {
      toggleDialog("enter_code");
    }
  };

  render() {
    const { loading, handleSubmit, submitting, pristine, valid, toggleDialog, t } = this.props;
    return (
      <form onSubmit={handleSubmit(this.enterCode)} className="dialog_form">
        <Field name="code" type="text" component={RenderField} label={t("t:profile.code")} />
        <div className="buttons_wrapper">
          <DefaultButton
            variant="contained"
            classes="purple_btn"
            formAction
            disabled={submitting || pristine || !valid}
            loading={loading}
          >
            {t("t:common.change")}
          </DefaultButton>
          <DefaultButton
            variant="contained"
            classes="cancel_btn"
            onClick={() => toggleDialog("enter_code")}
          >
            {t("t:common.cancel")}
          </DefaultButton>
        </div>
      </form>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.code) {
    errors.code = t("t:common.field-is-required");
  }
  return errors;
};

EnterCodeForm = reduxForm({
  form: "EnterCodeForm",
  validate
})(EnterCodeForm);

const mapStateToProps = ({ dashboard }) => {
  return {
    loading: dashboard.loadingComponent
  };
};

const mapDispatchToProps = {
  postCode
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EnterCodeForm));
