import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import Snack from "../../components/HelperComponents/Snack/Snack";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import LanguageSwitcher from "../HelperComponents/LanguageSwitcher";

import { postLogout } from "../../actions/authActions";
import { resetCompanyErrors } from "../../actions/companiesActions";
import { handleBalanceDialog } from "../../actions/balanceActions";
import { splitByCommas } from "../../helpers/functions";
import Breadcrumbs from "./Breadcrumbs";

import CampyLogo from "../../assets/image/campy.svg";
import AlarmIcon from "../../assets/image/alarm.svg";
import ArrowDownIcon from "../../assets/image/down-arrow.svg";
import ProfileIcon from "../../assets/image/single-05.svg";
import LogOutIcon from "../../assets/image/logout.svg";
import CheckIcon from "../../assets/image/check.svg";
import CampyCoinIcon from "../../assets/image/campycoin.svg";

import "./styles.scss";

const Header = () => {
  const { first_name, last_name, current_company } = useSelector(({ auth }) => auth.user_info);
  const companyError = useSelector(({ companies }) => companies.settingCompanyError);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const resetErrorsTimeoutRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => () => {
    clearTimeout(resetErrorsTimeoutRef.current);
  });

  const openMenu = () => setMenuIsOpen(!menuIsOpen);

  const closeMenu = () => setTimeout(() => setMenuIsOpen(false), 100);

  const onLogout = async () => {
    await dispatch(postLogout());
    localStorage.clear();
    history.push("/auth");
  };

  const closeSnack = () => {
    setOpenSnack(false);
    resetErrorsTimeoutRef.current = setTimeout(() => dispatch(resetCompanyErrors()), 50);
  };

  return (
    <header className="header_wrapper no_select">
      <div className="logo-and-breadcrumbs">
        <Link to="/main" className="logo">
          <img src={CampyLogo} alt="Campy" />
        </Link>
        <Breadcrumbs />
      </div>

      <div className="header_info">
        {current_company && current_company.is_owner && (
          <>
            <div className="balance_info">
              <div className="balance">
                <img src={CampyCoinIcon} alt="coin" className="campy-coin__16" />
                {splitByCommas(current_company.balance || "0.00")}
              </div>
              <div className="company_balance">{t("t:balance.company-balance")}</div>
            </div>
            {!current_company.is_monthly_payment_enabled && (
              <IconButton
                classes={{
                  root: "dialog_close_button"
                }}
                onClick={() => dispatch(handleBalanceDialog(true, "get_invoice"))}
              >
                <div className="add_btn">+</div>
              </IconButton>
            )}
            <div className="header_separator" />
          </>
        )}

        <div className="alarm_block">
          <img src={AlarmIcon} alt="notifications" />
        </div>

        <div className="info" onClick={openMenu}>
          <div>
            <span>{first_name + " " + last_name}</span>
            {current_company && (
              <p>
                {current_company.name} {current_company.is_owner && " (owner)"}
              </p>
            )}
          </div>
          <div>
            <IconButton
              classes={{
                root: "dialog_close_button"
              }}
            >
              <img
                src={ArrowDownIcon}
                alt="->"
                className={clsx("transition_ease", menuIsOpen && "rotated_90")}
              />
            </IconButton>
          </div>
          {menuIsOpen && (
            <ClickAwayListener onClickAway={closeMenu}>
              <div className="menu">
                <Link to="/main/profile/?tab=general">
                  <img src={ProfileIcon} alt="profile" />
                  {t("t:common.my-profile")}
                </Link>
                <hr />
                <div className="companies_list">
                  <p>{t("t:common.active-company")}</p>
                  {current_company && (
                    <div>
                      <img src={CheckIcon} alt="active" className="current" />
                      <div>{current_company.name}</div>
                    </div>
                  )}
                  <DefaultButton
                    variant="outlined"
                    type="link"
                    to="/main/profile/?tab=companies"
                    classes="switch_btn"
                  >
                    {t("t:common.switch")}
                  </DefaultButton>
                </div>
                <hr />
                <button onClick={onLogout}>
                  <img src={LogOutIcon} alt="logout" />
                  {t("t:common.sign-out")}
                </button>
              </div>
            </ClickAwayListener>
          )}
        </div>

        <div className="header_separator" />

        <LanguageSwitcher inWhite />
      </div>

      <Snack open={openSnack} handleSnack={closeSnack} message={companyError} type="error" />
    </header>
  );
};

export default Header;
