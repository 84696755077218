import { BALANCE } from "../constants";

const INITIAL_STATE = {
  openBalanceDialog: false,
  typeOfBalanceDialog: "",
  campaignCost: {},
  fbSharing: { results: Array.from({ length: 10 }, (v, k) => ({ id: k })) },
  linkSharing: { results: Array.from({ length: 10 }, (v, k) => ({ id: k })) },
  requestCampaignId: undefined,
  prefilledValue: null
};

const reduceBalance = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BALANCE.HANDLE_BALANCE_DIALOG:
      return {
        ...state,
        openBalanceDialog: action.open,
        typeOfBalanceDialog: action.typeOfBalanceDialog || state.typeOfBalanceDialog,
        requestCampaignId: action.requestCampaignId,
        prefilledValue: action.prefilledValue
      };
    case BALANCE.GET_CAMPAIGN_COST_SUCCESS:
      return { ...state, campaignCost: action.payload.data };
    case BALANCE.GET_CAMPAIGN_SHARING_FB_COST_SUCCESS:
      return { ...state, fbSharing: action.payload.data };
    case BALANCE.GET_CAMPAIGN_SHARING_LINK_COST_SUCCESS:
      return { ...state, linkSharing: action.payload.data };
    default:
      return state;
  }
};

export default reduceBalance;
