import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";

import { deliveryTypesForSelector, deliveryStatusesForSelector } from "./constants";
import { getCompanyDeliveries, setCompanyDeliveriesMeta } from "../../actions/campaignActions";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import Pagination from "../HelperComponents/Pagination/Pagination";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import ListItem from "./ListItem";
import TrackingInfoForm from "./TrackingInfoForm";
import { renderDeliveryInfo } from "../Campaign/Deliveries/Blocks/DeliveryItem";

import zoom from "../../assets/image/zoom.svg";

import "./styles.scss";

const Deliveries = () => {
  const [infoToShow, setInfoToShow] = useState({});
  const { list, meta } = useSelector(({ deliveries }) => deliveries);
  const [openTrackingInfoDialog, setOpenTrackingInfoDialog] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyDeliveries(meta));
  }, [dispatch, meta]);

  const onMetaChange = metaFields => {
    dispatch(setCompanyDeliveriesMeta(metaFields));
  };

  const onInputChange = useMemo(
    () => _.debounce(search => onMetaChange({ search, page: 1 }), 300),
    // eslint-disable-next-line
    []
  );

  const onSelectorValueChange = name => event => onMetaChange({ [name]: event.value });

  const onPageChange = page => onMetaChange({ page });
  console.log(infoToShow);
  return (
    <>
      <div className="deliveries__wrapper">
        <h1 className="deliveries__title">{t("t:deliveries.deliveries")}</h1>
        <div className="deliveries__meta-controls">
          <div className="deliveries__search">
            <input
              type="text"
              placeholder={t("t:deliveries.search-delivery")}
              onChange={e => {
                e.persist();
                onInputChange(e.target.value);
              }}
            />
            <img src={zoom} alt="zoom" />
          </div>
          <FormControl className="deliveries__select">
            <SelectComponent
              value={deliveryTypesForSelector(t).find(el => el.value === meta.type)}
              options={deliveryTypesForSelector(t)}
              change={onSelectorValueChange("type")}
              isClearable="false"
              isSearchable={false}
              labelStartAdornment={t("t:common.type")}
            />
          </FormControl>
          <FormControl className="deliveries__select longer-one">
            <SelectComponent
              value={deliveryStatusesForSelector(t).find(el => el.value === meta.status)}
              options={deliveryStatusesForSelector(t)}
              change={onSelectorValueChange("status")}
              isClearable="false"
              isSearchable={false}
              labelStartAdornment={t("t:common.status")}
            />
          </FormControl>
        </div>
        <div className="deliveries__list">
          {list.results.map(el => (
            <ListItem key={el.id} {...el} setInfoToShow={setInfoToShow} />
          ))}
        </div>
        <div className="deliveries__pagination">
          {list.count > 10 && (
            <Pagination
              active={meta.page}
              pageCount={Math.ceil(list.count / 10)}
              onChange={e => onPageChange(e.selected + 1)}
              t={t}
            />
          )}
        </div>
      </div>
      <DialogComponent
        open={!!infoToShow.campaign_name}
        onClose={() => setInfoToShow({})}
        classes="deliveries__info-dialog"
        closeIcon
      >
        <div className="default_dialog_wrapper deliveries__info-dialog--wrapper">
          <div className="deliveries__info-dialog--title">{t("t:deliveries.delivery-info")}</div>
          <div className="deliveries__info-dialog--info">
            <div>
              <div className="deliveries__info-dialog--label">{t("t:campaigns.campaign-name")}</div>
              <div className="deliveries__info-dialog--value">
                {infoToShow.campaign_name || "—"}
              </div>
            </div>
            <div>
              <div className="deliveries__info-dialog--label">{t("t:deliveries.reward-type")}</div>
              <div className="deliveries__info-dialog--value">
                {infoToShow.reward_type_display || "—"}
              </div>
            </div>
            <div className="deliveries__info-dialog--fields">
              <div>
                <div className="deliveries__info-dialog--label">
                  {t("t:deliveries.reward-product")}
                </div>
                <div className="deliveries__info-dialog--value">
                  {infoToShow.product_name || "—"}
                </div>
              </div>
              {infoToShow.product_size && (
                <div>
                  <div className="deliveries__info-dialog--label">{t("t:build-campaign.size")}</div>
                  <div className="deliveries__info-dialog--value">{infoToShow.product_size}</div>
                </div>
              )}
              {infoToShow.product_quantity && (
                <div>
                  <div className="deliveries__info-dialog--label">
                    {t("t:build-campaign.quantity")}
                  </div>
                  <div className="deliveries__info-dialog--value">
                    {infoToShow.product_quantity}
                  </div>
                </div>
              )}
            </div>
            {infoToShow.gift_name && (
              <div className="deliveries__info-dialog--fields">
                <div>
                  <div className="deliveries__info-dialog--label">
                    {t("t:build-campaign.bonus-product")}
                  </div>
                  <div className="deliveries__info-dialog--value">{infoToShow.gift_name}</div>
                </div>
                {infoToShow.gift_size && (
                  <div>
                    <div className="deliveries__info-dialog--label">
                      {t("t:build-campaign.size")}
                    </div>
                    <div className="deliveries__info-dialog--value">
                      {infoToShow.gift_size || "—"}
                    </div>
                  </div>
                )}
                {infoToShow.gift_quantity && (
                  <div>
                    <div className="deliveries__info-dialog--label">
                      {t("t:build-campaign.quantity")}
                    </div>
                    <div className="deliveries__info-dialog--value">
                      {infoToShow.gift_quantity || "—"}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div>{renderDeliveryInfo(infoToShow, t)}</div>
            {(infoToShow.status === "on_delivery" ||
              (infoToShow.status === "done" && infoToShow.type !== "pickup")) && (
              <DefaultButton
                variant="contained"
                classes="delivery_btn"
                onClick={() => setOpenTrackingInfoDialog(true)}
              >
                {t("t:deliveries.edit-tracking-info")}
              </DefaultButton>
            )}
          </div>
        </div>
      </DialogComponent>
      {openTrackingInfoDialog && (
        <DialogComponent
          open
          onClose={() => setOpenTrackingInfoDialog(false)}
          closeIcon
          classes="tracking_info_dialog"
        >
          <TrackingInfoForm
            {...infoToShow}
            toggleDialog={newInfo => {
              setOpenTrackingInfoDialog(false);
              setInfoToShow({ ...infoToShow, ...newInfo });
            }}
            campaignId={infoToShow.campaign_id}
          />
        </DialogComponent>
      )}
    </>
  );
};

export default Deliveries;
