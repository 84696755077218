import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Image as CloudinaryImage } from "cloudinary-react";
import { withTranslation } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Snack from "../../HelperComponents/Snack/Snack";
import { validateImage } from "../../../helpers/functions";
import Dropzone from "../../HelperComponents/DropzoneComponent/DropzoneComponent";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import Loader from "../../HelperComponents/Loader";
import ColorPicker from "../../HelperComponents/ColorPicker/ColorPicker";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import CropperComponent from "../../HelperComponents/CropperComponent";
import CharactersLeft from "../../HelperComponents/CharactersLeft";
import ConfirmationDialog from "../../HelperComponents/DialogComponent/ConfirmationDialog/ConfirmationDialog";
import { renderCampaignsListErrorDialog } from "../../Audience/helpers";

import {
  getProduct,
  patchProduct,
  resetProductErrors,
  setProductError,
  postProductImage
} from "../../../actions/productsActions";
import { setAudienceConfirmationDialog } from "../../../actions/audienceActions";

import eye from "../../../assets/image/My_Campaign/eye.svg";
import del from "../../../assets/image/My_Campaign/del.svg";
import Shape from "../../../assets/image/My_Campaign/Shape.svg";

import "./EditProduct.scss";

class EditProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      initialLoading: true,
      loading: false,
      name: "",
      api_product_id: "",
      description: "",
      price: "",
      vat_percent: "",
      color: "",
      images: [],
      is_blocked: false,
      openErrorSnack: false,
      openFileTypeErrorSnack: false,
      previewDialogOpen: false,
      editProductError: false,
      url: "",
      campaigns: [],
      status: "",
      imagesToCrop: [],
      isBackDropOpen: false,
      selectedOrientation: null
    };
  }

  componentDidMount() {
    const { getProduct } = this.props;
    const { id } = this.state;
    getProduct(id).then(res => {
      const {
        name,
        description,
        price,
        vat_percent,
        image,
        images,
        url,
        color,
        is_blocked,
        api_product_id,
        campaigns,
        status
      } = res.payload.data;
      let tempImage = image.split("/");
      tempImage.splice(6, 0, "ar_0.7,c_fill,g_auto:subject,w_20");
      tempImage.splice(6, 0, "fl_getinfo");
      axios.get(tempImage.join("/")).then(cloudinaryResponse => {
        this.setState({
          name,
          description,
          price,
          vat_percent,
          color,
          images,
          url,
          is_blocked,
          api_product_id,
          initialLoading: false,
          campaigns,
          status,
          selectedOrientation:
            cloudinaryResponse.data.input.width > cloudinaryResponse.data.input.height
              ? "landscape"
              : "portrait"
        });
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.images.length !== this.state.images.length && !this.state.images.length) {
      this.setState({ selectedOrientation: null });
    }
  }

  editProduct = async () => {
    const {
      patchProduct,
      history,
      resetProductErrors,
      setProductError,
      t,
      setAudienceConfirmationDialog
    } = this.props;
    const {
      id,
      name,
      description,
      price,
      vat_percent,
      images,
      url,
      color,
      api_product_id,
      campaigns
    } = this.state;
    if (!images.length) {
      setProductError({
        image: [{ code: "should_be", message: t("t:snack-messages.product-image-error") }]
      });
      this.setState({ openErrorSnack: true });
    } else if (images.length > 5) {
      setProductError({
        image: [
          { code: "max_limit", message: t("t:snack-messages.maximum-n-images", { number: 5 }) }
        ]
      });
      this.setState({ openErrorSnack: true });
    } else {
      if (campaigns.length) {
        setAudienceConfirmationDialog({
          open: true,
          title: t("t:audience.warning"),
          description: t("t:audience.this-will-affect-following-campaigns"),
          confirmText: t("t:audience.accept"),
          declineText: t("t:common.cancel"),
          additionalContent: () => renderCampaignsListErrorDialog(campaigns)
        });
      } else {
        this.setState({ loading: true });
        const res = await patchProduct(id, {
          name,
          description,
          api_product_id,
          url,
          price,
          vat_percent,
          color,
          images_ids: images.map(i => i.id)
        });
        if (res.error) {
          this.setState({ openErrorSnack: true, loading: false });
        } else {
          resetProductErrors();
          history.push(`/main/products`);
        }
      }
    }
  };

  handleFiles = files => {
    this.validateFiles(files).then(res => {
      if (!res) {
        this.setState({ openErrorSnack: true });
      } else {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onloadend = () =>
            this.setState(({ imagesToCrop }) => ({
              imagesToCrop: [...imagesToCrop, { dataUrl: [reader.result] }]
            }));
        }
      }
    });
  };

  validateFiles = async files => {
    let validationPassed = true;
    for await (const el of files) {
      if (!validateImage(el)) {
        validationPassed = false;
      }
    }
    return validationPassed;
  };

  uploadImage = (canvas, mimeType, orientation) => {
    const { postProductImage } = this.props;
    const { imagesToCrop, images } = this.state;
    const tempImagesToCrop = imagesToCrop.slice(1);
    this.setState({ imagesToCrop: [], isBackDropOpen: true, selectedOrientation: orientation });
    canvas.toBlob(blob => {
      const formData = new FormData();
      formData.append("image", blob, "product.png");
      postProductImage(formData).then(res => {
        this.setState({
          imagesToCrop: tempImagesToCrop,
          images: [...images, res.payload.data],
          isBackDropOpen: false
        });
      });
    }, mimeType);
  };

  onCancelCropping = () => this.setState({ imagesToCrop: [] });

  handleColorChange = color => this.setState({ color });

  onDescriptionChange = textInput => {
    const { description } = this.state;
    if (textInput.length < 351 || textInput.length < description) {
      this.setState({ description: textInput });
    } else {
      this.setState({ description: textInput.slice(0, 350) });
    }
  };

  onDialogConfirm = () => {
    this.props.setAudienceConfirmationDialog({ reset: true });
    this.setState({ campaigns: [] });
    setTimeout(() => {
      this.editProduct();
    }, 0);
  };

  onDialogDecline = () => {
    this.props.setAudienceConfirmationDialog({ reset: true });
  };

  render() {
    const { productError, t, buttonLoading, confirmationDialog } = this.props;
    const {
      initialLoading,
      name,
      description,
      price,
      color,
      openErrorSnack,
      loading,
      images,
      previewDialogOpen,
      url,
      api_product_id,
      editProductError,
      fileTypeErrorSnack,
      imagesToCrop,
      isBackDropOpen,
      status,
      vat_percent,
      selectedOrientation
    } = this.state;
    const regexp = new RegExp("^[0-9.,]+$");
    const fieldsAreMissing = !name || !description || !price || !vat_percent || !images.length;
    return (
      <div className="edit_product_wrapper">
        {initialLoading ? (
          <Loader />
        ) : (
          <div>
            <div className="title">{t("t:products.edit-product")}</div>
            <span className="info">{`${t("t:products.product-name")} *`}</span>
            <div className="input_wrapper">
              <input
                type="text"
                value={name}
                onChange={e => this.setState({ name: e.target.value })}
              />
            </div>
            <span className="info">{`${t("t:products.product-description")} *`}</span>
            <div className="input_wrapper">
              <textarea
                value={description}
                onChange={e => this.onDescriptionChange(e.target.value)}
                placeholder={t("t:products.product-description-placeholder")}
              />
              <CharactersLeft title={description} maxCharacters={350} />
            </div>
            <span className="info">
              {t("t:products.product-id")}
              <TooltipMessage
                text={<p>{t("t:helper-texts.product-sku")}</p>}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img className="tooltip-question-icon" src={Shape} alt="Shape" />
              </TooltipMessage>
            </span>
            <div className="input_wrapper product-id">
              <input
                type="text"
                value={api_product_id}
                onChange={e => this.setState({ api_product_id: e.target.value })}
              />
            </div>
            <span className="info">{t("t:products.product-url")}</span>
            <div className="input_wrapper">
              <input
                type="text"
                value={url}
                onChange={e => this.setState({ url: e.target.value })}
                placeholder={t("t:products.product-url-placeholder")}
              />
            </div>
            <span className="info">
              {`${t("t:products.product-images")} *`}
              <TooltipMessage
                text={
                  <p>
                    {t("t:helper-texts.image")} <br />
                    {t("t:helper-texts.aspect-ratio-and-min-res", {
                      aspect_ratio: "16:10",
                      min_resolution: "1280x800"
                    })}
                  </p>
                }
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img className="tooltip-question-icon" src={Shape} alt="Shape" />
              </TooltipMessage>
            </span>
            {images.map(({ id, image }) => (
              <div className="drag_img" key={id}>
                <div>
                  <CloudinaryImage
                    cloudName="campy"
                    width="39"
                    heigth="39"
                    gravity="face"
                    crop="thumb"
                    publicId={image.split("/").pop()}
                  />
                  <div>{image.name || image.split("/").pop()}</div>
                </div>
                <div>
                  <button onClick={() => this.setState({ previewDialogOpen: id })}>
                    <img src={eye} alt="eye" />
                  </button>
                  <button
                    onClick={() => this.setState({ images: images.filter(i => i.id !== id) })}
                  >
                    <img src={del} alt="del" />
                  </button>
                </div>
              </div>
            ))}
            <Dropzone
              placeholder={t("t:common.drop-your-image-here")}
              multiple
              handleFiles={this.handleFiles}
            />
            <div className="dp_flex">
              <div>
                <span className="info">
                  {`${t("t:products.product-full-price")} *`}
                  <TooltipMessage
                    text={<p>{t("t:helper-texts.product-vat")}</p>}
                    delay={200}
                    position="top"
                    classes="step_tooltip"
                  >
                    <img className="tooltip-question-icon" src={Shape} alt="Shape" />
                  </TooltipMessage>
                </span>
                <div className="price_block_input">
                  <input
                    type="text"
                    value={price}
                    disabled={status === "in_use"}
                    onChange={e => {
                      if (
                        e.target.value === "" ||
                        regexp.test(e.target.value) ||
                        e.target.value.length < (price || "").length
                      ) {
                        this.setState({
                          price: e.target.value.replace(",", ".")
                        });
                      }
                    }}
                  />
                  <span>€</span>
                </div>
              </div>
              <div>
                <span className="info">{`${t("t:products.vat")} *`}</span>
                <div className="price_block_input">
                  <input
                    type="text"
                    value={vat_percent}
                    disabled={status === "in_use"}
                    onChange={e => {
                      if (
                        e.target.value === "" ||
                        regexp.test(e.target.value) ||
                        e.target.value.length < (vat_percent || "").length
                      ) {
                        this.setState({ vat_percent: e.target.value });
                      }
                    }}
                  />
                  <span>%</span>
                </div>
              </div>
              <div>
                <span className="info">{t("t:products.color")}</span>
                <ColorPicker
                  colorID={color}
                  handleColorChange={this.handleColorChange}
                  fieldHeight={21}
                />
              </div>
            </div>
            <div className="btn_wrapper" style={{ marginTop: 60 }}>
              <TooltipMessage
                text={fieldsAreMissing ? t("t:common.enter-mandatory-fields") : ""}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <div>
                  <DefaultButton
                    variant="contained"
                    classes="add_product"
                    type="button"
                    onClick={this.editProduct}
                    loading={loading}
                    disabled={fieldsAreMissing || loading}
                  >
                    {t("t:common.save-changes")}
                  </DefaultButton>
                </div>
              </TooltipMessage>
            </div>
            <Link to="/main/products" className="text_hover back">
              {t("t:common.cancel")}
            </Link>
            <Snack
              open={openErrorSnack}
              handleSnack={() => this.setState({ openErrorSnack: false })}
              message={
                productError &&
                Object.values(productError)[0] &&
                Object.values(productError)[0][0].message
              }
              type="error"
            />
            <Snack
              open={fileTypeErrorSnack}
              handleSnack={() => this.setState({ fileTypeErrorSnack: false })}
              message={t("t:snack-messages.file-type-fail")}
              type="error"
            />
            <DialogComponent
              open={!!previewDialogOpen}
              onClose={() => this.setState({ previewDialogOpen: false })}
              closeIcon
              classes="image_dialog"
              rootClass="image_root_dialog"
            >
              <div>
                <img src={images.find(i => i.id === previewDialogOpen)?.image} alt="preview" />
              </div>
            </DialogComponent>
            <DialogComponent
              open={editProductError}
              onClose={() => this.setState({ editProductError: false })}
              closeIcon
            >
              <div className="delete_dialog product_delete_dialog">
                <div className="dialog_name">{t("t:products.edit-product")}</div>
                <div className="dialog_title">{t("t:products.product-edit-fail")}</div>
                <div className="edit_product_ok_btn">
                  <DefaultButton
                    variant="contained"
                    classes="ok_btn"
                    onClick={() => this.setState({ editProductError: false })}
                  >
                    {t("t:common.ok")}
                  </DefaultButton>
                </div>
              </div>
            </DialogComponent>
            {!!imagesToCrop.length && (
              <CropperComponent
                aspectRatio="product"
                imageToCrop={imagesToCrop[0].dataUrl}
                onFinishCropping={this.uploadImage}
                onCancelCropping={this.onCancelCropping}
                withOrientation={!selectedOrientation}
                lockedOrientation={selectedOrientation}
              />
            )}
            <Backdrop open={isBackDropOpen} style={{ color: "white", zIndex: 9999 }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        <ConfirmationDialog
          {...confirmationDialog}
          buttonLoading={buttonLoading}
          confirmAction={this.onDialogConfirm}
          declineAction={this.onDialogDecline}
          onClose={this.onDialogDecline}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ products, audience }) => {
  return {
    productError: products.productError,
    confirmationDialog: audience.confirmationDialog,
    buttonLoading: audience.buttonLoading
  };
};

const mapDispatchToProps = {
  resetProductErrors,
  patchProduct,
  getProduct,
  setProductError,
  setAudienceConfirmationDialog,
  postProductImage
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditProduct));
