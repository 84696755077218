import React from "react";
import { useTranslation } from "react-i18next";

const YesNo = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ textAlign: "left", width: "100%" }}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="yes-no">
        <div>{t("t:common.yes")}</div>
        <div>{t("t:common.no")}</div>
      </div>
    </>
  );
};

export default YesNo;
