import React from "react";
import { Image as CloudinaryImage } from "cloudinary-react";
import clsx from "clsx";

import NoImage from "../../../../assets/image/no_image.svg";
import location from "../../../../assets/image/CampaignPanel/location.svg";
import location_blue from "../../../../assets/image/CampaignPanel/location_blue.svg";
import LocationPlaceholder from "../../../../assets/image/location_placeholder.svg";
import video from "../../../../assets/image/CampaignPanel/video.svg";
import video_blue from "../../../../assets/image/CampaignPanel/video_blue.svg";
import VideoPlaceholder from "../../../../assets/image/video_placeholder.svg";
import img from "../../../../assets/image/CampaignPanel/img.svg";
import img_blue from "../../../../assets/image/CampaignPanel/img_blue.svg";
import hyper_Icon from "../../../../assets/image/CampaignPanel/hyperlink.svg";
import hyperlink_blue from "../../../../assets/image/CampaignPanel/hyperlink_blue.svg";
import scan from "../../../../assets/image/CampaignPanel/scan.svg";
import scan_blue from "../../../../assets/image/CampaignPanel/scan_blue.svg";
import instant from "../../../../assets/image/CampaignPanel/instant.svg";
import instant_blue from "../../../../assets/image/CampaignPanel/instant_blue.svg";
import check_text from "../../../../assets/image/CampaignPanel/check_text.svg";
import check_text_blue from "../../../../assets/image/CampaignPanel/check_text_blue.svg";
import check_list from "../../../../assets/image/CampaignPanel/check_list.svg";
import check_list_green from "../../../../assets/image/CampaignPanel/check_list_green.svg";
import color_text from "../../../../assets/image/CampaignPanel/color.svg";
import color_green from "../../../../assets/image/CampaignPanel/color_green.svg";
import layers from "../../../../assets/image/CampaignPanel/layers.svg";
import layers_green from "../../../../assets/image/CampaignPanel/layers_green.svg";
import path from "../../../../assets/image/CampaignPanel/path.svg";
import path_green from "../../../../assets/image/CampaignPanel/path_green.svg";
import star from "../../../../assets/image/CampaignPanel/star.svg";
import star_green from "../../../../assets/image/CampaignPanel/star_green.svg";
import quiz from "../../../../assets/image/CampaignPanel/quiz.svg";
import quiz_green from "../../../../assets/image/CampaignPanel/quiz_green.svg";
import coupon from "../../../../assets/image/CampaignPanel/coupon.svg";
import coupon_yellow from "../../../../assets/image/CampaignPanel/coupon_yellow.svg";
import present from "../../../../assets/image/CampaignPanel/present.svg";
import present_yellow from "../../../../assets/image/CampaignPanel/present_yellow.svg";
import euro from "../../../../assets/image/CampaignPanel/euro.svg";
import euro_yellow from "../../../../assets/image/CampaignPanel/euro_yellow.svg";
import novelty from "../../../../assets/image/CampaignPanel/novelty.svg";
import novelty_yellow from "../../../../assets/image/CampaignPanel/novelty_yellow.svg";
// import bar from "../../../../../assets/image/CampaignPanel/bar.svg";
// import bar_blue from "../../../../../assets/image/CampaignPanel/bar_blue.svg";
// import barcode from "../../../../../assets/image/CampaignPanel/barcode.svg";
// import barcode_blue from "../../../../../assets/image/CampaignPanel/barcode_blue.svg";

// progressOnMobile: "Done" || "Coming soon"

const getChallenges = t => [
  {
    name: "location",
    img: location,
    imgOnHover: location_blue,
    label: t("t:build-campaign.location"),
    previewImage: LocationPlaceholder,
    progressOnMobile: "Done"
  },
  {
    name: "video",
    img: video,
    imgOnHover: video_blue,
    label: t("t:build-campaign.video"),
    previewImage: VideoPlaceholder,
    progressOnMobile: "Done"
  },
  {
    name: "image",
    img: img,
    imgOnHover: img_blue,
    label: t("t:build-campaign.image"),
    progressOnMobile: "Done"
  },
  {
    name: "url",
    img: hyper_Icon,
    imgOnHover: hyperlink_blue,
    label: t("t:build-campaign.url"),
    progressOnMobile: "Done"
  },
  {
    name: "scan_qr",
    img: scan,
    imgOnHover: scan_blue,
    label: t("t:build-campaign.scan-qr"),
    progressOnMobile: "Done"
  },
  {
    name: "photo",
    img: instant,
    imgOnHover: instant_blue,
    label: t("t:build-campaign.photo"),
    progressOnMobile: "Done"
  },
  // {
  //     name: "new_qr",
  //     img: barcode,
  //     imgOnHover: barcode_blue,
  //     label: t("t:build-campaign.new-qr"),
  //     progressOnMobile: "Done"
  // },
  // {
  //     name: "new_barcode",
  //     img: bar,
  //     imgOnHover: bar_blue,
  //     label: t("t:build-campaign.new-barcode"),
  //     progressOnMobile: "Done"
  // },
  {
    name: "check_text",
    img: check_text,
    imgOnHover: check_text_blue,
    label: t("t:build-campaign.check-text"),
    progressOnMobile: "Done"
  }
];

const getResponses = t => [
  {
    name: "multiple",
    img: check_list,
    imgOnHover: check_list_green,
    label: t("t:build-campaign.multiple"),
    progressOnMobile: "Done"
  },
  {
    name: "short_text",
    img: color_text,
    imgOnHover: color_green,
    label: t("t:build-campaign.short-text"),
    progressOnMobile: "Done"
  },
  {
    name: "pictures",
    img: layers,
    imgOnHover: layers_green,
    label: t("t:build-campaign.pictures"),
    progressOnMobile: "Done"
  },
  {
    name: "yes_no",
    img: path,
    imgOnHover: path_green,
    label: t("t:build-campaign.yes-no"),
    progressOnMobile: "Done"
  },
  {
    name: "rating",
    img: star,
    imgOnHover: star_green,
    label: t("t:build-campaign.rating"),
    progressOnMobile: "Done"
  },
  {
    name: "quiz",
    img: quiz,
    imgOnHover: quiz_green,
    label: t("t:build-campaign.quiz"),
    progressOnMobile: "Done"
  }
];

const getRewards = t => [
  {
    name: "discount",
    img: coupon,
    imgOnHover: coupon_yellow,
    label: t("t:build-campaign.discount"),
    progressOnMobile: "Done"
  },
  {
    name: "bonus",
    img: present,
    imgOnHover: present_yellow,
    label: t("t:build-campaign.bonus"),
    progressOnMobile: "Done"
  },
  {
    name: "monetary",
    img: euro,
    imgOnHover: euro_yellow,
    label: t("t:build-campaign.monetary"),
    progressOnMobile: "Done"
  },
  {
    name: "novelty",
    img: novelty,
    imgOnHover: novelty_yellow,
    label: t("t:build-campaign.novelty"),
    progressOnMobile: "Done"
  }
];

const renderMobileImage = (image, width = 234, height = undefined) => {
  if (image && image.includes(".cloudinary.")) {
    return (
      <CloudinaryImage
        className="mobile-icon"
        cloudName="campy"
        width={width}
        height={height}
        gravity="custom"
        crop="thumb"
        publicId={image.split("/").pop()}
      />
    );
  }
  return (
    <img
      src={image || NoImage}
      alt="preview"
      className={clsx("mobile-icon", !image && "fixed-height")}
      style={{ width, height: height || "unset" }}
    />
  );
};

export { getChallenges, getResponses, getRewards, renderMobileImage };
