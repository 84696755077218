import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import SelectComponent from "../../../HelperComponents/SelectComponent/SelectComponent";
import { getOption } from "../../../../helpers/functions";

import BackIcon from "../../../../assets/image/small-left.svg";

const StepZero = ({ fetchProducts, dataSource, setDataSource, params, setParams }) => {
  const dataSources = useSelector(({ dataSources }) => dataSources.dataSourcesList);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!dataSource.value) {
      if (dataSources && dataSources.length) {
        let options = [];
        dataSources.forEach(({ id, name }) => options.push({ label: getOption(name), value: id }));
        setDataSource({ options, value: dataSources.length === 1 ? options[0] : null });
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(dataSources)]);

  const onProceedClick = async () => {
    setLoading(true);
    await fetchProducts(dataSource.value.value, params);
    setLoading(false);
  };

  return (
    <div>
      <Link to="/main/products" className="text_hover back">
        <img src={BackIcon} alt="" />
        {t("t:data-sources.back-to-products")}
      </Link>
      <div className="title">{t("t:data-sources.choose-api-data-source")}</div>
      <div className="description">{t("t:data-sources.select-one-data-source")}</div>
      <FormControl className={"select_wrapper"}>
        <SelectComponent
          value={dataSource.value}
          options={dataSource.options}
          change={event => setDataSource({ ...dataSource, value: event })}
          isClearable="false"
          isSearchable={false}
          placeholder={t("t:data-sources.choose-data-source")}
        />
      </FormControl>
      <hr className="separator" />
      <div className="title">{t("t:data-sources.choose-way-to-add-products")}</div>
      <div className="description">
        {t("t:data-sources.choose-way-to-add-products-description")}
      </div>
      <div className="item_radio">
        <div className="option_wrapper">
          <div onClick={() => setParams({ ...params, id: "", option: "search_by_keyword" })}>
            <input
              type="radio"
              name="single"
              checked={params.option === "search_by_keyword"}
              onChange={() => console.log("radio changed")}
            />
            <label htmlFor="radio1">{t("t:data-sources.search-by-keyword")}</label>
          </div>
          <input
            value={params.search}
            onChange={e => setParams({ ...params, search: e.target.value })}
            type="text"
            placeholder={t("t:data-sources.keyword")}
            disabled={params.option !== "search_by_keyword"}
          />
        </div>
        <hr />
        <div className="option_wrapper">
          <div onClick={() => setParams({ ...params, id: "", option: "all_products" })}>
            <input
              type="radio"
              name="multiple"
              checked={params.option === "all_products"}
              onChange={() => console.log("radio changed")}
            />
            <label htmlFor="radio1">{t("t:data-sources.all-products")}</label>
          </div>
        </div>
        <hr />
        <div className="option_wrapper">
          <div onClick={() => setParams({ ...params, option: "product_by_id" })}>
            <input
              type="radio"
              name="multiple"
              checked={params.option === "product_by_id"}
              onChange={() => console.log("radio changed")}
            />
            <label htmlFor="radio1">{t("t:data-sources.product-by-id")}</label>
          </div>
          <input
            value={params.id}
            onChange={e => setParams({ ...params, id: e.target.value })}
            type="text"
            placeholder={t("t:data-sources.product-id")}
            disabled={params.option !== "product_by_id"}
          />
        </div>
        <hr />
      </div>
      <div className="btn_wrapper">
        <DefaultButton
          variant="contained"
          classes="auth"
          disabled={
            !dataSource.value ||
            (params.option === "product_by_id" && !params.id) ||
            (params.option === "search_by_keyword" && !params.search)
          }
          onClick={onProceedClick}
          loading={loading}
        >
          {t("t:data-sources.proceed")}
        </DefaultButton>
      </div>
    </div>
  );
};

export default StepZero;
