// import { PROFILE } from "../constants";

const INITIAL_STATE = {
  profile_info: {}
};

const reduceProfile = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case PROFILE.GET_PROFILE_INFO_SUCCESS:
    //     return { ...state, profile_info: action.payload.data };
    default:
      return state;
  }
};

export default reduceProfile;
