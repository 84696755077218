import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import StatusBarIcon from "../../../../../assets/image/mobile-statusbar.svg";
import CloseIcon from "../../../../../assets/image/mobile-close.svg";
import BackIcon from "../../../../../assets/image/mobile-back.svg";

import Location from "./Location";
import Video from "./Video";
import Image from "./Image";
import Url from "./Url";
import ScanQR from "./ScanQR";
import Photo from "./Photo";
import CheckText from "./CheckText";

import Multiple from "./Multiple";
import ShortText from "./ShortText";
import Rating from "./Rating";
import YesNo from "./YesNo";
import Pictures from "./Pictures";
import Quiz from "./Quiz";

import Novelty from "./Novelty";
import Bonus from "./Bonus";
import Discount from "./Discount";
import Monetary from "./Monetary";

import "./styles.scss";

const StepMobilePreview = () => {
  const currentStepInfo = useSelector(({ campaign }) => campaign.currentStepInfo);
  const { t } = useTranslation();

  const renderInfo = useMemo(() => {
    switch (currentStepInfo.step) {
      case "location":
        return <Location {...currentStepInfo.location} />;
      case "video":
        return <Video {...currentStepInfo.video} />;
      case "image":
        return <Image {...currentStepInfo.image} />;
      case "url":
        return <Url {...currentStepInfo.url} />;
      case "scan_qr":
        return <ScanQR {...currentStepInfo.scan_qr} />;
      case "photo":
        return <Photo {...currentStepInfo.photo} />;
      case "check_text":
        return <CheckText {...currentStepInfo.check_text} />;
      case "multiple":
        return <Multiple {...currentStepInfo.multiple} />;
      case "quiz":
        return <Quiz {...currentStepInfo.quiz} />;
      case "short_text":
        return <ShortText {...currentStepInfo.short_text} />;
      case "rating":
        return <Rating {...currentStepInfo.rating} />;
      case "yes_no":
        return <YesNo {...currentStepInfo.yes_no} />;
      case "pictures":
        return <Pictures {...currentStepInfo.pictures} />;
      case "novelty":
        return <Novelty {...currentStepInfo.novelty} />;
      case "bonus":
        return <Bonus {...currentStepInfo.bonus} />;
      case "discount":
        return <Discount {...currentStepInfo.discount} />;
      case "monetary":
        return <Monetary {...currentStepInfo.monetary} />;
      case "not-selected":
      default:
        return null;
    }
  }, [currentStepInfo]);

  const renderContinueButton = useMemo(() => {
    switch (currentStepInfo.step) {
      case "image":
        return (
          <div className="continue-btn progress-btn">
            <p>{t("t:common.continue")}</p>
          </div>
        );
      case "yes_no":
      case "pictures":
        return (
          <div className="continue-btn disabled">
            <p>{t("t:common.continue")}</p>
          </div>
        );
      case "novelty":
      case "discount":
      case "bonus":
      case "monetary":
        return (
          <div className="continue-btn">
            <p>{t("t:build-campaign.mobile-preview--done")}</p>
          </div>
        );
      default:
        return <div className="continue-btn">{t("t:common.continue")}</div>;
    }
    // eslint-disable-next-line
  }, [currentStepInfo]);

  return (
    <section className="step-mobile-preview">
      <div className="step-mobile-preview__note">
        {t("t:build-campaign.this-is-how-campaign-will-look")}
      </div>
      <div className="step-mobile-preview__mobile-preview">
        <div className="step-mobile-preview__mobile-preview--view">
          <div className="step-mobile-preview__mobile-preview--mobile-panel">
            <div />
            <span />
          </div>
          {currentStepInfo.step === "novelty" ||
          currentStepInfo.step === "discount" ||
          currentStepInfo.step === "bonus" ||
          currentStepInfo.step === "monetary" ? (
            <div className="step-mobile-preview__mobile-preview--header body-background">
              <img
                src={StatusBarIcon}
                alt="status bar"
                className="step-mobile-preview__mobile-preview--statusbar"
              />
            </div>
          ) : (
            <div className="step-mobile-preview__mobile-preview--header">
              <img
                src={StatusBarIcon}
                alt="status bar"
                className="step-mobile-preview__mobile-preview--statusbar"
              />
              <div className="step-mobile-preview__mobile-preview--header-wrapper">
                <img src={BackIcon} alt="<" />
                <p>{t(`t:build-campaign.mobile-preview--${currentStepInfo.step}`)}</p>
                <img src={CloseIcon} alt="X" />
              </div>
            </div>
          )}
          <div
            className={clsx(
              "step-mobile-preview__mobile-preview--body",
              (currentStepInfo.step === "rating" || currentStepInfo.step === "yes_no") &&
                "flex-center"
            )}
          >
            {renderInfo}
          </div>
          <div className="step-mobile-preview__mobile-preview--footer">{renderContinueButton}</div>
        </div>
      </div>
    </section>
  );
};

export default StepMobilePreview;
