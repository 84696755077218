import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Snack from "../../HelperComponents/Snack/Snack";

import { resetCompanyErrors, postCompany } from "../../../actions/companiesActions";
import { getProfile } from "../../../actions/authActions";

import "./styles.scss";

class AddCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      campaignName: "",
      openSnack: false
    };
  }

  componentDidMount() {
    this.doRequest(1);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeout2);
  }

  doRequest = async page => {};

  handleChange = name => event => {
    this.setState({ [name]: event });
  };

  closeSnack = () => {
    this.setState({ openSnack: false });
  };

  createCampaign = async () => {
    const { postCompany, history, getProfile, resetCompanyErrors } = this.props;
    const { campaignName } = this.state;
    this.setState({ loading: true });
    const res = await postCompany({ name: campaignName });
    if (res.error) {
      this.timeout = setTimeout(() => {
        this.setState({ openSnack: true, loading: false });
      }, 500);
    } else {
      this.timeout = setTimeout(() => {
        resetCompanyErrors();
        history.push(`/main/campaign/${res.payload.data.id}?editor-mode=true`);
        getProfile();
      }, 3000);
    }
  };

  render() {
    const { companyError, campaignCreationSuccess, t } = this.props;
    const { campaignName, openSnack, loading } = this.state;
    return (
      <div className="add_campaign_wrapper">
        <div>
          <div className="title">{t("t:campaigns.add-campaign-title")}</div>
          <span className="info">{`${t("t:campaigns.name-of-campaign")} *`}</span>
          <div className="input_wrapper">
            <input
              type="text"
              value={campaignName}
              onChange={e => this.setState({ campaignName: e.target.value })}
            />
            <p>{t("t:campaigns.for-internal-use")}</p>
          </div>

          {/* TODO in the future sprints */}
          {/*<div className="info">Do you want to use a template as a starting point?</div>*/}
          {/*<FormControl className="select_wrapper">*/}
          {/*    <SelectComponent*/}
          {/*        value={template}*/}
          {/*        options={templateList}*/}
          {/*        change={this.handleChange("template")}*/}
          {/*        isClearable="false"*/}
          {/*        isSearchable={false}*/}
          {/*        placeholder="Select template"*/}
          {/*    />*/}
          {/*</FormControl>*/}

          <div className="btn_wrapper">
            <DefaultButton
              variant="contained"
              classes="auth"
              type="button"
              onClick={this.createCampaign}
              loading={loading}
              disabled={!campaignName}
            >
              {t("t:common.continue")}
            </DefaultButton>
          </div>
          <Link to="/main/campaigns" className="text_hover back">
            {t("t:common.cancel")}
          </Link>
          <Snack
            open={openSnack || campaignCreationSuccess}
            handleSnack={this.closeSnack}
            message={campaignCreationSuccess ? t("t:campaigns.campaign-created") : companyError}
            type={campaignCreationSuccess ? "success" : "error"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, companies }) => {
  return {
    user_info: auth.user_info,
    companyError: companies.creatingCompanyError,
    campaignCreationSuccess: companies.campaignCreationSuccess
  };
};

const mapDispatchToProps = {
  resetCompanyErrors,
  postCompany,
  getProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCampaign));
