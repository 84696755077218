import React, { Component } from "react";
import { connect } from "react-redux";
import { Image as CloudinaryImage } from "cloudinary-react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getOption, renderPageLoader, splitByCommas } from "../../../helpers/functions";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Pagination from "../../HelperComponents/Pagination/Pagination";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";

import {
  getCompanies,
  postArchiveCompany,
  postLeaveCompany,
  postRestoreCompany,
  setCurrentCompany
} from "../../../actions/companiesActions";
import { handleBalanceDialog } from "../../../actions/balanceActions";

import add from "../../../assets/image/i-add.svg";
import bg_icon from "../../../assets/image/auth_bg_with_assets.png";
import menu from "../../../assets/image/menu-5.svg";
import EditIcon from "../../../assets/image/i-edit.svg";
import zoom from "../../../assets/image/zoom.svg";
import SourcesIcon from "../../../assets/image/data_icon.svg";
import APIIcon from "../../../assets/image/api_icon.svg";
import ArchiveIcon from "../../../assets/image/archive_icon.svg";
import RestoreIcon from "../../../assets/image/restore_icon.svg";
import CheckIcon from "../../../assets/image/check.svg";
import CampyCoinIcon from "../../../assets/image/campycoin.svg";

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noInitialInfo: false,
      activePage: 1,
      searchValue: "",
      openedControls: null,
      status: { label: getOption(props.t("t:companies.all-companies")), value: "" },
      statusList: [
        { label: getOption(props.t("t:companies.all-companies")), value: "" },
        { label: getOption(props.t("t:companies.active-companies")), value: "active" },
        { label: getOption(props.t("t:companies.archived-companies")), value: "archived" }
      ],
      restoreDialog: false,
      archiveDialog: false,
      archiveFailureDialog: false,
      switchDialog: false,
      leaveDialog: false,
      companyId: undefined
    };
  }

  componentDidMount() {
    this.fetchCompanies(1).then(() => this.setState({ noInitialInfo: true }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.status.value !== this.state.status.value ||
      prevState.searchValue !== this.state.searchValue
    ) {
      this.fetchCompanies(1).then(() => {
        this.setState({ activePage: 1 });
      });
    }
  }

  fetchCompanies = async page => {
    const { getCompanies } = this.props;
    const { status, searchValue } = this.state;
    let data = [];
    data.push(`page=${page}`);
    data.push(`page_size=5`);
    status.value && data.push(`is_archived=${status.value === "archived"}`);
    searchValue && data.push(`search=${searchValue}`);
    await getCompanies(data);
  };

  handleChange = name => event => {
    this.setState({ [name]: event });
  };

  handleControls = id => {
    const { openedControls } = this.state;
    if (openedControls === id) {
      this.setState({ openedControls: null });
    } else {
      this.setState({ openedControls: id });
    }
  };

  toggleDialog = (type, companyId = undefined) => {
    switch (type) {
      case "restore_company":
        this.setState(({ restoreDialog }) => ({
          restoreDialog: !restoreDialog,
          companyId
        }));
        break;
      case "archive_company":
        this.setState(({ archiveDialog }) => ({
          archiveDialog: !archiveDialog,
          companyId
        }));
        break;
      case "archive_failure":
        this.setState(({ archiveFailureDialog }) => ({
          archiveFailureDialog: !archiveFailureDialog
        }));
        break;
      case "switch_company":
        this.setState(({ switchDialog }) => ({ switchDialog: !switchDialog, companyId }));
        break;
      case "leave_company":
        this.setState(({ leaveDialog }) => ({ leaveDialog: !leaveDialog, companyId }));
        break;
      default:
        break;
    }
  };

  switchCompany = async () => {
    const { setCurrentCompany } = this.props;
    const { companyId, activePage } = this.state;
    await setCurrentCompany(companyId);
    this.toggleDialog("switch_company");
    this.fetchCompanies(activePage);
  };

  leaveCompany = async () => {
    const { postLeaveCompany } = this.props;
    const { companyId } = this.state;
    await postLeaveCompany(companyId);
    this.toggleDialog("leave_company");
    this.fetchCompanies(1).then(() => {
      this.setState({ activePage: 1 });
    });
  };

  archiveCompany = async () => {
    const { postArchiveCompany } = this.props;
    const { companyId, activePage } = this.state;
    const res = await postArchiveCompany(companyId);
    this.toggleDialog("archive_company");
    if (res.payload) {
      this.fetchCompanies(activePage);
    } else {
      this.toggleDialog("archive_failure");
    }
  };

  restoreCompany = async () => {
    const { postRestoreCompany } = this.props;
    const { companyId, activePage } = this.state;
    await postRestoreCompany(companyId);
    this.toggleDialog("restore_company");
    this.fetchCompanies(activePage);
  };

  render() {
    const {
      loading,
      companies,
      history,
      handleBalanceDialog,
      user_info: { current_company },
      t
    } = this.props;
    const {
      noInitialInfo,
      searchValue,
      status,
      statusList,
      activePage,
      openedControls,
      restoreDialog,
      archiveDialog,
      archiveFailureDialog,
      switchDialog,
      leaveDialog
    } = this.state;
    if (!noInitialInfo) return renderPageLoader();
    return (
      <div className="companies_wrapper">
        <div className="campaigns_title_block">
          <div className="block">
            <div className="title_panel">
              <div className="search">
                <input
                  value={searchValue}
                  onChange={e => this.setState({ searchValue: e.target.value })}
                  type="text"
                  placeholder={t("t:companies.search-company")}
                />
                <button>
                  <img src={zoom} alt="zoom" />
                </button>
              </div>
              <FormControl className="select_wrapper">
                <SelectComponent
                  value={status}
                  options={statusList}
                  change={this.handleChange("status")}
                  isClearable="false"
                  isSearchable={false}
                  placeholder={t("t:common.status")}
                />
              </FormControl>
            </div>
          </div>
          <div className="btn_wrapper">
            <DefaultButton variant="contained" classes="auth" type="link" to="/main/new-company">
              {t("t:companies.new-company")} <img src={add} alt="add" />
            </DefaultButton>
          </div>
        </div>
        <div className="companies_content">
          {companies.results.map(
            ({ id, name, is_current, is_owner, is_archived, can_leave, image }) => (
              <div key={`company_item_${id}`}>
                <Link className="content" to={`/main/companies/view-company/${id}`}>
                  <div className="img_block">
                    {image ? (
                      <CloudinaryImage
                        cloudName="campy"
                        width="133"
                        gravity="face"
                        crop="thumb"
                        publicId={image.split("/").pop()}
                      />
                    ) : (
                      <img src={bg_icon} alt="bg_icon" />
                    )}
                  </div>
                  <div className="user_info">
                    {is_owner ? (
                      <p className="is_owner">{t("t:companies.owner")}</p>
                    ) : (
                      <p>{t("t:companies.invited")}</p>
                    )}
                    {is_archived && <p className="is_archived">{t("t:companies.archived")}</p>}
                    <div>{name}</div>
                    {is_current && (
                      <div className="active_one">
                        <img src={CheckIcon} alt="active" />
                        {t("t:companies.active-company")}
                      </div>
                    )}
                  </div>
                </Link>
                <div className="more_info_btn">
                  {is_current ? (
                    <div className="btn_wrapper">
                      {is_owner && (
                        <div className="company_balance">
                          <div className="balance_label">{t("t:balance.balance")}</div>
                          <div className="balance_value">
                            <img src={CampyCoinIcon} alt="coin" className="campy-coin__22" />
                            {splitByCommas(current_company.balance || "0.00")}
                          </div>
                        </div>
                      )}
                      {!is_archived && !current_company.is_monthly_payment_enabled && (
                        <DefaultButton
                          variant="outlined"
                          classes="switch_btn"
                          onClick={() => handleBalanceDialog(true, "get_invoice")}
                        >
                          {t("t:balance.get-invoice")}
                        </DefaultButton>
                      )}
                    </div>
                  ) : (
                    <div className="btn_wrapper">
                      {!is_archived && (
                        <DefaultButton
                          variant="outlined"
                          classes="switch_btn"
                          onClick={() => this.toggleDialog("switch_company", id)}
                        >
                          {t("t:common.switch")}
                        </DefaultButton>
                      )}
                      {can_leave && (
                        <DefaultButton
                          variant="outlined"
                          classes="leave_btn"
                          onClick={() => this.toggleDialog("leave_company", id)}
                        >
                          {t("t:common.leave")}
                        </DefaultButton>
                      )}
                    </div>
                  )}
                  {is_owner ? (
                    <IconButton
                      onClick={() => this.handleControls(id)}
                      classes={{
                        root: "dialog_close_button"
                      }}
                    >
                      <img src={menu} alt="menu" />
                    </IconButton>
                  ) : (
                    <div className="dialog_close_button" />
                  )}
                </div>
                {openedControls === id && (
                  <ClickAwayListener onClickAway={() => this.handleControls(id)}>
                    <div className="menu">
                      <button onClick={() => history.push(`/main/company/${id}`)}>
                        <img src={EditIcon} alt="edit" />
                        {t("t:companies.edit-company")}
                      </button>
                      {is_archived ? (
                        <button onClick={() => this.toggleDialog("restore_company", id)}>
                          <img src={RestoreIcon} alt="restore" />
                          {t("t:companies.restore-company")}
                        </button>
                      ) : !is_current ? (
                        <button onClick={() => this.toggleDialog("archive_company", id)}>
                          <img src={ArchiveIcon} alt="archive" />
                          {t("t:companies.archive-company")}
                        </button>
                      ) : (
                        ""
                      )}
                      <button onClick={() => history.push(`/main/add-new-data-source/${id}`)}>
                        <img src={SourcesIcon} alt="data sources" />
                        {t("t:companies.configure-data-sources")}
                      </button>
                      <button onClick={() => history.push(`/main/company-api-access/${id}`)}>
                        <img src={APIIcon} alt="public api access" />
                        {t("t:companies.public-api-access")}
                      </button>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            )
          )}
        </div>
        <div className="pagination_wrapper">
          {companies.count > 5 && (
            <Pagination
              active={activePage}
              pageCount={Math.ceil(companies.count / 5)}
              onChange={e => {
                this.fetchCompanies(e.selected + 1).then(() => {
                  this.setState({ activePage: e.selected + 1 });
                });
              }}
              t={t}
            />
          )}
        </div>
        <DialogComponent
          open={restoreDialog}
          onClose={() => this.toggleDialog("restore_company")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:companies.restore-company")}</div>
            <div className="dialog_description">
              {t("t:companies.restore-company-confirmation")}
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.restoreCompany}
                loading={loading}
              >
                {t("t:companies.restore")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("restore_company")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={archiveDialog}
          onClose={() => this.toggleDialog("archive_company")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:companies.archive-company")}</div>
            <div className="dialog_description">
              {t("t:companies.archive-company-confirmation")}
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.archiveCompany}
                loading={loading}
              >
                {t("t:companies.archive")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("archive_company")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={archiveFailureDialog}
          onClose={() => this.toggleDialog("archive_failure")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:companies.archive-company")}</div>
            <div className="dialog_description">
              {t("t:companies.archive-company-with-campaigns-error")}
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={() => this.toggleDialog("archive_failure")}
              >
                {t("t:common.ok")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={switchDialog}
          onClose={() => this.toggleDialog("switch_company")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:companies.switch-company")}</div>
            <div className="dialog_description">{t("t:companies.switch-company-confirmation")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.switchCompany}
                loading={loading}
              >
                {t("t:common.switch")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("switch_company")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={leaveDialog}
          onClose={() => this.toggleDialog("leave_company")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:companies.leave-company")}</div>
            <div className="dialog_description">{t("t:companies.leave-company-confirmation")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.leaveCompany}
                loading={loading}
              >
                {t("t:common.leave")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("leave_company")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, companies, dashboard }) => {
  return {
    companies: companies.companiesList,
    loading: dashboard.loadingComponent,
    user_info: auth.user_info
  };
};

const mapDispatchToProps = {
  getCompanies,
  postArchiveCompany,
  postLeaveCompany,
  postRestoreCompany,
  setCurrentCompany,
  handleBalanceDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Companies));
