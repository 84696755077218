import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _omitBy from "lodash/omitBy";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import UsersThatMeetConditions from "../UsersThatMeetConditions";
import SegmentGroup from "../SegmentGroup";
import {
  addCustomSegmentGroup,
  calculateCustomAudienceByFields,
  removeCustomSegmentGroup
} from "../../../../../actions/audienceActions";
import { showToastError } from "../../../../../helpers/functions";

const SegmentGroups = ({ onSubmit, isEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    buttonLoading,
    customAudienceInfo: { match_count, groups }
  } = useSelector(({ audience }) => audience);
  const [calculationInProgress, setCalculationInProgress] = useState(false);

  const fieldsAreMissing = groups.some(group => !group.interests.length || !group.gender.length);

  const onAddGroupClick = () => {
    dispatch(addCustomSegmentGroup());
  };

  const onRemoveGroupClick = segmentIndex => {
    dispatch(removeCustomSegmentGroup(segmentIndex));
  };

  const onCalculateUsersThatMeetConditionsClick = () => {
    setCalculationInProgress(true);
    dispatch(
      calculateCustomAudienceByFields({
        groups: groups
          .map(group => _omitBy(group, v => !v))
          .map(group => ({
            ...group,
            interests: group.interests.map(el => {
              if (el.id) return el.id;
              return el;
            }),
            hashtags: group.hashtags.map(el => {
              if (el.id) return el.id;
              return el;
            })
          }))
      })
    ).then(res => {
      setCalculationInProgress(false);
      if (res.error) {
        Object.values(res.error.response.data.groups).forEach(el => {
          showToastError(Object.values(el)[0][0].message);
        });
      }
    });
  };

  return (
    <div className="custom-audience__segment-groups">
      <p className="custom-audience__segment-groups--description">
        {t("t:audience.this-segment-contains")}
      </p>
      {groups.map((el, key) => (
        <SegmentGroup
          key={`group_${key}`}
          groupIndex={key}
          onRemoveGroupClick={() => onRemoveGroupClick(key)}
          withDeleteButton={groups.length > 1}
          {...el}
        />
      ))}
      <div
        className="custom-audience__segment-group--add text_hover no_select"
        onClick={onAddGroupClick}
      >
        {t("t:audience.add-group")}
      </div>
      <UsersThatMeetConditions
        usersNumber={match_count}
        onCalculateClick={onCalculateUsersThatMeetConditionsClick}
        loading={calculationInProgress}
        fieldsAreMissing={fieldsAreMissing}
      />
      <TooltipMessage
        text={fieldsAreMissing ? t("t:common.enter-mandatory-fields") : ""}
        delay={200}
        position="top"
        classes="step_tooltip"
      >
        <div className="custom-audience__submit-btn">
          <DefaultButton
            variant="contained"
            classes="auth"
            type="button"
            onClick={() => onSubmit()}
            disabled={fieldsAreMissing}
            loading={buttonLoading}
          >
            {isEdit ? t("t:common.save-changes") : t("t:audience.create-segment")}
          </DefaultButton>
        </div>
      </TooltipMessage>
    </div>
  );
};

export default SegmentGroups;
