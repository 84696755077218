import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";

import { patchLanguage } from "../../../actions/profileActions";

import CheckIcon from "../../../assets/image/lng-check.svg";
import ENIcon from "../../../assets/image/en.svg";
import ETIcon from "../../../assets/image/ee.svg";
import RUIcon from "../../../assets/image/ru.svg";
import ArrowIcon from "../../../assets/image/down-arrow-black.svg";
import ArrowWhiteIcon from "../../../assets/image/down-arrow.svg";

import "./styles.scss";

const LanguageSwitcher = ({ inWhite = false }) => {
  const [isOpen, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(val => !val);

  const selectLanguage = async lng => {
    if (localStorage.getItem("token")) {
      await dispatch(patchLanguage(lng));
      window.location.reload();
    } else {
      i18n.changeLanguage(lng).then(() => handleOpen());
    }
    localStorage.setItem("i18nextLng", lng);
  };

  const renderAdornment = (lng, isSelected) => {
    if (isSelected) {
      return (
        <img
          src={inWhite ? ArrowWhiteIcon : ArrowIcon}
          alt=">"
          className={clsx("language-switcher__arrow", isOpen && "rotated_90")}
        />
      );
    }
    if (i18n.language === lng)
      return <img src={CheckIcon} alt="V" className="language-switcher__checkmark" />;
    return null;
  };

  const renderLanguage = (lng, isSelected = false) => {
    switch (lng) {
      case "en":
        return (
          <div
            className={clsx("language-switcher__option", inWhite && isSelected && "in-white")}
            onClick={isSelected ? handleOpen : () => selectLanguage("en")}
          >
            <img src={ENIcon} alt="en" className="language-switcher__flag" />
            <p className={clsx(i18n.language === lng && "in-bold")}>EN</p>
            {renderAdornment("en", isSelected)}
          </div>
        );
      case "et":
        return (
          <div
            className={clsx("language-switcher__option", inWhite && isSelected && "in-white")}
            onClick={isSelected ? handleOpen : () => selectLanguage("et")}
          >
            <img src={ETIcon} alt="et" className="language-switcher__flag" />
            <p className={clsx(i18n.language === lng && "in-bold")}>ET</p>
            {renderAdornment("et", isSelected)}
          </div>
        );
      case "ru":
        return (
          <div
            className={clsx("language-switcher__option", inWhite && isSelected && "in-white")}
            onClick={isSelected ? handleOpen : () => selectLanguage("ru")}
          >
            <img src={RUIcon} alt="ru" className="language-switcher__flag" />
            <p className={clsx(i18n.language === lng && "in-bold")}>RU</p>
            {renderAdornment("ru", isSelected)}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="language-switcher">
      {renderLanguage(i18n.language, true)}
      {isOpen && (
        <ClickAwayListener onClickAway={handleOpen}>
          <div className="language-switcher__options-popup">
            {renderLanguage("en")}
            {renderLanguage("et")}
            {renderLanguage("ru")}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default LanguageSwitcher;
