import React, { memo } from "react";

import ProductSelector from "../../ProductSelector";
import CharactersLeft from "../../../../../HelperComponents/CharactersLeft";
import TooltipMessage from "../../../../../HelperComponents/TooltipMessage/TooltipMessage";
import { renderStepPlaceholder } from "../../../../../../helpers/functions";
import Snack from "../../../../../HelperComponents/Snack/Snack";

import novelty from "../../../../../../assets/image/My_Campaign/novelty.svg";
import cancel from "../../../../../../assets/image/My_Campaign/btn-cancel.svg";
import trash from "../../../../../../assets/image/My_Campaign/btn-trash.svg";
import save from "../../../../../../assets/image/My_Campaign/btn-save.svg";
import Shape from "../../../../../../assets/image/My_Campaign/Shape.svg";
import ErrorIcon from "../../../../../../assets/image/c-warning-e.svg";

const NoveltyExpandedView = ({
  type,
  detail,
  toClosePopper,
  campaignError,
  products,
  handleChange,
  productOption,
  changeStep,
  title,
  description,
  setTitle,
  setDescription,
  isEditor,
  setProductError,
  openErrorSnack,
  onAddingNewProduct,
  deleteStep,
  productSizes,
  setProductSizes,
  t,
  campaignStatus,
  campaign: campaignID
}) => {
  let full_price = 0,
    product_image = null;

  if (products !== "loading" && productOption) {
    const product = products.find(
      el =>
        el.id ===
        (campaignStatus === "inactive" ? productOption.value : detail.product?.original_product)
    );
    full_price = product?.price || 0;
    product_image = product?.image;
  }

  return (
    <div className="block_wrapper">
      <div className="head_block">
        <div className="title_head">
          <div>
            <img src={novelty} alt="novelty" />
          </div>
          <div>
            <p>{t("t:build-campaign.novelty")}</p>
            <span>{renderStepPlaceholder(detail.title, type, t)}</span>
          </div>
        </div>
        {isEditor ? (
          <div className="btn_head">
            <button onClick={deleteStep}>
              <img src={cancel} alt="cancel" />
            </button>
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
            <button onClick={changeStep}>
              <img src={save} alt="save" />
            </button>
          </div>
        ) : (
          <div className="btn_head">
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
          </div>
        )}
      </div>
      <div className="input_title_block_my_campaign no-padding-bottom">
        <div>
          <span>{`${t("t:common.title")} *`}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-novelty-title")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <input
            disabled={!isEditor}
            type="text"
            placeholder={renderStepPlaceholder(detail.title, type, t)}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          {campaignError.title ? (
            <TooltipMessage
              text={campaignError.title[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
        <CharactersLeft title={title} maxCharacters={50} />
      </div>
      <div className="textarea_description_block_my_campaign">
        <div>
          <span>{t("t:common.description")}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-novelty-description")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <textarea
            disabled={!isEditor}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {campaignError.description ? (
            <TooltipMessage
              text={campaignError.description[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="product_rewards">
        <div className="title">
          <span>{`${t("t:build-campaign.product")} *`}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-novelty-product")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <ProductSelector
          name="productOption"
          value={productOption}
          handleChange={handleChange}
          onAddingNewProduct={newProduct => onAddingNewProduct(newProduct, "productOption")}
          fullPrice={full_price}
          productImage={product_image}
          sizes={productSizes}
          setSizes={setProductSizes}
          campaignError={campaignError}
          isStatic={campaignStatus !== "inactive"}
          campaignID={campaignID}
        />
      </div>
      <Snack
        open={openErrorSnack}
        handleSnack={() => setProductError(false)}
        message={t("t:snack-messages.no-product-error")}
        type="error"
      />
    </div>
  );
};

export default memo(NoveltyExpandedView);
