import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { formatPrice, splitByCommas } from "../../../../../helpers/functions";

const Totals = ({ cost_of_acquisition, fb_shares, link_shares }) => {
  const { t } = useTranslation();
  return (
    <div className="total_blocks_wrapper">
      <div className="total_block">
        <div className="title">{t("t:campaign-analytics.cost-of-acquisition")}</div>
        <div className="total_big">
          <div className="total_value">{formatPrice(cost_of_acquisition.total)}</div>
          <div className="total_label">{t("t:campaign-analytics.total-spent")}</div>
        </div>
        <div className="details_wrapper">
          <div className="details_block">
            <div className="details_value">{formatPrice(cost_of_acquisition.reach)}</div>
            <div className="details_label">{t("t:campaign-analytics.reach")}</div>
          </div>
          <div className="details_block">
            <div className="details_value">{formatPrice(cost_of_acquisition.acquisition)}</div>
            <div className="details_label">{t("t:campaign-analytics.acquisition")}</div>
          </div>
        </div>
      </div>
      {fb_shares && (
        <div className="total_block">
          <div className="title">{t("t:campaign-analytics.sharing-via-facebook")}</div>
          <div className="total_big">
            <div className="total_value">{splitByCommas(fb_shares.total_shares || "0")}</div>
            <div className="total_label">{t("t:campaign-analytics.total-shares")}</div>
          </div>
          <div className="details_wrapper">
            <div className="details_block">
              <div className="details_value">
                {splitByCommas(fb_shares.posts_on_users_feed || "0")}
              </div>
              <div className="details_label">{t("t:campaign-analytics.posts-on-users-feed")}</div>
            </div>
          </div>
        </div>
      )}
      {link_shares && (
        <div className="total_block">
          <div className="title">{t("t:campaign-analytics.sharing-via-link")}</div>
          <div className="total_big">
            <div className="total_value">
              {splitByCommas(link_shares.total_unique_links || "0")}
            </div>
            <div className="total_label">{t("t:campaign-analytics.total-unique-links")}</div>
          </div>
          <div className="details_wrapper">
            <div className="details_block">
              <div className="details_value">{splitByCommas(link_shares.unique_joins || "0")}</div>
              <div className="details_label">{t("t:campaign-analytics.unique-joinings")}</div>
            </div>
            <div className="details_block">
              <div className="details_value">{splitByCommas(link_shares.completions || "0")}</div>
              <div className="details_label">{t("t:campaign-analytics.completions")}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Totals);
