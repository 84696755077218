import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";

const Gender = ({ audienceError, isEditor, gender, changeCheckbox }) => {
  const { t } = useTranslation();
  return (
    <div className="audience_row_wrapper">
      <div className="row_name">
        {`${t("t:campaign-audience.gender")} *`}
        {audienceError.gender ? (
          <TooltipMessage
            text={audienceError.gender[0].message}
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
      </div>
      <div className="row_content">
        <div className="item_checkbox no_select">
          <div
            onClick={() => isEditor && changeCheckbox("male")}
            className={clsx(!isEditor && "cursor_not_allowed")}
          >
            <input
              type="checkbox"
              checked={gender.includes("male")}
              onChange={() => console.log("changed")}
            />
            <label htmlFor="radio1" className={clsx(!isEditor && "cursor_not_allowed")}>
              {t("t:campaign-audience.male")}
            </label>
          </div>
          <div
            onClick={() => isEditor && changeCheckbox("female")}
            className={clsx(!isEditor && "cursor_not_allowed")}
          >
            <input
              type="checkbox"
              checked={gender.includes("female")}
              onChange={() => console.log("changed")}
            />
            <label htmlFor="radio1" className={clsx(!isEditor && "cursor_not_allowed")}>
              {t("t:campaign-audience.female")}
            </label>
          </div>
          <div
            onClick={() => isEditor && changeCheckbox("unisex")}
            className={clsx(!isEditor && "cursor_not_allowed")}
          >
            <input
              type="checkbox"
              checked={gender.includes("unisex")}
              onChange={() => console.log("changed")}
            />
            <label htmlFor="radio1" className={clsx(!isEditor && "cursor_not_allowed")}>
              {t("t:campaign-audience.other")}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Gender);
