import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";

import DefaultButton from "../../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import ConfirmationDialog from "../../../../../HelperComponents/DialogComponent/ConfirmationDialog/ConfirmationDialog";
import TooltipMessage from "../../../../../HelperComponents/TooltipMessage/TooltipMessage";

import DeleteIcon from "../../../../../../assets/image/My_Campaign/btn-cancel.svg";
import ErrorIcon from "../../../../../../assets/image/c-warning-e.svg";

const Sizes = ({ productKey, sizes, setSizes, errors, disabled }) => {
  const [errorDialogText, setErrorDialogText] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (
      errors?.length &&
      (errors[0].code === "invalid_quantity" || errors[0].code === "unique_names")
    ) {
      setErrorDialogText(errors[0].message);
    }
  }, [errors]);

  const onAddSizeClick = () => {
    setSizes([
      ...sizes,
      {
        name: "",
        quantity: ""
      }
    ]);
  };

  const onChangeSize = (key, fieldName, fieldValue) => {
    setSizes(
      sizes.map((el, order) => {
        if (order === key) {
          return {
            ...el,
            [fieldName]: fieldValue
          };
        }
        return el;
      })
    );
  };

  const onRemoveSize = key => {
    const tempSizes = [...sizes];
    tempSizes.splice(key, 1);
    setSizes(tempSizes);
  };

  const renderSizes = useMemo(
    () =>
      sizes.map(({ name, quantity }, key) => (
        <div className="sizes__size" key={`${productKey}-size-${key}`}>
          <input
            className="sizes__input-field"
            type="text"
            placeholder={t("t:build-campaign.size")}
            value={name}
            onChange={e => onChangeSize(key, "name", e.target.value)}
            disabled={disabled}
          />
          <div className="sizes__quantity--wrapper">
            <input
              className="sizes__input-field"
              type="number"
              placeholder={t("t:build-campaign.quantity")}
              value={quantity}
              onChange={e => onChangeSize(key, "quantity", e.target.value)}
              disabled={disabled}
            />
            <span className="sizes__quantity--label">{t("t:build-campaign.items")}</span>
          </div>
          {!disabled && (
            <IconButton
              onClick={() => onRemoveSize(key)}
              classes={{
                root: "sizes__del-btn"
              }}
            >
              <img src={DeleteIcon} alt="del" />
            </IconButton>
          )}
          {!!errors && !!errors[key] && (errors[key].name || errors[key].quantity) && (
            <TooltipMessage
              text={t("t:build-campaign.sizes-validation-empty")}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          )}
        </div>
      )),
    // eslint-disable-next-line
    [JSON.stringify(sizes), productKey, JSON.stringify(errors), disabled]
  );

  return (
    <div className="sizes">
      {!!sizes.length && <div className="sizes__title">{t("t:build-campaign.sizes")}</div>}
      {renderSizes}
      <DefaultButton
        variant="contained"
        classes="add-size"
        onClick={onAddSizeClick}
        disabled={disabled}
      >
        {t("t:build-campaign.add-size")}
      </DefaultButton>
      {!!errorDialogText && (
        <ConfirmationDialog
          open
          onClose={() => setErrorDialogText("")}
          confirmAction={() => setErrorDialogText("")}
          title={t("t:common.sorry")}
          description={errorDialogText}
          confirmText={t("t:common.ok")}
        />
      )}
    </div>
  );
};

export default Sizes;
