import { CAMPAIGN, STEPS } from "../constants";
import { joinQueries, parseObjectWithQueries } from "../helpers/functions";

export function getCampaignInfo(campaign_id) {
  return {
    type: CAMPAIGN.GET_INFO,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/`,
        method: "get"
      }
    }
  };
}

export function getCampaignMobilePreview(campaign_id) {
  return {
    type: CAMPAIGN.GET_MOBILE_PREVIEW,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/mobile-preview/`,
        method: "get"
      }
    }
  };
}

export function getCampaignStepResults(campaign_id) {
  return {
    type: CAMPAIGN.GET_CAMPAIGN_STEP_RESULTS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps-results/`,
        method: "get"
      }
    }
  };
}

export function patchCampaignName(campaign_id, data) {
  return {
    type: CAMPAIGN.PATCH_NAME,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/`,
        method: "patch",
        data
      }
    }
  };
}

export function deleteCampaign(campaign_id) {
  return {
    type: CAMPAIGN.DELETE_CAMPAIGN,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/`,
        method: "delete"
      }
    }
  };
}

export function postPublishCampaign(campaign_id) {
  return {
    type: CAMPAIGN.POST_PUBLISH,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/publish/`,
        method: "post"
      }
    }
  };
}

export function postUnpublishCampaign(campaign_id) {
  return {
    type: CAMPAIGN.POST_UNPUBLISH,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/unpublish/`,
        method: "post"
      }
    }
  };
}

export function postStopCampaign(campaign_id) {
  return {
    type: CAMPAIGN.POST_STOP_CAMPAIGN,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/force-stop/`,
        method: "post"
      }
    }
  };
}

//////EDITING/VIEWING//////

export function postEditor(campaign_id) {
  return {
    type: CAMPAIGN.POST_EDITOR,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/editor/`,
        method: "post"
      }
    }
  };
}

export function deleteEditor(campaign_id) {
  return {
    type: CAMPAIGN.DELETE_EDITOR,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/editor/`,
        method: "delete"
      }
    }
  };
}

export function postViewer(campaign_id) {
  return {
    type: CAMPAIGN.POST_VIEWER,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/viewer/`,
        method: "post"
      }
    }
  };
}

export function deleteViewer(campaign_id) {
  return {
    type: CAMPAIGN.DELETE_VIEWER,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/viewer/`,
        method: "delete"
      }
    }
  };
}

export function getInterests() {
  return {
    type: CAMPAIGN.GET_INTERESTS,
    payload: {
      client: "default",
      request: {
        url: `/all-interests/`,
        method: "get"
      }
    }
  };
}

export function getAudience(campaign_id) {
  return {
    type: CAMPAIGN.GET_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/audience/`,
        method: "get"
      }
    }
  };
}

export function patchAudience(campaign_id, data) {
  return {
    type: CAMPAIGN.PATCH_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/audience/`,
        method: "patch",
        data
      }
    }
  };
}

export function getPotentialReach(campaign_id, data) {
  return {
    type: CAMPAIGN.GET_POTENTIAL_REACH,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/potential-reach/`,
        method: "post",
        data
      }
    }
  };
}

export function getDistribution(campaign_id) {
  return {
    type: CAMPAIGN.GET_DISTRIBUTION,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/distribution/`,
        method: "get"
      }
    }
  };
}

export function patchDistribution(campaign_id, data) {
  return {
    type: CAMPAIGN.PATCH_DISTRIBUTION,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/distribution/`,
        method: "patch",
        data
      }
    }
  };
}

export function getCost(campaign_id) {
  return {
    type: CAMPAIGN.GET_COST,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/cost-tab/v3/`,
        method: "get"
      }
    }
  };
}

export function getAnalytics(campaign_id, period) {
  return {
    type: CAMPAIGN.GET_ANALYTICS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/analytics/?period=${period}`,
        method: "get"
      }
    }
  };
}

export function getDeliveries(campaign_id, arr) {
  return {
    type: CAMPAIGN.GET_DELIVERIES,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/deliveries/${joinQueries(arr)}`,
        method: "get"
      }
    }
  };
}

export function getCompanyDeliveries(queries) {
  return {
    type: CAMPAIGN.GET_COMPANY_DELIVERIES,
    payload: {
      client: "default",
      request: {
        url: `/company/current/deliveries/${joinQueries(parseObjectWithQueries(queries))}`,
        method: "get"
      }
    }
  };
}

export function setCompanyDeliveriesMeta(meta) {
  return {
    type: CAMPAIGN.SET_COMPANY_DELIVERIES_META,
    meta
  };
}

export function postDeliveryDeliverToPickupStatus(campaign_id, delivery_id) {
  return {
    type: CAMPAIGN.POST_DELIVERY_DELIVER_TO_PICKUP_STATUS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/deliveries/${delivery_id}/set-deliver_to_pickup/`,
        method: "post"
      }
    }
  };
}

export function postDeliveryReadyForPickupStatus(campaign_id, delivery_id) {
  return {
    type: CAMPAIGN.POST_DELIVERY_READY_FOR_PICKUP_STATUS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/deliveries/${delivery_id}/set-ready_for_pickup/`,
        method: "post"
      }
    }
  };
}

export function postDeliveryNeedsDeliveryStatus(campaign_id, delivery_id) {
  return {
    type: CAMPAIGN.POST_DELIVERY_NEEDS_DELIVERY_STATUS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/deliveries/${delivery_id}/set-needs_delivery/`,
        method: "post"
      }
    }
  };
}

export function postDeliveryDoneStatus(campaign_id, delivery_id) {
  return {
    type: CAMPAIGN.POST_DELIVERY_DONE_STATUS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/deliveries/${delivery_id}/set-done/`,
        method: "post"
      }
    }
  };
}

export function postDeliveryOnDeliveryStatus(campaign_id, delivery_id) {
  return {
    type: CAMPAIGN.POST_DELIVERY_ON_DELIVERY_STATUS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/deliveries/${delivery_id}/set-on_delivery/`,
        method: "post"
      }
    }
  };
}

export function postDeliveryRedeemedStatus(campaign_id, delivery_id, data) {
  return {
    type: CAMPAIGN.POST_DELIVERY_REDEEMED_STATUS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/deliveries/${delivery_id}/set-redeemed/`,
        method: "post",
        data
      }
    }
  };
}

export function patchDeliveryInfo(campaign_id, delivery_id, data) {
  return {
    type: CAMPAIGN.PATCH_DELIVERY_INFO,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/deliveries/${delivery_id}/update-data/`,
        method: "patch",
        data // courier, tracking_number, eta
      }
    }
  };
}

//////STEPS///////

export function getCampaignSteps(campaign_id) {
  return {
    type: STEPS.GET_CAMPAIGN_STEPS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/`,
        method: "get"
      }
    }
  };
}

export function postCampaignSteps(campaign_id, data) {
  return {
    type: STEPS.POST_CAMPAIGN_STEPS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/`,
        method: "post",
        data
      }
    }
  };
}

export function postOneStep(campaign_id, data) {
  return {
    type: STEPS.POST_ONE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/`,
        method: "post",
        data
      }
    }
  };
}

export function deleteOneStep(campaign_id, step_id) {
  return {
    type: STEPS.DELETE_ONE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/`,
        method: "delete"
      }
    }
  };
}

export function postStepsOrder(campaign_id, data) {
  return {
    type: STEPS.POST_ORDER,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/set-order/`,
        method: "post",
        data
      }
    }
  };
}

export function patchLocationStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_LOCATION,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/location/`,
        method: "patch",
        data //title, description, address, radius, location_point : { longitude, latitude }
      }
    }
  };
}

export function patchURLStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_URL,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/url/`,
        method: "patch",
        data //title, description, url
      }
    }
  };
}

export function patchVideoStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_VIDEO,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/video/`,
        method: "patch",
        data //title, description, url
      }
    }
  };
}

export function patchCheckTextStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_CHECK_TEXT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/check_text/`,
        method: "patch",
        data //title, description, text
      }
    }
  };
}

export function patchShortTextStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_SHORT_TEXT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/short_text/`,
        method: "patch",
        data //title, description
      }
    }
  };
}

export function patchPhotoStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_PHOTO,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/photo/`,
        method: "patch",
        data //title, description
      }
    }
  };
}

export function patchRatingStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_RATING,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/rating/`,
        method: "patch",
        data //title, description
      }
    }
  };
}

export function patchYesNoStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_YES_NO,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/yes_no/`,
        method: "patch",
        data //title, description
      }
    }
  };
}

export function patchImageStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_IMAGE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/image/`,
        method: "patch",
        data //title, description, image, duration
      }
    }
  };
}

export function patchMultipleStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_MULTIPLE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/multiple/`,
        method: "patch",
        data //title, description, type, text_answers: [" ", " "]
      }
    }
  };
}

export function patchQuizStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_MULTIPLE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/quiz/`,
        method: "patch",
        data // title, description, type,
        // available_answers: [{"answer": "", "is_correct": true}, {"answer": "", "is_correct": false}]
      }
    }
  };
}

export function patchPicturesStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_PICTURES,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/pictures/`,
        method: "patch",
        data //title, description, type, answers: [" ", " "]
      }
    }
  };
}

export function postPicture(campaign_id, step_id, data) {
  return {
    type: STEPS.POST_PICTURE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/pictures/`,
        method: "post",
        data //title, description, type, images: [id, id]
      }
    }
  };
}

export function patchNewBarcodeStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_NEW_BARCODE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/new_barcode/`,
        method: "patch",
        data //title, description, number_of_codes, codes_remaining, expiration_hours
      }
    }
  };
}

export function patchNewQRStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_NEW_QR,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/new_qr/`,
        method: "patch",
        data //title, description, number_of_codes, codes_remaining, expiration_hours
      }
    }
  };
}

export function patchScanQRStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_SCAN_QR,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/scan_qr/`,
        method: "patch",
        data //title, code, image, description
      }
    }
  };
}

export function patchMonetaryStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_MONETARY,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/monetary/`,
        method: "patch",
        data //title, description, amount
      }
    }
  };
}

export function patchBonusStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_BONUS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/bonus/`,
        method: "patch",
        data //title, description, product_id, need_to_buy, free_products_given
      }
    }
  };
}

export function patchDiscountStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_DISCOUNT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/discount/`,
        method: "patch",
        data //title, description, product_id, type, value (percent/price, depending on type)
      }
    }
  };
}

export function patchNoveltyStep(campaign_id, step_id, data) {
  return {
    type: STEPS.PATCH_NOVELTY,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/steps/${step_id}/type/novelty/`,
        method: "patch",
        data //title, description, product_id
      }
    }
  };
}

export function getHashtagSuggestions(search) {
  return {
    type: CAMPAIGN.GET_HASHTAGS_SUGGESTIONS,
    payload: {
      client: "default",
      request: {
        url: `/hashtags/suggest/?s=${search}`,
        method: "get"
      }
    }
  };
}

export function postHashtag(data) {
  return {
    type: CAMPAIGN.POST_HASHTAG,
    payload: {
      client: "default",
      request: {
        url: `/hashtags/add/`,
        method: "post",
        data
      }
    }
  };
}

export function rewritePictures(data) {
  return {
    type: STEPS.REWRITE_PICTURES,
    payload: data
  };
}

export function deletePicture(key) {
  return {
    type: STEPS.DELETE_PICTURE,
    payload: key
  };
}

export function blockOtherActions(data) {
  return {
    type: CAMPAIGN.BLOCK_OTHER_ACTIONS,
    payload: data
  };
}

export function setCampaignError(data) {
  return {
    type: CAMPAIGN.SET_ERROR,
    error: data
  };
}

export function setDistributionError(data) {
  return {
    type: CAMPAIGN.SET_DISTRIBUTION_ERROR,
    error: data
  };
}

export function setAudienceError(data) {
  return {
    type: CAMPAIGN.SET_AUDIENCE_ERROR,
    error: data
  };
}

export function resetCampaignErrors() {
  return {
    type: CAMPAIGN.RESET_ERRORS
  };
}

export function setAccessError(data) {
  return {
    type: CAMPAIGN.SET_ACCESS_ERROR,
    payload: data
  };
}

export function setEditorRulesAreChanging() {
  return {
    type: CAMPAIGN.SET_EDITOR_RULES_ARE_CHANGING
  };
}

export function resetPublishValidationError(errorToDelete) {
  return {
    //pass the name of the field that should be deleted
    //like if I submit Distribution info than there is
    //no distribution error on publish anymore
    type: CAMPAIGN.FILTER_PUBLISH_VALIDATION_ERROR,
    errorToDelete
  };
}

export function resetCampaign() {
  return {
    type: CAMPAIGN.RESET_CAMPAIGN
  };
}

export function setCurrentStepInfo(currentStepInfo) {
  return {
    type: CAMPAIGN.SET_CURRENT_STEP_INFO,
    currentStepInfo
  };
}
