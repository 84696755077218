import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import DraggableStep from "../DraggableStep";
import { getChallenges, getResponses, getRewards } from "../helpers";

import todo from "../../../../../assets/image/todo.svg";
import comment from "../../../../../assets/image/comment-add.svg";
import gold_coin from "../../../../../assets/image/CampaignPanel/gold_coin.svg";

import "./styles.scss";

const Steps = ({ addStep }) => {
  const { t } = useTranslation();

  const { renderChallenges, renderResponses, renderRewards } = useMemo(
    () => ({
      renderChallenges: getChallenges(t).map(el => (
        <DraggableStep {...el} addStep={addStep} key={`draggable-${el.name}`} />
      )),
      renderResponses: getResponses(t).map(el => (
        <DraggableStep {...el} addStep={addStep} key={`draggable-${el.name}`} />
      )),
      renderRewards: getRewards(t).map(el => (
        <DraggableStep {...el} addStep={addStep} key={`draggable-${el.name}`} />
      ))
    }),
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div className="challenges_block my_campaign_block">
        <span>
          <img src={todo} alt="todo" />
          {t("t:build-campaign.challenges")}
        </span>
        <div>{renderChallenges}</div>
      </div>
      <div className="responses_block my_campaign_block">
        <span>
          <img src={comment} alt="comment" />
          {t("t:build-campaign.responses")}
        </span>
        <div>{renderResponses}</div>
      </div>
      <div className="rewards_block my_campaign_block">
        <span>
          <img src={gold_coin} alt="gold_coin" />
          {t("t:build-campaign.rewards")}
        </span>
        <div>{renderRewards}</div>
      </div>
    </>
  );
};

export default Steps;
