import React, { Component } from "react";
import AppleLogin from "react-apple-login";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { postAppleAuth } from "../../../../actions/authActions";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";

import { AppleRedirectUrl, AppleConnectRedirectUrl, AppleClientId } from "../../../../config";

class AppleAuth extends Component {
  // 92H3A6ST9H.ee.campy.iosapplication.sandbox
  // ee.campy.iosapplication.sandbox.signinwithapple
  render() {
    const { variant, label, classes, history } = this.props;
    const fromProfile = history.location.pathname.includes("profile");
    return (
      <AppleLogin
        clientId={AppleClientId}
        redirectURI={fromProfile ? AppleConnectRedirectUrl : AppleRedirectUrl}
        // responseType={"code"}
        responseType={"code id_token"}
        responseMode={"form_post"}
        scope={"email"}
        render={renderProps => (
          <DefaultButton
            variant={variant || "contained"}
            classes={classes || "apple"}
            onClick={renderProps.onClick}
          >
            {label || "Apple"}
          </DefaultButton>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  postAppleAuth
};

export default connect(null, mapDispatchToProps)(withRouter(AppleAuth));
