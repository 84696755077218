import React, { Component } from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { withTranslation } from "react-i18next";

import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import Snack from "../HelperComponents/Snack/Snack";
import { getOption, renderPageLoader } from "../../helpers/functions";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import Tabs from "../HelperComponents/Tabs";
import ApprovedUsers from "./Approved/Approved";
import InvitedUsers from "./Invited/Invited";
import {
  getUsersList,
  getInvitedUsers,
  deleteUser,
  deleteInvitation,
  postBlockUserInCompany,
  postUnblockUserInCompany,
  postBLockUserInAllCampaigns,
  postMakeARegularUser,
  postMakeAnOwner
} from "../../actions/usersActions";

import add from "../../assets/image/i-add.svg";
import zoom from "../../assets/image/zoom.svg";

import "./Users.scss";

class Users extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search.substring(1));
    this.tabs = [
      { label: props.t("t:users.approved"), value: "approved" },
      { label: props.t("t:users.invited"), value: "invited" }
    ];
    this.state = {
      loading: true,
      searchValue: "",
      searchInvitedValue: "",
      activePage: 1,
      activeInvitedPage: 1,
      openSnack: false,
      openedControls: null,
      status: { label: props.t("t:users.all-users"), value: "" },
      statusList: [
        { label: getOption(props.t("t:users.all-users")), value: "" },
        { label: getOption(props.t("t:users.active-users")), value: "active" },
        { label: getOption(props.t("t:users.blocked-users")), value: "blocked" }
      ],
      userId: undefined,
      campaignsList: [],
      blockedCampaignsIds: [],
      initialIds: [],
      campaignsDialog: false,
      deleteDialog: false,
      blockDialog: false,
      unblockDialog: false,
      revokeInvitationDialog: false,
      makeARegularUserDialog: false,
      makeAnOwnerDialog: false,
      tab: params.get("tab")
    };
  }

  componentDidMount() {
    if (!this.props.user_info?.current_company?.is_owner)
      this.props.history.push("/main/campaigns");
    this.doApprovedRequest(1).then(() => {
      this.doInvitedRequest(1).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.status.value !== this.state.status.value ||
      prevState.searchValue !== this.state.searchValue
    ) {
      this.doApprovedRequest(1).then(() => {
        this.setState({ activePage: 1 });
      });
    }
    if (prevState.searchInvitedValue !== this.state.searchInvitedValue) {
      this.doInvitedRequest(1).then(() => {
        this.setState({ activeInvitedPage: 1 });
      });
    }

    if (prevProps.location.search !== this.props.location.search) {
      this.setState({ tab: new URLSearchParams(this.props.location.search).get("tab") });
    }
  }

  doApprovedRequest = async page => {
    const { getUsersList } = this.props;
    const { searchValue, status } = this.state;
    let data = [`page=${page}`, `page_size=3`];
    searchValue && data.push(`search=${searchValue}`);
    if (status.value) {
      data.push(`is_blocked=${status.value === "blocked"}`);
    }
    const res = await getUsersList(data);
    if (!res.payload && res.error && res.error.response.status !== 403) {
      data[0] = `page=${page - 1}`; // if last user on a page is deleted
      this.setState(({ activePage }) => ({ activePage: activePage - 1 }));
      getUsersList(data);
      window.scrollTo(0, 0);
    }
  };

  doInvitedRequest = async page => {
    const { getInvitedUsers } = this.props;
    const { searchInvitedValue } = this.state;
    let data = [`page=${page}`, `page_size=3`];
    searchInvitedValue && data.push(`search=${searchInvitedValue}`);
    const res = await getInvitedUsers(data);
    if (!res.payload && res.error && res.error.response.status !== 403) {
      data[0] = `page=${page - 1}`; // if last user on a page is deleted
      this.setState(({ activeInvitedPage }) => ({
        activeInvitedPage: activeInvitedPage - 1
      }));
      getInvitedUsers(data);
      window.scrollTo(0, 0);
    }
  };

  changeTab = tab => {
    const { history } = this.props;
    this.setState({ tab });
    history.push(`/main/users/?tab=${tab}`);
  };

  setPage = (name, page) => {
    this.setState({ [name]: page });
  };

  handleChange = name => event => {
    this.setState({ [name]: event });
  };

  handleControls = id => {
    const { openedControls } = this.state;
    if (openedControls === id) {
      this.setState({ openedControls: null });
    } else {
      this.setState({ openedControls: id });
    }
  };

  handleCampaignsIds = id => {
    const { blockedCampaignsIds } = this.state;
    if (blockedCampaignsIds.includes(id)) {
      this.setState({ blockedCampaignsIds: blockedCampaignsIds.filter(el => el !== id) });
    } else {
      this.setState({ blockedCampaignsIds: [...blockedCampaignsIds, id] });
    }
  };

  clearBlockedIds = () => this.setState({ blockedCampaignsIds: [] });

  fillBlockedIds = () => {
    const { campaignsList } = this.state;
    let blockedCampaignsIds = [];
    campaignsList.forEach(({ id }) => blockedCampaignsIds.push(id));
    this.setState({ blockedCampaignsIds });
  };

  toggleDialog = (type, userId = undefined, campaignsList = []) => {
    switch (type) {
      case "campaigns_dialog":
        let blockedCampaignsIds = [];
        campaignsList.forEach(({ id, is_blocked }) => is_blocked && blockedCampaignsIds.push(id));
        this.setState(({ campaignsDialog }) => ({
          campaignsDialog: !campaignsDialog,
          campaignsList,
          blockedCampaignsIds,
          initialIds: blockedCampaignsIds,
          userId
        }));
        break;
      case "delete_user":
        this.setState(({ deleteDialog }) => ({ deleteDialog: !deleteDialog, userId }));
        break;
      case "block_user":
        this.setState(({ blockDialog }) => ({ blockDialog: !blockDialog, userId }));
        break;
      case "unblock_user":
        this.setState(({ unblockDialog }) => ({ unblockDialog: !unblockDialog, userId }));
        break;
      case "revoke_invitation":
        this.setState(({ revokeInvitationDialog }) => ({
          revokeInvitationDialog: !revokeInvitationDialog,
          userId
        }));
        break;
      case "make_a_regular_user":
        this.setState(({ makeARegularUserDialog }) => ({
          makeARegularUserDialog: !makeARegularUserDialog,
          userId
        }));
        break;
      case "make_an_owner":
        this.setState(({ makeAnOwnerDialog }) => ({
          makeAnOwnerDialog: !makeAnOwnerDialog,
          userId
        }));
        break;
      default:
        break;
    }
  };

  removeUser = async () => {
    const { deleteUser } = this.props;
    const { activePage, userId } = this.state;
    const res = await deleteUser(userId);
    this.toggleDialog("delete_user");
    if (res.payload) {
      this.doApprovedRequest(activePage).then(() => {
        this.setState({ openSnack: true });
      });
    }
  };

  blockUser = async () => {
    const { postBlockUserInCompany } = this.props;
    const { activePage, userId } = this.state;
    await postBlockUserInCompany(userId);
    this.toggleDialog("block_user");
    this.doApprovedRequest(activePage);
  };

  unblockUser = async () => {
    const { postUnblockUserInCompany } = this.props;
    const { activePage, userId } = this.state;
    await postUnblockUserInCompany(userId);
    this.toggleDialog("unblock_user");
    this.doApprovedRequest(activePage);
  };

  blockCampaigns = async () => {
    const { postBLockUserInAllCampaigns } = this.props;
    const { blockedCampaignsIds, activePage, userId, campaignsList } = this.state;
    let data = [];
    campaignsList.forEach(({ id }) =>
      data.push({ id, is_blocked: blockedCampaignsIds.includes(id) })
    );
    await postBLockUserInAllCampaigns(userId, data);
    this.toggleDialog("campaigns_dialog");
    this.doApprovedRequest(activePage);
  };

  revokeInvitation = async () => {
    const { deleteInvitation } = this.props;
    const { userId } = this.state;
    await deleteInvitation(userId);
    this.toggleDialog("revoke_invitation");
    this.doInvitedRequest(1).then(() => this.setState({ activeInvitedPage: 1 }));
  };

  makeARegularUser = async () => {
    const { postMakeARegularUser } = this.props;
    const { activePage, userId } = this.state;
    await postMakeARegularUser(userId);
    this.toggleDialog("make_a_regular_user");
    this.doApprovedRequest(activePage);
  };

  makeAnOwner = async () => {
    const { postMakeAnOwner } = this.props;
    const { activePage, userId } = this.state;
    await postMakeAnOwner(userId);
    this.toggleDialog("make_an_owner");
    this.doApprovedRequest(activePage);
  };

  render() {
    const { usersList, invitedUsers, buttonLoading, t } = this.props;
    const {
      status,
      statusList,
      searchValue,
      searchInvitedValue,
      openedControls,
      activePage,
      activeInvitedPage,
      loading,
      openSnack,
      deleteDialog,
      blockDialog,
      unblockDialog,
      revokeInvitationDialog,
      campaignsDialog,
      makeARegularUserDialog,
      makeAnOwnerDialog,
      tab,
      campaignsList,
      blockedCampaignsIds,
      initialIds
    } = this.state;
    if (loading) return renderPageLoader();
    return (
      <div className="page_wrapper">
        <div className="users_title_block">
          <div className="title">{t("t:common.users")}</div>
          <div className="btn_wrapper">
            <DefaultButton
              variant="contained"
              classes="auth"
              type="link"
              to="/main/users/invite-user"
            >
              {t("t:users.invite-users")} <img src={add} alt="add" />
            </DefaultButton>
          </div>
        </div>
        <div className="users_block">
          <div className="users_tabs_wrapper">
            <Tabs currentTab={tab} changeTab={this.changeTab} tabs={this.tabs} />
          </div>
          {tab === "approved" ? (
            <div className="title_panel">
              <div className="search">
                <input
                  type="text"
                  placeholder={t("t:users.search-user")}
                  value={searchValue}
                  onChange={e => this.setState({ searchValue: e.target.value })}
                />
                <button>
                  <img src={zoom} alt="zoom" />
                </button>
              </div>
              <FormControl className="select_wrapper">
                <SelectComponent
                  value={status}
                  options={statusList}
                  change={this.handleChange("status")}
                  isClearable="false"
                  isSearchable={false}
                  placeholder={t("t:common.status")}
                />
              </FormControl>
            </div>
          ) : (
            <div className="title_panel">
              <div className="search">
                <input
                  type="text"
                  placeholder={t("t:users.search-user-by-email")}
                  value={searchInvitedValue}
                  onChange={e => this.setState({ searchInvitedValue: e.target.value })}
                />
                <button>
                  <img src={zoom} alt="zoom" />
                </button>
              </div>
            </div>
          )}
        </div>
        {tab === "approved" ? (
          <ApprovedUsers
            usersList={usersList}
            openedControls={openedControls}
            handleControls={this.handleControls}
            toggleDialog={this.toggleDialog}
            activePage={activePage}
            doRequest={this.doApprovedRequest}
            setPage={this.setPage}
            t={t}
          />
        ) : (
          <InvitedUsers
            usersList={invitedUsers}
            toggleDialog={this.toggleDialog}
            activePage={activeInvitedPage}
            doRequest={this.doInvitedRequest}
            setPage={this.setPage}
            t={t}
          />
        )}
        <Snack
          open={openSnack}
          handleSnack={() => this.setState({ openSnack: false })}
          message={t("t:users.user-deleted")}
          type="success"
        />
        <DialogComponent
          open={deleteDialog}
          onClose={() => this.toggleDialog("delete_user")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:users.remove-user")}</div>
            <div className="dialog_description">{t("t:users.remove-user-confirmation")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.removeUser}
                loading={buttonLoading}
              >
                {t("t:common.remove")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("delete_user")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={blockDialog}
          onClose={() => this.toggleDialog("block_user")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:users.block-user")}</div>
            <div className="dialog_description">{t("t:users.block-user-confirmation")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.blockUser}
                loading={buttonLoading}
              >
                {t("t:users.block")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("block_user")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={unblockDialog}
          onClose={() => this.toggleDialog("unblock_user")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:users.unblock-user")}</div>
            <div className="dialog_description">{t("t:users.unblock-user-confirmation")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.unblockUser}
                loading={buttonLoading}
              >
                {t("t:users.unblock")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("unblock_user")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={revokeInvitationDialog}
          onClose={() => this.toggleDialog("revoke_invitation")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:users.revoke-invitation")}</div>
            <div className="dialog_description">{t("t:users.revoke-invitation-confirmation")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.revokeInvitation}
                loading={buttonLoading}
              >
                {t("t:users.revoke")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("revoke_invitation")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={makeARegularUserDialog}
          onClose={() => this.toggleDialog("make_a_regular_user")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:users.make-a-regular-user")}</div>
            <div className="dialog_description">
              {t("t:users.make-a-regular-user-confirmation")}
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.makeARegularUser}
                loading={buttonLoading}
              >
                {t("t:common.confirm")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("make_a_regular_user")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={makeAnOwnerDialog}
          onClose={() => this.toggleDialog("make_an_owner")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:users.make-an-owner")}</div>
            <div className="dialog_description">{t("t:users.make-an-owner-confirmation")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.makeAnOwner}
                loading={buttonLoading}
              >
                {t("t:common.confirm")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("make_an_owner")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={campaignsDialog}
          onClose={() => this.toggleDialog("campaigns_dialog")}
          closeIcon
        >
          <div className="campaign_users_dialog">
            <div className="dialog_title">{t("t:users.user-campaigns")}</div>
            <div className="controls_wrapper">
              <div className="text_hover" onClick={this.clearBlockedIds}>
                {t("t:users.unblock-all-campaigns")}
              </div>
              <div className="text_hover" onClick={this.fillBlockedIds}>
                {t("t:users.block-all-campaigns")}
              </div>
            </div>
            <div className="users_list">
              {campaignsList.map(({ id, name }) => (
                <div className="user_row" key={`campaign_of_user_${id}`}>
                  <div className="user">{name}</div>
                  <div className="btn_wrapper">
                    {blockedCampaignsIds.includes(id) ? (
                      <DefaultButton
                        variant="outlined"
                        classes="switch_btn"
                        onClick={() => this.handleCampaignsIds(id)}
                      >
                        {t("t:users.unblock")}
                      </DefaultButton>
                    ) : (
                      <DefaultButton
                        variant="outlined"
                        classes="leave_btn"
                        onClick={() => this.handleCampaignsIds(id)}
                      >
                        {t("t:users.block")}
                      </DefaultButton>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {JSON.stringify(initialIds) !== JSON.stringify(blockedCampaignsIds) && (
              <div className="buttons_wrapper">
                <DefaultButton
                  variant="contained"
                  classes="purple_btn"
                  onClick={this.blockCampaigns}
                  loading={buttonLoading}
                >
                  {t("t:common.confirm")}
                </DefaultButton>
                <DefaultButton
                  variant="contained"
                  classes="cancel_btn"
                  onClick={() => this.toggleDialog("campaigns_dialog")}
                >
                  {t("t:common.cancel")}
                </DefaultButton>
              </div>
            )}
          </div>
        </DialogComponent>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, users, dashboard }) => {
  return {
    user_info: auth.user_info,
    usersList: users.usersList,
    invitedUsers: users.invitedUsers,
    buttonLoading: dashboard.loadingComponent
  };
};

const mapDispatchToProps = {
  getUsersList,
  getInvitedUsers,
  deleteUser,
  deleteInvitation,
  postBlockUserInCompany,
  postUnblockUserInCompany,
  postBLockUserInAllCampaigns,
  postMakeARegularUser,
  postMakeAnOwner
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users));
