import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Login from "../../components/Auth/Login/Login";
import SignUp from "../../components/Auth/SignUp/SignUp";
import ForgotPassword from "../../components/Auth/ForgotPassword/ForgotPassword";
import PasswordRecovery from "../../components/Auth/PasswordRecovery/PasswordRecovery";
import AppleRedirectUrl from "../../components/Auth/AppleRedirectUrl/AppleRedirectUrl";
import ConfirmYourEmail from "../../components/Auth/ConfirmYourEmail/ConfirmYourEmail";

import AppIcon from "../../assets/image/app_icon.svg";

import "./AuthContainer.scss";

const AuthContainer = props => {
  const { match, t } = props;
  if (!!localStorage.token) return <Redirect to="/main" />;
  return (
    <Fragment>
      <main className="auth_container">
        <div className="auth-box">
          <div className="auth_bg">
            <div className="auth_bg_text_wrapper">
              <div>{t("t:auth.get-into-the-game")}</div>
              <div>{t("t:auth.campy-auth-description")}</div>
              <div>
                <img src={AppIcon} alt="campy" />
                <div>{t("t:auth.get-campy-app")}</div>
              </div>
            </div>
          </div>
          <div className="auth_content">
            <Switch>
              <Route path={match.url} exact render={() => <Redirect to="/auth/login" />} />
              <Route path={`${match.url}/login`} exact component={Login} />
              <Route path={`${match.url}/sign-up`} exact component={SignUp} />
              <Route path={`${match.url}/confirm-you-email`} exact component={ConfirmYourEmail} />
              <Route path={`${match.url}/forgot-password`} exact component={ForgotPassword} />
              <Route path={`${match.url}/reset-password`} component={PasswordRecovery} />
              <Route path={`${match.url}/apple`} component={AppleRedirectUrl} />
              <Route path={`${match.url}/fail`} render={() => <Redirect to="/auth/login" />} />
              <Route render={() => <p>Not found</p>} />
            </Switch>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default withTranslation()(AuthContainer);
