import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

import "./SkeletonComponent.scss";

const SkeletonComponent = ({ loading, width, height, children, variant }) => {
  if (loading) {
    return <Skeleton variant={variant} width={width} height={height} />;
  } else {
    return children;
  }
};

export default SkeletonComponent;
