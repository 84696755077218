import React, { memo } from "react";
import { useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import moment from "moment";

import SelectComponent from "../../../../HelperComponents/SelectComponent/SelectComponent";

import { periodList } from "../../constants";

import UsersIcon from "../../../../../assets/image/users_icon.svg";
import CalendarIcon from "../../../../../assets/image/calendar-gray.svg";

const AnalyticsHeader = ({ periodValue, onPeriodChange }) => {
  const { t } = useTranslation();
  const { joined_participants, max_participants } = useSelector(
    ({ campaign }) => campaign.analytics
  );

  return (
    <div className="analytics-header">
      <div className="analytics-header__title">{t("t:campaign-analytics.campaign-analytics")}</div>
      <div className="analytics-header__controls">
        <div className="analytics-header__users">
          <img src={UsersIcon} alt="users" className="analytics-header__users--icon" />
          <p className="analytics-header__users--participants">{joined_participants || 0}</p>
          <p>/{max_participants || 0}</p>
        </div>
        <hr className="analytics-header__separator" />
        <div className="analytics-header__period">
          <img src={CalendarIcon} alt="users" className="analytics-header__period--icon" />
          <p>{`${moment()
            .subtract(1, periodValue.value)
            .format("DD.MM.YY")} - ${moment().format("DD.MM.YY")}`}</p>
        </div>
        <FormControl>
          <SelectComponent
            value={periodValue}
            options={periodList(t)}
            change={onPeriodChange}
            isClearable="false"
            isSearchable={false}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default memo(AnalyticsHeader);
