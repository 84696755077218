import React from "react";

import { renderMobileImage } from "../../helpers";

import QRIcon from "../../../../../../assets/image/mobile-scan-qr.svg";

const ScanQR = ({ title, description, image }) => (
  <>
    <h2>{title}</h2>
    <p>{description}</p>
    <div className="image-wrapper">
      {renderMobileImage(image)}
      <img src={QRIcon} alt="qr" className="image-wrapper__icon" />
    </div>
  </>
);

export default ScanQR;
