import React from "react";

import CompanyForm from "../CompanyForm";

const CreateCompany = () => (
  <div className="add_company_wrapper">
    <CompanyForm
      mode="create"
      defaultValues={{
        name: "",
        legal_name: "",
        description: "",
        image: null,
        address: "",
        registry_code: "",
        country: "Estonia",
        contact_person_full_name: "",
        contact_person_phone_number: "",
        contact_email: "",
        addresses: [],
        delivery_companies: [],
        product_return: "",
        no_product_return: false
      }}
    />
  </div>
);

export default CreateCompany;
