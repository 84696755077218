import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import CreatableSelect from "react-select/creatable";

import { getCompanyAddresses } from "../../../actions/companiesActions";
import { postDeliveryRedeemedStatus } from "../../../actions/campaignActions";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";

import "../../Campaign/Deliveries/styles.scss";

class RedemptionInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redemption_address: null,
      name_of_the_employee: props.name_of_the_employee || "",
      submitting: false
    };
  }

  componentDidMount() {
    const { companyAddresses, getCompanyAddresses, redemption_address } = this.props;
    if (!companyAddresses.length)
      getCompanyAddresses().then(res => {
        if (redemption_address) {
          const preselected = companyAddresses.find(a => a.address === redemption_address) || null;
          this.setState({ redemption_address: preselected });
        }
      });
  }

  onSelectorValueChange = e => this.setState({ redemption_address: e.value });
  onSelectorInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "set-value") {
      this.setState({ redemption_address: inputValue });
    }
  };

  saveRedemptionInfo = async () => {
    const { toggleDialog, campaignId, id, postDeliveryRedeemedStatus } = this.props;
    const { redemption_address, name_of_the_employee } = this.state;
    this.setState({ submitting: true });
    const res = await postDeliveryRedeemedStatus(campaignId, id, {
      redemption_address,
      name_of_the_employee
    });
    if (res.payload) toggleDialog();
    this.setState({ submitting: false });
  };

  render() {
    const { toggleDialog, t, companyAddresses } = this.props;
    const { redemption_address, name_of_the_employee, submitting } = this.state;
    const selectOptions = companyAddresses.map(({ address }) => ({
      label: address,
      value: address
    }));
    return (
      <div className="tracking_info">
        <div className="dialog_title">{t("t:deliveries.add-info")}</div>
        <div className="input_title_block_my_campaign">
          <div>
            <span>{`${t("t:deliveries.redemption-address")} *`}</span>
          </div>
          <FormControl className="deliveries__select max-width">
            <CreatableSelect
              className="select_component"
              classNamePrefix="select"
              options={selectOptions}
              onChange={this.onSelectorValueChange}
              onInputChange={this.onSelectorInputChange}
              placeholder={t("t:deliveries.select-or-insert-address")}
            />
          </FormControl>
        </div>
        <div className="input_title_block_my_campaign">
          <div>
            <span>{`${t("t:deliveries.name-of-the-employee")} *`}</span>
          </div>
          <input
            type="text"
            value={name_of_the_employee}
            onChange={e => this.setState({ name_of_the_employee: e.target.value })}
          />
        </div>
        <div className="buttons_wrapper">
          <DefaultButton
            variant="contained"
            classes="auth"
            onClick={this.saveRedemptionInfo}
            loading={submitting}
            disabled={!redemption_address || !name_of_the_employee || submitting}
          >
            {t("t:common.save")}
          </DefaultButton>
          <DefaultButton variant="contained" classes="cancel_btn" onClick={toggleDialog}>
            {t("t:common.cancel")}
          </DefaultButton>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ companies }) {
  return {
    companyAddresses: companies.companyAddresses
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postDeliveryRedeemedStatus,
      getCompanyAddresses
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RedemptionInfoForm));
