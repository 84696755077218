import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import moment from "moment";

import { splitByCommas } from "../../../../../helpers/functions";

const AnalyticsChart = ({ total_send, total_viewed, total_accepted, chartData }) => {
  const { t } = useTranslation();

  const getSeriesData = () => [
    {
      showInLegend: true,
      name: t("t:campaign-analytics.viewed"),
      data: chartData.viewedData,
      marker: {
        symbol: "circle"
      }
    },
    {
      showInLegend: true,
      name: t("t:campaign-analytics.accepted"),
      data: chartData.startedData,
      color: "#4ABFB4",
      marker: {
        symbol: "circle"
      }
    },
    {
      showInLegend: true,
      name: t("t:campaign-analytics.completed"),
      data: chartData.completedData,
      marker: {
        symbol: "circle"
      }
    },
    {
      showInLegend: true,
      name: t("t:campaign-analytics.cancelled"),
      data: chartData.cancelledData,
      marker: {
        symbol: "circle"
      }
    }
  ];

  const [chartOptions, setChartOptions] = useState({
    title: {
      text: null
    },
    navigator: {
      enabled: true,
      maskFill: "rgba(100, 41, 179, 0.1)"
    },
    tooltip: {
      borderWidth: 0,
      borderRadius: 4,
      backgroundColor: "#FFFFFF",
      shared: true,
      style: {
        fontFamily: "InterRegular,sans-serif",
        color: "#020018"
      }
    },
    series: getSeriesData(),
    credits: {
      enabled: false
    },
    chart: {
      type: "spline",
      height: 350,
      backgroundColor: "transparent"
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: true,
          enabledThreshold: 1
        },
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 4
          }
        }
      }
    },
    xAxis: {
      type: "datetime",
      gridLineWidth: 1,
      gridLineColor: "rgba(2, 0, 24, 0.05)",
      tickColor: "rgba(127, 127, 139, 0.5)",
      lineColor: "rgba(127, 127, 139, 0.5)",
      lineWidth: 2,
      tickWidth: 2,
      tickLength: 6,
      minPadding: 0,
      maxPadding: 0,
      offset: 0,
      crosshair: {
        width: 2,
        color: "#d8d8d8",
        dashStyle: "dash"
      },
      labels: {
        style: {
          fontFamily: "InterMedium,sans-serif",
          color: "rgba(2, 0, 24, 0.5)",
          fontSize: "10px"
        },
        formatter: function() {
          return moment(this.value).format("D MMM YYYY");
        }
      }
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: "rgba(2, 0, 24, 0.05)",
      tickColor: "rgba(127, 127, 139, 0.5)",
      lineColor: "rgba(127, 127, 139, 0.5)",
      lineWidth: 2,
      tickWidth: 2,
      tickLength: 6,
      title: {
        text: t("t:campaign-analytics.app-users"),
        style: {
          fontFamily: "InterMedium,sans-serif",
          color: "#383838",
          fontSize: "12px"
        }
      },
      labels: {
        style: {
          fontFamily: "InterMedium,sans-serif",
          color: "rgba(2, 0, 24, 0.5)",
          fontSize: "10px"
        }
      }
    },
    legend: {
      itemStyle: {
        color: "#020018",
        fontFamily: "InterMedium,sans-serif",
        fontSize: "12px",
        fontWeight: "normal"
      },
      itemMarginBottom: 20,
      itemHoverStyle: {
        color: "rgba(2, 0, 24, 0.5)"
      },
      symbolHeight: 10,
      symbolWidth: 32,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle"
    }
  });

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      series: getSeriesData()
    });
    // eslint-disable-next-line
  }, [JSON.stringify(chartData)]);

  return (
    <div className="analytics">
      <div className="title">{t("t:campaign-analytics.campaign-reach")}</div>
      <div className="reach_wrapper">
        <div className="reach_item">
          <div className="name">{t("t:campaign-analytics.total-sent-to")}</div>
          <div className="value">{splitByCommas(total_send || "0")}</div>
        </div>
        <div className="reach_item">
          <div className="name">{t("t:campaign-analytics.viewed")}</div>
          <div className="value">{splitByCommas(total_viewed || "0")}</div>
        </div>
        <div className="reach_item">
          <div className="name">{t("t:campaign-analytics.accepted")}</div>
          <div className="value">{splitByCommas(total_accepted || "0")}</div>
        </div>
      </div>
      <div className="chart_wrapper">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  );
};

export default memo(AnalyticsChart);
