import React, { Component } from "react";
import GooglePlacesSuggest from "react-google-places-suggest";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import TooltipMessage from "../TooltipMessage/TooltipMessage";

import ErrorIcon from "../../../assets/image/c-warning-e.svg";

import "./GoogleSuggest.scss";

class GoogleSuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      value: props.address
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.shouldAddressUpdate) {
      if (prevProps.address !== this.props.address) {
        this.setState({ value: this.props.address });
      }
    }
  }

  handleInputChange = e => {
    const { resetAddress } = this.props;
    this.setState({
      search: e.target.value,
      value: e.target.value
    });
    if (resetAddress && !e.target.value) resetAddress();
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    const { handleLocation } = this.props;
    this.setState({ search: "", value: geocodedPrediction.formatted_address });
    handleLocation(geocodedPrediction, originalPrediction);
  };

  handleStatusUpdate = () => {};

  render() {
    const { search, value } = this.state;
    const { campaignError, googleMaps, disabled, placeholder, t, inputClasses = "" } = this.props;

    return (
      <GooglePlacesSuggest
        googleMaps={googleMaps}
        autocompletionRequest={{
          input: search
        }}
        onNoResult={this.handleNoResult}
        onSelectSuggest={this.handleSelectSuggest}
        onStatusUpdate={this.handleStatusUpdate}
        textNoResults={t("t:common.no-results")}
        customRender={prediction => (
          <div className="customWrapper">
            {prediction ? prediction.description : t("t:common.no-results")}
          </div>
        )}
      >
        <div className={clsx("step_input_wrapper", inputClasses)}>
          <input
            disabled={disabled}
            type="text"
            tabIndex={1}
            value={value}
            onChange={this.handleInputChange}
            className={clsx(disabled && "cursor_not_allowed")}
            placeholder={placeholder}
          />
          {campaignError.address ? (
            <TooltipMessage
              text={campaignError.address[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
      </GooglePlacesSuggest>
    );
  }
}

export default withTranslation()(GoogleSuggest);
