import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  deleteOneStep,
  patchLocationStep,
  resetCampaignErrors,
  getCampaignSteps,
  blockOtherActions,
  setCurrentStepInfo
} from "../../../../../actions/campaignActions";
import { setLoading } from "../../../../../actions/dashboardActions";

import { renderStepPlaceholder, validateImage } from "../../../../../helpers/functions";
import Snack from "../../../../HelperComponents/Snack/Snack";
import LocationExpandedView from "./LocationExpandedView";

import locations from "../../../../../assets/image/My_Campaign/locations.svg";
import deleteIcon from "../../../../../assets/image/My_Campaign/btn-cancel.svg";
import edit from "../../../../../assets/image/btn-edit.svg";
import dragHandle from "../../../../../assets/image/drag-handle.svg";
import eye from "../../../../../assets/image/My_Campaign/eye.svg";

import "./styles.scss";

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: props.match.params.id,
      popperOpened: false,
      title: props.detail.title,
      description: props.detail.description,
      address: props.detail.address,
      radius: props.detail.radius ? props.detail.radius * 1000 : "",
      image: props.detail.image,
      imagePreview: null,
      imageToCrop: null,
      blobToDownload: null,
      previewDialogOpen: false,
      openErrorSnack: false,
      openSuccessSnack: false,
      openSnack: false,
      openRadiusSnack: false,
      openNoImageSnack: false,
      coordinates: props.detail.location_point
        ? {
            lat: props.detail.location_point.latitude,
            lng: props.detail.location_point.longitude
          }
        : { lat: 59.43, lng: 24.75 }
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.popperOpened !== this.state.popperOpened) {
      this.props.handleEdit(this.props.id);
      this.props.blockOtherActions(this.state.popperOpened);
      this.props.isEditor && this.props.handleEditingTimer();
      if (this.state.popperOpened) {
        this.setInfoForMobilePreview();
      } else {
        this.props.setCurrentStepInfo({
          step: "not-selected"
        });
      }
    }

    if (this.infoForPreviewWasChanged(this.state, prevState)) {
      this.setInfoForMobilePreview();
    }

    if (this.props.campaignError) {
      if (prevState !== this.state) {
        this.props.resetCampaignErrors();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCampaignErrors();
  }

  infoForPreviewWasChanged = (currState, prevState) => {
    return (
      currState.title !== prevState.title ||
      currState.description !== prevState.description ||
      currState.image !== prevState.image ||
      currState.imagePreview !== prevState.imagePreview
    );
  };

  setInfoForMobilePreview = () => {
    const { title, description, image, imagePreview } = this.state;

    this.props.setCurrentStepInfo({
      step: "location",
      location: {
        title,
        description,
        image: imagePreview || image
      }
    });
  };

  openPopper = () => this.setState({ popperOpened: true });

  closePopper = () => this.setState({ popperOpened: false });

  deleteStep = () => {
    const { deleteOneStep, id, campaign, handleEditingTimer } = this.props;
    deleteOneStep(campaign, id);
    handleEditingTimer();
    this.closePopper();
  };

  changeStep = async () => {
    const { patchLocationStep, id, getCampaignSteps, setLoading, handleEditingTimer } = this.props;
    const {
      title,
      description,
      address,
      radius,
      coordinates,
      campaignId,
      image,
      imagePreview,
      blobToDownload
    } = this.state;
    if (!address) {
      this.setState({ openSnack: true });
    } else if (!radius) {
      this.setState({ openRadiusSnack: true });
    } else if (!imagePreview && !image) {
      this.setState({ openNoImageSnack: true });
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("address", address);
      formData.append("radius", (radius / 1000).toString());
      formData.append(
        "location_point",
        JSON.stringify({
          longitude: coordinates.lng,
          latitude: coordinates.lat
        })
      );
      if (!!imagePreview) {
        formData.append("image", blobToDownload, "location.png");
      } else if (!image) {
        formData.append("image", "");
      }
      const res = await patchLocationStep(campaignId, id, formData);
      handleEditingTimer();
      if (res.payload) {
        getCampaignSteps(campaignId).then(() => setLoading(false));
        this.setState({ openSuccessSnack: true });
        this.closePopper();
      } else {
        setLoading(false);
        if (res.error.response.data.location_point) {
          this.setState({ openSnack: true });
        } else if (res.error.response.data.radius) {
          this.setState({ openRadiusSnack: true });
        }
      }
    }
  };

  handleFiles = files => {
    if (validateImage(files[0])) {
      this.handleImage(files[0]);
    } else {
      this.setState({ openErrorSnack: true });
    }
  };

  handleImage = image => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => this.setState({ imageToCrop: [reader.result] });
  };

  onFinishCroppingImage = (canvas, imageMimeType) => {
    this.setState({ imageToCrop: null });
    canvas.toBlob(blob => {
      this.setState({
        imagePreview: canvas.toDataURL(imageMimeType),
        image: canvas.toDataURL(imageMimeType),
        blobToDownload: blob
      });
    }, imageMimeType);
  };

  onCancelCropping = () => this.setState({ imageToCrop: null });

  handleLocation = geocodedLocation => {
    this.setState({
      coordinates: {
        lat: geocodedLocation.geometry.location.lat(),
        lng: geocodedLocation.geometry.location.lng()
      },
      address: geocodedLocation.formatted_address
    });
  };

  onTitleChange = textInput => {
    const { title } = this.state;
    if (textInput.length < 51 || textInput.length < title) {
      this.setState({ title: textInput });
    } else {
      this.setState({ title: textInput.slice(0, 50) });
    }
  };

  render() {
    const { type, detail, blockActions, isEditor, t, campaignError } = this.props;
    const {
      popperOpened,
      title,
      description,
      openSnack,
      openRadiusSnack,
      coordinates,
      address,
      radius,
      image,
      imagePreview,
      previewDialogOpen,
      openErrorSnack,
      openSuccessSnack,
      openNoImageSnack,
      imageToCrop
    } = this.state;
    return (
      <div className="wrapper_block_my_campaign step_wrapper image_wrapper">
        <div className="head_block head_block_step">
          <div className="title_head">
            <div className="not_expanded">
              <img src={dragHandle} alt="draggable" />
              <img src={locations} alt="locations" />
            </div>
            <div>
              <p>{t("t:build-campaign.location")}</p>
              <span>{renderStepPlaceholder(detail.title, type, t)}</span>
            </div>
          </div>
          {isEditor ? (
            <div className="btn_head">
              <button onClick={this.deleteStep} disabled={blockActions}>
                <img src={deleteIcon} alt="trash" />
              </button>
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={edit} alt="save" />
              </button>
            </div>
          ) : (
            <div className="btn_head">
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={eye} alt="open" />
              </button>
            </div>
          )}
        </div>
        {popperOpened && (
          <div className="challenges_wrapper wrapper_block_my_campaign location_wrapper">
            <LocationExpandedView
              toClosePopper={this.closePopper}
              type={type}
              detail={detail}
              title={title}
              description={description}
              openSnack={openSnack}
              openRadiusSnack={openRadiusSnack}
              coordinates={coordinates}
              address={address}
              radius={radius}
              image={image}
              imagePreview={imagePreview}
              previewDialogOpen={previewDialogOpen}
              openErrorSnack={openErrorSnack}
              openNoImageSnack={openNoImageSnack}
              imageToCrop={imageToCrop}
              isEditor={isEditor}
              deleteStep={this.deleteStep}
              changeStep={this.changeStep}
              onTitleChange={this.onTitleChange}
              campaignError={campaignError}
              changeState={fields => this.setState(fields)}
              handleLocation={this.handleLocation}
              handleFiles={this.handleFiles}
              onFinishCroppingImage={this.onFinishCroppingImage}
              onCancelCropping={this.onCancelCropping}
            />
          </div>
        )}
        <Snack
          open={openSuccessSnack}
          handleSnack={() => this.setState({ openSuccessSnack: false })}
          message={t("t:snack-messages.successfully-changed")}
          type="success"
        />
      </div>
    );
  }
}

function mapStateToProps({ campaign }) {
  return {
    campaignError: campaign.campaignError,
    blockActions: campaign.blockActions,
    isEditor: campaign.campaignViewers.is_editor
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteOneStep,
      patchLocationStep,
      getCampaignSteps,
      resetCampaignErrors,
      blockOtherActions,
      setLoading,
      setCurrentStepInfo
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Location)));
