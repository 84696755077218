import React from "react";

import CharactersLeft from "../../../../../HelperComponents/CharactersLeft";
import ProductSelector from "../../ProductSelector";
import Snack from "../../../../../HelperComponents/Snack/Snack";
import TooltipMessage from "../../../../../HelperComponents/TooltipMessage/TooltipMessage";
import { isIntNumberRegexp, renderStepPlaceholder } from "../../../../../../helpers/functions";

import present from "../../../../../../assets/image/My_Campaign/present.svg";
import cancel from "../../../../../../assets/image/My_Campaign/btn-cancel.svg";
import trash from "../../../../../../assets/image/My_Campaign/btn-trash.svg";
import save from "../../../../../../assets/image/My_Campaign/btn-save.svg";
import Shape from "../../../../../../assets/image/My_Campaign/Shape.svg";
import ErrorIcon from "../../../../../../assets/image/c-warning-e.svg";

const BonusExpandedView = ({
  type,
  detail,
  toClosePopper,
  campaignError,
  products,
  handleChange,
  productOption,
  giftOption,
  changeStep,
  title,
  description,
  need_to_buy,
  free_products_given,
  setTitle,
  setDescription,
  setNeedToBuy,
  setFreeProductsGiven,
  isEditor,
  openErrorSnack,
  setProductError,
  onAddingNewProduct,
  deleteStep,
  productSizes,
  setProductSizes,
  giftSizes,
  setGiftSizes,
  t,
  campaignStatus,
  campaign: campaignID
}) => {
  let discount = 0,
    discount_value = 0,
    full_price = 0,
    gift_price = 0,
    product_image = null,
    gift_image = null;

  if (products !== "loading") {
    if (productOption) {
      const product = products.find(
        el =>
          el.id ===
          (campaignStatus === "inactive" ? productOption.value : detail.product?.original_product)
      );
      full_price = product?.price || 0;
      product_image = product?.image;
    }
    if (giftOption) {
      const gift = products.find(
        el =>
          el.id ===
          (campaignStatus === "inactive" ? giftOption.value : detail.gift?.original_product)
      );
      gift_price = gift?.price || 0;
      gift_image = gift?.image;
    }
    if (productOption && giftOption) {
      if (need_to_buy && free_products_given) {
        discount = ((gift_price * free_products_given) / (full_price * need_to_buy)) * 100;
        discount_value = gift_price * free_products_given;
      }
    }
  }

  return (
    <div className="block_wrapper">
      <div className="head_block">
        <div className="title_head">
          <div>
            <img src={present} alt="present" />
          </div>
          <div>
            <p>{t("t:build-campaign.bonus")}</p>
            <span>{renderStepPlaceholder(detail.title, type, t)}</span>
          </div>
        </div>
        {isEditor ? (
          <div className="btn_head">
            <button onClick={deleteStep}>
              <img src={cancel} alt="cancel" />
            </button>
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
            <button onClick={changeStep}>
              <img src={save} alt="save" />
            </button>
          </div>
        ) : (
          <div className="btn_head">
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
          </div>
        )}
      </div>
      <div className="input_title_block_my_campaign no-padding-bottom">
        <div>
          <span>{`${t("t:common.title")} *`}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-bonus-title")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <input
            disabled={!isEditor}
            type="text"
            placeholder={renderStepPlaceholder(detail.title, type, t)}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          {campaignError.title ? (
            <TooltipMessage
              text={campaignError.title[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
        <CharactersLeft title={title} maxCharacters={50} />
      </div>
      <div className="textarea_description_block_my_campaign">
        <div>
          <span>{t("t:common.description")}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-bonus-description")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <textarea
            disabled={!isEditor}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {campaignError.description ? (
            <TooltipMessage
              text={campaignError.description[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="product_rewards">
        <div className="bonus_rewards" style={{ marginBottom: "30px" }}>
          <div className="select_container">
            <div className="title">
              <span>{`${t("t:build-campaign.product")} *`}</span>
              <TooltipMessage
                text={t("t:helper-texts.build-bonus-product")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <ProductSelector
              name="productOption"
              value={productOption}
              handleChange={handleChange}
              onAddingNewProduct={newProduct => onAddingNewProduct(newProduct, "productOption")}
              fullPrice={full_price}
              productImage={product_image}
              sizes={productSizes}
              setSizes={setProductSizes}
              campaignError={campaignError}
              isStatic={campaignStatus !== "inactive"}
              campaignID={campaignID}
            />
          </div>
          <div className="price_block_mini">
            <div className="title">
              <span>{t("t:build-campaign.need-to-buy")}</span>
              <TooltipMessage
                text={t("t:helper-texts.build-bonus-need-to-buy")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <div className="price_block_input">
              <div className="step_input_wrapper money_block">
                <input
                  disabled={!isEditor}
                  type="text"
                  value={need_to_buy}
                  onChange={e => {
                    if (
                      e.target.value === "" ||
                      isIntNumberRegexp.test(e.target.value) ||
                      e.target.value.length < (need_to_buy || "").length
                    ) {
                      setNeedToBuy(e.target.value);
                    }
                  }}
                />
                {campaignError.need_to_buy ? (
                  <TooltipMessage
                    text={campaignError.need_to_buy[0].message}
                    delay={200}
                    error
                    position="top"
                    classes=""
                  >
                    <img src={ErrorIcon} alt="error" />
                  </TooltipMessage>
                ) : (
                  ""
                )}
              </div>
              <span>{t("t:build-campaign.items")}</span>
            </div>
          </div>
        </div>
        <div className="bonus_rewards">
          <div className="select_container">
            <div className="title">
              <span>{`${t("t:build-campaign.bonus-product")} *`}</span>
              <TooltipMessage
                text={t("t:helper-texts.build-bonus-bonus-product")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <ProductSelector
              name="giftOption"
              value={giftOption}
              handleChange={handleChange}
              onAddingNewProduct={newProduct => onAddingNewProduct(newProduct, "giftOption")}
              fullPrice={gift_price}
              productImage={gift_image}
              sizes={giftSizes}
              setSizes={setGiftSizes}
              campaignError={campaignError}
              isStatic={campaignStatus !== "inactive"}
              campaignID={campaignID}
            />
          </div>
          <div className="price_block_mini">
            <div className="title">
              <span>{t("t:build-campaign.free-products-given")}</span>
              <TooltipMessage
                text={t("t:helper-texts.build-bonus-free-products-given")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <div className="price_block_input">
              <div className="step_input_wrapper money_block">
                <input
                  disabled={!isEditor}
                  type="text"
                  value={free_products_given}
                  onChange={e => {
                    if (
                      e.target.value === "" ||
                      isIntNumberRegexp.test(e.target.value) ||
                      e.target.value.length < (free_products_given || "").length
                    ) {
                      setFreeProductsGiven(e.target.value);
                    }
                  }}
                />
                {campaignError.free_products_given ? (
                  <TooltipMessage
                    text={campaignError.free_products_given[0].message}
                    delay={200}
                    error
                    position="top"
                    classes=""
                  >
                    <img src={ErrorIcon} alt="error" />
                  </TooltipMessage>
                ) : (
                  ""
                )}
              </div>
              <span>{t("t:build-campaign.items")}</span>
            </div>
          </div>
        </div>
      </div>
      <hr className="product-step-separator" />
      <div className="info_bonus">
        <div className="info">
          <p>{t("t:build-campaign.discount-percent")}</p>
          <span>{discount.toFixed(0)}%</span>
        </div>
        <div className="info">
          <p>{t("t:build-campaign.discount-value")}</p>
          <span>{discount_value.toFixed(2)} €</span>
        </div>
      </div>
      <Snack
        open={openErrorSnack}
        handleSnack={() => setProductError(false)}
        message={t("t:snack-messages.bonus-step-error")}
        type="error"
      />
    </div>
  );
};

export default BonusExpandedView;
