import React from "react";
import { useTranslation } from "react-i18next";

import SkeletonComponent from "../../../../HelperComponents/SkeletonComponent/SkeletonComponent";
import { splitByCommas, calculateFeeWithVAT } from "../../../../../helpers/functions";
import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import HelpTooltip from "../../../../HelperComponents/HelpTooltip";

const Inactive = ({
  loading,
  campaignId,
  currentCompany,
  max_participants,
  total_estimated_cost,
  total_estimated_cost_bonuses,
  total_estimated_fee,
  total_estimated_fb_sharing_cost,
  total_estimated_link_sharing_cost,
  available_balance,
  missing_balance,
  estimated_campaign_turnover,
  handleBalanceDialog
}) => {
  const { t } = useTranslation();
  return (
    <div className="cost_block">
      <div className="cost_marked_block">
        <div>
          <p>
            {t("t:cost.available-balance")}
            <HelpTooltip helperTextsTransKey="cost-unpublished-available-balance" />
          </p>
          <SkeletonComponent variant="text" loading={loading} width={100}>
            <div>{splitByCommas(available_balance || "0.00")}&nbsp;€</div>
          </SkeletonComponent>
        </div>
        <div>
          <p>
            {t("t:cost.missing-balance")}
            <HelpTooltip helperTextsTransKey="cost-unpublished-missing-balance" />
          </p>
          <SkeletonComponent variant="text" loading={loading} width={100}>
            <div className="value_with_button">
              <div>
                {splitByCommas(
                  missing_balance && !missing_balance.toString().includes("-")
                    ? missing_balance
                    : "0.00"
                )}
                &nbsp;€
              </div>
              {missing_balance > 0 && (
                <>
                  {currentCompany.is_owner ? (
                    <DefaultButton
                      variant="outlined"
                      classes="switch_btn"
                      onClick={() =>
                        handleBalanceDialog(true, "deposit", campaignId, missing_balance)
                      }
                    >
                      {t("t:balance.deposit")}
                    </DefaultButton>
                  ) : (
                    <DefaultButton
                      variant="outlined"
                      classes="switch_btn"
                      onClick={() =>
                        handleBalanceDialog(true, "request_money", campaignId, missing_balance)
                      }
                    >
                      {t("t:balance.request-money")}
                    </DefaultButton>
                  )}
                </>
              )}
            </div>
          </SkeletonComponent>
        </div>
      </div>
      <div className="cost_block_info">
        <div className="totals">
          <div className="total_estimated_cost">
            <p>
              {t("t:cost.estimated-campaign-turnover")}
              <HelpTooltip helperTextsTransKey="cost-unpublished-estimated-campaign-turnover" />
            </p>
            <SkeletonComponent variant="text" loading={loading} width={250}>
              <div>{splitByCommas(estimated_campaign_turnover || "0.00")}&nbsp;€</div>
            </SkeletonComponent>
          </div>
          <div className="total_estimated_cost">
            <p>
              {t("t:cost.estimated-campaign-cost")}
              <HelpTooltip helperTextsTransKey="cost-unpublished-estimated-campaign-cost" />
            </p>
            <SkeletonComponent variant="text" loading={loading} width={250}>
              <div>{splitByCommas(total_estimated_cost || "0.00")}&nbsp;€</div>
            </SkeletonComponent>
          </div>
        </div>
        <div className="split_info mb-32">
          <div>
            <p>
              {t("t:cost.estimated-cost-of-bonuses")}
              <HelpTooltip helperTextsTransKey="cost-unpublished-estimated-cost-of-bonuses" />
            </p>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <div>
                <span>{splitByCommas(total_estimated_cost_bonuses || "0.00")}&nbsp;€</span>
              </div>
            </SkeletonComponent>
          </div>
          <div>
            <p>
              {t("t:cost.maximum-participants")}
              <HelpTooltip helperTextsTransKey="cost-unpublished-maximum-participants" />
            </p>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <div>
                <span>{max_participants}</span>
              </div>
            </SkeletonComponent>
          </div>
          <div>
            <p>
              {t("t:cost.estimated-fee")}
              <HelpTooltip helperTextsTransKey="cost-unpublished-estimated-fee" />
            </p>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <div>
                <span>{splitByCommas(calculateFeeWithVAT(total_estimated_fee))}&nbsp;€</span>
              </div>
            </SkeletonComponent>
          </div>
        </div>
        <div className="split_info">
          {/*<div>*/}
          {/*    <p>{t("t:cost.estimated-fb-sharing-cost")}</p>*/}
          {/*    <SkeletonComponent variant="text" loading={loading} width={100}>*/}
          {/*        <div>*/}
          {/*            <span>{splitByCommas(total_estimated_fb_sharing_cost || "0.00")}&nbsp;€</span>*/}
          {/*        </div>*/}
          {/*    </SkeletonComponent>*/}
          {/*</div>*/}
          <div>
            <p>
              {t("t:cost.estimated-link-sharing-cost")}
              <HelpTooltip helperTextsTransKey="cost-unpublished-estimated-link-sharing-cost" />
            </p>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <div>
                <span>
                  {splitByCommas(total_estimated_link_sharing_cost || "0.00")}
                  &nbsp;€
                </span>
              </div>
            </SkeletonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inactive;
