import React from "react";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

import EditIcon from "../../../../assets/image/i-edit.svg";
import CloneIcon from "../../../../assets/image/clone_icon.svg";
import RestoreIcon from "../../../../assets/image/restore_icon.svg";
import ArchiveIcon from "../../../../assets/image/archive_icon.svg";
import DeleteIcon from "../../../../assets/image/bin.svg";
import StopIcon from "../../../../assets/image/stop_icon.svg";

const CampaignContextMenu = ({
  handleMenu,
  isArchived,
  isPublished,
  isEditable,
  onEditClick,
  toggleDialog,
  isOwner,
  id,
  cannotDeleteCamaign
}) => {
  const { t } = useTranslation();
  return (
    <ClickAwayListener onClickAway={handleMenu}>
      <div className="menu">
        {isEditable && (
          <button onClick={onEditClick}>
            <img src={EditIcon} alt="edit" />
            {t("t:common.edit")}
          </button>
        )}
        <button onClick={() => toggleDialog("duplicate_campaign", id)}>
          <img src={CloneIcon} alt="edit" />
          {t("t:campaigns.duplicate")}
        </button>
        {isPublished && (
          <button onClick={() => toggleDialog("stop_campaign", id)}>
            <img src={StopIcon} alt="stop" />
            {t("t:common.stop")}
          </button>
        )}
        {isOwner && (
          <>
            {isArchived ? (
              <button onClick={() => toggleDialog("restore_campaign", id)}>
                <img src={RestoreIcon} alt="delete" />
                {t("t:campaigns.restore")}
              </button>
            ) : (
              <button onClick={() => toggleDialog("archive_campaign", id)}>
                <img src={ArchiveIcon} alt="delete" />
                {t("t:campaigns.archive")}
              </button>
            )}
          </>
        )}
        {isOwner && !isPublished && (
          <button
            onClick={() =>
              toggleDialog(cannotDeleteCamaign ? "cannot_delete_campaign" : "delete_campaign", id)
            }
          >
            <img src={DeleteIcon} alt="delete" className="delete-icon" />
            {t("t:common.delete")}
          </button>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default CampaignContextMenu;
