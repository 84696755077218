import React from "react";

import TooltipMessage from "../../../../../HelperComponents/TooltipMessage/TooltipMessage";
import CharactersLeft from "../../../../../HelperComponents/CharactersLeft";
import { renderStepPlaceholder } from "../../../../../../helpers/functions";
import ProductSelector from "../../ProductSelector";

import coupon from "../../../../../../assets/image/My_Campaign/coupon.svg";
import cancel from "../../../../../../assets/image/My_Campaign/btn-cancel.svg";
import trash from "../../../../../../assets/image/My_Campaign/btn-trash.svg";
import save from "../../../../../../assets/image/My_Campaign/btn-save.svg";
import Shape from "../../../../../../assets/image/My_Campaign/Shape.svg";
import ErrorIcon from "../../../../../../assets/image/c-warning-e.svg";

const DiscountExpandedView = ({
  type,
  detail,
  toClosePopper,
  campaignError,
  products,
  handleChange,
  productOption,
  changeStep,
  title,
  description,
  radioValue,
  price,
  percent,
  setTitle,
  setDescription,
  setRadioValue,
  setPercent,
  setPrice,
  isEditor,
  onAddingNewProduct,
  deleteStep,
  productSizes,
  setProductSizes,
  t,
  campaignStatus,
  campaign: campaignID
}) => {
  let discount_value = 0,
    full_price = 0,
    product_image = null;

  if (products !== "loading" && productOption) {
    const product = products.find(
      el =>
        el.id ===
        (campaignStatus === "inactive" ? productOption.value : detail.product?.original_product)
    );
    full_price = product?.price || 0;
    product_image = product?.image;
    if (radioValue === "percent") {
      discount_value = full_price * (percent / 100);
    } else {
      discount_value = full_price - price;
    }
  }

  return (
    <div className="block_wrapper">
      <div className="head_block">
        <div className="title_head">
          <div>
            <img src={coupon} alt="coupon" />
          </div>
          <div>
            <p>{t("t:build-campaign.discount")}</p>
            <span>{renderStepPlaceholder(detail.title, type, t)}</span>
          </div>
        </div>
        {isEditor ? (
          <div className="btn_head">
            <button onClick={deleteStep}>
              <img src={cancel} alt="cancel" />
            </button>
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
            <button onClick={changeStep}>
              <img src={save} alt="save" />
            </button>
          </div>
        ) : (
          <div className="btn_head">
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
          </div>
        )}
      </div>
      <div className="input_title_block_my_campaign no-padding-bottom">
        <div>
          <span>{`${t("t:common.title")} *`}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-discount-title")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <input
            disabled={!isEditor}
            type="text"
            placeholder={renderStepPlaceholder(detail.title, type, t)}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          {campaignError.title ? (
            <TooltipMessage
              text={campaignError.title[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
        <CharactersLeft title={title} maxCharacters={50} />
      </div>
      <div className="textarea_description_block_my_campaign">
        <div>
          <span>{t("t:common.description")}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-discount-description")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <textarea
            disabled={!isEditor}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {campaignError.description ? (
            <TooltipMessage
              text={campaignError.description[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="product_rewards">
        <div className="title">
          <span>{`${t("t:build-campaign.product")} *`}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-discount-product")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <ProductSelector
          name="productOption"
          value={productOption}
          handleChange={handleChange}
          onAddingNewProduct={newProduct => onAddingNewProduct(newProduct, "productOption")}
          fullPrice={full_price}
          productImage={product_image}
          sizes={productSizes}
          setSizes={setProductSizes}
          campaignError={campaignError}
          isStatic={campaignStatus !== "inactive"}
          campaignID={campaignID}
        />
      </div>
      <hr className="product-step-separator" />
      <div className="discount_type">
        <div className="title">
          <span>{t("t:build-campaign.discount-type")}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-discount-type")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="item_radio">
          <div onClick={() => isEditor && setRadioValue("percent")}>
            <input
              type="radio"
              name="percent"
              checked={radioValue === "percent"}
              onChange={() => console.log("changed")}
            />
            <label htmlFor="radio2">{t("t:build-campaign.percentage-discount")}</label>
          </div>
          <div onClick={() => isEditor && setRadioValue("price")}>
            <input
              type="radio"
              name="price"
              checked={radioValue === "price"}
              onChange={() => console.log("changed")}
            />
            <label htmlFor="radio2">{t("t:build-campaign.specific-price")}</label>
          </div>
        </div>
      </div>

      <div className="discount_price">
        {radioValue === "percent" ? (
          <div className="price_block_big">
            <div className="title">
              <span>{`${t("t:build-campaign.discount-percent")} *`}</span>
              <TooltipMessage
                text={t("t:helper-texts.build-discount-percent")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <div className="price_block_input">
              <div className="step_input_wrapper money_block">
                <input
                  disabled={!isEditor}
                  type="number"
                  value={percent}
                  onChange={e => setPercent(e.target.value)}
                />
                {campaignError.value ? (
                  <TooltipMessage
                    text={campaignError.value[0].message}
                    delay={200}
                    error
                    position="top"
                    classes=""
                  >
                    <img src={ErrorIcon} alt="error" />
                  </TooltipMessage>
                ) : (
                  ""
                )}
              </div>
              <span>%</span>
            </div>
          </div>
        ) : (
          <div className="price_block_big">
            <div className="title">
              <span>{`${t("t:build-campaign.discount-price")} *`}</span>
              {/*<TooltipMessage text={t("t:helper-texts.build-discount-price")} delay={200} position="top" classes="step_tooltip">*/}
              {/*    <img src={Shape} alt="Shape" />*/}
              {/*</TooltipMessage>*/}
            </div>
            <div className="price_block_input">
              <div className="step_input_wrapper money_block">
                <input
                  disabled={!isEditor}
                  type="number"
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                />
                {campaignError.value ? (
                  <TooltipMessage
                    text={campaignError.value[0].message}
                    delay={200}
                    error
                    position="top"
                    classes=""
                  >
                    <img src={ErrorIcon} alt="error" />
                  </TooltipMessage>
                ) : (
                  ""
                )}
              </div>
              <span>€</span>
            </div>
          </div>
        )}
        <div className="info_rewards">
          <p>{t("t:build-campaign.discount-value")}</p>
          <span>{discount_value.toFixed(2)} €</span>
        </div>
      </div>
    </div>
  );
};

export default DiscountExpandedView;
