import React from "react";

import { renderMobileImage } from "../../helpers";

const Image = ({ title, description, image }) => (
  <>
    <h2>{title}</h2>
    <p>{description}</p>
    <div className="image-wrapper">{renderMobileImage(image)}</div>
  </>
);

export default Image;
