import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";

const Interests = ({ audienceError, isEditor, interestsList, interests, handleInterest }) => {
  const { t } = useTranslation();
  return (
    <div className="audience_row_wrapper">
      <div className="row_name">
        {`${t("t:campaign-audience.interests")} *`}
        {audienceError.interests ? (
          <TooltipMessage
            text={audienceError.interests[0].message}
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
        <div className="small-text">{t("t:campaign-audience.max-1-interest-categories")}</div>
      </div>
      <div className="row_content">
        <div className="interests_wrapper">
          {interestsList.map(item => {
            const isSelected = !!interests.find(el => el === item.id);
            return (
              <div
                className={clsx(
                  "interest_item",
                  "no_select",
                  isSelected && "chosen_one",
                  !isEditor && "no_hover"
                )}
                onClick={() => isEditor && handleInterest(item)}
                key={`interest-item-${item.id}`}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(Interests);
