import React, { Suspense } from "react";
import App from "../containers/App";
import { Route, Switch, Redirect } from "react-router-dom";
import Container from "../containers/Container/Container";
import AuthContainer from "../containers/AuthContainer/AuthContainer";
import InvitationLink from "../components/InvitationLink/InvitationLink";
import NotFoundImage from "../assets/image/404.png";

export default (
  <App>
    <Suspense fallback={<></>}>
      <Switch>
        <Route
          path="/"
          exact
          render={() =>
            !!localStorage.token ? <Redirect to="/main" /> : <Redirect to="/auth/login" />
          }
        />
        <Route path="/auth" component={AuthContainer} />
        <Route path="/main" component={Container} />
        <Route path="/invitation-link" component={InvitationLink} />
        <Route
          render={() => (
            <div className="not_found">
              <img src={NotFoundImage} alt="not found" />
            </div>
          )}
        />
      </Switch>
    </Suspense>
  </App>
);
