import moment from "moment";

const calculateAnalyticsGraphHeight = (value, maxValue) => {
  if (value === maxValue && value !== 0) return "100%";
  if (value !== 0) return `${(value * 100) / maxValue || 1}%`;
  return "1%";
};

const parseChartData = data => {
  let viewedData = [],
    startedData = [],
    completedData = [],
    cancelledData = [];
  data.forEach(({ date, viewed, accepted, completed, cancelled }) => {
    const timeStamp = moment(date).valueOf();
    viewedData.push([timeStamp, viewed]);
    startedData.push([timeStamp, accepted]);
    completedData.push([timeStamp, completed]);
    cancelledData.push([timeStamp, cancelled]);
  });
  return { viewedData, startedData, completedData, cancelledData };
};

export { calculateAnalyticsGraphHeight, parseChartData };
