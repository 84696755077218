import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  blockOtherActions,
  deleteOneStep,
  getCampaignSteps,
  patchBonusStep,
  resetCampaignErrors,
  setCampaignError,
  setCurrentStepInfo
} from "../../../../../actions/campaignActions";
import { setLoading } from "../../../../../actions/dashboardActions";

import { getOption, renderStepPlaceholder } from "../../../../../helpers/functions";
import BonusExpandedView from "./BonusExpandedView";
import Snack from "../../../../HelperComponents/Snack/Snack";

import present from "../../../../../assets/image/My_Campaign/present.svg";
import deleteIcon from "../../../../../assets/image/My_Campaign/btn-cancel.svg";
import edit from "../../../../../assets/image/btn-edit.svg";
import dragHandle from "../../../../../assets/image/drag-handle.svg";
import eye from "../../../../../assets/image/My_Campaign/eye.svg";

import "./styles.scss";

class Bonus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productOption: props.detail.product
        ? { label: getOption(props.detail.product.name), value: props.detail.product.id }
        : null,
      giftOption: props.detail.gift
        ? { label: getOption(props.detail.gift.name), value: props.detail.gift.id }
        : null,
      campaignId: props.match.params.id,
      popperOpened: false,
      title: props.detail.title || "",
      description: props.detail.description || "",
      need_to_buy: props.detail.need_to_buy || "",
      free_products_given: props.detail.free_products_given,
      openSnack: false,
      openErrorSnack: false,
      productSizes: props.detail.product_sizes || [],
      giftSizes: props.detail.gift_sizes || []
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.popperOpened !== this.state.popperOpened) {
      this.props.handleEdit(this.props.id);
      this.props.blockOtherActions(this.state.popperOpened);
      this.props.isEditor && this.props.handleEditingTimer();
      if (this.state.popperOpened) {
        this.setInfoForMobilePreview();
      } else {
        this.props.setCurrentStepInfo({
          step: "not-selected"
        });
      }
    }

    if (this.infoForPreviewWasChanged(this.state, prevState)) {
      this.setInfoForMobilePreview();
    }

    if (JSON.stringify(this.props.products) !== JSON.stringify(prevProps.products)) {
      this.setInfoForMobilePreview();
    }

    if (this.props.campaignError) {
      if (prevState !== this.state) {
        this.props.resetCampaignErrors();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCampaignErrors();
  }

  infoForPreviewWasChanged = (currState, prevState) => {
    return (
      currState.title !== prevState.title ||
      currState.description !== prevState.description ||
      currState.productOption !== prevState.productOption ||
      currState.giftOption !== prevState.giftOption
    );
  };

  setInfoForMobilePreview = () => {
    const { products } = this.props;
    const { title, description, productOption, giftOption } = this.state;

    this.props.setCurrentStepInfo({
      step: "bonus",
      bonus: {
        title,
        description,
        image:
          typeof products === "object"
            ? products.find(el => el.id === productOption?.value)?.image
            : null,
        bonusImage:
          typeof products === "object"
            ? products.find(el => el.id === giftOption?.value)?.image
            : null
      }
    });
  };

  onAddingNewProduct = (newProduct, name) => {
    this.setState({
      [name]: {
        label: getOption(newProduct.name),
        value: newProduct.id
      }
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event });
  };

  openPopper = () => this.setState({ popperOpened: true });

  closePopper = () => this.setState({ popperOpened: false });

  deleteStep = () => {
    const { deleteOneStep, id, campaign, handleEditingTimer } = this.props;
    deleteOneStep(campaign, id);
    handleEditingTimer();
    this.closePopper();
  };

  setProductError = openErrorSnack => this.setState({ openErrorSnack });

  changeStep = async () => {
    const {
      patchBonusStep,
      id,
      getCampaignSteps,
      setCampaignError,
      setLoading,
      handleEditingTimer,
      t
    } = this.props;
    const {
      title,
      description,
      productOption,
      giftOption,
      need_to_buy,
      free_products_given,
      campaignId,
      productSizes,
      giftSizes
    } = this.state;
    if (need_to_buy === 0 || (!!Number(need_to_buy) && need_to_buy < 1)) {
      setCampaignError({
        need_to_buy: [{ code: "too small", message: t("t:common.more-than-one") }]
      });
    } else if (
      free_products_given === 0 ||
      (!!Number(free_products_given) && free_products_given < 1)
    ) {
      setCampaignError({
        free_products_given: [{ code: "too small", message: t("t:common.more-than-one") }]
      });
    } else if (!productOption || !giftOption) {
      this.setProductError(true);
    } else {
      setLoading(true);
      let data = {
        title,
        description,
        need_to_buy,
        free_products_given,
        product_sizes: productSizes,
        gift_sizes: giftSizes
      };
      if (productOption) {
        data.product_id = productOption.value;
      }
      if (giftOption) {
        data.gift_id = giftOption.value;
      }
      const res = await patchBonusStep(campaignId, id, data);
      handleEditingTimer();
      if (res.payload) {
        getCampaignSteps(campaignId).then(() => setLoading(false));
        this.setState({ openSnack: true });
        this.closePopper();
      } else {
        setLoading(false);
      }
    }
  };

  onTitleChange = textInput => {
    const { title } = this.state;
    if (textInput.length < 51 || textInput.length < title) {
      this.setState({ title: textInput });
    } else {
      this.setState({ title: textInput.slice(0, 50) });
    }
  };

  render() {
    const { type, detail, blockActions, isEditor, t } = this.props;
    const {
      popperOpened,
      openSnack,
      productOption,
      giftOption,
      title,
      description,
      need_to_buy,
      free_products_given,
      openErrorSnack,
      productSizes,
      giftSizes
    } = this.state;
    return (
      <div className="wrapper_block_my_campaign step_wrapper image_wrapper">
        <div className="head_block head_block_step">
          <div className="title_head">
            <div className="not_expanded">
              <img src={dragHandle} alt="draggable" />
              <img src={present} alt="present" />
            </div>
            <div>
              <p>{t("t:build-campaign.bonus")}</p>
              <span>{renderStepPlaceholder(detail.title, type, t)}</span>
            </div>
          </div>
          {isEditor ? (
            <div className="btn_head">
              <button onClick={this.deleteStep} disabled={blockActions}>
                <img src={deleteIcon} alt="trash" />
              </button>
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={edit} alt="save" />
              </button>
            </div>
          ) : (
            <div className="btn_head">
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={eye} alt="open" />
              </button>
            </div>
          )}
        </div>
        {popperOpened && (
          <div className="rewards_wrapper wrapper_block_my_campaign">
            <BonusExpandedView
              {...this.props}
              toClosePopper={this.closePopper}
              productOption={productOption}
              giftOption={giftOption}
              handleChange={this.handleChange}
              title={title}
              description={description}
              need_to_buy={need_to_buy}
              free_products_given={free_products_given}
              setTitle={title => this.onTitleChange(title)}
              setDescription={description => this.setState({ description })}
              setNeedToBuy={need_to_buy =>
                this.setState({ need_to_buy: need_to_buy ? +need_to_buy : "" })
              }
              setFreeProductsGiven={free_products_given =>
                this.setState({
                  free_products_given: free_products_given ? +free_products_given : ""
                })
              }
              changeStep={this.changeStep}
              setProductError={this.setProductError}
              openErrorSnack={openErrorSnack}
              onAddingNewProduct={this.onAddingNewProduct}
              deleteStep={this.deleteStep}
              productSizes={productSizes}
              setProductSizes={val => this.setState({ productSizes: val })}
              giftSizes={giftSizes}
              setGiftSizes={val => this.setState({ giftSizes: val })}
            />
          </div>
        )}
        <Snack
          open={openSnack}
          handleSnack={() => this.setState({ openSnack: false })}
          message={t("t:snack-messages.successfully-changed")}
          type="success"
        />
      </div>
    );
  }
}

function mapStateToProps({ campaign, products }) {
  return {
    campaignError: campaign.campaignError,
    campaignStatus: campaign.campaignInfo.status,
    blockActions: campaign.blockActions,
    products: products.list,
    isEditor: campaign.campaignViewers.is_editor
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteOneStep,
      patchBonusStep,
      getCampaignSteps,
      resetCampaignErrors,
      setCampaignError,
      blockOtherActions,
      setLoading,
      setCurrentStepInfo
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Bonus)));
