import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { withTranslation } from "react-i18next";

import RenderField from "../../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";

import { postNewEmail } from "../../../../actions/profileActions";

class ChangeEmailForm extends Component {
  changeEmail = async data => {
    const { postNewEmail, toggleDialog } = this.props;
    let email = { email: data.email };
    const res = await postNewEmail(email);
    if (res.error) {
      throw new SubmissionError({ email: res.error.response.data.email[0].message });
    } else {
      toggleDialog("enter_new_email");
      toggleDialog("enter_code");
    }
  };

  render() {
    const { loading, handleSubmit, submitting, pristine, valid, toggleDialog, t } = this.props;
    return (
      <form onSubmit={handleSubmit(this.changeEmail)} className="dialog_form">
        <Field name="email" type="text" component={RenderField} label={t("t:common.email")} />
        <div className="buttons_wrapper">
          <DefaultButton
            variant="contained"
            classes="purple_btn"
            formAction
            disabled={submitting || pristine || !valid}
            loading={loading}
          >
            {t("t:common.continue")}
          </DefaultButton>
          <DefaultButton
            variant="contained"
            classes="cancel_btn"
            onClick={() => toggleDialog("enter_new_email")}
          >
            {t("t:common.cancel")}
          </DefaultButton>
        </div>
      </form>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.email) {
    errors.email = t("t:common.field-is-required");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = t("t:auth.please-enter-correct-email");
  }
  return errors;
};

ChangeEmailForm = reduxForm({
  form: "ChangeEmailForm",
  validate
})(ChangeEmailForm);

const mapStateToProps = ({ dashboard }) => {
  return {
    loading: dashboard.loadingComponent
  };
};

const mapDispatchToProps = {
  postNewEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangeEmailForm));
