import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  blockOtherActions,
  deleteOneStep,
  getCampaignSteps,
  patchNoveltyStep,
  resetCampaignErrors,
  setCampaignError,
  setCurrentStepInfo
} from "../../../../../actions/campaignActions";
import { setLoading } from "../../../../../actions/dashboardActions";

import { getOption, renderStepPlaceholder } from "../../../../../helpers/functions";
import Snack from "../../../../HelperComponents/Snack/Snack";
import NoveltyExpandedView from "./NoveltyExpandedView";

import novelty from "../../../../../assets/image/My_Campaign/novelty.svg";
import edit from "../../../../../assets/image/btn-edit.svg";
import deleteIcon from "../../../../../assets/image/My_Campaign/btn-cancel.svg";
import dragHandle from "../../../../../assets/image/drag-handle.svg";
import eye from "../../../../../assets/image/My_Campaign/eye.svg";

import "./styles.scss";

class Novelty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productOption: props.detail.product
        ? { label: getOption(props.detail.product.name), value: props.detail.product.id }
        : null,
      campaignId: props.match.params.id,
      popperOpened: false,
      title: props.detail.title || "",
      description: props.detail.description || "",
      openSnack: false,
      openErrorSnack: false,
      productSizes: props.detail.product_sizes || []
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.popperOpened !== this.state.popperOpened) {
      this.props.handleEdit(this.props.id);
      this.props.blockOtherActions(this.state.popperOpened);
      this.props.isEditor && this.props.handleEditingTimer();
      if (this.state.popperOpened) {
        this.setInfoForMobilePreview();
      } else {
        this.props.setCurrentStepInfo({
          step: "not-selected"
        });
      }
    }

    if (this.infoForPreviewWasChanged(this.state, prevState)) {
      this.setInfoForMobilePreview();
    }

    if (JSON.stringify(this.props.products) !== JSON.stringify(prevProps.products)) {
      this.setInfoForMobilePreview();
    }

    if (this.props.campaignError) {
      if (prevState !== this.state) {
        this.props.resetCampaignErrors();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCampaignErrors();
  }

  infoForPreviewWasChanged = (currState, prevState) => {
    return (
      currState.title !== prevState.title ||
      currState.description !== prevState.description ||
      currState.productOption !== prevState.productOption
    );
  };

  setInfoForMobilePreview = () => {
    const { products } = this.props;
    const { title, description, productOption } = this.state;

    this.props.setCurrentStepInfo({
      step: "novelty",
      novelty: {
        title,
        description,
        image:
          typeof products === "object"
            ? products.find(el => el.id === productOption?.value)?.image
            : null
      }
    });
  };

  onAddingNewProduct = (newProduct, name) => {
    this.setState({
      [name]: {
        label: getOption(newProduct.name),
        value: newProduct.id
      }
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event });
  };

  openPopper = () => this.setState({ popperOpened: true });

  closePopper = () => this.setState({ popperOpened: false });

  deleteStep = () => {
    const { deleteOneStep, id, campaign, handleEditingTimer } = this.props;
    this.closePopper();
    deleteOneStep(campaign, id);
    handleEditingTimer();
  };

  setProductError = openErrorSnack => this.setState({ openErrorSnack });

  changeStep = async () => {
    const { patchNoveltyStep, id, getCampaignSteps, setLoading, handleEditingTimer } = this.props;
    const { title, description, productOption, campaignId, productSizes } = this.state;
    if (!productOption) {
      this.setProductError(true);
    } else {
      setLoading(true);
      let data = {
        title,
        description,
        product_id: productOption.value,
        product_sizes: productSizes
      };
      const res = await patchNoveltyStep(campaignId, id, data);
      handleEditingTimer();
      if (res.payload) {
        getCampaignSteps(campaignId).then(() => setLoading(false));
        this.setState({ openSnack: true });
        this.closePopper();
      } else {
        setLoading(false);
      }
    }
  };

  onTitleChange = textInput => {
    const { title } = this.state;
    if (textInput.length < 51 || textInput.length < title) {
      this.setState({ title: textInput });
    } else {
      this.setState({ title: textInput.slice(0, 50) });
    }
  };

  render() {
    const { type, detail, blockActions, isEditor, t } = this.props;
    const {
      popperOpened,
      openSnack,
      productOption,
      title,
      description,
      openErrorSnack,
      productSizes
    } = this.state;
    return (
      <div className="wrapper_block_my_campaign step_wrapper image_wrapper">
        <div className="head_block head_block_step">
          <div className="title_head">
            <div className="not_expanded">
              <img src={dragHandle} alt="draggable" />
              <img src={novelty} alt="novelty" />
            </div>
            <div>
              <p>{t("t:build-campaign.novelty")}</p>
              <span>{renderStepPlaceholder(detail.title, type, t)}</span>
            </div>
          </div>
          {isEditor ? (
            <div className="btn_head">
              <button onClick={this.deleteStep} disabled={blockActions}>
                <img src={deleteIcon} alt="trash" />
              </button>
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={edit} alt="edit" />
              </button>
            </div>
          ) : (
            <div className="btn_head">
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={eye} alt="open" />
              </button>
            </div>
          )}
        </div>
        {popperOpened && (
          <div className="rewards_wrapper wrapper_block_my_campaign">
            <NoveltyExpandedView
              {...this.props}
              toClosePopper={this.closePopper}
              productOption={productOption}
              handleChange={this.handleChange}
              title={title}
              description={description}
              setTitle={title => this.onTitleChange(title)}
              setDescription={description => this.setState({ description })}
              changeStep={this.changeStep}
              openErrorSnack={openErrorSnack}
              setProductError={this.setProductError}
              onAddingNewProduct={this.onAddingNewProduct}
              deleteStep={this.deleteStep}
              productSizes={productSizes}
              setProductSizes={val => this.setState({ productSizes: val })}
            />
          </div>
        )}
        <Snack
          open={openSnack}
          handleSnack={() => this.setState({ openSnack: false })}
          message={t("t:snack-messages.successfully-changed")}
          type="success"
        />
      </div>
    );
  }
}

function mapStateToProps({ campaign, products }) {
  return {
    campaignError: campaign.campaignError,
    campaignStatus: campaign.campaignInfo.status,
    blockActions: campaign.blockActions,
    products: products.list,
    isEditor: campaign.campaignViewers.is_editor
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteOneStep,
      patchNoveltyStep,
      getCampaignSteps,
      resetCampaignErrors,
      setCampaignError,
      blockOtherActions,
      setLoading,
      setCurrentStepInfo
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(memo(Novelty))));
