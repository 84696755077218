import React, { useMemo } from "react";

import { renderMobileImage } from "../../helpers";

import RadioSelectedIcon from "../../../../../../assets/image/mobile-radio-selected.svg";
import RadioIcon from "../../../../../../assets/image/mobile-radio.svg";
import CheckboxSelectedIcon from "../../../../../../assets/image/mobile-checkbox-selected.svg";
import CheckboxIcon from "../../../../../../assets/image/mobile-checkbox.svg";

const Multiple = ({ title, description, image, multipleChoice, answers }) => {
  const renderAnswers = useMemo(
    () => (
      <div className="multiple-choices">
        {answers.map((el, key) => (
          <div className="multiple-choices__row" key={`answer-${key}`}>
            {key === 1 ? (
              <img src={multipleChoice ? CheckboxSelectedIcon : RadioSelectedIcon} alt="input" />
            ) : (
              <img src={multipleChoice ? CheckboxIcon : RadioIcon} alt="input" />
            )}
            <span>{el}</span>
          </div>
        ))}
      </div>
    ),
    // eslint-disable-next-line
    [JSON.stringify(answers), multipleChoice]
  );

  return (
    <>
      <h2>{title}</h2>
      <p>{description}</p>
      {image && <div className="image-wrapper">{renderMobileImage(image)}</div>}
      {renderAnswers}
    </>
  );
};

export default Multiple;
