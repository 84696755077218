import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import SelectComponent from "../../../../HelperComponents/SelectComponent/SelectComponent";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";
import Shape from "../../../../../assets/image/My_Campaign/Shape.svg";

const Age = ({
  audienceError,
  older_than,
  younger_than,
  isEditor,
  olderThanList,
  youngerThanList,
  handleChange
}) => {
  const { t } = useTranslation();
  return (
    <div className="audience_row_wrapper">
      <div className="row_name">
        {t("t:campaign-audience.age")}
        {audienceError.age_from ||
        (older_than.value && younger_than.value && +older_than.value > +younger_than.value) ? (
          <TooltipMessage
            text={
              audienceError.age_from
                ? audienceError.age_from[0].message
                : t("t:campaign-audience.age-validation")
            }
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
      </div>
      <div className="row_content">
        <div className="age_wrapper">
          <div>
            <div className="input_title_campaign">
              <span>{t("t:campaign-audience.older-than")}</span>
              <TooltipMessage
                text={t("t:helper-texts.audience-age-older-than")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <FormControl className={clsx("select_wrapper", !isEditor && "cursor_not_allowed")}>
              <SelectComponent
                disabled={!isEditor}
                value={older_than}
                options={olderThanList}
                change={handleChange("older_than")}
                isClearable="false"
                isSearchable={false}
                placeholder={t("t:common.choose")}
              />
            </FormControl>
          </div>
          <div>
            <div className="input_title_campaign">
              <span>{t("t:campaign-audience.younger-than")}</span>
              <TooltipMessage
                text={t("t:helper-texts.audience-age-younger-than")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <FormControl className={clsx("select_wrapper", !isEditor && "cursor_not_allowed")}>
              <SelectComponent
                disabled={!isEditor}
                value={younger_than}
                options={youngerThanList}
                change={handleChange("younger_than")}
                isClearable="false"
                isSearchable={false}
                placeholder={t("t:common.choose")}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Age);
