import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import TrackingInfoForm from "../TrackingInfoForm";
import RedemptionInfoForm from "../RedemptionInfoForm";

import {
  postDeliveryDeliverToPickupStatus,
  postDeliveryReadyForPickupStatus,
  postDeliveryNeedsDeliveryStatus,
  postDeliveryDoneStatus,
  postDeliveryOnDeliveryStatus,
  patchDeliveryInfo
} from "../../../actions/campaignActions";

class DeliveryActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: props.campaignId,
      confirmationDialog: false,
      openTrackingInfoDialog: false,
      openRedemptionInfoDialog: false,
      deliveryInfo: {},
      buttonLoading: false
    };
  }

  handleConfirmationDialog = dialogType => {
    this.setState(({ confirmationDialog }) => ({
      confirmationDialog: confirmationDialog ? null : dialogType
    }));
  };

  handleTrackingInfoDialog = () => {
    this.setState(({ openTrackingInfoDialog }) => ({
      openTrackingInfoDialog: !openTrackingInfoDialog
    }));
  };

  handleRedemptionInfoDialog = () => {
    this.setState(({ openRedemptionInfoDialog }) => ({
      openRedemptionInfoDialog: !openRedemptionInfoDialog
    }));
  };

  renderButtonByStatus = deliveryInfo => {
    const { t } = this.props;
    switch (deliveryInfo.status) {
      case "delivery_confirmation":
        return (
          <DefaultButton
            variant="contained"
            classes="delivery_btn"
            onClick={() => {
              this.setState({ deliveryInfo });
              this.handleConfirmationDialog(
                deliveryInfo.type === "pickup" ? "confirmPickup" : "confirmDelivery"
              );
            }}
          >
            {deliveryInfo.type === "pickup"
              ? t("t:deliveries.confirm-pickup")
              : t("t:deliveries.confirm-delivery")}
          </DefaultButton>
        );
      case "deliver_to_pickup":
        return (
          <DefaultButton
            variant="contained"
            classes="delivery_btn"
            onClick={() => {
              this.setState({ deliveryInfo });
              this.handleConfirmationDialog("readyForPickup");
            }}
          >
            {t("t:deliveries.ready-for-pickup")}
          </DefaultButton>
        );
      case "ready_for_pickup":
      case "on_delivery":
        return (
          <DefaultButton
            variant="contained"
            classes="delivery_btn"
            onClick={() => {
              this.setState({ deliveryInfo });
              this.handleConfirmationDialog("finishDelivery");
            }}
          >
            {t("t:deliveries.finish-delivery")}
          </DefaultButton>
        );
      case "needs_delivery":
        return (
          <DefaultButton
            variant="contained"
            classes="delivery_btn"
            onClick={() => {
              this.setState({ deliveryInfo });
              this.handleConfirmationDialog("startDelivery");
            }}
          >
            {t("t:deliveries.start-delivery")}
          </DefaultButton>
        );
      case "unredeemed":
        return (
          <DefaultButton
            variant="contained"
            classes="delivery_btn"
            onClick={() => {
              this.setState({ deliveryInfo });
              this.handleConfirmationDialog("confirmRedemption");
            }}
          >
            {t("t:deliveries.redeemed")}
          </DefaultButton>
        );
      default:
        return null;
    }
  };

  confirmPickup = async () => {
    const { postDeliveryDeliverToPickupStatus } = this.props;
    const { deliveryInfo, campaignId } = this.state;
    await postDeliveryDeliverToPickupStatus(campaignId, deliveryInfo.id);
  };

  readyForPickup = async () => {
    const { postDeliveryReadyForPickupStatus } = this.props;
    const { deliveryInfo, campaignId } = this.state;
    await postDeliveryReadyForPickupStatus(campaignId, deliveryInfo.id);
  };

  confirmDelivery = async () => {
    const { postDeliveryNeedsDeliveryStatus } = this.props;
    const { deliveryInfo, campaignId } = this.state;
    await postDeliveryNeedsDeliveryStatus(campaignId, deliveryInfo.id);
  };

  startDelivery = async () => {
    const { postDeliveryOnDeliveryStatus } = this.props;
    const { deliveryInfo, campaignId } = this.state;
    await postDeliveryOnDeliveryStatus(campaignId, deliveryInfo.id);
  };

  finishDelivery = async () => {
    const { postDeliveryDoneStatus } = this.props;
    const { deliveryInfo, campaignId } = this.state;
    await postDeliveryDoneStatus(campaignId, deliveryInfo.id);
  };

  getConfirmationDialogTitle = () => {
    const { t } = this.props;
    switch (this.state.confirmationDialog) {
      case "confirmPickup":
        return t("t:deliveries.confirm-pickup");
      case "readyForPickup":
        return t("t:deliveries.ready-for-pickup");
      case "confirmDelivery":
        return t("t:deliveries.confirm-delivery");
      case "startDelivery":
        return t("t:deliveries.start-delivery");
      case "finishDelivery":
        return t("t:deliveries.finish-delivery");
      case "confirmRedemption":
        return t("t:deliveries.confirm-redemption");
      default:
        return null;
    }
  };

  getConfirmationDialogDescription = () => {
    const { t } = this.props;
    switch (this.state.confirmationDialog) {
      case "confirmPickup":
        return t("t:deliveries.confirm-pickup-description");
      case "readyForPickup":
        return t("t:deliveries.ready-for-pickup-description");
      case "confirmDelivery":
        return t("t:deliveries.confirm-delivery-description");
      case "startDelivery":
        return t("t:deliveries.start-delivery-description");
      case "finishDelivery":
        return t("t:deliveries.finish-delivery-description");
      case "confirmRedemption":
        return t("t:deliveries.confirm-redemption-description");
      default:
        return null;
    }
  };

  confirmAction = () => {
    switch (this.state.confirmationDialog) {
      case "confirmPickup":
        this.setState({ buttonLoading: true });
        this.confirmPickup().then(() => {
          this.handleConfirmationDialog();
          this.setState({ buttonLoading: false });
        });
        break;
      case "readyForPickup":
        this.setState({ buttonLoading: true });
        this.readyForPickup().then(() => {
          this.handleConfirmationDialog();
          this.setState({ buttonLoading: false });
        });
        break;
      case "confirmDelivery":
        this.setState({ buttonLoading: true });
        this.confirmDelivery().then(() => {
          this.handleConfirmationDialog();
          this.setState({ buttonLoading: false });
        });
        break;
      case "startDelivery":
        this.handleConfirmationDialog();
        this.handleTrackingInfoDialog();
        break;
      case "finishDelivery":
        this.setState({ buttonLoading: true });
        this.finishDelivery().then(() => {
          this.handleConfirmationDialog();
          this.setState({ buttonLoading: false });
        });
        break;
      case "confirmRedemption":
        this.handleConfirmationDialog();
        this.handleRedemptionInfoDialog();
        break;
      default:
        break;
    }
  };

  render() {
    const { t, delivery } = this.props;
    const {
      openTrackingInfoDialog,
      openRedemptionInfoDialog,
      deliveryInfo,
      campaignId,
      confirmationDialog,
      buttonLoading
    } = this.state;
    return (
      <>
        {this.renderButtonByStatus(delivery)}
        {openTrackingInfoDialog && (
          <DialogComponent
            open
            onClose={this.handleTrackingInfoDialog}
            closeIcon
            classes="tracking_info_dialog"
          >
            <TrackingInfoForm
              {...deliveryInfo}
              toggleDialog={this.handleTrackingInfoDialog}
              campaignId={campaignId}
              moveToOnDeliveryStatus
            />
          </DialogComponent>
        )}
        {openRedemptionInfoDialog && (
          <DialogComponent
            open
            onClose={this.handleRedemptionInfoDialog}
            closeIcon
            classes="tracking_info_dialog"
          >
            <RedemptionInfoForm
              {...deliveryInfo}
              toggleDialog={this.handleRedemptionInfoDialog}
              campaignId={campaignId}
            />
          </DialogComponent>
        )}
        {!!confirmationDialog && (
          <DialogComponent open onClose={this.handleConfirmationDialog} closeIcon>
            <div className="default_dialog_wrapper">
              <div className="dialog_title">{this.getConfirmationDialogTitle()}</div>
              <div className="dialog_description">{this.getConfirmationDialogDescription()}</div>
              <div className="buttons_wrapper">
                <DefaultButton
                  variant="contained"
                  classes="ok_btn"
                  onClick={this.confirmAction}
                  loading={buttonLoading}
                >
                  {t("t:common.confirm")}
                </DefaultButton>
              </div>
            </div>
          </DialogComponent>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postDeliveryDeliverToPickupStatus,
      postDeliveryReadyForPickupStatus,
      postDeliveryNeedsDeliveryStatus,
      postDeliveryDoneStatus,
      postDeliveryOnDeliveryStatus,
      patchDeliveryInfo
    },
    dispatch
  );
}

export default memo(connect(null, mapDispatchToProps)(withTranslation()(DeliveryActions)));
