import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";

const SegmentName = ({ onFieldChange, onStepChange, isEdit }) => {
  const { t } = useTranslation();
  const {
    customAudienceInfo: { name }
  } = useSelector(({ audience }) => audience);

  return (
    <div className={clsx("custom-audience__segment-name", isEdit && "edit-mode")}>
      <div className={clsx("custom-audience__segment-name--label", isEdit && "edit-mode")}>
        {isEdit ? t("t:audience.segment-name") : t("t:audience.name-your-segment")}
      </div>
      <div className={clsx("custom-audience__segment-name--input-wrapper", isEdit && "edit-mode")}>
        <input
          className="default-input"
          type="text"
          value={name}
          onChange={e => onFieldChange("name", e.target.value)}
        />
      </div>
      <TooltipMessage
        text={!name ? t("t:common.enter-mandatory-fields") : ""}
        delay={200}
        position="top"
        classes="step_tooltip"
      >
        <div className="custom-audience__submit-btn">
          <DefaultButton
            variant="contained"
            classes="auth"
            type="button"
            onClick={() => onStepChange("segment_groups")}
            disabled={!name}
          >
            {t("t:common.continue")}
          </DefaultButton>
        </div>
      </TooltipMessage>
    </div>
  );
};

export default SegmentName;
