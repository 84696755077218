import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  deleteOneStep,
  patchMultipleStep,
  resetCampaignErrors,
  getCampaignSteps,
  blockOtherActions,
  setCurrentStepInfo
} from "../../../../../actions/campaignActions";
import { setLoading } from "../../../../../actions/dashboardActions";

import { renderStepPlaceholder, validateImage } from "../../../../../helpers/functions";
import Snack from "../../../../HelperComponents/Snack/Snack";
import MultipleExpandedView from "./MultipleExpandedView";

import check_list from "../../../../../assets/image/My_Campaign/check-list.svg";
import edit from "../../../../../assets/image/btn-edit.svg";
import deleteIcon from "../../../../../assets/image/My_Campaign/btn-cancel.svg";
import dragHandle from "../../../../../assets/image/drag-handle.svg";
import eye from "../../../../../assets/image/My_Campaign/eye.svg";

import "./styles.scss";

class Multiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: props.match.params.id,
      popperOpened: false,
      title: props.detail.title,
      description: props.detail.description,
      openSnack: false,
      openMaxAnswersSnack: false,
      openErrorSnack: false,
      openMinimumAnswersSnack: false,
      openErrorImageSnack: false,
      image: props.detail.image,
      imagePreview: null,
      imageToCrop: null,
      blobToDownload: null,
      previewDialogOpen: false,
      typeRadio: props.detail.type || "single",
      text_answers: props.detail.text_answers[0] ? props.detail.text_answers : ["", ""]
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.popperOpened !== this.state.popperOpened) {
      this.props.handleEdit(this.props.id);
      this.props.blockOtherActions(this.state.popperOpened);
      this.props.isEditor && this.props.handleEditingTimer();
      if (this.state.popperOpened) {
        this.setInfoForMobilePreview();
      } else {
        this.props.setCurrentStepInfo({
          step: "not-selected"
        });
      }
    }

    if (this.infoForPreviewWasChanged(this.state, prevState)) {
      this.setInfoForMobilePreview();
    }

    if (this.props.campaignError) {
      if (prevState !== this.state) {
        this.props.resetCampaignErrors();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCampaignErrors();
  }

  infoForPreviewWasChanged = (currState, prevState) => {
    return (
      currState.title !== prevState.title ||
      currState.description !== prevState.description ||
      currState.image !== prevState.image ||
      currState.imagePreview !== prevState.imagePreview ||
      currState.typeRadio !== prevState.typeRadio ||
      JSON.stringify(currState.text_answers) !== JSON.stringify(prevState.text_answers)
    );
  };

  setInfoForMobilePreview = () => {
    const { title, description, image, imagePreview, typeRadio, text_answers } = this.state;

    this.props.setCurrentStepInfo({
      step: "multiple",
      multiple: {
        title,
        description,
        image: imagePreview || image,
        multipleChoice: typeRadio === "multiple",
        answers: text_answers
      }
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = result => {
    const { title, description, image, imagePreview, typeRadio, text_answers } = this.state;
    if (!result.destination) {
      return;
    }
    const items = this.reorder(text_answers, result.source.index, result.destination.index);
    this.setState({
      text_answers: items
    });

    this.props.setCurrentStepInfo({
      step: "multiple",
      multiple: {
        title,
        description,
        image: imagePreview || image,
        multipleChoice: typeRadio === "multiple",
        answers: items
      }
    });
  };

  openPopper = () => this.setState({ popperOpened: true });

  closePopper = () => this.setState({ popperOpened: false });

  deleteStep = () => {
    const { deleteOneStep, id, campaign, handleEditingTimer } = this.props;
    deleteOneStep(campaign, id);
    handleEditingTimer();
    this.closePopper();
  };

  changeStep = async () => {
    const { patchMultipleStep, id, getCampaignSteps, setLoading, handleEditingTimer } = this.props;
    const {
      title,
      description,
      typeRadio,
      text_answers,
      campaignId,
      imagePreview,
      image,
      blobToDownload
    } = this.state;
    let numberOfFilledAnswers = 0;
    text_answers.forEach(el => !!el && numberOfFilledAnswers++);
    if (numberOfFilledAnswers < 2) {
      this.setState({ openMinimumAnswersSnack: true });
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("type", typeRadio);
      formData.append("text_answers", JSON.stringify(text_answers));
      if (!!imagePreview) {
        formData.append("image", blobToDownload, "multiple.png");
      } else if (!image) {
        formData.append("image", "");
      }
      const res = await patchMultipleStep(campaignId, id, formData);
      handleEditingTimer();
      if (res.payload) {
        getCampaignSteps(campaignId).then(() => setLoading(false));
        this.setState({ openSnack: true });
        this.closePopper();
      } else {
        if (res.error.response.data.text_answers) {
          this.setState({ openErrorSnack: true });
        }
        setLoading(false);
      }
    }
  };

  changeAnswer = (e, key) => {
    let text_answers = [...this.state.text_answers];
    text_answers[key] = e.target.value;
    this.setState({ text_answers });
  };

  addAnswer = () => {
    const { text_answers } = this.state;
    if (text_answers.length < 10) {
      this.setState({ text_answers: [...text_answers, ""] });
    } else {
      this.setState({ openMaxAnswersSnack: true });
    }
  };

  deleteAnswer = key => {
    let text_answers = [...this.state.text_answers];
    text_answers.splice(key, 1);
    this.setState({ text_answers });
  };

  handleFiles = files => {
    if (validateImage(files[0])) {
      this.handleImage(files[0]);
    } else {
      this.setState({ openErrorSnack: true });
    }
  };

  handleImage = image => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => this.setState({ imageToCrop: [reader.result] });
  };

  onFinishCroppingImage = (canvas, imageMimeType) => {
    this.setState({ imageToCrop: null });
    canvas.toBlob(blob => {
      this.setState({
        imagePreview: canvas.toDataURL(imageMimeType),
        image: canvas.toDataURL(imageMimeType),
        blobToDownload: blob
      });
    }, imageMimeType);
  };

  onCancelCropping = () => this.setState({ imageToCrop: null });

  onTitleChange = textInput => {
    const { title } = this.state;
    if (textInput.length < 51 || textInput.length < title) {
      this.setState({ title: textInput });
    } else {
      this.setState({ title: textInput.slice(0, 50) });
    }
  };

  render() {
    const { type, detail, blockActions, isEditor, t, campaignError } = this.props;
    const {
      popperOpened,
      openSnack,
      title,
      description,
      typeRadio,
      text_answers,
      openMaxAnswersSnack,
      openErrorSnack,
      openMinimumAnswersSnack,
      openErrorImageSnack,
      image,
      imagePreview,
      previewDialogOpen,
      imageToCrop
    } = this.state;
    return (
      <div className="wrapper_block_my_campaign step_wrapper">
        <div className="head_block head_block_step">
          <div className="title_head">
            <div className="not_expanded">
              <img src={dragHandle} alt="draggable" />
              <img src={check_list} alt="check_list" />
            </div>
            <div>
              <p>{t("t:build-campaign.multiple-choice")}</p>
              <span>{renderStepPlaceholder(detail.title, type, t)}</span>
            </div>
          </div>
          {isEditor ? (
            <div className="btn_head">
              <button onClick={this.deleteStep} disabled={blockActions}>
                <img src={deleteIcon} alt="trash" />
              </button>
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={edit} alt="save" />
              </button>
            </div>
          ) : (
            <div className="btn_head">
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={eye} alt="open" />
              </button>
            </div>
          )}
        </div>
        {popperOpened && (
          <div className="responses_wrapper wrapper_block_my_campaign multiple_wrapper">
            <MultipleExpandedView
              type={type}
              detail={detail}
              title={title}
              description={description}
              typeRadio={typeRadio}
              text_answers={text_answers}
              openMaxAnswersSnack={openMaxAnswersSnack}
              openErrorSnack={openErrorSnack}
              openMinimumAnswersSnack={openMinimumAnswersSnack}
              openErrorImageSnack={openErrorImageSnack}
              image={image}
              imagePreview={imagePreview}
              previewDialogOpen={previewDialogOpen}
              imageToCrop={imageToCrop}
              campaignError={campaignError}
              isEditor={isEditor}
              onDragEnd={this.onDragEnd}
              onTitleChange={this.onTitleChange}
              deleteStep={this.deleteStep}
              changeStep={this.changeStep}
              deleteAnswer={this.deleteAnswer}
              changeAnswer={this.changeAnswer}
              addAnswer={this.addAnswer}
              changeState={fields => this.setState(fields)}
              onFinishCroppingImage={this.onFinishCroppingImage}
              onCancelCropping={this.onCancelCropping}
              handleFiles={this.handleFiles}
              toClosePopper={this.closePopper}
            />
          </div>
        )}
        <Snack
          open={openSnack}
          handleSnack={() => this.setState({ openSnack: false })}
          message={t("t:snack-messages.successfully-changed")}
          type="success"
        />
      </div>
    );
  }
}

function mapStateToProps({ campaign }) {
  return {
    campaignError: campaign.campaignError,
    blockActions: campaign.blockActions,
    isEditor: campaign.campaignViewers.is_editor
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteOneStep,
      patchMultipleStep,
      getCampaignSteps,
      resetCampaignErrors,
      blockOtherActions,
      setLoading,
      setCurrentStepInfo
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Multiple)));
