import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Field, reduxForm, change } from "redux-form";
import { ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";

import RenderField from "../../components/HelperComponents/RenderField/RenderField";
import DefaultButton from "../../components/HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../components/HelperComponents/DialogComponent/DialogComponent";
import Snack from "../../components/HelperComponents/Snack/Snack";

import Panel from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header";
import Campaigns from "../../components/Campaigns";
import AddCampaign from "../../components/Campaigns/AddCampaign";
import Users from "../../components/Users/Users";
import InviteUser from "../../components/Users/InviteUser/InviteUser";
import Products from "../../components/Products";
import CreateProduct from "../../components/Products/CreateProduct/CreateProduct";
import EditProduct from "../../components/Products/EditProduct/EditProduct";
import ViewProduct from "../../components/Products/ViewProduct";

// import Templates from "../../components/Templates/Templates";
import AudienceList from "../../components/Audience/List";
import CreateCSVBasedAudience from "../../components/Audience/CSVBased/Create";
import EditCSVBasedAudience from "../../components/Audience/CSVBased/Edit";
import ViewCSVBasedAudience from "../../components/Audience/CSVBased/View";
import CreateCustomAudience from "../../components/Audience/CustomAudience/Create";
import EditCustomAudience from "../../components/Audience/CustomAudience/Edit";
import EditAnalyticsBasedAudience from "../../components/Audience/AnalyticsBased/Edit";

import Campaign from "../../components/Campaign";
import WelcomeToCampy from "../../components/WelcomeToCampy/WelcomeToCampy";

import Profile from "../../components/Profile/Profile";
import EditCompany from "../../components/Profile/Companies/EditCompany/EditCompany";
import CreateCompany from "../../components/Profile/Companies/CreateCompany/CreateCompany";
import ViewCompany from "../../components/Profile/Companies/ViewCompany";

import AddNewDataSource from "../../components/DataSources/AddNewDataSource";
import AddMagentoDataSource from "../../components/DataSources/AddMagentoDataSource";
import EditDataSource from "../../components/DataSources/EditDataSource";
import AddProducts from "../../components/DataSources/AddProducts/AddProducts";

import BillingReports from "../../components/BillingReports";

import CreateCompanyProfile from "../../components/CreateCompanyProfile";

import Deliveries from "../../components/Deliveries";

import CompanyApiAccess from "../../components/CompanyApiAccess";

import { getGlobalParameters, getProfile } from "../../actions/authActions";
import { setAccessError } from "../../actions/campaignActions";
import { closeErrorSnack, closeSuccessSnack } from "../../actions/dashboardActions";
import {
  handleBalanceDialog,
  postGetInvoice,
  postDeposit,
  postRequestMoney
} from "../../actions/balanceActions";

import NotFoundImage from "../../assets/image/404.png";

const Support = React.lazy(() => import("../../components/Support"));

class Container extends Component {
  state = {
    loading: true,
    balanceNotEnoughDialog: false,
    invoiceErrorDialog: false
  };

  componentDidMount() {
    const { history } = this.props;
    this.doRequest().then(res => {
      if (localStorage.invitation_link) {
        history.push(localStorage.invitation_link);
        localStorage.removeItem("invitation_link");
      } else if (res.payload && !res.payload.data.companies.length) {
        history.push("/main/create-company-profile");
      }
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.prefilledValue !== this.props.prefilledValue) {
      this.props.dispatch(change("GetInvoiceForm", "amount", this.props.prefilledValue || ""));
    }
  }

  doRequest = async () => {
    const { getProfile, getGlobalParameters } = this.props;
    await getGlobalParameters();
    return await getProfile();
  };

  resetError = () => {
    const { setAccessError } = this.props;
    setAccessError(false);
  };

  handleBalanceAction = data => {
    const { typeOfBalanceDialog, handleBalanceDialog, reset } = this.props;
    switch (typeOfBalanceDialog) {
      case "deposit":
        this.submitDeposit(data).then(res => {
          handleBalanceDialog(false);
          reset("GetInvoiceForm");
          if (!res.payload) {
            if (res.error.response.status === 400) {
              this.setState({ balanceNotEnoughDialog: true });
            } else {
              this.setState({ accessError: true });
            }
          }
        });
        break;
      case "get_invoice":
        this.submitGetInvoice(data).then(res => {
          handleBalanceDialog(false);
          reset("GetInvoiceForm");
          if (res.error) this.setState({ invoiceErrorDialog: true });
        });
        break;
      case "request_money":
        this.submitRequestMoney(data).then(() => {
          handleBalanceDialog(false);
          reset("GetInvoiceForm");
        });
        break;
      default:
        break;
    }
  };

  submitGetInvoice = async data => {
    const {
      postGetInvoice,
      user_info: { current_company }
    } = this.props;
    return await postGetInvoice(current_company.id, data);
  };

  submitDeposit = async data => {
    const { postDeposit, requestCampaignId } = this.props;
    return await postDeposit(requestCampaignId, data);
  };

  submitRequestMoney = async data => {
    const { postRequestMoney, requestCampaignId } = this.props;
    return await postRequestMoney(requestCampaignId, data);
  };

  withHeader = () => {
    const { location } = this.props;
    return location.pathname !== "/main/create-company-profile";
  };

  withPanel = () => {
    const { location } = this.props;
    return (
      location.pathname !== "/main/my-campaign" &&
      !location.pathname.includes("/campaign/") &&
      location.pathname !== "/main/create-company-profile"
    );
  };

  render() {
    const {
      match,
      history,
      user_info: { full_name },
      loadingComponent,
      errorSnack,
      errorSnackText,
      successSnack,
      successSnackText,
      closeSuccessSnack,
      closeErrorSnack,
      accessError,
      openBalanceDialog,
      handleBalanceDialog,
      handleSubmit,
      submitting,
      pristine,
      valid,
      typeOfBalanceDialog,
      prefilledValue,
      t
    } = this.props;
    const { loading, balanceNotEnoughDialog, invoiceErrorDialog } = this.state;
    if (!localStorage.token) return <Redirect to="/auth" />;
    if (loading) return null;
    if (!full_name) return <WelcomeToCampy doRequest={this.doRequest} />;
    return (
      <Fragment>
        <div className="page_all">
          {this.withHeader() && <Header history={history} />}
          <div className="page">
            {this.withPanel() && <Panel />}
            <Switch>
              <Route path={match.url} exact render={() => <Redirect to="/main/campaigns" />} />
              <Route path={`${match.url}/campaigns`} exact component={Campaigns} />
              <Route path={`${match.url}/campaigns/new-campaign`} exact component={AddCampaign} />
              <Route path={`${match.url}/campaign/:id`} exact component={Campaign} />

              <Route path={`${match.url}/users`} exact component={Users} />
              <Route path={`${match.url}/users/invite-user`} exact component={InviteUser} />

              <Route path={`${match.url}/products`} exact component={Products} />
              <Route path={`${match.url}/products/new-product`} exact component={CreateProduct} />
              <Route
                path={`${match.url}/products/edit-product/:id`}
                exact
                component={EditProduct}
              />
              <Route
                path={`${match.url}/products/view-product/:id`}
                exact
                component={ViewProduct}
              />

              {/*<Route path={`${match.url}/templates`} exact component={Templates} />*/}
              <Route path={`${match.url}/audience`} exact component={AudienceList} />
              <Route
                path={`${match.url}/audience/csv-based/create`}
                exact
                component={CreateCSVBasedAudience}
              />
              <Route
                path={`${match.url}/audience/csv-based/edit/:id`}
                exact
                component={EditCSVBasedAudience}
              />
              <Route
                path={`${match.url}/audience/csv-based/view/:id`}
                exact
                component={ViewCSVBasedAudience}
              />
              <Route
                path={`${match.url}/audience/custom/create`}
                exact
                component={CreateCustomAudience}
              />
              <Route
                path={`${match.url}/audience/custom/edit/:id`}
                exact
                component={EditCustomAudience}
              />
              <Route
                path={`${match.url}/audience/analytics-based/edit/:id`}
                exact
                component={EditAnalyticsBasedAudience}
              />

              <Route path={`${match.url}/profile`} exact component={Profile} />
              <Route path={`${match.url}/new-company`} exact component={CreateCompany} />
              <Route
                path={`${match.url}/companies/view-company/:id`}
                exact
                component={ViewCompany}
              />
              <Route path={`${match.url}/company/:id`} exact component={EditCompany} />

              <Route path={`${match.url}/add-new-data-source`} component={AddNewDataSource} />
              <Route
                path={`${match.url}/add-magento-data-source`}
                component={AddMagentoDataSource}
              />
              <Route path={`${match.url}/edit-data-source/:id`} exact component={EditDataSource} />
              <Route path={`${match.url}/add-products-via-api`} exact component={AddProducts} />

              <Route path={`${match.url}/deliveries`} exact component={Deliveries} />

              <Route
                path={`${match.url}/company-api-access/:id`}
                exact
                component={CompanyApiAccess}
              />

              <Route
                path={`${match.url}/create-company-profile`}
                exact
                component={CreateCompanyProfile}
              />

              <Route path={`${match.url}/billing-reports`} exact component={BillingReports} />

              <Route path={`${match.url}/support`} component={Support} />

              <Route
                render={() => (
                  <div className="not_found">
                    <img src={NotFoundImage} alt="not found" />
                  </div>
                )}
              />
            </Switch>
          </div>
          <Snack
            open={errorSnack}
            handleSnack={closeErrorSnack}
            message={errorSnackText.includes("t:") ? t(errorSnackText) : errorSnackText}
            type="error"
          />
          <Snack
            open={successSnack}
            handleSnack={closeSuccessSnack}
            message={t(`t:snack-messages.${successSnackText}`)}
          />
          {accessError && (
            <DialogComponent open={accessError} onClose={this.resetError}>
              <div className="delete_dialog">
                <div className="sorry_word">{t("t:common.sorry")}</div>
                <div className="dialog_title text_centered">
                  {t("t:common.content-is-not-available")}
                </div>
                <DefaultButton variant="contained" classes="ok_btn" onClick={this.resetError}>
                  {t("t:common.ok")}
                </DefaultButton>
              </div>
            </DialogComponent>
          )}
          {balanceNotEnoughDialog && (
            <DialogComponent open onClose={() => this.setState({ balanceNotEnoughDialog: false })}>
              <div className="delete_dialog">
                <div className="sorry_word">{t("t:common.sorry")}</div>
                <div className="dialog_title text_centered">
                  {t("t:balance.balance-is-not-enough")}
                </div>
                <DefaultButton
                  variant="contained"
                  classes="ok_btn"
                  onClick={() => this.setState({ balanceNotEnoughDialog: false })}
                >
                  {t("t:common.ok")}
                </DefaultButton>
              </div>
            </DialogComponent>
          )}
          {openBalanceDialog && (
            <DialogComponent open onClose={() => handleBalanceDialog(false)} closeIcon>
              <div className="default_dialog_wrapper">
                <div className="dialog_title">
                  {typeOfBalanceDialog === "deposit"
                    ? t("t:balance.deposit")
                    : typeOfBalanceDialog === "get_invoice"
                    ? t("t:balance.get-invoice")
                    : t("t:balance.request-money")}
                </div>
                <div className="dialog_description">
                  {typeOfBalanceDialog === "deposit"
                    ? t("t:balance.enter-amount-to-deposit")
                    : t("t:balance.enter-amount-to-get")}
                </div>
                <form
                  onSubmit={handleSubmit(this.handleBalanceAction)}
                  className="dialog_form get_invoice_form"
                >
                  <Field
                    name="amount"
                    type="number"
                    component={RenderField}
                    label={`${t("t:balance.amount")} *`}
                    disabled={!!prefilledValue}
                  />
                  <div className="buttons_wrapper">
                    <DefaultButton
                      variant="contained"
                      classes="purple_btn"
                      formAction
                      disabled={submitting || pristine || !valid}
                      loading={loadingComponent}
                    >
                      {!!prefilledValue ? t("t:audience.accept") : t("t:common.submit")}
                    </DefaultButton>
                    <DefaultButton
                      variant="contained"
                      classes="cancel_btn"
                      onClick={() => handleBalanceDialog(false)}
                    >
                      {t("t:common.cancel")}
                    </DefaultButton>
                  </div>
                </form>
              </div>
            </DialogComponent>
          )}
          {invoiceErrorDialog && (
            <DialogComponent open onClose={() => this.setState({ invoiceErrorDialog: false })}>
              <div className="delete_dialog">
                <div className="sorry_word">{t("t:common.sorry")}</div>
                <div className="dialog_title text_centered">
                  {t("t:balance.your-previous-money-request-error")}
                </div>
                <DefaultButton
                  variant="contained"
                  classes="ok_btn"
                  onClick={() => this.setState({ invoiceErrorDialog: false })}
                >
                  {t("t:common.ok")}
                </DefaultButton>
              </div>
            </DialogComponent>
          )}
          <ToastContainer />
        </div>
      </Fragment>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.amount) {
    errors.amount = t("t:common.field-is-required");
  }
  return errors;
};

Container = reduxForm({
  form: "GetInvoiceForm",
  validate,
  enableReinitialize: true
})(Container);

const mapStateToProps = ({ auth, dashboard, balance }) => {
  return {
    user_info: auth.user_info,
    loadingComponent: dashboard.loadingComponent,
    errorSnack: dashboard.errorSnack,
    errorSnackText: dashboard.errorSnackText,
    successSnack: dashboard.successSnack,
    successSnackText: dashboard.successSnackText,
    accessError: dashboard.accessError,
    openBalanceDialog: balance.openBalanceDialog,
    typeOfBalanceDialog: balance.typeOfBalanceDialog,
    prefilledValue: balance.prefilledValue,
    requestCampaignId: balance.requestCampaignId
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProfile,
      getGlobalParameters,
      closeErrorSnack,
      closeSuccessSnack,
      setAccessError,
      handleBalanceDialog,
      postGetInvoice,
      postDeposit,
      postRequestMoney
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Container));
