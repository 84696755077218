import _omit from "lodash/omit";
import { AUDIENCE, CAMPAIGN, STEPS } from "../constants";
import { handlePublishValidationError } from "../helpers/functions";

const INITIAL_STATE = {
  campaignInfo: {},
  campaignMobilePreview: {},
  stepResults: {},
  campaignSteps: [],
  pictures: [],
  campaignViewers: {},
  interests: [],
  audience: [],
  distribution: [],
  analytics: [],
  cost: null,
  deliveries: {
    results: Array.from({ length: 10 }, (v, k) => ({ id: k, user: {} }))
  },

  blockActions: false,
  campaignError: "",
  audienceError: {},
  distributionError: {},
  publishValidationError: {},
  potentialReach: 0,

  currentStepInfo: {
    step: "not-selected"
  },

  editorRulesAreChanging: false
};

const reduceCampaign = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CAMPAIGN.GET_INFO_SUCCESS:
    case CAMPAIGN.POST_PUBLISH_SUCCESS:
    case CAMPAIGN.POST_STOP_CAMPAIGN_SUCCESS:
      return { ...state, campaignInfo: action.payload.data };
    case CAMPAIGN.GET_MOBILE_PREVIEW_SUCCESS:
      return { ...state, campaignMobilePreview: action.payload.data };
    case CAMPAIGN.GET_CAMPAIGN_STEP_RESULTS_SUCCESS:
      return { ...state, stepResults: action.payload.data };
    case CAMPAIGN.POST_EDITOR_SUCCESS:
      return {
        ...state,
        campaignViewers: action.payload.data,
        editorRulesAreChanging: false
      };
    case CAMPAIGN.DELETE_EDITOR_SUCCESS:
      return { ...state };
    case CAMPAIGN.POST_VIEWER_SUCCESS:
      return {
        ...state,
        campaignViewers: action.payload.data,
        editorRulesAreChanging: false
      };
    case CAMPAIGN.GET_INTERESTS_SUCCESS:
      return { ...state, interests: action.payload.data };
    case CAMPAIGN.GET_AUDIENCE_SUCCESS:
      return { ...state, audience: action.payload.data };
    case CAMPAIGN.GET_POTENTIAL_REACH_SUCCESS:
      return { ...state, potentialReach: action.payload.data.potential_reach };
    case AUDIENCE.RECALCULATE_CUSTOM_AUDIENCE_BY_ID_SUCCESS:
      return { ...state, potentialReach: action.payload.data.total_contacts };
    case CAMPAIGN.GET_DISTRIBUTION_SUCCESS:
      return { ...state, distribution: action.payload.data };
    case CAMPAIGN.GET_COST_SUCCESS:
      return { ...state, cost: action.payload.data };
    case CAMPAIGN.GET_ANALYTICS_SUCCESS:
      return { ...state, analytics: action.payload.data };
    case CAMPAIGN.GET_DELIVERIES_SUCCESS:
      return { ...state, deliveries: action.payload.data };
    case CAMPAIGN.PATCH_DELIVERY_INFO_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_DELIVER_TO_PICKUP_STATUS_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_READY_FOR_PICKUP_STATUS_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_NEEDS_DELIVERY_STATUS_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_ON_DELIVERY_STATUS_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_DONE_STATUS_SUCCESS:
    case CAMPAIGN.POST_DELIVERY_REDEEMED_STATUS_SUCCESS:
      let tempResults = [...state.deliveries.results];
      tempResults = tempResults.map(el =>
        el.id === action.payload.data.id ? action.payload.data : el
      );
      return { ...state, deliveries: { ...state.deliveries, results: tempResults } };

    case STEPS.GET_CAMPAIGN_STEPS_SUCCESS:
    case STEPS.POST_ONE_SUCCESS:
    case STEPS.POST_CAMPAIGN_STEPS_SUCCESS:
    case STEPS.DELETE_ONE_SUCCESS:
      return { ...state, campaignSteps: action.payload.data };

    case STEPS.PATCH_URL_FAIL:
    case STEPS.PATCH_VIDEO_FAIL:
    case STEPS.PATCH_CHECK_TEXT_FAIL:
    case STEPS.PATCH_SHORT_TEXT_FAIL:
    case STEPS.PATCH_PHOTO_FAIL:
    case STEPS.PATCH_IMAGE_FAIL:
    case STEPS.PATCH_LOCATION_FAIL:
    case STEPS.PATCH_RATING_FAIL:
    case STEPS.PATCH_YES_NO_FAIL:
    case STEPS.PATCH_MULTIPLE_FAIL:
    case STEPS.PATCH_QUIZ_FAIL:
    case STEPS.PATCH_PICTURES_FAIL:
    case STEPS.PATCH_NEW_BARCODE_FAIL:
    case STEPS.PATCH_NEW_QR_FAIL:
    case STEPS.PATCH_SCAN_QR_FAIL:
    case STEPS.PATCH_MONETARY_FAIL:
    case STEPS.PATCH_DISCOUNT_FAIL:
    case STEPS.PATCH_NOVELTY_FAIL:
      return { ...state, campaignError: action.error.response.data };
    case STEPS.PATCH_BONUS_FAIL:
      if (action.error.response.data.gift_sizes && action.error.response.data.product_sizes) {
        return {
          ...state,
          campaignError: _omit(action.error.response.data, ["gift_sizes"])
        };
      }
      return { ...state, campaignError: action.error.response.data };

    case CAMPAIGN.PATCH_AUDIENCE_FAIL:
      return { ...state, audienceError: action.error.response.data };
    case CAMPAIGN.PATCH_DISTRIBUTION_FAIL:
      return { ...state, distributionError: action.error.response.data };

    case STEPS.POST_PICTURE_SUCCESS:
      return { ...state, pictures: [...state.pictures, action.payload.data] };
    case STEPS.REWRITE_PICTURES:
      return { ...state, pictures: action.payload };
    case STEPS.DELETE_PICTURE:
      let pictures = [...state.pictures];
      pictures.splice(action.payload, 1);
      return { ...state, pictures };

    case CAMPAIGN.BLOCK_OTHER_ACTIONS:
      return {
        ...state, //when one of steps is editing, others should not be accessible
        blockActions: action.payload
      };

    case CAMPAIGN.POST_PUBLISH_FAIL:
      return {
        ...state,
        publishValidationError: handlePublishValidationError(action.error.response.data)
      };
    case CAMPAIGN.FILTER_PUBLISH_VALIDATION_ERROR:
      let tempPublishValidationError = { ...state.publishValidationError };
      delete tempPublishValidationError[action.errorToDelete];
      return {
        ...state,
        publishValidationError: tempPublishValidationError
      };

    case CAMPAIGN.SET_ERROR:
      return {
        ...state,
        campaignError: action.error
      };

    case CAMPAIGN.SET_DISTRIBUTION_ERROR:
      return {
        ...state,
        distributionError: action.error
      };
    case CAMPAIGN.SET_AUDIENCE_ERROR:
      return {
        ...state,
        audienceError: action.error
      };
    case CAMPAIGN.SET_EDITOR_RULES_ARE_CHANGING:
      return {
        ...state,
        editorRulesAreChanging: true
      };
    case CAMPAIGN.RESET_ERRORS:
      return {
        ...state,
        campaignError: "",
        audienceError: {},
        distributionError: {}
      };

    case CAMPAIGN.RESET_CAMPAIGN:
      return INITIAL_STATE;

    case CAMPAIGN.SET_CURRENT_STEP_INFO:
      return {
        ...state,
        currentStepInfo: action.currentStepInfo
      };

    default:
      return state;
  }
};

export default reduceCampaign;
