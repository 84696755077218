import React from "react";
import { useTranslation } from "react-i18next";
import { Image as CloudinaryImage } from "cloudinary-react";

import Dropzone from "../../../../../HelperComponents/DropzoneComponent/DropzoneComponent";
import Snack from "../../../../../HelperComponents/Snack/Snack";
import DialogComponent from "../../../../../HelperComponents/DialogComponent/DialogComponent";
import CropperComponent from "../../../../../HelperComponents/CropperComponent";
import TooltipMessage from "../../../../../HelperComponents/TooltipMessage/TooltipMessage";
import CharactersLeft from "../../../../../HelperComponents/CharactersLeft";
import { renderStepPlaceholder } from "../../../../../../helpers/functions";

import instant from "../../../../../../assets/image/My_Campaign/instant.svg";
import cancel from "../../../../../../assets/image/My_Campaign/btn-cancel.svg";
import trash from "../../../../../../assets/image/My_Campaign/btn-trash.svg";
import save from "../../../../../../assets/image/My_Campaign/btn-save.svg";
import Shape from "../../../../../../assets/image/My_Campaign/Shape.svg";
import ErrorIcon from "../../../../../../assets/image/c-warning-e.svg";
import eye from "../../../../../../assets/image/My_Campaign/eye.svg";
import del from "../../../../../../assets/image/My_Campaign/del.svg";

const PhotoExpandedView = ({
  type,
  detail,
  toClosePopper,
  title,
  description,
  image,
  imagePreview,
  previewDialogOpen,
  openErrorSnack,
  openNoImageSnack,
  imageToCrop,
  isEditor,
  campaignError,
  deleteStep,
  changeStep,
  onTitleChange,
  changeState,
  handleFiles,
  onFinishCroppingImage,
  onCancelCropping
}) => {
  const { t } = useTranslation();
  return (
    <div className="block_wrapper">
      <div className="head_block">
        <div className="title_head">
          <div>
            <img src={instant} alt="hyperlink" />
          </div>
          <div>
            <p>{t("t:build-campaign.photo")}</p>
            <span>{renderStepPlaceholder(detail.title, type, t)}</span>
          </div>
        </div>
        {isEditor ? (
          <div className="btn_head">
            <button onClick={deleteStep}>
              <img src={cancel} alt="cancel" />
            </button>
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
            <button onClick={changeStep}>
              <img src={save} alt="save" />
            </button>
          </div>
        ) : (
          <div className="btn_head">
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
          </div>
        )}
      </div>
      <div className="input_title_block_my_campaign no-padding-bottom">
        <div>
          <span>{`${t("t:common.title")} *`}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-photo-title")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <input
            disabled={!isEditor}
            type="text"
            placeholder={renderStepPlaceholder(detail.title, type, t)}
            value={title}
            onChange={e => onTitleChange(e.target.value)}
          />
          {campaignError.title ? (
            <TooltipMessage
              text={campaignError.title[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
        <CharactersLeft title={title} maxCharacters={50} />
      </div>
      <div className="textarea_description_block_my_campaign">
        <div>
          <span>{t("t:common.description")}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-photo-description")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <textarea
            disabled={!isEditor}
            value={description}
            onChange={e => changeState({ description: e.target.value })}
          />
          {campaignError.description ? (
            <TooltipMessage
              text={campaignError.description[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="images_add">
        <div className="title">
          <span>{`${t("t:common.image")} *`}</span>
          <TooltipMessage
            text={
              <p>
                {t("t:helper-texts.build-photo-image")} <br /> {t("t:helper-texts.image")} <br />
                {t("t:helper-texts.aspect-ratio-and-min-res", {
                  aspect_ratio: "16:10",
                  min_resolution: "1280x800"
                })}
              </p>
            }
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        {image && (
          <div className="drag_img">
            <p>
              {imagePreview ? (
                <img src={imagePreview} alt="upload" />
              ) : (
                <CloudinaryImage
                  cloudName="campy"
                  width="25"
                  height="25"
                  gravity="face"
                  crop="thumb"
                  publicId={image.split("/").pop()}
                />
              )}
            </p>
            <div>
              {image.name ||
                image
                  .split("/")
                  .pop()
                  .slice(0, 49)}
            </div>
            <button onClick={() => changeState({ previewDialogOpen: true })}>
              <img src={eye} alt="eye" />
            </button>
            {isEditor && (
              <button
                onClick={() =>
                  changeState({
                    image: null,
                    imagePreview: null,
                    blobToDownload: null
                  })
                }
              >
                <img src={del} alt="del" />
              </button>
            )}
          </div>
        )}
        {isEditor && (
          <Dropzone
            placeholder={t("t:common.drop-your-image-here")}
            multiple={false}
            handleFiles={handleFiles}
          />
        )}
      </div>
      <Snack
        open={openErrorSnack}
        handleSnack={() => changeState({ openErrorSnack: false })}
        message={t("t:snack-messages.file-type-fail")}
        type="error"
      />
      <Snack
        open={openNoImageSnack}
        handleSnack={() => changeState({ openNoImageSnack: false })}
        message={t("t:snack-messages.image-step-error")}
        type="error"
      />
      <DialogComponent
        open={previewDialogOpen}
        onClose={() => changeState({ previewDialogOpen: false })}
        closeIcon
        classes="image_dialog"
        rootClass="image_root_dialog"
      >
        <div>
          <img src={imagePreview ? imagePreview : image} alt="preview" />
        </div>
      </DialogComponent>
      {imageToCrop && (
        <CropperComponent
          aspectRatio={1.6}
          imageToCrop={imageToCrop}
          onFinishCropping={onFinishCroppingImage}
          onCancelCropping={onCancelCropping}
        />
      )}
    </div>
  );
};

export default PhotoExpandedView;
