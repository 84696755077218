import React, { Component } from "react";
import { connect } from "react-redux";

import StepZero from "./Steps/StepZero";
import StepOne from "./Steps/StepOne";

import {
  getDataSourcesList,
  getProducts,
  resetDataSources
} from "../../../actions/dataSourcesActions";

import "./AddProducts.scss";

class AddProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsRequested: false,
      previousParams: {},
      dataSource: { value: null, options: [] },
      params: { option: "all_products", search: "", id: "" }
    };
  }

  componentDidMount() {
    const { dataSources, getDataSourcesList } = this.props;
    if (!dataSources.length) {
      getDataSourcesList();
    }
  }

  componentWillUnmount() {
    this.props.resetDataSources();
  }

  fetchProducts = async (dataSourceId, params) => {
    const { getProducts } = this.props;
    const { option, search, id } = params;
    let data = ["page=1"];
    if (option === "search_by_keyword") {
      data.push(`search=${search}`);
    } else if (option === "product_by_id") {
      data.push(`product_id=${id}`);
    }
    await getProducts(dataSourceId, data).then(() => {
      this.resetRequest();
      this.setState({ previousParams: params, dataSourceId });
    });
  };

  setDataSource = dataSource => this.setState({ dataSource });

  setParams = params => this.setState({ params });

  resetRequest = () =>
    this.setState(({ productsRequested }) => ({ productsRequested: !productsRequested }));

  render() {
    const { history } = this.props;
    const { productsRequested, previousParams, dataSourceId, dataSource, params } = this.state;
    return (
      <div className="add_products_via_api_container">
        {!productsRequested ? (
          <StepZero
            fetchProducts={this.fetchProducts}
            dataSource={dataSource}
            setDataSource={this.setDataSource}
            params={params}
            setParams={this.setParams}
          />
        ) : (
          <StepOne
            goToPreviousStep={this.resetRequest}
            previousParams={previousParams}
            history={history}
            dataSourceId={dataSourceId}
            searchByID={!!params.id}
            defaultSearchValue={params.search}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ dataSources }) => {
  return {
    dataSources: dataSources.dataSourcesList
  };
};

const mapDispatchToProps = {
  getDataSourcesList,
  getProducts,
  resetDataSources
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProducts);
