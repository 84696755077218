import { getOption } from "../../helpers/functions";
import React from "react";

const deliveryTypesForSelector = t => [
  { label: getOption(t("t:deliveries.all-deliveries")), value: "" },
  { label: getOption(t("t:deliveries.redeemable")), value: "redeemable_any" },
  { label: getOption(t("t:deliveries.pickup")), value: "pickup" },
  { label: getOption(t("t:deliveries.parcel-machine")), value: "parcel_machine" },
  { label: getOption(t("t:deliveries.courier")), value: "courier" }
];

const deliveryStatusesForSelector = t => [
  { label: getOption(t("t:deliveries.all-deliveries")), value: "" },
  { label: getOption(t("t:deliveries.unredeemed")), value: "unredeemed" },
  { label: getOption(t("t:deliveries.redeemed")), value: "redeemed" },
  { label: getOption(t("t:deliveries.delivery-confirmation")), value: "delivery_confirmation" },
  { label: getOption(t("t:deliveries.deliver-to-pickup")), value: "deliver_to_pickup" },
  { label: getOption(t("t:deliveries.ready-for-pickup")), value: "ready_for_pickup" },
  { label: getOption(t("t:deliveries.needs-delivery")), value: "needs_delivery" },
  { label: getOption(t("t:deliveries.on-delivery")), value: "on_delivery" },
  { label: getOption(t("t:deliveries.done")), value: "done" }
];

const renderStatusName = (status, t) => {
  switch (status) {
    case "unredeemed":
      return t("t:deliveries.unredeemed");
    case "redeemed":
      return t("t:deliveries.redeemed");
    case "delivery_confirmation":
      return t("t:deliveries.delivery-confirmation");
    case "deliver_to_pickup":
      return t("t:deliveries.deliver-to-pickup");
    case "needs_delivery":
      return t("t:deliveries.needs-delivery");
    case "ready_for_pickup":
      return t("t:deliveries.ready-for-pickup");
    case "on_delivery":
      return t("t:deliveries.on-delivery");
    case "done":
      return t("t:deliveries.done");
    default:
      return <p>—</p>;
  }
};

const deliveryType = (type, t) => {
  switch (type) {
    case "redeemable_any":
      return t("t:distribution.redeemable-any");
    case "pickup":
      return t("t:deliveries.pickup");
    case "courier":
      return t("t:deliveries.courier");
    case "parcel_machine":
      return t("t:deliveries.parcel-machine");
    default:
      return type;
  }
};

export { deliveryTypesForSelector, deliveryStatusesForSelector, renderStatusName, deliveryType };
