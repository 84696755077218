import React, { Component } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { postSignUp, resetAuthError } from "../../../actions/authActions";

import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import AuthButtons from "../AuthButtons/AuthButtons";
import LanguageSwitcher from "../../HelperComponents/LanguageSwitcher";

import ErrorIcon from "../../../assets/image/c-warning-e.svg";

class SignUp extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    this.props.resetAuthError();
  }

  submitForm = data => {
    const { postSignUp, history } = this.props;
    this.setState({ loading: true });
    return postSignUp(data).then(res => {
      if (res.payload && res.payload.status && res.payload.status === 201) {
        localStorage.setItem("email", data.email);
        history.push("/auth/confirm-you-email");
      } else {
        this.setState({ loading: false });
        if (res.error.response.data.email) {
          throw new SubmissionError({ email: res.error.response.data.email[0].message });
        } else if (res.error.response.data.password) {
          throw new SubmissionError({
            password: res.error.response.data.password[0].message
          });
        } else if (res.error.response.data.first_name) {
          throw new SubmissionError({
            first_name: res.error.response.data.first_name[0].message
          });
        } else if (res.error.response.data.last_name) {
          throw new SubmissionError({
            last_name: res.error.response.data.last_name[0].message
          });
        }
      }
    });
  };

  render() {
    const { handleSubmit, submitting, pristine, valid, authError, t } = this.props;
    const { loading } = this.state;

    return (
      <div className="sign_in_wrapper">
        <div>
          <div className="auth_redirect with-lng-switcher">
            <LanguageSwitcher />
            <div>
              {t("t:auth.already-member")}
              <Link to="/auth/login" className="text_hover">
                {t("t:auth.sign-in")}
              </Link>
            </div>
          </div>
          <div className="auth_block_head">{t("t:auth.create-company-account")}</div>
          <AuthButtons />
          <div className="or_separator">
            <hr />
            <span>{t("t:common.or")}</span>
          </div>
          <form onSubmit={handleSubmit(this.submitForm)}>
            <Field
              name="email"
              type="text"
              component={RenderField}
              label={t("t:common.email")}
              placeholder={t("t:auth.enter-your-email")}
            />
            <Field
              name="first_name"
              type="text"
              component={RenderField}
              label={t("t:common.first-name")}
              placeholder={t("t:auth.enter-your-first-name")}
            />
            <Field
              name="last_name"
              type="text"
              component={RenderField}
              label={t("t:common.last-name")}
              placeholder={t("t:auth.enter-your-last-name")}
            />
            <Field
              name="password"
              type="password"
              component={RenderField}
              label={t("t:auth.choose-password")}
              placeholder={t("t:auth.min-6-characters")}
            />
            <div className="auth_btn_wrapper login_auth_buttons">
              <DefaultButton
                variant="contained"
                classes="auth_create"
                disabled={submitting || pristine || !valid}
                loading={loading}
                formAction
              >
                {t("t:auth.create-account")}
              </DefaultButton>
              {authError ? (
                <TooltipMessage text={authError} delay={200} error position="right" classes="">
                  <img src={ErrorIcon} alt="error" />
                </TooltipMessage>
              ) : (
                ""
              )}
            </div>
            <br />
          </form>
        </div>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.email) {
    errors.email = t("t:common.field-is-required");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = t("t:auth.please-enter-correct-email");
  }
  if (!values.password) {
    errors.password = t("t:common.field-is-required");
  } else if (values.password.length < 8) {
    errors.password = t("t:common.min-8-characters");
  }
  return errors;
};

SignUp = reduxForm({
  form: "SignUpForm",
  validate
})(SignUp);

const mapStateToProps = state => {
  return {
    authError: state.auth.error_auth
  };
};
const mapDispatchToProps = {
  postSignUp,
  resetAuthError
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignUp));
