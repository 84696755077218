import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import PhotoIcon from "../../../../../assets/image/CampaignPanel/instant_blue.svg";
import DownloadIcon from "../../../../../assets/image/download_icon-purple.svg";

const Photo = () => {
  const { t } = useTranslation();

  const { count, url } = useSelector(({ campaign }) => campaign.stepResults.photos);

  return (
    <div className="step-results__block">
      <img src={PhotoIcon} alt="photos" className="step-results__icon" />
      <div className="step-results__info">
        <div className="step-results__info--header">
          <div className="step-results__info--title">{t("t:build-campaign.photo")}</div>
          <a className="step-results__info--download text_hover" href={url} download>
            <img src={DownloadIcon} alt="download" className="step-results__info--download-icon" />
            <p>{t("t:build-campaign.download-all")}</p>
          </a>
        </div>
        <div className="step-results__info--total">
          {t("t:build-campaign.total")}
          &nbsp;
          <span className="step-results__info--total-number">{count}</span>
          &nbsp;
          <p className="step-results__info--total-lowercase">{t("t:build-campaign.photos")}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(Photo);
