import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { withTranslation } from "react-i18next";

import SkeletonComponent from "../../../HelperComponents/SkeletonComponent/SkeletonComponent";
import Pagination from "../../../HelperComponents/Pagination/Pagination";
import Tabs from "../../../HelperComponents/Tabs";

import {
  getCampaignSharingFBCost,
  getCampaignSharingLinkCost
} from "../../../../actions/balanceActions";

import LinkIcon from "../../../../assets/image/open-link.svg";

class Sharing extends Component {
  constructor(props) {
    super(props);
    this.tabs = [
      // { label: props.t("t:cost.via-facebook"), value: "via-fb" },
      { label: props.t("t:cost.via-native-link"), value: "via-link" }
    ];
    this.state = {
      campaignId: props.campaignId,
      loading: true,
      currentPage: null,
      // tab: "via-fb"
      tab: "via-link"
    };
  }

  componentDidMount() {
    this.fetchCampaignSharing(1).then(() => {
      this.setState({ currentPage: 1, loading: false });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.tab !== this.state.tab) {
      this.fetchCampaignSharing(1).then(() => {
        this.setState({ currentPage: 1, loading: false });
      });
    }
  }

  fetchCampaignSharing = async page => {
    const { getCampaignSharingFBCost, getCampaignSharingLinkCost } = this.props;
    const { campaignId, tab } = this.state;
    this.setState({ loading: true });
    let data = [`page=${page}`, `page_size=10`];
    if (tab === "via-fb") {
      await getCampaignSharingFBCost(campaignId, data);
    } else {
      await getCampaignSharingLinkCost(campaignId, data);
    }
  };

  renderStatus = status => {
    const { t } = this.props;
    switch (status) {
      case "initial":
        return <div className="status joined">{t("t:cost.initial")}</div>;
      case "joined":
        return <div className="status joined">{t("t:cost.joined")}</div>;
      case "started":
        return <div className="status started">{t("t:cost.started")}</div>;
      case "pending":
        return <div className="status pending">{t("t:cost.pending")}</div>;
      case "finished":
        return <div className="status finished">{t("t:cost.finished")}</div>;
      case "paid":
        return <div className="status paid">{t("t:cost.paid")}</div>;
      default:
        return <div className="status">Unknown status</div>;
    }
  };

  render() {
    const { fbSharing, linkSharing, t } = this.props;
    const { currentPage, loading, tab } = this.state;
    const isFB = tab === "via-fb";
    const pageCount = isFB ? fbSharing.count : linkSharing.count;
    return (
      <div className="cost_block">
        <div className="cost_block_title">{t("t:cost.sharing")}</div>
        <div className="sharing-wrapper">
          <Tabs currentTab={tab} changeTab={tab => this.setState({ tab })} tabs={this.tabs} />
          {isFB ? (
            <div className="table_container">
              {fbSharing.results.length ? (
                <>
                  <div className="table_header fb_table_header">
                    <div className="table_row">
                      <div className="row_item">{t("t:cost.posted")}</div>
                      <div className="row_item">{t("t:cost.post-must-be-available")}</div>
                      <div className="row_item">{t("t:cost.link")}</div>
                      <div className="row_item">{t("t:common.status")}</div>
                      <div className="row_item">{t("t:cost.reward")}</div>
                    </div>
                  </div>
                  <div className="table_body fb_table_body">
                    {fbSharing.results.map(
                      ({ created_at, facebook_share_days, fb_url, status, reward }, key) => (
                        <div className="table_row" key={`fb_sharing_${key}`}>
                          <div className="row_item">
                            <SkeletonComponent variant="text" loading={loading} width={130}>
                              <p className="used_at">
                                {moment(created_at).format("DD.MM.YYYY HH:mm")}
                              </p>
                            </SkeletonComponent>
                          </div>
                          <div className="row_item">
                            <SkeletonComponent variant="text" loading={loading} width={120}>
                              <p className="post-must-be-available">
                                <span>{`${facebook_share_days} ${t("t:common.days")} `}</span>
                                {t("t:common.or-more")}
                              </p>
                            </SkeletonComponent>
                          </div>
                          <div className="row_item">
                            <SkeletonComponent variant="text" loading={loading} width={100}>
                              <a
                                href={fb_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="open-post"
                              >
                                {t("t:cost.open-post")}
                                <img src={LinkIcon} alt="->" />
                              </a>
                            </SkeletonComponent>
                          </div>
                          <div className="row_item">
                            <SkeletonComponent variant="text" loading={loading} width={50}>
                              {this.renderStatus(status)}
                            </SkeletonComponent>
                          </div>
                          <div className="row_item">
                            <SkeletonComponent variant="text" loading={loading} width={50}>
                              <span className="reward">{`${reward} €`}</span>
                            </SkeletonComponent>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              ) : (
                t("t:common.nothing-to-show")
              )}
            </div>
          ) : (
            <div className="table_container">
              {linkSharing.results.length ? (
                <>
                  <div className="table_header link_table_header">
                    <div className="table_row">
                      <div className="row_item">{t("t:cost.used")}</div>
                      <div className="row_item">{t("t:common.status")}</div>
                      <div className="row_item">{t("t:cost.reward")}</div>
                    </div>
                  </div>
                  <div className="table_body link_table_body">
                    {linkSharing.results.map(({ created_at, status, reward }, key) => (
                      <div className="table_row" key={`fb_sharing_${key}`}>
                        <div className="row_item">
                          <SkeletonComponent variant="text" loading={loading} width={130}>
                            <p className="used_at">
                              {moment(created_at).format("DD.MM.YYYY HH:mm")}
                            </p>
                          </SkeletonComponent>
                        </div>
                        <div className="row_item">
                          <SkeletonComponent variant="text" loading={loading} width={100}>
                            {this.renderStatus(status)}
                          </SkeletonComponent>
                        </div>
                        <div className="row_item">
                          <SkeletonComponent variant="text" loading={loading} width={50}>
                            <span className="reward">{`${reward} €`}</span>
                          </SkeletonComponent>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                t("t:common.nothing-to-show")
              )}
            </div>
          )}
          <div className="pagination_wrapper">
            {pageCount > 10 && (
              <Pagination
                active={currentPage}
                pageCount={Math.ceil(pageCount / 10)}
                onChange={e => {
                  this.fetchCampaignSharing(e.selected + 1).then(() => {
                    this.setState({ currentPage: e.selected + 1 });
                  });
                }}
                t={t}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ balance }) {
  return {
    fbSharing: balance.fbSharing,
    linkSharing: balance.linkSharing
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCampaignSharingFBCost,
      getCampaignSharingLinkCost
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Sharing));
