import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AnalyticsPanel from "./Blocks/AnalyticsPanel";
import Loader from "../../HelperComponents/Loader";
import { parseChartData } from "./helpers";
import { getAnalytics } from "../../../actions/campaignActions";
import { periodList } from "./constants";
import AnalyticsChart from "./Blocks/AnalyticsChart";
import Totals from "./Blocks/Totals";
import AnalyticsDetails from "./Blocks/AnalyticsDetails";
import AnalyticsHeader from "./Blocks/Header";

import "./styles.scss";

const Analytics = ({ campaignId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const analytics = useSelector(({ campaign }) => campaign.analytics);
  const [loading, setLoading] = useState(true);
  const [periodValue, setPeriodValue] = useState(periodList(t)[1]);

  const fetchAnalytics = () => {
    dispatch(getAnalytics(campaignId, periodValue.value)).then(res => {
      if (res && res.payload) setLoading(false);
    });
  };

  useEffect(
    () => fetchAnalytics(),
    // eslint-disable-next-line
    [periodValue.value]
  );

  const onPeriodChange = event => setPeriodValue(event);

  return (
    <div className="analytics_wrapper">
      <AnalyticsPanel />
      <div className="analytics_container">
        <AnalyticsHeader periodValue={periodValue} onPeriodChange={onPeriodChange} />
        {loading ? (
          <Loader />
        ) : (
          <>
            <AnalyticsChart {...analytics} chartData={parseChartData(analytics.main_chart_data)} />
            <AnalyticsDetails periodValue={periodValue} {...analytics} />
            <Totals {...analytics.bottom_blocks} />
          </>
        )}
      </div>
    </div>
  );
};

export default memo(Analytics);
