import React, { useRef, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import ReactTags from "react-tag-autocomplete";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import clsx from "clsx";

import "./styles.scss";

const TagsComponent = ({ tags, changeTags, getSuggestions, addNewHashtag, disabled }) => {
  const reactTags = useRef(null);
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();

  const onInput = e => {
    if (e.length >= 2) {
      getSuggestions(e).then(({ payload }) => {
        setSuggestions(payload.data);
      });
    }
  };

  const debouncedOnInput = useMemo(
    () => _.debounce(onInput, 300),
    // eslint-disable-next-line
    []
  );

  const onDelete = i => {
    const tempTags = tags.slice(0);
    tempTags.splice(i, 1);
    changeTags(tempTags);
  };

  const onAddition = async tag => {
    if (tags.length < 15) {
      let tempTags;
      if (!tag.id) {
        const existingTag = suggestions.find(el => el.name === tag.name);
        if (existingTag) {
          tempTags = [].concat(tags, existingTag);
        } else {
          const res = await addNewHashtag({ name: tag.name });
          if (res.payload) {
            tempTags = [].concat(tags, res.payload.data);
          } else {
            tempTags = tags;
          }
        }
      } else {
        tempTags = [].concat(tags, tag);
      }
      changeTags(tempTags);
    } else {
      dispatch({
        type: "ERROR_SNACK_OPEN",
        payload: "t:snack-messages.max-hashtags-error"
      });
    }
  };

  return (
    <div className={clsx("tags__wrapper", disabled && "not-active")}>
      <ReactTags
        ref={reactTags.current}
        tags={tags}
        suggestions={suggestions.filter(el => !tags.some(item => item.name === el.name))}
        onDelete={onDelete}
        onAddition={onAddition}
        onInput={debouncedOnInput}
        placeholderText={t("t:common.type-something")}
        allowNew
        classNames={{
          root: "tags",
          rootFocused: "is-focused",
          selected: "tags__selected",
          selectedTag: "tags__selected-tag",
          selectedTagName: "tags__selected-tag-name",
          search: "tags__search",
          searchWrapper: "tags__search-wrapper",
          searchInput: "tags__search-input",
          suggestions: "tags__suggestions",
          suggestionActive: "is-active",
          suggestionDisabled: "is-disabled",
          suggestionPrefix: "tags__suggestion-prefix"
        }}
        inputAttributes={disabled ? { disabled: true } : {}}
      />
      <div className="tags__info">
        <p>{t("t:campaign-audience.maximum-n-hashtags", { number: 15 })}</p>
        <p>{t("t:campaign-audience.n-characters-per-hashtag", { number: 25 })}</p>
      </div>
    </div>
  );
};

export default TagsComponent;
