import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import { limitsError } from "../../helpers/errorMessages";
import { getOption, isIntNumberRegexp } from "../../../../../helpers/functions";
import SelectComponent from "../../../../HelperComponents/SelectComponent/SelectComponent";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";
import Shape from "../../../../../assets/image/My_Campaign/Shape.svg";

const Limits = ({
  distributionError,
  isEditor,
  max_participants_error,
  participants_limit,
  max_participants,
  onSetFieldsInState,
  handleSelectChange,
  timeToFinishParsed
}) => {
  const { t } = useTranslation();

  const limitsList = [
    { label: getOption(t("t:distribution.in-total")), value: "in_total" },
    { label: getOption(t("t:distribution.per-day")), value: "per_day" },
    { label: getOption(t("t:distribution.unlimited")), value: "unlimited" }
  ];

  const onMaxParticipantsChange = e => {
    if (
      e.target.value === "" ||
      isIntNumberRegexp.test(e.target.value) ||
      e.target.value.length < (max_participants || "").length
    ) {
      onSetFieldsInState({ max_participants: e.target.value });
    }
  };

  return (
    <div className="distribution_row_wrapper">
      <div className="row_name">
        {t("t:distribution.limits")}
        {distributionError.max_participants_value ||
        distributionError.time_to_finish ||
        max_participants_error ? (
          <TooltipMessage
            text={limitsError(distributionError, max_participants_error, t)}
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
      </div>
      <div className="row_content">
        <div className="input_title_block_my_campaign margin_bottom_15">
          <div>
            <span>{t("t:cost.maximum-participants")} *</span>
            <TooltipMessage
              text={t("t:helper-texts.distribution-limits-max-participants")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <div className="distribution_line">
            <input
              className={clsx(
                (!isEditor || participants_limit.value === "unlimited") && "cursor_not_allowed",
                "limit_input"
              )}
              style={{
                opacity: participants_limit.value === "unlimited" ? "0.6" : "1"
              }}
              disabled={!isEditor || participants_limit.value === "unlimited"}
              type="text"
              value={max_participants || ""}
              onChange={onMaxParticipantsChange}
            />
            <FormControl className={clsx("select_wrapper", !isEditor && "cursor_not_allowed")}>
              <SelectComponent
                disabled={!isEditor}
                value={participants_limit}
                options={limitsList.filter(el => el.value === "in_total")}
                change={handleSelectChange("participants_limit")}
                isClearable="false"
                isSearchable={false}
                placeholder={t("t:common.choose")}
              />
            </FormControl>
          </div>
        </div>
        <div className="input_title_block_my_campaign">
          <div>
            <span>{t("t:distribution.time-to-finish")} *</span>
            <TooltipMessage
              text={t("t:helper-texts.distribution-limits-time-to-finish")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <div className="time-to-finish-fields">
            <div className="price_block_input no_select">
              <input
                type="number"
                disabled={!isEditor}
                value={timeToFinishParsed.hours || ""}
                onChange={e =>
                  onSetFieldsInState({
                    timeToFinishParsed: {
                      ...timeToFinishParsed,
                      hours: e.target.value
                    }
                  })
                }
              />
              <span>{t("t:common.hour")}</span>
            </div>
            <div className="price_block_input no_select">
              <input
                type="number"
                disabled={!isEditor}
                value={timeToFinishParsed.minutes || ""}
                onChange={e =>
                  onSetFieldsInState({
                    timeToFinishParsed: {
                      ...timeToFinishParsed,
                      minutes: e.target.value
                    }
                  })
                }
              />
              <span>{t("t:common.minutes-short")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Limits;
