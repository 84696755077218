import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const { search } = useLocation();
  const queries = new URLSearchParams(search);
  const createSearchParams = obj => new URLSearchParams(obj).toString();
  const getParam = paramName => queries.get(paramName) || null;
  const removeParam = paramName => queries.delete(paramName);

  return {
    locationSearch: search,
    queries,
    getParam,
    removeParam,
    createSearchParams
  };
};

// Create a ref that we add to the element for which we want to detect outside clicks
// const ref = useRef();
// Call hook passing in the ref and a function to call on outside click
// useOnClickOutside(ref, () => {});

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { useQueryParams, useOnClickOutside, usePrevious };
