import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import GoogleMapLoader from "react-google-maps-loader";

import GoogleSuggest from "../../../../HelperComponents/GoogleSuggest/GoogleSuggest";
import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import GoogleMaps from "../../../../HelperComponents/GoogleMaps/GoogleMaps";
import { GOOGLE_API_KEY } from "../../../../../config";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";
import Shape from "../../../../../assets/image/My_Campaign/Shape.svg";

const Region = ({
  audienceError,
  isEditor,
  address,
  handleLocation,
  coordinates,
  radius,
  setRadius,
  resetAddress
}) => {
  const { t } = useTranslation();
  const regexp = new RegExp("^[0-9]+$");
  return (
    <GoogleMapLoader
      params={{
        key: GOOGLE_API_KEY,
        libraries: "places, geocode, maps",
        language: "en"
      }}
      render={googleMaps =>
        googleMaps && (
          <div className="audience_row_wrapper">
            <div className="row_name">
              {t("t:campaign-audience.region")}
              {audienceError.location_radius ? (
                <TooltipMessage
                  text={audienceError.location_radius[0].message}
                  delay={200}
                  error
                  position="top"
                  classes=""
                >
                  <img src={ErrorIcon} alt="error" />
                </TooltipMessage>
              ) : (
                ""
              )}
            </div>
            <div className="row_content">
              <div className="address_info_map">
                <div className="input_title_block_my_campaign">
                  <div>
                    <span>{t("t:common.address")}</span>
                    <TooltipMessage
                      text={t("t:helper-texts.audience-region-address")}
                      delay={200}
                      position="top"
                      classes="step_tooltip"
                    >
                      <img src={Shape} alt="Shape" />
                    </TooltipMessage>
                  </div>
                  <GoogleSuggest
                    disabled={!isEditor}
                    address={address}
                    handleLocation={handleLocation}
                    googleMaps={googleMaps}
                    campaignError={{}}
                    placeholder=""
                    resetAddress={resetAddress}
                  />
                </div>
                <div className="price_block_mini">
                  <div className="title">
                    <span>{t("t:common.radius")}</span>
                    <TooltipMessage
                      text={t("t:helper-texts.audience-region-radius")}
                      delay={200}
                      position="top"
                      classes="step_tooltip"
                    >
                      <img src={Shape} alt="Shape" />
                    </TooltipMessage>
                  </div>
                  <div className="price_block_input">
                    <div className="step_input_wrapper">
                      <input
                        className={clsx(!isEditor && "cursor_not_allowed")}
                        disabled={!isEditor}
                        type="text"
                        value={radius || ""}
                        onChange={e => {
                          if (
                            e.target.value === "" ||
                            regexp.test(e.target.value) ||
                            e.target.value.length < radius.length
                          ) {
                            setRadius(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <span>KM</span>
                  </div>
                </div>
              </div>
              <div className="map_wrapper">
                <div>
                  <GoogleMaps
                    isMarkerShown={!!coordinates}
                    coordinates={coordinates || { lat: 59.43, lng: 24.75 }}
                    radius={radius}
                    googleMaps={googleMaps}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }
    />
  );
};

export default memo(Region);
