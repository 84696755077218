import React, { Component } from "react";
import { connect } from "react-redux";
import { Collapse } from "react-collapse";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import _ from "lodash";

import {
  postNewDataSource,
  postAddProducts,
  getProducts
} from "../../../../actions/dataSourcesActions";
import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { numberWithSpaces } from "../../../../helpers/functions";
import Pagination from "../../../HelperComponents/Pagination/Pagination";
import DialogComponent from "../../../HelperComponents/DialogComponent/DialogComponent";
import TooltipMessage from "../../../HelperComponents/TooltipMessage/TooltipMessage";

import MoreIcon from "../../../../assets/image/down-arrow-green.svg";
import BackIcon from "../../../../assets/image/small-left.svg";
import CheckedIcon from "../../../../assets/image/checkbox_checked.svg";
import UncheckedIcon from "../../../../assets/image/checkbox_unchecked.svg";
import WarningIcon from "../../../../assets/image/c-warning-e.svg";
import zoom from "../../../../assets/image/zoom.svg";

class StepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseIsOpen: false,
      chosenProducts: [],
      activePage: 1,
      successDialogOpen: false,
      searchValue: props.defaultSearchValue
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.searchValue && prevState.searchValue !== this.state.searchValue) {
      this.fetchProducts(1);
    }
  }

  debouncedOnInput = _.debounce(searchValue => this.setState({ searchValue }), 300);

  getProductsContent = () => {
    const {
      loading,
      t,
      searchByID,
      products: {
        data: { results, count }
      }
    } = this.props;
    const { chosenProducts, activePage, searchValue } = this.state;
    if (searchByID) {
      if (!results.length) return <p className="no-results">{t("t:common.no-results")}</p>;
      const { name, price, image, description } = results[0];
      return (
        <div className="products_wrapper">
          <div className="product">
            <div>
              {image ? (
                <img src={image} alt={name || ""} className="product-image" />
              ) : (
                <div className="not-valid-image">
                  <img src={WarningIcon} alt="warning" className="warning-icon" />
                </div>
              )}
              <div className="product_info">
                <div className={clsx("name", !name && "not-valid")}>
                  {name || t("t:common.no-data")}
                  {!name && <img src={WarningIcon} alt="warning" className="warning-icon" />}
                </div>
                <p className={clsx("product-description", !description && "not-valid")}>
                  {description || t("t:common.no-data")}
                  {!description && <img src={WarningIcon} alt="warning" className="warning-icon" />}
                </p>
              </div>
            </div>
            <div>
              <div className="price">
                <div className="label">{t("t:products.full-price")}</div>
                <p className={clsx("value", !price && "not-valid")}>
                  {price ? `${numberWithSpaces(price.toFixed(2))} €` : t("t:common.no-data")}
                  {!price && <img src={WarningIcon} alt="warning" className="warning-icon" />}
                </p>
              </div>
            </div>
          </div>
          <div className="btn_wrapper">
            <DefaultButton
              variant="contained"
              classes="auth"
              onClick={() => this.addProducts("all")}
              loading={loading}
            >
              {t("t:products.add-product")}
            </DefaultButton>
          </div>
        </div>
      );
    } else {
      return (
        <div className="products_wrapper">
          <div>{t("t:data-sources.choose-products-you-want-to-add")}</div>
          <div className="search-panel">
            <div className="search">
              <input
                defaultValue={searchValue}
                onChange={e => this.debouncedOnInput(e.target.value)}
                type="text"
                placeholder={t("t:products.search-products")}
              />
              <button>
                <img src={zoom} alt="zoom" />
              </button>
            </div>
            <div className="products-selected">
              {t("t:data-sources.n-products-selected", { number: chosenProducts.length })}
            </div>
          </div>
          {!results.length ? (
            <p className="no-results">{t("t:common.no-results")}</p>
          ) : (
            results.map(item => {
              const {
                id,
                name,
                price,
                image,
                description,
                url,
                is_exists: isExists,
                show_checkmark
              } = item;
              const isValid = !!show_checkmark;
              const descriptionIsTooLong =
                !!image && !!name && !!description && !!price && !show_checkmark && !isExists;
              let checkboxIcon = isExists ? CheckedIcon : UncheckedIcon;
              if (chosenProducts.some(el => el.id === id)) checkboxIcon = CheckedIcon;
              return (
                <div
                  className="product"
                  key={`product_${id}`}
                  onClick={() => url && this.openInNewTab(url)}
                >
                  <div>
                    <TooltipMessage
                      text={isExists ? t("t:data-sources.product-exists") : ""}
                      delay={200}
                      position="top"
                      classes="step_tooltip"
                      error
                    >
                      <div
                        className="checkbox-wrapper"
                        onClick={e => {
                          e.stopPropagation();
                          if (isValid && !isExists) this.handleAddProducts(item);
                        }}
                      >
                        <img
                          className={clsx(
                            "checkbox no_select",
                            isExists && "exists",
                            !isValid && "not-valid"
                          )}
                          src={checkboxIcon}
                          alt=""
                        />
                      </div>
                    </TooltipMessage>
                    {image ? (
                      <img src={image} alt={name || ""} className="product-image" />
                    ) : (
                      <div className="not-valid-image">
                        <img src={WarningIcon} alt="warning" className="warning-icon" />
                      </div>
                    )}
                    <div className="product_info">
                      <div className={clsx("name", !name && "not-valid")}>
                        {name || t("t:common.no-data")}
                        {!name && <img src={WarningIcon} alt="warning" className="warning-icon" />}
                      </div>
                      <p className={clsx("product-description", !description && "not-valid")}>
                        <span>{description || t("t:common.no-data")}</span>
                        {(!description || descriptionIsTooLong) && (
                          <TooltipMessage
                            text={
                              descriptionIsTooLong
                                ? t("t:data-sources.description-is-too-long")
                                : ""
                            }
                            delay={200}
                            error
                            position="top"
                            classes="step_tooltip"
                          >
                            <img src={WarningIcon} alt="warning" className="warning-icon" />
                          </TooltipMessage>
                        )}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="price">
                      <div className="label">{t("t:products.full-price")}</div>
                      <p className={clsx("value", !price && "not-valid")}>
                        {price ? `${numberWithSpaces(price.toFixed(2))} €` : t("t:common.no-data")}
                        {!price && <img src={WarningIcon} alt="warning" className="warning-icon" />}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          )}
          {count > 50 && (
            <div className="pagination_wrapper">
              <Pagination
                active={activePage}
                pageCount={Math.ceil(count / 50)}
                onChange={e => this.fetchProducts(e.selected + 1)}
                t={t}
              />
            </div>
          )}
          <div className="btn_wrapper">
            <DefaultButton
              variant="contained"
              classes="auth"
              disabled={!chosenProducts.length}
              onClick={() => this.addProducts("several")}
              loading={loading}
            >
              {t("t:data-sources.add-products")}
            </DefaultButton>
          </div>
        </div>
      );
    }
  };

  fetchProducts = page => {
    const { dataSourceId, getProducts } = this.props;
    const { searchValue } = this.state;
    let params = [`page=${page}`];
    if (searchValue) params.push(`search=${searchValue}`);
    getProducts(dataSourceId, params).then(() => {
      this.setState({ activePage: page });
      window.scrollTo(0, 0);
    });
  };

  handleAddProducts = itemToAdd => {
    const { chosenProducts } = this.state;
    if (chosenProducts.some(el => el.id === itemToAdd.id)) {
      this.setState({
        chosenProducts: chosenProducts.filter(el => el.id !== itemToAdd.id)
      });
    } else {
      this.setState({ chosenProducts: [...chosenProducts, itemToAdd] });
    }
  };

  addProducts = type => {
    const {
      products: { data },
      postAddProducts,
      dataSourceId
    } = this.props;
    const { chosenProducts } = this.state;
    let products;
    if (type === "all") {
      products = [...data.results];
    } else {
      products = [...chosenProducts];
    }
    postAddProducts(dataSourceId, products).then(() => this.setState({ successDialogOpen: true }));
  };

  openInNewTab = url => {
    const win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    const {
      goToPreviousStep,
      products: {
        has_errors,
        request_method,
        request_url,
        response_status_code,
        errors,
        data,
        request_headers,
        response_headers,
        text
      },
      history,
      t
    } = this.props;
    const { responseIsOpen, successDialogOpen } = this.state;
    return (
      <div>
        <div onClick={goToPreviousStep} className="text_hover back">
          <img src={BackIcon} alt="" />
          {t("t:data-sources.back-to-options")}
        </div>
        <div className="title">{t("t:data-sources.api-results")}</div>
        {!has_errors ? (
          <div className="response_wrapper success">
            <div className="success_header">
              <p>
                API request <strong>success</strong>:
              </p>
              <p
                className="show_response text_hover no_select"
                onClick={() => this.setState({ responseIsOpen: !responseIsOpen })}
              >
                {responseIsOpen ? "Hide" : "Show"} response{" "}
                <img src={MoreIcon} alt="show" className={responseIsOpen ? "reversed" : ""} />
              </p>
            </div>
            <Collapse isOpened={responseIsOpen}>
              <div className="collapsible_block">
                <br />
                <p>Request method: {request_method}</p>
                <p>Request URL: {request_url}</p>
                <p>Request headers:</p>
                {Object.entries(request_headers).map(el => (
                  <p key={`request_${el[0]}`}>
                    <strong>{el[0]}</strong>: {el[1]}
                  </p>
                ))}
                <br />
                <p>Response status: {response_status_code}</p>
                <p>Response headers:</p>
                {Object.entries(response_headers).map(el => (
                  <p key={`response_${el[0]}`}>
                    <strong>{el[0]}</strong>: {el[1]}
                  </p>
                ))}
                <p>Response body:</p>
                <p>{text}</p>
              </div>
            </Collapse>
          </div>
        ) : (
          <div className="response_wrapper fail">
            <p>
              API request <strong>failed</strong>:
            </p>
            <br />
            <p>Request method: {request_method}</p>
            <p>Request URL: {request_url}</p>
            <p>Request headers:</p>
            {Object.entries(request_headers).map(el => (
              <p key={`request_${el[0]}`}>
                <strong>{el[0]}</strong>: {el[1]}
              </p>
            ))}
            <br />
            <p>Response status: {response_status_code}</p>
            {errors && <p>Response error: {errors[0]}</p>}
            <p>Response headers:</p>
            {Object.entries(response_headers).map(el => (
              <p key={`response_${el[0]}`}>
                <strong>{el[0]}</strong>: {el[1]}
              </p>
            ))}
          </div>
        )}

        {data && !!data.results ? this.getProductsContent() : ""}

        <DialogComponent
          open={successDialogOpen}
          onClose={() => history.push("/main/products/")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:common.success")}</div>
            <div className="dialog_description">{t("t:data-sources.products-added-success")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={() => history.push("/main/products/")}
              >
                {t("t:common.ok")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard, dataSources }) => {
  return {
    loading: dashboard.loadingComponent,
    products: dataSources.products
  };
};

const mapDispatchToProps = {
  postNewDataSource,
  postAddProducts,
  getProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StepOne));
