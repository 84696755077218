import React from "react";

import DialogComponent from "../../../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";

import "./ConfirmationDialog.scss";

const ConfirmationDialog = ({
  open,
  onClose,
  confirmAction,
  declineAction,
  title,
  description,
  confirmText,
  declineText,
  buttonLoading,
  additionalContent
}) => (
  <DialogComponent open={open} onClose={onClose} closeIcon>
    <div className="confirmation-dialog">
      <h2 className="confirmation-dialog__title">{title}</h2>
      <div className="confirmation-dialog__description">{description}</div>
      {additionalContent && additionalContent()}
      <div className="confirmation-dialog__buttons-wrapper">
        <DefaultButton
          variant="contained"
          onClick={confirmAction}
          classes="auth"
          loading={buttonLoading}
        >
          {confirmText}
        </DefaultButton>
        {declineText && (
          <DefaultButton variant="contained" onClick={declineAction} classes="cancel-btn">
            {declineText}
          </DefaultButton>
        )}
      </div>
    </div>
  </DialogComponent>
);

export default ConfirmationDialog;
