import React, { Fragment, Component } from "react";
import { reduxForm, SubmissionError, Field } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import RenderField from "../HelperComponents/RenderField/RenderField";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";

import { changeName } from "../../actions/authActions";

import AppIcon from "../../assets/image/app_icon.svg";

import "./WelcomeToCampy.scss";

class WelcomeToCampy extends Component {
  state = {
    loading: false
  };

  submitForm = data => {
    const { changeName, doRequest } = this.props;
    this.setState({ loading: true });
    return changeName(data).then(res => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        doRequest();
      } else {
        this.setState({ loading: false });
        if (res.error.response.data.first_name) {
          throw new SubmissionError({
            first_name: res.error.response.data.first_name[0].message
          });
        } else if (res.error.response.data.last_name) {
          throw new SubmissionError({
            last_name: res.error.response.data.last_name[0].message
          });
        }
      }
    });
  };

  render() {
    const { handleSubmit, submitting, pristine, valid, t } = this.props;
    const { loading } = this.state;
    return (
      <Fragment>
        <main className="auth_container">
          <div className="auth-box">
            <div className="auth_bg">
              <div className="auth_bg_text_wrapper">
                <div>{t("t:auth.get-into-the-game")}</div>
                <div>{t("t:auth.campy-auth-description")}</div>
                <div>
                  <img src={AppIcon} alt="campy" />
                  <div>{t("t:auth.get-campy-app")}</div>
                </div>
              </div>
            </div>
            <div className="auth_content">
              <div className="sign_in_wrapper">
                <div>
                  <div className="auth_redirect" />
                  <div className="auth_block_head">{t("t:auth.welcome-to-campy")}</div>
                  <div className="auth_description">{t("t:auth.enter-your-name")}</div>
                  <form onSubmit={handleSubmit(this.submitForm)}>
                    <Field
                      name="first_name"
                      type="text"
                      component={RenderField}
                      label={`${t("t:common.first-name")} *`}
                    />
                    <Field
                      name="last_name"
                      type="text"
                      component={RenderField}
                      label={`${t("t:common.last-name")} *`}
                    />
                    <div className="auth_btn_wrapper forgot_pass_auth_buttons">
                      <DefaultButton
                        variant="contained"
                        classes="auth"
                        disabled={submitting || pristine || !valid}
                        loading={loading}
                        formAction
                      >
                        {t("t:common.continue")}
                      </DefaultButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = t("t:common.field-is-required");
  }
  if (!values.last_name) {
    errors.last_name = t("t:common.field-is-required");
  }
  return errors;
};

WelcomeToCampy = reduxForm({
  form: "WelcomeToCampyForm",
  validate
})(WelcomeToCampy);

const mapStateToProps = state => {
  return {
    authError: state.auth.error_auth
  };
};
const mapDispatchToProps = {
  changeName
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(WelcomeToCampy)));
