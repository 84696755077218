import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Image as CloudinaryImage } from "cloudinary-react";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import { linkShareError } from "../../helpers/errorMessages";
import Dropzone from "../../../../HelperComponents/DropzoneComponent/DropzoneComponent";
import DialogComponent from "../../../../HelperComponents/DialogComponent/DialogComponent";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";
import Shape from "../../../../../assets/image/My_Campaign/Shape.svg";
import eye from "../../../../../assets/image/My_Campaign/eye.svg";
import del from "../../../../../assets/image/My_Campaign/del.svg";

const ShareViaLink = ({
  distributionError,
  isEditor,
  link_share_is_enabled,
  link_share_title,
  link_share_image,
  link_share_imagePreview,
  link_share_reward,
  link_share_description,
  onSetFieldsInState,
  handleFiles
}) => {
  const [previewLinkDialogOpen, setPreviewLinkDialogOpen] = useState(false);
  const { t } = useTranslation();
  console.log(link_share_image);
  return (
    <div className="distribution_row_wrapper">
      <div className="row_name">
        {t("t:distribution.share-via-link")}
        {distributionError.link_share_title ||
        distributionError.link_share_image ||
        distributionError.link_share_description ||
        distributionError.link_share_reward ? (
          <TooltipMessage
            text={linkShareError(distributionError, t)}
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
      </div>
      <div className="row_content">
        <div className="item_checkbox no_select mb-10">
          <div
            onClick={() =>
              isEditor && onSetFieldsInState({ link_share_is_enabled: !link_share_is_enabled })
            }
            className={clsx(!isEditor && "cursor_not_allowed")}
          >
            <input
              type="checkbox"
              checked={link_share_is_enabled}
              onChange={() => console.log("changed")}
            />
            <label htmlFor="radio1" className={clsx(!isEditor && "cursor_not_allowed")}>
              {t("t:distribution.enable")}
            </label>
          </div>
        </div>
        <div className="input_title_block_my_campaign margin_bottom_25">
          <div>
            <span>{t("t:common.title")}</span>
            <TooltipMessage
              text={t("t:helper-texts.distribution-link-share-title")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <input
            className={clsx(!isEditor && "cursor_not_allowed")}
            disabled={!isEditor}
            type="text"
            value={link_share_title || ""}
            onChange={e => onSetFieldsInState({ link_share_title: e.target.value })}
          />
        </div>
        {!isEditor && !link_share_image ? (
          ""
        ) : (
          <div className="input_title_block_my_campaign margin_bottom_25">
            <div>
              <span>{t("t:common.image")}</span>
              <TooltipMessage
                text={
                  <p>
                    {t("t:helper-texts.distribution-link-share-image")} <br />{" "}
                    {t("t:helper-texts.image")} <br />
                    {t("t:helper-texts.aspect-ratio-and-min-res", {
                      aspect_ratio: "16:10",
                      min_resolution: "1280x800"
                    })}
                  </p>
                }
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <div className="distribution_description">
              {t("t:build-campaign.leave-share-image-empty")}
            </div>
            {link_share_image && (
              <div className="drag_img">
                <p>
                  {link_share_imagePreview ? (
                    <img src={link_share_imagePreview} alt="upload" />
                  ) : (
                    <CloudinaryImage
                      cloudName="campy"
                      width="25"
                      height="25"
                      gravity="face"
                      crop="thumb"
                      publicId={link_share_image.split("/").pop()}
                    />
                  )}
                </p>
                <div>
                  {link_share_image.name ||
                    link_share_image
                      .split("/")
                      .pop()
                      .slice(0, 49)}
                </div>
                <button onClick={() => setPreviewLinkDialogOpen(true)}>
                  <img src={eye} alt="eye" />
                </button>
                {isEditor && (
                  <button
                    onClick={() =>
                      onSetFieldsInState({
                        link_share_image: null,
                        link_share_imagePreview: null
                      })
                    }
                  >
                    <img src={del} alt="del" />
                  </button>
                )}
              </div>
            )}
            {isEditor && (
              <Dropzone
                placeholder={t("t:common.drop-your-image-here")}
                multiple={false}
                handleFiles={e => handleFiles(e, "link_share_image")}
              />
            )}
          </div>
        )}
        <div className="input_title_block_my_campaign margin_bottom_25">
          <div>
            <span>{t("t:common.description")}</span>
            <TooltipMessage
              text={t("t:helper-texts.distribution-link-share-description")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <textarea
            disabled={!isEditor}
            className={clsx(!isEditor && "cursor_not_allowed")}
            value={link_share_description || ""}
            onChange={e => onSetFieldsInState({ link_share_description: e.target.value })}
          />
        </div>
        <div className="input_title_block_my_campaign">
          <div>
            <span>{t("t:cost.reward")}</span>
            <TooltipMessage
              text={t("t:helper-texts.distribution-link-share-reward")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <div className="time-to-finish-fields">
            <div className="price_block_input no_select">
              <input
                type="number"
                disabled={!isEditor}
                value={link_share_reward || ""}
                onChange={e =>
                  onSetFieldsInState({
                    link_share_reward: e.target.value
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <DialogComponent
        open={previewLinkDialogOpen}
        onClose={() => setPreviewLinkDialogOpen(false)}
        closeIcon
        classes="image_dialog"
        rootClass="image_root_dialog"
      >
        <div>
          <img src={link_share_imagePreview || link_share_image} alt="preview" />
        </div>
      </DialogComponent>
    </div>
  );
};

export default ShareViaLink;
