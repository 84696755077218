import { CAMPAIGN, DASHBOARD } from "../constants";

const INITIAL_STATE = {
  loadingComponent: false, // for linear progress loader under the header
  errorSnack: false,
  errorSnackText: "",
  successSnack: false,
  accessError: false,
  successSnackText: ""
};

const reduceDashboard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DASHBOARD.SET_LOADING:
      return { ...state, loadingComponent: action.payload };
    case CAMPAIGN.SET_ACCESS_ERROR:
      return { ...state, accessError: action.payload };

    case DASHBOARD.ERROR_SNACK_OPEN:
      return { ...state, errorSnack: true, errorSnackText: action.payload };
    case DASHBOARD.ERROR_SNACK_CLOSE:
      return { ...state, errorSnack: false };
    case DASHBOARD.SUCCESS_SNACK_OPEN:
      return { ...state, successSnack: true, successSnackText: action.payload };
    case DASHBOARD.SUCCESS_SNACK_CLOSE:
      return { ...state, successSnack: false };
    case DASHBOARD.SNACK_TEXTS_CLEARING:
      return {
        ...state,
        errorSnackText: "",
        successSnackText: ""
      };
    default:
      return state;
  }
};

export default reduceDashboard;
