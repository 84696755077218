import { getOption } from "../../../../helpers/functions";

export const parseDistributionData = ({
  image,
  title,
  description,
  is_available_immediately,
  has_no_deadline,
  availability_start_date,
  availability_start_time,
  availability_end_date,
  availability_end_time,
  max_participants_value,
  time_to_finish,
  deliveries,
  addresses,
  agreement,
  facebook_share_is_enabled,
  facebook_share_title,
  facebook_share_image,
  facebook_share_description,
  facebook_share_reward,
  facebook_share_days,
  link_share_is_enabled,
  link_share_title,
  link_share_image,
  link_share_description,
  link_share_reward,
  timeToFinishParsed,
  reward_type,
  t
}) => ({
  loading: false,
  image,
  title,
  description,
  available_immediately: is_available_immediately,
  no_deadline: has_no_deadline,
  start_date: availability_start_date,
  start_time: availability_start_time
    ? { label: getOption(availability_start_time), value: availability_start_time }
    : null,
  end_date: availability_end_date,
  end_time: availability_end_time
    ? { label: getOption(availability_end_time), value: availability_end_time }
    : null,
  participants_limit: { label: getOption(t("t:distribution.in-total")), value: "in_total" },
  max_participants: max_participants_value,
  time_to_finish,
  timeToFinishParsed: time_to_finish
    ? {
        hours: Math.floor(time_to_finish / 60),
        minutes: time_to_finish % 60
      }
    : timeToFinishParsed,
  deliveries,
  addresses: addresses || [],
  agreement,
  facebook_share_is_enabled,
  facebook_share_title,
  facebook_share_image,
  facebook_share_description,
  facebook_share_reward,
  facebook_share_days,
  link_share_is_enabled,
  link_share_title,
  link_share_image,
  link_share_description,
  link_share_reward,
  rewardType: reward_type
});

const calculateTimeToFinish = timeToFinishParsed => {
  let time_to_finish = 0;
  if (timeToFinishParsed.minutes) {
    time_to_finish = time_to_finish + +timeToFinishParsed.minutes;
  }
  if (timeToFinishParsed.hours) {
    time_to_finish = time_to_finish + +timeToFinishParsed.hours * 60;
  }
  return time_to_finish;
};

export const prepareDistributionDataToSubmit = ({
  imagePreview,
  imageBlobToDownload,
  title,
  description,
  available_immediately,
  no_deadline,
  start_date,
  start_time,
  end_date,
  end_time,
  max_participants,
  participants_limit,
  timeToFinishParsed,
  deliveries,
  addresses,
  agreement,
  facebook_share_is_enabled,
  facebook_share_title,
  facebook_share_imagePreview,
  facebook_share_imageBlobToDownload,
  facebook_share_description,
  facebook_share_reward,
  facebook_share_days,
  link_share_is_enabled,
  link_share_title,
  link_share_image,
  link_share_imagePreview,
  link_share_imageBlobToDownload,
  link_share_description,
  link_share_reward
}) => {
  const formData = new FormData();
  const addressesIds = [];
  addresses.forEach(el => addressesIds.push(el.id));
  imagePreview &&
    imageBlobToDownload &&
    formData.append("image", imageBlobToDownload, "campaign.png");
  formData.append("title", title || "");
  formData.append("description", description || "");
  formData.append("is_available_immediately", available_immediately);
  formData.append("has_no_deadline", no_deadline);
  formData.append("availability_start_date", start_date || "");
  start_time
    ? formData.append("availability_start_time", start_time.value)
    : formData.append("availability_start_time", "");
  formData.append("availability_end_date", end_date || "");
  end_time
    ? formData.append("availability_end_time", end_time.value)
    : formData.append("availability_end_time", "");
  formData.append("max_participants_type", participants_limit.value);
  formData.append("max_participants_value", max_participants || "");
  formData.append(
    "time_to_finish",
    calculateTimeToFinish(timeToFinishParsed) === 0 ? "" : calculateTimeToFinish(timeToFinishParsed)
  );
  formData.append("addresses", JSON.stringify(addressesIds));
  formData.append(
    "deliveries",
    JSON.stringify(deliveries.map(d => ({ ...d, price: d.price || 0 })))
  );
  formData.append("agreement", agreement);
  formData.append("facebook_share_is_enabled", facebook_share_is_enabled);
  formData.append("facebook_share_title", facebook_share_title);
  facebook_share_imagePreview &&
    facebook_share_imageBlobToDownload &&
    formData.append(
      "facebook_share_image",
      facebook_share_imageBlobToDownload,
      "facebook-share.png"
    );
  // !facebook_share_image && formData.append("facebook_share_image", "");
  formData.append("facebook_share_description", facebook_share_description);
  formData.append("facebook_share_reward", facebook_share_reward || "");
  formData.append("facebook_share_days", facebook_share_days || "");
  formData.append("link_share_is_enabled", link_share_is_enabled);
  formData.append("link_share_title", link_share_title);
  link_share_imagePreview &&
    link_share_imageBlobToDownload &&
    formData.append("link_share_image", link_share_imageBlobToDownload, "link-share.png");
  !link_share_image && formData.append("link_share_image", "");
  formData.append("link_share_description", link_share_description);
  formData.append("link_share_reward", link_share_reward || "");
  return formData;
};

export const checkDistributionChanges = ({
  isEditor,
  prev,
  loading,
  image,
  title,
  description,
  available_immediately,
  no_deadline,
  start_date,
  start_time,
  end_date,
  end_time,
  max_participants,
  deliveries,
  addresses,
  agreement,
  facebook_share_is_enabled,
  facebook_share_title,
  facebook_share_image,
  facebook_share_description,
  facebook_share_reward,
  facebook_share_days,
  link_share_is_enabled,
  link_share_title,
  link_share_image,
  link_share_description,
  link_share_reward,
  timeToFinishParsed
}) => {
  if (loading || !isEditor) return false;
  return (
    title !== prev.title ||
    image !== prev.image ||
    description !== prev.description ||
    available_immediately !== prev.is_available_immediately ||
    no_deadline !== prev.has_no_deadline ||
    start_date !== prev.availability_start_date ||
    (!!start_time && start_time.value !== prev.availability_start_time) ||
    end_date !== prev.availability_end_date ||
    (!!end_time && end_time.value !== prev.availability_end_time) ||
    max_participants !== prev.max_participants_value ||
    JSON.stringify(addresses) !== JSON.stringify(prev.addresses) ||
    JSON.stringify(deliveries) !== JSON.stringify(prev.deliveries) ||
    agreement !== prev.agreement ||
    facebook_share_is_enabled !== prev.facebook_share_is_enabled ||
    facebook_share_title !== prev.facebook_share_title ||
    facebook_share_image !== prev.facebook_share_image ||
    facebook_share_description !== prev.facebook_share_description ||
    facebook_share_reward !== prev.facebook_share_reward ||
    facebook_share_days !== prev.facebook_share_days ||
    link_share_is_enabled !== prev.link_share_is_enabled ||
    link_share_title !== prev.link_share_title ||
    link_share_image !== prev.link_share_image ||
    link_share_description !== prev.link_share_description ||
    link_share_reward !== prev.link_share_reward ||
    +calculateTimeToFinish(timeToFinishParsed) !== +prev.time_to_finish
  );
};
