import React from "react";
import Tab from "@material-ui/core/Tab";
import MUITabs from "@material-ui/core/Tabs";

import "./styles.scss";

const Tabs = ({ currentTab, changeTab, tabs, styles }) => (
  <MUITabs
    value={currentTab}
    onChange={(e, tab) => changeTab(tab)}
    classes={{
      root: `tabs_wrapper ${styles}`,
      flexContainer: "tabs_buttons_container"
    }}
  >
    {tabs.map(({ label, value, error, activeIcon, inactiveIcon }) => (
      <Tab
        key={`${value}_tab`}
        label={label}
        value={value}
        icon={
          activeIcon && <img src={currentTab === value ? activeIcon : inactiveIcon} alt="tab" />
        }
        classes={{
          root: error ? "tab_wrapper tab_with_error" : "tab_wrapper",
          selected: "tab_selected"
        }}
      />
    ))}
  </MUITabs>
);

export default Tabs;
