import React from "react";
import { useTranslation } from "react-i18next";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { numberWithSpaces } from "../../../../../helpers/functions";
import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";

const UsersThatMeetConditions = ({ usersNumber, onCalculateClick, loading, fieldsAreMissing }) => {
  const { t } = useTranslation();
  return (
    <div className="custom-audience__suitable-users">
      <div className="items-center">
        <p className="custom-audience__suitable-users--number">{numberWithSpaces(usersNumber)}</p>
        <p className="custom-audience__suitable-users--description">
          {t("t:audience.users-that-meet-conditions")}
        </p>
      </div>
      <TooltipMessage
        text={fieldsAreMissing ? t("t:common.enter-mandatory-fields") : ""}
        delay={200}
        position="top"
        classes="step_tooltip"
      >
        <div>
          <DefaultButton
            variant="outlined"
            classes="calculate_btn"
            onClick={onCalculateClick}
            disabled={fieldsAreMissing}
            loading={loading}
          >
            {t("t:audience.calculate")}
          </DefaultButton>
        </div>
      </TooltipMessage>
    </div>
  );
};

export default UsersThatMeetConditions;
