import React, { useMemo } from "react";

import { renderMobileImage } from "../../helpers";

import RadioSelectedIcon from "../../../../../../assets/image/mobile-radio-selected.svg";
import RadioIcon from "../../../../../../assets/image/mobile-radio.svg";

const Quiz = ({ title, description, image, answers }) => {
  const renderAnswers = useMemo(
    () => (
      <div className="multiple-choices">
        {answers.map(({ answer, is_correct }, key) => (
          <div className="multiple-choices__row" key={`answer-${key}`}>
            <img src={is_correct ? RadioSelectedIcon : RadioIcon} alt="input" />
            <span>{answer}</span>
          </div>
        ))}
      </div>
    ),
    // eslint-disable-next-line
    [JSON.stringify(answers)]
  );

  return (
    <>
      <h2>{title}</h2>
      <p>{description}</p>
      {image && <div className="image-wrapper">{renderMobileImage(image)}</div>}
      {renderAnswers}
    </>
  );
};

export default Quiz;
