import React from "react";

import CampaignDummyIcon from "../../assets/image/auth_bg_with_assets.png";

const audienceFileTypeIsSupported = file => {
  const validTypes = [
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ];
  return validTypes.indexOf(file.type) !== -1;
};

const getStatusTranslationKey = status => {
  switch (status) {
    case "available":
      return "t:common.available";
    case "in_use":
      return "t:audience.in-use";
    case "archived":
      return "t:common.archived";
    default:
      return "";
  }
};

const renderCampaignsListErrorDialog = campaigns => (
  <div className="confirmation-dialog__campaigns-list">
    {campaigns.map(({ id, name, image }) => (
      <div className="confirmation-dialog__campaign" key={id}>
        <img
          src={image || CampaignDummyIcon}
          alt="campaign"
          className="confirmation-dialog__campaign-icon"
        />
        <p className="confirmation-dialog__campaign-name">{name}</p>
      </div>
    ))}
  </div>
);

export { audienceFileTypeIsSupported, getStatusTranslationKey, renderCampaignsListErrorDialog };
