import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _omitBy from "lodash/omitBy";
import moment from "moment";

import { getCampaignReport, setCampaignReportMeta } from "../../../actions/billingReportsActions";
import SkeletonComponent from "../../HelperComponents/SkeletonComponent/SkeletonComponent";
import Pagination from "../../HelperComponents/Pagination/Pagination";
import TotalBlock from "../../HelperComponents/TotalBlock";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DatePicker from "../../HelperComponents/DatePicker";
import { formatDate, formatPrice } from "../../../helpers/functions";

import CampaignsIcon from "../../../assets/image/campaigns_icon.svg";

import "./styles.scss";

const CampaignReport = () => {
  const {
    list: { totals, results, count, excel_link },
    meta
  } = useSelector(({ billingReports }) => billingReports.campaignReport);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCampaignReport(_omitBy(meta, v => !v)));
  }, [dispatch, meta]);

  const onMetaChange = metaFields => {
    dispatch(setCampaignReportMeta(metaFields));
  };

  const onPageChange = page => onMetaChange({ page });

  const handleDateChange = (date, name) => {
    onMetaChange({
      page: 1,
      [name]: date ? formatDate(date) : ""
    });
  };

  const onExportToExcelClick = () => {
    const win = window.open(excel_link, "_blank");
    win.focus();
  };

  return (
    <div className="campaign-report__wrapper">
      <TotalBlock
        loading={!totals}
        totalIcon={CampaignsIcon}
        totalText={t("t:billing-reports.n-campaigns-in-total", { amount: totals?.campaigns || 0 })}
        items={[
          {
            label: t("t:billing-reports.n-received", { amount: formatPrice(totals?.received) }),
            value: "received"
          },
          {
            label: t("t:billing-reports.n-campy-fee", { amount: formatPrice(totals?.fee) }),
            value: "fee"
          }
        ]}
      />
      <div className="campaign-report__meta-controls">
        <div className="date-pickers">
          <DatePicker
            maxDate={meta.date_to ? new Date(meta.date_to) : undefined}
            minDate={new Date("2022-01-01")}
            handleDateChange={handleDateChange}
            name="date_from"
            prevDate={moment(meta.date_from)}
            leftInputAdornment="From"
          />
          <DatePicker
            minDate={meta.date_from ? new Date(meta.date_from) : new Date("2022-01-01")}
            maxDate={new Date()}
            handleDateChange={handleDateChange}
            name="date_to"
            prevDate={moment(meta.date_to)}
            leftInputAdornment="To"
          />
        </div>
        {count > 0 && (
          <DefaultButton
            variant="contained"
            classes="auth"
            disabled={!excel_link}
            onClick={onExportToExcelClick}
          >
            {t("t:billing-reports.export-to-excel")}
          </DefaultButton>
        )}
      </div>
      {!!results.length && (
        <>
          <div className="table_container campaign-report__table_container">
            {results.length ? (
              <>
                <div className="table_header campaign-report__table_header">
                  <div className="table_row">
                    <div className="row_item">{t("t:campaigns.campaign-name")}</div>
                    <div className="row_item">{t("t:billing-reports.finalized-participants")}</div>
                    <div className="row_item">{t("t:billing-reports.total-received-amount")}</div>
                    <div className="row_item">{t("t:billing-reports.total-campy-fee")}</div>
                  </div>
                </div>
                <div className="table_body campaign-report__table_body">
                  {results.map(
                    ({
                      id,
                      name,
                      finalized_participants,
                      total_received_amount,
                      total_fee,
                      loading
                    }) => (
                      <div className="table_row" key={`report_${id}`}>
                        <div className="row_item">
                          <SkeletonComponent variant="text" loading={loading} width={200}>
                            <p>{name}</p>
                          </SkeletonComponent>
                        </div>
                        <div className="row_item">
                          <SkeletonComponent variant="text" loading={loading} width={50}>
                            <p>{finalized_participants}</p>
                          </SkeletonComponent>
                        </div>
                        <div className="row_item">
                          <SkeletonComponent variant="text" loading={loading} width={70}>
                            <p>{formatPrice(total_received_amount)}</p>
                          </SkeletonComponent>
                        </div>
                        <div className="row_item">
                          <SkeletonComponent variant="text" loading={loading} width={80}>
                            <p>{formatPrice(total_fee)}</p>
                          </SkeletonComponent>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              t("t:common.nothing-to-show")
            )}
          </div>
          <div className="campaign-report__pagination">
            {count > 10 && (
              <Pagination
                active={meta.page}
                pageCount={Math.ceil(count / 10)}
                onChange={e => onPageChange(e.selected + 1)}
                t={t}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CampaignReport;
