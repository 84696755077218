import moment from "moment";
import { BILLING_REPORTS } from "../constants";

const INITIAL_STATE = {
  campaignReport: {
    meta: {
      page: 1,
      date_from: moment(new Date())
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      date_to: moment(new Date())
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD")
    },
    list: {
      results: Array.from({ length: 5 }, (v, k) => ({ id: k, loading: true }))
    }
  },
  purchaseReport: {
    meta: {
      page: 1,
      date_from: moment(new Date())
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      date_to: moment(new Date())
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD")
    },
    list: {
      results: Array.from({ length: 5 }, (v, k) => ({ id: k, loading: true }))
    }
  }
};

const reduceBillingReports = (state = INITIAL_STATE, { type, payload, meta }) => {
  switch (type) {
    case BILLING_REPORTS.GET_CAMPAIGN_REPORT_SUCCESS:
      return {
        ...state,
        campaignReport: { ...state.campaignReport, list: payload.data }
      };
    case BILLING_REPORTS.GET_PURCHASE_REPORT_SUCCESS:
      return {
        ...state,
        purchaseReport: { ...state.purchaseReport, list: payload.data }
      };
    case BILLING_REPORTS.SET_CAMPAIGN_REPORT_META:
      return {
        ...state,
        campaignReport: { ...state.campaignReport, meta: { ...state.campaignReport.meta, ...meta } }
      };
    case BILLING_REPORTS.SET_PURCHASE_REPORT_META:
      return {
        ...state,
        purchaseReport: { ...state.purchaseReport, meta: { ...state.purchaseReport.meta, ...meta } }
      };
    case BILLING_REPORTS.RESET_BILLING_REPORTS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reduceBillingReports;
