import React from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";

import "./Calendar.scss";

export default function MaterialUIPickers({
  handleDateChange,
  prevdate,
  disabled,
  name,
  disablePast = true,
  minDate = new Date("1900-01-01"),
  maxDate = new Date("2100-01-01")
}) {
  const [selectedDate, setSelectedDate] = React.useState(null);
  const { t } = useTranslation();

  const handleDate = date => {
    setSelectedDate(date);
    handleDateChange(date, name);
  };

  return (
    <div className="calendar">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disabled={disabled}
          invalidDateMessage={t("t:common.invalid-date")}
          minDateMessage={t("t:common.current-or-past-date-validation")}
          minDate={minDate}
          maxDate={maxDate}
          disablePast={disablePast}
          variant="dialog"
          format="dd/MM/yyyy"
          margin="normal"
          id={`date-picker-inline-${name}`}
          placeholder="DD/MM/YYYY"
          value={prevdate || selectedDate}
          onChange={handleDate}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
