import React from "react";

import GoogleAuth from "./GoogleAuth/GoogleAuth";
import FacebookAuth from "./FacebookAuth/FacebookAuth";
import AppleAuth from "./AppleAuth/AppleAuth";

const AuthButtons = () => (
  <div className="auth_buttons_wrapper">
    <GoogleAuth />
    <FacebookAuth />
    <AppleAuth />
  </div>
);

export default AuthButtons;
