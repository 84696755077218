import React, { useEffect, useMemo, useState } from "react";
import YouTube from "react-youtube";

import { renderMobileImage } from "../../helpers";

import PlayIcon from "../../../../../../assets/image/mobile-play-btn.svg";

const Video = ({ title, description, youtubeURL }) => {
  const [duration, setDuration] = useState(0);

  const videoID = useMemo(() => {
    if (!youtubeURL) return null;
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = youtubeURL.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  }, [youtubeURL]);

  useEffect(() => {
    if (!videoID) setDuration(0);
  }, [videoID]);

  const onReady = e => setDuration(e.target.getDuration());

  const secondsToMMSS = seconds => new Date(seconds * 1000).toISOString().substr(14, 5);

  return (
    <>
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="image-wrapper">
        {renderMobileImage(`https://img.youtube.com/vi/${videoID}/0.jpg`)}
        <img src={PlayIcon} alt="play" className="image-wrapper__icon" />
        <div className="image-wrapper__duration">{secondsToMMSS(duration)}</div>
        {videoID && <YouTube videoId={videoID} onReady={onReady} />}
      </div>
    </>
  );
};

export default Video;
