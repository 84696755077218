import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { splitByCommas } from "../../../../../helpers/functions";
import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { getPotentialReach } from "../../../../../actions/campaignActions";
import { recalculateCustomAudienceByID } from "../../../../../actions/audienceActions";

import MultipleIcon from "../../../../../assets/image/multiple-blue.svg";

const PotentialReach = ({ audience, prepareDataForSubmit }) => {
  const { potentialReach } = useSelector(({ campaign }) => campaign);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: campaignId } = useParams();

  useEffect(() => {
    if (!audience || (audience && audience.id && audience.type !== "csv")) {
      dispatch(getPotentialReach(campaignId, prepareDataForSubmit()));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(prepareDataForSubmit())]);

  const onCalculateClick = async () => {
    setButtonLoading(true);
    await dispatch(recalculateCustomAudienceByID(audience.id));
    setButtonLoading(false);
  };

  if (audience && audience.type === "csv") return null;

  if (audience && !audience.id) {
    return (
      <div className="potential_reach">
        <p>
          <img src={MultipleIcon} alt="people" />
          {`${t("t:campaign-audience.potential-reach")} ...`}
        </p>
      </div>
    );
  }

  if (audience && audience.type === "segment" && audience.id) {
    return (
      <div className="potential_reach">
        <p>
          <img src={MultipleIcon} alt="people" />
          {t("t:campaign-audience.potential-reach")}
          &nbsp;
          {t("t:campaign-audience.n-people", { number: splitByCommas(potentialReach) })}
        </p>
        <DefaultButton
          variant="outlined"
          classes="calculate_btn"
          onClick={onCalculateClick}
          loading={buttonLoading}
        >
          {t("t:audience.calculate")}
        </DefaultButton>
      </div>
    );
  }

  return (
    <div className="potential_reach">
      <p>
        <img src={MultipleIcon} alt="people" />
        {t("t:campaign-audience.potential-reach")}
        &nbsp;
        {t("t:campaign-audience.n-people", { number: splitByCommas(potentialReach) })}
      </p>
    </div>
  );
};

export default PotentialReach;
