import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { postFacebookAuth } from "../../../../actions/authActions";
import { postConnectFacebook } from "../../../../actions/profileActions";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";

import { FACEBOOK_APP_ID } from "../../../../config";

class FacebookAuth extends Component {
  responseFacebook = response => {
    const { postFacebookAuth, postConnectFacebook, history } = this.props;
    const data = {
      access_token: response.accessToken
    };
    if (!this.props.history.location.pathname.includes("profile")) {
      postFacebookAuth(data).then(res => {
        if (res.payload && (res.payload.status === 200 || res.payload.status === 201)) {
          localStorage.token = res.payload.data.key;
          history.push("/main");
        } else {
          this.setState({ openSnack: true });
        }
      });
    } else {
      postConnectFacebook(data).then(res => {
        if (res.payload && (res.payload.status === 200 || res.payload.status === 201)) {
          localStorage.token = res.payload.data.key;
        }
      });
    }
  };

  render() {
    const { label, variant, classes } = this.props;
    return (
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        callback={this.responseFacebook}
        render={renderProps => (
          <DefaultButton
            variant={variant || "contained"}
            classes={classes || "facebook"}
            onClick={renderProps.onClick}
          >
            {label || "Facebook"}
          </DefaultButton>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  postFacebookAuth,
  postConnectFacebook
};

export default connect(null, mapDispatchToProps)(withRouter(withTranslation()(FacebookAuth)));
