import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const CharactersLeft = ({ maxCharacters, title, visibleToUsers }) => {
  const { t } = useTranslation();
  return (
    <div className="input_footer">
      {visibleToUsers ? <div>{t("t:distribution.visible-to-users")}</div> : <div />}
      <div>
        {t("t:distribution.characters-left", {
          number: maxCharacters - (title.toString().length + title.toString().split("\n").length)
        })}
      </div>
    </div>
  );
};

export default CharactersLeft;
