import React from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.scss";

const Pagination = ({ active, pageCount, onChange, t }) => {
  return (
    <div className="pagination-container">
      <ReactPaginate
        forcePage={active - 1}
        onPageChange={onChange}
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        previousLabel={t("t:common.prev")}
        nextLabel={t("t:common.next")}
        containerClassName="pagination-list"
        pageClassName="pagination-item"
        pageLinkClassName="pagination-link"
        activeLinkClassName="pagination-link-active"
        breakClassName="pagination-ellipsis"
      />
    </div>
  );
};

Pagination.defaultProps = {
  active: 1,
  pageCount: 1,
  onChange: () => {},
  t: () => {}
};

export default Pagination;
