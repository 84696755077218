import React from "react";
import { Trans, useTranslation } from "react-i18next";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { DATA_SOURCES_DOCUMENTATION_URL } from "../../../../../config";
import SeeTutorialLink from "../../../../Support/_shared/SeeTutorialLink";

const InitialInfo = () => {
  const { t } = useTranslation();
  const wordpressLink = "https://campy.eu/wp-content/uploads/updater/campy-woocommerce-sync.zip";
  const prestashopLink = "https://campy.eu/wp-content/uploads/updater/prestashop-module.zip";
  return (
    <div className="add-api-data-source">
      <div className="title">{t("t:data-sources.add-data-source")}</div>

      <div className="add-api-data-source">
        <p>{t("t:data-sources.add-api-data-source-excerpt")}</p>

        <h4>•&nbsp;&nbsp;{t("t:data-sources.magento-2-platforms")}</h4>
        <p>{t("t:data-sources.magento-2-platforms--1")}</p>
        <p>{t("t:data-sources.magento-2-platforms--2")}</p>
        <p>{t("t:data-sources.magento-2-platforms--3")}</p>
        <p>{t("t:data-sources.magento-2-platforms--4")}</p>

        <h4>•&nbsp;&nbsp;{t("t:data-sources.woocommerce-and-wordpress-platforms")}</h4>
        <SeeTutorialLink
          link={t("t:data-sources.woocommerce-and-wordpress-platforms-tutorial-link")}
          classes="with-margin-bottom"
        />
        <a download href={wordpressLink}>
          <Trans
            i18nKey="t:data-sources.woocommerce-and-wordpress-platforms--1"
            components={{ bold: <strong /> }}
          />
        </a>
        <p>{t("t:data-sources.woocommerce-and-wordpress-platforms--2")}</p>
        <p>{t("t:data-sources.woocommerce-and-wordpress-platforms--3")}</p>

        <h4>•&nbsp;&nbsp;{t("t:data-sources.prestashop-platforms")}</h4>
        <a download href={prestashopLink}>
          <Trans
            i18nKey="t:data-sources.prestashop-platforms--1"
            components={{ bold: <strong /> }}
          />
        </a>
        <p>{t("t:data-sources.prestashop-platforms--2")}</p>
        <p>{t("t:data-sources.prestashop-platforms--3")}</p>

        <h4>•&nbsp;&nbsp;{t("t:data-sources.custom-integration")}</h4>
        <p>{t("t:data-sources.custom-integration-excerpt")}</p>
        <br />
        <p>{t("t:data-sources.custom-integration--1")}</p>
        <p>{t("t:data-sources.custom-integration--2")}</p>
        <p>{t("t:data-sources.custom-integration--3")}</p>
        <p>{t("t:data-sources.custom-integration--4")}</p>
      </div>

      <div className="btn_wrapper">
        <DefaultButton
          variant="contained"
          classes="auth"
          type="link"
          to="/main/add-new-data-source/generate-api-key"
        >
          {t("t:common.continue")}
        </DefaultButton>
        <a href={DATA_SOURCES_DOCUMENTATION_URL} target="_blank" rel="noopener noreferrer">
          <DefaultButton variant="contained" classes="generate_link">
            {t("t:data-sources.view-documentation")}
          </DefaultButton>
        </a>
      </div>
    </div>
  );
};

export default InitialInfo;
