import { takeEvery } from "redux-saga/effects";
import { disableLoader, enableLoader } from "./index";
import { openSuccessSnack, openErrorSnack } from "./snacks";

export function* handleAudienceLoader() {
  yield takeEvery("GET_CSV_BASED_AUDIENCE", enableLoader);
  yield takeEvery("GET_CSV_BASED_AUDIENCE_SUCCESS", disableLoader);
  yield takeEvery("GET_CSV_BASED_AUDIENCE_FAIL", disableLoader);

  yield takeEvery("RECALCULATE_CSV_BASED_AUDIENCE_BY_ID", enableLoader);
  yield takeEvery("RECALCULATE_CSV_BASED_AUDIENCE_BY_ID_SUCCESS", disableLoader);
  yield takeEvery("RECALCULATE_CSV_BASED_AUDIENCE_BY_ID_FAIL", disableLoader);

  yield takeEvery("GET_ANALYTICS_BASED_AUDIENCE", enableLoader);
  yield takeEvery("GET_ANALYTICS_BASED_AUDIENCE_SUCCESS", disableLoader);
  yield takeEvery("GET_ANALYTICS_BASED_AUDIENCE_FAIL", disableLoader);

  yield takeEvery("GET_CUSTOM_AUDIENCE", enableLoader);
  yield takeEvery("GET_CUSTOM_AUDIENCE_SUCCESS", disableLoader);
  yield takeEvery("GET_CUSTOM_AUDIENCE_FAIL", disableLoader);

  yield takeEvery("RECALCULATE_CUSTOM_AUDIENCE_BY_ID", enableLoader);
  yield takeEvery("RECALCULATE_CUSTOM_AUDIENCE_BY_ID_SUCCESS", disableLoader);
  yield takeEvery("RECALCULATE_CUSTOM_AUDIENCE_BY_ID_FAIL", disableLoader);

  yield takeEvery("CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS", enableLoader);
  yield takeEvery("CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS_SUCCESS", disableLoader);
  yield takeEvery("CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS_FAIL", disableLoader);
}

export function* handleAudienceSnacks() {
  yield takeEvery("POST_CSV_BASED_AUDIENCE_SUCCESS", () =>
    openSuccessSnack("audience-created-success")
  );
  yield takeEvery("PATCH_CSV_BASED_AUDIENCE_BY_ID_SUCCESS", () =>
    openSuccessSnack("audience-updated-success")
  );
  yield takeEvery("DELETE_CSV_BASED_AUDIENCE_BY_ID_SUCCESS", () =>
    openSuccessSnack("audience-deleted-success")
  );

  yield takeEvery("PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS", () =>
    openSuccessSnack("audience-updated-success")
  );
  yield takeEvery("POST_ANALYTICS_BASED_AUDIENCE_SUCCESS", () =>
    openSuccessSnack("audience-created-success")
  );
  yield takeEvery("UPDATE_ANALYTICS_BASED_AUDIENCE_SUCCESS", () =>
    openSuccessSnack("audience-updated-success")
  );
  yield takeEvery("ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS", () =>
    openSuccessSnack("audience-archived-success")
  );
  yield takeEvery("RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID_SUCCESS", () =>
    openSuccessSnack("audience-restored-success")
  );

  yield takeEvery("POST_CUSTOM_AUDIENCE_SUCCESS", () =>
    openSuccessSnack("audience-created-success")
  );
  yield takeEvery("PATCH_CUSTOM_AUDIENCE_BY_ID_SUCCESS", () =>
    openSuccessSnack("audience-updated-success")
  );
  yield takeEvery("ARCHIVE_CUSTOM_AUDIENCE_BY_ID_SUCCESS", () =>
    openSuccessSnack("audience-archived-success")
  );
  yield takeEvery("RESTORE_CUSTOM_AUDIENCE_BY_ID_SUCCESS", () =>
    openSuccessSnack("audience-restored-success")
  );
  yield takeEvery("DUPLICATE_CUSTOM_AUDIENCE_BY_ID_SUCCESS", () =>
    openSuccessSnack("audience-duplicated-success")
  );

  yield takeEvery("POST_CSV_BASED_AUDIENCE_FAIL", openErrorSnack);
  yield takeEvery("PATCH_CSV_BASED_AUDIENCE_BY_ID_FAIL", openErrorSnack);
  yield takeEvery("RECALCULATE_CSV_BASED_AUDIENCE_BY_ID_FAIL", openErrorSnack);

  yield takeEvery("PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL", openErrorSnack);
  yield takeEvery("ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL", openErrorSnack);
  yield takeEvery("RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID_FAIL", openErrorSnack);
  yield takeEvery("UPDATE_ANALYTICS_BASED_AUDIENCE_FAIL", openErrorSnack);

  yield takeEvery("RECALCULATE_CUSTOM_AUDIENCE_BY_ID_FAIL", openErrorSnack);
  yield takeEvery("ARCHIVE_CUSTOM_AUDIENCE_BY_ID_FAIL", openErrorSnack);
  yield takeEvery("RESTORE_CUSTOM_AUDIENCE_BY_ID_FAIL", openErrorSnack);
  yield takeEvery("DUPLICATE_CUSTOM_AUDIENCE_BY_ID_FAIL", openErrorSnack);
}
