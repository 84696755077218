import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import Tabs from "../HelperComponents/Tabs";
import { resetBillingReports } from "../../actions/billingReportsActions";
import { billingReportsTabs } from "./constants";
import CampaignReport from "./CampaignReport";
import PurchaseReport from "./PurchaseReport";
import { useQueryParams } from "../../helpers/hooks";

import "./styles.scss";

const BillingReports = () => {
  const { getParam } = useQueryParams();
  const [tab, setTab] = useState(getParam("tab") || "campaign_report");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (tab !== getParam("tab")) {
      setTab(getParam("tab"));
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (tab !== getParam("tab")) history.push(`/main/billing-reports?tab=${tab}`);
    // eslint-disable-next-line
  }, [tab]);

  useEffect(
    () => () => {
      dispatch(resetBillingReports());
    },
    [dispatch]
  );
  return (
    <div className="page_wrapper">
      <h1 className="billing-reports__title">{t("t:billing-reports.billing-reports")}</h1>
      <Tabs
        currentTab={tab}
        changeTab={setTab}
        tabs={billingReportsTabs.map(({ translationKey, value }) => ({
          label: t(translationKey),
          value
        }))}
        styles="audience-list__tabs"
      />
      <div className="billing-reports__wrapper">
        {tab === "campaign_report" && <CampaignReport />}
        {tab === "purchase_report" && <PurchaseReport />}
      </div>
    </div>
  );
};

export default BillingReports;
