import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Prompt } from "react-router";
import { withTranslation } from "react-i18next";

import AudiencePanel from "./AudiencePanel";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Snack from "../../HelperComponents/Snack/Snack";
import Loader from "../../HelperComponents/Loader";
import { getOption } from "../../../helpers/functions";
import {
  getInterests,
  getAudience,
  patchAudience,
  resetCampaignErrors,
  getHashtagSuggestions,
  postHashtag,
  setAudienceError
} from "../../../actions/campaignActions";

import PotentialReach from "./Blocks/PotentialReach";
import Age from "./Blocks/Age";
import Gender from "./Blocks/Gender";
import Interests from "./Blocks/Interests";
import Hashtags from "./Blocks/Hashtags";
import Region from "./Blocks/Region";
import CustomAudiences from "./Blocks/CustomAudiences";
import { olderThanList, youngerThanList } from "../../Audience/constants";

import "./styles.scss";

class Audience extends Component {
  constructor(props) {
    super(props);
    this.editingCoolDown = 0;
    this.state = {
      loading: true,
      campaignId: props.campaignId,
      openSuccessSnack: false,
      buttonLoading: false,
      audience: undefined,
      older_than: undefined,
      younger_than: undefined,
      gender: undefined,
      interests: undefined,
      customerRanking: undefined,
      radius: undefined,
      address: undefined,
      coordinates: undefined,
      hashtags: undefined
    };
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      if (this) return this.checkChanges();
    };
    this.fetchAudience();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.loading && this.state !== prevState) {
      if (this.editingCoolDown !== 10000) {
        this.props.isEditor && this.props.handleEditingTimer();
        this.editingCoolDown = 10000;
        const timeout = setTimeout(() => {
          this.editingCoolDown = 0;
          clearTimeout(timeout);
        }, 10000);
      }
    }
  }

  fetchAudience = () => {
    const { getInterests, getAudience } = this.props;
    const { campaignId } = this.state;
    getAudience(campaignId).then(res => {
      const {
        age_from,
        age_to,
        gender,
        interests,
        location_description,
        location_point,
        location_radius,
        customer_ranking_from,
        hashtags,
        audience
      } = res.payload.data;
      this.setState({
        older_than: age_from
          ? { label: getOption(`${age_from} years`), value: age_from }
          : { label: getOption(""), value: "" },
        younger_than: age_to
          ? { label: getOption(`${age_to} years`), value: age_to }
          : { label: getOption(""), value: "" },
        gender,
        interests: interests.map(({ id }) => id),
        hashtags,
        address: location_description,
        coordinates: location_point
          ? { lat: location_point.latitude, lng: location_point.longitude }
          : null,
        radius: location_radius || 0,
        customerRanking: customer_ranking_from
          ? {
              label: getOption(
                `${customer_ranking_from} star${+customer_ranking_from > 1 ? "s" : ""}`
              ),
              value: customer_ranking_from
            }
          : customer_ranking_from,
        audience
      });
      getInterests().then(() => {
        this.setState({ loading: false });
      });
    });
  };

  handleWarningDialog = () => {
    this.setState({ openWarningDialog: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event });
  };

  handleInterest = item => {
    const { interests } = this.state;
    if (interests.includes(item.id)) {
      this.setState({ interests: interests.filter(el => el !== item.id) });
    } else {
      this.setState({ interests: [item.id] });
    }
  };

  handleLocation = geocodedLocation => {
    this.setState({
      coordinates: {
        lat: geocodedLocation.geometry.location.lat(),
        lng: geocodedLocation.geometry.location.lng()
      },
      address: geocodedLocation.formatted_address
    });
  };

  changeCheckbox = e => {
    const { gender } = this.state;
    if (gender.includes(e)) {
      this.setState({ gender: gender.filter(el => el !== e) });
    } else {
      this.setState({ gender: [...gender, e] });
    }
  };

  checkChanges = () => {
    const { audience, isEditor } = this.props;
    const {
      older_than,
      younger_than,
      gender,
      interests,
      customerRanking,
      radius,
      address,
      hashtags,
      loading,
      audience: customAudience
    } = this.state;
    if (loading || !isEditor) return false;
    return (
      (older_than && !!older_than.value && older_than.value !== audience.age_from) ||
      (younger_than && !!younger_than.value && younger_than.value !== audience.age_to) ||
      JSON.stringify(gender) !== JSON.stringify(audience.gender) ||
      JSON.stringify(interests) !== JSON.stringify(audience.interests.map(({ id }) => id)) ||
      JSON.stringify(hashtags) !== JSON.stringify(audience.hashtags) ||
      JSON.stringify(customAudience) !== JSON.stringify(audience.audience) ||
      (customerRanking && customerRanking.value !== audience.customer_ranking_from) ||
      (!!radius && radius !== audience.location_radius) ||
      address !== audience.location_description
    );
  };

  prepareDataForSubmit = () => {
    const {
      older_than,
      younger_than,
      gender,
      interests,
      customerRanking,
      radius,
      address,
      coordinates,
      hashtags,
      audience
    } = this.state;
    let data;
    if (audience) {
      data = {
        customer_ranking_from: customerRanking ? customerRanking.value : customerRanking,
        audience: audience ? audience.id : null
      };
    } else {
      data = {
        age_from: older_than.value || null,
        age_to: younger_than.value || null,
        gender,
        interests,
        customer_ranking_from: customerRanking ? customerRanking.value : customerRanking,
        audience: null,
        location_radius: radius,
        location_description: address,
        location_point: coordinates
          ? { latitude: coordinates.lat, longitude: coordinates.lng }
          : null,
        hashtags: hashtags.map(tag => tag.id)
      };
    }
    return data;
  };

  saveCampaign = async () => {
    const { patchAudience, resetCampaignErrors, t, setAudienceError } = this.props;
    const { campaignId, interests, audience, gender, radius, coordinates } = this.state;
    if (
      (!interests.length && !audience) ||
      (!gender.length && !audience) ||
      (!!coordinates && !radius)
    ) {
      const tempError = {};
      if (!interests.length)
        tempError.interests = [
          { code: "is_empty", message: t("t:campaign-audience.interests-validation") }
        ];
      if (!gender.length)
        tempError.gender = [
          { code: "is_empty", message: t("t:campaign-audience.gender-validation") }
        ];
      if (!!coordinates && !radius)
        tempError.location_radius = [
          { code: "is_empty", message: t("t:campaign-audience.radius-validation") }
        ];
      setAudienceError(tempError);
    } else {
      this.setState({ buttonLoading: true });
      resetCampaignErrors();
      const res = await patchAudience(campaignId, this.prepareDataForSubmit());
      this.setState({ buttonLoading: false });
      if (res.payload) {
        await this.fetchAudience();
        this.setState({ openSuccessSnack: true });
      }
    }
  };

  render() {
    const {
      audienceError,
      isEditor,
      interestsList,
      t,
      getHashtagSuggestions,
      postHashtag
    } = this.props;
    const {
      loading,
      older_than,
      younger_than,
      gender,
      interests,
      customerRanking,
      openSuccessSnack,
      radius,
      address,
      coordinates,
      hashtags,
      buttonLoading,
      audience
    } = this.state;
    const customAudienceSelected = audience && audience.id;
    return (
      <div className="audience_wrapper">
        <AudiencePanel />
        <div className="audience_container">
          {loading ? (
            <Loader />
          ) : (
            <div className="choose_your_audience">
              <div className="title">{t("t:campaign-audience.choose-your-audience")}</div>
              <div className="description">
                {t("t:campaign-audience.only-these-users-can-participate")}
              </div>
              <PotentialReach
                audience={audience}
                audienceFields={{
                  customerRanking,
                  older_than,
                  younger_than,
                  gender,
                  interests,
                  hashtags,
                  address,
                  radius
                }}
                prepareDataForSubmit={this.prepareDataForSubmit}
              />
              <CustomAudiences
                audienceError={audienceError}
                isEditor={isEditor}
                customerRanking={customerRanking}
                audience={audience}
                handleChange={this.handleChange}
                handleAudienceChange={newAudience => this.setState({ audience: newAudience })}
              />
              <div style={{ width: "100%", opacity: customAudienceSelected ? 0.3 : 1 }}>
                <Age
                  audienceError={audienceError}
                  isEditor={customAudienceSelected ? false : isEditor}
                  older_than={older_than}
                  younger_than={younger_than}
                  olderThanList={olderThanList(t)}
                  youngerThanList={youngerThanList(t)}
                  handleChange={this.handleChange}
                />
                <Gender
                  audienceError={audienceError}
                  isEditor={customAudienceSelected ? false : isEditor}
                  gender={gender}
                  changeCheckbox={this.changeCheckbox}
                />
                <Interests
                  audienceError={audienceError}
                  isEditor={customAudienceSelected ? false : isEditor}
                  interestsList={interestsList}
                  interests={interests}
                  handleInterest={this.handleInterest}
                />
                <Hashtags
                  audienceError={audienceError}
                  isEditor={customAudienceSelected ? false : isEditor}
                  changeTags={tags => this.setState({ hashtags: tags })}
                  hashtags={hashtags}
                  getHashtagSuggestions={getHashtagSuggestions}
                  postHashtag={postHashtag}
                />
                <Region
                  audienceError={audienceError}
                  isEditor={customAudienceSelected ? false : isEditor}
                  address={address}
                  handleLocation={this.handleLocation}
                  coordinates={coordinates}
                  radius={radius}
                  setRadius={tempRadius => this.setState({ radius: tempRadius })}
                  resetAddress={() =>
                    this.setState({ address: "", coordinates: null, radius: null })
                  }
                />
              </div>
              {isEditor && (
                <div className="save_button">
                  <DefaultButton
                    variant="contained"
                    classes="campaign_save_btn"
                    onClick={this.saveCampaign}
                    loading={buttonLoading}
                    disabled={
                      (!audience &&
                        !!older_than.value &&
                        !!younger_than.value &&
                        +older_than.value > +younger_than.value) ||
                      (audience && !audience.id) ||
                      (!audience && (!interests.length || !gender.length)) ||
                      !this.checkChanges()
                    }
                  >
                    {t("t:common.save")}
                  </DefaultButton>
                </div>
              )}
            </div>
          )}
          <Prompt when={this.checkChanges()} message={t("t:common.you-have-unsaved-changes")} />
          <Snack
            open={openSuccessSnack}
            handleSnack={() => this.setState({ openSuccessSnack: false })}
            message={t("t:snack-messages.successfully-changed")}
            type="success"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ campaign }) {
  return {
    isEditor: campaign.campaignViewers.is_editor,
    audienceError: campaign.audienceError,
    interestsList: campaign.interests,
    audience: campaign.audience
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getInterests,
      getAudience,
      patchAudience,
      resetCampaignErrors,
      getHashtagSuggestions,
      postHashtag,
      setAudienceError
    },
    dispatch
  );
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Audience)));
