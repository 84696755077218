import React, { Component } from "react";
import { Map, Marker, Circle } from "google-maps-react";
import { withTranslation } from "react-i18next";

export class MapContainer extends Component {
  onMapClicked = (mapProps, map, clickEvent) => {
    const { onMapClick, t } = this.props;
    if (onMapClick) {
      const latlng = {
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng()
      };
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            onMapClick(latlng, results[0].formatted_address);
          } else {
            window.alert(t("t:common.no-results-found"));
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    }
  };

  render() {
    const { coordinates, radius, isMarkerShown } = this.props;
    return (
      <Map
        google={window.google}
        zoom={14}
        streetViewControl={false}
        initialCenter={coordinates}
        center={coordinates}
        onClick={this.onMapClicked}
      >
        {isMarkerShown && <Marker name={"Current location"} position={coordinates} />}
        <Circle
          radius={+radius * 1000 || 0}
          center={coordinates}
          strokeColor="transparent"
          strokeOpacity={0}
          strokeWeight={5}
          fillColor="#FF0000"
          fillOpacity={0.2}
        />
      </Map>
    );
  }
}

export default withTranslation()(MapContainer);
