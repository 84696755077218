import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import CostPanel from "./CostPanel";
import Published from "./Views/Published";
import Inactive from "./Views/Inactive";
import Finalized from "./Views/Finalized";

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { getCampaignCost, handleBalanceDialog } from "../../../actions/balanceActions";
import { postPublishCampaign, getCampaignInfo, postViewer } from "../../../actions/campaignActions";

import "./styles.scss";

class Cost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      campaignId: props.campaignId
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.openBalanceDialog !== prevProps.openBalanceDialog &&
      !this.props.openBalanceDialog
    ) {
      this.doCampaignCostRequest();
    }
  }

  componentDidMount() {
    this.doCampaignCostRequest();
  }

  doCampaignCostRequest = () => {
    const {
      getCampaignCost,
      campaignInfo: { status }
    } = this.props;
    const { campaignId } = this.state;
    getCampaignCost(campaignId, status).then(res => {
      if (res && res.payload) {
        this.setState({ loading: false });
      }
    });
  };

  handlePublishing = async () => {
    const {
      postPublishCampaign,
      clearEditingTimer,
      handleViewingInterval,
      postViewer
    } = this.props;
    const { campaignId } = this.state;
    const res = await postPublishCampaign(campaignId);
    if (res.payload) {
      clearEditingTimer();
      handleViewingInterval();
      postViewer(campaignId);
      this.doCampaignCostRequest();
    }
  };

  render() {
    const {
      campaignCost,
      campaignInfo: { is_published: isPublished, status },
      user_info: { current_company },
      handleBalanceDialog,
      toggleStopCampaignDialog,
      campaignViewers: { is_editor },
      t
    } = this.props;
    const { loading, campaignId } = this.state;
    const isInactive = status === "inactive";
    const isFinalizedOrStopped = status === "finalized" || status === "stopped_immediately";
    return (
      <div className="cost_wrapper">
        <CostPanel />
        <div className="cost_container">
          <div className="cost_header">
            <div className="title">{t("t:cost.campaign-cost")}</div>
            {isPublished && (
              <div className="cost_btn_wrapper">
                <DefaultButton
                  variant="outlined"
                  classes="stop_campaign"
                  onClick={toggleStopCampaignDialog}
                >
                  {t("t:campaigns.stop-campaign")}
                </DefaultButton>
              </div>
            )}
            {isInactive && (
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.handlePublishing}
                disabled={!is_editor}
              >
                {t("t:campaigns.publish-campaign")}
              </DefaultButton>
            )}
          </div>
          {isPublished && <Published loading={loading} campaignId={campaignId} {...campaignCost} />}
          {isInactive && (
            <Inactive
              loading={loading}
              campaignId={campaignId}
              currentCompany={current_company}
              handleBalanceDialog={handleBalanceDialog}
              {...campaignCost}
            />
          )}
          {isFinalizedOrStopped && <Finalized loading={loading} {...campaignCost} />}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, balance, campaign }) {
  return {
    campaignCost: balance.campaignCost,
    campaignInfo: campaign.campaignInfo,
    user_info: auth.user_info,
    campaignViewers: campaign.campaignViewers,
    openBalanceDialog: balance.openBalanceDialog
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCampaignCost,
      postPublishCampaign,
      handleBalanceDialog,
      getCampaignInfo,
      postViewer
    },
    dispatch
  );
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Cost)));
