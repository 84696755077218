import React from "react";
import clsx from "clsx";
import { DraggableComponent } from "../../../../HelperComponents/DraggableComponent/DraggableComponent";

const DraggableStep = ({
  addStep,
  name,
  img,
  imgOnHover,
  label,
  previewImage,
  progressOnMobile
}) => (
  <DraggableComponent
    name={name}
    onDrop={addStep}
    key={name}
    previewImage={previewImage}
    canDrag={progressOnMobile === "Done"}
  >
    <button>
      {progressOnMobile !== "Done" && (
        <div
          className="coming_soon"
          style={{
            color: progressOnMobile === "Coming soon" ? "#324EA3" : "#30837B",
            backgroundColor:
              progressOnMobile === "Coming soon" ? "rgba(238,243,255,0.8)" : "rgba(238,255,245,0.8)"
          }}
        >
          <span>{progressOnMobile}</span>
        </div>
      )}
      <div className={clsx(progressOnMobile === "Done" && "unhovered-icon")}>
        <img src={img} alt={name} />
      </div>
      {progressOnMobile === "Done" ? (
        <div className="hover">
          <img src={imgOnHover} alt={`${name}_hovered`} />
        </div>
      ) : (
        ""
      )}
      <p>{label}</p>
    </button>
  </DraggableComponent>
);

export default DraggableStep;
