import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { getProducts } from "../../../actions/dataSourcesActions";

import "./styles.scss";

const TestApiComponent = ({ type = "custom" }) => {
  const { id, api_base_url: apiURL } = useSelector(({ dataSources }) => dataSources.dataSource);
  const products = useSelector(({ dataSources }) => dataSources.products);
  const loading = useSelector(({ dashboard }) => dashboard.loadingComponent);
  const {
    has_errors,
    request_method,
    request_url,
    response_status_code,
    errors,
    request_headers,
    response_headers,
    text
  } = products;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [meta, setMeta] = useState({
    searchKeyword: "",
    productID: "",
    radioValue: "name"
  });
  const isMagento = type === "magento";

  const onRadioClick = radioValue => {
    setMeta({
      ...meta,
      searchKeyword: "",
      productID: "",
      radioValue
    });
  };

  const changeMagentoInput = val => {
    if (meta.radioValue === "name") {
      setMeta({
        ...meta,
        searchKeyword: val
      });
    } else {
      setMeta({
        ...meta,
        productID: val
      });
    }
  };

  const testURL = () => {
    const { searchKeyword, productID } = meta;
    let data = ["page=1"];
    if (searchKeyword) {
      data.push(`search=${searchKeyword}`);
    }
    if (productID) {
      data.push(`product_id=${productID}`);
    }
    dispatch(getProducts(id, data));
  };

  return (
    <div className="test_your_api_wrapper">
      <p>{t("t:data-sources.test-your-endpoints")}</p>
      {isMagento ? (
        <div>
          <div className="item_radio no_select">
            <div className="option_wrapper">
              <div onClick={() => onRadioClick("name")}>
                <input
                  type="radio"
                  checked={meta.radioValue === "name"}
                  onChange={() => console.log("changed")}
                />
                <label htmlFor="radio1">{t("t:products.product-name")}</label>
              </div>
            </div>
            <div className="option_wrapper">
              <div onClick={() => onRadioClick("id")}>
                <input
                  type="radio"
                  checked={meta.radioValue === "id"}
                  onChange={() => console.log("changed")}
                />
                <label htmlFor="radio1">{t("t:data-sources.product-id")}</label>
              </div>
            </div>
          </div>
          <div className="input-with-button">
            <input
              value={meta.radioValue === "name" ? meta.searchKeyword : meta.productID}
              onChange={e => changeMagentoInput(e.target.value)}
              type="text"
              placeholder=""
            />
            <DefaultButton
              variant="contained"
              classes="test_btn"
              onClick={testURL}
              loading={loading}
            >
              {t("t:data-sources.test")}
            </DefaultButton>
          </div>
        </div>
      ) : (
        <div className="test_url">
          <div className="entered_url">
            <div>{`${apiURL}products/?search=`}</div>
            <input
              value={meta.searchKeyword}
              onChange={e => setMeta({ ...meta, searchKeyword: e.target.value })}
              type="text"
              placeholder={t("t:data-sources.keyword")}
            />
            <p>&product_id=</p>
            <input
              value={meta.productID}
              onChange={e => setMeta({ ...meta, productID: e.target.value })}
              type="text"
              placeholder={t("t:data-sources.product-id")}
            />
          </div>
          <DefaultButton variant="contained" classes="test_btn" onClick={testURL} loading={loading}>
            {t("t:data-sources.test")}
          </DefaultButton>
        </div>
      )}
      {products.hasOwnProperty("has_errors") && (
        <div className={clsx("response_wrapper", !has_errors ? "success" : "fail")}>
          <p>
            API request <strong>{has_errors ? "failed" : "success"}</strong>:
          </p>
          <br />
          <p>Request method: {request_method}</p>
          <p>Request URL: {request_url}</p>
          <p>Request headers:</p>
          {Object.entries(request_headers).map(el => (
            <p key={`request_${el[0]}`}>
              <strong>{el[0]}</strong>: {el[1]}
            </p>
          ))}
          <br />
          <p>Response status: {response_status_code}</p>
          {has_errors && <p>Response error: {errors[0]}</p>}
          <p>Response headers:</p>
          {Object.entries(response_headers).map(el => (
            <p key={`response_${el[0]}`}>
              <strong>{el[0]}</strong>: {el[1]}
            </p>
          ))}
          {!has_errors && (
            <>
              <p>Response body:</p>
              <p>{text}</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default TestApiComponent;
