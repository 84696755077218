import React from "react";
import clsx from "clsx";
import "./styles.scss";

const BreadcrumbsTabs = ({ currentTab, changeTab, tabs }) => (
  <div className="breadcrumbs-tabs">
    {tabs.map(({ label, value, error, activeIcon, inactiveIcon }) => (
      <div
        key={value}
        className={clsx([
          "breadcrumbs-tabs__tab",
          currentTab === value && "active-tab",
          error && "with-error"
        ])}
        onClick={() => changeTab(value)}
      >
        <img src={currentTab === value ? activeIcon : inactiveIcon} alt="tab" />
        <p>{label}</p>
      </div>
    ))}
  </div>
);

export default BreadcrumbsTabs;
