import { AUDIENCE } from "../constants";
import { joinQueries } from "../helpers/functions";

export function getCSVBasedAudience(queries) {
  return {
    type: AUDIENCE.GET_CSV_BASED_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/csv-based/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function postCSVBasedAudience(data) {
  return {
    type: AUDIENCE.POST_CSV_BASED_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: "/company/current/audience/csv-based/add/",
        method: "post",
        data
      }
    }
  };
}

export function getCSVBasedAudienceByID(id) {
  return {
    type: AUDIENCE.GET_CSV_BASED_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/csv-based/${id}/`,
        method: "get"
      }
    }
  };
}

export function patchCSVBasedAudienceByID(id, data) {
  return {
    type: AUDIENCE.PATCH_CSV_BASED_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/csv-based/${id}/`,
        method: "patch",
        data
      }
    }
  };
}

export function recalculateCSVBasedAudienceByID(id) {
  return {
    type: AUDIENCE.RECALCULATE_CSV_BASED_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/csv-based/${id}/recalculate/`,
        method: "post"
      }
    }
  };
}

export function deleteCSVBasedAudienceByID(id) {
  return {
    type: AUDIENCE.DELETE_CSV_BASED_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/csv-based/${id}/`,
        method: "delete"
      }
    }
  };
}

export function getAnalyticsBasedAudience(queries) {
  return {
    type: AUDIENCE.GET_ANALYTICS_BASED_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/analytics/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function getAnalyticsBasedAudienceByID(id) {
  return {
    type: AUDIENCE.GET_ANALYTICS_BASED_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/analytics/${id}/`,
        method: "get"
      }
    }
  };
}

export function postAnalyticsBasedAudience(data) {
  return {
    type: AUDIENCE.POST_ANALYTICS_BASED_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/analytics/create/`,
        method: "post",
        data
      }
    }
  };
}

export function updateAnalyticsBasedAudience(data) {
  return {
    type: AUDIENCE.UPDATE_ANALYTICS_BASED_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/analytics/update/`,
        method: "post",
        data
      }
    }
  };
}

export function patchAnalyticsBasedAudienceByID(id, data) {
  return {
    type: AUDIENCE.PATCH_ANALYTICS_BASED_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/analytics/${id}/`,
        method: "patch",
        data
      }
    }
  };
}

export function archiveAnalyticsBasedAudienceByID(id) {
  return {
    type: AUDIENCE.ARCHIVE_ANALYTICS_BASED_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/analytics/${id}/archive/`,
        method: "post"
      }
    }
  };
}

export function restoreAnalyticsBasedAudienceByID(id) {
  return {
    type: AUDIENCE.RESTORE_ANALYTICS_BASED_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/analytics/${id}/restore/`,
        method: "post"
      }
    }
  };
}

export function getCustomAudience(queries) {
  return {
    type: AUDIENCE.GET_CUSTOM_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/segment/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function postCustomAudience(data) {
  return {
    type: AUDIENCE.POST_CUSTOM_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: "/company/current/audience/segment/add/",
        method: "post",
        data
      }
    }
  };
}

export function getCustomAudienceByID(id) {
  return {
    type: AUDIENCE.GET_CUSTOM_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/segment/${id}/`,
        method: "get"
      }
    }
  };
}

export function patchCustomAudienceByID(id, data) {
  return {
    type: AUDIENCE.PATCH_CUSTOM_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/segment/${id}/`,
        method: "patch",
        data
      }
    }
  };
}

export function recalculateCustomAudienceByID(id) {
  return {
    type: AUDIENCE.RECALCULATE_CUSTOM_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/segment/${id}/recalculate/`,
        method: "post"
      }
    }
  };
}

export function calculateCustomAudienceByFields(data) {
  return {
    type: AUDIENCE.CALCULATE_CUSTOM_AUDIENCE_BY_FIELDS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/segment/calculate-audience/`,
        method: "post",
        data
      }
    }
  };
}

export function duplicateCustomAudienceByID(id, data) {
  return {
    type: AUDIENCE.DUPLICATE_CUSTOM_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/segment/${id}/duplicate/`,
        method: "post",
        data
      }
    }
  };
}

export function archiveCustomAudienceByID(id) {
  return {
    type: AUDIENCE.ARCHIVE_CUSTOM_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/segment/${id}/archive/`,
        method: "post"
      }
    }
  };
}

export function restoreCustomAudienceByID(id) {
  return {
    type: AUDIENCE.RESTORE_CUSTOM_AUDIENCE_BY_ID,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/segment/${id}/restore/`,
        method: "post"
      }
    }
  };
}

export function postEditorForCustomAudience(id) {
  return {
    type: AUDIENCE.POST_EDITOR_FOR_CUSTOM_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/${id}/editor/`,
        method: "post"
      }
    }
  };
}

export function deleteEditorForCustomAudience(id) {
  return {
    type: AUDIENCE.DELETE_EDITOR_FROM_CUSTOM_AUDIENCE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/audience/${id}/editor/`,
        method: "delete"
      }
    }
  };
}

export function setAudienceMetaFields({
  metaCsvBasedAudience,
  metaAnalyticsBasedAudience,
  metaCustomAudience
}) {
  return {
    type: AUDIENCE.SET_AUDIENCE_META,
    metaCsvBasedAudience,
    metaAnalyticsBasedAudience,
    metaCustomAudience
  };
}

export function setCSVBasedAudienceInfo(csvBasedAudienceInfo) {
  return {
    type: AUDIENCE.SET_CSV_BASED_AUDIENCE_INFO,
    csvBasedAudienceInfo
  };
}

export function setAnalyticsBasedAudienceInfo(analyticsBasedAudienceInfo) {
  return {
    type: AUDIENCE.SET_ANALYTICS_BASED_AUDIENCE_INFO,
    analyticsBasedAudienceInfo
  };
}

export function setCustomAudienceInfo(customAudienceField) {
  return {
    type: AUDIENCE.SET_CUSTOM_AUDIENCE_FIELD,
    customAudienceField
  };
}

export function setCustomAudienceGroupField(groupIndex, customAudienceGroupField) {
  return {
    type: AUDIENCE.SET_CUSTOM_AUDIENCE_GROUP_FIELD,
    groupIndex,
    customAudienceGroupField
  };
}

export function addCustomSegmentGroup() {
  return {
    type: AUDIENCE.ADD_CUSTOM_SEGMENT_GROUP
  };
}

export function removeCustomSegmentGroup(segmentIndex) {
  return {
    type: AUDIENCE.REMOVE_CUSTOM_SEGMENT_GROUP,
    segmentIndex
  };
}

export function setAudienceConfirmationDialog(confirmationDialog) {
  return {
    type: AUDIENCE.SET_CONFIRMATION_DIALOG,
    confirmationDialog
  };
}

export function resetAudienceStore() {
  return {
    type: AUDIENCE.RESET_AUDIENCE_STORE
  };
}

export function resetCustomAudienceInfo() {
  return {
    type: AUDIENCE.RESET_CUSTOM_AUDIENCE_STORE
  };
}
