import { getOption } from "../../helpers/functions";

const audienceTabs = [
  { translationKey: "t:audience.csv-based", value: "csv_based" },
  { translationKey: "t:audience.analytics-based", value: "analytics_based" },
  { translationKey: "t:audience.custom", value: "custom" }
];

const sortByValuesCsvBased = t => [
  { label: getOption(t("t:audience.creation-date")), value: "created_at" },
  { label: getOption(t("t:audience.total-contacts")), value: "total_contacts" },
  { label: getOption(t("t:audience.match-rate")), value: "match_percent" }
];

const statusesAnalyticsBased = t => [
  { label: getOption(t("t:audience.all-segments")), value: "" },
  { label: getOption(t("t:common.available")), value: "available" },
  { label: getOption(t("t:common.archived")), value: "archived" }
];

const statusesCustomAudience = t => [
  { label: getOption(t("t:audience.all-segments")), value: "" },
  { label: getOption(t("t:common.available")), value: "available" },
  { label: getOption(t("t:audience.in-use")), value: "in_use" },
  { label: getOption(t("t:common.archived")), value: "archived" }
];

const olderThanList = t => [
  { label: getOption(t("t:audience.any-age")), value: "" },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 14 })), value: 14 },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 18 })), value: 18 },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 26 })), value: 26 },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 36 })), value: 36 },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 46 })), value: 46 },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 60 })), value: 60 }
];

const youngerThanList = t => [
  { label: getOption(t("t:audience.any-age")), value: "" },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 17 })), value: 17 },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 25 })), value: 25 },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 35 })), value: 35 },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 45 })), value: 45 },
  { label: getOption(t("t:campaign-audience.number-of-years", { number: 59 })), value: 59 }
];

const genderList = t => [
  { labelKey: "t:campaign-audience.male", value: "male" },
  { labelKey: "t:campaign-audience.female", value: "female" },
  { labelKey: "t:campaign-audience.other", value: "unisex" }
];

const customSegmentGroup = {
  action: "purchased",
  action_value: 1,
  period_value: 1,
  period: "months",
  age_from: "",
  age_to: "",
  gender: [],
  interests: [],
  hashtags: [],
  location_radius: "",
  location_description: "",
  location_point: { latitude: 59.43, longitude: 24.75 }
};

const performedList = t => [
  { label: getOption(t("t:audience.has-made-purchase")), value: "purchased" },
  { label: getOption(t("t:audience.has-joined-campaign")), value: "joined" },
  { label: getOption(t("t:audience.has-finished-campaign")), value: "completed" },
  { label: getOption(t("t:audience.has-made-photo")), value: "made_photo" },
  { label: getOption(t("t:audience.has-scanned-qr")), value: "qr_scanned" },
  { label: getOption(t("t:audience.has-visited-location")), value: "location" },
  { label: getOption(t("t:audience.has-provided-feedback")), value: "response" },
  { label: getOption(t("t:audience.has-received-campy-credits")), value: "monetary" }
];

const periodsList = t => [
  { label: getOption(t("t:audience.days")), value: "days" },
  { label: getOption(t("t:audience.weeks")), value: "weeks" },
  { label: getOption(t("t:audience.months")), value: "months" },
  { label: getOption(t("t:audience.years")), value: "years" }
];

const numberRegexp = new RegExp("^[0-9]+$");

export {
  audienceTabs,
  sortByValuesCsvBased,
  statusesAnalyticsBased,
  statusesCustomAudience,
  olderThanList,
  youngerThanList,
  customSegmentGroup,
  performedList,
  periodsList,
  genderList,
  numberRegexp
};
