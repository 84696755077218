import React from "react";

import Location from "./Challenges/Location";
import Video from "./Challenges/Video";
import CheckText from "./Challenges/CheckText";
import Image from "./Challenges/Image";
import Url from "./Challenges/Url";
import Scan from "./Challenges/Scan";
import Photo from "./Challenges/Photo";
import NewQR from "./Challenges/[DEPRECATED]NewQR/NewQR";
import Multiple from "./Responses/Multiple";
import Quiz from "./Responses/Quiz";
import ShortText from "./Responses/ShortText";
import Pictures from "./Responses/Pictures";
import Path from "./Responses/Path";
import Rating from "./Responses/Rating";
import Discount from "./Rewards/Discount";
import Bonus from "./Rewards/Bonus";
import Monetary from "./Rewards/Monetary";
import NewBarcode from "./Challenges/[DEPRECATED]NewBarcode/NewBarcode";
import Novelty from "./Rewards/Novelty";

// TYPES
// location, video, image, url, scan_qr, photo, new_qr, multiple, short_text,
// pictures, yes_no, rating, long_text, discount, bonus, bonus, novelty

const renderStep = (el, handleEdit, handleEditingTimer) => {
  if (el.type === "location") {
    return (
      <Location
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "video") {
    return (
      <Video
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "check_text") {
    return (
      <CheckText
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "image") {
    return (
      <Image
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "url") {
    return (
      <Url
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "scan_qr") {
    return (
      <Scan
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "photo") {
    return (
      <Photo
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "new_qr") {
    return (
      <NewQR
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "multiple") {
    return (
      <Multiple
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "quiz") {
    return (
      <Quiz
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "short_text") {
    return (
      <ShortText
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "pictures") {
    return (
      <Pictures
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "yes_no") {
    return (
      <Path
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "rating") {
    return (
      <Rating
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "discount") {
    return <Discount {...el} handleEdit={handleEdit} handleEditingTimer={handleEditingTimer} />;
  } else if (el.type === "bonus") {
    return <Bonus {...el} handleEdit={handleEdit} handleEditingTimer={handleEditingTimer} />;
  } else if (el.type === "monetary") {
    return (
      <Monetary
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "new_barcode") {
    return (
      <NewBarcode
        {...el}
        expanded={false}
        handleEdit={handleEdit}
        handleEditingTimer={handleEditingTimer}
      />
    );
  } else if (el.type === "novelty") {
    return <Novelty {...el} handleEdit={handleEdit} handleEditingTimer={handleEditingTimer} />;
  }
};

const getListStyle = () => ({
  background: "transparent",
  padding: 0,
  width: 640,
  margin: "80px auto"
});

const getItemStyle = (isDragging, draggableStyle, category, notEditing) => {
  return {
    userSelect: "none",
    padding: `15px 20px 18px 15px`,
    margin: `0 0 50px 0`,
    background: "#FFFFFF",
    border: isDragging
      ? category === "challenges"
        ? "2px solid #324EA3"
        : category === "responses"
        ? "2px solid #069284"
        : "2px solid #FE8F1D"
      : "none",
    borderRadius: "4px",
    opacity: notEditing ? "0.5" : "1",
    boxShadow:
      "0px 5px 23px rgba(89, 89, 89, 0.05), 0px 12px 19px rgba(92, 92, 92, 0.06), 0px 5px 7px rgba(88, 88, 88, 0.2)",
    // styles we need to apply on draggables
    ...draggableStyle
  };
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItems = (array, handleEdit, handleEditingTimer) =>
  array.map(el => ({
    id: `item_${el.id}`,
    content: renderStep(el, handleEdit, handleEditingTimer)
  }));

const calculatePercentOf = (value, sum) => Math.round((value / sum) * 100);

export { renderStep, getListStyle, getItemStyle, reorder, getItems, calculatePercentOf };
