import React from "react";
import { useTranslation } from "react-i18next";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { DATA_SOURCES_DOCUMENTATION_URL } from "../../../../../config";

const InitialInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="title">{t("t:data-sources.add-magento-as-data-source")}</div>
      <div className="add-magento__info">
        <p>{t("t:data-sources.add-magento--info-1")}</p>
        <br />
        <p>{t("t:data-sources.add-magento--info-2")}</p>
        <br />
        <p>{t("t:data-sources.add-magento--info-3")}</p>
        <p>{t("t:data-sources.add-magento--info-4")}</p>
        <p>{t("t:data-sources.add-magento--info-5")}</p>
        <p>{t("t:data-sources.add-magento--info-6")}</p>
      </div>
      <div className="btn_wrapper">
        <DefaultButton
          variant="contained"
          classes="auth"
          type="link"
          to="/main/add-magento-data-source/set-name-and-url"
        >
          {t("t:common.continue")}
        </DefaultButton>
        <a href={DATA_SOURCES_DOCUMENTATION_URL} target="_blank" rel="noopener noreferrer">
          <DefaultButton variant="contained" classes="generate_link">
            {t("t:data-sources.view-documentation")}
          </DefaultButton>
        </a>
      </div>
    </>
  );
};

export default InitialInfo;
