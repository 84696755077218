import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { calculatePercentOf } from "../../helpers";

import RatingIcon from "../../../../../assets/image/CampaignPanel/star_green.svg";
import StarIcon from "../../../../../assets/image/star-yellow.svg";

const Rating = () => {
  const { t } = useTranslation();

  const rating = useSelector(({ campaign }) => campaign.stepResults.rating);

  const ratingList = Array.from({ length: 5 }, (v, k) => ({
    id: k + 1,
    value: rating[k + 1]
  })).reverse();
  const ratingSum = ratingList.reduce((acc, item) => acc + item.value, 0);

  return (
    <div className="step-results__block">
      <img src={RatingIcon} alt="rating" className="step-results__icon" />
      <div className="step-results__info">
        <div className="step-results__info--header">
          <div className="step-results__info--title">{t("t:build-campaign.rating")}</div>
          <div className="step-results__info--header-rating">
            <span className="step-results__info--header-rating-score">
              {rating.score.toFixed(1)}
            </span>
            <img src={StarIcon} alt="star" className="step-results__info--header-rating-icon" />
            <p className="step-results__info--header-rating-total">
              {t("t:build-campaign.n-answers", { number: rating.total })}
            </p>
          </div>
        </div>
        <div className="step-results__info--bars">
          {ratingList.map(({ id, value }) => (
            <div className="step-results__info--bars-row" key={`rating-${id}`}>
              <div className="step-results__info--bars-label">
                <span>{id}</span>
                <img src={StarIcon} alt="star" className="step-results__info--bars-icon" />
              </div>
              <div className="step-results__info--bars-bar">
                <div
                  className="step-results__info--bars-filled"
                  style={{ width: `${calculatePercentOf(value, ratingSum)}%` }}
                />
              </div>
              <div className="step-results__info--bars-value">{value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Rating);
