import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

import { getMaxOfArray, splitByCommas } from "../../../../../../helpers/functions";
import { calculateAnalyticsGraphHeight } from "../../../helpers";
import DefaultButton from "../../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import CreateAudienceListDialog from "../CreateAudienceListDialog";
import AddToExistingListDialog from "../AddToExistingListDialog";

import ArrowTopImage from "../../../../../../assets/image/arrow-top-right.svg";
import ArrowBottomRedImage from "../../../../../../assets/image/arrow-top-left-red.svg";
import ArrowBottomImage from "../../../../../../assets/image/arrow-top-left.svg";
import ArrowTopRedImage from "../../../../../../assets/image/arrow-top-right-red.svg";
import AddIcon from "../../../../../../assets/image/i-add.svg";

let AnalyticsBlock = ({
  name,
  value,
  delta_percent,
  chart,
  percent,
  avg_age,
  top_region,
  gender_name,
  gender_percent,
  new_customers,
  periodValue
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isOpenCreateListDialog, setOpenCreateListDialog] = useState(false);
  const [isOpenAddToExistingListDialog, setOpenAddToExistingListDialog] = useState(false);
  const { t } = useTranslation();

  const maxValue = getMaxOfArray(chart);

  const renderPerWeekLine = useCallback(
    (percent, type) => {
      let img = ArrowTopImage;
      let positive = true;
      if (type === "Cancelled") {
        if (percent < 0) {
          img = ArrowBottomImage;
        } else {
          img = ArrowTopRedImage;
          positive = false;
        }
      } else {
        if (percent < 0) {
          img = ArrowBottomRedImage;
          positive = false;
        }
      }
      return (
        <div className="result_per_week">
          <img src={img} alt="per last week" />
          <span className={clsx(positive && "green")}>{percent}%</span>
          {t("t:campaign-analytics.per-last", { time_interval: periodValue.value })}
        </div>
      );
    },
    // eslint-disable-next-line
    [periodValue]
  );

  const onAddAudienceClick = () => setMenuIsOpen(true);

  const handleCreateListDialog = useCallback(() => setOpenCreateListDialog(value => !value), []);
  const handleAddToExistingListDialog = useCallback(
    () => setOpenAddToExistingListDialog(value => !value),
    []
  );

  const onAddToExistingListClick = () => {
    setMenuIsOpen(false);
    handleAddToExistingListDialog();
  };

  const onCreateNewListClick = () => {
    setMenuIsOpen(false);
    handleCreateListDialog();
  };

  const renderPercentOfLine = () => {
    let translationKey = "t:common.percent-of-started";
    if (name === "Viewed") translationKey = "t:common.percent-of-total-sent-to";
    if (name === "Accepted") translationKey = "t:common.percent-of-viewed";
    return <>{t(translationKey, { percent: `${percent || "0"}%` })}</>;
  };

  return (
    <div className="analytics_block">
      {value > 0 && (
        <div className="add-audience__btn">
          <DefaultButton variant="contained" classes="plus-icon-btn" onClick={onAddAudienceClick}>
            <img src={AddIcon} alt="add" />
          </DefaultButton>
          {menuIsOpen && (
            <ClickAwayListener onClickAway={() => setMenuIsOpen(false)}>
              <div className="add-audience__menu">
                <button className="add-audience__menu--option" onClick={onCreateNewListClick}>
                  + {t("t:campaign-analytics.create-new-list")}
                </button>
                <hr className="add-audience__menu--separator" />
                <button className="add-audience__menu--option" onClick={onAddToExistingListClick}>
                  {t("t:campaign-analytics.add-to-existing-list")}
                </button>
              </div>
            </ClickAwayListener>
          )}
        </div>
      )}
      <div className="title">{t(`t:campaign-analytics.${name.toLowerCase()}`)}</div>
      <div className="number">{splitByCommas(value || "0")}</div>
      <div className="percentage">{renderPercentOfLine()}</div>
      <div className="graph">
        {chart.map((el, key) => (
          <div
            className="line"
            key={`line-${key}`}
            style={{
              height: calculateAnalyticsGraphHeight(el, maxValue)
            }}
            title={el}
          />
        ))}
      </div>
      {renderPerWeekLine(delta_percent || 0, name)}
      <div className="details_table">
        <div className="details_line">
          <div>{t("t:campaign-analytics.avg-age")}</div>
          <div>{avg_age || "—"}</div>
        </div>
        <div className="details_line">
          <div>{t("t:campaign-analytics.top-region")}</div>
          <div>{top_region || "—"}</div>
        </div>
        <div className="details_line">
          <div>{t("t:campaign-analytics.gender")}</div>
          <div>{gender_percent ? `${gender_percent}% ${gender_name}` : "—"}</div>
        </div>
        <div className="details_line">
          <div>{t("t:campaign-analytics.new-customers")}</div>
          <div>{new_customers || "0"}%</div>
        </div>
      </div>
      <CreateAudienceListDialog
        isOpen={isOpenCreateListDialog}
        handleDialog={handleCreateListDialog}
        type={name.toLowerCase()}
        period={periodValue.value}
      />
      <AddToExistingListDialog
        isOpen={isOpenAddToExistingListDialog}
        handleDialog={handleAddToExistingListDialog}
        type={name.toLowerCase()}
        period={periodValue.value}
      />
    </div>
  );
};

export default AnalyticsBlock;
