import { PRODUCTS } from "../constants";

export function getProductsList(query = "") {
  return {
    type: PRODUCTS.GET_LIST,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/${query}`,
        method: "get"
      }
    }
  };
}

export function getProducts(arr) {
  return {
    type: PRODUCTS.GET_PRODUCTS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/${arr.length && arr.length !== 0 ? "?" : ""}${arr.join(
          "&"
        )}`,
        method: "get"
      }
    }
  };
}

export function postProduct(data) {
  return {
    type: PRODUCTS.POST_PRODUCT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/`,
        method: "post",
        data //image, name, price
      }
    }
  };
}

export function getProduct(id) {
  return {
    type: PRODUCTS.GET_PRODUCT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/${id}/`,
        method: "get"
      }
    }
  };
}

export function patchProduct(id, data) {
  return {
    type: PRODUCTS.PATCH_PRODUCT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/${id}/`,
        method: "patch",
        data
      }
    }
  };
}

export function deleteProduct(id) {
  return {
    type: PRODUCTS.DELETE_PRODUCT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/${id}/`,
        method: "delete"
      }
    }
  };
}

export function getColors() {
  return {
    type: PRODUCTS.GET_COLORS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/colors/`,
        method: "get"
      }
    }
  };
}

export function postProductImage(data) {
  return {
    type: PRODUCTS.POST_PRODUCT_IMAGE,
    payload: {
      client: "default",
      request: {
        url: `/company/current/products/upload-image/`,
        method: "post",
        data // image
      }
    }
  };
}

export function setProductError(data) {
  return {
    type: PRODUCTS.SET_ERROR,
    error: data
  };
}

export function resetProductErrors() {
  return {
    type: PRODUCTS.RESET_ERRORS
  };
}

export function resetProducts() {
  return {
    type: PRODUCTS.RESET_PRODUCTS
  };
}
