import { getOption } from "../../../helpers/functions";

const periodList = t => [
  { label: getOption(t("t:campaign-analytics.last-day")), value: "day" },
  { label: getOption(t("t:campaign-analytics.last-week")), value: "week" },
  { label: getOption(t("t:campaign-analytics.last-month")), value: "month" },
  { label: getOption(t("t:campaign-analytics.last-quarter")), value: "quarter" },
  { label: getOption(t("t:campaign-analytics.last-year")), value: "year" }
];

export { periodList };
