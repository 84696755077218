import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import GoogleMapLoader from "react-google-maps-loader";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import SelectComponent from "../../../../HelperComponents/SelectComponent/SelectComponent";
import {
  genderList,
  numberRegexp,
  olderThanList,
  performedList,
  periodsList,
  youngerThanList
} from "../../../constants";
import { setCustomAudienceGroupField } from "../../../../../actions/audienceActions";
import TagsComponent from "../../../../HelperComponents/TagsComponent";
import { getHashtagSuggestions, postHashtag } from "../../../../../actions/campaignActions";
import { GOOGLE_API_KEY } from "../../../../../config";
import GoogleMaps from "../../../../HelperComponents/GoogleMaps/GoogleMaps";
import GoogleSuggest from "../../../../HelperComponents/GoogleSuggest/GoogleSuggest";

const SegmentGroup = ({
  groupIndex,
  withDeleteButton,
  onRemoveGroupClick,
  action,
  period,
  age_from,
  age_to,
  location_point,
  interests,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const interestsList = useSelector(({ campaign }) => campaign.interests);

  const onGroupFieldChange = (fieldName, fieldValue) => {
    dispatch(setCustomAudienceGroupField(groupIndex, { [fieldName]: fieldValue }));
  };

  const fields = useMemo(
    () => ({
      action: performedList(t).find(el => el.value === action),
      period: periodsList(t).find(el => el.value === period),
      age_from: olderThanList(t).find(el => el.value === age_from),
      age_to: youngerThanList(t).find(el => el.value === age_to),
      location_point: { lat: location_point.latitude, lng: location_point.longitude },
      interests: interests.map(el => {
        if (el.id) return el.id;
        return el;
      }),
      ...rest
    }),
    // eslint-disable-next-line
    [action, period, rest]
  );

  const onMultipleValueChange = (valueKey, selectedValue) => {
    if (fields[valueKey].includes(selectedValue)) {
      onGroupFieldChange(
        valueKey,
        fields[valueKey].filter(el => el !== selectedValue)
      );
    } else {
      onGroupFieldChange(valueKey, [...fields[valueKey], selectedValue]);
    }
  };

  const onNumberFieldChange = (valueKey, value) => {
    if (value === "" || numberRegexp.test(value) || value.length < fields[valueKey].length) {
      onGroupFieldChange(valueKey, value);
    }
  };

  const onGoogleSuggestChoose = geocodedLocation => {
    onGroupFieldChange("location_point", {
      latitude: geocodedLocation.geometry.location.lat(),
      longitude: geocodedLocation.geometry.location.lng()
    });
    onGroupFieldChange("location_description", geocodedLocation.formatted_address);
  };

  const onMapClick = (coordinates, address) => {
    onGroupFieldChange("location_point", {
      latitude: coordinates.lat,
      longitude: coordinates.lng
    });
    onGroupFieldChange("location_description", address);
  };

  return (
    <div className="custom-audience__segment-group">
      <div className="custom-audience__segment-group--title">
        <p className="custom-audience__segment-group--title-text">
          {t("t:audience.group-n-with-users-who", { index_number: groupIndex + 1 })}
        </p>
      </div>
      <GoogleMapLoader
        params={{
          key: GOOGLE_API_KEY,
          libraries: "places, geocode, maps",
          language: "en"
        }}
        render={googleMaps =>
          googleMaps && (
            <div className="custom-audience__segment-group--content-box">
              <div className="custom-audience__segment-group--fields-box mb-20">
                <p className="custom-audience__segment-group--inline-text bold-text">
                  {t("t:audience.performed")}
                </p>
                <FormControl className="custom-audience__segment-group--field">
                  <SelectComponent
                    value={fields.action}
                    options={performedList(t)}
                    change={({ value }) => onGroupFieldChange("action", value)}
                    isClearable="false"
                    isSearchable={false}
                    placeholder={t("t:common.choose")}
                  />
                </FormControl>
              </div>
              <div className="custom-audience__segment-group--fields-box mb-40">
                <p className="custom-audience__segment-group--inline-text">
                  {t("t:audience.at-least")}
                </p>
                <input
                  className="default-input small-input"
                  type="text"
                  value={fields.action_value}
                  onChange={e => onNumberFieldChange("action_value", e.target.value)}
                />
                <p className="custom-audience__segment-group--inline-text">
                  {t("t:audience.times")}
                </p>
                <p className="custom-audience__segment-group--inline-text">
                  {t("t:audience.within-last")}
                </p>
                <input
                  className="default-input small-input"
                  type="text"
                  value={fields.period_value}
                  onChange={e => onNumberFieldChange("period_value", e.target.value)}
                />
                <FormControl className="custom-audience__segment-group--field small-selector">
                  <SelectComponent
                    value={fields.period}
                    options={periodsList(t)}
                    change={({ value }) => onGroupFieldChange("period", value)}
                    isClearable="false"
                    isSearchable={false}
                    placeholder={t("t:common.choose")}
                  />
                </FormControl>
              </div>
              <div className="custom-audience__segment-group--fields-box mb-40">
                <p className="custom-audience__segment-group--inline-text bold-text">
                  {t("t:audience.are-older-than")}
                </p>
                <FormControl className="custom-audience__segment-group--field small-selector mr-auto">
                  <SelectComponent
                    value={fields.age_from}
                    options={olderThanList(t)}
                    change={({ value }) => onGroupFieldChange("age_from", value)}
                    isClearable="false"
                    isSearchable={false}
                    placeholder={t("t:common.choose")}
                  />
                </FormControl>
                <p className="custom-audience__segment-group--inline-text bold-text">
                  {t("t:audience.and-younger-than")}
                </p>
                <FormControl className="custom-audience__segment-group--field small-selector">
                  <SelectComponent
                    value={fields.age_to}
                    options={youngerThanList(t)}
                    change={({ value }) => onGroupFieldChange("age_to", value)}
                    isClearable="false"
                    isSearchable={false}
                    placeholder={t("t:common.choose")}
                  />
                </FormControl>
              </div>
              <div className="custom-audience__segment-group--fields-box mb-40">
                <p className="custom-audience__segment-group--inline-text bold-text">
                  {t("t:audience.and-are")}*
                </p>
                <div className="item_checkbox no_select">
                  {genderList(t).map(({ labelKey, value }) => (
                    <div onClick={() => onMultipleValueChange("gender", value)} key={labelKey}>
                      <input
                        type="checkbox"
                        checked={fields.gender.includes(value)}
                        onChange={() => console.log("changed")}
                      />
                      <label htmlFor="radio1">{t(labelKey)}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="custom-audience__segment-group--fields-box flex-col mb-40">
                <p className="custom-audience__segment-group--inline-text bold-text mb-15">
                  {t("t:audience.and-are-interested-in")}*
                </p>
                <div className="interests_wrapper">
                  {interestsList.map(({ id, name }) => (
                    <div
                      className={clsx(
                        "interest_item",
                        "no_select",
                        !!fields.interests.includes(id) && "chosen_one"
                      )}
                      onClick={() => onMultipleValueChange("interests", id)}
                      key={`interest-item-${id}`}
                    >
                      {name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="custom-audience__segment-group--fields-box flex-col mb-40">
                <p className="custom-audience__segment-group--inline-text bold-text mb-15">
                  {t("t:audience.and-also-match-hashtags")}
                </p>
                <TagsComponent
                  tags={fields.hashtags}
                  changeTags={value => onGroupFieldChange("hashtags", value)}
                  getSuggestions={value => dispatch(getHashtagSuggestions(value))}
                  addNewHashtag={value => dispatch(postHashtag(value))}
                />
              </div>
              <div className="custom-audience__segment-group--fields-box mb-20">
                <p className="custom-audience__segment-group--inline-text bold-text">
                  {t("t:audience.and-lives-in")}
                </p>
                <div className="input-with-adornment">
                  <input
                    className="default-input radius-input"
                    type="text"
                    value={fields.location_radius || ""}
                    onChange={e => onNumberFieldChange("location_radius", e.target.value)}
                    placeholder={t("t:common.radius")}
                  />
                  <span className="adornment">KM</span>
                </div>
                <p className="custom-audience__segment-group--inline-text">
                  {t("t:audience.from")}
                </p>
                <div className="suggest-wrapper">
                  <GoogleSuggest
                    address={fields.location_description}
                    handleLocation={onGoogleSuggestChoose}
                    googleMaps={googleMaps}
                    campaignError={{}}
                    placeholder={t("t:common.address")}
                    inputClasses="default-input address-input"
                  />
                </div>
              </div>
              <div className="custom-audience__segment-group--fields-box mb-20">
                <p className="custom-audience__segment-group--inline-text">
                  {t("t:common.or-select-area-on-map")}
                </p>
              </div>
              <div className="custom-audience__segment-group--fields-box">
                <div className="map_wrapper">
                  <div>
                    <GoogleMaps
                      isMarkerShown
                      coordinates={fields.location_point}
                      radius={fields.location_radius}
                      googleMaps={googleMaps}
                      onMapClick={onMapClick}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                </div>
              </div>
              {withDeleteButton && (
                <DefaultButton
                  variant="contained"
                  classes="cancel-btn"
                  type="button"
                  onClick={onRemoveGroupClick}
                >
                  {t("t:audience.delete-group")}
                </DefaultButton>
              )}
            </div>
          )
        }
      />
    </div>
  );
};

export default memo(SegmentGroup);
