import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import { timePeriodError } from "../../helpers/errorMessages";
import SwitchComponent from "../../../../HelperComponents/SwitchComponent/SwitchComponent";
import Calendar from "../../../../HelperComponents/Calendar/Calendar";
import SelectComponent from "../../../../HelperComponents/SelectComponent/SelectComponent";
import { formatDate, timeList } from "../../../../../helpers/functions";

import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";
import Shape from "../../../../../assets/image/My_Campaign/Shape.svg";

const TimePeriod = ({
  distributionError,
  startDateIsInvalid,
  endDateIsInvalid,
  isEditor,
  available_immediately,
  no_deadline,
  end_date,
  start_date,
  start_time,
  end_time,
  onSetFieldsInState,
  handleSelectChange
}) => {
  const { t } = useTranslation();

  const handleDateChange = (date, name) => {
    if (date !== null) {
      if (date.toString() !== "Invalid Date") {
        onSetFieldsInState({ [name]: formatDate(date) });
        name === "start_date"
          ? onSetFieldsInState({ startDateIsInvalid: false })
          : onSetFieldsInState({ endDateIsInvalid: false });
      } else {
        name === "start_date"
          ? onSetFieldsInState({ startDateIsInvalid: true })
          : onSetFieldsInState({ endDateIsInvalid: true });
      }
    } else {
      name === "start_date"
        ? onSetFieldsInState({ startDateIsInvalid: false })
        : onSetFieldsInState({ endDateIsInvalid: false });
    }
  };

  const changeAvailableImmediately = () => {
    onSetFieldsInState({
      available_immediately: !available_immediately,
      startDateIsInvalid: false
    });
  };

  const changeNoDeadline = () => {
    onSetFieldsInState({
      no_deadline: !no_deadline,
      endDateIsInvalid: false
    });
  };

  return (
    <div className="distribution_row_wrapper">
      <div className="row_name">
        {t("t:distribution.time-period")}
        {distributionError.availability_start_date ||
        distributionError.availability_start_time ||
        distributionError.availability_end_date ||
        distributionError.availability_end_time ||
        startDateIsInvalid ||
        endDateIsInvalid ? (
          <TooltipMessage
            text={timePeriodError(distributionError, startDateIsInvalid, endDateIsInvalid, t)}
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
      </div>
      <div className="row_content">
        <SwitchComponent
          labelLeftSide={false}
          disabled={!isEditor}
          label={t("t:distribution.available-immediately")}
          defaultChecked={available_immediately}
          onChange={changeAvailableImmediately}
          height={20}
          width={40}
          handleDiameter={12}
        />
        <div
          className="input_title_block_my_campaign margin_bottom_15"
          style={{ opacity: available_immediately ? "0.5" : "1" }}
        >
          <div>
            <span>{t("t:distribution.start-date-and-time")}</span>
            <TooltipMessage
              text={t("t:helper-texts.distribution-time-period-start-date")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <div className="distribution_line">
            <Calendar
              maxDate={end_date ? new Date(end_date) : undefined}
              handleDateChange={handleDateChange}
              name="start_date"
              prevdate={start_date}
              disabled={!isEditor || available_immediately}
            />
            <span>{t("t:common.time-period-date-AT-time")}</span>
            <FormControl className={clsx("select_wrapper", !isEditor && "cursor_not_allowed")}>
              <SelectComponent
                disabled={!isEditor || available_immediately}
                value={start_time}
                options={timeList}
                change={handleSelectChange("start_time")}
                isClearable="false"
                isSearchable={false}
                placeholder="hh:mm"
              />
            </FormControl>
          </div>
        </div>
        <SwitchComponent
          labelLeftSide={false}
          disabled={!isEditor}
          label={t("t:distribution.no-deadline")}
          defaultChecked={no_deadline}
          onChange={changeNoDeadline}
          height={20}
          width={40}
          handleDiameter={12}
        />
        <div
          className="input_title_block_my_campaign"
          style={{ opacity: no_deadline ? "0.5" : "1" }}
        >
          <div>
            <span>{t("t:distribution.end-date-and-time")}</span>
            <TooltipMessage
              text={t("t:helper-texts.distribution-time-period-end-date")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <div className="distribution_line">
            <Calendar
              minDate={new Date(start_date)}
              handleDateChange={handleDateChange}
              name="end_date"
              prevdate={end_date}
              disabled={!isEditor || no_deadline}
            />
            <span>{t("t:common.time-period-date-AT-time")}</span>
            <FormControl className={clsx("select_wrapper", !isEditor && "cursor_not_allowed")}>
              <SelectComponent
                disabled={!isEditor || no_deadline}
                value={end_time}
                options={timeList}
                change={handleSelectChange("end_time")}
                isClearable="false"
                isSearchable={false}
                placeholder="hh:mm"
              />
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimePeriod;
