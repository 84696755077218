import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { Image as CloudinaryImage } from "cloudinary-react";
import clsx from "clsx";

import SelectComponent from "../../../../HelperComponents/SelectComponent/SelectComponent";
import DialogComponent from "../../../../HelperComponents/DialogComponent/DialogComponent";
import { getOption } from "../../../../../helpers/functions";
import CreateProduct from "../../../../Products/CreateProduct/CreateProduct";
import { getProductsList } from "../../../../../actions/productsActions";
import Sizes from "./Sizes";

import "./styles.scss";

const ProductSelector = ({
  name,
  handleChange,
  value,
  onAddingNewProduct,
  fullPrice,
  productImage,
  sizes,
  setSizes,
  campaignError,
  campaignID
}) => {
  const [isAddNewDialogOpen, setAddNewDialogOpen] = useState(false);
  const isEditor = useSelector(({ campaign }) => campaign.campaignViewers.is_editor);
  const products = useSelector(({ products }) => products.list);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sizesErrorKey = name === "productOption" ? "product_sizes" : "gift_sizes";

  const fetchProducts = () => dispatch(getProductsList(`?campaign_id=${campaignID}`));

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, []);

  const productsListForSelector = useMemo(() => {
    const tempListForSelector =
      products !== "loading"
        ? products.map(el => ({ label: getOption(el.name), value: el.id, name: el.name }))
        : [];
    return [
      ...tempListForSelector,
      {
        label: getOption(`+ ${t("t:products.add-product")}`),
        value: null,
        onAddNewOption: () => setAddNewDialogOpen(true)
      }
    ];
    // eslint-disable-next-line
  }, [JSON.stringify(products)]);

  const onSuccess = newProduct => {
    onAddingNewProduct(newProduct);
    fetchProducts();
    setAddNewDialogOpen(false);
  };

  return (
    <>
      <FormControl
        className={clsx(
          "select_wrapper",
          !!value && products !== "loading" && "select-with-product"
        )}
      >
        <SelectComponent
          disabled={!isEditor}
          value={value}
          options={productsListForSelector}
          loading={products === "loading"}
          loadingMessage={t("t:build-campaign.loading-products")}
          change={handleChange(name)}
          isClearable="false"
          isSearchable
          placeholder={t("t:build-campaign.choose-product")}
        />
      </FormControl>
      {value && products !== "loading" && (
        <>
          <div className="selected-product">
            <div className="selected-product__icon">
              <CloudinaryImage
                cloudName="campy"
                width="50"
                height="50"
                gravity="face"
                crop="thumb"
                publicId={productImage?.split("/").pop() || ""}
              />
            </div>
            <div className="selected-product__price">
              <div className="selected-product__price--label">{t("t:products.full-price")}</div>
              <div className="selected-product__price--value">{(+fullPrice).toFixed(2)} €</div>
            </div>
          </div>
          <Sizes
            productKey={name}
            sizes={sizes}
            setSizes={setSizes}
            errors={campaignError ? campaignError[sizesErrorKey] : []}
            disabled={!isEditor}
          />
        </>
      )}
      <DialogComponent
        open={isAddNewDialogOpen}
        onClose={() => setAddNewDialogOpen(false)}
        closeIcon
      >
        <div className="add-new-product__dialog">
          <CreateProduct
            pageTitle={t("t:products.add-product")}
            productNameText={`${t("t:products.product-name")} *`}
            productDescriptionText={`${t("t:products.product-description")} *`}
            productUrlText={t("t:products.product-url")}
            productImageText={`${t("t:products.product-images")} *`}
            productFullPriceText={`${t("t:products.product-full-price")} *`}
            productColorText={t("t:products.color")}
            submitButtonText={t("t:common.add")}
            onSuccess={onSuccess}
            onCancel={() => setAddNewDialogOpen(false)}
          />
        </div>
      </DialogComponent>
    </>
  );
};

export default ProductSelector;
