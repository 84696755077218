import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  deleteOneStep,
  patchCheckTextStep,
  resetCampaignErrors,
  getCampaignSteps,
  blockOtherActions,
  setCurrentStepInfo
} from "../../../../../actions/campaignActions";
import { setLoading } from "../../../../../actions/dashboardActions";
import { renderStepPlaceholder } from "../../../../../helpers/functions";
import Snack from "../../../../HelperComponents/Snack/Snack";
import CheckTextExpandedView from "./CheckTextExpandedView";

import check_text_blue from "../../../../../assets/image/My_Campaign/check-text.svg";
import deleteIcon from "../../../../../assets/image/My_Campaign/btn-cancel.svg";
import edit from "../../../../../assets/image/btn-edit.svg";
import dragHandle from "../../../../../assets/image/drag-handle.svg";
import eye from "../../../../../assets/image/My_Campaign/eye.svg";

import "./styles.scss";

class CheckText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: props.match.params.id,
      popperOpened: false,
      title: props.detail.title,
      description: props.detail.description,
      text: props.detail.text,
      openSnack: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.popperOpened !== this.state.popperOpened) {
      this.props.handleEdit(this.props.id);
      this.props.blockOtherActions(this.state.popperOpened);
      this.props.isEditor && this.props.handleEditingTimer();
      if (this.state.popperOpened) {
        this.setInfoForMobilePreview();
      } else {
        this.props.setCurrentStepInfo({
          step: "not-selected"
        });
      }
    }

    if (this.infoForPreviewWasChanged(this.state, prevState)) {
      this.setInfoForMobilePreview();
    }

    if (this.props.campaignError) {
      if (prevState !== this.state) {
        this.props.resetCampaignErrors();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCampaignErrors();
  }

  infoForPreviewWasChanged = (currState, prevState) => {
    return currState.title !== prevState.title || currState.description !== prevState.description;
  };

  setInfoForMobilePreview = () => {
    const { title, description } = this.state;

    this.props.setCurrentStepInfo({
      step: "check_text",
      check_text: {
        title,
        description
      }
    });
  };

  openPopper = () => this.setState({ popperOpened: true });

  closePopper = () => this.setState({ popperOpened: false });

  deleteStep = () => {
    const { deleteOneStep, id, campaign, handleEditingTimer } = this.props;
    deleteOneStep(campaign, id);
    handleEditingTimer();
    this.closePopper();
  };

  changeStep = async () => {
    const { patchCheckTextStep, id, getCampaignSteps, setLoading, handleEditingTimer } = this.props;
    const { title, description, text, campaignId } = this.state;
    setLoading(true);
    const data = {
      title,
      description,
      text
    };
    const res = await patchCheckTextStep(campaignId, id, data);
    handleEditingTimer();
    if (res.payload) {
      getCampaignSteps(campaignId).then(() => setLoading(false));
      this.setState({ openSnack: true });
      this.closePopper();
    } else {
      setLoading(false);
    }
  };

  onTitleChange = textInput => {
    const { title } = this.state;
    if (textInput.length < 51 || textInput.length < title) {
      this.setState({ title: textInput });
    } else {
      this.setState({ title: textInput.slice(0, 50) });
    }
  };

  render() {
    const { type, detail, blockActions, isEditor, t, campaignError } = this.props;
    const { popperOpened, openSnack, title, description, text } = this.state;

    return (
      <div className="wrapper_block_my_campaign step_wrapper">
        <div className="head_block head_block_step">
          <div className="title_head">
            <div className="not_expanded">
              <img src={dragHandle} alt="draggable" />
              <img src={check_text_blue} alt="check text" />
            </div>
            <div>
              <p>{t("t:build-campaign.check-text")}</p>
              <span>{renderStepPlaceholder(detail.title, type, t)}</span>
            </div>
          </div>
          {isEditor ? (
            <div className="btn_head">
              <button onClick={this.deleteStep} disabled={blockActions}>
                <img src={deleteIcon} alt="trash" />
              </button>
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={edit} alt="save" />
              </button>
            </div>
          ) : (
            <div className="btn_head">
              <button onClick={this.openPopper} disabled={blockActions}>
                <img src={eye} alt="open" />
              </button>
            </div>
          )}
        </div>
        {popperOpened && (
          <div className="challenges_wrapper wrapper_block_my_campaign check_text_wrapper">
            <CheckTextExpandedView
              type={type}
              detail={detail}
              toClosePopper={this.closePopper}
              title={title}
              description={description}
              text={text}
              isEditor={isEditor}
              campaignError={campaignError}
              deleteStep={this.deleteStep}
              changeStep={this.changeStep}
              onTitleChange={this.onTitleChange}
              changeState={fields => this.setState(fields)}
            />
          </div>
        )}
        <Snack
          open={openSnack}
          handleSnack={() => this.setState({ openSnack: false })}
          message={t("t:snack-messages.successfully-changed")}
          type="success"
        />
      </div>
    );
  }
}

function mapStateToProps({ campaign }) {
  return {
    campaignError: campaign.campaignError,
    blockActions: campaign.blockActions,
    isEditor: campaign.campaignViewers.is_editor
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteOneStep,
      patchCheckTextStep,
      resetCampaignErrors,
      getCampaignSteps,
      blockOtherActions,
      setLoading,
      setCurrentStepInfo
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CheckText)));
