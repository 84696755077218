import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image as CloudinaryImage } from "cloudinary-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { splitByCommas } from "../../../helpers/functions";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";

import { getColors, getProduct } from "../../../actions/productsActions";

import eye from "../../../assets/image/My_Campaign/eye.svg";
import BackIcon from "../../../assets/image/small-left.svg";

import "./styles.scss";

const ViewProduct = () => {
  const { t } = useTranslation();
  const { id: productId } = useParams();
  const dispatch = useDispatch();
  const {
    name,
    description,
    api_product_id,
    url,
    images,
    price,
    vat_percent: vat,
    color: colorID,
    status
  } = useSelector(({ products }) => products.productInfo);
  const colorsList = useSelector(({ products }) => products.colors);
  const [loaded, setLoaded] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);

  const color = colorsList.find(el => el.id === colorID);

  const fetchProductAndColors = async () => {
    await dispatch(getProduct(productId));
    await dispatch(getColors());
  };

  useEffect(() => {
    fetchProductAndColors().then(() => {
      setLoaded(true);
    });
    // eslint-disable-next-line
  }, []);

  if (!loaded) return null;

  return (
    <div className="view-product">
      <div className="view-product__wrapper">
        <Link to="/main/products" className="text_hover view-product__back">
          <img src={BackIcon} alt="back" className="view-product__back--icon" />
          {t("t:data-sources.back-to-products")}
        </Link>
        <div className="view-product__title">{name}</div>
        <div className="view-product__label">{t("t:products.product-name")}</div>
        <div className="view-product__value">{name}</div>
        <div className="view-product__label">{t("t:products.product-description")}</div>
        <div className="view-product__value">{description || "—"}</div>
        <div className="view-product__label">{t("t:products.product-id")}</div>
        <div className="view-product__value">{api_product_id || "—"}</div>
        <div className="view-product__label">{t("t:products.product-url")}</div>
        <div className="view-product__value is-url">
          {url ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          ) : (
            "—"
          )}
        </div>
        <div className="view-product__label">{t("t:products.product-images")}</div>
        <div className="view-product__images">
          {images.map(({ id, image }) => (
            <div className="view-product__value is-image" key={id}>
              <CloudinaryImage
                cloudName="campy"
                width="39"
                height="39"
                gravity="face"
                crop="thumb"
                publicId={image.split("/").pop()}
              />
              <p>{image.split("/").pop()}</p>
              <button onClick={() => setPreviewDialogOpen(id)}>
                <img src={eye} alt="eye" />
              </button>
            </div>
          ))}
        </div>
        <div className="view-product__values">
          <div>
            <div className="view-product__label">{t("t:products.product-full-price")}</div>
            <div className="view-product__value">{splitByCommas(price || "0.00")} €</div>
          </div>
          <div>
            <div className="view-product__label">{t("t:products.vat")}</div>
            <div className="view-product__value">{vat} %</div>
          </div>
        </div>
        <div className="view-product__label">{t("t:products.color")}</div>
        <div className="view-product__value">
          {color ? (
            <div className="items-center">
              <div
                className="view-product__color-preview"
                style={{
                  backgroundColor: `#${color.hex}`,
                  border: color.hex === "FFFFFF" ? "1px solid #EAEAEA" : "1px solid transparent"
                }}
              />
              <p>{color.name}</p>
            </div>
          ) : (
            <div>—</div>
          )}
        </div>
        {status === "available" && (
          <DefaultButton
            variant="contained"
            classes="add_product"
            type="link"
            to={`/main/products/edit-product/${productId}`}
          >
            {t("t:common.edit")}
          </DefaultButton>
        )}
      </div>
      <DialogComponent
        open={!!previewDialogOpen}
        onClose={() => setPreviewDialogOpen(false)}
        closeIcon
        classes="image_dialog"
        rootClass="image_root_dialog"
      >
        <div>
          <img src={images.find(i => i.id === previewDialogOpen)?.image} alt="preview" />
        </div>
      </DialogComponent>
    </div>
  );
};

export default ViewProduct;
