import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { getCSVBasedAudienceByID } from "../../../../actions/audienceActions";
import SkeletonComponent from "../../../HelperComponents/SkeletonComponent/SkeletonComponent";

import BackIcon from "../../../../assets/image/small-left.svg";

import "../../styles.scss";

const ViewAudience = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const {
    csvBasedAudienceInfo: { name, description }
  } = useSelector(({ audience }) => audience);

  useEffect(() => {
    dispatch(getCSVBasedAudienceByID(id));
    // eslint-disable-next-line
  }, []);

  const onEditAudienceClick = () => {
    history.push(`/main/audience/csv-based/edit/${id}`);
  };

  return (
    <div className="audience-page__page align-start">
      <div className="audience-page__wrapper view-single">
        <Link to="/main/audience?tab=csv_based" className="audience-page__back text_hover">
          <img src={BackIcon} alt="" />
          {t("t:audience.back-to-audience")}
        </Link>
        <div className="audience-page__name">
          <SkeletonComponent variant="text" loading={!name} width={300}>
            <p>{name}</p>
          </SkeletonComponent>
        </div>
        <div className="audience-page__view-label">{t("t:audience.audience-name")}</div>
        <div className="audience-page__view-value">
          <SkeletonComponent variant="text" loading={!name} width={200}>
            <p>{name}</p>
          </SkeletonComponent>
        </div>
        <div className="audience-page__view-label">{t("t:audience.audience-description")}</div>
        <div className="audience-page__view-value">
          <SkeletonComponent variant="text" loading={!description} width={500}>
            <p>{description}</p>
          </SkeletonComponent>
        </div>
        <div className="audience-page__edit-btn-wrapper">
          <DefaultButton
            variant="contained"
            classes="auth audience-page__edit-btn"
            type="button"
            onClick={onEditAudienceClick}
          >
            {t("t:common.edit")}
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default ViewAudience;
