import React from "react";
import { useTranslation } from "react-i18next";

import InfoIcon from "../../../../../assets/image/c-info.svg";

const AnalyticsPanel = () => {
  const { t } = useTranslation();
  return (
    <div className="analytics_panel">
      <div className="info_wrapper">
        <div className="title_wrapper">
          <p>{t("t:support-info.analytics-about-title")}</p>
          <img src={InfoIcon} alt="info" />
        </div>
        <div className="separator_block" />
        <div className="info_text big_margin_bottom">
          {t("t:support-info.analytics-about-description")}
        </div>
        <h4>{t("t:support-info.analytics-shared-title")}</h4>
        <div className="info_text small_margin_bottom">
          {t("t:support-info.analytics-shared-description")}
        </div>
        <h4>{t("t:support-info.analytics-invites-title")}</h4>
        <div className="info_text small_margin_bottom">
          {t("t:support-info.analytics-invites-description")}
        </div>
        <h4>{t("t:support-info.analytics-customer-acquisition-cost-title")}</h4>
        <div className="info_text small_margin_bottom">
          {t("t:support-info.analytics-customer-acquisition-cost-description")}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsPanel;
