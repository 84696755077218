import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Image as CloudinaryImage } from "cloudinary-react";

import DialogComponent from "../../../../HelperComponents/DialogComponent/DialogComponent";

import PicturesIcon from "../../../../../assets/image/CampaignPanel/layers_green.svg";
import CheckIcon from "../../../../../assets/image/tick.svg";

const Pictures = () => {
  const [previewImage, setPreviewImage] = useState(null);
  const { t } = useTranslation();

  const pictures = useSelector(({ campaign }) => campaign.stepResults.pictures);

  const resetPreviewImage = () => setPreviewImage(null);

  return (
    <div className="step-results__block">
      <img src={PicturesIcon} alt="pictures" className="step-results__icon" />
      <div className="step-results__info">
        <div className="step-results__info--header">
          <div className="step-results__info--title">{t("t:build-campaign.pictures")}</div>
        </div>
        <div className="step-results__info--multiple">
          {pictures.map(({ url, name, count, is_most_popular: mostPopular }, key) => (
            <div key={`multiple-${key}`} className="step-results__info--multiple-row">
              <button
                className="step-results__info--multiple-image-wrapper"
                onClick={() => setPreviewImage(url)}
              >
                <CloudinaryImage
                  cloudName="campy"
                  width="24"
                  height="24"
                  gravity="face"
                  crop="thumb"
                  publicId={url.split("/").pop()}
                />
              </button>
              {mostPopular && (
                <img
                  src={CheckIcon}
                  alt="most popular"
                  className="step-results__info--multiple-check"
                />
              )}
              <p
                className={clsx(
                  "step-results__info--multiple-text with-image",
                  mostPopular && "most-popular"
                )}
                title={name}
              >
                {name}
              </p>
              <hr className="step-results__info--multiple-separator" />
              <p
                className={clsx(
                  "step-results__info--multiple-count",
                  mostPopular && "most-popular"
                )}
              >
                {count}
              </p>
            </div>
          ))}
        </div>
      </div>
      <DialogComponent
        open={!!previewImage}
        onClose={resetPreviewImage}
        closeIcon
        classes="image_dialog"
        rootClass="image_root_dialog"
      >
        <div>
          <img src={previewImage} alt="preview" />
        </div>
      </DialogComponent>
    </div>
  );
};

export default memo(Pictures);
