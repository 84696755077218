import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import TooltipMessage from "../../../HelperComponents/TooltipMessage/TooltipMessage";
import {
  getAnalyticsBasedAudienceByID,
  patchAnalyticsBasedAudienceByID,
  setAnalyticsBasedAudienceInfo
} from "../../../../actions/audienceActions";

const EditAudience = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { buttonLoading, analyticsBasedAudienceInfo } = useSelector(({ audience }) => audience);

  useEffect(() => {
    dispatch(getAnalyticsBasedAudienceByID(id));
    // eslint-disable-next-line
  }, []);

  const fieldsAreMissing = !analyticsBasedAudienceInfo.name;

  const onFieldChange = (name, value) => {
    dispatch(setAnalyticsBasedAudienceInfo({ ...analyticsBasedAudienceInfo, [name]: value }));
  };

  const onEditAudienceClick = async () => {
    dispatch(patchAnalyticsBasedAudienceByID(id, analyticsBasedAudienceInfo)).then(res => {
      if (res.payload) history.push("/main/audience?tab=analytics_based");
    });
  };

  return (
    <div className="audience-page__page">
      <div className="audience-page__wrapper">
        <div className="audience-page__title">{t("t:audience.edit-segment")}</div>
        <div className="audience-page__label edit-mode">{t("t:audience.segment-name")}*</div>
        <div className="audience-page__input-wrapper edit-mode">
          <input
            type="text"
            value={analyticsBasedAudienceInfo.name}
            onChange={e => onFieldChange("name", e.target.value)}
          />
        </div>
        <div className="audience-page__label edit-mode">{t("t:audience.segment-description")}</div>
        <div className="audience-page__input-wrapper edit-mode">
          <textarea
            value={analyticsBasedAudienceInfo.description}
            onChange={e => onFieldChange("description", e.target.value)}
          />
        </div>
        <TooltipMessage
          text={fieldsAreMissing ? t("t:common.enter-mandatory-fields") : ""}
          delay={200}
          position="top"
          classes="step_tooltip"
        >
          <div>
            <DefaultButton
              variant="contained"
              classes="auth audience-page__submit-btn"
              type="button"
              onClick={onEditAudienceClick}
              disabled={fieldsAreMissing}
              loading={buttonLoading}
            >
              {t("t:common.save-changes")}
            </DefaultButton>
          </div>
        </TooltipMessage>
        <Link to="/main/audience?tab=analytics_based" className="text_hover audience-page__cancel">
          {t("t:common.cancel")}
        </Link>
      </div>
    </div>
  );
};

export default EditAudience;
