import React from "react";
import { useTranslation } from "react-i18next";

import SkeletonComponent from "../../../../HelperComponents/SkeletonComponent/SkeletonComponent";
import HelpTooltip from "../../../../HelperComponents/HelpTooltip";
import { calculateFeeWithVAT, splitByCommas } from "../../../../../helpers/functions";

const Finalized = ({
  loading,
  actual_total_cost,
  actual_participants,
  actual_bonus_cost,
  actual_fee,
  actual_fb_sharing,
  actual_link_sharing,
  estimated_total_cost,
  estimated_participants,
  estimated_bonus_cost,
  estimated_fee,
  estimated_fb_sharing,
  estimated_link_sharing,
  total_campaign_turnover
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="cost_block">
        <div className="cost_block_title">{t("t:cost.actual")}</div>
        <div className="cost_block_info">
          <div className="totals">
            <div className="total_estimated_cost">
              <p>
                {t("t:cost.total-campaign-turnover")}
                <HelpTooltip helperTextsTransKey="cost-finalized-total-campaign-turnover" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={250}>
                <div>
                  {splitByCommas(total_campaign_turnover || "0.00")}
                  &nbsp;€
                </div>
              </SkeletonComponent>
            </div>
            <div className="total_estimated_cost">
              <p>
                {t("t:cost.total-campaign-cost")}
                <HelpTooltip helperTextsTransKey="cost-finalized-total-campaign-cost" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={250}>
                <div>
                  {splitByCommas(actual_total_cost ? actual_total_cost.toFixed(2) : "0.00")}
                  &nbsp;€
                </div>
              </SkeletonComponent>
            </div>
          </div>
          <div className="split_info mb-32">
            <div>
              <p>
                {t("t:cost.cost-of-bonuses")}
                <HelpTooltip helperTextsTransKey="cost-finalized-cost-of-bonuses" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>
                  <span>
                    {splitByCommas(actual_bonus_cost ? actual_bonus_cost.toFixed(2) : "0.00")}
                    &nbsp;€
                  </span>
                </div>
              </SkeletonComponent>
            </div>
            <div>
              <p>
                {t("t:cost.participants")}
                <HelpTooltip helperTextsTransKey="cost-finalized-participants" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>
                  <span>{actual_participants}</span> {t("t:common.one-OF-two")}{" "}
                  {estimated_participants}
                </div>
              </SkeletonComponent>
            </div>
            <div>
              <p>
                {t("t:cost.fee")}
                <HelpTooltip helperTextsTransKey="cost-finalized-fee" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>
                  <span>{splitByCommas(calculateFeeWithVAT(actual_fee))}&nbsp;€</span>
                </div>
              </SkeletonComponent>
            </div>
          </div>
          <div className="split_info">
            {/*<div>*/}
            {/*    <p>{t("t:cost.fb-sharing-cost")}</p>*/}
            {/*    <SkeletonComponent variant="text" loading={loading} width={100}>*/}
            {/*        <div>*/}
            {/*            <span>*/}
            {/*                {splitByCommas(actual_fb_sharing ? actual_fb_sharing.toFixed(2) : "0.00")}*/}
            {/*                &nbsp;€*/}
            {/*            </span>*/}
            {/*        </div>*/}
            {/*    </SkeletonComponent>*/}
            {/*</div>*/}
            <div>
              <p>
                {t("t:cost.link-sharing-cost")}
                <HelpTooltip helperTextsTransKey="cost-finalized-link-sharing-cost" />
              </p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>
                  <span>
                    {splitByCommas(actual_link_sharing ? actual_link_sharing.toFixed(2) : "0.00")}
                    &nbsp;€
                  </span>
                </div>
              </SkeletonComponent>
            </div>
          </div>
        </div>
      </div>
      <div className="cost_block">
        <div className="cost_block_title">{t("t:cost.estimated")}</div>
        <div className="cost_block_info">
          <div className="total_estimated_cost">
            <p>{t("t:cost.estimated-campaign-cost")}</p>
            <SkeletonComponent variant="text" loading={loading} width={250}>
              <div className="inactive">
                {splitByCommas(estimated_total_cost ? estimated_total_cost.toFixed(2) : "0.00")}
                &nbsp;€
              </div>
            </SkeletonComponent>
          </div>
          <div className="split_info mb-32">
            <div>
              <p>{t("t:cost.cost-of-bonuses")}</p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>
                  {splitByCommas(estimated_bonus_cost ? estimated_bonus_cost.toFixed(2) : "0.00")}
                  &nbsp;€
                </div>
              </SkeletonComponent>
            </div>
            <div>
              <p>{t("t:cost.maximum-participants")}</p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>{estimated_participants}</div>
              </SkeletonComponent>
            </div>
            <div>
              <p>{t("t:cost.fee")}</p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>{splitByCommas(calculateFeeWithVAT(estimated_fee))}&nbsp;€</div>
              </SkeletonComponent>
            </div>
          </div>
          <div className="split_info">
            {/*<div>*/}
            {/*    <p>{t("t:cost.fb-sharing-cost")}</p>*/}
            {/*    <SkeletonComponent variant="text" loading={loading} width={100}>*/}
            {/*        <div>*/}
            {/*            {splitByCommas(estimated_fb_sharing ? estimated_fb_sharing.toFixed(2) : "0.00")}*/}
            {/*            &nbsp;€*/}
            {/*        </div>*/}
            {/*    </SkeletonComponent>*/}
            {/*</div>*/}
            <div>
              <p>{t("t:cost.link-sharing-cost")}</p>
              <SkeletonComponent variant="text" loading={loading} width={100}>
                <div>
                  {splitByCommas(
                    estimated_link_sharing ? estimated_link_sharing.toFixed(2) : "0.00"
                  )}
                  &nbsp;€
                </div>
              </SkeletonComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Finalized;
