export const titleAndDescriptionError = (distributionError, t) => {
  switch (Object.keys(distributionError)[0]) {
    case "title":
      if (distributionError.title[0].code === "blank") {
        return t("t:distribution.empty-title-validation");
      }
      return distributionError.title[0].message;
    case "image":
      return distributionError.image[0].message;
    case "description":
      return distributionError.description[0].message;
    default:
      return "";
  }
};

export const timePeriodError = (distributionError, startDateIsInvalid, endDateIsInvalid, t) => {
  if (startDateIsInvalid) return t("t:distribution.start-date-validation");
  if (endDateIsInvalid) return t("t:distribution.end-date-validation");
  switch (Object.keys(distributionError)[0]) {
    case "availability_start_date":
      return distributionError.availability_start_date[0].message;
    case "availability_start_time":
      return distributionError.availability_start_time[0].message;
    case "availability_end_date":
      return distributionError.availability_end_date[0].message;
    case "availability_end_time":
      return distributionError.availability_end_time[0].message;
    default:
      return "";
  }
};

export const limitsError = (distributionError, max_participants_error, t) => {
  if (max_participants_error) return t("t:distribution.max-participants-validation");
  switch (Object.keys(distributionError)[0]) {
    case "max_participants_value":
      return distributionError.max_participants_value[0].message;
    case "time_to_finish":
      return t("t:distribution.time-to-finish-validation");
    default:
      return "";
  }
};

export const deliveryError = distributionError => {
  if (distributionError.delivery[0]) return distributionError.delivery[0].message;
  return "fallback-error";
};

export const userAgreementError = distributionError => {
  if (distributionError.agreement[0]) return distributionError.agreement[0].message;
  return "fallback-error";
};

export const facebookShareError = (distributionError, t) => {
  switch (Object.keys(distributionError)[0]) {
    case "facebook_share_title":
      if (distributionError.facebook_share_title[0].code === "required") {
        return t("t:distribution.empty-title-validation");
      }
      return distributionError.facebook_share_title[0].message;
    case "facebook_share_image":
      return t("t:snack-messages.no-image-error");
    case "facebook_share_description":
      if (distributionError.facebook_share_description[0].code === "required") {
        return t("t:distribution.empty-description-validation");
      }
      return distributionError.facebook_share_description[0].message;
    case "facebook_share_reward":
      if (distributionError.facebook_share_reward[0].code === "required") {
        return t("t:distribution.reward-field-validation");
      }
      return distributionError.facebook_share_reward[0].message;
    case "facebook_share_days":
      if (distributionError.facebook_share_days[0].code === "required") {
        return t("t:distribution.days-post-is-available");
      }
      return distributionError.facebook_share_days[0].message;
    default:
      return "";
  }
};

export const linkShareError = (distributionError, t) => {
  switch (Object.keys(distributionError)[0]) {
    case "link_share_title":
      if (distributionError.link_share_title[0].code === "required") {
        return t("t:distribution.empty-title-validation");
      }
      return distributionError.link_share_title[0].message;
    case "link_share_image":
      return t("t:snack-messages.no-image-error");
    case "link_share_description":
      if (distributionError.link_share_description[0].code === "required") {
        return t("t:distribution.empty-description-validation");
      }
      return distributionError.link_share_description[0].message;
    case "link_share_reward":
      if (distributionError.link_share_reward[0].code === "required") {
        return t("t:distribution.reward-field-validation");
      }
      return distributionError.link_share_reward[0].message;
    default:
      return "";
  }
};
