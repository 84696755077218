import { USERS } from "../constants";

const INITIAL_STATE = {
  users: {},
  usersList: { results: [] },
  invitedUsers: { results: [] },
  user_invitation_info: {},
  invitation_info: {}
};

const reduceUsers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USERS.GET_USERS_SUCCESS:
      return { ...state, users: action.payload.data };
    case USERS.GET_USERS_LIST_SUCCESS:
      return { ...state, usersList: action.payload.data };
    case USERS.INVITE_USER_SUCCESS:
      return { ...state, user_invitation_info: action.payload.data };
    case USERS.GET_INVITATION_INFO_SUCCESS:
      return { ...state, invitation_info: action.payload.data };
    case USERS.GET_INVITED_USERS_SUCCESS:
      return { ...state, invitedUsers: action.payload.data };
    default:
      return state;
  }
};

export default reduceUsers;
