import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { getInvitationInfo, acceptInvitation } from "../../actions/usersActions";
import { setCurrentCompany } from "../../actions/companiesActions";

import { renderPageLoader } from "../../helpers/functions";

import LogoIcon from "../../assets/image/logo.svg";

import "./InvitationLink.scss";

class InvitationLink extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search.substring(1));
    this.state = {
      token: params.get("token"),
      errorCode: null,
      loading: false,
      mounted: false
    };
  }

  componentDidMount() {
    const { getInvitationInfo } = this.props;
    const { token } = this.state;
    if (localStorage.token) {
      getInvitationInfo(token).then(res => {
        if ((res.payload && res.payload.data.is_used) || res.error) {
          this.setState({ errorCode: "expired" });
        }
        this.setState({ mounted: true });
      });
    }
  }

  acceptInvite = async () => {
    const { acceptInvitation, history, invitation_info } = this.props;
    const { token } = this.state;
    this.setState({ loading: true });
    const res = await acceptInvitation(token);
    if (res.payload) {
      this.handleCurrentCompany(invitation_info.company_id).then(() => {
        if (invitation_info.campaign_id) {
          history.push(`/main/campaign/${invitation_info.campaign_id}?tab=build_campaign`);
        } else {
          history.push("/main/campaigns");
        }
      });
    } else {
      if (res.error && res.error.response.data.non_field_errors) {
        if (res.error.response.data.non_field_errors[0].code === "same_user") {
          this.setState({ errorCode: "same_user" });
        } else if (res.error.response.data.non_field_errors[0].code === "already_has_access") {
          this.setState({ errorCode: "already_has_access" });
        } else {
          this.setState({ errorCode: "expired" });
        }
      } else {
        this.setState({ errorCode: "expired" });
      }
    }
  };

  handleCurrentCompany = async id => {
    const { setCurrentCompany } = this.props;
    await setCurrentCompany(id);
  };

  rejectInvite = () => {
    const { history } = this.props;
    history.push("/main/campaigns");
  };

  getContent = () => {
    const { invitation_info, t } = this.props;
    const { errorCode, mounted } = this.state;
    const singleCampaign = !!invitation_info.campaign_name;
    if (!mounted) return renderPageLoader();
    switch (errorCode) {
      case "expired":
        return (
          <div>
            <img src={LogoIcon} alt="campy" />
            <div className="invitation_expired">{t("t:invitation-link.link-is-expired")}</div>
            <div className="btn_wrapper">
              <DefaultButton variant="contained" classes="auth" type="link" to="/main/campaigns">
                {t("t:invitation-link.go-to-campaigns")}
              </DefaultButton>
            </div>
          </div>
        );
      case "same_user":
        return (
          <div>
            <img src={LogoIcon} alt="campy" />
            <div className="invitation_expired">
              {t("t:invitation-link.link-is-invalid-for-you")}
            </div>
            <div className="btn_wrapper">
              <DefaultButton variant="contained" classes="auth" type="link" to="/main/campaigns">
                {t("t:invitation-link.go-to-campaigns")}
              </DefaultButton>
            </div>
          </div>
        );
      case "already_has_access":
        return (
          <div>
            <img src={LogoIcon} alt="campy" />
            <div className="invitation_expired">
              {t("t:invitation-link.link-is-invalid-you-have-access")}
            </div>
            <div className="btn_wrapper">
              <DefaultButton variant="contained" classes="auth" type="link" to="/main/campaigns">
                {t("t:invitation-link.go-to-campaigns")}
              </DefaultButton>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <img src={LogoIcon} alt="campy" />
            <div className="invitation_text">
              {singleCampaign ? (
                <section>
                  {t("t:invitation-link.campaign-join-description", {
                    campaign_name: invitation_info.campaign_name,
                    company_name: invitation_info.company_name
                  })}
                </section>
              ) : (
                <section>
                  {t("t:invitation-link.company-join-description", {
                    company_name: invitation_info.company_name
                  })}
                </section>
              )}
            </div>
            <div className="btn_wrapper">
              <DefaultButton variant="contained" classes="reject" onClick={this.rejectInvite}>
                {t("t:invitation-link.reject")}
              </DefaultButton>
              <DefaultButton variant="contained" classes="auth" onClick={this.acceptInvite}>
                {t("t:invitation-link.accept-invite")}
              </DefaultButton>
            </div>
          </div>
        );
    }
  };

  render() {
    const {
      history: {
        location: { pathname, search }
      }
    } = this.props;
    if (!localStorage.token) {
      localStorage.setItem("invitation_link", pathname + search);
      return <Redirect to="/auth" />;
    }
    return <div className="invitation_wrapper">{this.getContent()}</div>;
  }
}

const mapStateToProps = ({ users }) => {
  return {
    invitation_info: users.invitation_info
  };
};

const mapDispatchToProps = {
  getInvitationInfo,
  acceptInvitation,
  setCurrentCompany
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvitationLink));
