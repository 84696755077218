import { COMPANIES } from "../constants";

const INITIAL_STATE = {
  accessToken: null
};

const reduceCompanyApiAccess = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMPANIES.POST_GENERATE_ACCESS_TOKEN_SUCCESS:
    case COMPANIES.GET_COMPANY_ACCESS_TOKEN_SUCCESS:
      return { ...state, accessToken: action.payload.data.api_key };
    default:
      return state;
  }
};

export default reduceCompanyApiAccess;
