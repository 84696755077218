import { BILLING_REPORTS } from "../constants";
import { joinQueries, parseObjectWithQueries } from "../helpers/functions";

export function getCampaignReport(queries) {
  return {
    type: BILLING_REPORTS.GET_CAMPAIGN_REPORT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/reports/campaign/${joinQueries(parseObjectWithQueries(queries))}`,
        method: "get"
      }
    }
  };
}

export function getPurchaseReport(queries) {
  return {
    type: BILLING_REPORTS.GET_PURCHASE_REPORT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/reports/purchase/${joinQueries(parseObjectWithQueries(queries))}`,
        method: "get"
      }
    }
  };
}

export function setCampaignReportMeta(meta) {
  return {
    type: BILLING_REPORTS.SET_CAMPAIGN_REPORT_META,
    meta
  };
}

export function setPurchaseReportMeta(meta) {
  return {
    type: BILLING_REPORTS.SET_PURCHASE_REPORT_META,
    meta
  };
}

export function resetBillingReports() {
  return {
    type: BILLING_REPORTS.RESET_BILLING_REPORTS
  };
}
