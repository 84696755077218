import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  asyncCheckImagePixels,
  isFilesSizeTooBig,
  validateImage
} from "../../../helpers/functions";

import "./DropzoneComponent.scss";

const Dropzone = ({ placeholder, multiple = true, handleFiles }) => {
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const globalParameters = useSelector(({ auth }) => auth.globalParameters);

  const preventDefault = e => {
    e.preventDefault();
  };

  const dragOver = e => {
    preventDefault(e);
  };

  const dragEnter = e => {
    preventDefault(e);
  };

  const dragLeave = e => {
    preventDefault(e);
  };

  const fileDrop = e => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
      setTimeout(() => {
        fileInputRef.current.value = "";
      }, 1000);
    }
  };

  const checkImageFiles = async files => {
    let validationPassed = true;
    for await (const el of files) {
      if (!validateImage(el)) {
        validationPassed = false;
      }
    }
    return validationPassed;
  };

  const validateImagePixels = async files => {
    let validationPassed = true;
    for await (const el of files) {
      const pixels = await asyncCheckImagePixels(el);
      if (globalParameters.max_image_resolution && globalParameters.max_image_resolution < pixels) {
        validationPassed = false;
      }
    }
    return validationPassed;
  };

  const onFilesFinishValidation = files => {
    handleFiles(files);
    setTimeout(() => {
      fileInputRef.current.value = "";
    }, 1000);
  };

  const onFilesSelection = async () => {
    const files = fileInputRef.current.files;
    const allImages = await checkImageFiles(files);
    if (files.length) {
      // Any selected files should be smaller than 5 MB
      if (isFilesSizeTooBig(files)) {
        dispatch({ type: "FILE_SIZE_ERROR" });

        // If images are selected, they should pass pixels validation
      } else if (allImages) {
        const hasNotTooManyPixels = await validateImagePixels(files);
        if (hasNotTooManyPixels) {
          onFilesFinishValidation(files);
        } else {
          dispatch({
            type: "ERROR_SNACK_OPEN",
            payload: t("t:snack-messages.image-too-many-pixels-fail", {
              number: globalParameters.max_image_resolution
            })
          });
        }
      } else {
        onFilesFinishValidation(files);
      }
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="container">
      <div
        className="drop-container"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
      >
        <div className="drop-message">
          <div className="upload-icon" />
          {placeholder}
        </div>
        <input
          ref={fileInputRef}
          className="file-input"
          type="file"
          multiple={multiple}
          onChange={onFilesSelection}
        />
      </div>
    </div>
  );
};

export default Dropzone;
