import React from "react";
import Loader from "../components/HelperComponents/Loader";
import { toast } from "react-toastify";

export function getOption(label, additionalClasses = "") {
  return (
    <div className={`status ${additionalClasses || encodeURIComponent(label)}`}>
      <div>
        {label !== "All networks" && <span />}
        {label}
      </div>
    </div>
  );
}

export function splitByCommas(data) {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function renderPageLoader() {
  return (
    <div className="loader_wrapper">
      <Loader size={40} />
    </div>
  );
}

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
export function hashCode(str) {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function intToRGB(i) {
  let c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}

// TYPES
// location, video, image, url, scan_qr, photo, new_qr, check_text, multiple, short_text,
// pictures, yes_no, rating, long_text, discount, bonus, bonus, novelty

export const renderStepPlaceholder = (name, type, t, length) => {
  if (name) return name;
  if (type === "pictures") {
    if (length < 2) {
      return t("t:step-placeholders.pictures-single");
    } else if (length === 2) {
      return t("t:step-placeholders.pictures-two");
    } else {
      return t("t:step-placeholders.pictures-multiple");
    }
  }
  return t(`t:step-placeholders.${type}`);
};

export const isFilesSizeTooBig = files => Array.from(files).some(el => el.size > 10000000); // 5 MB

export const asyncCheckImagePixels = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const image = new Image();

      image.src = reader.result;

      image.onload = function() {
        resolve((image.width * image.height) / 1000000);
      };
    };
    reader.onerror = () => reject(null);
  });
};

export const validateImage = file => {
  const validTypes = ["image/jpeg", "image/jpg", "image/png"];
  return validTypes.indexOf(file.type) !== -1;
};

export const timeList = [
  { label: getOption("00:00"), value: "00:00" },
  { label: getOption("00:30"), value: "00:30" },
  { label: getOption("01:00"), value: "01:00" },
  { label: getOption("01:30"), value: "01:30" },
  { label: getOption("02:00"), value: "02:00" },
  { label: getOption("02:30"), value: "02:30" },
  { label: getOption("03:00"), value: "03:00" },
  { label: getOption("03:30"), value: "03:30" },
  { label: getOption("04:00"), value: "04:00" },
  { label: getOption("04:30"), value: "04:30" },
  { label: getOption("05:00"), value: "05:00" },
  { label: getOption("05:30"), value: "05:30" },
  { label: getOption("06:00"), value: "06:00" },
  { label: getOption("06:30"), value: "06:30" },
  { label: getOption("07:00"), value: "07:00" },
  { label: getOption("07:30"), value: "07:30" },
  { label: getOption("08:00"), value: "08:00" },
  { label: getOption("08:30"), value: "08:30" },
  { label: getOption("09:00"), value: "09:00" },
  { label: getOption("09:30"), value: "09:30" },
  { label: getOption("10:00"), value: "10:00" },
  { label: getOption("10:30"), value: "10:30" },
  { label: getOption("11:00"), value: "11:00" },
  { label: getOption("11:30"), value: "11:30" },
  { label: getOption("12:00"), value: "12:00" },
  { label: getOption("12:30"), value: "12:30" },
  { label: getOption("13:00"), value: "13:00" },
  { label: getOption("13:30"), value: "13:30" },
  { label: getOption("14:00"), value: "14:00" },
  { label: getOption("14:30"), value: "14:30" },
  { label: getOption("15:00"), value: "15:00" },
  { label: getOption("15:30"), value: "15:30" },
  { label: getOption("16:00"), value: "16:00" },
  { label: getOption("16:30"), value: "16:30" },
  { label: getOption("17:00"), value: "17:00" },
  { label: getOption("17:30"), value: "17:30" },
  { label: getOption("18:00"), value: "18:00" },
  { label: getOption("18:30"), value: "18:30" },
  { label: getOption("19:00"), value: "19:00" },
  { label: getOption("19:30"), value: "19:30" },
  { label: getOption("20:00"), value: "20:00" },
  { label: getOption("20:30"), value: "20:30" },
  { label: getOption("21:00"), value: "21:00" },
  { label: getOption("21:30"), value: "21:30" },
  { label: getOption("22:00"), value: "22:00" },
  { label: getOption("22:30"), value: "22:30" },
  { label: getOption("23:00"), value: "23:00" },
  { label: getOption("23:30"), value: "23:30" }
];

export function getMaxOfArray(numArray) {
  return Math.max.apply(null, numArray);
}

export const urlPattern = new RegExp("^(https?|chrome):\\/\\/[^\\s$.?#].[^\\s]*$");

export function findAnError(object) {
  if (Object.values(object)[0][0]) {
    return Object.values(object)[0][0].message;
  } else if (Object.values(object)[0]) {
    return (
      Object.values(object)[0].message ||
      Object.values(object)[0].image ||
      "t:snack-messages.fallback-error"
    );
  } else {
    return "t:snack-messages.fallback-error";
  }
}

export const handlePublishValidationError = error_response => {
  const responseCode = Object.values(error_response)[0][0].code;
  const responseMessage = Object.values(error_response)[0][0].message;
  console.log({ responseCode, responseMessage });
  let publishValidationError = {};
  if (responseCode === "can_not_publish") {
    if (responseMessage.includes("company")) {
      publishValidationError = {
        ...publishValidationError,
        company: "not-approved"
      };
    }
    if (responseMessage.includes("distribution.addresses")) {
      publishValidationError = {
        ...publishValidationError,
        distributionAddresses: "delivery-address"
      };
    }
    if (responseMessage.includes("distribution.title")) {
      publishValidationError = {
        ...publishValidationError,
        distributionTitle: "campaign-title"
      };
    }
    if (responseMessage.includes("distribution.image")) {
      publishValidationError = {
        ...publishValidationError,
        distributionImage: "campaign-image"
      };
    }
    if (responseMessage.includes("distribution.description")) {
      publishValidationError = {
        ...publishValidationError,
        distributionDescription: "campaign-description"
      };
    }
    if (responseMessage.includes("distribution.delivery_type")) {
      publishValidationError = {
        ...publishValidationError,
        distributionDeliveryType: "delivery-type"
      };
    }
    if (responseMessage.includes("distribution.max_participants_value")) {
      publishValidationError = {
        ...publishValidationError,
        distributionMaxParticipants: "max-participants"
      };
    }
    if (responseMessage.includes("distribution.time_to_finish")) {
      publishValidationError = {
        ...publishValidationError,
        distributionTimeToFinish: "time-to-finish"
      };
    }
    if (
      responseMessage.includes("balance.balance_lower_than_total_estimated_cost") ||
      responseMessage.includes("cost_tab.is_approved")
    ) {
      publishValidationError = {
        ...publishValidationError,
        costBalance: "balance-should-be-approved"
      };
    }
    if (responseMessage.includes("number_of_tasks")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNumberOfTasks: "number-of-tasks"
      };
    }
    if (responseMessage.includes("reward_type")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignRewardType: "reward-type"
      };
    }
    if (responseMessage.includes("reward_steps.count")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignRewardTypeCount: "steps-count"
      };
    }
    if (responseMessage.includes("reward_steps.last_step")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignRewardTypeLastStepInconsistency: "last-step"
      };
    }
    if (responseMessage.includes("location.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignLocationTitle: "location-title"
      };
    }
    if (responseMessage.includes("location.address")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignLocationAddress: "location-address"
      };
    }
    if (responseMessage.includes("location.radius")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignLocationRadius: "location-radius"
      };
    }
    if (responseMessage.includes("location.image")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignLocationImage: "location-image"
      };
    }
    if (responseMessage.includes("video.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignVideoTitle: "video-title"
      };
    }
    if (responseMessage.includes("video.url")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignVideoUrl: "video-url"
      };
    }
    if (responseMessage.includes("image.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignImageTitle: "image-title"
      };
    }
    if (responseMessage.includes("image.image")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignImageImage: "image-image"
      };
    }
    if (responseMessage.includes("image.duration")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignImageDuration: "image-duration"
      };
    }
    if (responseMessage.includes("url.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignUrlTitle: "url-title"
      };
    }
    if (responseMessage.includes("url.url")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignUrlUrl: "url-url"
      };
    }
    if (responseMessage.includes("url.image")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignUrlImage: "url-image"
      };
    }
    if (responseMessage.includes("url.duration")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignUrlDuration: "url-duration"
      };
    }
    if (responseMessage.includes("photo.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignPhotoTitle: "photo-title"
      };
    }
    if (responseMessage.includes("photo.image")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignPhotoImage: "photo-image"
      };
    }
    if (responseMessage.includes("multiple.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignMultipleTitle: "multiple-title"
      };
    }
    if (responseMessage.includes("multiple.available_answers.count")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignMultipleAnswersCount: "multiple-count"
      };
    }
    if (responseMessage.includes("multiple.available_answers.empty")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignMultipleAnswersEmpty: "multiple-empty-answers"
      };
    }
    if (responseMessage.includes("quiz.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignQuizTitle: "quiz-title"
      };
    }
    if (responseMessage.includes("quiz.available_answers.count")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignQuizAnswersCount: "quiz-count"
      };
    }
    if (responseMessage.includes("multiple.available_answers.empty")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignQuizAnswersEmpty: "quiz-empty-answers"
      };
    }
    if (responseMessage.includes("quiz.available_answers.correct_answer")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignQuizCorrectAnswer: "quiz-correct-answer"
      };
    }
    if (responseMessage.includes("pictures.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignPicturesTitle: "pictures-title"
      };
    }
    if (responseMessage.includes("pictures.images.count")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignPicturesImages: "pictures-count"
      };
    }
    if (responseMessage.includes("new_qr.number_of_codes")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNewQRNumber: "new-qr-number-of-codes"
      };
    }
    if (responseMessage.includes("new_qr.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNewQRTitle: "new-qr-title"
      };
    }
    if (responseMessage.includes("new_qr.expiration_hours")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNewQRExpiration: "new-qr-expiration-hours"
      };
    }
    if (responseMessage.includes("scan_qr.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignScanQRTitle: "scan-qr-title"
      };
    }
    if (responseMessage.includes("scan_qr.image")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNewQRImage: "scan-qr-image"
      };
    }
    if (responseMessage.includes("scan_qr.background_image")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNewQRBackgroundImage: "scan-qr-background-image"
      };
    }
    if (responseMessage.includes("new_barcode.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNewBarcodeTitle: "new-barcode-title"
      };
    }
    if (responseMessage.includes("new_barcode.number_of_codes")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNewBarcodeNumber: "new-barcode-number-of-codes"
      };
    }
    if (responseMessage.includes("new_barcode.expiration_hours")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNewBarcodeExpiration: "new-barcode-expiration-hours"
      };
    }
    if (responseMessage.includes("monetary.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignMonetaryTitle: "monetary-title"
      };
    }
    if (responseMessage.includes("short_text.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignShortTextTitle: "short-text-title"
      };
    }
    if (responseMessage.includes("yes_no.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignYesNoTitle: "yes-no-title"
      };
    }
    if (responseMessage.includes("monetary.amount")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignMonetaryAmount: "monetary-amount"
      };
    }
    if (responseMessage.includes("monetary.currency")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignMonetaryCurrency: "monetary-currency"
      };
    }
    if (responseMessage.includes("discount.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignDiscountTitle: "discount-title"
      };
    }
    if (responseMessage.includes("discount.product")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignDiscountProduct: "discount-product"
      };
    }
    if (responseMessage.includes("discount.value")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignDiscountValue: "discount-value"
      };
    }
    if (responseMessage.includes("bonus.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignBonusTitle: "bonus-title"
      };
    }
    if (responseMessage.includes("bonus.product")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignBonusProduct: "bonus-product"
      };
    }
    if (responseMessage.includes("bonus.gift")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignBonusGift: "bonus-gift"
      };
    }
    if (responseMessage.includes("novelty.title")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNoveltyTitle: "novelty-title"
      };
    }
    if (responseMessage.includes("novelty.product")) {
      publishValidationError = {
        ...publishValidationError,
        buildCampaignNoveltyProduct: "novelty-product"
      };
    }
    if (responseMessage.includes("audience.interests")) {
      publishValidationError = {
        ...publishValidationError,
        audienceInterests: "audience-interests"
      };
    }
    if (responseMessage.includes("audience.gender")) {
      publishValidationError = {
        ...publishValidationError,
        audienceGender: "audience-gender"
      };
    }
    return publishValidationError;
  } else {
    return { unhandledError: responseMessage };
  }
};

export const showToastError = error => {
  toast.error(error, {
    position: "bottom-left",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
};

export const joinQueries = arr => `${arr.length && arr.length !== 0 ? "?" : ""}${arr.join("&")}`;

export const parseObjectWithQueries = queries => {
  return Object.entries(queries).map(entry => `${entry[0]}=${entry[1]}`);
};

export const isIntNumberRegexp = new RegExp("^[0-9]+$");

export const isDecimalNumberRegexp = new RegExp("^(?:\\d*[0-9](?:\\.[0-9]{1,2})?|\\.[0-9]{1,2})$");

export const calculateFeeWithVAT = fee => {
  if (fee) return (+fee).toFixed(2);
  return 0;
};

export const formatPrice = amount =>
  `${splitByCommas(amount ? Number(amount).toFixed(2) : "0.00")}€`;

export const formatDate = date => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getImageMimeTypeForBlob = imageToCrop => {
  const mimeType = imageToCrop[0].substring(
    imageToCrop[0].indexOf(":") + 1,
    imageToCrop[0].indexOf(";")
  );
  if (mimeType === "image/jpg" || mimeType === "image/jpeg") return mimeType;
  return undefined;
};
