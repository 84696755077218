import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import clsx from "clsx";

import Panel from "./Panel";
import { DroppableArea } from "../../HelperComponents/DroppableArea/DroppableArea";
import { getItems, reorder, getItemStyle, getListStyle } from "./helpers";
import {
  postOneStep,
  postStepsOrder,
  blockOtherActions,
  setCurrentStepInfo,
  getCampaignSteps
} from "../../../actions/campaignActions";

import "./styles.scss";

const BuildCampaign = ({ handleEditingTimer }) => {
  const { t } = useTranslation();
  const { id: campaignId } = useParams();
  const dispatch = useDispatch();

  const [editingItem, setEditingItem] = useState(null);
  const [items, setItems] = useState([]);
  const {
    campaignViewers: { is_editor: isEditor },
    campaignSteps
  } = useSelector(({ campaign }) => campaign);

  const handleEdit = id => setEditingItem(prevValue => (prevValue === id ? null : id));

  useEffect(() => {
    dispatch(getCampaignSteps(campaignId));
    dispatch(blockOtherActions(false));
    return () => {
      dispatch(blockOtherActions(false));
      dispatch(
        setCurrentStepInfo({
          step: "not-selected"
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setItems(getItems(campaignSteps, handleEdit, handleEditingTimer));
    // eslint-disable-next-line
  }, [JSON.stringify(campaignSteps)]);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const tempItems = reorder(items, result.source.index, result.destination.index);
    setItems(tempItems);
    let tempArray = [];
    tempItems.forEach(el => {
      tempArray.push({ id: el.content.props.id });
    });
    handleEditingTimer();
    dispatch(postStepsOrder(campaignId, tempArray));
  };

  const addStep = useCallback(
    stepType => {
      dispatch(
        postOneStep(campaignId, {
          type: stepType
        })
      );
      handleEditingTimer();
    },
    // eslint-disable-next-line
    [campaignId]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Panel addStep={addStep} />
        <DroppableArea backgroundFaded={!!editingItem}>
          {!items.length && (
            <div className="empty_canvas_placeholder">
              {t("t:build-campaign.start-building-your-campaign")}
            </div>
          )}
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
                {items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={!!editingItem || !isEditor}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          item.content.props.category,
                          !!editingItem && editingItem !== item.content.props.id
                        )}
                        className={clsx(snapshot.isDragging && "is_dragging")}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DroppableArea>
      </DragDropContext>
    </DndProvider>
  );
};

export default memo(BuildCampaign);
