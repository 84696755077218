import { USERS } from "../constants";

export function getUsers(arr) {
  return {
    type: USERS.GET_USERS,
    payload: {
      client: "default",
      request: {
        url: `/manage/company-managers/${arr.length && arr.length !== 0 ? "?" : ""}${arr.join(
          "&"
        )}`,
        method: "get"
      }
    }
  };
}

export function deleteUser(manager_id) {
  return {
    type: USERS.DELETE_USER,
    payload: {
      client: "default",
      request: {
        url: `/manage/company-managers/${manager_id}/`,
        method: "delete"
      }
    }
  };
}

export function inviteUser(data) {
  return {
    type: USERS.INVITE_USER,
    payload: {
      client: "default",
      request: {
        url: `/manage/company-managers/invite/`,
        method: "post",
        data //email, campaign, url
      }
    }
  };
}

export function getInvitationInfo(token) {
  return {
    type: USERS.GET_INVITATION_INFO,
    payload: {
      client: "default",
      request: {
        url: `/invitation/check-token/${token}/`,
        method: "get"
      }
    }
  };
}

export function acceptInvitation(token) {
  return {
    type: USERS.ACCEPT_INVITATION,
    payload: {
      client: "default",
      request: {
        url: `/invitation/apply/${token}/`,
        method: "post"
      }
    }
  };
}

export function getUsersList(arr) {
  return {
    type: USERS.GET_USERS_LIST,
    payload: {
      client: "default",
      request: {
        url: `/users/${arr.length && arr.length !== 0 ? "?" : ""}${arr.join("&")}`,
        method: "get"
      }
    }
  };
}

export function postBlockUserInCompany(userId) {
  return {
    type: USERS.POST_BLOCK_USER_IN_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/users/${userId}/block/`,
        method: "post"
      }
    }
  };
}

export function postUnblockUserInCompany(userId) {
  return {
    type: USERS.POST_UNBLOCK_USER_IN_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/users/${userId}/unblock/`,
        method: "post"
      }
    }
  };
}

export function postRemoveUserFromCompany(userId) {
  return {
    type: USERS.POST_REMOVE_USER_FROM_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/users/${userId}/remove/`,
        method: "post"
      }
    }
  };
}

export function postBLockUserInAllCampaigns(userId, data) {
  return {
    type: USERS.POST_BLOCK_USER_IN_ALL_CAMPAIGNS,
    payload: {
      client: "default",
      request: {
        url: `/users/${userId}/set-campaigns-blocked-status/`,
        method: "post",
        data
        // [
        //      {
        //          "id": 3,
        //          "is_blocked": false
        //      }
        // ]
      }
    }
  };
}

export function postMakeARegularUser(userId) {
  return {
    type: USERS.POST_MAKE_A_REGULAR_USER,
    payload: {
      client: "default",
      request: {
        url: `/users/${userId}/set-role-user/`,
        method: "post"
      }
    }
  };
}

export function postMakeAnOwner(userId) {
  return {
    type: USERS.POST_MAKE_AN_OWNER,
    payload: {
      client: "default",
      request: {
        url: `/users/${userId}/set-role-owner/`,
        method: "post"
      }
    }
  };
}

export function getInvitedUsers(arr) {
  return {
    type: USERS.GET_INVITED_USERS,
    payload: {
      client: "default",
      request: {
        url: `/invitations/${arr.length && arr.length !== 0 ? "?" : ""}${arr.join("&")}`,
        method: "get"
      }
    }
  };
}

export function deleteInvitation(id) {
  return {
    type: USERS.DELETE_INVITATION,
    payload: {
      client: "default",
      request: {
        url: `/invitations/${id}/revoke/`,
        method: "delete"
      }
    }
  };
}
