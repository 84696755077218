import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import { patchDeliveryInfo, postDeliveryOnDeliveryStatus } from "../../../actions/campaignActions";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Calendar from "../../HelperComponents/Calendar/Calendar";
import { formatDate } from "../../../helpers/functions";
import HelpTooltip from "../../HelperComponents/HelpTooltip";

import "../../Campaign/Deliveries/styles.scss";

class TrackingInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingNumber: props.tracking_number || "",
      ETA: props.eta || "",
      submitting: false,
      etaIsInvalid: false
    };
  }

  handleDateChange = date => {
    if (date !== null) {
      if (date.toString() !== "Invalid Date") {
        this.setState({ ETA: formatDate(date), etaIsInvalid: false });
      } else {
        this.setState({ etaIsInvalid: true });
      }
    } else {
      this.setState({ etaIsInvalid: false });
    }
  };

  saveTrackingInfo = async () => {
    const {
      toggleDialog,
      patchDeliveryInfo,
      campaignId,
      id,
      postDeliveryOnDeliveryStatus,
      moveToOnDeliveryStatus
    } = this.props;
    const { trackingNumber, ETA } = this.state;
    this.setState({ submitting: true });
    const dataToPost = {
      tracking_number: trackingNumber
    };
    if (ETA) dataToPost.eta = ETA;
    const res = await patchDeliveryInfo(campaignId, id, dataToPost);
    if (moveToOnDeliveryStatus && res.payload) await postDeliveryOnDeliveryStatus(campaignId, id);
    this.setState({ submitting: false });
    toggleDialog(res.payload.data);
  };

  render() {
    const { toggleDialog, tracking_number, address_name, courier, type, t } = this.props;
    const { trackingNumber, ETA, submitting, etaIsInvalid } = this.state;
    return (
      <div className="tracking_info">
        <div className="dialog_title">
          {tracking_number
            ? t("t:deliveries.edit-tracking-info")
            : t("t:deliveries.add-tracking-info")}
        </div>
        <div className="input_title_block_my_campaign">
          <div>
            <span>{`${t("t:deliveries.courier-info")} *`}</span>
          </div>
          <input value={courier || "—"} disabled />
        </div>
        <div className="inputs_in_the_row">
          <div className="input_title_block_my_campaign">
            <div>
              <span>
                {`${t("t:deliveries.tracking-number")} ${type === "parcel_machine" ? "*" : ""}`}
                <HelpTooltip
                  helperTextsTransKey={
                    type === "parcel_machine"
                      ? "deliveries-tracking-number-parcel-machine"
                      : "deliveries-tracking-number-courier"
                  }
                />
              </span>
            </div>
            <input
              type="text"
              value={trackingNumber}
              onChange={e => this.setState({ trackingNumber: e.target.value })}
            />
          </div>
          <div className="input_title_block_my_campaign">
            <div>
              <span>{t("t:deliveries.eta")}</span>
            </div>
            <Calendar handleDateChange={this.handleDateChange} name="eta" prevdate={ETA} />
          </div>
        </div>
        <div className="input_title_block_my_campaign">
          <div>
            <span>
              {`${
                type === "parcel_machine"
                  ? t("t:deliveries.parcel-machine-location")
                  : t("t:deliveries.shipping-address")
              } *`}
            </span>
          </div>
          <input value={address_name || "—"} disabled />
        </div>
        <div className="buttons_wrapper">
          <DefaultButton
            variant="contained"
            classes="auth"
            onClick={this.saveTrackingInfo}
            loading={submitting}
            disabled={etaIsInvalid || (type === "parcel_machine" && !trackingNumber) || submitting}
          >
            {t("t:common.save")}
          </DefaultButton>
          <DefaultButton variant="contained" classes="cancel_btn" onClick={toggleDialog}>
            {t("t:common.cancel")}
          </DefaultButton>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      patchDeliveryInfo,
      postDeliveryOnDeliveryStatus
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withTranslation()(TrackingInfoForm));
