import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  deleteOneStep,
  patchNewBarcodeStep,
  resetCampaignErrors,
  getCampaignSteps,
  setCampaignError,
  blockOtherActions
} from "../../../../../actions/campaignActions";
import { setLoading } from "../../../../../actions/dashboardActions";

import { renderStepPlaceholder } from "../../../../../helpers/functions";
import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import Snack from "../../../../HelperComponents/Snack/Snack";

import barcode from "../../../../../assets/image/My_Campaign/barcode.svg";
import cancel from "../../../../../assets/image/My_Campaign/btn-cancel.svg";
import trash from "../../../../../assets/image/My_Campaign/btn-trash.svg";
import save from "../../../../../assets/image/My_Campaign/btn-save.svg";
import Shape from "../../../../../assets/image/My_Campaign/Shape.svg";
import edit from "../../../../../assets/image/btn-edit.svg";
import deleteIcon from "../../../../../assets/image/My_Campaign/btn-cancel.svg";
import dragHandle from "../../../../../assets/image/drag-handle.svg";
import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";
import eye from "../../../../../assets/image/My_Campaign/eye.svg";

import "./NewBarcode.scss";
import CharactersLeft from "../../../../HelperComponents/CharactersLeft";

class NewBarcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: props.match.params.id,
      popperOpened: false,
      title: props.detail.title || "",
      description: props.detail.description || "",
      number_of_codes: props.detail.number_of_codes || "",
      codes_remaining: props.detail.codes_remaining,
      expiration_hours: props.detail.expiration_hours || "",
      openSnack: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.popperOpened !== this.state.popperOpened) {
      this.props.handleEdit(this.props.id);
      this.props.blockOtherActions(this.state.popperOpened);
      this.props.isEditor && this.props.handleEditingTimer();
    }
    if (this.props.campaignError) {
      if (prevState !== this.state) {
        this.props.resetCampaignErrors();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCampaignErrors();
  }

  openPopper = () => {
    this.setState(({ popperOpened }) => ({ popperOpened: !popperOpened }));
  };

  deleteStep = () => {
    const { deleteOneStep, id, campaign, toClosePopper, handleEditingTimer } = this.props;
    !!toClosePopper && toClosePopper();
    deleteOneStep(campaign, id);
    handleEditingTimer();
  };

  changeStep = async () => {
    const {
      patchNewBarcodeStep,
      id,
      toClosePopper,
      getCampaignSteps,
      setCampaignError,
      handleSnack,
      setLoading,
      handleEditingTimer,
      t
    } = this.props;
    const {
      title,
      description,
      number_of_codes,
      codes_remaining,
      expiration_hours,
      campaignId
    } = this.state;
    if (number_of_codes === "0" || (!!Number(number_of_codes) && number_of_codes < 1)) {
      setCampaignError({
        number_of_codes: [{ code: "too small", message: t("t:common.more-than-one") }]
      });
    } else if (expiration_hours === "0" || (!!Number(expiration_hours) && expiration_hours < 1)) {
      setCampaignError({
        expiration_hours: [{ code: "too small", message: t("t:common.more-than-one") }]
      });
    } else {
      setLoading(true);
      const data = {
        title,
        description,
        number_of_codes,
        codes_remaining,
        expiration_hours
      };
      const res = await patchNewBarcodeStep(campaignId, id, data);
      handleEditingTimer();
      if (res.payload) {
        getCampaignSteps(campaignId).then(() => setLoading(false));
        handleSnack(true);
        toClosePopper();
      } else {
        setLoading(false);
      }
    }
  };

  onTitleChange = textInput => {
    const { title } = this.state;
    if (textInput.length < 51 || textInput.length < title) {
      this.setState({ title: textInput });
    } else {
      this.setState({ title: textInput.slice(0, 50) });
    }
  };

  renderExpandedView = (type, detail) => {
    const { toClosePopper, campaignError, isEditor, t } = this.props;
    const { title, description, number_of_codes, codes_remaining, expiration_hours } = this.state;
    return (
      <div className="block_wrapper">
        <div className="head_block">
          <div className="title_head">
            <div>
              <img src={barcode} alt="barcode" />
            </div>
            <div>
              <p>{t("t:build-campaign.new-barcode")}</p>
              <span>{renderStepPlaceholder(detail.title, type, t)}</span>
            </div>
          </div>
          <div className="btn_head">
            {isEditor ? (
              <div className="btn_head">
                <button onClick={this.deleteStep}>
                  <img src={cancel} alt="cancel" />
                </button>
                <button onClick={toClosePopper}>
                  <img src={trash} alt="trash" />
                </button>
                <button onClick={this.changeStep}>
                  <img src={save} alt="save" />
                </button>
              </div>
            ) : (
              <div className="btn_head">
                <button onClick={toClosePopper}>
                  <img src={trash} alt="trash" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="input_title_block_my_campaign no-padding-bottom">
          <div>
            <span>{`${t("t:common.title")} *`}</span>
            <TooltipMessage
              text={t("t:helper-texts.build-new-barcode-title")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <div className="step_input_wrapper">
            <input
              disabled={!isEditor}
              type="text"
              placeholder={renderStepPlaceholder(detail.title, type, t)}
              value={title}
              onChange={e => this.onTitleChange(e.target.value)}
            />
            {campaignError.title ? (
              <TooltipMessage
                text={campaignError.title[0].message}
                delay={200}
                error
                position="top"
                classes=""
              >
                <img src={ErrorIcon} alt="error" />
              </TooltipMessage>
            ) : (
              ""
            )}
          </div>
          <CharactersLeft title={title} maxCharacters={50} />
        </div>
        <div className="textarea_description_block_my_campaign">
          <div>
            <span>{t("t:common.description")}</span>
            <TooltipMessage
              text={t("t:helper-texts.build-new-barcode-description")}
              delay={200}
              position="top"
              classes="step_tooltip"
            >
              <img src={Shape} alt="Shape" />
            </TooltipMessage>
          </div>
          <div className="step_input_wrapper">
            <textarea
              disabled={!isEditor}
              value={description}
              onChange={e => this.setState({ description: e.target.value })}
            />
            {campaignError.description ? (
              <TooltipMessage
                text={campaignError.description[0].message}
                delay={200}
                error
                position="top"
                classes=""
              >
                <img src={ErrorIcon} alt="error" />
              </TooltipMessage>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="qr_code_info">
          <div className="price_block_big">
            <div className="title">
              <span>{t("t:build-campaign.number-of-codes")}</span>
              <TooltipMessage
                text={t("t:helper-texts.build-new-barcode-number-of-codes")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <div className="price_block_input">
              <div className="step_input_wrapper seconds_block">
                <input
                  disabled={!isEditor}
                  type="number"
                  value={number_of_codes}
                  onChange={e => this.setState({ number_of_codes: e.target.value })}
                />
                {campaignError.number_of_codes ? (
                  <TooltipMessage
                    text={campaignError.number_of_codes[0].message}
                    delay={200}
                    error
                    position="top"
                    classes=""
                  >
                    <img src={ErrorIcon} alt="error" />
                  </TooltipMessage>
                ) : (
                  ""
                )}
              </div>
              <span>{t("t:build-campaign.codes")}</span>
            </div>
          </div>
          <div className="price_block_big">
            <div className="title">
              <span>{`${t("t:build-campaign.barcode-expiration")} *`}</span>
              <TooltipMessage
                text={t("t:helper-texts.build-new-barcode-code-expiration")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img src={Shape} alt="Shape" />
              </TooltipMessage>
            </div>
            <div className="price_block_input">
              <div className="step_input_wrapper seconds_block">
                <input
                  disabled={!isEditor}
                  type="number"
                  value={expiration_hours}
                  onChange={e => this.setState({ expiration_hours: e.target.value })}
                />
                {campaignError.expiration_hours ? (
                  <TooltipMessage
                    text={campaignError.expiration_hours[0].message}
                    delay={200}
                    error
                    position="top"
                    classes=""
                  >
                    <img src={ErrorIcon} alt="error" />
                  </TooltipMessage>
                ) : (
                  ""
                )}
              </div>
              <span>{t("t:common.hours")}</span>
            </div>
          </div>
          <div className="info">
            <p>{t("t:build-campaign.codes-remaining")}</p>
            <span>{codes_remaining || "—"}</span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { expanded, type, detail, blockActions, isEditor, t } = this.props;
    const { popperOpened, openSnack } = this.state;
    if (expanded) {
      return (
        <div className="challenges_wrapper wrapper_block_my_campaign new_barcode_wrapper">
          {this.renderExpandedView(type, detail)}
        </div>
      );
    } else {
      return (
        <div className="wrapper_block_my_campaign step_wrapper image_wrapper">
          <div className="head_block head_block_step">
            <div className="title_head">
              <div className="not_expanded">
                <img src={dragHandle} alt="draggable" />
                <img src={barcode} alt="barcode" />
              </div>
              <div>
                <p>{t("t:build-campaign.new-barcode")}</p>
                <span>{renderStepPlaceholder(detail.title, type, t)}</span>
              </div>
            </div>
            {isEditor ? (
              <div className="btn_head">
                <button onClick={this.deleteStep} disabled={blockActions}>
                  <img src={deleteIcon} alt="trash" />
                </button>
                <button onClick={this.openPopper} disabled={blockActions}>
                  <img src={edit} alt="save" />
                </button>
              </div>
            ) : (
              <div className="btn_head">
                <button onClick={this.openPopper} disabled={blockActions}>
                  <img src={eye} alt="open" />
                </button>
              </div>
            )}
          </div>
          {popperOpened && (
            <NewBarcode
              {...this.props}
              toClosePopper={this.openPopper}
              handleSnack={openSnack => this.setState({ openSnack })}
              expanded
            />
          )}
          <Snack
            open={openSnack}
            handleSnack={() => this.setState({ openSnack: false })}
            message={t("t:snack-messages.successfully-changed")}
            type="success"
          />
        </div>
      );
    }
  }
}

function mapStateToProps({ campaign }) {
  return {
    campaignError: campaign.campaignError,
    blockActions: campaign.blockActions,
    isEditor: campaign.campaignViewers.is_editor
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteOneStep,
      patchNewBarcodeStep,
      getCampaignSteps,
      resetCampaignErrors,
      setCampaignError,
      blockOtherActions,
      setLoading
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(NewBarcode)));
