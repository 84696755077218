import React, { memo } from "react";

import AnalyticsBlock from "./AnalyticsBlock";

const AnalyticsDetails = ({ accepted, cancelled, completed, viewed, periodValue }) => {
  const analytics_details = [viewed, accepted, completed, cancelled];
  return (
    <div className="analytics_details">
      {analytics_details.map(item => (
        <AnalyticsBlock key={item.name} {...item} periodValue={periodValue} />
      ))}
    </div>
  );
};

export default memo(AnalyticsDetails);
