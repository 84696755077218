import React from "react";
import { useTranslation } from "react-i18next";

import SortUpIcon from "../../../../../assets/image/sort-up.svg";
import SortDownIcon from "../../../../../assets/image/sort-down.svg";
import SortInactiveIcon from "../../../../../assets/image/sort_inactive.svg";

const DeliveriesTableHeader = ({ sortBy, setSortBy }) => {
  const { t } = useTranslation();

  return (
    <div className="table_header">
      <div className="table_row">
        <div className="row_item">{t("t:common.user")}</div>
        <div
          className="row_item with_sort_icon"
          onClick={() => setSortBy(sortBy === "type" ? "-type" : "type")}
        >
          {t("t:common.type")}
          <img
            src={
              sortBy === "type" ? SortUpIcon : sortBy === "-type" ? SortDownIcon : SortInactiveIcon
            }
            alt="sort"
          />
        </div>
        <div
          className="row_item with_sort_icon"
          onClick={() => setSortBy(sortBy === "status" ? "-status" : "status")}
        >
          {t("t:common.status")}
          <img
            src={
              sortBy === "status"
                ? SortUpIcon
                : sortBy === "-status"
                ? SortDownIcon
                : SortInactiveIcon
            }
            alt="sort"
          />
        </div>
        <div className="row_item" />
        <div className="row_item" />
      </div>
    </div>
  );
};

export default DeliveriesTableHeader;
