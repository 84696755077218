import React, { Component, Fragment, memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import DeliveriesPanel from "./DeliveriesPanel";
import SkeletonComponent from "../../HelperComponents/SkeletonComponent/SkeletonComponent";
import Pagination from "../../HelperComponents/Pagination/Pagination";
import DeliveryItem from "./Blocks/DeliveryItem";
import DeliveriesTableHeader from "./Blocks/DeliveriesTableHeader";

import {
  getDeliveries,
  postDeliveryDeliverToPickupStatus,
  postDeliveryReadyForPickupStatus,
  postDeliveryNeedsDeliveryStatus,
  postDeliveryDoneStatus,
  postDeliveryOnDeliveryStatus,
  patchDeliveryInfo
} from "../../../actions/campaignActions";

import UsersIcon from "../../../assets/image/users_icon.svg";
import zoom from "../../../assets/image/zoom.svg";

import "./styles.scss";

class Deliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: props.campaignId,
      mounted: false,
      currentPage: null,
      searchValue: "",
      moreInfoArray: [],
      sortBy: undefined
    };
  }

  componentDidMount() {
    this.fetchCampaignDeliveries(1).then(() => {
      this.setState({ currentPage: 1, mounted: true });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.searchValue !== this.state.searchValue) {
      this.fetchCampaignDeliveries(1).then(() => {
        this.setState({ currentPage: 1 });
      });
    }
    if (prevState.sortBy !== this.state.sortBy) {
      this.fetchCampaignDeliveries(1).then(() => {
        this.setState({ currentPage: 1 });
      });
    }
  }

  fetchCampaignDeliveries = async page => {
    const { getDeliveries } = this.props;
    const { campaignId, searchValue, sortBy } = this.state;
    let data = [`page=${page}`, `page_size=10`];
    searchValue && data.push(`search=${searchValue}`);
    sortBy && data.push(`ordering=${sortBy}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
    await getDeliveries(campaignId, data);
  };

  handleMoreInfo = element => {
    const { moreInfoArray } = this.state;
    if (moreInfoArray.some(el => el.id === element.id)) {
      this.setState({ moreInfoArray: moreInfoArray.filter(item => item.id !== element.id) });
    } else {
      this.setState({ moreInfoArray: [...moreInfoArray, element] });
    }
  };

  render() {
    const { deliveries, t } = this.props;
    const { currentPage, searchValue, moreInfoArray, sortBy, mounted, campaignId } = this.state;
    const { finalized_count, monetary_count, delivery_count } = deliveries;
    return (
      <div className="deliveries_wrapper">
        <DeliveriesPanel />
        <div className="deliveries_container">
          <div className="deliveries_header">
            <div className="title">{t("t:deliveries.campaign-deliveries")}</div>
          </div>
          <div className="deliveries_list_wrapper">
            <div className="in_total_block">
              <SkeletonComponent variant="text" loading={!mounted} width={300}>
                <div>
                  <img src={UsersIcon} alt="users" />
                  {t("t:deliveries.users-finalized-campaign", {
                    number: finalized_count
                  })}
                </div>
              </SkeletonComponent>
              <SkeletonComponent variant="text" loading={!mounted} width={350}>
                <p>
                  {t("t:deliveries.monetary-rewards", { number: monetary_count })}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {t("t:deliveries.delivery-rewards", { number: delivery_count })}
                </p>
              </SkeletonComponent>
            </div>
            {!deliveries.results.length && !searchValue ? null : (
              <Fragment>
                <div className="search">
                  <input
                    value={searchValue}
                    onChange={e => this.setState({ searchValue: e.target.value })}
                    type="text"
                    placeholder={t("t:deliveries.search-delivery")}
                  />
                  <button>
                    <img src={zoom} alt="zoom" />
                  </button>
                </div>
                <div className="table_container">
                  <DeliveriesTableHeader
                    sortBy={sortBy}
                    setSortBy={newSortBy => this.setState({ sortBy: newSortBy })}
                  />
                  <div className="table_body">
                    {deliveries.results.map(el => (
                      <DeliveryItem
                        key={`delivery_${el.id}`}
                        mounted={mounted}
                        moreInfoArray={moreInfoArray}
                        toggleDialog={this.toggleDialog}
                        handleMoreInfo={() => this.handleMoreInfo(el)}
                        campaignId={campaignId}
                        {...el}
                      />
                    ))}
                  </div>
                </div>
              </Fragment>
            )}
            <div className="pagination_wrapper">
              {deliveries.count > 10 && (
                <Pagination
                  active={currentPage}
                  pageCount={Math.ceil(deliveries.count / 10)}
                  onChange={e => {
                    this.fetchCampaignDeliveries(e.selected + 1).then(() => {
                      this.setState({ currentPage: e.selected + 1 });
                    });
                  }}
                  t={t}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ campaign }) {
  return {
    deliveries: campaign.deliveries
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDeliveries,
      postDeliveryDeliverToPickupStatus,
      postDeliveryReadyForPickupStatus,
      postDeliveryNeedsDeliveryStatus,
      postDeliveryDoneStatus,
      postDeliveryOnDeliveryStatus,
      patchDeliveryInfo
    },
    dispatch
  );
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Deliveries)));
