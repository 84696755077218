import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Image as CloudinaryImage } from "cloudinary-react";
import _isEmpty from "lodash/isEmpty";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import Flag from "react-world-flags";

import DefaultButton from "../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Snack from "../../../HelperComponents/Snack/Snack";
import { findAnError, getOption, urlPattern, validateImage } from "../../../../helpers/functions";
import Dropzone from "../../../HelperComponents/DropzoneComponent/DropzoneComponent";
import DialogComponent from "../../../HelperComponents/DialogComponent/DialogComponent";
import GooglePickupAddress from "../../../HelperComponents/GooglePickupAddress/GooglePickupAddress";
import TooltipMessage from "../../../HelperComponents/TooltipMessage/TooltipMessage";
import CropperComponent from "../../../HelperComponents/CropperComponent";
import CharactersLeft from "../../../HelperComponents/CharactersLeft";
import SelectComponent from "../../../HelperComponents/SelectComponent/SelectComponent";
import {
  checkRegistryCode,
  getEUCountries,
  patchCompanyDetails,
  postNewCompany,
  resetCompanyErrors,
  setCompanyError
} from "../../../../actions/companiesActions";
import DeliveryCompanies from "./DeliveryCompanies";

import eye from "../../../../assets/image/My_Campaign/eye.svg";
import del from "../../../../assets/image/My_Campaign/del.svg";
import edit from "../../../../assets/image/btn-edit.svg";
import Shape from "../../../../assets/image/My_Campaign/Shape.svg";
import CheckOff from "../../../../assets/image/checkbox_unchecked.svg";
import CheckOn from "../../../../assets/image/checkbox_checked.svg";
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  TERMS_AND_CONDITIONS_ESTONIAN_URL
} from "../../../../config";

import "./styles.scss";
import _omit from "lodash/omit";
import PhoneNumberInput from "../../../HelperComponents/PhoneNumberInput";

class CompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.defaultValues,

      loading: false,
      imagePreview: null,
      previewDialogOpen: false,
      imageToCrop: null,
      blobToDownload: null,
      pickupAddressDialogOpen: false,
      deletePickupAddressDialogOpen: false,
      pickupAddressToChange: undefined,
      idOfAddressToChange: undefined,
      openErrorSnack: false,
      validationErrors: {},
      termsAccepted: false
    };
  }

  componentDidMount() {
    this.props.getEUCountries();
  }

  onFieldChange = (fieldName, val) => {
    let validationErrors = this.state.validationErrors;
    delete validationErrors[fieldName];
    this.setState({ [fieldName]: val, validationErrors });
  };

  handleCountryChange = event => {
    this.setState(({ validationErrors }) => ({
      country_of_registration: event.value,
      validationErrors: _omit(validationErrors, ["registry_code"])
    }));
    if (event.value === "EE") this.validateRegistryCode();
  };

  onDescriptionChange = textInput => {
    const { description } = this.state;
    if (textInput.length < 351 || textInput.length < description) {
      this.setState({ description: textInput });
    } else {
      this.setState({ description: textInput.slice(0, 350) });
    }
  };

  handleFiles = files => {
    const { setCompanyError, t } = this.props;
    if (validateImage(files[0])) {
      this.handleImage(files[0]);
    } else {
      setCompanyError({
        image: [{ code: "file_type", message: t("t:snack-messages.file-type-fail") }]
      });
      this.setState({ openErrorSnack: true });
    }
  };

  handleImage = image => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => this.setState({ imageToCrop: [reader.result] });
  };

  onFinishCroppingImage = (canvas, imageMimeType) => {
    this.setState({ imageToCrop: null });
    canvas.toBlob(blob => {
      this.setState({
        imagePreview: canvas.toDataURL(imageMimeType),
        image: canvas.toDataURL(imageMimeType),
        blobToDownload: blob
      });
    }, imageMimeType);
  };

  onCancelCropping = () => this.setState({ imageToCrop: null });

  onImageDelete = () => {
    this.setState({ image: null, imagePreview: null, blobToDownload: null });
  };

  savePickupAddress = (address, coordinates) => {
    const { pickupAddressToChange, addresses, idOfAddressToChange } = this.state;
    let tempPickupAddresses = [...addresses];
    if (pickupAddressToChange) {
      tempPickupAddresses[idOfAddressToChange] = {
        address,
        lat: coordinates.lat,
        lng: coordinates.lng
      };
    } else {
      tempPickupAddresses.push({ address, lat: coordinates.lat, lng: coordinates.lng });
    }
    this.setState({
      pickupAddressDialogOpen: false,
      pickupAddressToChange: undefined,
      addresses: tempPickupAddresses,
      idOfAddressToChange: undefined
    });
  };

  deletePickupAddress = () => {
    const { idOfAddressToChange, addresses } = this.state;
    let tempPickupAddresses = [...addresses];
    tempPickupAddresses.splice(idOfAddressToChange, 1);
    this.setState({
      deletePickupAddressDialogOpen: false,
      idOfAddressToChange: undefined,
      addresses: tempPickupAddresses
    });
  };

  closePickupAddressDialog = () =>
    this.setState({
      pickupAddressDialogOpen: false,
      pickupAddressToChange: undefined,
      idOfAddressToChange: undefined
    });

  onLogoutClick = () => {
    localStorage.removeItem("token");
    this.props.history.push("/auth/login");
  };

  validateRegistryCode = async () => {
    const { checkRegistryCode, t } = this.props;
    const { validationErrors, registry_code } = this.state;
    const res = await checkRegistryCode({ code: registry_code });
    if (res.error) {
      this.setState({
        validationErrors: {
          ...validationErrors,
          registry_code: t("t:companies.invalid-registry-code")
        }
      });
    }
  };

  validateFields = () => {
    const { t } = this.props;
    const { contact_email, billing_contact_email, product_return, validationErrors } = this.state;
    const errors = {};
    if (contact_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(contact_email)) {
      errors.contact_email = t("t:auth.please-enter-correct-email");
    }
    if (
      billing_contact_email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(billing_contact_email)
    ) {
      errors.billing_contact_email = t("t:auth.please-enter-correct-email");
    }
    if (product_return && !urlPattern.test(product_return)) {
      errors.product_return = t("t:auth.please-enter-valid-url");
    }
    this.setState({ validationErrors: { ...validationErrors, ...errors } });
    return errors;
  };

  createFormData = () => {
    const {
      name,
      legal_name,
      description,
      address,
      registry_code,
      contact_person_full_name,
      contact_person_phone_number,
      contact_email,
      bank_account_number,
      country_of_registration,
      billing_contact_email,
      imagePreview,
      addresses,
      blobToDownload,
      delivery_companies,
      product_return,
      no_product_return
    } = this.state;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("legal_name", legal_name);
    formData.append("description", description);
    !!imagePreview && formData.append("image", blobToDownload, "company.png");
    formData.append("address", address);
    formData.append("registry_code", registry_code);
    formData.append("contact_person_full_name", contact_person_full_name);
    formData.append("contact_person_phone_number", contact_person_phone_number);
    formData.append("contact_email", contact_email);
    formData.append("billing_contact_email", billing_contact_email);
    formData.append("bank_account_number", bank_account_number);
    formData.append("country_of_registration", country_of_registration || "EE");
    addresses.length && formData.append("addresses", JSON.stringify(addresses));
    formData.append("delivery_companies", JSON.stringify(delivery_companies) || "");
    formData.append("product_return", product_return);
    formData.append("no_product_return", no_product_return);
    return formData;
  };

  onFormSubmit = async () => {
    const {
      patchCompanyDetails,
      postNewCompany,
      history,
      resetCompanyErrors,
      mode,
      match
    } = this.props;
    const { validationErrors } = this.state;
    if (_isEmpty(this.validateFields())) {
      this.setState({ loading: true });
      let res;
      if (mode === "edit") {
        res = await patchCompanyDetails(match.params.id, this.createFormData());
      } else {
        res = await postNewCompany(this.createFormData());
      }
      if (res.error) {
        const tempValidationErrors = { ...validationErrors };
        if (res.error.response.data.contact_person_phone_number)
          tempValidationErrors.contact_person_phone_number =
            res.error.response.data.contact_person_phone_number[0].message;
        this.setState({
          openErrorSnack: true,
          loading: false,
          validationErrors: tempValidationErrors
        });
      } else {
        resetCompanyErrors();
        history.push(mode === "createFirst" ? "/main/campaigns" : "/main/profile/?tab=companies");
      }
    }
  };

  onTermsAndConditionsChange = () => this.setState({ termsAccepted: !this.state.termsAccepted });
  onNoReturnProductChange = () => {
    this.setState({ no_product_return: !this.state.no_product_return, product_return: "" });
  };

  renderCountrySelectLabel = country => {
    if (!country) return "Loading country list...";
    return (
      <div className="country-select-label">
        <Flag code={country.country_iso_code} />
        {getOption(country.country_name)}
      </div>
    );
  };

  render() {
    const { companyError, t, i18n, mode, noTerms = false, euCountries } = this.props;
    const {
      name,
      legal_name,
      description,
      address,
      registry_code,
      contact_person_full_name,
      contact_person_phone_number,
      contact_email,
      bank_account_number,
      country_of_registration,
      billing_contact_email,
      openErrorSnack,
      loading,
      image,
      imagePreview,
      previewDialogOpen,
      pickupAddressDialogOpen,
      deletePickupAddressDialogOpen,
      pickupAddressToChange,
      addresses,
      imageToCrop,
      delivery_companies,
      product_return,
      no_product_return,
      validationErrors,
      termsAccepted
    } = this.state;
    const fieldsAreMissing =
      !name ||
      !legal_name ||
      !description ||
      (!image && !imagePreview) ||
      !address ||
      !registry_code ||
      !contact_person_full_name ||
      !contact_person_phone_number ||
      !contact_email ||
      !bank_account_number ||
      !billing_contact_email ||
      (!no_product_return && !product_return);
    const creatingMode = mode === "create";
    const editingMode = mode === "edit";
    const firstCompanyMode = mode === "createFirst";
    const countryOfRegistration = euCountries.find(
      c => c.country_iso_code === (country_of_registration || "EE")
    );

    return (
      <div className="company-form">
        <div className="title">
          {creatingMode && t("t:companies.add-new-company")}
          {editingMode && t("t:companies.edit-company")}
          {firstCompanyMode && t("t:companies.create-company-profile")}
        </div>
        <span className="info">
          {`${t("t:companies.company-name")} *`}
          <TooltipMessage
            text={t("t:helper-texts.company-brand-name")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        <div className="input_wrapper">
          <input
            type="text"
            value={name}
            onChange={e => this.onFieldChange("name", e.target.value)}
          />
        </div>
        <span className="info">
          {`${t("t:companies.company-legal-name")} *`}
          <TooltipMessage
            text={t("t:helper-texts.company-legal-name")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        <div className="input_wrapper">
          <input
            type="text"
            value={legal_name}
            onChange={e => this.onFieldChange("legal_name", e.target.value)}
          />
        </div>
        <span className="info">
          {`${t("t:companies.company-description")} *`}
          <TooltipMessage
            text={t("t:helper-texts.company-description")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        <div className="input_wrapper">
          <textarea value={description} onChange={e => this.onDescriptionChange(e.target.value)} />
          <CharactersLeft title={description} maxCharacters={350} />
        </div>
        <span className="info">
          {`${t("t:companies.company-image")} *`}
          <TooltipMessage
            text={
              <p>
                {t("t:helper-texts.image")} <br />
                {t("t:helper-texts.aspect-ratio-and-min-res", {
                  aspect_ratio: "16:9",
                  min_resolution: "1280x720"
                })}
              </p>
            }
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        {image && (
          <div className="drag_img">
            <p>
              {imagePreview ? (
                <img src={imagePreview} alt="upload" />
              ) : (
                <CloudinaryImage
                  cloudName="campy"
                  width="25"
                  height="25"
                  gravity="face"
                  crop="thumb"
                  publicId={image.split("/").pop()}
                />
              )}
            </p>
            <div>
              {image.name ||
                image
                  .split("/")
                  .pop()
                  .slice(0, 49)}
            </div>
            <button onClick={() => this.setState({ previewDialogOpen: true })}>
              <img src={eye} alt="eye" />
            </button>
            <button onClick={this.onImageDelete}>
              <img src={del} alt="del" />
            </button>
          </div>
        )}
        <Dropzone
          placeholder={t("t:common.drop-your-image-here")}
          multiple={false}
          handleFiles={this.handleFiles}
        />
        <span className="info">
          {`${t("t:companies.company-address")} *`}
          <TooltipMessage
            text={t("t:helper-texts.company-address")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        <div className="input_wrapper">
          <input
            type="text"
            value={address}
            onChange={e => this.onFieldChange("address", e.target.value)}
          />
        </div>
        <div className="country-fields">
          <div>
            <span className="info">
              {`${t("t:companies.country-of-registration")} *`}
              <TooltipMessage
                text={t("t:helper-texts.company-country-code")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img className="tooltip-question-icon" src={Shape} alt="Shape" />
              </TooltipMessage>
            </span>
            <FormControl className="select_wrapper">
              <SelectComponent
                value={{
                  label: this.renderCountrySelectLabel(countryOfRegistration),
                  value: countryOfRegistration?.country_iso_code
                }}
                options={euCountries.map(c => ({
                  label: this.renderCountrySelectLabel(c),
                  value: c.country_iso_code
                }))}
                change={this.handleCountryChange}
                isClearable="false"
                isSearchable={false}
              />
            </FormControl>
          </div>
          <div>
            <span className="info">
              {`${t("t:companies.registry-code")} *`}
              <TooltipMessage
                text={t("t:helper-texts.company-registry-code")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img className="tooltip-question-icon" src={Shape} alt="Shape" />
              </TooltipMessage>
            </span>
            <div className="input_wrapper">
              <input
                type="text"
                value={registry_code}
                onBlur={
                  countryOfRegistration?.country_iso_code === "EE" && this.validateRegistryCode
                }
                onChange={e => this.onFieldChange("registry_code", e.target.value)}
              />
              <span className="validation-error">{validationErrors.registry_code}</span>
            </div>
          </div>
        </div>
        <span className="info">
          {`${t("t:companies.full-name-of-contact-person")} *`}
          <TooltipMessage
            text={t("t:helper-texts.company-full-name-of-contact-person")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        <div className="input_wrapper">
          <input
            type="text"
            value={contact_person_full_name}
            onChange={e => this.onFieldChange("contact_person_full_name", e.target.value)}
          />
        </div>
        <div className={clsx(["fields-wrapper", editingMode && "two-fields"])}>
          <div>
            <span className="info">
              {`${t("t:companies.contact-email-address")} *`}
              <TooltipMessage
                text={t("t:helper-texts.company-contact-email-address")}
                delay={200}
                position="top"
                classes="step_tooltip"
              >
                <img className="tooltip-question-icon" src={Shape} alt="Shape" />
              </TooltipMessage>
            </span>
            <div className="input_wrapper">
              <input
                type="email"
                value={contact_email}
                onBlur={this.validateFields}
                onChange={e => this.onFieldChange("contact_email", e.target.value)}
              />
              <span className="validation-error">{validationErrors.contact_email}</span>
            </div>
          </div>
          <div>
            <span className="info">{`${t("t:companies.contact-person-phone-number")} *`}</span>
            <PhoneNumberInput
              value={contact_person_phone_number}
              onChange={e => this.onFieldChange("contact_person_phone_number", e)}
              error={validationErrors.contact_person_phone_number}
            />
          </div>
        </div>
        <span className="info">
          {`${t("t:companies.company-bank-account-number")} *`}
          <TooltipMessage
            text={t("t:helper-texts.company-bank-account-number")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        <div className="input_wrapper">
          <input
            type="text"
            value={bank_account_number}
            onBlur={this.validateFields}
            onChange={e => this.onFieldChange("bank_account_number", e.target.value)}
          />
          <span className="validation-error">{validationErrors.bank_account_number}</span>
        </div>
        <span className="info">
          {`${t("t:companies.billing-contact-email-address")} *`}
          <TooltipMessage
            text={t("t:helper-texts.billing-contact-email-address")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        <div className="input_wrapper">
          <input
            type="email"
            value={billing_contact_email}
            onBlur={this.validateFields}
            onChange={e => this.onFieldChange("billing_contact_email", e.target.value)}
          />
          <span className="validation-error">{validationErrors.billing_contact_email}</span>
        </div>
        <span className="info">
          {t("t:companies.company-pickup-addresses")}
          <TooltipMessage
            text={t("t:helper-texts.company-pickup-addresses")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        <div className="pickup_addresses_wrapper">
          {addresses.map((el, key) => (
            <div className="address" key={`address_${key}`}>
              <span>{el.address}</span>
              <div className="address_controls">
                <button
                  onClick={() =>
                    this.setState({
                      pickupAddressToChange: el,
                      idOfAddressToChange: key,
                      pickupAddressDialogOpen: true
                    })
                  }
                >
                  <img src={edit} alt="edit" />
                </button>
                <button
                  onClick={() =>
                    this.setState({
                      idOfAddressToChange: key,
                      deletePickupAddressDialogOpen: true
                    })
                  }
                >
                  <img src={del} alt="del" />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div
          className="add_address"
          onClick={() => this.setState({ pickupAddressDialogOpen: true })}
        >
          + {t("t:companies.add-address")}
        </div>
        <DeliveryCompanies
          delivery_companies={delivery_companies}
          setDeliveryCompanies={val => this.setState({ delivery_companies: val })}
        />
        <span className="info">
          {`${t("t:companies.product-return")} *`}
          <TooltipMessage
            text={t("t:helper-texts.product-return")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img className="tooltip-question-icon" src={Shape} alt="Shape" />
          </TooltipMessage>
        </span>
        <div className="input_wrapper">
          <input
            type="product_return"
            value={product_return}
            onBlur={this.validateFields}
            onChange={e => this.onFieldChange("product_return", e.target.value)}
            disabled={no_product_return}
            style={{ opacity: no_product_return ? 0.3 : 1 }}
          />
          <span className="validation-error">{validationErrors.product_return}</span>
          <div className="input_wrapper--check-field">
            <Checkbox
              checked={no_product_return}
              onChange={this.onNoReturnProductChange}
              checkedIcon={<img src={CheckOn} alt="check on" />}
              icon={<img src={CheckOff} alt="check off" />}
            />
            <span>{t("t:companies.no-product-return")}</span>
          </div>
        </div>

        {!noTerms && (
          <div className="check-wrapper">
            <Checkbox
              checked={termsAccepted}
              onChange={this.onTermsAndConditionsChange}
              checkedIcon={<img src={CheckOn} alt="check on" />}
              icon={<img src={CheckOff} alt="check off" />}
            />
            <p>
              {t("t:auth.i-agree")}
              <a
                href={
                  i18n.language === "et"
                    ? TERMS_AND_CONDITIONS_ESTONIAN_URL
                    : TERMS_AND_CONDITIONS_URL
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp;{t("t:auth.terms-of-service")}&nbsp;
              </a>
              {t("t:common.and")}
              <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
                &nbsp;{t("t:auth.privacy-policy")}&nbsp;
              </a>
            </p>
          </div>
        )}
        <div className="btn_wrapper">
          <TooltipMessage
            text={fieldsAreMissing ? t("t:common.enter-mandatory-fields") : ""}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <div>
              <DefaultButton
                variant="contained"
                classes="add_product"
                type="button"
                onClick={this.onFormSubmit}
                loading={loading}
                disabled={
                  fieldsAreMissing ||
                  loading ||
                  !_isEmpty(validationErrors) ||
                  (!noTerms && !termsAccepted)
                }
              >
                {creatingMode && t("t:companies.add-company")}
                {editingMode && t("t:common.save-changes")}
                {firstCompanyMode && t("t:companies.create-profile")}
              </DefaultButton>
            </div>
          </TooltipMessage>
        </div>
        {firstCompanyMode ? (
          <div role="button" className="text_hover back" onClick={this.onLogoutClick}>
            {t("t:common.sign-out")}
          </div>
        ) : (
          <Link to="/main/profile/?tab=companies" className="text_hover back">
            {t("t:common.cancel")}
          </Link>
        )}
        <Snack
          open={openErrorSnack}
          handleSnack={() => this.setState({ openErrorSnack: false })}
          message={companyError && findAnError(companyError)}
          type="error"
        />
        <DialogComponent
          open={previewDialogOpen}
          onClose={() => this.setState({ previewDialogOpen: false })}
          closeIcon
          classes="image_dialog"
          rootClass="image_root_dialog"
        >
          <div>
            <img src={imagePreview || image} alt="preview" />
          </div>
        </DialogComponent>
        <DialogComponent
          open={pickupAddressDialogOpen}
          onClose={this.closePickupAddressDialog}
          closeIcon
          classes="pickup_address"
        >
          <div className="pickup_address_dialog">
            <h2>
              {pickupAddressToChange
                ? t("t:companies.edit-pickup-address")
                : t("t:companies.add-pickup-address")}
            </h2>
            <GooglePickupAddress
              defaultAddress={pickupAddressToChange}
              changeAddress={this.savePickupAddress}
              closeDialog={this.closePickupAddressDialog}
            />
          </div>
        </DialogComponent>
        <DialogComponent
          open={deletePickupAddressDialogOpen}
          onClose={() =>
            this.setState({
              deletePickupAddressDialogOpen: false,
              idOfAddressToChange: undefined
            })
          }
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:companies.delete-pickup-address")}</div>
            <div className="dialog_description">
              {t("t:companies.delete-pickup-address-confirmation")}
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.deletePickupAddress}
              >
                {t("t:common.delete")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() =>
                  this.setState({
                    deletePickupAddressDialogOpen: false,
                    idOfAddressToChange: undefined
                  })
                }
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        {imageToCrop && (
          <CropperComponent
            imageToCrop={imageToCrop}
            onFinishCropping={this.onFinishCroppingImage}
            onCancelCropping={this.onCancelCropping}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ companies }) => {
  return {
    companyError: companies.companyError,
    euCountries: companies.euCountries
  };
};

const mapDispatchToProps = {
  postNewCompany,
  patchCompanyDetails,
  resetCompanyErrors,
  setCompanyError,
  checkRegistryCode,
  getEUCountries
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(CompanyForm)));
