import { PRODUCTS } from "../constants";

const INITIAL_STATE = {
  list: "loading",
  products: [],
  productInfo: {},
  colors: [],

  productError: ""
};

const reduceProducts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRODUCTS.GET_LIST_SUCCESS:
      return { ...state, list: action.payload.data };
    case PRODUCTS.GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload.data };
    case PRODUCTS.GET_PRODUCT_SUCCESS:
      return { ...state, productInfo: action.payload.data };
    case PRODUCTS.GET_COLORS_SUCCESS:
      return { ...state, colors: action.payload.data };

    case PRODUCTS.PATCH_PRODUCT_FAIL:
      return { ...state, productError: action.error.response.data };
    case PRODUCTS.DELETE_PRODUCT_FAIL:
      return { ...state, productError: action.error.response.data };
    case PRODUCTS.POST_PRODUCT_FAIL:
      return { ...state, productError: action.error.response.data };
    case PRODUCTS.SET_ERROR:
      return {
        ...state,
        productError: action.error
      };
    case PRODUCTS.RESET_ERRORS:
      return {
        ...state,
        productError: ""
      };
    case PRODUCTS.RESET_PRODUCTS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reduceProducts;
