import React from "react";
import { PushSpinner } from "react-spinners-kit";

const Loader = props => (
  <div className="loader_block">
    <PushSpinner sizeUnit={"px"} color={"#F23EA6"} {...props} />
  </div>
);

export default Loader;
