import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import { Image as CloudinaryImage } from "cloudinary-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";

import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { getOption, numberWithSpaces } from "../../helpers/functions";
import Loader from "../HelperComponents/Loader";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import Snack from "../HelperComponents/Snack/Snack";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import { getProducts, deleteProduct, resetProducts } from "../../actions/productsActions";
import { getDataSourcesList } from "../../actions/dataSourcesActions";

import add from "../../assets/image/i-add.svg";
import zoom from "../../assets/image/zoom.svg";
import menu from "../../assets/image/menu-5.svg";
import EditIcon from "../../assets/image/i-edit.svg";
import DeleteIcon from "../../assets/image/bin.svg";

import "./styles.scss";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activePage: 1,
      searchValue: "",
      openedControls: null,
      deleteDialogOpened: false,
      openErrorSnack: false,
      buttonLoading: false,
      openNewProductOptions: false,
      sourcesDialogOpened:
        new URLSearchParams(props.location.search).get("data-sources") === "true",
      status: { label: getOption(props.t("t:products.all-products")), value: "" },
      statusList: [
        { label: getOption(props.t("t:products.all-products")), value: "" },
        { label: getOption(props.t("t:products.in-use")), value: "in_use" }
      ]
    };
  }

  componentDidMount() {
    const { getDataSourcesList, companyId, history } = this.props;
    if (!companyId) {
      history.push("/main");
    }
    this.fetchProducts(1).then(() => {
      getDataSourcesList().then(() => {
        this.setState({ loading: false });
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.status.value !== this.state.status.value ||
      prevState.searchValue !== this.state.searchValue ||
      this.props.user_info.current_company !== prevProps.user_info.current_company
    ) {
      this.fetchProducts().then(() => {
        this.setState({ activePage: 1 });
      });
    }
  }

  componentWillUnmount() {
    this.props.resetProducts();
  }

  fetchProducts = async () => {
    const { getProducts } = this.props;
    const { searchValue, status } = this.state;
    let data = [];
    // data.push(`page=${page}`);
    // data.push(`page_size=3`);
    status.value && data.push(`status=${status.value}`);
    searchValue && data.push(`search=${searchValue}`);
    await getProducts(data);
  };

  handleControls = id => {
    const { openedControls } = this.state;
    this.setState({ openedControls: openedControls === id ? null : id });
  };

  removeProduct = async () => {
    const { deleteProduct } = this.props;
    const { openedControls } = this.state;
    this.setState({ buttonLoading: true });
    const res = await deleteProduct(openedControls);
    if (res.error) {
      this.setState({
        openErrorSnack: true,
        deleteDialogOpened: false,
        buttonLoading: false,
        openedControls: null
      });
    } else {
      this.fetchProducts().then(() => {
        this.setState({
          deleteDialogOpened: false,
          buttonLoading: false,
          openedControls: null
        });
      });
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event });
  };

  render() {
    const { products, history, productError, dataSources, current_company, t } = this.props;
    const {
      loading,
      searchValue,
      openedControls,
      // activePage,
      deleteDialogOpened,
      buttonLoading,
      openErrorSnack,
      openNewProductOptions,
      sourcesDialogOpened,
      status,
      statusList
    } = this.state;
    if (loading)
      return (
        <div className="loader_wrapper">
          <Loader />
        </div>
      );
    return (
      <div className="page_wrapper products_wrapper">
        <div className="products_title_block">
          <div className="block">
            <div className="title">
              {t("t:products.number-of-products", { number: products.length })}
            </div>
            <div className="title_panel">
              <div className="search">
                <input
                  value={searchValue}
                  onChange={e => this.setState({ searchValue: e.target.value })}
                  type="text"
                  placeholder={t("t:products.search-products")}
                />
                <button>
                  <img src={zoom} alt="zoom" />
                </button>
              </div>
              <FormControl className="select_wrapper">
                <SelectComponent
                  value={status}
                  options={statusList}
                  change={this.handleChange("status")}
                  isClearable="false"
                  isSearchable={false}
                  placeholder=""
                  labelStartAdornment={t("t:common.status")}
                />
              </FormControl>
            </div>
          </div>
          <div className="btn_wrapper">
            {current_company.is_owner && (
              <DefaultButton
                variant="outlined"
                classes="sources_btn"
                onClick={() => this.setState({ sourcesDialogOpened: true })}
              >
                {t("t:products.data-sources")}
              </DefaultButton>
            )}
            <DefaultButton
              variant="contained"
              classes="auth"
              onClick={() =>
                dataSources.length
                  ? this.setState({ openNewProductOptions: true })
                  : history.push(`/main/products/new-product`)
              }
            >
              {t("t:products.new-products")} <img src={add} alt="add" />
            </DefaultButton>
            {openNewProductOptions && (
              <ClickAwayListener
                onClickAway={() => this.setState({ openNewProductOptions: false })}
              >
                <div className="menu">
                  <button onClick={() => history.push(`/main/add-products-via-api`)}>
                    {t("t:products.add-using-api")}
                  </button>
                  <hr />
                  <button onClick={() => history.push(`/main/products/new-product`)}>
                    {t("t:products.add-manually")}
                  </button>
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
        <div className="products_content">
          {products.map(({ id, image, description, name, price, status }) => (
            <div key={`product_item_${id}`}>
              <div className="content">
                {status === "in_use" && (
                  <div className="product-status">{t("t:products.in-use")}</div>
                )}
                <Link to={`/main/products/view-product/${id}`} className="product_block">
                  <div className="img_block">
                    <CloudinaryImage
                      cloudName="campy"
                      height="83"
                      gravity="face"
                      crop="thumb"
                      publicId={image.split("/").pop()}
                    />
                  </div>
                  <div className="product_info">
                    <div>{name}</div>
                    <div className="description">{description}</div>
                  </div>
                </Link>
                <div className="data_info">
                  <span>{t("t:products.full-price")}</span>
                  <p>{numberWithSpaces(price.toFixed(2))} €</p>
                </div>
              </div>
              {status === "available" && (
                <div className="more_info_btn">
                  <IconButton
                    onClick={() => this.handleControls(id)}
                    classes={{
                      root: "dialog_close_button"
                    }}
                  >
                    <img src={menu} alt="menu" />
                  </IconButton>
                </div>
              )}
              {openedControls === id && (
                <ClickAwayListener onClickAway={() => this.handleControls(id)}>
                  <div className="menu">
                    <button onClick={() => history.push(`/main/products/edit-product/${id}`)}>
                      <img src={EditIcon} alt="edit" />
                      {t("t:products.edit-product")}
                    </button>
                    <button
                      onClick={() => {
                        this.setState({ deleteDialogOpened: true });
                      }}
                    >
                      <img src={DeleteIcon} alt="delete" />
                      {t("t:products.delete-product")}
                    </button>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          ))}
        </div>
        {/*<div className="pagination_wrapper">*/}
        {/*    {products.count > 3 && (*/}
        {/*        <Pagination*/}
        {/*            active={activePage}*/}
        {/*            pageCount={Math.ceil(products.count / 3)}*/}
        {/*            onChange={e => {*/}
        {/*                this.fetchProducts(e.selected + 1).then(() => {*/}
        {/*                    this.setState({ activePage: e.selected + 1, reloading: false });*/}
        {/*                });*/}
        {/*            }}*/}
        {/*            t={t}*/}
        {/*        />*/}
        {/*    )}*/}
        {/*</div>*/}
        <DialogComponent
          open={deleteDialogOpened}
          onClose={() => this.setState({ deleteDialogOpened: false })}
          closeIcon
        >
          <div className="delete_dialog product_delete_dialog">
            <div className="dialog_name">{t("t:products.delete-product")}</div>
            <div className="dialog_title">{t("t:products.delete-product-confirmation")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="delete"
                onClick={this.removeProduct}
                loading={buttonLoading}
              >
                {t("t:common.delete")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.setState({ deleteDialogOpened: false })}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={sourcesDialogOpened}
          onClose={() => this.setState({ sourcesDialogOpened: false })}
          closeIcon
        >
          <div className="campaign_users_dialog">
            <div className="dialog_title">{t("t:products.configure-data-sources")}</div>
            {dataSources.length ? (
              <Fragment>
                <div className="description">{t("t:products.following-data-sources")}</div>
                <br />
                <div className="users_list">
                  {dataSources.map(({ id, name }) => (
                    <div className="user_row" key={`campaign_of_user_${id}`}>
                      <div className="user">{name}</div>
                      <div className="btn_wrapper">
                        <DefaultButton
                          variant="outlined"
                          classes="leave_btn"
                          type="link"
                          to={`/main/edit-data-source/${id}`}
                        >
                          {t("t:common.edit")}
                        </DefaultButton>
                      </div>
                    </div>
                  ))}
                </div>
              </Fragment>
            ) : (
              <div className="description">{t("t:products.no-data-source")}</div>
            )}
            <div className="links-wrapper">
              {t("t:distribution.add-new")}
              &nbsp;
              <Link to="/main/add-new-data-source">{t("t:data-sources.custom-api")}</Link>
              &nbsp;
              {t("t:common.or")}
              &nbsp;
              <Link to="/main/add-magento-data-source/info">{t("t:data-sources.magento-api")}</Link>
              &nbsp;
              <span className="lowercase">{t("t:data-sources.data-source")}</span>
            </div>
          </div>
        </DialogComponent>
        <Snack
          open={openErrorSnack}
          handleSnack={() => this.setState({ openErrorSnack: false })}
          message={
            productError &&
            Object.values(productError)[0] &&
            Object.values(productError)[0][0].message
          }
          type="error"
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, products, dataSources }) => {
  return {
    user_info: auth.user_info,
    products: products.products,
    productError: products.productError,
    companyId: auth.user_info.current_company ? auth.user_info.current_company.id : null,
    dataSources: dataSources.dataSourcesList,
    current_company: auth.user_info.current_company
  };
};

const mapDispatchToProps = {
  getProducts,
  deleteProduct,
  getDataSourcesList,
  resetProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Products));
