import React from "react";

import { renderMobileImage } from "../../helpers";

import SuccessIcon from "../../../../../../assets/image/mobile-check.svg";
import EuroIcon from "../../../../../../assets/image/mobile-euro.svg";

const Novelty = ({ title, description, image }) => (
  <>
    <img src={SuccessIcon} alt="success" className="success-icon" />
    <div className="products-wrapper">
      <div className="product-wrapper">
        {renderMobileImage(image, 90, 90)}
        <img src={EuroIcon} alt="euro" className="product-wrapper__icon" />
      </div>
    </div>
    <div className="success-text">
      <strong>{title}</strong>
      <p>{description}</p>
    </div>
  </>
);

export default Novelty;
