import { takeEvery, put } from "redux-saga/effects";
import { getProfile } from "../actions/authActions";

export function* handleClientsActions() {
  yield takeEvery("POST_CONNECT_GOOGLE_SUCCESS", fetchProfileInfo);
  yield takeEvery("POST_CONNECT_FACEBOOK_SUCCESS", fetchProfileInfo);
  yield takeEvery("POST_DISCONNECT_FACEBOOK_SUCCESS", fetchProfileInfo);
  yield takeEvery("POST_DISCONNECT_GOOGLE_SUCCESS", fetchProfileInfo);
  yield takeEvery("POST_DISCONNECT_APPLE_SUCCESS", fetchProfileInfo);
  yield takeEvery("POST_AVATAR_SUCCESS", fetchProfileInfo);
  yield takeEvery("POST_SET_PASSWORD_SUCCESS", fetchProfileInfo);
  yield takeEvery("POST_CHANGE_PASSWORD_SUCCESS", fetchProfileInfo);
  yield takeEvery("POST_CODE_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_INFO_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_MANAGERS_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_USERS_LIST_FAIL", fetchProfileInfo);
  yield takeEvery("GET_DATA_SOURCES_LIST_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_DATA_SOURCES_LIST_FAIL", fetchProfileInfo);
  yield takeEvery("POST_LEAVE_CAMPAIGN_SUCCESS", fetchProfileInfo);
  yield takeEvery("POST_DEPOSIT_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_CSV_BASED_AUDIENCE_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_CSV_BASED_AUDIENCE_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_CSV_BASED_AUDIENCE_BY_ID_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_ANALYTICS_BASED_AUDIENCE_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_CUSTOM_AUDIENCE_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_CUSTOM_AUDIENCE_BY_ID_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_INVITED_USERS_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_USERS_LIST_SUCCESS", fetchProfileInfo);
  yield takeEvery("GET_DELIVERIES_SUCCESS", fetchProfileInfo);
  // yield takeEvery("GET_CAMPAIGNS_SUCCESS", fetchProfileInfo);
}

function* fetchProfileInfo() {
  yield put(getProfile());
}
