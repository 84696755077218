import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import FormControl from "@material-ui/core/FormControl";

import DefaultButton from "../../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../../../../HelperComponents/DialogComponent/DialogComponent";
import {
  getAnalyticsBasedAudience,
  updateAnalyticsBasedAudience
} from "../../../../../../actions/audienceActions";
import SelectComponent from "../../../../../HelperComponents/SelectComponent/SelectComponent";

let AddToExistingListDialog = ({ isOpen, handleDialog, type, period }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: campaignID } = useParams();
  const { buttonLoading, analyticsBasedAudience } = useSelector(({ audience }) => audience);
  const { is_published: isPublished } = useSelector(({ campaign }) => campaign.campaignInfo);
  const [segmentName, setSegmentName] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setListLoading(true);
      dispatch(getAnalyticsBasedAudience(["page=1", "page_size=1000"]));
      setListLoading(false);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const segmentsList = useMemo(
    () => analyticsBasedAudience.results.map(({ id, name }) => ({ label: name, value: id })),
    // eslint-disable-next-line
    [JSON.stringify(analyticsBasedAudience)]
  );

  const prepareDataForSubmit = () => ({
    audience: segmentName.value,
    type,
    campaign: +campaignID,
    period
  });

  const onSubmit = () => {
    dispatch(updateAnalyticsBasedAudience(prepareDataForSubmit())).then(res => {
      if (res.payload && res.payload.status === 200) handleDialog();
    });
  };

  return (
    <DialogComponent open={isOpen} onClose={handleDialog} closeIcon>
      <div className="default_dialog_wrapper">
        <div className="dialog_title">{t("t:campaign-analytics.add-to-existing-list")}</div>
        <div className="input_wrapper mt-0">
          <div className="label">{t("t:audience.segment-name")}</div>
          <FormControl style={{ width: "100%" }}>
            <SelectComponent
              value={segmentName}
              options={segmentsList}
              change={setSegmentName}
              isClearable="false"
              isSearchable={false}
              placeholder={t("t:audience.select-segment")}
              loading={listLoading}
            />
          </FormControl>
        </div>
        {isPublished && (
          <div className="dialog-info-text">{t("t:campaign-analytics.list-creating-warning")}</div>
        )}
        <div className="buttons_wrapper">
          <DefaultButton
            variant="contained"
            classes="purple_btn"
            loading={buttonLoading}
            disabled={!segmentName || buttonLoading}
            onClick={onSubmit}
          >
            {t("t:common.add")}
          </DefaultButton>
          <DefaultButton variant="contained" classes="cancel_btn" onClick={handleDialog}>
            {t("t:common.cancel")}
          </DefaultButton>
        </div>
      </div>
    </DialogComponent>
  );
};

export default memo(AddToExistingListDialog);
