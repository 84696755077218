/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router";
import clsx from "clsx";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import ListItem from "./ListItem";
import Tabs from "../../HelperComponents/Tabs";
import {
  audienceTabs,
  sortByValuesCsvBased,
  statusesAnalyticsBased,
  statusesCustomAudience
} from "../constants";
import { useQueryParams } from "../../../helpers/hooks";
import Pagination from "../../HelperComponents/Pagination/Pagination";
import {
  getCSVBasedAudience,
  resetAudienceStore,
  setAudienceMetaFields,
  getAnalyticsBasedAudience,
  getCustomAudience
} from "../../../actions/audienceActions";
import ConfirmationDialog from "../../HelperComponents/DialogComponent/ConfirmationDialog/ConfirmationDialog";

import zoom from "../../../assets/image/zoom.svg";
import SortIcon from "../../../assets/image/sort-arrow.svg";
import add from "../../../assets/image/i-add.svg";

import "./styles.scss";

const AudienceList = () => {
  const { getParam } = useQueryParams();
  const [tab, setTab] = useState(getParam("tab") || "csv_based");
  const {
    csvBasedAudience,
    analyticsBasedAudience,
    customAudience,
    metaCsvBasedAudience,
    metaAnalyticsBasedAudience,
    metaCustomAudience,
    confirmationDialog,
    buttonLoading
  } = useSelector(({ audience }) => audience);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    if (tab !== getParam("tab")) {
      setTab(getParam("tab"));
    }
  }, [search]);

  const {
    audienceList,
    sortByValues,
    fetchAudience,
    metaFields,
    selectorMetaKey,
    selectorAdornmentText,
    searchPlaceholder
  } = useMemo(() => {
    if (tab === "analytics_based")
      return {
        sortByValues: statusesAnalyticsBased(t),
        audienceList: analyticsBasedAudience,
        fetchAudience: getAnalyticsBasedAudience,
        metaFields: metaAnalyticsBasedAudience,
        selectorMetaKey: "status",
        selectorAdornmentText: t("t:common.status"),
        searchPlaceholder: t("t:audience.search-segment")
      };
    if (tab === "custom")
      return {
        sortByValues: statusesCustomAudience(t),
        audienceList: customAudience,
        fetchAudience: getCustomAudience,
        metaFields: metaCustomAudience,
        selectorMetaKey: "status",
        selectorAdornmentText: t("t:common.status"),
        searchPlaceholder: t("t:audience.search-segment")
      };
    return {
      sortByValues: sortByValuesCsvBased(t),
      audienceList: csvBasedAudience,
      fetchAudience: getCSVBasedAudience,
      metaFields: metaCsvBasedAudience,
      selectorMetaKey: "ordering",
      selectorAdornmentText: t("t:common.sort-by"),
      searchPlaceholder: t("t:audience.search-audience")
    };
  }, [
    tab,
    JSON.stringify(csvBasedAudience),
    JSON.stringify(analyticsBasedAudience),
    JSON.stringify(customAudience),
    JSON.stringify(metaCsvBasedAudience),
    JSON.stringify(metaAnalyticsBasedAudience),
    JSON.stringify(metaCustomAudience)
  ]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return () => {
      dispatch(resetAudienceStore());
    };
    // eslint-disable-next-line
  }, []);

  const prepareQueries = () => {
    let queries = [
      `page=${metaFields.page}`,
      `page_size=5`,
      `search=${metaFields.search}`,
      `ordering=${metaFields.orderingDirection}${metaFields.ordering}`
    ];
    if (tab !== "csv_based") queries.push(`status=${metaFields.status}`);
    return queries;
  };

  useEffect(() => {
    dispatch(fetchAudience(prepareQueries()));
    // eslint-disable-next-line
  }, [tab, JSON.stringify(metaFields)]);

  useEffect(() => {
    if (tab !== getParam("tab")) history.push(`/main/audience?tab=${tab}`);
    // eslint-disable-next-line
  }, [tab]);

  const onSearchChange = search =>
    dispatch(setAudienceMetaFields({ [metaFields.key]: { search } }));

  const onInputChange = useMemo(
    () => _.debounce(onSearchChange, 300),
    // eslint-disable-next-line
    []
  );

  const onPageChange = page => dispatch(setAudienceMetaFields({ [metaFields.key]: { page } }));

  const onSelectorValueChange = ({ value }) =>
    dispatch(setAudienceMetaFields({ [metaFields.key]: { [selectorMetaKey]: value } }));

  const onSortDirectionClick = () => {
    dispatch(
      setAudienceMetaFields({
        [metaFields.key]: {
          orderingDirection: metaFields.orderingDirection === "" ? "-" : ""
        }
      })
    );
  };

  return (
    <div className="page_wrapper">
      <div className="audience-list__title">{t("t:common.audience")}</div>
      <Tabs
        currentTab={tab}
        changeTab={setTab}
        tabs={audienceTabs.map(({ translationKey, value }) => ({
          label: t(translationKey),
          value
        }))}
        styles="audience-list__tabs"
      />
      <div className="items-center justify-between">
        <div className="items-center">
          <div className="audience-list__search">
            <input
              type="text"
              placeholder={searchPlaceholder}
              onChange={e => {
                e.persist();
                onInputChange(e.target.value);
              }}
            />
            <img src={zoom} alt="zoom" />
          </div>
          <FormControl className="audience-list__select">
            <SelectComponent
              value={sortByValues.find(el => el.value === metaFields[selectorMetaKey]) || null}
              options={sortByValues}
              change={onSelectorValueChange}
              isClearable="false"
              isSearchable={false}
              placeholder=""
              labelStartAdornment={selectorAdornmentText}
            />
          </FormControl>
          <div className="items-center">
            <div className="audience-list__sort-by items-center">
              <p className="audience-list__sort-by--label">{t("t:common.sort-by")}</p>
              <p className="audience-list__sort-by--value">{t("t:audience.last-update")}</p>
            </div>
            <DefaultButton
              variant="contained"
              classes={clsx("sort-btn", metaFields.orderingDirection === "" && "rotated")}
              type="button"
              onClick={onSortDirectionClick}
            >
              <img src={SortIcon} alt="arrow" />
            </DefaultButton>
          </div>
        </div>
        {tab === "csv_based" && (
          <DefaultButton
            variant="contained"
            classes="auth"
            type="link"
            to="/main/audience/csv-based/create"
          >
            {t("t:audience.upload-csv-file")}
          </DefaultButton>
        )}
        {tab === "custom" && (
          <DefaultButton
            variant="contained"
            classes="auth"
            type="link"
            to="/main/audience/custom/create"
          >
            {t("t:audience.create-segment")}
            <img src={add} alt="add" />
          </DefaultButton>
        )}
      </div>
      <div className="audience-list__wrapper">
        {audienceList.results.map(el => (
          <ListItem key={el.id} {...el} audienceType={tab} />
        ))}
      </div>
      <div className="audience-list__pagination">
        {audienceList.count > 5 && (
          <Pagination
            active={metaFields.page}
            pageCount={Math.ceil(audienceList.count / 5)}
            onChange={e => onPageChange(e.selected + 1)}
            t={t}
          />
        )}
      </div>
      <ConfirmationDialog {...confirmationDialog} buttonLoading={buttonLoading} />
    </div>
  );
};

export default AudienceList;
