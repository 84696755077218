import React from "react";

import CompanyForm from "../Profile/Companies/CompanyForm";

import campy_logo from "../../assets/image/campy.svg";

import "./styles.scss";

const CreateCompanyProfile = () => (
  <div className="create-company-profile">
    <header className="create-company-profile__header">
      <img src={campy_logo} alt="campy_logo" />
    </header>
    <section className="create-company-profile__wrapper">
      <CompanyForm
        mode="createFirst"
        defaultValues={{
          name: "",
          legal_name: "",
          description: "",
          image: null,
          address: "",
          registry_code: "",
          country: "Estonia",
          contact_person_full_name: "",
          contact_person_phone_number: "",
          contact_email: "",
          addresses: [],
          delivery_companies: [],
          product_return: "",
          no_product_return: false
        }}
      />
    </section>
  </div>
);

export default CreateCompanyProfile;
