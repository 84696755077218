import { COMPANIES } from "../constants";

export function setCurrentCompany(company_id) {
  return {
    type: COMPANIES.SET_CURRENT_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/user/set-current-company/${company_id}/`,
        method: "post"
      }
    }
  };
}

export function postCompany(data) {
  return {
    type: COMPANIES.CREATE_CAMPAIGN,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaigns/create/`,
        method: "post",
        data
      }
    }
  };
}

export function getCurrentCompanyCampaigns() {
  return {
    type: COMPANIES.GET_CURRENT_COMPANY_CAMPAIGNS,
    payload: {
      client: "default", //for selector on campaign creation
      request: {
        url: `/manage/company-managers/invite/campaigns-list/`,
        method: "get"
      }
    }
  };
}

export function getCampaigns(arr) {
  return {
    type: COMPANIES.GET_CAMPAIGNS,
    payload: {
      client: "default", //for campaigns page
      request: {
        url: `/company/current/campaigns/${arr.length && arr.length !== 0 ? "?" : ""}${arr.join(
          "&"
        )}`,
        method: "get"
      }
    }
  };
}

export function postRestoreCampaign(id) {
  return {
    type: COMPANIES.POST_RESTORE_CAMPAIGN,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${id}/restore/`,
        method: "post"
      }
    }
  };
}

export function postArchiveCampaign(id) {
  return {
    type: COMPANIES.POST_ARCHIVE_CAMPAIGN,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${id}/archive/`,
        method: "post"
      }
    }
  };
}

export function postDuplicateCampaign(id, data) {
  return {
    type: COMPANIES.POST_DUPLICATE_CAMPAIGN,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${id}/clone/`,
        method: "post",
        data
      }
    }
  };
}

export function postLeaveCampaign(id) {
  return {
    type: COMPANIES.POST_LEAVE_CAMPAIGN,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${id}/leave/`,
        method: "post"
      }
    }
  };
}

export function getManagers() {
  return {
    type: COMPANIES.GET_MANAGERS,
    payload: {
      client: "default", //for campaigns page
      request: {
        url: `/company/current/managers/`,
        method: "get"
      }
    }
  };
}

export function getEUCountries() {
  return {
    type: COMPANIES.GET_EU_COUNTRIES,
    payload: {
      client: "default",
      request: {
        url: `/eu-country-phone-codes`,
        method: "get"
      }
    }
  };
}

export function resetCompanyErrors(data) {
  return {
    type: COMPANIES.RESET_ERRORS
  };
}

//profile tab

export function getCompanies(arr) {
  return {
    type: COMPANIES.GET_COMPANIES,
    payload: {
      client: "default",
      request: {
        url: `/companies/${arr.length && arr.length !== 0 ? "?" : ""}${arr.join("&")}`,
        method: "get"
      }
    }
  };
}

export function postNewCompany(data) {
  return {
    type: COMPANIES.POST_NEW_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/companies/`,
        method: "post",
        data //name, image, description, website
      }
    }
  };
}

export function getCompanyDetails(company_id) {
  return {
    type: COMPANIES.GET_COMPANY_DETAILS,
    payload: {
      client: "default",
      request: {
        url: `/companies/${company_id}/`,
        method: "get"
      }
    }
  };
}

export function patchCompanyDetails(company_id, data) {
  return {
    type: COMPANIES.PATCH_COMPANY_DETAILS,
    payload: {
      client: "default",
      request: {
        url: `/companies/${company_id}/`,
        method: "patch",
        data //name, image, description, website
      }
    }
  };
}

export function postArchiveCompany(company_id) {
  return {
    type: COMPANIES.POST_ARCHIVE_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/companies/${company_id}/archive/`,
        method: "post"
      }
    }
  };
}

export function postRestoreCompany(company_id) {
  return {
    type: COMPANIES.POST_RESTORE_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/companies/${company_id}/restore/`,
        method: "post"
      }
    }
  };
}

export function postLeaveCompany(company_id) {
  return {
    type: COMPANIES.POST_LEAVE_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/companies/${company_id}/leave/`,
        method: "post"
      }
    }
  };
}

export function getCompanyAddresses() {
  return {
    type: COMPANIES.GET_COMPANY_ADDRESSES,
    payload: {
      client: "default",
      request: {
        url: `/company/current/addresses/`,
        method: "get"
      }
    }
  };
}

export function postAddAddress(data) {
  return {
    type: COMPANIES.POST_ADD_ADDRESS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/addresses/add/`,
        method: "post",
        data //address, lat, lng
      }
    }
  };
}

export function getCompanyOptions() {
  return {
    type: COMPANIES.GET_COMPANY_OPTIONS,
    payload: {
      client: "default",
      request: {
        url: `/company/current/options/`,
        method: "get"
      }
    }
  };
}

export function checkRegistryCode(data) {
  return {
    type: COMPANIES.CHECK_REGISTRY_CODE,
    payload: {
      client: "default",
      request: {
        url: `/companies/check-registry-code/`,
        method: "post",
        data // { code }
      }
    }
  };
}

export function generateCompanyAccessToken(id) {
  return {
    type: COMPANIES.POST_GENERATE_ACCESS_TOKEN,
    payload: {
      client: "default",
      request: {
        url: `/companies/${id}/api-key/`,
        method: "post"
      }
    }
  };
}

export function getCompanyAccessToken(id) {
  return {
    type: COMPANIES.GET_COMPANY_ACCESS_TOKEN,
    payload: {
      client: "default",
      request: {
        url: `/companies/${id}/api-key/`,
        method: "get"
      }
    }
  };
}

export function resetCompanyOptions(data) {
  return {
    type: COMPANIES.RESET_COMPANY_OPTIONS_SUCCESS,
    error: data
  };
}

export function setCompanyError(data) {
  return {
    type: COMPANIES.SET_COMPANY_ERROR,
    error: data
  };
}
