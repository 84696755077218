import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import TooltipMessage from "../../../../HelperComponents/TooltipMessage/TooltipMessage";
import { deliveryError } from "../../helpers/errorMessages";
import Tabs from "../../../../HelperComponents/Tabs";
import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../../../HelperComponents/DialogComponent/DialogComponent";
import GooglePickupAddress from "../../../../HelperComponents/GooglePickupAddress/GooglePickupAddress";

import del from "../../../../../assets/image/My_Campaign/del.svg";
import ErrorIcon from "../../../../../assets/image/c-warning-e.svg";
import CheckboxCheckedIcon from "../../../../../assets/image/checkbox_checked.svg";
import CheckboxUncheckedIcon from "../../../../../assets/image/checkbox_unchecked.svg";
import { isDecimalNumberRegexp } from "../../../../../helpers/functions";

const Delivery = ({
  distributionError,
  isEditor,
  addresses,
  companyAddresses,
  postAddAddress,
  getCompanyAddresses,
  onSetFieldsInState,
  deliveries
}) => {
  const [pickupAddressTab, setPickupAddressTab] = useState("select_existing");
  const [submittingPickupAddress, setSubmittingPickupAddress] = useState(false);
  const [pickupAddressDialogOpen, setPickupAddressDialogOpen] = useState(false);
  const { t } = useTranslation();
  const tabs = [
    { label: t("t:distribution.select-existing"), value: "select_existing" },
    { label: t("t:distribution.add-new"), value: "add_new" }
  ];

  const deliveriesMeta = useMemo(
    () => ({
      redeemable_any: deliveries.some(el => el.type === "redeemable_any"),
      pickup: deliveries.some(el => el.type === "pickup"),
      smartpost: deliveries.some(el => el.type === "smartpost"),
      omniva: deliveries.some(el => el.type === "omniva"),
      courier: deliveries.some(el => el.type === "courier")
    }),
    // eslint-disable-next-line
    [JSON.stringify(deliveries)]
  );

  const handlePickupAddress = address => {
    if (!!addresses.find(el => el.id === address.id)) {
      onSetFieldsInState({ addresses: addresses.filter(el => el.id !== address.id) });
    } else {
      onSetFieldsInState({ addresses: [...addresses, address] });
    }
  };

  const savePickupAddress = async (address, coordinates) => {
    setSubmittingPickupAddress(true);
    const res = await postAddAddress({ address, lat: coordinates.lat, lng: coordinates.lng });
    await getCompanyAddresses();
    setPickupAddressTab("select_existing");
    setSubmittingPickupAddress(false);
    onSetFieldsInState({
      addresses: [...addresses, { ...res.payload.data }]
    });
  };

  const onCheck = name => {
    if (isEditor) {
      if (name === "redeemable_any") {
        onSetFieldsInState({
          deliveries: deliveriesMeta[name] ? deliveries : [{ type: name, price: 0 }]
        });
      } else {
        onSetFieldsInState({
          deliveries: deliveriesMeta[name]
            ? deliveries.filter(el => el.type !== name)
            : [...deliveries.filter(el => el.type !== "redeemable_any"), { type: name, price: 0 }]
        });
      }
    }
  };

  const onPriceChange = (e, name) => {
    if (isEditor && (e.target.value === "" || isDecimalNumberRegexp.test(+e.target.value))) {
      onSetFieldsInState({
        deliveries: deliveries.map(el => {
          if (el.type === name)
            return {
              type: name,
              price: e.target.value
            };
          return el;
        })
      });
    }
  };

  return (
    <div className="distribution_row_wrapper">
      <div className="row_name">
        {t("t:distribution.delivery")}
        {distributionError.delivery ? (
          <TooltipMessage
            text={deliveryError(distributionError)}
            delay={200}
            error
            position="top"
            classes=""
          >
            <img src={ErrorIcon} alt="error" />
          </TooltipMessage>
        ) : (
          ""
        )}
      </div>
      <div className="row_content">
        <div className="item_radio no_select">
          <div
            onClick={() => onCheck("redeemable_any")}
            className={clsx(!isEditor && "cursor_not_allowed")}
          >
            <input type="radio" checked={deliveriesMeta.redeemable_any} onChange={() => {}} />
            <label className={clsx(!isEditor && "cursor_not_allowed")}>
              {t("t:distribution.redeemable-any")}
            </label>
          </div>
        </div>
        <hr className="separator" />
        <div className="item_checkbox no_select">
          <div
            onClick={() => onCheck("pickup")}
            className={clsx(!isEditor && "cursor_not_allowed")}
          >
            <input type="checkbox" checked={deliveriesMeta.pickup} onChange={() => {}} />
            <label className={clsx(!isEditor && "cursor_not_allowed")}>
              {t("t:distribution.pickup")}
            </label>
          </div>
        </div>
        {deliveriesMeta.pickup && (
          <>
            <div className="pickup_addresses_wrapper">
              {addresses.map((el, key) => (
                <div className="address" key={`address_${key}`}>
                  <span>{el.address}</span>
                  {isEditor && (
                    <div className="address_controls">
                      <button
                        onClick={() =>
                          onSetFieldsInState({
                            addresses: addresses.filter(item => item.id !== el.id)
                          })
                        }
                      >
                        <img src={del} alt="del" />
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {isEditor && (
              <div className="add_address" onClick={() => setPickupAddressDialogOpen(true)}>
                + {t("t:companies.add-address")} *
              </div>
            )}
            <br />
          </>
        )}
        <div className="item_checkbox no_select">
          <div
            onClick={() => onCheck("smartpost")}
            className={clsx(!isEditor && "cursor_not_allowed")}
          >
            <input type="checkbox" checked={deliveriesMeta.smartpost} onChange={() => {}} />
            <label className={clsx(!isEditor && "cursor_not_allowed")}>
              {t("t:distribution.smartpost-parcel-machine")}
            </label>
          </div>
        </div>
        {deliveriesMeta.smartpost && (
          <div className="input_title_block_my_campaign delivery-price">
            <div>
              <span>{t("t:distribution.delivery-price")}</span>
            </div>
            <div className="price_block_input no_select">
              <input
                type="number"
                disabled={!isEditor}
                value={deliveries.find(el => el.type === "smartpost")?.price || ""}
                onChange={e => onPriceChange(e, "smartpost")}
                placeholder="0.00"
              />
              <span className="small_one">€</span>
            </div>
          </div>
        )}
        <div className="item_checkbox no_select">
          <div
            onClick={() => onCheck("omniva")}
            className={clsx(!isEditor && "cursor_not_allowed")}
          >
            <input type="checkbox" checked={deliveriesMeta.omniva} onChange={() => {}} />
            <label className={clsx(!isEditor && "cursor_not_allowed")}>
              {t("t:distribution.omniva-parcel-machine")}
            </label>
          </div>
        </div>
        {deliveriesMeta.omniva && (
          <div className="input_title_block_my_campaign delivery-price">
            <div>
              <span>{t("t:distribution.delivery-price")}</span>
            </div>
            <div className="price_block_input no_select">
              <input
                type="number"
                disabled={!isEditor}
                value={deliveries.find(el => el.type === "omniva")?.price || ""}
                onChange={e => onPriceChange(e, "omniva")}
                placeholder="0.00"
              />
              <span className="small_one">€</span>
            </div>
          </div>
        )}
        <div className="item_checkbox no_select">
          <div
            onClick={() => onCheck("courier")}
            className={clsx(!isEditor && "cursor_not_allowed")}
          >
            <input type="checkbox" checked={deliveriesMeta.courier} onChange={() => {}} />
            <label htmlFor="radio1" className={clsx(!isEditor && "cursor_not_allowed")}>
              {t("t:distribution.courier")}
            </label>
          </div>
        </div>
        {deliveriesMeta.courier && (
          <div className="input_title_block_my_campaign delivery-price">
            <div>
              <span>{t("t:distribution.delivery-price")}</span>
            </div>
            <div className="price_block_input no_select">
              <input
                type="number"
                disabled={!isEditor}
                value={deliveries.find(el => el.type === "courier")?.price || ""}
                onChange={e => onPriceChange(e, "courier")}
                placeholder="0.00"
              />
              <span className="small_one">€</span>
            </div>
          </div>
        )}
      </div>
      <DialogComponent
        open={pickupAddressDialogOpen}
        onClose={() => setPickupAddressDialogOpen(false)}
        closeIcon
        classes="pickup_address"
      >
        <div className="pickup_address_dialog">
          <h2>{t("t:companies.add-pickup-address")}</h2>
          {companyAddresses.length && (
            <Tabs currentTab={pickupAddressTab} changeTab={setPickupAddressTab} tabs={tabs} />
          )}
          {!companyAddresses.length || pickupAddressTab === "add_new" ? (
            <GooglePickupAddress
              loading={submittingPickupAddress}
              changeAddress={savePickupAddress}
              closeDialog={() => setPickupAddressDialogOpen(false)}
            />
          ) : (
            <div className="select_existing_address_wrapper">
              <div className="pickup_addresses_wrapper">
                {companyAddresses.map((el, key) => (
                  <div
                    className="address"
                    key={`address_${key}`}
                    onClick={() => handlePickupAddress(el)}
                  >
                    <span>
                      <img
                        src={
                          !!addresses.find(item => item.id === el.id)
                            ? CheckboxCheckedIcon
                            : CheckboxUncheckedIcon
                        }
                        alt=""
                      />
                      {el.address}
                    </span>
                  </div>
                ))}
              </div>
              <div className="buttons_wrapper">
                <DefaultButton
                  variant="contained"
                  classes="auth"
                  onClick={() => setPickupAddressDialogOpen(false)}
                >
                  {t("t:common.ok")}
                </DefaultButton>
              </div>
            </div>
          )}
        </div>
      </DialogComponent>
    </div>
  );
};

export default Delivery;
