import React from "react";
import Select, { components } from "react-select";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ExpandMoreIcon from "../../../assets/image/down-arrow-purple.svg";

import "./SelectComponent.scss";

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <div
        className={
          props.selectProps.menuIsOpen ? "select_indicator indicator_active" : "select_indicator"
        }
      >
        <img src={ExpandMoreIcon} alt="expand" />
      </div>
    </components.DropdownIndicator>
  );

const SingleValue = props => {
  if (!props.selectProps.labelStartAdornment) {
    return <components.SingleValue {...props}>{props.data.label}</components.SingleValue>;
  }
  return (
    <components.SingleValue {...props}>
      <div className="items-center">
        <div className="select_component__label-start-adornment">
          {props.selectProps.labelStartAdornment}
        </div>
        <b>{props.data.label}</b>
      </div>
    </components.SingleValue>
  );
};

const Option = props => {
  const {
    data: { label, onAddNewOption }
  } = props;
  return (
    <components.Option {...props}>
      {onAddNewOption ? (
        <div className="select_component__add-new-option" onClick={onAddNewOption}>
          {label}
        </div>
      ) : (
        <div>{label}</div>
      )}
    </components.Option>
  );
};

const filterOption = (option, inputValue) =>
  option.data?.name
    ? (
        option.data.name
          .toString()
          .toLowerCase()
          .trim()
          .match(inputValue.toLowerCase().trim()) || []
      ).length > 0
    : true;

const SelectComponent = ({
  value,
  onKeyDown,
  onInputChange,
  options,
  loading,
  loadingMessage = "...",
  change,
  placeholder,
  isClearable,
  isSearchable = true,
  disabled = false,
  labelStartAdornment = null
}) => (
  <Select
    className="select_component"
    classNamePrefix="select"
    isDisabled={disabled}
    isLoading={loading}
    isClearable={!isClearable}
    isSearchable={isSearchable}
    name="color"
    value={value}
    options={options}
    onChange={change}
    loadingMessage={() => loadingMessage}
    placeholder={placeholder}
    onKeyDown={onKeyDown}
    onInputChange={onInputChange}
    components={{ DropdownIndicator, Option, SingleValue }}
    isOptionDisabled={option => !!option.onAddNewOption}
    labelStartAdornment={labelStartAdornment}
    filterOption={filterOption}
  />
);

export default SelectComponent;
