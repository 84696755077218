import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import { Image as CloudinaryImage } from "cloudinary-react";

import Pagination from "../../HelperComponents/Pagination/Pagination";

import DeleteIcon from "../../../assets/image/bin.svg";
import menu from "../../../assets/image/menu-5.svg";
import BlockIcon from "../../../assets/image/block_icon.svg";
import UnblockIcon from "../../../assets/image/unblock_icon.svg";
import bg_icon from "../../../assets/image/user_dummy.svg";
import MakeOwnerIcon from "../../../assets/image/make_owner.svg";
import MakeUserIcon from "../../../assets/image/make_user.svg";

const ApprovedUsers = ({
  usersList,
  openedControls,
  handleControls,
  toggleDialog,
  activePage,
  doRequest,
  setPage,
  t
}) => (
  <Fragment>
    <div className="users_content">
      {usersList.results.map(
        ({
          id,
          full_name,
          avatar,
          email,
          is_blocked,
          campaigns_count_for_company_owner,
          campaigns,
          role
        }) => (
          <div key={`user_item_${id}`}>
            <div className="content">
              <div className="img_block">
                {avatar ? (
                  <CloudinaryImage
                    cloudName="campy"
                    width="133"
                    gravity="face"
                    crop="thumb"
                    publicId={avatar.split("/").pop()}
                  />
                ) : (
                  <img src={bg_icon} alt="bg_icon" />
                )}
              </div>
              <div className="user_info">
                <div className="user-status">
                  {role === "owner" && <p className="owner">{t("t:users.owner")}</p>}
                  {is_blocked ? (
                    <p className="blocked">{t("t:users.blocked")}</p>
                  ) : (
                    <p className="active">{t("t:users.active")}</p>
                  )}
                </div>
                <div className="name">{full_name}</div>
                <div className="last_updated_info">{email}</div>
              </div>
            </div>
            <div className="more_info_btn">
              <div className="campaigns_wrapper">
                <p>{t("t:common.campaigns")}</p>
                <div
                  className="text_hover"
                  onClick={() => toggleDialog("campaigns_dialog", id, campaigns)}
                >
                  {campaigns_count_for_company_owner}
                </div>
              </div>
              <IconButton
                onClick={() => handleControls(id)}
                classes={{
                  root: "dialog_close_button"
                }}
              >
                <img src={menu} alt="menu" />
              </IconButton>
            </div>
            {openedControls === id && (
              <ClickAwayListener onClickAway={() => handleControls(id)}>
                <div className="menu">
                  {!is_blocked && (
                    <>
                      {role === "owner" ? (
                        <button onClick={() => toggleDialog("make_a_regular_user", id)}>
                          <img src={MakeUserIcon} alt="user" />
                          {t("t:users.make-a-regular-user")}
                        </button>
                      ) : (
                        <button onClick={() => toggleDialog("make_an_owner", id)}>
                          <img src={MakeOwnerIcon} alt="owner" />
                          {t("t:users.make-an-owner")}
                        </button>
                      )}
                    </>
                  )}
                  {is_blocked ? (
                    <button onClick={() => toggleDialog("unblock_user", id)}>
                      <img src={UnblockIcon} alt="delete" />
                      {t("t:users.unblock-user")}
                    </button>
                  ) : (
                    <button onClick={() => toggleDialog("block_user", id)}>
                      <img src={BlockIcon} alt="delete" />
                      {t("t:users.block-user")}
                    </button>
                  )}
                  <button onClick={() => toggleDialog("delete_user", id)}>
                    <img src={DeleteIcon} alt="delete" className="remove_icon" />
                    {t("t:users.remove-from-company")}
                  </button>
                </div>
              </ClickAwayListener>
            )}
          </div>
        )
      )}
    </div>
    <div className="users_pagination_wrapper">
      {usersList.count > 3 && (
        <Pagination
          active={activePage}
          pageCount={Math.ceil(usersList.count / 3)}
          onChange={e =>
            doRequest(e.selected + 1).then(() => setPage("activePage", e.selected + 1))
          }
          t={t}
        />
      )}
    </div>
  </Fragment>
);

export default ApprovedUsers;
