import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import RenderField from "../../../../HelperComponents/RenderField/RenderField";
import { urlPattern } from "../../../../../helpers/functions";

import { getProducts, patchDataSource } from "../../../../../actions/dataSourcesActions";

class ConfigureApiEndpoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKeyword: "",
      productID: ""
    };
  }

  enterAPIURL = data => {
    const {
      patchDataSource,
      dataSource: { id }
    } = this.props;
    patchDataSource(id, data);
  };

  testURL = () => {
    const {
      getProducts,
      dataSource: { id }
    } = this.props;
    const { searchKeyword, productID } = this.state;
    let data = ["page=1"];
    if (searchKeyword) {
      data.push(`search=${searchKeyword}`);
    }
    if (productID) {
      data.push(`product_id=${productID}`);
    }
    getProducts(id, data);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      valid,
      products: {
        has_errors,
        request_method,
        request_url,
        response_status_code,
        errors,
        request_headers,
        response_headers,
        text
      },
      products,
      dataSource: { api_base_url },
      loading,
      t
    } = this.props;
    const { searchKeyword, productID } = this.state;
    return (
      <Fragment>
        <div className="title">{t("t:data-sources.configure-endpoints")}</div>
        <div className="add_api_source_description">
          <p>{t("t:data-sources.enter-the-path")}</p>
        </div>
        <form onSubmit={handleSubmit(this.enterAPIURL)} className="generate_api_key_form">
          <div className="label">{t("t:data-sources.api-url")}</div>
          <Field name="api_base_url" type="text" component={RenderField} label="" />
          <DefaultButton
            variant="contained"
            classes="auth"
            disabled={submitting || pristine || !valid}
            formAction
          >
            {t("t:common.save")}
          </DefaultButton>
        </form>
        {api_base_url && (
          <div className="test_your_api_wrapper">
            <p>{t("t:data-sources.test-your-endpoints")}</p>
            <div className="test_url">
              <div className="entered_url">
                <p>{`${api_base_url}products/?search=`}</p>
                <input
                  value={searchKeyword}
                  onChange={e => this.setState({ searchKeyword: e.target.value })}
                  type="text"
                  placeholder={t("t:data-sources.keyword")}
                />
                <p>&product_id=</p>
                <input
                  value={productID}
                  onChange={e => this.setState({ productID: e.target.value })}
                  type="text"
                  placeholder={t("t:data-sources.product-id")}
                />
              </div>
              <DefaultButton
                variant="contained"
                classes="test_btn"
                onClick={this.testURL}
                loading={loading}
              >
                {t("t:data-sources.test")}
              </DefaultButton>
            </div>
            {products.hasOwnProperty("has_errors") && (
              <div className={clsx("response_wrapper", !has_errors ? "success" : "fail")}>
                <p>
                  API request <strong>{has_errors ? "failed" : "success"}</strong>:
                </p>
                <br />
                <p>Request method: {request_method}</p>
                <p>Request URL: {request_url}</p>
                <p>Request headers:</p>
                {Object.entries(request_headers).map(el => (
                  <p key={`request_${el[0]}`}>
                    <strong>{el[0]}</strong>: {el[1]}
                  </p>
                ))}
                <br />
                <p>Response status: {response_status_code}</p>
                {has_errors && <p>Response error: {errors[0]}</p>}
                <p>Response headers:</p>
                {Object.entries(response_headers).map(el => (
                  <p key={`response_${el[0]}`}>
                    <strong>{el[0]}</strong>: {el[1]}
                  </p>
                ))}
                {!has_errors && (
                  <Fragment>
                    <p>Response body:</p>
                    <p>{text}</p>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.api_base_url) {
    errors.api_base_url = t("t:common.field-is-required");
  } else if (!urlPattern.test(values.api_base_url)) {
    errors.api_base_url = t("t:data-sources.enter-correct-api-url");
  }
  return errors;
};

ConfigureApiEndpoints = reduxForm({
  form: "APIURLForm",
  validate
})(ConfigureApiEndpoints);

const mapStateToProps = ({ dataSources, dashboard }) => {
  return {
    products: dataSources.products,
    dataSource: dataSources.dataSource,
    loading: dashboard.loadingComponent
  };
};

const mapDispatchToProps = {
  getProducts,
  patchDataSource
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ConfigureApiEndpoints));
