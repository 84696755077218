import React from "react";
import { useTranslation } from "react-i18next";

import InfoIcon from "../../../../assets/image/c-info.svg";

const AudiencePanel = () => {
  const { t } = useTranslation();
  return (
    <div className="audience_panel">
      <div className="info_wrapper">
        <div className="title_wrapper">
          <p>{t("t:support-info.audience-how-to-title")}</p>
          <img src={InfoIcon} alt="info" />
        </div>
        <div className="separator_block" />
        <div className="info_text big_margin_bottom">
          {t("t:support-info.audience-how-to-description")}
        </div>
        <h4>{t("t:support-info.audience-age-and-gender-title")}</h4>
        <div className="info_text small_margin_bottom">
          {t("t:support-info.audience-age-and-gender-description")}
        </div>
        <h4>{t("t:support-info.audience-region-title")}</h4>
        <div className="info_text small_margin_bottom">
          {t("t:support-info.audience-region-description")}
        </div>
        <h4>{t("t:support-info.audience-interests-title")}</h4>
        <div className="info_text small_margin_bottom">
          {t("t:support-info.audience-interests-description")}
        </div>
        <h4>{t("t:support-info.audience-hashtags-title")}</h4>
        <div className="info_text small_margin_bottom">
          {t("t:support-info.audience-hashtags-description")}
        </div>
      </div>
    </div>
  );
};

export default AudiencePanel;
