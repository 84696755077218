import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { postChangePassword } from "../../../actions/authActions";

import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  TERMS_AND_CONDITIONS_ESTONIAN_URL
} from "../../../config";

import ErrorIcon from "../../../assets/image/c-warning-e.svg";

class PasswordRecovery extends Component {
  state = {
    loading: false
  };

  submitForm = data => {
    const {
      postChangePassword,
      location: { search },
      history
    } = this.props;
    this.setState({ loading: true });
    const params = new URLSearchParams(search.substring(1));
    const obj = {
      code: params.get("code"),
      password: data.new_password
    };
    return postChangePassword(obj).then(res => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        localStorage.token = res.payload.data.token;
        history.push("/main");
      } else {
        this.setState({ loading: false });
        if (res.error.response.data.password) {
          throw new SubmissionError({
            new_password: res.error.response.data.password[0].message
          });
        }
      }
    });
  };

  render() {
    const { handleSubmit, submitting, pristine, valid, authError, t, i18n } = this.props;
    const { loading } = this.state;

    return (
      <div className="sign_in_wrapper">
        <div>
          <div className="auth_redirect">
            {t("t:auth.dont-have-account")}
            <Link to="/auth/sign-up" className="text_hover">
              {t("t:auth.sign-up-now")}
            </Link>
          </div>
          <div className="auth_block_head">{t("t:auth.change-your-password")}</div>
          <div className="auth_description">{t("t:auth.choose-new-password")}</div>
          <form onSubmit={handleSubmit(this.submitForm)}>
            <Field
              name="new_password"
              type="password"
              component={RenderField}
              label={t("t:auth.new-password")}
            />
            <Field
              name="confirm_password"
              type="password"
              component={RenderField}
              label={t("t:auth.reenter-new-password")}
            />
            <div className="auth_btn_wrapper forgot_pass_auth_buttons">
              <DefaultButton
                variant="contained"
                classes="auth"
                disabled={submitting || pristine || !valid}
                loading={loading}
                formAction
              >
                {t("t:auth.reset-password")}
              </DefaultButton>
              {authError ? (
                <TooltipMessage text={authError} delay={200} error position="right" classes="">
                  <img src={ErrorIcon} alt="error" />
                </TooltipMessage>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
        <div className="terms_policy_wrapper">
          <a
            href={
              i18n.language === "et" ? TERMS_AND_CONDITIONS_ESTONIAN_URL : TERMS_AND_CONDITIONS_URL
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("t:auth.terms-and-conditions")}
          </a>
          <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
            {t("t:auth.privacy-policy")}
          </a>
        </div>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.new_password) {
    errors.new_password = t("t:common.field-is-required");
  } else if (values.new_password.length < 8) {
    errors.new_password = t("t:common.min-8-characters");
  }
  if (!values.confirm_password) {
    errors.confirm_password = t("t:common.field-is-required");
  } else if (values.confirm_password.length < 8) {
    errors.confirm_password = t("t:common.min-8-characters");
  } else if (values.confirm_password !== values.new_password) {
    errors.confirm_password = t("t:auth.passwords-not-match");
  }
  return errors;
};

PasswordRecovery = reduxForm({
  form: "ThirdStep",
  validate
})(PasswordRecovery);

const mapStateToProps = state => {
  return {
    authError: state.auth.error_auth
  };
};

const mapDispatchToProps = {
  postChangePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PasswordRecovery));
