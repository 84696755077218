import React from "react";
import { useTranslation } from "react-i18next";

import TooltipMessage from "../TooltipMessage/TooltipMessage";
import Shape from "../../../assets/image/My_Campaign/Shape.svg";

import "./styles.scss";

const HelpTooltip = ({ helperTextsTransKey }) => {
  const { t } = useTranslation();
  return (
    <TooltipMessage
      text={t(`t:helper-texts.${helperTextsTransKey}`)}
      delay={200}
      position="top"
      classes="step_tooltip"
    >
      <img src={Shape} alt="Shape" className="help-tooltip" />
    </TooltipMessage>
  );
};

export default HelpTooltip;
