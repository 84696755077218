import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import clsx from "clsx";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import { getCost, postPublishCampaign, postViewer } from "../../../actions/campaignActions";
import { postDeposit, postRequestMoney } from "../../../actions/balanceActions";
import CostPanel from "./CostPanel";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import Tabs from "../../HelperComponents/Tabs";
import { formatPrice } from "../../../helpers/functions";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import SwitchComponent from "../../HelperComponents/SwitchComponent/SwitchComponent";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import Sharing from "./Sharing";

import InfoIcon from "../../../assets/image/info-cost.svg";
import CheckIcon from "../../../assets/image/completed.svg";
import Shape from "../../../assets/image/My_Campaign/Shape.svg";

import "./styles.scss";

const Cost = ({
  campaignId,
  clearEditingTimer,
  handleViewingInterval,
  toggleStopCampaignDialog
}) => {
  const {
    cost,
    campaignInfo,
    campaignViewers: { is_editor }
  } = useSelector(({ campaign }) => campaign);
  const {
    current_company: { is_owner, balance }
  } = useSelector(({ auth }) => auth.user_info);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [refetchCostInfo, setRefetchCostInfo] = useState(false);
  const [tab, setTab] = useState(
    campaignInfo.status === "inactive"
      ? "max"
      : campaignInfo.status === "active"
      ? "current"
      : "actual"
  );
  const [withVat, setWithVat] = useState(false);
  const [isApproveDialogOpened, setIsApprovedDialogOpened] = useState(false);
  const [isPublishDialogOpened, setIsPublishDialogOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleApprovedDialog = () => setIsApprovedDialogOpened(val => !val);
  const togglePublishDialog = () => setIsPublishDialogOpened(val => !val);
  const toggleWithVat = () => setWithVat(val => !val);

  const { isPublished, isInactive, isFinalizedOrStopped, statusTranslationCode } = useMemo(
    () => ({
      isPublished: campaignInfo.is_published,
      isInactive: campaignInfo.status === "inactive",
      isFinalizedOrStopped:
        campaignInfo.status === "finalized" || campaignInfo.status === "stopped_immediately",
      statusTranslationCode:
        campaignInfo.status === "finalized" || campaignInfo.status === "stopped_immediately"
          ? "stopped"
          : campaignInfo.status
    }),
    [campaignInfo.is_published, campaignInfo.status]
  );

  const tabs = useMemo(() => {
    const tabs = [{ label: t("t:cost.maximum"), value: "max" }];
    if (isPublished) tabs.unshift({ label: t("t:cost.current"), value: "current" });
    if (isFinalizedOrStopped) tabs.unshift({ label: t("t:cost.actual"), value: "actual" });
    return tabs;
  }, [isPublished, isFinalizedOrStopped, t]);

  useEffect(() => {
    dispatch(getCost(campaignId));
  }, [campaignInfo.status, dispatch, campaignId]);

  useEffect(() => {
    if (refetchCostInfo) {
      setRefetchCostInfo(false);
      dispatch(getCost(campaignId));
    }
  }, [refetchCostInfo, dispatch, campaignId]);

  const onRequestConfirmationClick = async () => {
    setLoading(true);
    await dispatch(
      postRequestMoney(campaignId, {
        amount: cost?.missing_balance > 0 ? cost.missing_balance : 0.1
      })
    );
    setRefetchCostInfo(true);
    setLoading(false);
  };

  const onApproveCost = async () => {
    setLoading(true);
    await dispatch(
      postDeposit(campaignId, { amount: cost?.missing_balance > 0 ? cost.missing_balance : 0.1 })
    );
    toggleApprovedDialog();
    setRefetchCostInfo(true);
    setLoading(false);
  };

  const onPublish = async () => {
    setLoading(true);
    const res = await dispatch(postPublishCampaign(campaignId));
    if (res.payload) {
      setRefetchCostInfo(true);
      clearEditingTimer();
      handleViewingInterval();
      dispatch(postViewer(campaignId));
    }
    togglePublishDialog();
    setLoading(false);
  };

  if (!cost)
    return (
      <Backdrop open style={{ color: "white", zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <>
      <div className="cost__wrapper">
        <CostPanel />
        <div className="cost__container">
          <div className="cost__title-wrapper">
            <div className="cost__title">{t("t:cost.campaign-cost")}</div>
            {isPublished && (
              <DefaultButton
                variant="outlined"
                classes="stop_campaign"
                onClick={toggleStopCampaignDialog}
              >
                {t("t:campaigns.stop-campaign")}
              </DefaultButton>
            )}
          </div>

          <div className="cost__card">
            <div className="cost__info-box">
              <img src={InfoIcon} alt="i" className="cost__info-box--icon" />
              <div className="cost__info-box--text">
                <Trans
                  i18nKey={`t:cost.cost-${statusTranslationCode}-info-text`}
                  components={{ bold: <strong /> }}
                />
              </div>
            </div>

            {tabs.length > 1 && <Tabs currentTab={tab} changeTab={setTab} tabs={tabs} />}

            <div className="cost__totals-box">
              <div className="cost__totals-box--wrapper">
                <div className="cost__totals-box--wrapper">
                  <div className="cost__totals-box--item">
                    <div className="cost__totals-box--label">
                      {t(`t:cost.${tab}-campaign-cost`)}
                      <TooltipMessage
                        text={
                          <Trans
                            i18nKey={t(`t:helper-texts.cost-${tab}-campaign-cost`)}
                            components={{ break: <br /> }}
                          />
                        }
                        delay={200}
                        position="top"
                        classes="step_tooltip"
                      >
                        <img src={Shape} alt="Shape" />
                      </TooltipMessage>
                    </div>
                    <div className="cost__totals-box--value">
                      {formatPrice(
                        cost[tab === "max" ? "max_campaign_cost" : "actual_campaign_cost"]
                      )}
                    </div>
                  </div>
                  <div className="cost__totals-box--item">
                    <div className="cost__totals-box--label">
                      {t(`t:cost.${tab}-campaign-turnover`)}
                      <TooltipMessage
                        text={
                          <Trans
                            i18nKey={t(`t:helper-texts.cost-${tab}-campaign-turnover`)}
                            components={{ break: <br /> }}
                          />
                        }
                        delay={200}
                        position="top"
                        classes="step_tooltip"
                      >
                        <img src={Shape} alt="Shape" />
                      </TooltipMessage>
                    </div>
                    <div className="cost__totals-box--value">
                      {formatPrice(
                        cost[tab === "max" ? "max_possible_turnover" : "actual_turnover"]
                      )}
                    </div>
                  </div>
                  {tab === "max" ? (
                    <div className="cost__totals-box--item">
                      <div className="cost__totals-box--label">
                        {t(`t:cost.${tab}-participants`)}
                      </div>
                      <div className="cost__totals-box--value">{cost[`${tab}_participants`]}</div>
                    </div>
                  ) : (
                    <>
                      {tab === "current" && (
                        <div className="cost__totals-box--item">
                          <div className="cost__totals-box--label">
                            {t("t:cost.joined-participants")}
                          </div>
                          <div className="cost__totals-box--value">
                            {cost.joined_participants}
                            <span>{` ${t("t:common.one-OF-two")} ${cost.max_participants}`}</span>
                          </div>
                        </div>
                      )}
                      <div className="cost__totals-box--item">
                        <div className="cost__totals-box--label">
                          {t("t:cost.finalized-participants")}
                        </div>
                        <div className="cost__totals-box--value">
                          {cost.finalized_participants}
                          <span>{` ${t("t:common.one-OF-two")} ${cost.joined_participants}`}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {isInactive && is_owner && !cost.is_approved && (
                  <DefaultButton
                    variant="contained"
                    classes="purple_btn"
                    onClick={toggleApprovedDialog}
                    disabled={balance < cost.max_campaign_cost}
                  >
                    {t("t:cost.approve-cost")}
                  </DefaultButton>
                )}
              </div>
              {isInactive && is_owner && balance < cost.max_campaign_cost && (
                <div className="cost__totals-box--warning">
                  {t("t:cost.not-enough-balance-to-approve")}
                </div>
              )}
            </div>

            <div className="cost__lines">
              <div className="cost__line headline">
                <div className="cost__line--bold">{t(`t:cost.${tab}-campaign-cost-breakout`)}</div>
                <SwitchComponent
                  labelLeftSide
                  label={t("t:cost.including-vat")}
                  defaultChecked={withVat}
                  onChange={toggleWithVat}
                  height={20}
                  width={40}
                  handleDiameter={12}
                />
              </div>
              <div className="cost__line">
                <div className="cost__line--medium">{t(`t:cost.${tab}-campy-fee`)}</div>
                <div className="cost__line--bold">
                  {formatPrice(
                    cost[
                      tab === "max"
                        ? `max_campy_fee_${withVat ? "with" : "no"}_vat`
                        : `actual_campy_fee_${withVat ? "with" : "no"}_vat`
                    ]
                  )}
                </div>
              </div>
              <div className="cost__line">
                <div className="cost__line--medium">{t(`t:cost.${tab}-cost-of-link-sharing`)}</div>
                <div className="cost__line--bold">
                  {formatPrice(
                    cost[
                      tab === "max"
                        ? `max_cost_of_link_sharing_${withVat ? "with" : "no"}_vat`
                        : `actual_link_sharing_cost_${withVat ? "with" : "no"}_vat`
                    ]
                  )}
                </div>
              </div>
              <div className="cost__line">
                <div className="cost__line--medium">
                  {t(`t:cost.${tab}-cost-of-monetary-reward-step`)}
                </div>
                <div className="cost__line--bold">
                  {formatPrice(
                    cost[
                      tab === "max"
                        ? `max_cost_of_monetary_reward_step_${withVat ? "with" : "no"}_vat`
                        : `actual_monetary_cost_${withVat ? "with" : "no"}_vat`
                    ]
                  )}
                </div>
              </div>
            </div>

            {isInactive && !cost.is_approved && (
              <div className="cost__lines">
                <div className="cost__approve-block">
                  <div>{t("t:cost.campaign-cost-has-to-be-approved")}</div>
                  {!cost.is_approval_requested && is_owner ? null : (
                    <div
                      className={clsx([cost.is_approval_requested ? "inactive" : "active"])}
                      onClick={() => !cost.is_approval_requested && onRequestConfirmationClick()}
                    >
                      <Trans
                        i18nKey={
                          !cost.is_approval_requested
                            ? "t:cost.you-can-request-confirmation-of-the-cost"
                            : cost.is_approval_requested_by_you
                            ? "t:cost.you-have-requested-confirmation"
                            : "t:cost.someone-has-requested-confirmation"
                        }
                        components={{ bold: <strong /> }}
                        values={{ name: cost.approval_requested_by?.full_name || "Anonymous" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            {isInactive && cost.is_approved && (
              <div className="cost__lines cost__publish-block">
                <DefaultButton
                  variant="contained"
                  classes="purple_btn"
                  onClick={togglePublishDialog}
                  disabled={!is_editor}
                >
                  {t("t:campaigns.publish-campaign")}
                </DefaultButton>
                <div className="cost__publish-block--approved-by">
                  <img src={CheckIcon} alt="V" />
                  <p>
                    <Trans
                      i18nKey="t:cost.campaign-cost-was-approved-by"
                      components={{ bold: <strong /> }}
                      values={{
                        when: moment(cost.approved_at)
                          .subtract(1, "seconds")
                          .fromNow(),
                        whom: cost.approved_by?.full_name || "Anonymous"
                      }}
                    />
                  </p>
                </div>
              </div>
            )}
          </div>

          {!!cost.max_cost_of_link_sharing_no_vat && (
            <div className="cost__card">
              <Sharing campaignId={campaignId} />
            </div>
          )}
        </div>
      </div>

      <DialogComponent open={isApproveDialogOpened} onClose={toggleApprovedDialog} closeIcon>
        <div className="default_dialog_wrapper">
          <div className="dialog_title">{t("t:cost.approve-cost")}</div>
          <div className="dialog_description">{t("t:cost.approve-cost-confirmation")}</div>
          <div className="cost__max-campaign-cost-in-dialog">
            <div className="cost__max-campaign-cost-in-dialog--label">
              {t("t:cost.max-campaign-cost")}
            </div>
            <div className="cost__max-campaign-cost-in-dialog--value">
              {formatPrice(cost.max_campaign_cost)}
            </div>
          </div>
          <div className="buttons_wrapper">
            <DefaultButton
              variant="contained"
              classes="purple_btn"
              loading={loading}
              onClick={onApproveCost}
            >
              {t("t:common.confirm")}
            </DefaultButton>
            <DefaultButton variant="contained" classes="cancel_btn" onClick={toggleApprovedDialog}>
              {t("t:common.cancel")}
            </DefaultButton>
          </div>
        </div>
      </DialogComponent>

      <DialogComponent open={isPublishDialogOpened} onClose={togglePublishDialog} closeIcon>
        <div className="default_dialog_wrapper">
          <div className="dialog_title">{t("t:campaigns.publish-campaign")}</div>
          <div className="dialog_description">{t("t:cost.publish-campaign-approve")}</div>
          <div className="buttons_wrapper">
            <DefaultButton
              variant="contained"
              classes="purple_btn"
              loading={loading}
              onClick={onPublish}
            >
              {t("t:common.publish")}
            </DefaultButton>
            <DefaultButton variant="contained" classes="cancel_btn" onClick={togglePublishDialog}>
              {t("t:common.cancel")}
            </DefaultButton>
          </div>
        </div>
      </DialogComponent>

      <Backdrop open={loading} style={{ color: "white", zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Cost;
