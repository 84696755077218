import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { multiClientMiddleware } from "redux-axios-middleware";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import api from "./actions/api";
import routes from "./routes/routes";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import common_en from "./translations/en/common.json";
import common_ee from "./translations/ee/common.json";
import common_ru from "./translations/ru/common.json";
import { SENTRY_DSN_URL } from "./config";

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      (action, config) => {
        if (localStorage.token || localStorage.token_res) {
          let token = localStorage.token ? localStorage.token : localStorage.token_res;
          config.headers["Authorization"] = "Token " + token;
        }
        if (localStorage.i18nextLng) {
          config.headers["Accept-Language"] = localStorage.i18nextLng;
        }
        return config;
      }
    ],
    response: [
      function({ getState, dispatch, getSourceAction }, req) {
        if (req.status === 401) {
          localStorage.clear();
        }
        return req;
      }
    ]
  }
};

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  detection: {
    order: ["localStorage", "querystring", "navigator"],
    lookupQuerystring: "lng"
  },
  supportedLngs: ["ee", "et", "en", "ru"],
  fallbackLng: "en",
  resources: {
    en: {
      t: common_en
    },
    ru: {
      t: common_ru
    },
    ee: {
      t: common_ee
    },
    et: {
      t: common_ee
    },
    es: {
      t: common_ee
    }
  },
  transSupportBasicHtmlNodes: true,
  transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
  saveMissing: true
});

const history = createBrowserHistory();
const appRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const createStoreWithMiddleware = applyMiddleware(
  multiClientMiddleware(api, axiosMiddlewareOptions),
  appRouterMiddleware,
  sagaMiddleware
)(createStore);
const store = createStoreWithMiddleware(
  rootReducer(history),
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
);

sagaMiddleware.run(rootSaga);

Sentry.init({
  dsn: SENTRY_DSN_URL,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ConnectedRouter history={history} children={routes} />
    </I18nextProvider>
  </Provider>,
  document.getElementById("wrapper")
);
