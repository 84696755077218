import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import PreviewIcon from "../../../../assets/image/btn-play.svg";

import "./styles.scss";

const SeeTutorialLink = ({ link, classes }) => {
  const { t } = useTranslation();

  const onClick = () => window.open(link, "_blank");

  return (
    <div className={clsx(["tutorial-link", classes])} role="button" onClick={onClick}>
      <img src={PreviewIcon} alt="play" className="tutorial-link__icon" />
      {t("t:support.see-tutorial-video")}
    </div>
  );
};

export default SeeTutorialLink;
