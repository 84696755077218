import React, { Component } from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import { withTranslation } from "react-i18next";

import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { getOption, numberWithSpaces } from "../../helpers/functions";
import Loader from "../HelperComponents/Loader";
import Pagination from "../HelperComponents/Pagination/Pagination";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import CampaignContextMenu from "./Blocks/CampaignContextMenu";
import EditableText from "../HelperComponents/EditableText";

import { getCampaigns, getManagers } from "../../actions/companiesActions";
import {
  postPublishCampaign,
  postUnpublishCampaign,
  deleteCampaign,
  patchCampaignName,
  postStopCampaign
} from "../../actions/campaignActions";
import {
  postArchiveCampaign,
  postRestoreCampaign,
  postLeaveCampaign,
  postDuplicateCampaign
} from "../../actions/companiesActions";
import { getProfile } from "../../actions/authActions";
import { handleBalanceDialog } from "../../actions/balanceActions";

import add from "../../assets/image/i-add.svg";
import zoom from "../../assets/image/zoom.svg";
import bg_icon from "../../assets/image/auth_bg_with_assets.png";
import menu from "../../assets/image/menu-5.svg";

import "./styles.scss";
import { TERMS_AND_CONDITIONS_ESTONIAN_URL, TERMS_AND_CONDITIONS_URL } from "../../config";

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reloading: false,
      activePage: 1,
      searchValue: "",
      openedControls: null,
      restoreDialog: false,
      archiveDialog: false,
      archiveFailureDialog: false,
      deleteDialogOpened: false,
      cannotDeleteDialogOpened: false,
      leaveDialog: false,
      stopDialog: false,
      duplicateDialog: false,
      campaignId: undefined,
      campaignName: "",
      status: { label: getOption(props.t("t:campaigns.all-campaigns")), value: "" },
      statusList: [
        { label: getOption(props.t("t:campaigns.all-campaigns")), value: "" },
        { label: getOption(props.t("t:campaigns.published-campaigns")), value: "active" },
        {
          label: getOption(props.t("t:campaigns.not-published-campaigns")),
          value: "inactive"
        },
        {
          label: getOption(props.t("t:campaigns.finalized-campaigns")),
          value: "finalized"
        },
        {
          label: getOption(props.t("t:campaigns.stopped-campaigns")),
          value: "stopped_immediately"
        },
        { label: getOption(props.t("t:campaigns.archived-campaigns")), value: "archived" }
      ]
    };
  }

  componentDidMount() {
    const { getManagers } = this.props;
    getManagers().then(res => {
      let authorList = [{ label: getOption("All"), value: "" }];
      res.payload.data.forEach(({ first_name, id, last_name }) => {
        first_name &&
          authorList.push({ label: getOption(`${first_name} ${last_name}`), value: id });
      });
      this.setState({ authorList });
      this.fetchCampaigns(1).then(() => {
        this.setState({ loading: false, reloading: false });
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.status.value !== this.state.status.value ||
      prevState.searchValue !== this.state.searchValue
    ) {
      if (prevState.status.value !== this.state.status.value) {
        this.setState({ reloading: true });
      }
      this.fetchCampaigns(1).then(() => {
        this.setState({ reloading: false, activePage: 1 });
      });
    }
    if (this.props.user_info.current_company !== prevProps.user_info.current_company) {
      this.setState({ reloading: true });
      this.fetchCampaigns(1).then(() => {
        this.setState({ reloading: false, activePage: 1 });
      });
    }
    if (
      this.props.openBalanceDialog !== prevProps.openBalanceDialog &&
      !this.props.openBalanceDialog
    ) {
      this.fetchCampaigns(this.state.activePage);
    }
  }

  fetchCampaigns = async page => {
    const { getCampaigns } = this.props;
    const { author, status, searchValue } = this.state;
    let data = [];
    data.push(`page=${page}`);
    data.push(`page_size=4`);
    author && author.value && data.push(`author_id=${author.value}`);
    status.value && data.push(`status=${status.value}`);
    searchValue && data.push(`search=${searchValue}`);
    await getCampaigns(data);
  };

  handleChange = name => event => {
    this.setState({ [name]: event });
  };

  handleControls = id => {
    const { openedControls } = this.state;
    if (openedControls === id) {
      this.setState({ openedControls: null });
    } else {
      this.setState({ openedControls: id });
    }
  };

  toggleDialog = (type, campaignId = undefined) => {
    switch (type) {
      case "restore_campaign":
        this.setState(({ restoreDialog }) => ({
          restoreDialog: !restoreDialog,
          campaignId
        }));
        break;
      case "duplicate_campaign":
        this.setState(({ duplicateDialog }) => ({
          duplicateDialog: !duplicateDialog,
          campaignId
        }));
        break;
      case "archive_campaign":
        this.setState(({ archiveDialog }) => ({
          archiveDialog: !archiveDialog,
          campaignId
        }));
        break;
      case "archive_failure":
        this.setState(({ archiveFailureDialog }) => ({
          archiveFailureDialog: !archiveFailureDialog
        }));
        break;
      case "delete_campaign":
        this.setState(({ deleteDialogOpened }) => ({
          deleteDialogOpened: !deleteDialogOpened,
          campaignId
        }));
        break;
      case "cannot_delete_campaign":
        this.setState(({ cannotDeleteDialogOpened }) => ({
          cannotDeleteDialogOpened: !cannotDeleteDialogOpened,
          campaignId
        }));
        break;
      case "leave_campaign":
        this.setState(({ leaveDialog }) => ({ leaveDialog: !leaveDialog, campaignId }));
        break;
      case "stop_campaign":
        this.setState(({ stopDialog }) => ({ stopDialog: !stopDialog, campaignId }));
        break;
      default:
        break;
    }
  };

  renderCampaignsLine = () => {
    const { campaigns, t } = this.props;
    const { reloading, status } = this.state;
    if (reloading) {
      return (
        <div className="title">
          <Loader />
        </div>
      );
    } else {
      if (status.value === "active") {
        return (
          <div className="title">
            {t("t:campaigns.number-of-published-campaigns", {
              number: campaigns.count
            })}
          </div>
        );
      } else if (status.value === "inactive") {
        return (
          <div className="title">
            {t("t:campaigns.number-of-unpublished-campaigns", {
              number: campaigns.count
            })}
          </div>
        );
      } else if (status.value === "archived") {
        return (
          <div className="title">
            {t("t:campaigns.number-of-archived-campaigns", { number: campaigns.count })}
          </div>
        );
      } else {
        return (
          <div className="title">
            {t("t:campaigns.number-of-campaigns", { number: campaigns.count })}
          </div>
        );
      }
    }
  };

  publishCampaign = async id => {
    const { postPublishCampaign } = this.props;
    await postPublishCampaign(id);
    this.handleControls(id);
  };

  removeCampaign = async () => {
    const { deleteCampaign } = this.props;
    const { openedControls } = this.state;
    await deleteCampaign(openedControls);
    this.fetchCampaigns(1).then(() => {
      this.setState({
        deleteDialogOpened: false,
        openedControls: null,
        activePage: 1
      });
    });
  };

  leaveCampaign = async () => {
    const { postLeaveCampaign } = this.props;
    const { campaignId } = this.state;
    await postLeaveCampaign(campaignId);
    this.toggleDialog("leave_campaign");
    this.fetchCampaigns(1).then(() => {
      this.setState({ activePage: 1, openedControls: null });
    });
  };

  handleStopCampaign = async () => {
    const { postStopCampaign } = this.props;
    const { campaignId, activePage } = this.state;
    await postStopCampaign(campaignId);
    this.toggleDialog("stop_campaign");
    this.setState({ openedControls: null });
    this.fetchCampaigns(activePage);
  };

  archiveCampaign = async () => {
    const { postArchiveCampaign } = this.props;
    const { campaignId, activePage } = this.state;
    const res = await postArchiveCampaign(campaignId);
    this.toggleDialog("archive_campaign");
    if (res.payload) {
      this.fetchCampaigns(activePage);
    } else {
      this.toggleDialog("archive_failure");
    }
  };

  restoreCampaign = async () => {
    const { postRestoreCampaign } = this.props;
    const { campaignId, activePage } = this.state;
    await postRestoreCampaign(campaignId);
    this.toggleDialog("restore_campaign");
    this.fetchCampaigns(activePage);
  };

  duplicateCampaign = async () => {
    const { postDuplicateCampaign } = this.props;
    const { campaignId, activePage, campaignName } = this.state;
    const res = await postDuplicateCampaign(campaignId, { name: campaignName });
    if (res && res.payload) {
      this.toggleDialog("duplicate_campaign");
      this.setState({ campaignName: "" });
      this.fetchCampaigns(activePage);
    }
  };

  changeCampaignName = campaignId => name =>
    new Promise(async resolve => {
      const res = await this.props.patchCampaignName(campaignId, {
        name
      });
      if (res.payload) {
        resolve("success");
      } else {
        resolve(
          res.error.response.data.name[0].code === "max_length"
            ? "campaign-name-length-error"
            : "campaign-name-exists-error"
        );
      }
    });

  render() {
    const {
      campaigns,
      history,
      buttonLoading,
      handleBalanceDialog,
      user_info: { current_company },
      t,
      i18n
    } = this.props;
    const {
      loading,
      status,
      statusList,
      searchValue,
      openedControls,
      activePage,
      deleteDialogOpened,
      cannotDeleteDialogOpened,
      leaveDialog,
      stopDialog,
      archiveFailureDialog,
      archiveDialog,
      restoreDialog,
      duplicateDialog,
      campaignName
    } = this.state;
    if (loading)
      return (
        <div className="loader_wrapper">
          <Loader />
        </div>
      );
    return (
      <div className="page_wrapper campaigns_wrapper">
        <div className="campaigns_title_block">
          <div className="block">
            {this.renderCampaignsLine()}
            <div className="title_panel">
              <div className="search">
                <input
                  value={searchValue}
                  onChange={e => this.setState({ searchValue: e.target.value })}
                  type="text"
                  placeholder={t("t:campaigns.search-campaign")}
                />
                <button>
                  <img src={zoom} alt="zoom" />
                </button>
              </div>
              <FormControl className="select_wrapper">
                <SelectComponent
                  value={status}
                  options={statusList}
                  change={this.handleChange("status")}
                  isClearable="false"
                  isSearchable={false}
                  placeholder={t("t:common.status")}
                  labelStartAdornment={t("t:common.status")}
                />
              </FormControl>
            </div>
          </div>
          <div className="btn_wrapper">
            <DefaultButton
              variant="contained"
              classes="auth"
              type="link"
              to="/main/campaigns/new-campaign"
            >
              {t("t:campaigns.new-campaign")} <img src={add} alt="add" />
            </DefaultButton>
          </div>
        </div>
        <div className="campaigns_content">
          {campaigns.results.map(
            ({
              id,
              name,
              updated_at,
              updated_by,
              users_count,
              is_owner,
              is_published,
              is_archived,
              completion_percent,
              balance,
              status: campaignStatus,
              has_uncompleted_deliveries
            }) => (
              <div key={`campaign_item_${id}`}>
                <div className="content">
                  <div className="img_block">
                    <img src={bg_icon} alt="bg_icon" />
                  </div>
                  <div className="user_info">
                    {is_owner && <p className="is_owner">{t("t:campaigns.owner")}</p>}
                    <p className={clsx("campaign-status", is_owner && "is_owner", campaignStatus)}>
                      {t(`t:campaigns.${campaignStatus}`)}
                    </p>
                    {is_archived && <p className="is_archived">{t("t:campaigns.archived")}</p>}
                    <EditableText
                      text={name}
                      linkTo={`/main/campaign/${id}?tab=build_campaign`}
                      onSave={this.changeCampaignName(id)}
                      canEdit
                      containerStyle="campaign-editable-name"
                      inputPlaceholder={t("t:campaigns.name-of-your-campaign")}
                    />
                    <div className="last_updated_info">
                      {updated_by ? (
                        <>
                          {t("t:campaigns.updated-time-ago-by-username", {
                            time_from_now: moment(updated_at).fromNow(true)
                          })}
                          <span>
                            {updated_by.first_name} {updated_by.last_name}
                          </span>
                        </>
                      ) : (
                        t("t:campaigns.updated-time-ago", {
                          time_from_now: moment(updated_at).fromNow(true)
                        })
                      )}
                    </div>
                  </div>
                  <div className="data_info">
                    <span>{t("t:campaigns.active-users")}</span>
                    <p>{numberWithSpaces(users_count)}</p>
                  </div>
                  <div className="data_info">
                    <span>{t("t:campaigns.completions")}</span>
                    <p>{(completion_percent || 0).toFixed(0)}%</p>
                  </div>
                </div>
                <div className="more_info_btn">
                  {!is_owner && (
                    <DefaultButton
                      variant="outlined"
                      classes="leave_btn"
                      onClick={() => this.toggleDialog("leave_campaign", id)}
                    >
                      {t("t:common.leave")}
                    </DefaultButton>
                  )}
                  <IconButton
                    onClick={() => this.handleControls(id)}
                    classes={{
                      root: "dialog_close_button"
                    }}
                  >
                    <img src={menu} alt="menu" />
                  </IconButton>
                </div>
                {openedControls === id && (
                  <CampaignContextMenu
                    handleMenu={() => this.handleControls(id)}
                    balance={balance}
                    current_company={current_company}
                    handleBalance={() => handleBalanceDialog(true, "deposit", id)}
                    isArchived={is_archived}
                    isPublished={is_published}
                    isEditable={
                      !is_published &&
                      campaignStatus !== "stopped_immediately" &&
                      campaignStatus !== "finalizing" &&
                      campaignStatus !== "finalized"
                    }
                    onEditClick={() => history.push(`/main/campaign/${id}?tab=build_campaign`)}
                    toggleDialog={this.toggleDialog}
                    isOwner={is_owner}
                    id={id}
                    cannotDeleteCamaign={has_uncompleted_deliveries}
                  />
                )}
              </div>
            )
          )}
        </div>
        <div className="pagination_wrapper">
          {campaigns.count > 4 && (
            <Pagination
              active={activePage}
              pageCount={Math.ceil(campaigns.count / 4)}
              onChange={e => {
                this.fetchCampaigns(e.selected + 1).then(() => {
                  this.setState({ activePage: e.selected + 1, reloading: false });
                });
              }}
              t={t}
            />
          )}
        </div>
        <DialogComponent
          open={deleteDialogOpened}
          onClose={() => this.toggleDialog("delete_campaign")}
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:campaigns.delete-campaign")}</div>
            <div className="dialog_description">
              {t("t:campaigns.delete-campaign-confirmation")}
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="auth"
                onClick={this.removeCampaign}
                loading={buttonLoading}
              >
                {t("t:campaigns.delete-campaign")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("delete_campaign")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={cannotDeleteDialogOpened}
          onClose={() => this.toggleDialog("cannot_delete_campaign")}
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:campaigns.delete-campaign")}</div>
            <div className="dialog_description">{t("t:campaigns.cannot-delete-campaign")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={() => this.toggleDialog("cannot_delete_campaign")}
              >
                {t("t:common.ok")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={leaveDialog}
          onClose={() => this.toggleDialog("leave_campaign")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:campaigns.leave-campaign")}</div>
            <div className="dialog_description">{t("t:campaigns.leave-campaign-confirmation")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.leaveCampaign}
                loading={loading}
              >
                {t("t:common.leave")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("leave_campaign")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={stopDialog}
          onClose={() => this.toggleDialog("stop_campaign")}
          closeIcon
        >
          <div className="delete_dialog">
            <div className="dialog_title text_centered">
              {t("t:campaigns.stop-campaign-confirmation")}&nbsp;
              <a
                href={
                  i18n.language === "et"
                    ? TERMS_AND_CONDITIONS_ESTONIAN_URL
                    : TERMS_AND_CONDITIONS_URL
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("t:auth.terms-and-conditions")}
              </a>
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="auth"
                onClick={this.handleStopCampaign}
                loading={buttonLoading}
              >
                {t("t:campaigns.stop-campaign")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("stop_campaign")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={restoreDialog}
          onClose={() => this.toggleDialog("restore_campaign")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:campaigns.restore-campaign")}</div>
            <div className="dialog_description">
              {t("t:campaigns.restore-campaign-confirmation")}
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.restoreCampaign}
                loading={loading}
              >
                {t("t:campaigns.restore")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("restore_campaign")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={archiveDialog}
          onClose={() => this.toggleDialog("archive_campaign")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:campaigns.archive-campaign")}</div>
            <div className="dialog_description">
              {t("t:campaigns.archive-campaign-confirmation")}
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.archiveCampaign}
                loading={loading}
              >
                {t("t:campaigns.archive")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("archive_campaign")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={archiveFailureDialog}
          onClose={() => this.toggleDialog("archive_failure")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:campaigns.archive-campaign")}</div>
            <div className="dialog_description">{t("t:campaigns.archive-failure")}</div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={() => this.toggleDialog("archive_failure")}
              >
                {t("t:common.ok")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
        <DialogComponent
          open={duplicateDialog}
          onClose={() => this.toggleDialog("duplicate_campaign")}
          closeIcon
        >
          <div className="default_dialog_wrapper">
            <div className="dialog_title">{t("t:campaigns.duplicate-campaign")}</div>
            <div className="dialog_description">
              {t("t:campaigns.duplicate-campaign-confirmation")}
            </div>
            <div className="input_wrapper">
              <div className="label">{t("t:campaigns.campaign-name")}</div>
              <input
                type="text"
                value={campaignName}
                onChange={e => this.setState({ campaignName: e.target.value })}
              />
            </div>
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="purple_btn"
                onClick={this.duplicateCampaign}
                loading={loading}
                disabled={!campaignName || !campaignName.trim()}
              >
                {t("t:campaigns.duplicate")}
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="cancel_btn"
                onClick={() => this.toggleDialog("duplicate_campaign")}
              >
                {t("t:common.cancel")}
              </DefaultButton>
            </div>
          </div>
        </DialogComponent>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, companies, dashboard, balance }) => {
  return {
    user_info: auth.user_info,
    campaigns: companies.campaigns,
    managers: companies.managers,
    buttonLoading: dashboard.loadingComponent,
    openBalanceDialog: balance.openBalanceDialog
  };
};

const mapDispatchToProps = {
  getCampaigns,
  getManagers,
  postPublishCampaign,
  postUnpublishCampaign,
  deleteCampaign,
  postArchiveCampaign,
  postRestoreCampaign,
  postLeaveCampaign,
  postDuplicateCampaign,
  getProfile,
  handleBalanceDialog,
  patchCampaignName,
  postStopCampaign
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Campaigns));
