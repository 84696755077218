import React from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment/moment";

import SkeletonComponent from "../../HelperComponents/SkeletonComponent/SkeletonComponent";
import { deliveryType, renderStatusName } from "../constants";

import InfoIcon from "../../../assets/image/info.svg";
import DeliveryActions from "../DeliveryActions";

const ListItem = ({ setInfoToShow, ...deliveryInfo }) => {
  const { user, type, status, campaign_id, created_at, updated_at } = deliveryInfo;
  const { t } = useTranslation();
  return (
    <div className="deliveries__item">
      <div>
        <div className="deliveries__item--username">
          <SkeletonComponent variant="text" loading={!user} width={150}>
            <p>
              {user?.first_name} {user?.last_name}
            </p>
          </SkeletonComponent>
        </div>
        <div className="deliveries__item--email">
          <SkeletonComponent variant="text" loading={!user} width={180}>
            <p>{user?.email}</p>
          </SkeletonComponent>
        </div>
      </div>
      <div>
        <div className="deliveries__item--label">
          <SkeletonComponent variant="text" loading={!user} width={61}>
            <p>{t("t:deliveries.purchased")}</p>
          </SkeletonComponent>
        </div>
        <div className="deliveries__item--value">
          <SkeletonComponent variant="text" loading={!user} width={70}>
            <p>{moment(created_at).format("DD.MM.YYYY")}</p>
          </SkeletonComponent>
        </div>
      </div>
      <div>
        <div className="deliveries__item--label">
          <SkeletonComponent variant="text" loading={!user} width={100}>
            <p>{t("t:deliveries.delivery-type")}</p>
          </SkeletonComponent>
        </div>
        <div className="deliveries__item--value">
          <SkeletonComponent variant="text" loading={!user} width={80}>
            <p>{deliveryType(type, t)}</p>
          </SkeletonComponent>
        </div>
      </div>
      <div>
        <div className="deliveries__item--status">
          <SkeletonComponent variant="text" loading={!user} width={140}>
            <p>{renderStatusName(status, t)}</p>
          </SkeletonComponent>
        </div>
        <div className="deliveries__item--updated-at">
          <SkeletonComponent variant="text" loading={!user} width={120}>
            {t("t:common.updated")}&nbsp;
            <span>
              {moment(updated_at)
                .subtract(1, "seconds")
                .fromNow()}
            </span>
          </SkeletonComponent>
        </div>
      </div>
      <div className="deliveries__item--controls">
        <IconButton onClick={() => setInfoToShow(deliveryInfo)}>
          <img src={InfoIcon} alt="i" />
        </IconButton>
        <div className="deliveries__item--action-btn">
          <DeliveryActions delivery={deliveryInfo} campaignId={campaign_id} />
        </div>
      </div>
    </div>
  );
};

export default ListItem;
