import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import MultipleIcon from "../../../../../assets/image/CampaignPanel/check_list_green.svg";
import CheckIcon from "../../../../../assets/image/tick.svg";

const Multiple = () => {
  const { t } = useTranslation();

  const multiple = useSelector(({ campaign }) => campaign.stepResults.multiple);

  return (
    <div className="step-results__block">
      <img src={MultipleIcon} alt="multiple" className="step-results__icon" />
      <div className="step-results__info">
        <div className="step-results__info--header">
          <div className="step-results__info--title">{t("t:build-campaign.multiple")}</div>
        </div>
        <div className="step-results__info--multiple">
          {multiple.map(({ text, count, is_most_popular: mostPopular }, key) => (
            <div key={`multiple-${key}`} className="step-results__info--multiple-row">
              {mostPopular && (
                <img
                  src={CheckIcon}
                  alt="most popular"
                  className="step-results__info--multiple-check"
                />
              )}
              <p
                className={clsx("step-results__info--multiple-text", mostPopular && "most-popular")}
                title={text}
              >
                {text}
              </p>
              <hr className="step-results__info--multiple-separator" />
              <p
                className={clsx(
                  "step-results__info--multiple-count",
                  mostPopular && "most-popular"
                )}
              >
                {count}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Multiple);
