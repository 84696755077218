import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clsx from "clsx";
import _omitBy from "lodash/omitBy";

import {
  postCustomAudience,
  setCustomAudienceInfo,
  resetCustomAudienceInfo
} from "../../../../actions/audienceActions";
import { getInterests } from "../../../../actions/campaignActions";
import SegmentName from "../Blocks/SegmentName";
import SegmentGroups from "../Blocks/SegmentGroups";
import { showToastError } from "../../../../helpers/functions";

import "../styles.scss";

const CreateCustomAudience = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { customAudienceInfo } = useSelector(({ audience }) => audience);
  const [step, setStep] = useState("segment_name");

  useEffect(() => {
    dispatch(getInterests());
    return () => {
      dispatch(resetCustomAudienceInfo());
    };
    // eslint-disable-next-line
  }, []);

  const onStepChange = newStep => setStep(newStep);

  const onFieldChange = (name, value) => {
    dispatch(setCustomAudienceInfo({ [name]: value }));
  };

  const onCreateSegmentClick = () => {
    dispatch(
      postCustomAudience({
        ...customAudienceInfo,
        groups: customAudienceInfo.groups
          .map(group => _omitBy(group, v => !v))
          .map(group => ({ ...group, hashtags: group.hashtags.map(el => el.id) }))
      })
    ).then(res => {
      if (res.payload) {
        history.push("/main/audience?tab=custom");
      } else {
        if (res.error) {
          if (res.error.response.data.name) showToastError(res.error.response.data.name.message);
          if (res.error.response.data.groups) {
            Object.values(res.error.response.data.groups).forEach(el => {
              showToastError(Object.values(el)[0][0].message);
            });
          }
        }
      }
    });
  };

  return (
    <div className="custom-audience__page">
      <div className={clsx("custom-audience__wrapper", step)}>
        <div className="custom-audience__title">{t("t:audience.create-new-segment")}</div>
        {step === "segment_name" && (
          <SegmentName onFieldChange={onFieldChange} onStepChange={onStepChange} />
        )}
        {step === "segment_groups" && <SegmentGroups onSubmit={onCreateSegmentClick} />}
        <Link to="/main/audience?tab=custom" className="text_hover custom-audience__cancel">
          {t("t:common.cancel")}
        </Link>
      </div>
    </div>
  );
};

export default CreateCustomAudience;
