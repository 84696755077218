import React, { Component } from "react";
import { connect } from "react-redux";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

import { getColors } from "../../../actions/productsActions";

import ArrowDown from "../../../assets/image/down-arrow-purple.svg";
import ClearIcon from "../../../assets/image/clear.png";

import "./ColorPicker.scss";

class ColorPicker extends Component {
  state = {
    pickerIsOpen: false
  };

  componentDidMount() {
    const { getColors } = this.props;
    getColors();
  }

  handleColorClick = colorID => {
    const { handleColorChange } = this.props;
    handleColorChange(colorID);
    this.handlePicker();
  };

  handlePicker = () => this.setState(({ pickerIsOpen }) => ({ pickerIsOpen: !pickerIsOpen }));

  render() {
    const { colorsList, colorID, fieldHeight } = this.props;
    const { pickerIsOpen } = this.state;
    if (!colorsList.length) return null;
    const color = colorsList.find(el => el.id === colorID);
    return (
      <div className="color_picker_wrapper">
        <div
          className="color_field"
          style={{ height: `${fieldHeight}px` }}
          onClick={this.handlePicker}
        >
          {color ? (
            <div>
              <div
                className="color_preview"
                style={{
                  backgroundColor: `#${color.hex}`,
                  height: `${fieldHeight}px`,
                  border: color.hex === "FFFFFF" ? "1px solid #EAEAEA" : "1px solid transparent"
                }}
              />
              <p>{color.name}</p>
            </div>
          ) : (
            <div />
          )}
          <div className="arrow_wrapper">
            <img src={ArrowDown} alt="arrow" className={pickerIsOpen ? "arrow up" : "arrow down"} />
          </div>
        </div>
        {pickerIsOpen && (
          <ClickAwayListener onClickAway={this.handlePicker}>
            <div className="colors_container">
              <div
                onClick={() => this.handleColorClick(null)}
                key={`no_color`}
                className="color_preview"
                style={{
                  height: `${fieldHeight}px`,
                  border: "1px solid #EAEAEA"
                }}
              >
                <img src={ClearIcon} alt="clear" className="clear-icon" />
              </div>
              {colorsList.map(({ id, hex }) => (
                <div
                  onClick={() => this.handleColorClick(id)}
                  key={`color_${id}`}
                  className="color_preview"
                  style={{
                    backgroundColor: `#${hex}`,
                    height: `${fieldHeight}px`,
                    border: hex === "FFFFFF" ? "1px solid #EAEAEA" : "1px solid transparent"
                  }}
                >
                  {colorID === id && (
                    <div
                      className="current_color"
                      style={{
                        height: `${fieldHeight + 4}px`,
                        border: hex === "FFFFFF" ? "1px solid #EAEAEA" : `1px solid #${hex}`
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </ClickAwayListener>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ products }) => {
  return {
    colorsList: products.colors
  };
};

const mapDispatchToProps = {
  getColors
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorPicker);
