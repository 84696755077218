import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { withTranslation } from "react-i18next";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import RenderField from "../../../../HelperComponents/RenderField/RenderField";

import { postNewDataSource } from "../../../../../actions/dataSourcesActions";

class GenerateApiKey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: ""
    };
  }

  generateAPIkey = data => {
    const { postNewDataSource } = this.props;
    return postNewDataSource(data).then(res => {
      if (res.payload) {
        this.setState({ apiKey: res.payload.data.api_key });
      } else {
        throw new SubmissionError({ apiKey: res.error.response.data.api_key[0].message });
      }
    });
  };

  render() {
    const { handleSubmit, submitting, pristine, valid, loading, t } = this.props;
    const { apiKey } = this.state;
    return (
      <Fragment>
        <div className="title">{t("t:data-sources.set-data-source")}</div>
        <div className="add_api_source_description">
          <p>{t("t:data-sources.set-data-source-description-1")}</p>
          <br />
          <p>{t("t:data-sources.set-data-source-description-2")}</p>
        </div>
        <form onSubmit={handleSubmit(this.generateAPIkey)} className="generate_api_key_form">
          <div className="label">{t("t:data-sources.data-source-name")}</div>
          <Field name="name" type="text" component={RenderField} disabled={!!apiKey} label="" />
          {!apiKey && (
            <DefaultButton
              variant="contained"
              classes="auth"
              disabled={submitting || pristine || !valid}
              loading={loading}
              formAction
            >
              {t("t:data-sources.generate")}
            </DefaultButton>
          )}
        </form>
        {apiKey && (
          <div className="api_key_wrapper">
            <p>
              {t("t:data-sources.api-key")}: <strong>{apiKey}</strong>
            </p>
            <br />
            <p>{t("t:data-sources.store-api-key")}</p>
            <DefaultButton
              variant="contained"
              classes="auth"
              type="link"
              to="/main/add-new-data-source/configure-api-endpoints"
            >
              {t("t:common.continue")}
            </DefaultButton>
          </div>
        )}
      </Fragment>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.name) {
    errors.name = t("t:common.field-is-required");
  }
  return errors;
};

GenerateApiKey = reduxForm({
  form: "GenerateAPIkeyForm",
  validate
})(GenerateApiKey);

const mapStateToProps = ({ dashboard }) => {
  return {
    loading: dashboard.loadingComponent
  };
};

const mapDispatchToProps = {
  postNewDataSource
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GenerateApiKey));
