import React, { Component } from "react";
import { connect } from "react-redux";

import { getCompanyDetails } from "../../../../actions/companiesActions";
import Loader from "../../../HelperComponents/Loader";
import CompanyForm from "../CompanyForm";

class EditCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      defaultValues: { initial: true }
    };
  }

  componentDidMount() {
    const { getCompanyDetails } = this.props;
    const { id } = this.state;
    getCompanyDetails(id).then(res => {
      this.setState({ defaultValues: res.payload.data });
    });
  }

  render() {
    const { defaultValues } = this.state;
    return (
      <div className="edit_company_wrapper">
        {defaultValues.initial ? (
          <Loader />
        ) : (
          <CompanyForm mode="edit" defaultValues={defaultValues} noTerms />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCompanyDetails
};

export default connect(null, mapDispatchToProps)(EditCompany);
