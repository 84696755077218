import React from "react";
import { useTranslation } from "react-i18next";

import { renderStepPlaceholder } from "../../../../../../helpers/functions";
import TooltipMessage from "../../../../../HelperComponents/TooltipMessage/TooltipMessage";
import CharactersLeft from "../../../../../HelperComponents/CharactersLeft";

import text from "../../../../../../assets/image/My_Campaign/color.svg";
import cancel from "../../../../../../assets/image/My_Campaign/btn-cancel.svg";
import trash from "../../../../../../assets/image/My_Campaign/btn-trash.svg";
import save from "../../../../../../assets/image/My_Campaign/btn-save.svg";
import Shape from "../../../../../../assets/image/My_Campaign/Shape.svg";
import ErrorIcon from "../../../../../../assets/image/c-warning-e.svg";

const ShortTextExpandedView = ({
  type,
  detail,
  toClosePopper,
  title,
  description,
  campaignError,
  isEditor,
  onTitleChange,
  deleteStep,
  changeStep,
  changeState
}) => {
  const { t } = useTranslation();

  return (
    <div className="block_wrapper">
      <div className="head_block">
        <div className="title_head">
          <div>
            <img src={text} alt="text" />
          </div>
          <div>
            <p>{t("t:build-campaign.short-text")}</p>
            <span>{renderStepPlaceholder(detail.title, type, t)}</span>
          </div>
        </div>
        {isEditor ? (
          <div className="btn_head">
            <button onClick={deleteStep}>
              <img src={cancel} alt="cancel" />
            </button>
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
            <button onClick={changeStep}>
              <img src={save} alt="save" />
            </button>
          </div>
        ) : (
          <div className="btn_head">
            <button onClick={toClosePopper}>
              <img src={trash} alt="trash" />
            </button>
          </div>
        )}
      </div>
      <div className="input_title_block_my_campaign no-padding-bottom">
        <div>
          <span>{`${t("t:common.title")} *`}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-short-text-title")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <input
            disabled={!isEditor}
            type="text"
            placeholder={renderStepPlaceholder(detail.title, type, t)}
            value={title}
            onChange={e => onTitleChange(e.target.value)}
          />
          {campaignError.title ? (
            <TooltipMessage
              text={campaignError.title[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
        <CharactersLeft title={title} maxCharacters={50} />
      </div>
      <div className="textarea_description_block_my_campaign">
        <div>
          <span>{t("t:common.description")}</span>
          <TooltipMessage
            text={t("t:helper-texts.build-short-text-description")}
            delay={200}
            position="top"
            classes="step_tooltip"
          >
            <img src={Shape} alt="Shape" />
          </TooltipMessage>
        </div>
        <div className="step_input_wrapper">
          <textarea
            disabled={!isEditor}
            value={description}
            onChange={e => changeState({ description: e.target.value })}
          />
          {campaignError.description ? (
            <TooltipMessage
              text={campaignError.description[0].message}
              delay={200}
              error
              position="top"
              classes=""
            >
              <img src={ErrorIcon} alt="error" />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ShortTextExpandedView;
