import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Flag from "react-world-flags";

import SelectComponent from "../SelectComponent/SelectComponent";

import "./styles.scss";

const PhoneNumberInput = ({ value, onChange, error }) => {
  const euCountries = useSelector(({ companies }) => companies.euCountries);

  const country = useMemo(() => {
    const eeInfo = euCountries.find(c => c.country_iso_code === "EE");
    if (!value) return eeInfo;
    const splitNumber = value.toString().slice(1, 4);
    return euCountries.find(c => splitNumber.includes(c.phone_code)) || eeInfo;
    // eslint-disable-next-line
  }, [value, euCountries.length]);

  const inputValue = useMemo(
    () =>
      (value || "")
        .toString()
        .replace("+", "")
        .replace(country?.phone_code, ""),
    [country, value]
  );

  const onInputChange = e => onChange(`+${country.phone_code}${e.target.value}`);

  const onFlagChange = ({ value }) =>
    onChange(`+${euCountries.find(c => c.country_iso_code === value).phone_code}${inputValue}`);

  return (
    <div className="input_wrapper phone-number-input">
      <div className="phone-number-input__flag-and-code">
        <FormControl className="phone-number-input__flag-and-code--selector">
          <SelectComponent
            value={{
              label: (
                <div className="country-select-label">
                  <Flag code={country?.country_iso_code} />
                </div>
              ),
              value: country?.country_iso_code
            }}
            options={euCountries.map(c => ({
              label: (
                <div className="country-select-label">
                  <Flag code={c.country_iso_code} />
                </div>
              ),
              value: c.country_iso_code
            }))}
            change={onFlagChange}
            isClearable="false"
            isSearchable={false}
          />
        </FormControl>
        <div className="phone-number-input__flag-and-code--separator" />
        <div className="phone-number-input__flag-and-code--code">{`+${country?.phone_code}`}</div>
      </div>
      <input
        type="number"
        value={inputValue}
        onChange={onInputChange}
        className="phone-number-input__input"
      />
      <span className="phone-number-input__error">{error}</span>
    </div>
  );
};

export default PhoneNumberInput;
