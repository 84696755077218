import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

// import grid from "../../assets/image/grid.svg";
// import grid_purple from "../../assets/image/grid_purple.svg";
// import window_paragraph_purple from "../../assets/image/window-paragraph-purple.svg";
// import window_paragraph from "../../assets/image/window-paragraph.svg";
import network from "../../assets/image/network.svg";
import network_purple from "../../assets/image/network_purple.svg";
// import sound_wave_purple from "../../assets/image/sound-wave-purple.svg";
// import sound_wave from "../../assets/image/sound-wave.svg";
import support_purple from "../../assets/image/support_purple.svg";
import support from "../../assets/image/support.svg";
// import document from "../../assets/image/document.svg";
// import document_purple from "../../assets/image/document_purple.svg";
import flag_purple from "../../assets/image/flag_purple.svg";
import flag from "../../assets/image/flag.svg";
import users from "../../assets/image/multiple-19.svg";
import users_purple from "../../assets/image/multiple-19_purple.svg";
import products from "../../assets/image/products_icon_unactive.svg";
import products_purple from "../../assets/image/products_icon_active.svg";
import deliveries from "../../assets/image/delivery_unactive.svg";
import deliveries_purple from "../../assets/image/delivery_active.svg";
import billing_reports from "../../assets/image/billing_inactive.svg";
import billing_reports_purple from "../../assets/image/billing_active.svg";

import "./Sidebar.scss";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOwner: props.current_company ? props.current_company.is_owner : false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.current_company !== this.props.current_company) {
      if (this.props.current_company) {
        this.setState({ isOwner: this.props.current_company.is_owner });
      } else {
        this.props.history.push("/main/create-company-profile");
      }
    }
  }

  render() {
    const { current_company, t } = this.props;
    const { isOwner } = this.state;
    return (
      <div className="panel_wrapper">
        <NavLink to="/main/campaigns" exact>
          <img src={flag} alt="campaigns" />
          <img src={flag_purple} alt="campaigns" className="hover" />
          {t("t:common.campaigns")}
        </NavLink>
        {/* TODO in the future sprints */}
        {/*<NavLink to="/main/dashboard" exact>*/}
        {/*    <img src={grid} alt="dashboard" />*/}
        {/*    <img src={grid_purple} alt="dashboard" className="hover" />*/}
        {/*    Dashboard*/}
        {/*</NavLink>*/}
        {/*<NavLink to="/main/templates" exact>*/}
        {/*    <img src={window_paragraph} alt="templates" />*/}
        {/*    <img src={window_paragraph_purple} alt="templates" className="hover" />*/}
        {/*    Templates*/}
        {/*</NavLink>*/}
        <NavLink to="/main/audience?tab=csv_based" exact>
          <img src={network} alt="audience" />
          <img src={network_purple} alt="audience" className="hover" />
          {t("t:common.audience")}
        </NavLink>
        {/*<NavLink to="/main/performance" exact>*/}
        {/*    <img src={sound_wave} alt="performance" />*/}
        {/*    <img src={sound_wave_purple} alt="performance" className="hover" />*/}
        {/*    Performance*/}
        {/*</NavLink>*/}
        {isOwner && (
          <NavLink to="/main/users/?tab=approved" exact>
            <img src={users} alt="users" />
            <img src={users_purple} alt="users" className="hover" />
            {t("t:common.users")}
          </NavLink>
        )}
        {current_company && (
          <NavLink to="/main/products">
            <img src={products} alt="products" />
            <img src={products_purple} alt="products" className="hover" />
            {t("t:common.products")}
          </NavLink>
        )}
        <NavLink to="/main/deliveries">
          <img src={deliveries} alt="deliveries" />
          <img src={deliveries_purple} alt="deliveries" className="hover" />
          {t("t:deliveries.deliveries")}
        </NavLink>

        <div className="hr" />

        <NavLink to="/main/billing-reports?tab=campaign_report">
          <img src={billing_reports} alt="billing reports" />
          <img src={billing_reports_purple} alt="billing reports" className="hover" />
          {t("t:billing-reports.billing-reports")}
        </NavLink>
        <NavLink to="/main/support">
          <img src={support} alt="support" />
          <img src={support_purple} alt="support" className="hover" />
          {t("t:common.support")}
        </NavLink>
        {/*<NavLink to="/main/documentation" exact>*/}
        {/*    <img src={document} alt="documentation" />*/}
        {/*    <img src={document_purple} alt="documentation" className="hover" />*/}
        {/*    {t("t:common.documentation")}*/}
        {/*</NavLink>*/}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    current_company: auth.user_info.current_company
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Sidebar)));
