/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { audienceTabs } from "../../Audience/constants";

import ArrowIcon from "../../../assets/image/forward.svg";

import "./styles.scss";

const Breadcrumbs = () => {
  const { pathname, search } = useLocation();
  const { t } = useTranslation();
  const {
    campaignInfo: { name: campaignName }
  } = useSelector(({ campaign }) => campaign);
  const { name: productName } = useSelector(({ products }) => products.productInfo);
  const { name: companyName } = useSelector(({ companies }) => companies.companyDetails);
  const { name: csvAudienceName } = useSelector(({ audience }) => audience.csvBasedAudienceInfo);
  const { name: analyticsAudienceName } = useSelector(
    ({ audience }) => audience.analyticsBasedAudienceInfo
  );
  const { name: segmentName } = useSelector(({ audience }) => audience.customAudienceInfo);

  const renderDynamicBreadcrumbs = useMemo(() => {
    const splitPathname = pathname.split("/");

    if (pathname === "/main/campaigns") {
      return <div className="breadcrumbs__child">{t("t:common.campaigns")}</div>;
    }

    if (pathname === "/main/products") {
      return <div className="breadcrumbs__child">{t("t:common.products")}</div>;
    }

    if (pathname === "/main/deliveries") {
      return <div className="breadcrumbs__child">{t("t:deliveries.deliveries")}</div>;
    }

    if (pathname === "/main/audience") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/audience?tab=csv_based">
            {t("t:common.audience")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">
            {t(audienceTabs.find(t => search.includes(t.value)).translationKey)}
          </div>
        </>
      );
    }

    if (pathname === "/main/audience/csv-based/create") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/audience?tab=csv_based">
            {t("t:common.audience")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <Link className="breadcrumbs__parent" to="/main/audience?tab=csv_based">
            {t("t:audience.csv-based")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:audience.create-audience")}</div>
        </>
      );
    }

    // Page of a single csv audience, the audience name as a breadcrumbs should be stored (redux store)
    if (
      pathname.includes("/main/audience/csv-based/edit/") ||
      pathname.includes("/main/audience/csv-based/view/")
    ) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/audience?tab=csv_based">
            {t("t:common.audience")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <Link className="breadcrumbs__parent" to="/main/audience?tab=csv_based">
            {t("t:audience.csv-based")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{csvAudienceName || "..."}</div>
        </>
      );
    }

    if (pathname === "/main/audience/custom/create") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/audience?tab=custom">
            {t("t:common.audience")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <Link className="breadcrumbs__parent" to="/main/audience?tab=custom">
            {t("t:audience.custom")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:audience.create-new-segment")}</div>
        </>
      );
    }

    // Page of a single segment, the segment name as a breadcrumbs should be stored (redux store)
    if (
      pathname.includes("/main/audience/custom/edit/") ||
      pathname.includes("/main/audience/custom/view/")
    ) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/audience?tab=custom">
            {t("t:common.audience")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <Link className="breadcrumbs__parent" to="/main/audience?tab=custom">
            {t("t:audience.custom")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{segmentName || "..."}</div>
        </>
      );
    }

    // Page of a single analytics audience, the audience name as a breadcrumbs should be stored (redux store)
    if (pathname.includes("/main/audience/analytics-based/edit/")) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/audience?tab=analytics_based">
            {t("t:common.audience")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <Link className="breadcrumbs__parent" to="/main/audience?tab=analytics_based">
            {t("t:audience.analytics-based")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{analyticsAudienceName || "..."}</div>
        </>
      );
    }

    if (pathname === "/main/users/") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/users/?tab=approved">
            {t("t:common.users")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">
            {search === "?tab=approved" ? t("t:users.approved") : t("t:users.invited")}
          </div>
        </>
      );
    }

    if (pathname === "/main/users/invite-user") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/users/?tab=approved">
            {t("t:common.users")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:users.invite-users")}</div>
        </>
      );
    }

    if (pathname === "/main/profile/") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/profile/?tab=general">
            {t("t:common.my-profile")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">
            {search === "?tab=companies" ? t("t:common.companies") : t("t:profile.general")}
          </div>
        </>
      );
    }

    if (pathname.includes("/main/support/")) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/support/good-to-know">
            {t("t:common.support")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t(`t:support.${splitPathname[3]}`)}</div>
        </>
      );
    }

    if (pathname === "/main/campaigns/new-campaign") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/campaigns">
            {t("t:common.campaigns")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:campaigns.new-campaign")}</div>
        </>
      );
    }

    // Page of a single campaign, the campaign name as a breadcrumbs should be stored (redux store)
    if (pathname.includes("/main/campaign/")) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/campaigns">
            {t("t:common.campaigns")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{campaignName || "..."}</div>
        </>
      );
    }

    // Page of a single product, the product name as a breadcrumbs should be stored (redux store)
    if (
      pathname.includes("/main/products/view-product/") ||
      pathname.includes("/main/products/edit-product/")
    ) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/products">
            {t("t:common.products")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{productName || "..."}</div>
        </>
      );
    }

    if (pathname === "/main/new-company") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/profile/?tab=general">
            {t("t:common.my-profile")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <Link className="breadcrumbs__parent" to="/main/profile/?tab=companies">
            {t("t:common.companies")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:companies.add-new-company")}</div>
        </>
      );
    }

    // Page of a single company, the company name as a breadcrumbs should be stored (redux store)
    if (pathname.includes("/main/companies/view-company/") || pathname.includes("/main/company/")) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/profile/?tab=general">
            {t("t:common.my-profile")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <Link className="breadcrumbs__parent" to="/main/profile/?tab=companies">
            {t("t:common.companies")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{companyName || "..."}</div>
        </>
      );
    }

    if (pathname.includes("/main/company-api-access/")) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/profile/?tab=general">
            {t("t:common.my-profile")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <Link className="breadcrumbs__parent" to="/main/profile/?tab=companies">
            {t("t:common.companies")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child"> {t("t:companies.public-api-access")}</div>
        </>
      );
    }

    if (pathname === "/main/billing-reports") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/billing-reports?tab=campaign_report">
            {t("t:billing-reports.billing-reports")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">
            {search === "?tab=purchase_report"
              ? t("t:billing-reports.purchase-report")
              : t("t:billing-reports.campaign-report")}
          </div>
        </>
      );
    }

    if (pathname.includes("/main/add-new-data-source/")) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/products">
            {t("t:common.products")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:data-sources.add-data-source")}</div>
        </>
      );
    }

    if (pathname.includes("/main/add-magento-data-source/")) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/products">
            {t("t:common.products")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:data-sources.add-magento-as-data-source")}</div>
        </>
      );
    }

    if (pathname.includes("/main/edit-data-source/")) {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/products">
            {t("t:common.products")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:data-sources.data-source")}</div>
        </>
      );
    }

    if (pathname === "/main/products/new-product") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/products">
            {t("t:common.products")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:products.add-new-product")}</div>
        </>
      );
    }

    if (pathname === "/main/add-products-via-api") {
      return (
        <>
          <Link className="breadcrumbs__parent" to="/main/products">
            {t("t:common.products")}
          </Link>
          <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
          <div className="breadcrumbs__child">{t("t:products.add-using-api")}</div>
        </>
      );
    }

    // Fallback breadcrumb in case of unhandled location
    return <div className="breadcrumbs__child">{splitPathname[splitPathname.length - 1]}</div>;
  }, [
    pathname,
    search,
    campaignName,
    productName,
    companyName,
    csvAudienceName,
    analyticsAudienceName,
    segmentName
  ]);

  return (
    <div className="breadcrumbs">
      <Link className="breadcrumbs__parent" to="/main/campaigns">
        {t("t:common.dashboard")}
      </Link>
      <img src={ArrowIcon} alt=">" className="breadcrumbs__arrow" />
      {renderDynamicBreadcrumbs}
    </div>
  );
};

export default Breadcrumbs;
