import React, { Fragment } from "react";
import clsx from "clsx";

import SkeletonComponent from "../SkeletonComponent/SkeletonComponent";

import "./styles.scss";

const TotalBlock = ({
  loading,
  totalIcon,
  totalText,
  items,
  onItemClick,
  backgroundColor = "#EEF3FF",
  textColor = "#324EA3"
}) => (
  <div className="total-block" style={{ backgroundColor }}>
    <SkeletonComponent variant="text" loading={loading} width={200}>
      <div
        onClick={() => onItemClick && onItemClick("")}
        className={clsx("total-block__total", onItemClick && "total-block__clickable")}
        style={{ color: textColor }}
      >
        <img src={totalIcon} alt="users" className="total-block__total--icon" />
        {totalText}
      </div>
    </SkeletonComponent>
    <SkeletonComponent variant="text" loading={loading} width={250}>
      <p className="total-block__items" style={{ color: textColor }}>
        {items.map(({ label, value }, key) => (
          <Fragment key={value}>
            <span
              onClick={() => onItemClick && onItemClick(value)}
              className={clsx("total-block__items--item", onItemClick && "total-block__clickable")}
            >
              {label}
            </span>
            {key + 1 < items.length && "|"}
          </Fragment>
        ))}
      </p>
    </SkeletonComponent>
  </div>
);

export default TotalBlock;
