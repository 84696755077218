import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import moment from "moment";

import SkeletonComponent from "../../../../HelperComponents/SkeletonComponent/SkeletonComponent";
import { deliveryType, renderStatusName } from "../../../../Deliveries/constants";
import DeliveryActions from "../../../../Deliveries/DeliveryActions";
import DialogComponent from "../../../../HelperComponents/DialogComponent/DialogComponent";
import TrackingInfoForm from "../../../../Deliveries/TrackingInfoForm";
import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";

import ArrowDownIcon from "../../../../../assets/image/down-arrow-purple.svg";

export const renderDeliveryInfo = (
  {
    type,
    address_name,
    delivery_point_name,
    courier,
    status,
    tracking_number,
    eta,
    phone_number,
    unique_id,
    redemption_address,
    name_of_the_employee
  },
  t
) => {
  switch (type) {
    case "redeemable_any":
      return (
        <>
          <h6>{t("t:deliveries.redemption-address")}</h6>
          <p>{redemption_address || "—"}</p>
          <br />
          <h6>{t("t:deliveries.name-of-the-employee")}</h6>
          <p>{name_of_the_employee || "—"}</p>
          <br />
          <h6>{t("t:deliveries.order-code")}</h6>
          <p>{unique_id || "—"}</p>
        </>
      );
    case "pickup":
      return (
        <>
          <h6>{t("t:deliveries.pickup-address")}</h6>
          <p>{address_name || "—"}</p>
          <br />
          <h6>{t("t:deliveries.order-code")}</h6>
          <p>{unique_id || "—"}</p>
        </>
      );
    case "courier":
      return (
        <>
          <div className="in_line_info">
            <div>
              <h6>{t("t:deliveries.courier-info")}</h6>
              <p>{courier || "—"}</p>
            </div>
            {(status === "on_delivery" || status === "done") && (
              <>
                <div>
                  <h6>{t("t:deliveries.tracking-number")}</h6>
                  <p>{tracking_number || "—"}</p>
                </div>
                <div>
                  <h6>{t("t:deliveries.eta")}</h6>
                  <p>{eta ? moment(eta).format("DD.MM.YYYY") : "—"}</p>
                </div>
              </>
            )}
            <div>
              <h6>{t("t:deliveries.app-user-phone")}</h6>
              <p>{phone_number || "—"}</p>
            </div>
          </div>
          <br />
          <h6>{t("t:deliveries.shipping-address")}</h6>
          <p>{address_name || "—"}</p>
        </>
      );
    case "parcel_machine":
      return (
        <>
          <div className="in_line_info">
            <div>
              <h6>{t("t:deliveries.courier-info")}</h6>
              <p>{courier || "—"}</p>
            </div>
            {(status === "on_delivery" || status === "done") && (
              <>
                <div>
                  <h6>{t("t:deliveries.tracking-number")}</h6>
                  <p>{tracking_number || "—"}</p>
                </div>
                <div>
                  <h6>{t("t:deliveries.eta")}</h6>
                  <p>{eta ? moment(eta).format("DD.MM.YYYY") : "—"}</p>
                </div>
              </>
            )}
            <div>
              <h6>{t("t:deliveries.app-user-phone")}</h6>
              <p>{phone_number || "—"}</p>
            </div>
          </div>
          <br />
          <h6>{t("t:deliveries.parcel-machine-location")}</h6>
          {delivery_point_name && <p>{delivery_point_name}</p>}
          <p>{address_name || "—"}</p>
        </>
      );
    default:
      return null;
  }
};

const DeliveryItem = ({
  mounted,
  moreInfoArray,
  toggleDialog,
  handleMoreInfo,
  renderButtonByStatus,
  campaignId,
  ...deliveryInfo
}) => {
  const {
    id,
    type,
    status,
    updated_at,
    user: { first_name, last_name, email }
  } = deliveryInfo;
  const [openTrackingInfoDialog, setOpenTrackingInfoDialog] = useState(false);
  const { t } = useTranslation();
  const isExpanded = moreInfoArray.some(el => el.id === id);

  return (
    <>
      <div className="table_row">
        <div className="main_info">
          <div className="row_item">
            <SkeletonComponent variant="text" loading={!mounted} width={130}>
              <p>
                {first_name} {last_name}
              </p>
            </SkeletonComponent>
            <SkeletonComponent variant="text" loading={!mounted} width={200}>
              <span>{email}</span>
            </SkeletonComponent>
          </div>
          <div className="row_item">
            <SkeletonComponent variant="text" loading={!mounted} width={130}>
              <p>{deliveryType(type, t)}</p>
            </SkeletonComponent>
          </div>
          <div className="row_item">
            <SkeletonComponent variant="text" loading={!mounted} width={50}>
              <div className="delivery-status">
                {renderStatusName(status, t)}
                <p className="delivery-status__updated">
                  {t("t:common.updated")}&nbsp;
                  <span className="delivery-status__updated--value">
                    {moment(updated_at)
                      .subtract(1, "seconds")
                      .fromNow()}
                  </span>
                </p>
              </div>
            </SkeletonComponent>
          </div>
          <div className="row_item">
            <DeliveryActions delivery={deliveryInfo} campaignId={campaignId} />
          </div>
          <div className="row_item" onClick={handleMoreInfo}>
            <img src={ArrowDownIcon} alt="more" className={clsx(isExpanded && "active")} />
          </div>
        </div>
        <div className={clsx("additional_info", isExpanded && "expanded")}>
          <>
            <div className="in_line_info">
              <div>
                <h6>{t("t:build-campaign.product")}</h6>
                <p>{deliveryInfo.product_name}</p>
              </div>
              {deliveryInfo.product_size && (
                <div>
                  <h6>{t("t:build-campaign.size")}</h6>
                  <p>{deliveryInfo.product_size}</p>
                </div>
              )}
              {deliveryInfo.product_quantity && (
                <div>
                  <h6>{t("t:build-campaign.quantity")}</h6>
                  <p>{deliveryInfo.product_quantity || "—"}</p>
                </div>
              )}
            </div>
            <br />
            {deliveryInfo.gift_name && (
              <>
                <div className="in_line_info">
                  <div>
                    <h6>{t("t:build-campaign.bonus-product")}</h6>
                    <p>{deliveryInfo.gift_name}</p>
                  </div>
                  {deliveryInfo.gift_size && (
                    <div>
                      <h6>{t("t:build-campaign.size")}</h6>
                      <p>{deliveryInfo.gift_size}</p>
                    </div>
                  )}
                  {deliveryInfo.gift_quantity && (
                    <div>
                      <h6>{t("t:build-campaign.quantity")}</h6>
                      <p>{deliveryInfo.gift_quantity || "—"}</p>
                    </div>
                  )}
                </div>
                <br />
              </>
            )}
          </>
          {renderDeliveryInfo(deliveryInfo, t)}
          {(status === "on_delivery" || (status === "done" && type !== "pickup")) && (
            <DefaultButton
              variant="contained"
              classes="delivery_btn mt-24"
              onClick={() => setOpenTrackingInfoDialog(true)}
            >
              {t("t:deliveries.edit-tracking-info")}
            </DefaultButton>
          )}
        </div>
      </div>
      {openTrackingInfoDialog && (
        <DialogComponent
          open
          onClose={() => setOpenTrackingInfoDialog(false)}
          closeIcon
          classes="tracking_info_dialog"
        >
          <TrackingInfoForm
            {...deliveryInfo}
            toggleDialog={() => setOpenTrackingInfoDialog(false)}
            campaignId={campaignId}
          />
        </DialogComponent>
      )}
    </>
  );
};

export default DeliveryItem;
