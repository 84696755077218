import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";

import DefaultButton from "../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import RenderField from "../../../../HelperComponents/RenderField/RenderField";
import { urlPattern } from "../../../../../helpers/functions";

import { getProducts, patchDataSource } from "../../../../../actions/dataSourcesActions";
import TestApiComponent from "../../../TestApiComponent";

class ConfigureApiEndpoints extends Component {
  enterAPIURL = data => {
    const {
      patchDataSource,
      dataSource: { id }
    } = this.props;
    patchDataSource(id, data);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      valid,
      dataSource: { api_base_url: apiURL },
      t
    } = this.props;
    return (
      <div className="add-magento__configure-api">
        <div className="title">{t("t:data-sources.configure-endpoints")}</div>
        <div className="add-magento__configure-api--description">
          <p>{t("t:data-sources.enter-the-path")}</p>
        </div>
        <form onSubmit={handleSubmit(this.enterAPIURL)}>
          <div className="add-magento__configure-api--label">{t("t:data-sources.api-url")}</div>
          <Field name="api_base_url" type="text" component={RenderField} label="" />
          <DefaultButton
            variant="contained"
            classes="auth"
            disabled={submitting || pristine || !valid}
            formAction
          >
            {t("t:common.save")}
          </DefaultButton>
        </form>
        {apiURL && <TestApiComponent type="magento" />}
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.api_base_url) {
    errors.api_base_url = t("t:common.field-is-required");
  } else if (!urlPattern.test(values.api_base_url)) {
    errors.api_base_url = t("t:data-sources.enter-correct-api-url");
  }
  return errors;
};

ConfigureApiEndpoints = reduxForm({
  form: "APIURLForm",
  validate
})(ConfigureApiEndpoints);

const mapStateToProps = ({ dataSources }) => {
  return {
    dataSource: dataSources.dataSource
  };
};

const mapDispatchToProps = {
  getProducts,
  patchDataSource
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ConfigureApiEndpoints));
