import React from "react";

import { renderMobileImage } from "../../helpers";

const Pictures = ({ title, description, images }) => (
  <>
    <h2>{title}</h2>
    <p>{description}</p>
    <div className="pictures-wrapper">
      {images.map(({ id, image }) => (
        <div className="pictures-wrapper__block" key={id}>
          {renderMobileImage(image, 110, 110)}
        </div>
      ))}
    </div>
  </>
);

export default Pictures;
