import React from "react";
import Dialog from "@material-ui/core/Dialog";
import clsx from "clsx";

import CloseDialogIcon from "../../../assets/image/close.svg";

import "./DialogComponent.scss";

const DialogComponent = ({ open, onClose, children, closeIcon = false, classes, rootClass }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        root: `default_dialog_root`,
        paper: `paper_custom_dialog ${rootClass}`
      }}
    >
      <div className={clsx("dialog_wrapper", classes)}>
        {closeIcon && (
          <button onClick={onClose} className="dialog_close_button">
            <img src={CloseDialogIcon} alt="close icon" />
          </button>
        )}
        {children}
      </div>
    </Dialog>
  );
};

export default DialogComponent;
