import React, { Component, Fragment } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { postForgotPassword } from "../../../actions/authActions";

import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  TERMS_AND_CONDITIONS_ESTONIAN_URL
} from "../../../config";

import BackIcon from "../../../assets/image/small-left.svg";

class ForgotPassword extends Component {
  state = {
    loading: false,
    emailSent: false
  };

  submitForm = data => {
    const { postForgotPassword, t } = this.props;
    this.setState({ loading: true });
    return postForgotPassword(data).then(res => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        this.setState({ emailSent: true, loading: false });
      } else {
        this.setState({ loading: false });
        throw new SubmissionError({
          email:
            res.error.response.data.email[0].code === "non_local_account"
              ? res.error.response.data.email[0].message
              : [t("t:auth.user-not-exists")]
        });
      }
    });
  };

  getContent = () => {
    const { handleSubmit, submitting, pristine, valid, t } = this.props;
    const { loading, emailSent } = this.state;
    if (emailSent) {
      return (
        <Fragment>
          <br />
          <br />
          <br />
          <div className="yellow_box max_width_460">
            {t("t:auth.instructions-to-reset-password-sent")}
          </div>
          <div className="auth_btn_wrapper sign_in_link_wrapper">
            <DefaultButton
              variant="contained"
              classes="auth_sign_in_link"
              type="link"
              to="/auth/login"
            >
              {t("t:auth.sign-in")}
            </DefaultButton>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="auth_block_head">{t("t:auth.forgot-password")}</div>
          <div className="auth_description">
            <Link
              to="/auth/login"
              className="back_link text_hover"
              style={{ textTransform: "uppercase" }}
            >
              <img src={BackIcon} alt="<" />
              {t("t:common.back")}
            </Link>
            {t("t:auth.please-enter-the-inbox-you-used")}
          </div>
          <form onSubmit={handleSubmit(this.submitForm)}>
            <Field name="email" type="text" component={RenderField} label={t("t:common.email")} />
            <div className="auth_btn_wrapper forgot_pass_auth_buttons">
              <DefaultButton
                variant="contained"
                classes="auth_forgot_pass"
                disabled={submitting || pristine || !valid}
                loading={loading}
                formAction
              >
                {t("t:auth.send-reset-instructions")}
              </DefaultButton>
            </div>
          </form>
        </Fragment>
      );
    }
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <div className="sign_in_wrapper">
        <div>
          <div className="auth_redirect">
            {t("t:auth.dont-have-account")}
            <Link to="/auth/sign-up" className="text_hover">
              {t("t:auth.sign-up-now")}
            </Link>
          </div>
          {this.getContent()}
        </div>
        <div className="terms_policy_wrapper">
          <a
            href={
              i18n.language === "et" ? TERMS_AND_CONDITIONS_ESTONIAN_URL : TERMS_AND_CONDITIONS_URL
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("t:auth.terms-and-conditions")}
          </a>
          <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
            {t("t:auth.privacy-policy")}
          </a>
        </div>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};
  if (!values.email) {
    errors.email = t("t:common.field-is-required");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = t("t:auth.please-enter-correct-email");
  }
  if (!values.password) {
    errors.password = t("t:common.field-is-required");
  } else if (values.password.length < 8) {
    errors.password = t("t:common.min-8-characters");
  }
  return errors;
};

ForgotPassword = reduxForm({
  form: "LoginForm",
  validate
})(ForgotPassword);

const mapStateToProps = state => {
  return {
    authError: state.auth.error_auth
  };
};
const mapDispatchToProps = {
  postForgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ForgotPassword));
