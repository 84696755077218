import React, { memo } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import Steps from "./Steps";
import StepMobilePreview from "./StepMobilePreview";

import "./styles.scss";

const Panel = ({ addStep }) => {
  const isEditor = useSelector(({ campaign }) => campaign.campaignViewers.is_editor);
  const { step } = useSelector(({ campaign }) => campaign.currentStepInfo);
  return (
    <div
      className={clsx(["build-campaign-panel", step !== "not-selected" && "with-mobile-preview"])}
    >
      {!isEditor && step === "not-selected" && <div className="build-campaign-panel__overlay" />}
      {step === "not-selected" ? <Steps addStep={addStep} /> : <StepMobilePreview />}
    </div>
  );
};

export default memo(Panel);
