import React from "react";

import { renderMobileImage } from "../../helpers";

import ChangeLocation from "../../../../../../assets/image/mobile-change-location.svg";

const Location = ({ title, description, image }) => (
  <>
    <h2>{title}</h2>
    <p>{description}</p>
    <div className="image-wrapper">
      {renderMobileImage(image)}
      <img src={ChangeLocation} alt="map" className="image-wrapper__icon" />
    </div>
  </>
);

export default Location;
