import React, { memo, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

import { numberWithSpaces } from "../../../../helpers/functions";
import { getStatusTranslationKey } from "../../helpers";
import SkeletonComponent from "../../../HelperComponents/SkeletonComponent/SkeletonComponent";
import {
  deleteCSVBasedAudienceByID,
  recalculateCSVBasedAudienceByID,
  setAudienceConfirmationDialog,
  recalculateCustomAudienceByID,
  archiveCustomAudienceByID,
  restoreCustomAudienceByID,
  postEditorForCustomAudience,
  duplicateCustomAudienceByID,
  archiveAnalyticsBasedAudienceByID,
  restoreAnalyticsBasedAudienceByID
} from "../../../../actions/audienceActions";

import MoreIcon from "../../../../assets/image/menu-5.svg";
import DeleteIcon from "../../../../assets/image/bin.svg";
import EditIcon from "../../../../assets/image/edit-icon.svg";
import RecalculateIcon from "../../../../assets/image/recalculate_icon.svg";
import ArchiveIcon from "../../../../assets/image/archive_icon.svg";
import RestoreIcon from "../../../../assets/image/restore_icon.svg";
import DuplicateIcon from "../../../../assets/image/clone_icon.svg";
import DownloadIcon from "../../../../assets/image/download_icon.svg";
import CampaignDummyIcon from "../../../../assets/image/auth_bg_with_assets.png";

const defaultProps = {
  loading: false
};

const ListItem = ({
  loading,
  audienceType,
  id,
  name,
  updated_at,
  updated_by,
  total_contacts,
  match_percent,
  match_percent_updated_at,
  file,
  status,
  period_from,
  period_to
}) => {
  const [isControlsOpen, setControlsOpen] = useState(false);
  const duplicateName = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    editURL,
    viewURL,
    recalculateAction,
    archiveAction,
    restoreAction,
    deleteAction,
    recalculateButtonText,
    editButtonText,
    isCSVBased,
    isCustom,
    isAnalyticsBased
  } = useMemo(() => {
    if (audienceType === "analytics_based")
      return {
        editURL: `/main/audience/analytics-based/edit/${id}`,
        viewURL: `/main/audience/analytics-based/edit/${id}`,
        recalculateAction: null,
        archiveAction: archiveAnalyticsBasedAudienceByID,
        restoreAction: restoreAnalyticsBasedAudienceByID,
        deleteAction: null,
        editButtonText: t("t:audience.edit-segment"),
        recalculateButtonText: null,
        isCSVBased: false,
        isCustom: false,
        isAnalyticsBased: true
      };
    if (audienceType === "custom")
      return {
        editURL: `/main/audience/custom/edit/${id}`,
        viewURL: `/main/audience/custom/edit/${id}`,
        recalculateAction: recalculateCustomAudienceByID,
        archiveAction: archiveCustomAudienceByID,
        restoreAction: restoreCustomAudienceByID,
        deleteAction: null,
        editButtonText: t("t:audience.edit-segment"),
        recalculateButtonText: t("t:audience.recalculate-contacts"),
        isCSVBased: false,
        isCustom: true,
        isAnalyticsBased: false
      };
    return {
      editURL: `/main/audience/csv-based/edit/${id}`,
      viewURL: `/main/audience/csv-based/view/${id}`,
      recalculateAction: recalculateCSVBasedAudienceByID,
      archiveAction: null,
      restoreAction: null,
      deleteAction: deleteCSVBasedAudienceByID,
      editButtonText: t("t:audience.edit-audience"),
      recalculateButtonText: t("t:audience.recalculate-match-rate"),
      isCSVBased: true,
      isCustom: false,
      isAnalyticsBased: false
    };
    // eslint-disable-next-line
  }, [audienceType]);

  const handleControls = () => setControlsOpen(!isControlsOpen);

  const onRecalculateClick = () => {
    handleControls();
    dispatch(recalculateAction(id));
  };

  const onDownloadClick = () => {
    handleControls();
  };

  const onEditClick = async () => {
    handleControls();
    if (audienceType === "custom") {
      const res = await dispatch(postEditorForCustomAudience(id));
      if (res.error) {
        dispatch(
          setAudienceConfirmationDialog({
            open: true,
            title: t("t:common.sorry"),
            description: t("t:audience.you-can-not-edit-this-segment"),
            confirmText: t("t:common.ok"),
            onClose: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
            confirmAction: () => dispatch(setAudienceConfirmationDialog({ reset: true }))
          })
        );
        return;
      }
    }
    history.push(editURL);
  };

  const onNameClick = () => {
    history.push(viewURL);
  };

  const renderCampaignsListForDeleteErrorDialog = campaigns => (
    <div className="confirmation-dialog__campaigns-list">
      {campaigns.map(({ id, name, image }) => (
        <div className="confirmation-dialog__campaign" key={id}>
          <img
            src={image || CampaignDummyIcon}
            alt="campaign"
            className="confirmation-dialog__campaign-icon"
          />
          <p className="confirmation-dialog__campaign-name">{name}</p>
        </div>
      ))}
    </div>
  );

  const renderNameInput = () => (
    <div className="audience-list__item--duplicate-name">
      <div className="label">{t("t:audience.segment-name")}</div>
      <input
        className="default-input"
        type="text"
        value={duplicateName.current}
        onChange={e => (duplicateName.current = e.target.value)}
      />
    </div>
  );

  const deleteAudienceByID = () => {
    dispatch(deleteAction(id)).then(res => {
      if (res.error) {
        dispatch(
          setAudienceConfirmationDialog({
            open: true,
            title: t("t:audience.delete-audience"),
            description: t("t:audience.you-can-not-delete-audience"),
            confirmText: t("t:common.ok"),
            additionalContent: () =>
              renderCampaignsListForDeleteErrorDialog(res.error.response.data.campaigns),
            onClose: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
            confirmAction: () => dispatch(setAudienceConfirmationDialog({ reset: true }))
          })
        );
      }
    });
  };

  const onDeleteClick = () => {
    handleControls();
    dispatch(
      setAudienceConfirmationDialog({
        open: true,
        onClose: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
        confirmAction: deleteAudienceByID,
        declineAction: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
        title: t("t:audience.delete-audience"),
        description: t("t:audience.delete-audience-confirmation"),
        confirmText: t("t:common.delete"),
        declineText: t("t:common.cancel")
      })
    );
  };

  const onArchiveClick = () => {
    handleControls();
    dispatch(
      setAudienceConfirmationDialog({
        open: true,
        onClose: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
        confirmAction: () => dispatch(archiveAction(id)),
        declineAction: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
        title: t("t:audience.archive-segment"),
        description: t("t:audience.archive-segment-confirmation"),
        confirmText: t("t:common.archive"),
        declineText: t("t:common.cancel")
      })
    );
  };

  const onRestoreClick = () => {
    handleControls();
    dispatch(
      setAudienceConfirmationDialog({
        open: true,
        onClose: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
        confirmAction: () => dispatch(restoreAction(id)),
        declineAction: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
        title: t("t:audience.restore-segment"),
        description: t("t:audience.restore-segment-confirmation"),
        confirmText: t("t:common.restore"),
        declineText: t("t:common.cancel")
      })
    );
  };

  const onDuplicateClick = () => {
    handleControls();
    dispatch(
      setAudienceConfirmationDialog({
        open: true,
        onClose: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
        confirmAction: () =>
          dispatch(duplicateCustomAudienceByID(id, { name: duplicateName.current })),
        additionalContent: renderNameInput,
        declineAction: () => dispatch(setAudienceConfirmationDialog({ reset: true })),
        title: t("t:audience.duplicate-segment"),
        description: t("t:audience.duplicate-segment-description"),
        confirmText: t("t:campaigns.duplicate"),
        declineText: t("t:common.cancel")
      })
    );
  };

  return (
    <section className="audience-list__item">
      {status && (
        <div className={clsx("audience-list__item--status", status)}>
          {t(getStatusTranslationKey(status))}
        </div>
      )}
      <div className={clsx("audience-list__item--main-info", isAnalyticsBased && "max-width-60")}>
        <SkeletonComponent variant="text" loading={loading} width={300}>
          <div className="audience-list__item--main-info-name text-ellipsis" onClick={onNameClick}>
            {name}
          </div>
        </SkeletonComponent>
        <SkeletonComponent variant="text" loading={loading} width={isAnalyticsBased ? 400 : 200}>
          <p className="audience-list__item--main-info-description text-ellipsis">
            {isAnalyticsBased && (
              <>
                {`${moment(period_from).format("DD.MM.YY")}${
                  period_to ? ` - ${moment(period_to).format("DD.MM.YY")}` : ""
                }`}
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </>
            )}
            {t("t:common.updated")}
            <span>
              {moment(updated_at)
                .subtract(1, "seconds")
                .fromNow()}
            </span>
            {t("t:common.updated-time-ago-BY-user-name")}
            <span>{updated_by ? `${updated_by.first_name} ${updated_by.last_name}` : ""}</span>
          </p>
        </SkeletonComponent>
      </div>
      <div className="items-center">
        <div className={clsx("audience-list__item--support-info", isCSVBased && "smaller-mr")}>
          <div className="audience-list__item--support-info-title">
            {t("t:audience.total-contacts")}
          </div>
          <SkeletonComponent variant="text" loading={loading} width={100}>
            <p className="audience-list__item--support-info-value">
              {numberWithSpaces(total_contacts || 0)}
            </p>
          </SkeletonComponent>
        </div>
        {isCSVBased && (
          <div className="audience-list__item--support-info min-width-260">
            <div className="audience-list__item--support-info-title">
              {t("t:audience.match-rate")}
            </div>
            <SkeletonComponent variant="text" loading={loading} width={200}>
              <p className="audience-list__item--match-rate">
                <span className="audience-list__item--match-rate-value">
                  {Math.round(match_percent * 100) / 100}%
                </span>
                <span className="audience-list__item--match-rate-text">
                  {t("t:common.calculated")}
                </span>
                <span className="audience-list__item--match-rate-ago">
                  {moment(match_percent_updated_at)
                    .subtract(1, "seconds")
                    .fromNow()}
                </span>
              </p>
            </SkeletonComponent>
          </div>
        )}
        <SkeletonComponent variant="circle" loading={loading} width={45} height={45}>
          <div className="audience-list__item--support-info-more">
            <IconButton
              onClick={handleControls}
              classes={{
                root: "audience-list__item--support-info-more-icon"
              }}
            >
              <img src={MoreIcon} alt="more" />
            </IconButton>
            {isControlsOpen && (
              <ClickAwayListener onClickAway={handleControls}>
                <div className="audience-list__item--actions-menu">
                  {!isAnalyticsBased && (
                    <button
                      onClick={onRecalculateClick}
                      className="audience-list__item--actions-action"
                    >
                      <div className="audience-list__item--actions-icon">
                        <img src={RecalculateIcon} alt="recalculate" />
                      </div>
                      {recalculateButtonText}
                    </button>
                  )}
                  {isCSVBased && (
                    <a
                      onClick={onDownloadClick}
                      className="audience-list__item--actions-action"
                      download
                      href={file}
                    >
                      <div className="audience-list__item--actions-icon">
                        <img src={DownloadIcon} alt="download" />
                      </div>
                      {t("t:audience.download-csv-file")}
                    </a>
                  )}
                  {status !== "archived" && (
                    <button onClick={onEditClick} className="audience-list__item--actions-action">
                      <div className="audience-list__item--actions-icon">
                        <img src={EditIcon} alt="edit" />
                      </div>
                      {editButtonText}
                    </button>
                  )}
                  {isCSVBased && (
                    <button onClick={onDeleteClick} className="audience-list__item--actions-action">
                      <div className="audience-list__item--actions-icon">
                        <img src={DeleteIcon} alt="delete" />
                      </div>
                      {t("t:audience.delete-audience")}
                    </button>
                  )}
                  {isCustom && (
                    <button
                      onClick={onDuplicateClick}
                      className="audience-list__item--actions-action"
                    >
                      <div className="audience-list__item--actions-icon">
                        <img src={DuplicateIcon} alt="duplicate" />
                      </div>
                      {t("t:audience.duplicate-segment")}
                    </button>
                  )}
                  {!isCSVBased && (
                    <>
                      {status === "archived" && (
                        <button
                          onClick={onRestoreClick}
                          className="audience-list__item--actions-action"
                        >
                          <div className="audience-list__item--actions-icon">
                            <img src={RestoreIcon} alt="restore" />
                          </div>
                          {t("t:audience.restore-segment")}
                        </button>
                      )}
                      {status === "available" && (
                        <button
                          onClick={onArchiveClick}
                          className="audience-list__item--actions-action"
                        >
                          <div className="audience-list__item--actions-icon">
                            <img src={ArchiveIcon} alt="archive" />
                          </div>
                          {t("t:audience.archive-segment")}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </ClickAwayListener>
            )}
          </div>
        </SkeletonComponent>
      </div>
    </section>
  );
};

ListItem.defaultProps = defaultProps;

export default memo(ListItem);
