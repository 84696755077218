import React, { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import _isEmpty from "lodash/isEmpty";

import { getCampaignStepResults } from "../../../../actions/campaignActions";
import Photo from "./Photo";
import Multiple from "./Multiple";
import ShortText from "./ShortText";
import Pictures from "./Pictures";
import YesNo from "./YesNo";
import Rating from "./Rating";

import ShowIcon from "../../../../assets/image/show-hide.svg";

const StepResults = ({ isHidden, hideStepResults }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: campaignId } = useParams();
  const { stepResults } = useSelector(({ campaign }) => campaign);

  const {
    challengesStepsExist,
    responsesStepsExist,
    multipleStepExists,
    shortTextStepExists,
    picturesStepExists,
    yesNoStepExists,
    ratingStepExists
  } = useMemo(
    () => ({
      challengesStepsExist: !!stepResults.photos && !_isEmpty(stepResults.photos),
      responsesStepsExist:
        (!!stepResults.multiple && !_isEmpty(stepResults.multiple)) ||
        (!!stepResults.short_text && !_isEmpty(stepResults.short_text)) ||
        (!!stepResults.pictures && !_isEmpty(stepResults.pictures)) ||
        (!!stepResults.yes_no && !_isEmpty(stepResults.yes_no)) ||
        (!!stepResults.rating && !_isEmpty(stepResults.rating)),
      multipleStepExists: !!stepResults.multiple && !_isEmpty(stepResults.multiple),
      shortTextStepExists: !!stepResults.short_text && !_isEmpty(stepResults.short_text),
      picturesStepExists: !!stepResults.pictures && !_isEmpty(stepResults.pictures),
      yesNoStepExists: !!stepResults.yes_no && !_isEmpty(stepResults.yes_no),
      ratingStepExists: !!stepResults.rating && !_isEmpty(stepResults.rating)
    }),
    // eslint-disable-next-line
    [JSON.stringify(stepResults)]
  );

  useEffect(() => {
    dispatch(getCampaignStepResults(campaignId));
    // eslint-disable-next-line
  }, []);

  const renderNoItemsBlock = () => (
    <p className="step-results__no-items">{t("t:common.nothing-to-show")}</p>
  );

  return (
    <div className={clsx("step-results", isHidden && "step-results__is-hidden")}>
      <button className="step-results__hide-results text_hover" onClick={hideStepResults}>
        <img src={ShowIcon} alt="show" className="step-results__hide-icon" />
        <p className="step-results__hide-results">{t("t:build-campaign.hide-step-results")}</p>
      </button>
      <div className="step-results__group-title">{t("t:build-campaign.challenges")}</div>
      {challengesStepsExist ? <Photo /> : renderNoItemsBlock()}
      <div className="step-results__group-title">{t("t:build-campaign.responses")}</div>
      {responsesStepsExist ? (
        <>
          {multipleStepExists && <Multiple />}
          {shortTextStepExists && <ShortText />}
          {picturesStepExists && <Pictures />}
          {yesNoStepExists && <YesNo />}
          {ratingStepExists && <Rating />}
        </>
      ) : (
        renderNoItemsBlock()
      )}
    </div>
  );
};

export default memo(StepResults);
