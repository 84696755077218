import { BALANCE } from "../constants";
import { joinQueries } from "../helpers/functions";

export function handleBalanceDialog(
  open,
  typeOfBalanceDialog = "",
  requestCampaignId = undefined,
  prefilledValue = null
) {
  return {
    type: BALANCE.HANDLE_BALANCE_DIALOG,
    open,
    typeOfBalanceDialog,
    requestCampaignId,
    prefilledValue
  };
}

export function postGetInvoice(company_id, data) {
  return {
    type: BALANCE.POST_GET_INVOICE,
    payload: {
      client: "default",
      request: {
        url: `/companies/${company_id}/add-balance/`,
        method: "post",
        data
      }
    }
  };
}

export function postDeposit(campaign_id, data) {
  return {
    type: BALANCE.POST_DEPOSIT,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/deposit/`,
        method: "post",
        data
      }
    }
  };
}

export function postRequestMoney(campaign_id, data) {
  return {
    type: BALANCE.POST_REQUEST_MONEY,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/request-money/`,
        method: "post",
        data
      }
    }
  };
}

export function getCampaignCost(campaign_id, status) {
  return {
    type: BALANCE.GET_CAMPAIGN_COST,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/cost-tab${
          status === "finalized" || status === "stopped_immediately" ? "-new" : ""
        }/`,
        method: "get"
      }
    }
  };
}

export function getCampaignSharingFBCost(campaign_id, queries) {
  return {
    type: BALANCE.GET_CAMPAIGN_SHARING_FB_COST,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/cost/sharing/fb/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function getCampaignSharingLinkCost(campaign_id, queries) {
  return {
    type: BALANCE.GET_CAMPAIGN_SHARING_LINK_COST,
    payload: {
      client: "default",
      request: {
        url: `/company/current/campaign/${campaign_id}/cost/sharing/link/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}
