import React, { memo } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router";

import RenderField from "../../../../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../../../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../../../../HelperComponents/DialogComponent/DialogComponent";
import { postAnalyticsBasedAudience } from "../../../../../../actions/audienceActions";

let CreateAudienceListDialog = ({
  t,
  isOpen,
  handleDialog,
  handleSubmit,
  submitting,
  pristine,
  valid,
  type,
  period
}) => {
  const buttonLoading = useSelector(({ audience }) => audience.buttonLoading);
  const { is_published: isPublished } = useSelector(({ campaign }) => campaign.campaignInfo);
  const dispatch = useDispatch();
  const { id: campaignID } = useParams();

  const prepareDataForSubmit = formFields => ({
    ...formFields,
    type,
    campaign: +campaignID,
    period
  });

  const onSubmit = data => {
    return dispatch(postAnalyticsBasedAudience(prepareDataForSubmit(data))).then(res => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        handleDialog();
      } else {
        const error = {};
        if (res.error.response.data.name) error.name = res.error.response.data.name[0].message;
        if (res.error.response.data.description)
          error.description = res.error.response.data.description[0].message;
        throw new SubmissionError(error);
      }
    });
  };

  return (
    <DialogComponent open={isOpen} onClose={handleDialog} closeIcon>
      <div className="default_dialog_wrapper">
        <div className="dialog_title">{t("t:campaign-analytics.create-new-list")}</div>
        <form onSubmit={handleSubmit(onSubmit)} className="dialog_form">
          <Field
            name="name"
            type="text"
            component={RenderField}
            label={t("t:audience.segment-name")}
          />
          <Field
            name="description"
            type="text"
            multiline
            additionalClasses="multiline-input"
            component={RenderField}
            label={t("t:audience.segment-description")}
            noErrorText
          />
          {isPublished && (
            <div className="dialog-info-text">
              {t("t:campaign-analytics.list-creating-warning")}
            </div>
          )}
          <div className="buttons_wrapper mt-48">
            <DefaultButton
              variant="contained"
              classes="purple_btn"
              loading={buttonLoading}
              disabled={submitting || pristine || !valid}
              formAction
            >
              {t("t:common.create")}
            </DefaultButton>
            <DefaultButton variant="contained" classes="cancel_btn" onClick={handleDialog}>
              {t("t:common.cancel")}
            </DefaultButton>
          </div>
        </form>
      </div>
    </DialogComponent>
  );
};

const validate = (values, { t }) => {
  const errors = {};
  if (!values.name) {
    errors.name = t("t:common.field-is-required");
  }
  return errors;
};

CreateAudienceListDialog = reduxForm({
  form: "CreateAudienceListForm",
  validate
})(CreateAudienceListDialog);

export default memo(withTranslation()(CreateAudienceListDialog));
